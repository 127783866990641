import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    data: [],
    userBookings: [],
    mentors: [],
    mentorRequest: [],
};
export const getUserBookings = createAsyncThunk(
    "mentoring/getUserBookings",
    async () => {
        const res = await apiRequest({
            url: `mentoring/getBookings`,
            method: "get",
        });
        return res;
    }
);
export const getAllMentors = createAsyncThunk(
    "mentoring/getAllMentors",
    async () => {
        const res = await apiRequest({
            url: `mentoring/getAllMentor`,
            method: "get",
        });
        return res;
    }
);
export const oneToOneMentorBooking = createAsyncThunk(
    "mentoring/oneToOneMentorBooking",
    async (data) => {
        const res = await apiRequest({
            url: `mentoring/oneToOneMentorBooking`,
            method: "post",
            data: data,
        });
        return res;
    }
);
export const getMentorRequest = createAsyncThunk(
    "mentoring/getMentorRequest",
    async () => {
        const res = await apiRequest({
            url: `mentoring/getAllRequests`,
            method: "get",
        });

        return res;
    }
);
export const requestApproval = createAsyncThunk(
    "mentoring/requestApproval",
    async (data) => {
        const res = await apiRequest({
            url: `mentoring/requestApproving/${data.mentoring_bookings_id}`,
            method: "post",
            data: data,
        });
        return res;
    }
);
export const rejectRequest = createAsyncThunk(
    "mentoring/rejectRequest",
    async (data) => {
        const res = await apiRequest({
            url: `mentoring/requestRejection/${data.id}`,
            method: "post",
            data: data,
        });
        return res;
    }
);

export const recheduleBooking = createAsyncThunk(
    "mentoring/recheduleBooking",
    async (data) => {
        const res = await apiRequest({
            url: `mentoring/updateBookings/${data.id}`,
            method: "post",
            data: data,
        });
        return res;
    }
);
export const deleteBooking = createAsyncThunk(
    "mentoring/deleteBooking",
    async (id) => {
        const res = await apiRequest({
            url: `mentoring/deleteRequest/${id}`,
            method: "delete",
        });
        return res;
    }
);
const mentoringSlice = createSlice({
    name: "mentoring",
    initialState: initialState,

    extraReducers: (builder) => {
        builder.addCase(getUserBookings.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getUserBookings.fulfilled, (state, action) => {
            const formated = formatDataForDataTable(
                action.payload.data.getAllBookingsById
            );

            return {
                ...state,
                userBookings: formated,
                loading: false,
            };
        });
        // ----------------------------------------------------------------
        builder.addCase(getAllMentors.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllMentors.fulfilled, (state, action) => {
            return {
                ...state,
                mentors: action.payload.data.namesAndUserIDs,
                loading: false,
            };
        });
        // -----------------------oneToOneMentorBooking----------------------------
        builder.addCase(oneToOneMentorBooking.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(oneToOneMentorBooking.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // --------------------------------getMentorRequest-----------------------------
        builder.addCase(getMentorRequest.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getMentorRequest.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.allRequests
            );
            return {
                ...state,
                mentorRequest: data,
                loading: false,
            };
        });
        // -----------------------requestApproval------------------------------------
        builder.addCase(requestApproval.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(requestApproval.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // --------------------rejectRequest------------------------------------
        builder.addCase(rejectRequest.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(rejectRequest.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------recheduleBooking------------------------------------
        builder.addCase(recheduleBooking.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(recheduleBooking.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------deleteBooking---------------------------
        builder.addCase(deleteBooking.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteBooking.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
    },
});

export default mentoringSlice.reducer;
