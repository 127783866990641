export const INACTIVE_STUDENTS_DATA_ANALYTICS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        width: 50,
    },
    {
        field: "croName",
        headerName: "CRO Name",
        width: 200,
    },
    {
        field: "totalInactiveStudents",
        headerName: "Total Inactive Students",
        width: 150,
    },
    {
        field: "admissionConfirmed",
        headerName: "Admission Confirmed",
        width: 150,
    },

    {
        field: "postponed",
        headerName: "Postponed",
        width: 150,
    },
    {
        field: "feeIssue",
        headerName: "Fee Issue",
        width: 150,
    },
    {
        field: "notNeededNow",
        headerName: "Not Needed Now",
        width: 150,
    },
    {
        field: "noCourseToEnroll",
        headerName: "No Course To Enroll",
        width: 150,
    },
    {
        field: "notAttended",
        headerName: "Not Attended",
        width: 150,
    },
];
