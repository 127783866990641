import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import BasicButton from "../../../components/mui/Button";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { ADMSSN_REGSTR_TBL_HEAD } from "../../../components/datagrid/myworkspace/cro/AdmissionRegisterTableHead";
import { Link } from "react-router-dom";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
    getAdmissionRegister,
    getCourses,
    saveAdmissionRegFilter,
} from "../../../redux/features/croSlice";
import CustomDataGrid from "../../../components/mui/DataGrid/CustomDataGrid";
import StudentDetailsView from "../../../components/datagrid/myworkspace/cro/StudentDetailsView";
import CroAdmissionRegisterActions from "../../../components/datagrid/myworkspace/cro/CroAdmissionRegisterActions";
import Wrapper from "../../../components/Wrapper/Wrapper";

const CroAdmissionRegister = () => {
    const [gridSize, setGridSize] = useState(5);
    const [selectedRows, setSelectedRows] = React.useState([]);

    const dispatch = useDispatch();
    const { courses, students, loading, admissionRegFilter } = useSelector(
        (state) => state.cro
    );
    const { data: userDetails } = useSelector((state) => state.login);
    const croId = userDetails.profile.userId;

    const schema = Yup.object().shape({
        courseId: Yup.number().required("this field is required"),
        courseStatusId: Yup.number().required("this field is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseId: admissionRegFilter.courseId,
            courseStatusId: admissionRegFilter.courseStatusId,
        },
        validationSchema: schema,
        onSubmit: (values) => {
            dispatch(getAdmissionRegister({ ...values, croId: croId }));
            dispatch(saveAdmissionRegFilter({ ...values, croId: croId }));
        },
    });

    useEffect(() => {
        dispatch(getCourses());
    }, []);
    return (
        <>
            <Wrapper
                title="Admission Register"
                actions={
                    <BasicButton
                        variant="contained"
                        sx={{
                            display: "flex",
                            gap: 1,
                        }}
                        component={Link}
                        to={"/my-workspace/cro-expected-sessions"}
                    >
                        Expected Sessions
                    </BasicButton>
                }
                Content={
                    <>
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px",
                                display: "flex",
                                justifyContent: "right",
                                padding: 1,
                            }}
                        >
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Stack
                                        gap={2}
                                        flexDirection="row"
                                        justifyContent="center"
                                    >
                                        <GlobalSelectField
                                            options={{
                                                name: "courseId",
                                                formik,
                                                sx: {
                                                    width: "250px",
                                                    "& .MuiInputBase-root": {
                                                        height: 50,
                                                    },
                                                },
                                                label: "Course",
                                                data: courses,
                                                keys: {
                                                    id: "courseId",
                                                    value: "courseName",
                                                },
                                            }}
                                        />
                                        <GlobalSelectField
                                            options={{
                                                name: "courseStatusId",
                                                formik,
                                                sx: {
                                                    width: "250px",
                                                    "& .MuiInputBase-root": {
                                                        height: 50,
                                                    },
                                                },
                                                label: "Status",
                                                data: [
                                                    { id: -1, name: "All" },
                                                    {
                                                        id: 1,
                                                        name: "Active",
                                                    },
                                                    {
                                                        id: 0,
                                                        name: "Inactive",
                                                    },
                                                    {
                                                        id: 2,
                                                        name: "Temporary Inactive",
                                                    },
                                                ],
                                                keys: {
                                                    id: "id",
                                                    value: "name",
                                                },
                                            }}
                                        />

                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{
                                                display: "inline-block",
                                                height: "50px",
                                            }}
                                        >
                                            Apply
                                        </Button>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Box>
                        <Box>
                            <Box>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        borderRadius: 1,
                                        width: "100%",
                                        mt: 2,
                                    }}
                                >
                                    <Box sx={{ width: "100%" }}>
                                        <CustomDataGrid
                                            loading={loading}
                                            data={students}
                                            rowId={"serialNumber"}
                                            columns={ADMSSN_REGSTR_TBL_HEAD}
                                            Head={[
                                                {
                                                    field: "studentName",
                                                    headerName: "Student Name",
                                                    width: 250,
                                                },
                                            ]}
                                            setSelectedRows={setSelectedRows}
                                            selectedRows={selectedRows}
                                            name={selectedRows[0]?.studentName}
                                            label={"Admission Number"}
                                            chipValue={selectedRows[0]?.admnNo}
                                            details={
                                                <StudentDetailsView
                                                    data={selectedRows[0]}
                                                />
                                            }
                                            actions={
                                                <CroAdmissionRegisterActions
                                                    data={selectedRows[0]}
                                                />
                                            }
                                        />
                                    </Box>
                                </Paper>
                            </Box>
                        </Box>
                    </>
                }
            />
        </>
    );
};

export default CroAdmissionRegister;
