export const LEAD_COURSE_ANALYTICS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "course",
        headerName: "Course",
        width: 150,
    },
    {
        field: "paidAssessment",
        headerName: "Paid Assessment",
        width: 150,
    },
    {
        field: "assessment",
        headerName: "Assessment",
        width: 150,
    },
    {
        field: "gccAssessment",
        headerName: "GCC Assessment",
        width: 150,
    },
    {
        field: "bdaAssessment",
        headerName: "BDA Assessment",
        width: 150,
    },
    {
        field: "foreignAssessment",
        headerName: "Foreign Assessment",
        width: 150,
    },
    {
        field: "referralSchemeEmployee",
        headerName: "Referral Scheme-Employee",
        width: 150,
    },
    {
        field: "referralSchemeTrainer",
        headerName: "Referral Scheme-Trainer",
        width: 150,
    },
    {
        field: "followUp",
        headerName: "Follow Up",
        width: 150,
    },
    {
        field: "personalMentorIndia",
        headerName: "Personal Mentor India",
        width: 150,
    },
    {
        field: "personalMentorGcc",
        headerName: "Personal Mentor Gcc",
        width: 150,
    },
    {
        field: "vacationCourses",
        headerName: "Vacation Courses",
        width: 150,
    },
    {
        field: "admissionConfirmed",
        headerName: "Admission Confirmed",
        width: 150,
    },
    {
        field: "totalSalesAmount",
        headerName: "TotalSales Amount",
        width: 150,
    },
    {
        field: "advancePaid",
        headerName: "Advance Paid",
        width: 150,
    },
];
