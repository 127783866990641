import { LocalizationProvider } from "@mui/x-date-pickers";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getPreprofile,
    getProfile,
    getProfilePending,
    getProfileReject,
    updateProfile,
} from "../../redux/features/profileSlice";

import SaveIcon from "@mui/icons-material/Save";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";

import { useSnackbar } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Loader from "../Loader/Loader";

const EditProfile = () => {
    const [preprofile, setPreprofile] = useState({});
    const [currentProfile, setCurrentProfile] = useState({
        PSTcity: "",
        PSTcountry: "",
        PSTdistrict: "",
        PSThouseName: "",
        PSTpinCode: "",
        PSTpostOffice: "",
        PSTstate: "",
        PSTstreetOrArea: "",
        aadhaarCardNo: "",
        accedemicRemarks: "",
        bloodGroup: "",
        city: "",
        country: "",
        courseId: "",
        courseStatus: "",
        courseStream: "",
        courseTypeId: "",
        district: "",
        dob: "",
        doj: "",
        email: "",
        esicNo: "",
        houseName: "",
        maritalStatus: "",
        mobileNo: "",
        name: "",
        officialEmail: "",
        officialMobileNo: "",
        panCardNo: "",
        parentContactNo: "",
        parentName: "",
        pfNo: "",
        photo: "",
        pinCode: "",
        postOffice: "",
        sex: "",
        state: "",
        streetOrArea: "",
        suggestion: "",
        userId: "",
        yearOfPass: "",
        isLoading: false,
    });
    const [updated, setUpdated] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.profile);
    const preprofile_ = useSelector((state) => state.preprofile);

    const general = {
        gender: ["", "Male", "Female"],
        bloodGroup: [
            { id: 1, value: "O+ve" },
            { id: 2, value: "O-ve" },
            { id: 3, value: "A+ve" },
            { id: 4, value: "A-ve" },
            { id: 5, value: "B+ve" },
            { id: 6, value: "B-ve" },
            { id: 7, value: "AB+ve" },
            { id: 8, value: "AB-ve" },
        ],
        matrialStatus: [
            { id: 1, value: "Single" },
            { id: 2, value: "Married" },
            { id: 3, value: "Separated" },
            { id: 4, value: "Widowed" },
            { id: 5, value: "Divorced" },
        ],
        courseStatus: [
            { id: 1, status: "Passed" },
            { id: 0, status: "Appearing" },
        ],
    };
    useEffect(() => {
        dispatch(getPreprofile());
        dispatch(getProfile());
    }, [updated]);

    useEffect(() => {
        const unsub = setCurrentProfile(userDetails);
        return () => unsub;
    }, [userDetails]);
    useEffect(() => {
        setPreprofile(preprofile_);
    }, [preprofile_]);

    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const editSchema = yup.object().shape({
        name: yup.string().required("This feild is required"),
        sex: yup.string().required("This feild is required"),
        dob: yup.string().required("This feild is required"),
        bloodGroup: yup.string().required("This feild is required"),
        maritalStatus: yup.string().required("This feild is required"),
        parentName: yup.string().required("This feild is required"),
        parentContactNo: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .min(10),
        houseName: yup.string().required("This feild is required"),
        streetOrArea: yup.string().required("This feild is required"),
        city: yup.string().required("This feild is required"),
        postOffice: yup.string().required("This feild is required"),
        district: yup.string().required("This feild is required"),
        state: yup.string().required("This feild is required"),
        country: yup.string().required("This feild is required"),
        pinCode: yup
            .number("Please enter a valid post code")
            .required("This feild is required"),
        PSThouseName: yup.string().required("This feild is required"),
        PSTstreetOrArea: yup.string().required("This feild is required"),
        PSTcity: yup.string().required("This feild is required"),
        PSTpostOffice: yup.string().required("This feild is required"),
        PSTdistrict: yup.string().required("This feild is required"),
        PSTstate: yup.string().required("This feild is required"),
        PSTcountry: yup.string().required("This feild is required"),
        PSTpinCode: yup
            .number("Please enter a valid post code")
            .required("This feild is required"),
        email: yup
            .string()
            .email("Please enter a valid email")
            .required("This feild is required"),
        officialEmail: yup
            .string()
            .email("Please enter a valid email")
            .required("This feild is required"),
        mobileNo: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .min(10),
        suggestion: yup.string().required("This feild is required"),
        officialMobileNo: yup
            .string()
            .matches(phoneRegExp, "Phone number is not valid")
            .min(10),

        courseStream: yup.string().required("This feild is required"),
        courseId: yup.string().required("This feild is required"),
        courseTypeId: yup.string().required("This feild is required"),
        courseStatus: yup.string().required("This feild is required"),
        yearOfPass: yup.number().required("This feild is required"),
        aadhaarCardNo: yup.string().required("This feild is required").min(12),
        panCardNo: yup.string().required("This feild is required"),
        doj: yup.string().required("This feild is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...currentProfile,
        },
        validationSchema: editSchema,
        onSubmit: (values) => {
            delete values.courseTypeId;
            delete values.courseId;
            delete values.userId;
            delete values.suggestion;
            delete values.accedemicRemarks;
            delete values.isLoading;
            delete values.isLogin;
            delete values.photo;
            delete values.email;
            delete values.mobileNo;
            dispatch(getProfilePending());
            dispatch(updateProfile({ body: values })).then((res) => {
                if (res.payload.status === "success") {
                    setUpdated(res.payload);
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                } else {
                    dispatch(getProfileReject());
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                }
            });
        },
    });

    const handlePstAdress = (e) => {
        if (e.target.checked) {
            formik.setFieldValue("PSThouseName", formik.values.houseName);
            formik.setFieldValue("PSTstreetOrArea", formik.values.streetOrArea);
            formik.setFieldValue("PSTcity", formik.values.city);
            formik.setFieldValue("PSTpostOffice", formik.values.postOffice);
            formik.setFieldValue("PSTdistrict", formik.values.district);
            formik.setFieldValue("PSTstate", formik.values.state);
            formik.setFieldValue("PSTcountry", formik.values.country);
            formik.setFieldValue("PSTpinCode", formik.values.pinCode);
        } else if (!e.target.checked) {
            formik.setFieldValue("PSThouseName", "");
            formik.setFieldValue("PSTstreetOrArea", "");
            formik.setFieldValue("PSTcity", "");
            formik.setFieldValue("PSTpostOffice", "");
            formik.setFieldValue("PSTdistrict", "");
            formik.setFieldValue("PSTstate", "");
            formik.setFieldValue("PSTcountry", "");
            formik.setFieldValue("PSTpinCode", "");
        }
    };

    return (
        <>
            {currentProfile.isLoading ? (
                <Loader />
            ) : (
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Stack
                            sx={{
                                width: { xs: "90%", sm: "80%", md: "75%" },
                                margin: "0 auto",
                                gap: "10px",
                            }}
                        >
                            <TextField
                                name="name"
                                label="Full Name"
                                type="text"
                                {...formik.getFieldProps("name")}
                                required
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "name",
                                        e.target.value.toUpperCase()
                                    );
                                }}
                                value={formik.values.name}
                                error={Boolean(
                                    formik.touched.name && formik.errors.name
                                )}
                                helperText={
                                    formik.touched.name
                                        ? formik.errors.name
                                        : "As per aadhaar"
                                }
                            />

                            <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    label="Gender"
                                    select
                                    name="sex"
                                    value={formik.values.sex}
                                    {...formik.getFieldProps("sex")}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "sex",
                                            e.target.value
                                        );
                                    }}
                                    error={Boolean(
                                        formik.touched.sex && formik.errors.sex
                                    )}
                                    helperText={formik.errors.sex}
                                    required
                                >
                                    {general.gender.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    label="Marital Status"
                                    name="maritalStatus"
                                    value={formik.values.maritalStatus}
                                    {...formik.getFieldProps("maritalStatus")}
                                    required
                                    select
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "maritalStatus",
                                            e.target.value
                                        )
                                    }
                                    error={Boolean(
                                        formik.touched.maritalStatus &&
                                            formik.errors.maritalStatus
                                    )}
                                    helperText={formik.errors.maritalStatus}
                                >
                                    {general.matrialStatus.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.value}
                                        >
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                            <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <Stack
                                        width={{
                                            xs: "100%",
                                            sm: "50%",
                                        }}
                                    >
                                        <DesktopDatePicker
                                            label="Date of birth"
                                            name="dob"
                                            inputFormat="MM/DD/YYYY"
                                            value={formik.values.dob}
                                            onChange={(e) => {
                                                if (e !== null) {
                                                    formik.setFieldValue(
                                                        "dob",
                                                        `${e.$y}-${e.$M + 1}-${
                                                            e.$D
                                                        }`
                                                    );
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    label="Blood Group"
                                    name="bloodGroup"
                                    type="text"
                                    required
                                    value={
                                        formik.values.bloodGroup
                                            ? formik.values.bloodGroup
                                            : ""
                                    }
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldValue(
                                            "bloodGroupe",
                                            e.target.value
                                        );
                                    }}
                                    select
                                    {...formik.getFieldProps("bloodGroup")}
                                    error={Boolean(
                                        formik.touched.bloodGroup &&
                                            formik.errors.bloodGroup
                                    )}
                                    helperText={formik.errors.bloodGroup}
                                >
                                    {general.bloodGroup.map((option) => (
                                        <MenuItem
                                            key={option.id}
                                            value={option.value}
                                        >
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>

                            <TextField
                                label="Name of Parent / Guardian"
                                required
                                name="parentName"
                                value={formik.values.parentName}
                                {...formik.getFieldProps("parentName")}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "parentName",
                                        e.target.value.toLocaleUpperCase()
                                    )
                                }
                                error={Boolean(
                                    formik.touched.parentName &&
                                        formik.errors.parentName
                                )}
                                helperText={formik.errors.parentName}
                            />
                            <TextField
                                type="tel"
                                label="Mobile No. of Parent / Guardian"
                                required
                                name="parentContactNo"
                                value={formik.values.parentContactNo}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "parentContactNo",
                                        e.target.value
                                    )
                                }
                                {...formik.getFieldProps("parentContactNo")}
                                error={Boolean(formik.errors.parentContactNo)}
                                helperText={formik.errors.parentContactNo}
                            />
                            <div
                                style={{
                                    borderBottom: "1px solid black",
                                    width: "100%",
                                    height: "1px",
                                    margin: "20px 0",
                                }}
                            ></div>
                            <TextField
                                label="House No. / Name"
                                required
                                name="houseName"
                                {...formik.getFieldProps("houseName")}
                                value={formik.values.houseName}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "houseName",
                                        e.target.value.toUpperCase()
                                    );
                                }}
                                error={Boolean(
                                    formik.touched.houseName &&
                                        formik.errors.houseName
                                )}
                                helperText={formik.errors.houseName}
                            />
                            <TextField
                                {...formik.getFieldProps("streetOrArea")}
                                label="Street / Area"
                                required
                                name="streetOrArea"
                                value={formik.values.streetOrArea}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "streetOrArea",
                                        e.target.value.toUpperCase()
                                    )
                                }
                                error={Boolean(
                                    formik.touched.streetOrArea &&
                                        formik.errors.streetOrArea
                                )}
                                helperText={formik.errors.streetOrArea}
                            />
                            <TextField
                                label="City / Town"
                                required
                                name="city"
                                {...formik.getFieldProps("city")}
                                value={formik.values.city}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "city",
                                        e.target.value.toUpperCase()
                                    );
                                }}
                                error={Boolean(
                                    formik.touched.city && formik.errors.city
                                )}
                                helperText={formik.errors.city}
                            />
                            <TextField
                                {...formik.getFieldProps("postOffice")}
                                label="Post Office"
                                required
                                name="postOffice"
                                value={formik.values.postOffice}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "postOffice",
                                        e.target.value.toUpperCase()
                                    )
                                }
                                error={Boolean(
                                    formik.touched.postOffice &&
                                        formik.errors.postOffice
                                )}
                                helperText={formik.errors.postOffice}
                            />
                            <TextField
                                label="District"
                                required
                                name="district"
                                {...formik.getFieldProps("district")}
                                value={formik.values.district}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "district",
                                        e.target.value.toUpperCase()
                                    )
                                }
                                error={Boolean(
                                    formik.touched.district &&
                                        formik.errors.district
                                )}
                                helperText={formik.errors.district}
                            />
                            <TextField
                                label="State"
                                {...formik.getFieldProps("state")}
                                required
                                name="state"
                                value={formik.values.state}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "state",
                                        e.target.value.toUpperCase()
                                    )
                                }
                                error={Boolean(
                                    formik.touched.state && formik.errors.state
                                )}
                                helperText={formik.errors.state}
                            />
                            <TextField
                                name="country"
                                label="Country"
                                required
                                {...formik.getFieldProps("country")}
                                value={formik.values.country}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "country",
                                        e.target.value.toUpperCase()
                                    )
                                }
                                error={Boolean(
                                    formik.touched.country &&
                                        formik.errors.country
                                )}
                                helperText={formik.errors.country}
                            />
                            <TextField
                                name="pinCode"
                                label="Pincode"
                                required
                                {...formik.getFieldProps("pinCode")}
                                type="number"
                                value={formik.values.pinCode}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "pinCode",
                                        e.target.value
                                    )
                                }
                                error={Boolean(
                                    formik.touched.pinCode &&
                                        formik.errors.pinCode
                                )}
                                helperText={formik.errors.pinCode}
                            />
                            <div
                                style={{
                                    borderBottom: "1px solid black",
                                    width: "100%",
                                    height: "1px",
                                    margin: "20px 0",
                                }}
                            ></div>
                            <Stack>
                                <Typography variant="h6">
                                    Permenet address
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="Same as communication adress"
                                            onChange={(e) => handlePstAdress(e)}
                                        />
                                    }
                                    label="Same as communication adress"
                                />
                            </Stack>
                            <TextField
                                label="House No. / Name"
                                required
                                name="PSThouseName"
                                {...formik.getFieldProps("PSThouseName")}
                                value={formik.values.PSThouseName}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "PSThouseName",
                                        e.target.value.toUpperCase()
                                    )
                                }
                                error={Boolean(
                                    formik.touched.PSThouseName &&
                                        formik.errors.PSThouseName
                                )}
                                helperText={formik.errors.PSThouseName}
                            />
                            <TextField
                                name="PSTstreetOrArea"
                                label="Street / Area"
                                required
                                {...formik.getFieldProps("PSTstreetOrArea")}
                                value={formik.values.PSTstreetOrArea}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "PSTstreetOrArea",
                                        e.target.value.toUpperCase()
                                    )
                                }
                                error={Boolean(
                                    formik.touched.PSTstreetOrArea &&
                                        formik.errors.PSTstreetOrArea
                                )}
                                helperText={formik.errors.PSTstreetOrArea}
                            />
                            <TextField
                                label="City / Town"
                                name="PSTcity"
                                {...formik.getFieldProps("PSTcity")}
                                value={formik.values.PSTcity}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "PSTcity",
                                        e.target.value.toUpperCase()
                                    );
                                }}
                                required
                                error={Boolean(
                                    formik.touched.PSTcity &&
                                        formik.errors.PSTcity
                                )}
                                helperText={formik.errors.PSTcity}
                            />
                            <TextField
                                label="Post Office"
                                name="PSTpostOffice"
                                {...formik.getFieldProps("PSTpostOffice")}
                                value={formik.values.PSTpostOffice}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "PSTpostOffice",
                                        e.target.value.toUpperCase()
                                    );
                                }}
                                required
                                error={Boolean(
                                    formik.touched.PSTpostOffice &&
                                        formik.errors.PSTpostOffice
                                )}
                                helperText={formik.errors.PSTpostOffice}
                            />
                            <TextField
                                label="District"
                                name="PSTdistrict"
                                {...formik.getFieldProps("PSTdistrict")}
                                value={formik.values.PSTdistrict}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "PSTdistrict",
                                        e.target.value.toUpperCase()
                                    );
                                }}
                                required
                                error={Boolean(
                                    formik.touched.PSTdistrict &&
                                        formik.errors.PSTdistrict
                                )}
                                helperText={formik.errors.PSTdistrict}
                            />
                            <TextField
                                label="State"
                                name="PSTstate"
                                {...formik.getFieldProps("PSTstate")}
                                value={formik.values.PSTstate}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "PSTstate",
                                        e.target.value.toUpperCase()
                                    );
                                }}
                                required
                                error={Boolean(
                                    formik.touched.PSTstate &&
                                        formik.errors.PSTstate
                                )}
                                helperText={formik.errors.PSTstate}
                            />
                            <TextField
                                label="Country"
                                name="PSTcountry"
                                {...formik.getFieldProps("PSTcountry")}
                                value={formik.values.PSTcountry}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "PSTcountry",
                                        e.target.value.toUpperCase()
                                    );
                                }}
                                required
                                error={Boolean(
                                    formik.touched.PSTcountry &&
                                        formik.errors.PSTcountry
                                )}
                                helperText={formik.errors.PSTcountry}
                            />
                            <TextField
                                label="Pincode"
                                name="PSTpinCode"
                                {...formik.getFieldProps("PSTpinCode")}
                                value={formik.values.PSTpinCode}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        "PSTpinCode",
                                        e.target.value
                                    );
                                }}
                                required
                                error={Boolean(
                                    formik.touched.PSTpinCode &&
                                        formik.errors.PSTpinCode
                                )}
                                helperText={formik.errors.PSTpinCode}
                            />
                            <div
                                style={{
                                    borderBottom: "1px solid black",
                                    width: "100%",
                                    height: "1px",
                                    margin: "20px 0",
                                }}
                            ></div>
                            <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                <TextField
                                    disabled
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                        cursor: "not-allowed",
                                    }}
                                    label="E-Mail Id"
                                    name="email"
                                    {...formik.getFieldProps("email")}
                                    type="email"
                                    required
                                    value={formik.values.email}
                                    error={Boolean(
                                        formik.touched.email &&
                                            formik.errors.email
                                    )}
                                    helperText={formik.errors.email}
                                />

                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    label="Official E-Mail Id"
                                    name="officialEmail"
                                    {...formik.getFieldProps("officialEmail")}
                                    type="email"
                                    required
                                    value={formik.values.officialEmail}
                                    error={Boolean(
                                        formik.touched.officialEmail &&
                                            formik.errors.officialEmail
                                    )}
                                    helperText={formik.errors.officialEmail}
                                />
                            </Stack>
                            <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                <TextField
                                    disabled
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    label="Mobile No"
                                    name="mobileNo"
                                    type="tel"
                                    {...formik.getFieldProps("mobileNo")}
                                    required
                                    value={formik.values.mobileNo}
                                    error={Boolean(formik.errors.mobileNo)}
                                    helperText={formik.errors.mobileNo}
                                />
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    label="Official No"
                                    name="officialMobileNo"
                                    type="tel"
                                    {...formik.getFieldProps(
                                        "officialMobileNo"
                                    )}
                                    required
                                    value={formik.values.officialMobileNo}
                                    error={Boolean(
                                        formik.errors.officialMobileNo
                                    )}
                                    helperText={formik.errors.officialMobileNo}
                                />
                            </Stack>

                            <div
                                style={{
                                    borderBottom: "1px solid black",
                                    width: "100%",
                                    height: "1px",
                                    margin: "20px 0",
                                }}
                            ></div>

                            {preprofile.isLoading ? null : (
                                <>
                                    <Stack
                                        spacing={2}
                                        direction={{ xs: "column", sm: "row" }}
                                    >
                                        <FormControl
                                            sx={{
                                                width: {
                                                    xs: "100%",
                                                    sm: "50%",
                                                },
                                            }}
                                        >
                                            <TextField
                                                label="Current Status / Highest Qualification"
                                                select
                                                required
                                                name="courseTypeId"
                                                {...formik.getFieldProps(
                                                    "courseTypeId"
                                                )}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "courseTypeId",
                                                        e.target.value
                                                    );
                                                    formik.setFieldValue(
                                                        "courseId",
                                                        ""
                                                    );
                                                    formik.setFieldValue(
                                                        "courseStream",
                                                        ""
                                                    );

                                                    formik.setFieldValue(
                                                        "courseStatus",
                                                        ""
                                                    );

                                                    formik.setFieldValue(
                                                        "yearOfPass",
                                                        ""
                                                    );
                                                }}
                                                value={
                                                    formik.values.courseTypeId
                                                        ? formik.values
                                                              .courseTypeId
                                                        : ""
                                                }
                                                error={Boolean(
                                                    formik.touched
                                                        .courseTypeId &&
                                                        formik.errors
                                                            .courseTypeId
                                                )}
                                                helperText={
                                                    formik.errors.courseTypeId
                                                }
                                            >
                                                {preprofile.courseType ? (
                                                    preprofile.courseType.map(
                                                        (option) => {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        option.course_type_id
                                                                    }
                                                                    value={
                                                                        option.course_type_id
                                                                    }
                                                                >
                                                                    {
                                                                        option.course_type_name
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <MenuItem>
                                                        {"loading....."}
                                                    </MenuItem>
                                                )}
                                            </TextField>
                                        </FormControl>
                                        <FormControl
                                            sx={{
                                                width: {
                                                    xs: "100%",
                                                    sm: "50%",
                                                },
                                            }}
                                        >
                                            <TextField
                                                label="Course Name"
                                                name="courseId"
                                                value={
                                                    formik.values.courseId
                                                        ? formik.values.courseId
                                                        : ""
                                                }
                                                required
                                                select
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "courseId",
                                                        e.target.value
                                                    );
                                                    formik.handleChange(e);
                                                }}
                                                error={Boolean(
                                                    formik.errors.courseId
                                                )}
                                                helperText={
                                                    formik.errors.courseId
                                                }
                                            >
                                                {preprofile.course ? (
                                                    preprofile.course?.map(
                                                        (option) => {
                                                            if (
                                                                option.course_type_id ===
                                                                formik.values
                                                                    .courseTypeId
                                                            ) {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            option.course_id
                                                                        }
                                                                        value={
                                                                            option.course_id
                                                                        }
                                                                    >
                                                                        {
                                                                            option.course_name
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        }
                                                    )
                                                ) : (
                                                    <MenuItem>
                                                        {"loading....."}
                                                    </MenuItem>
                                                )}
                                            </TextField>
                                        </FormControl>
                                    </Stack>

                                    <FormControl fullWidth>
                                        <TextField
                                            label="Branch /Specialization"
                                            name="courseStream"
                                            value={
                                                formik.values.courseStream
                                                    ? formik.values.courseStream
                                                    : ""
                                            }
                                            required
                                            {...formik.getFieldProps(
                                                "courseStream"
                                            )}
                                            select
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "courseStream",
                                                    e.target.value
                                                );
                                            }}
                                            error={Boolean(
                                                formik.errors.courseStream
                                            )}
                                            helperText={
                                                formik.errors.courseStream
                                            }
                                        >
                                            {preprofile.courseStream ? (
                                                preprofile.courseStream?.map(
                                                    (option) => {
                                                        if (
                                                            option.course_id ===
                                                            formik.values
                                                                .courseId
                                                        ) {
                                                            return (
                                                                <MenuItem
                                                                    key={
                                                                        option.course_stream_id
                                                                    }
                                                                    value={
                                                                        option.course_stream_id
                                                                    }
                                                                >
                                                                    {
                                                                        option.course_stream_name
                                                                    }
                                                                </MenuItem>
                                                            );
                                                        }
                                                    }
                                                )
                                            ) : (
                                                <MenuItem value="">
                                                    {"loading....."}
                                                </MenuItem>
                                            )}
                                        </TextField>
                                    </FormControl>
                                </>
                            )}
                            <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    name="CourseStatus"
                                    label="Course Status"
                                    {...formik.getFieldProps("courseStatus")}
                                    select
                                    required
                                    value={formik.values.courseStatus}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "courseStatus",
                                            e.target.value
                                        )
                                    }
                                    error={Boolean(formik.errors.courseStatus)}
                                    helperText={formik.errors.courseStatus}
                                >
                                    {general.courseStatus.map((opt) => (
                                        <MenuItem key={opt.id} value={opt.id}>
                                            {opt.status}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    name="yearOfPass"
                                    type="number"
                                    label="Year of Passing"
                                    required
                                    {...formik.getFieldProps("yearOfPass")}
                                    value={formik.values.yearOfPass}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "yearOfPass",
                                            e.target.value
                                        )
                                    }
                                    error={Boolean(formik.errors.yearOfPass)}
                                    helperText={formik.errors.yearOfPass}
                                />
                            </Stack>

                            <TextField
                                label="Academic Remarks"
                                name="accedemicRemarks"
                                value={formik.values.accedemicRemarks}
                                onChange={(e) =>
                                    formik.setFieldValue(
                                        "accedemicRemarks",
                                        e.target.value
                                    )
                                }
                            />
                            <div
                                style={{
                                    borderBottom: "1px solid black",
                                    width: "100%",
                                    height: "1px",
                                    margin: "20px 0",
                                }}
                            ></div>

                            <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    name="aadhaarCardNo"
                                    label="Aadhaar No"
                                    required
                                    {...formik.getFieldProps("aadhaarCardNo")}
                                    value={formik.values.aadhaarCardNo}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "aadhaarCardNo",
                                            e.target.value
                                        )
                                    }
                                    error={Boolean(formik.errors.aadhaarCardNo)}
                                    helperText={formik.errors.aadhaarCardNo}
                                />
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    name="panCardNo"
                                    label="PAN"
                                    required
                                    {...formik.getFieldProps("panCardNo")}
                                    value={formik.values.panCardNo}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "panCardNo",
                                            e.target.value
                                        )
                                    }
                                    error={Boolean(
                                        formik.touched.panCardNo &&
                                            formik.errors.panCardNo
                                    )}
                                    helperText={formik.errors.panCardNo}
                                />
                            </Stack>
                            <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    name="pfNo"
                                    label="PF Number"
                                    value={formik.values.pfNo}
                                    {...formik.getFieldProps("pfNo")}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "pfNo",
                                            e.target.value
                                        );
                                    }}
                                    helperText="if any"
                                />
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    name="esicNo"
                                    label="ESIC Number"
                                    {...formik.getFieldProps("esicNo")}
                                    helperText="if any"
                                    value={formik.values.esicNo}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            "esicNo",
                                            e.target.value
                                        );
                                    }}
                                />
                            </Stack>
                            <Stack
                                spacing={2}
                                direction={{ xs: "column", sm: "row" }}
                            >
                                <TextField
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            sm: "50%",
                                        },
                                    }}
                                    name="suggestion"
                                    {...formik.getFieldProps("suggestion")}
                                    label="Role / Designation"
                                    value={formik.values.suggestion}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "suggestion",
                                            e.target.value
                                        )
                                    }
                                    error={Boolean(
                                        formik.touched.suggestion &&
                                            formik.errors.suggestion
                                    )}
                                    helperText={formik.errors.suggestion}
                                />

                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <Stack
                                        width={{
                                            xs: "100%",
                                            sm: "50%",
                                        }}
                                    >
                                        <DesktopDatePicker
                                            label="Date of Join"
                                            name="doj"
                                            inputFormat="MM/DD/YYYY"
                                            value={formik.values.doj}
                                            onChange={(e) => {
                                                if (e !== null) {
                                                    formik.setFieldValue(
                                                        "doj",
                                                        `${e.$y}-${e.$M + 1}-${
                                                            e.$D
                                                        }`
                                                    );
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Stack>

                            <Button
                                variant="contained"
                                size="large"
                                endIcon={<SaveIcon />}
                                type="submit"
                                sx={{ width: "50%", margin: "20px auto 0" }}
                            >
                                SAVE
                            </Button>
                        </Stack>
                    </Form>
                </FormikProvider>
            )}
        </>
    );
};

export default EditProfile;
