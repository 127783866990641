import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiRequest from '../../api/request'

const initialState = {
  loading: false,
  resData: {
    courses: [],
    meta: {},
  },
  projects: [],
}

export const getAllCourses = createAsyncThunk('courseSlice/getAllCourses', async (paginationDetails) => {
  const res = await apiRequest({
    method: 'get',
    url: `general/courses/2?page=${paginationDetails?.pageNum}&limit=${
      paginationDetails?.gridSize
    }&search=${encodeURIComponent(paginationDetails?.search)}`,
  })
  return res
})

export const getAllProjects = createAsyncThunk('courseSlice/getAllProjects', async () => {
  const res = await apiRequest({
    method: 'get',
    url: `cr-pre-data/getProjects`,
  })
  return res
})

export const createCourse = createAsyncThunk('courseSlice/createCourse', async (data) => {
  const res = await apiRequest({
    method: 'post',
    url: `general/courses`,
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
  return res
})

export const deleteCourse = createAsyncThunk('courseSlice/deleteCourse', async (id) => {
  const res = await apiRequest({
    method: 'delete',
    url: `general/courses/${id}`,
  })
  return res
})

export const updateCourse = createAsyncThunk('courseSlice/updateCourse', async (data) => {
  const res = await apiRequest({
    method: 'patch',
    url: `general/courses/${data.id}`,
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
  return res
})

const courseSlice = createSlice({
  name: 'courseSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //..................GETING ALL AVAILABLE COURSES................
    builder.addCase(getAllCourses.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getAllCourses.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        resData: {
          ...state.resData,
          courses: action.payload?.data,
          meta: action.payload?.meta,
        },
      }
    })

    //..................GETING ALL AVAILABLE PROJECTS................
    builder.addCase(getAllProjects.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        projects: action.payload?.data?.projects,
      }
    })

    //..................CREATE A COURSE................
    builder.addCase(createCourse.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(createCourse.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })

    //..................DELETE A COURSE................
    builder.addCase(deleteCourse.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(deleteCourse.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })

    //..................UPDATE A COURSE................
    builder.addCase(updateCourse.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(updateCourse.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })
  },
})

export default courseSlice.reducer
