import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ImageContainer from "./HeaderImage";
import speakProHeader from "../../../../../img/Artboard.jpg";
import speakProFooter from "../../../../../img/Artboard2.jpg";
import commonHeader from "../../../../../img/Assessment.jpg";
import commonFooter from "../../../../../img/Assessment Foot.jpg";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Details from "./Details";
import GlobalWrapper from "../../../../../components/Wrapper/GlobalWrapper";
import { Icon } from "@iconify/react";
import ReportCharts from "../ReportCharts";
import SpeakProReportData from "./SpeakProReportData";
import useResponsive from "../../../../../Hooks/useResponsive";
import { useSnackbar } from "notistack";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import { createAssessmentReports } from "../../../../../redux/features/assessmentToolSlice";
import Loader3D from "../../../../../components/Loader/Loader3D";
import SimpleLoader from "../../../../../components/Loader/SimpleLoader";
import ReportConfirmationAlert from "./ReportConfirmationAlert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PreviewReport = ({
  data,
  open,
  setOpen,
  Chart,
  chartData,
  speakProChartData,
}) => {
  const [subjectDescriptions, setSubjectDescriptions] = React.useState([]);
  const storedStudentData = JSON.parse(localStorage.getItem("studentData"));
  const [pdfName, setPdfName] = React.useState("");
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [countdown, setCountdown] = React.useState(0);
  const [header, setHeader] = React.useState("");
  const [footer, setFooter] = React.useState("");
  const mdDown = useResponsive("down", "md");
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [confirmedAlert, setconfirmedAlert] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let componentRef = useRef(null);
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.assessmentTool.reportSubmitLoading
  );


  React.useEffect(() => {
    //USING A STATE TO SAVE THE CANDIDATE NAME FOR DOWNLOADING PDF NAME SUGGETION
    setPdfName(storedStudentData?.name);
  }, [storedStudentData]);


  React.useEffect(() => {
    //THIS USE EFFECT WILL DYNAMICALLY CHANGE THE HEADER AND FOOTER IMAGE
    if (storedStudentData.courseName === 1) {
      setHeader(speakProHeader);
      setFooter(speakProFooter);
    } else {
      setHeader(commonHeader);
      setFooter(commonFooter);
    }
  }, [storedStudentData?.courseName]);


  React.useEffect(() => {
    //IF THE CHART IS SELECTED SPEEDO METER THEN ADDING A 5SECOND DELAY TO DOWNLOD THE PDF BECAUSE THE SPEEDO METER ANIMATION NEED FEW SECONDS
    if (Chart === 4 && open == true) {
      setButtonDisabled(true);
      setCountdown(5);
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setButtonDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        if (timer) {
          clearInterval(timer);
        }
        setButtonDisabled(false);
        setCountdown(0);
      };
    }
  }, [Chart, open]);


  React.useEffect(() => {
    //MAKING STRUCTURE TO ITERATE TO DISPLAY DESCRIPTIONS BASED ON THE COURESE NAME
    if (data) {
      if (storedStudentData?.courseName === 1) {
        setSubjectDescriptions(data);
      } else {
        const result = data.map(({ subject, levels }) => ({
          subject,
          checkedDescriptions: levels.flatMap(({ objectives }) =>
            objectives.flatMap(({ checkedDescriptions }) => checkedDescriptions)
          ),
        }));
        setSubjectDescriptions(result);
      }
    }
  }, [data, storedStudentData?.courseName]);


  React.useEffect(() => {
    //PDF GENERATION ALERT BOX CONFIRMED THEN IT WILL CALL THE HANDLE PRINT FUNCTION TO TRIGGER THE API POSTING OF CANDIDATE REPORT
    if (confirmedAlert) {
      setOpenConfirmation(false);
      handlePrint();
    }
  }, [confirmedAlert]);


  const makingStructureToPostData = () => {
    if (storedStudentData.courseName == 1) {
      const ids = data.map((matrix) => matrix.checkedDescriptionId);
      storedStudentData.assessmentDescriptionId = ids;
    } else {
      const ids = data
        .flatMap((subject) => subject.levels)
        .flatMap((level) => level.objectives)
        .flatMap((objective) => objective.checkedDescriptions)
        .map((description) => description.id);
      storedStudentData.assessmentDescriptionId = ids;
    }
    const modifiedStudentData = {
      ...storedStudentData,
      favSubject: storedStudentData.favouriteSubject,
      sroLeadId: storedStudentData.nameId,
      userType: storedStudentData.userTypeName,
      gender: storedStudentData.genderName,
      courseName: storedStudentData.courseFullName,
    };
    return modifiedStudentData;
  };


  const handlePrint = () => {
    const finalDataToPost = makingStructureToPostData();
    dispatch(createAssessmentReports(finalDataToPost)).then((res) => {
      if (res.payload.status == "failed" || res.payload.status == "error") {
        enqueueSnackbar(` ${res.payload.message}`, {
          variant: "error",
          autoHideDuration: 3000,
        });
      } else if (res.payload.status === "success") {
        enqueueSnackbar(
          "PDF generated. Please make sure to save it, as you won't be able to generate it again.",
          { variant: "success" }
        );
        downloadPdf();
      }
    });
    setconfirmedAlert(false);
  };

  const downloadPdf = useReactToPrint({//BROWSER PREVIEW PAGE TO DOWNLOAD AND PRINT
    content: () => componentRef.current,
    documentTitle: `${pdfName}`,
    onPrintError: () => alert("error occured"),
    onAfterPrint: () => setconfirmedAlert(false),
  });

  const handleDownload = () => {
    setOpenConfirmation(true); //OPENING ALERT BOX TO CONFIRM THE PDF
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const downloadPdfNew = async () => {
  //   const element = componentRef.current;
  //   const canvas = await html2canvas(element, { scale: 0.75 });
  //   const imageData = canvas.toDataURL("image/png",0.7);

  //   const pdf = new jsPDF({
  //     orientation: "portrait",
  //     unit: "pt",
  //     format: "a4",
  //     compress: true,
  //   });
  //   const a4width = pdf.internal.pageSize.getWidth();
  //   const a4height = pdf.internal.pageSize.getHeight();

  //   const imgWidth = canvas.width;
  //   const imgHeight = canvas.height;
  //   const padding = 16;

  //   const ratio = (a4width - 2 * padding) / imgWidth;
  //   const scaledWidth = imgWidth * ratio;
  //   const scaledHeight = imgHeight * ratio;
  //   let position = 2;

  //   while (position < scaledHeight) {
  //     pdf.addImage(
  //       imageData, // The base64 image data
  //       "PNG", // The image format
  //       padding, // X-coordinate for placement (0 means left-aligned)
  //       padding - position, // Y-coordinate for placement (adjusts for page breaks)
  //       scaledWidth, // Scaled width of the image to fit the page width
  //       scaledHeight // Scaled height of the image to maintain aspect ratio
  //     );

  //     position += a4height - 2 * padding;

  //     if (position < scaledHeight) {
  //       pdf.addPage();
  //     }
  //   }

  //   pdf.addPage();
  //   const footerImg = new Image();
  //   footerImg.src = footer;
  //   await new Promise((resolve) => (footerImg.onload = resolve));
  //   const footerImageHeight = 800;
  //   pdf.addImage(
  //     footerImg, // The image object
  //     "JPEG", // The image format
  //     padding, // X-coordinate for placement (0 means left-aligned)
  //     padding, // Y-coordinate for placement (bottom of the page)
  //     scaledWidth, // Width of the footer image
  //     footerImageHeight // Height of the footer image
  //   );
  //   pdf.save(`${pdfName}`);
  // };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Box>
          <Container sx={{ paddingTop: 2 }}>
            <GlobalWrapper
              title={"Report Preview"}
              actions={
                <>
                  {loading ? (
                    <SimpleLoader />
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleDownload}
                      disabled={buttonDisabled}
                    >
                      {buttonDisabled ? (
                        `Please Wait  ${countdown}s To Print Report `
                      ) : (
                        <>
                          <Icon
                            icon="ion:print-outline"
                            height="25"
                            width="25"
                          />
                          Download Report
                        </>
                      )}
                    </Button>
                  )}
                </>
              }
            >
              <Box className="print-content">
                <Box ref={componentRef}>
                  <Container
                    sx={{
                      marginTop: 3,
                      marginBottom: 3,
                    }}
                  >
                    <ImageContainer src={header} alt="header" />
                  </Container>

                  <Box>
                    <Details />
                  </Box>
                  <Box pt={9}>
                    <Box sx={{ padding: 4 }}>
                      <Box>
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#3a6bae",
                            paddingLeft: 6,
                            fontFamily: "Lora, sans-serif",
                          }}
                        >
                          PERFORMANCE SUMMARY
                        </Typography>
                      </Box>
                      <Box
                        sx={{ width: Chart === 1 && mdDown ? "200%" : "80%" }}
                      >
                        <ReportCharts
                          speakProChartData={speakProChartData}
                          data={data}
                          chartId={Chart}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <Container
                      className="descriptionContainer"
                      sx={{
                        marginTop: 0,
                        marginBottom: 1,
                        padding: 4,
                      }}
                    >
                      {storedStudentData?.courseName === 1 ? (
                        <SpeakProReportData data={subjectDescriptions} />
                      ) : (
                        <div>
                          {subjectDescriptions.map((subjectData, index) => (
                            <Paper
                              key={index}
                              style={{
                                margin: "16px 0",
                                padding: "16px",
                              }}
                            >
                              <Typography variant="h4" gutterBottom>
                                {subjectData.subject}
                              </Typography>
                              <List
                                sx={{
                                  padding: 4,
                                }}
                              >
                                {subjectData.checkedDescriptions.map(
                                  (description) => (
                                    <Box key={description.id}>
                                      <li>
                                        <ul className="description">
                                          <li>
                                            <Box
                                              component="span"
                                              sx={{
                                                display: "block",
                                                textAlign: "justify",
                                              }}
                                            >
                                              {description.description}
                                            </Box>
                                          </li>
                                        </ul>
                                      </li>
                                    </Box>
                                  )
                                )}
                              </List>
                            </Paper>
                          ))}
                        </div>
                      )}
                    </Container>
                  </Box>

                  <Box sx={{ marginBottom: 10 }}>
                    <Container sx={{ display: "grid", maxWidth: "100%" }}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#3a6bae",
                          paddingLeft: 0,
                          fontFamily: "Lora, sans-serif",
                        }}
                      >
                        SUMMARY
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingBottom: 2,
                          marginTop: 1,
                          display: "block",
                          textAlign: "justify",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {storedStudentData.remarks}
                      </Typography>
                    </Container>
                  </Box>
                  <Container sx={{ paddingTop: 3 }}>
                    <ImageContainer src={footer} alt="footer" />
                  </Container>
                </Box>
                {/* <Container sx={{ paddingTop: 3 }}>
                  <ImageContainer src={footer} alt="footer" />
                </Container> */}
              </Box>
              <ReportConfirmationAlert
                openConfirmation={openConfirmation}
                setOpenConfirmation={setOpenConfirmation}
                setConfirmAlert={setconfirmedAlert}
              />
            </GlobalWrapper>
          </Container>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default PreviewReport;
