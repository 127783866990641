import React from 'react'
import GlobalDeleteDialog from '../../../../mui/Dialogue/GlobalDeleteDialog';
import { deleteHoliday,getHolidays} from '../../../../../redux/features/holidaysSlice';
import { useSelector } from 'react-redux';


const DeleteHoliday = ({id,year}) => {
    const {loading } = useSelector((state) => state.holidays); //SETTING A STATE TO RENDER ALL THE HOLIDAYS
    return (
        <GlobalDeleteDialog
            title={"Delete Holiday"}
            deleteNote={"Are you sure? you want to delete this Holiday..?"}
            api={deleteHoliday(id)}
            upDateCall={getHolidays(year)}
            loading={loading}
        />
    );
}

export default DeleteHoliday