import { Chip } from "@mui/material";
import { CroEnquiry } from "../../CRM/AdmissionRegister/CroEnquiryLog/CroEnquiry";
import OgaRemarksDetailedView from "./OgaRemarksDetailedView";
import SessionsCompleatedAction from "./SessionsCompleatedAction";
import ShareAndEditAction from "./ShareAndEditAction";
import TotalSessionsPop from "./TotalSessionsPop";
import { Icon } from "@iconify/react";
import { AdmissionStatus } from "../../CRM/AdmissionRegister/AdmissionStatus/AdmissionStatus";

export const ADMSSN_REGSTR_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "studentRegion",
        headerName: "Student Region",
        width: 150,
    },
    {
        field: "admnNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "contactNumber",
        headerName: "Contact Number",
        width: 170,
    },
    {
        field: "dob",
        headerName: "Date of Birth",
        width: 170,
    },
    {
        field: "className",
        headerName: "Class",
        width: 170,
    },
    {
        field: "courseName",
        headerName: "Course",
        width: 200,
    },
    {
        field: "courseEnrolledDate",
        headerName: "Course Enrollment Date",
        width: 170,
    },
    // {
    //     field: "totalSessions",
    //     headerName: "Total Sessions",
    //     width: 170,
    //     renderCell: (params) => <TotalSessionsPop row={params.row} />,
    // },

    // {
    //     field: "sessionsCompleted",
    //     headerName: "Sessions Completed",
    //     width: 150,
    //     renderCell: (params) => <SessionsCompleatedAction row={params.row} />,
    // },
    {
        field: "lastSessionDate",
        headerName: "Last Session Date",
        width: 150,
    },
    // {
    //     field: "oga",
    //     headerName: "OGA Remarks Details view",
    //     width: 150,
    //     align: "center",
    //     renderCell: (params) => <OgaRemarksDetailedView row={params.row} />,
    // },
    {
        field: "totalSalesAmount",
        headerName: "Total Sales Amount",
        width: 150,
    },
    {
        field: "totalFeeCollected",
        headerName: "Total Fee Collected",
        width: 150,
    },
    {
        field: "courseInactiveDate",
        headerName: "Course Inactive Date",
        width: 150,
    },
    // {
    //     field: "profile",
    //     headerName: "Profile",
    //     width: 200,
    //     renderCell: (params) => <ShareAndEditAction row={params.row} />,
    // },

    // {
    //     field: "croEnquiryLog",
    //     headerName: "Cro Enquiry Log",
    //     width: 150,
    //     renderCell: (params) => <CroEnquiry {...params.row} />,
    // },
    {
        field: "croNotes",
        headerName: "CRO Notes",
        width: 150,
    },
    {
        field: "admnFeeStatus",
        headerName: "Admission Fee",
        width: 150,
        renderCell: (params) => {
            return (
                <>
                    <Chip
                        size="small"
                        color={
                            params.row.admnFeeStatus === "Paid"
                                ? "success"
                                : "warning"
                        }
                        label={params.row.admnFeeStatus}
                        variant="outlined"
                        icon={
                            params.row.admnFeeStatus === "Paid" ? (
                                <Icon icon="mdi:tick" />
                            ) : (
                                <Icon
                                    icon="basil:cross-outline"
                                    height="20"
                                    width="20"
                                />
                            )
                        }
                    />
                </>
            );
        },
    },
    // {
    //     field: "courseStatus",
    //     headerName: "Admission Status",
    //     width: 200,
    //     renderCell: (params) => (
    //         <AdmissionStatus
    //             params={params.row.courseStatus}
    //             courseEnrollmentId={params.row.courseEnrollmentId}
    //         />
    //     ),
    // },
    {
        field: "mappingStatus",
        headerName: "Mapping Status",
        width: 150,
    },
    {
        field: "actions",
        headerName: "Action",
    },
    {
        field: "ledgerView",
        headerName: "ledger View",
        width: 150,
    },
    {
        field: "isFeeStructureAvailable",
        headerName: "Fee Structure",
        width: 150,
    },
    {
        field: "ogaRetainCallStatus",
        headerName: "Oga Retain call",
        width: 150,
    },
];
