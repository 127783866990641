export const CAMPAIGN_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
        width: 90,
    },
    {
        field: "campaignName",
        headerName: "Campaign Name",
        flex: 1,
    },
    {
        field: "admissionConfirmed",
        headerName: "Admission Confirmed",
        flex: 1,
    },
    {
        field: "assessmentConfirmed",
        headerName: "Assessment Confirmed",
        flex: 1,
    },

    {
        field: "alreadyAssessed",
        headerName: "Already Assessed",
        flex: 1,
    },

    {
        field: "admissionCancelled",
        headerName: "Admission Cancelled",
        flex: 1,
    },

    {
        field: "admissionWithheld",
        headerName: "Assessment Withheld",
        flex: 1,
    },

    {
        field: "waitingforResponse",
        headerName: "Waiting For Response",
        flex: 1,
    },

    {
        field: "feeIssue",
        headerName: "Fee Issue",
        flex: 1,
    },

    {
        field: "needToCallBack",
        headerName: "Need To Call Back",
        flex: 1,
    },

    {
        field: "postponed",
        headerName: "Postponed",
        flex: 1,
    },

    {
        field: "wrongNumber",
        headerName: "Wrong Number",
        flex: 1,
    },

    {
        field: "callNotAttended",
        headerName: "Call Not Attended",
        flex: 1,
    },

    {
        field: "noResponse",
        headerName: "No Response",
        flex: 1,
    },
    {
        field: "notReachable",
        headerName: "Not Reachable",
        flex: 1,
    },
    {
        field: "notAttended",
        headerName: "Not Attended",
        flex: 1,
    },
    {
        field: "notInterestedForAssessment",
        headerName: "Not Interested For Assessment",
        flex: 1,
    },
];
