import MytaskStatus from "./MyTaskStatus";
import { UpdateTaskActions } from "./UpdateTaskActions";
export const MYTASK_TABLE_HEAD = [
    {
        field: "serialNumber",
        flex: 0,
        minWidth: 30,
        headerName: "slno",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskType",
        flex: 0.1,
        minWidth: 90,
        headerName: "Type",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "task",
        flex: 0.1,
        minWidth: 90,
        headerName: "task",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskPriority",
        flex: 0.1,
        minWidth: 90,
        headerName: "priority",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskDeadLineDate",
        flex: 0.1,
        minWidth: 90,
        headerName: "Deadline Date",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskDeadLineTime",
        flex: 0.1,
        minWidth: 90,
        headerName: "Deadline Time",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskStatus",
        flex: 0.1,
        minWidth: 90,
        headerName: "Current Status",
        type: "srting",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => MytaskStatus(params),
    },
    {
        field: "Action",
        flex: 0.1,
        minWidth: 90,
        headerName: "Action",
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => UpdateTaskActions(params),
    },
];
