import React, { useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";

import {
  AddButton,
  CancelButton,
  SaveButton,
  UpdateButton,
} from "../../../../components/mui/Buttons/Buttons";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Stack, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  applyCompWorkReq,
  getCompWorkReq,
  updateCompWork,
} from "../../../../redux/features/leaveSlice";
import { useSnackbar } from "notistack";
import { savePaginationData } from "../../../../redux/features/globalDatagridSlice";

const AddCompWorkReq = ({ update, params, paginationData }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(params ? dayjs(params.date) : dayjs());
  const [remarks, setRemarks] = useState(params?.user_remarks || "");
  const { loading } = useSelector((state) => state.leave);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      date: value,
      reason: remarks,
    };
    if (params) {
      const updatedata = {
        id: params.id,
        date: value,
        reason: remarks,
      };
      dispatch(updateCompWork(updatedata)).then((res) => {
        if (res.payload.status === "failed" || res.payload.status === "error") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          dispatch(getCompWorkReq(update));
          dispatch(
            savePaginationData({ ...paginationData, openAnchor: false })
          );
          setOpen(false);
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    } else {
      dispatch(applyCompWorkReq(data)).then((res) => {
        if (res.payload.status === "failed" || res.payload.status === "error") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          dispatch(getCompWorkReq(update));
          setOpen(false);
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    }
  };
  return (
    <div>
      <GlobalDialog
        title="Request for Compensatory Work"
        open={open}
        setOpen={setOpen}
        fullWidth
        maxWidth={"sm"}
        closeButton={true}
        popupButton={
          <>
            {params ? (
              <UpdateButton
                disabled={params.status === 0 ? false : true}
                size="small"
                action={() => setOpen(true)}
              />
            ) : (
              <AddButton action={() => setOpen(true)}>Apply for Work</AddButton>
            )}
          </>
        }
        content={
          <>
            <Box>
              <form>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label="Date"
                      required
                      value={value}
                      onChange={(newValue) => setValue(newValue)}
                    />
                  </LocalizationProvider>
                </Box>
                <Box mt={2}>
                  <TextField
                    fullWidth
                    multiline
                    required
                    rows={3}
                    label="Remarks"
                    value={remarks}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                  <Stack direction={"row"} spacing={1}>
                    <CancelButton action={() => setOpen(false)}>
                      Cancel
                    </CancelButton>
                    <SaveButton
                      disabled={remarks ? false : true}
                      type="submit"
                      action={handleSubmit}
                    >
                      {loading ? "Please wait..." : "Save"}
                    </SaveButton>
                  </Stack>
                </Box>
              </form>
            </Box>
          </>
        }
      />
    </div>
  );
};

export default AddCompWorkReq;
