export const EMPLOYEES_TABLE_HEAD = [
    {
        field: "serialNumber",
        flex: 0.4,
        minWidth: 90,
        headerName: "No.",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "employeeName",
        flex: 1,
        minWidth: 90,
        headerName: "Employee Name",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "position",
        flex: 1,
        minWidth: 150,
        headerName: "Position",
        type: "string",
        headerAlign: "left",
        align: "left",
        // editable: true,
    },
];
