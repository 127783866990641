import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const request = (options) => {
    //Request header options
    let headers;
    if(options.headers){
        headers=options.headers
    }else{
         headers = {
            "Content-Type": "application/json",
        };
    }
   

    //Assigning authorization token to the header object
    if (localStorage.getItem("cred")) {
        let token = JSON.parse(localStorage.getItem("cred")).token;

        Object.assign(headers, { Authorization: "Bearer " + token });
    }

    //Assigning header to options
    Object.assign(options, { headers });


    //Actual axios request
    return axios(options)
        .then((res) => {
            // console.log("response in request", res.data);
            return res.data;
        })
        .catch((err) => {
            console.log("error in request", err.response);
            return err.response.data;
        });
};

export default request;
