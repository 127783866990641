import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { LogButton } from "../../../../mui/Buttons/Buttons";
import { Box, Checkbox, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSubjectWiseAssessmentData } from "../../../../../redux/features/ogdSlice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NoDataFound from "../../../../NodataIndication/NoDataFound";

const CheckBox = ({ value }) => {
  return (
    <Checkbox
      color="success"
      checked={value}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
};

const OgaSubjectWiseAssessmentData = ({ params }) => {
  const [open, setOpen] = useState(false);
  const { subjectWiseAssessmentdata, loading } = useSelector(
    (state) => state.ogd
  );
  const dispatch = useDispatch();
  return (
    <GlobalDialog
      title="OGA Subject Wise Assessment Data"
      open={open}
      setOpen={setOpen}
      closeButton={true}
      fullWidth
      maxWidth={"lg"}
      popupButton={
        <LogButton
          size="small"
          action={() => {
            dispatch(getSubjectWiseAssessmentData(params.sroAssignmentId));
            setOpen(true);
          }}
        />
      }
      content={
        <>
          <Box>
            <Paper elevation={3}>
              <Box sx={{ width: "100%", padding: 0 }}>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Subject Name</TableCell>
                        <TableCell align="right">Level1</TableCell>
                        <TableCell align="right">Level2</TableCell>
                        <TableCell align="right">Level3</TableCell>
                        <TableCell align="right">Level4</TableCell>
                        <TableCell align="right">Level5</TableCell>
                        <TableCell align="right">NA</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subjectWiseAssessmentdata.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.subject_name}
                          </TableCell>
                          {/* <TableCell align="right">{row.level1}</TableCell> */}
                          <TableCell align="right">
                            <CheckBox value={row.level1 == 1 ? true : false} />
                          </TableCell>
                          <TableCell align="right">
                            <CheckBox value={row.level2 == 1 ? true : false} />
                          </TableCell>
                          <TableCell align="right">
                            <CheckBox value={row.level3 == 1 ? true : false} />
                          </TableCell>
                          <TableCell align="right">
                            <CheckBox value={row.level4 == 1 ? true : false} />
                          </TableCell>
                          <TableCell align="right">
                            <CheckBox value={row.level5 == 1 ? true : false} />
                          </TableCell>
                          <TableCell align="right">
                            <CheckBox
                              value={row.not_assessed == 1 ? true : false}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {subjectWiseAssessmentdata.length == 0 ? <NoDataFound /> : null}
              </Box>
            </Paper>
          </Box>
        </>
      }
    />
  );
};

export default OgaSubjectWiseAssessmentData;
