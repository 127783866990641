export const CRO_FEE_UPDATE_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "name",
        headerName: "Name",
        flex: 1,
    },
    {
        field: "contactNo",
        headerName: "Contact No",
        flex: 1,
    },
    {
        field: "place",
        headerName: "place",
        flex: 1,
    },
    {
        field: "class",
        headerName: "Class",
        flex: 1,
    },
    {
        field: "Course",
        headerName: "Course",
        flex: 1,
    },
    {
        field: "croStatus",
        headerName: "Cro status",
        flex: 1,
    },
    {
        field: "action",
        headerName: "Action",
        flex: 1,
    },
];
