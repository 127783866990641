import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import { TotalSessions } from "../../../../components/datagrid/CRM/AdmissionRegister/TotalSession/TotalSessions";
import { Icon } from "@iconify/react";
import { Profile } from "../../../../components/datagrid/CRM/AdmissionRegister/StudentProfile/Profile";
import { AssessmentDetails } from "../../../../components/datagrid/CRM/AdmissionRegister/AssessmentDetails/AssessmentDetails";
import { CroEnquiry } from "../../../../components/datagrid/CRM/AdmissionRegister/CroEnquiryLog/CroEnquiry";
import { AdmissionStatus } from "../../../../components/datagrid/CRM/AdmissionRegister/AdmissionStatus/AdmissionStatus";

export const ActionsView = ({ data }) => {
    return (
        <div>
            <Box padding={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Total Sessions
                                        </Typography>
                                    </ListItemText>
                                    <TotalSessions
                                        courseEnrollmentId={
                                            data.courseEnrollmentId
                                        }
                                        totalSessions={data.totalSessions}
                                    />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Student Profile
                                        </Typography>
                                    </ListItemText>

                                    <Profile params={data.courseEnrollmentId} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    {/* ---------------------------------------------------------------------------------- */}
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Cro Enquiry Log
                                        </Typography>
                                    </ListItemText>
                                    <CroEnquiry params={data} />
                                </ListItem>

                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Admission Status
                                        </Typography>
                                    </ListItemText>

                                    <AdmissionStatus
                                        params={data.courseStatus}
                                        courseEnrollmentId={
                                            data.courseEnrollmentId
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    {/* ----------------------------------------------------------------------------------- */}
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            class Room Materials
                                        </Typography>
                                    </ListItemText>

                                    <AssessmentDetails params={data} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>

                <Box>
                    <Typography variant="h6">CRO Notes</Typography>

                    <TextField
                        label="CRO Notes"
                        variant="filled"
                        multiline
                        rows={4}
                        value={data?.croNotes}
                        fullWidth
                        disabled
                    />
                </Box>
            </Box>
        </div>
    );
};
