import {
  Box,
  Checkbox,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion"; // Import framer-motion

const WishListitem = ({
  data,
  setAvailableCartItems,
  availableCartItems,
  recentwishListItems,
  setTotalCourses,
  totalCourses,
}) => {
  // Handle checkbox changes and data updates
  const handleInputChange = (e, courseId, field) => {
    const { checked } = e.target;

    const updatedCourses = availableCartItems.map((item) => {
      if (item.id === courseId) {
        return { ...item, [field]: checked };
      }
      return item;
    });
    setAvailableCartItems(updatedCourses);
  };

  const handleRecentInputChange = (e, courseId, field) => {
    const { checked } = e.target;

    const updatedCourses = totalCourses.map((item) => {
      if (item.id === courseId) {
        return { ...item, [field]: checked };
      }
      return item;
    });
    setTotalCourses(updatedCourses);
  };

  const handleRemoveCourse = (courseId) => {
    // Remove the course from the data array
    const updatedData = data.filter((item) => item.id !== courseId);
    setAvailableCartItems(updatedData); // Assuming this updates both data and totalCourses
  };

  // Animation settings
  const slideAnimation = {
    initial: { opacity: 0, x: -50 }, // Slide in from left
    animate: { opacity: 1, x: 0 }, // Fade and slide into place
    exit: { opacity: 0, x: 50 }, // Slide out to the right
    transition: { duration: 0.3 }, // Animation duration
  };

  return (
    <Paper sx={{ margin: 1, borderRadius: 4 }}>
      <Table>
        <AnimatePresence>
          {data?.map((item, i) => (
            <motion.tr
              key={item.id} // Use unique key for each row
              initial="initial"
              animate="animate"
              exit="exit"
              transition={slideAnimation.transition}
              variants={slideAnimation}
            >
              <TableCell variant="head" sx={{ padding: 1 }}>
                <Stack direction={"row"} spacing={1}>
                  <Box>
                    <Checkbox
                      checked={item.wishList || false}
                      onChange={(e) =>
                        handleInputChange(e, item.id, "wishList")
                      }
                    />
                  </Box>
                  <Box pt={1}>{item.courseName}</Box>
                </Stack>
              </TableCell>
            </motion.tr>
          ))}
        </AnimatePresence>
      </Table>
      {/* ---------------------------------------------------------------------- */}
      <Table>
        <AnimatePresence>
          {recentwishListItems?.map((item, i) => (
            <motion.tr
              key={item.id} // Use unique key for each row
              initial="initial"
              animate="animate"
              exit="exit"
              transition={slideAnimation.transition}
              variants={slideAnimation}
            >
              <TableCell variant="head" sx={{ padding: 1 }}>
                <Stack direction={"row"} spacing={1}>
                  <Box>
                    <Checkbox
                      checked={item.wishList || false}
                      onChange={(e) =>
                        handleRecentInputChange(e, item.id, "wishList")
                      }
                    />
                  </Box>
                  <Box pt={1}>{item.courseName}</Box>
                </Stack>
              </TableCell>
            </motion.tr>
          ))}
        </AnimatePresence>
      </Table>
    </Paper>
  );
};

export { WishListitem };
