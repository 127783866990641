import React, { useEffect, useRef, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import GlobalDialog from '../../mui/Dialogue/GlobalDialog'
import { AddButton, CancelButton, SubmitButton, UpdateButton } from '../../mui/Buttons/Buttons'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import { Autocomplete, Box, Button, ImageList, ImageListItem, Stack, TextField, Typography } from '@mui/material'
import { createCourse, getAllCourses, getAllProjects, updateCourse } from '../../../redux/features/courseSlice'
import GlobalSelectField from '../../mui/GlobalSelectField'
import { compressImage } from '../../../utils/constants/ImageCompress'
import { savePaginationData } from '../../../redux/features/globalDatagridSlice'

const courseType = [
  { id: 0, value: 'Induvidual' },
  { id: 1, value: 'Group' },
]
const AddOrUpdateCourse = ({ row }) => {

  const [compressDelay, setCompressDelay] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { loading, projects } = useSelector((store) => store.course)
  const fileInputRef = useRef(null)
  const [imageGallery, setImageGallery] = useState(false)
  const [itemData, setItemData] = useState([]);
  const[imageChoosen,setImageChoosen]=useState(false)
  

  useEffect(() => {
    if (open) {
      dispatch(getAllProjects())
    }
  }, [open])

  const images = require.context('../../../../src/img/courses/', false, /\.(png|jpe?g|svg|webp)$/);
  const imagePaths = images.keys();
  
  useEffect(() => {
    const imageData = imagePaths.map((imagePath, index) => ({
      img: images(imagePath), //IT RETURN IMG SRC
      title: `Course Image ${index + 1}`,
    }));
    setItemData(imageData);
  }, [imageGallery]);

  const initialValues = {
    isUpdate: row ? true : false,
    projectId: row?.projectId || '',
    courseName: row?.courseName || '',
    courseOutcomes: row?.courseOutcomes || '',
    batchAvailability: row ? row.batchAvailability : '',
    pricePerSession: row?.price_per_session || '',
    file: '',
  }

  const schema = Yup.object({
    pricePerSession: Yup.number().required('Price Required'),
    projectId: Yup.number().required('Project Required'),
    batchAvailability: Yup.number().required('Course Type Required'),
    courseName: Yup.string().required('Course Name Required'),
    courseOutcomes: Yup.string().required('Description required'),
    file: Yup.mixed().when('isUpdate', {
      is: false,
      then: Yup.mixed().required('Course Image is required'),
      otherwise: Yup.mixed().nullable(),
    }),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      if (row) {
        dispatch(updateCourse({ ...values, id: row.id })).then((res) => {
          if (res.payload.status === 'error') {
            enqueueSnackbar(res.payload.message, {
              variant: 'error',
            })
          } else if (res.payload.status === 'success') {
            handleCancel()
            dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
            dispatch(getAllCourses(paginationData))
            enqueueSnackbar(res.payload.message, {
              variant: 'success',
            })
          }
        })
      } else {
        dispatch(createCourse(values)).then((res) => {
          if (res.payload.status === 'error') {
            enqueueSnackbar(res.payload.message, {
              variant: 'error',
            })
          } else if (res.payload.status === 'success') {
            handleCancel()
            enqueueSnackbar(res.payload.message, {
              variant: 'success',
            })
            dispatch(getAllCourses(paginationData))
          }
        })
      }
    },
  })

  const handleCancel = () => {
    setOpen(false)
    setImageChoosen(false)
    formik.resetForm()
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleFileChange = async (e,imagePath = null) => {
    let file;
    if(imagePath){
      const response = await fetch(imagePath);
      const blob = await response.blob();
      file = new File([blob], "course-image.png", { type: blob.type });
      setImageChoosen(true)
    }else{
      file = e.currentTarget.files[0];
    }
    setCompressDelay(true)
    const compressed = await compressImage(file, 0.002)
    setCompressDelay(false)
    formik.setFieldValue('file', compressed)
  }

  const handleProjectChange = (e, option) => {
    formik.setFieldValue('projectId', option.id)
  }


  return (
    <GlobalDialog
      maxWidth={'sm'}
      fullWidth
      title={row ? 'Update Course' : 'Add Course'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={
        <>
          {row ? (
            <UpdateButton
              //  disabled={row.status !== 'pending'}
              size="small"
              action={() => setOpen(true)}>
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size="large"
              action={() => {
                setOpen(true)
              }}>
              Add Course
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Stack gap={1}>
                <Box>
                  <Autocomplete
                    disabled={loading}
                    name="projectId"
                    options={projects}
                    getOptionLabel={(option) => option.name || ''}
                    value={projects.find((pro) => pro.id == formik.values.projectId) || null}
                    onChange={handleProjectChange}
                    renderInput={(params) => (
                      <TextField
                        disabled
                        fullWidth
                        autoComplete="off"
                        {...params}
                        label="Select Project"
                        error={formik.touched.projectId && Boolean(formik.errors.projectId)}
                        helperText={formik.touched.projectId && formik.errors.projectId}
                      />
                    )}
                  />
                </Box>
                <TextField
                  name="courseName"
                  fullWidth
                  type="text"
                  value={formik.values.courseName}
                  label="Course Name"
                  error={formik.touched.courseName && Boolean(formik.errors.courseName)}
                  helperText={formik.touched.courseName && formik.errors.courseName}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                <TextField
                  name="courseOutcomes"
                  fullWidth
                  type="text"
                  value={formik.values.courseOutcomes}
                  label="Description"
                  error={formik.touched.courseOutcomes && Boolean(formik.errors.courseOutcomes)}
                  helperText={formik.touched.courseOutcomes && formik.errors.courseOutcomes}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                <TextField
                  name="pricePerSession"
                  fullWidth
                  type="number"
                  value={formik.values.pricePerSession}
                  label="Price per session"
                  error={formik.touched.pricePerSession && Boolean(formik.errors.pricePerSession)}
                  helperText={formik.touched.pricePerSession && formik.errors.pricePerSession}
                  onChange={formik.handleChange}
                  autoComplete="off"
                />
                <GlobalSelectField
                  options={{
                    formik: formik,
                    label: 'Course Type',
                    name: 'batchAvailability',
                    data: courseType,
                    keys: {
                      id: 'id',
                      value: 'value',
                    },
                    sx: {
                      width: '100%',
                    },
                  }}
                />
                {/* {row && (
                <Box><ClickToShowImage url={row.img}/></Box>
                )} */}
                <Box>
                  <Typography sx={{ pl: 1.7, color: '#697077' }}>
                    {row ? 'Replace Course Image' : 'Course Image'}
                  </Typography>
                 {!imageChoosen &&(
                   <TextField
                   name="file"
                   disabled={compressDelay}
                   onChange={handleFileChange}
                   inputRef={fileInputRef}
                   type="file"
                   error={formik.touched.file && Boolean(formik.errors.file)}
                   helperText={formik.touched.file && formik.errors.file}
                 />
                 )}
                </Box>
                <Box>
                  {/* <Button variant='outlined' onClick={() => setImageGallery((prev) => !prev)}> {imageGallery ? "Close Course images" :"View Course Images"}</Button> */}
                  {imageGallery && (
                    <Box>
                       <ImageList sx={{ width: "100%", height: "auto" }} cols={5} rowHeight={164}>
                        {itemData.map((item) => (
                          <ImageListItem key={item.img}>
                            <img className='image-item'
                            src={item.img}
                            style={{ width: '100px', height: '100px', objectFit: 'contain' }}
                            alt={item.title}
                            onClick={() => {
                              handleFileChange(null, item.img)
                              setImageGallery(false)
                              fileInputRef.current.value = 'image choosen'
                            }}
                             />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Box>
                  )}
                </Box>
              </Stack>

              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Stack direction={'row'} spacing={1}>
                    <CancelButton action={handleCancel}>Cancel</CancelButton>
                    {row ? (
                      <UpdateButton disabled={loading || compressDelay} type="submit">
                        {loading ? 'Updating...' : 'Update'}
                      </UpdateButton>
                    ) : (
                      <SubmitButton disabled={loading || compressDelay} type="submit">
                        {loading ? ' Please Wait' : 'Save'}
                      </SubmitButton>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Form>
          </FormikProvider>
        </>
      }
    />
  )
}

export default AddOrUpdateCourse
