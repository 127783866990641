import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: "modal",
    initialState: {
        isOpen: false,
        variant: "",
        type: "",
        title: "",
        content: "",
        buttonText: "",
        id: "",
    },
    reducers: {
        openModal(state, action) {
            state.type = action.payload.type;
            state.variant = action.payload.variant;
            state.title = action.payload.title;
            state.content = action.payload.content;
            state.buttonText = action.payload.buttonText;
            state.id = action.payload.id;
            state.isOpen = true;
        },
        closeModal(state) {
            state.isOpen = false;
            state.type = "";
            state.variant = "";
            state.title = "";
            state.content = "";
            state.buttonText = "";
            state.id = "";
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
