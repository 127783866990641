import React from "react";
import { Route, Routes } from "react-router-dom";
import EditProfile from "../../components/profile/EditProfile";
import AccSettings from "../../views/user/Dashboard/AccSettings";
import Profile from "../../views/user/Dashboard/Profile";
import Page404 from "../../views/user/Auth/Page404";

const MenuRouter = () => {
    return (
        <Routes>
            <Route path="profile" element={<Profile />} />
            <Route path="edit" element={<EditProfile />} />
            <Route path="settings" element={<AccSettings />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default MenuRouter;
