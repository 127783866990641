import { Box, Grid } from "@mui/material";
import React from "react";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";

export const Details = ({ data }) => {
    return (
        <div>
            {" "}
            <Box>
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Mobile" value={data?.mobile} />
                                <Rows label="IFSC" value={data?.ifsc} />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows
                                    label="Account No"
                                    value={data?.accountNo}
                                />
                                <Rows label="pan" value={data?.pan} />
                            </>
                        }
                    />
                </Grid>
            </Box>
        </div>
    );
};
