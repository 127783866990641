import React, { useState } from "react";
import Page from "../../../components/Page";
import { Box, Paper, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { TRAINERS_EVALUATION_TBL_HEAD } from "../../../components/datagrid/myworkspace/ta/TrainersEvaluationTableHead";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { DataGrid } from "@mui/x-data-grid";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import BasicButton from "../../../components/mui/Button";

import PopUps from "../../../components/datagrid/myworkspace/cro/PopUps";
import AddMouForm from "../../../components/datagrid/myworkspace/ta/AddMouForm";

const TrainersEvaluation = () => {
    const [gridSize, setGridSize] = useState(5);
    const [open, setOpen] = useState(false);

    const data = [
        {
            serialNumber: 1,
            trainerId: "TRN001",
            nameOfCandidate: "John Doe",
            emailId: "john.doe@example.com",
            mobileNo: "123-456-7890",
            Qualification: "Bachelor's Degree",
            demovideoUploadedOn: "2023-08-01",
            demoVideoEvaluation: "Excellent",
        },
        {
            serialNumber: 2,
            trainerId: "TRN002",
            nameOfCandidate: "Jane Smith",
            emailId: "jane.smith@example.com",
            mobileNo: "987-654-3210",
            Qualification: "Master's Degree",
            demovideoUploadedOn: "2023-08-02",
            demoVideoEvaluation: "Good",
        },
        {
            serialNumber: 3,
            trainerId: "TRN003",
            nameOfCandidate: "Michael Johnson",
            emailId: "michael.johnson@example.com",
            mobileNo: "555-123-4567",
            Qualification: "Ph.D. in Physics",
            demovideoUploadedOn: "2023-08-03",
            demoVideoEvaluation: "Average",
        },
        {
            serialNumber: 4,
            trainerId: "TRN004",
            nameOfCandidate: "Emily Brown",
            emailId: "emily.brown@example.com",
            mobileNo: "222-333-4444",
            Qualification: "Bachelor's Degree",
            demovideoUploadedOn: "2023-08-01",
            demoVideoEvaluation: "Good",
        },
        {
            serialNumber: 5,
            trainerId: "TRN005",
            nameOfCandidate: "William Davis",
            emailId: "william.davis@example.com",
            mobileNo: "999-888-7777",
            Qualification: "Master's Degree",
            demovideoUploadedOn: "2023-08-02",
            demoVideoEvaluation: "Excellent",
        },
    ];

    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Trainer Evaluation
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                        }}
                    ></Box>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                p: 2,
                                gap: 2,
                                justifyContent: "right",
                            }}
                        >
                            <BasicButton
                                variant="contained"
                                sx={{
                                    display: "flex",
                                    gap: 1,
                                }}
                                action={() => setOpen(true)}
                            >
                                Add Trainer
                            </BasicButton>
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        label: "Course",
                                        sx: {
                                            width: "300px",
                                        },
                                    }}
                                />
                            </Box>
                        </Box>

                        <PopUps
                            open={open}
                            setOpen={setOpen}
                            component={AddMouForm}
                        />

                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                rows={data}
                                columns={TRAINERS_EVALUATION_TBL_HEAD}
                                components={{ Toolbar: CustomGridToolbar }}
                                autoHeight
                                sx={{
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1,
                                    },
                                }}
                                getRowId={(row) => row.serialNumber}
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default TrainersEvaluation;
