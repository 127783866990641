import { memo } from "react";
import { DepartmentEmpDetailsPopUp } from "./DepartmentEmpDetailsPopUp";
import DashboardWidgetSummaryCard from "../../../../components/Dashboard/GlobalComponents/DashboardWidgetSummaryCard";

const DepartmentEmpDetailsPopUpView = memo((item) => {
    return <DepartmentEmpDetailsPopUp item={item} />;
});
const DashboardWidgetSummaryCardView = memo(({ icon, total, title, color }) => {
    return (
        <DashboardWidgetSummaryCard
            icon={icon}
            total={total}
            title={title}
            color={color}
        />
    );
});

export { DepartmentEmpDetailsPopUpView, DashboardWidgetSummaryCardView };
