import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Box } from "@mui/material";

const MotionContainer = ({ delay, children }) => {
  const keyframesExample = {
    hidden: { opacity: 0, x: -200 },
    halfway: { opacity: 0.5, x: 100 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 }, // Define exit animation here
  };

  const transitionSettings = {
    duration: delay ? delay : 0.7,
    ease: "easeInOut",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit" // Apply exit animation here
          variants={keyframesExample}
          transition={transitionSettings}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default MotionContainer;
