import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Box, Stack, TextField } from "@mui/material";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import DatePickerCustom from "../../../../DatePicker/DatePickerCustom";
import dayjs from "dayjs";
import {
  addHoliday,
  getHolidays,
  updateHoliday,
} from "../../../../../redux/features/holidaysSlice";
import { useSnackbar } from "notistack";

const AddOrUpdateHoliday = ({ params, year }) => {
  const [open, setOpen] = useState(false);
  const { holidays, loading } = useSelector((state) => state.holidays);
  const [value, setValue] = useState(dayjs(params?.holiday_date));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const Schema = Yup.object().shape({
    holidayName: Yup.string().required("Holiday Name is required"),
    holidayDate: Yup.date().required("Date is required")
  });

  const formik = useFormik({
    initialValues: {
      holidayName: params ? params.holiday_name : "",
      holidayDate: params ? value : dayjs(),
      id: params ? params.holiday_id : "",
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      const valuesModified = {
        ...values,
        holidayDate: values.holidayDate.format("YYYY-MM-DD HH:mm:ss"),//DAY JS TIME FORMAT CHANGED TO TIME STAMP
      };
      if (params) {
        dispatch(updateHoliday(valuesModified)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getHolidays(year));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(addHoliday(valuesModified)).then((res) => {
          if (res.payload.status === "failed" || res.payload.status === "error") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getHolidays(year));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });

  return (
    <GlobalDialog
      title={"Add"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <>
          {params ? (
            <UpdateButton
              size="small"
              action={() => {
                setOpen(true);
              }}
            >
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size="large"
              action={() => {
                setOpen(true);
              }}
            >
              Add Holiday
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <Box>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Stack gap={2}>
                  <DatePickerCustom
                    value={formik.values.holidayDate}
                    setValue={(newValue) =>
                      formik.setFieldValue("holidayDate", newValue)
                    }
                    error={
                      formik.touched.holidayDate &&
                      Boolean(formik.errors.holidayDate)
                    }
                    helperText={formik.errors.holidayDate}
                    viewDetails={["year", "month", "day"]}
                    fullWidth={true}
                  />
                  <TextField
                    variant="outlined"
                    name="holidayName"
                    label="Holiday Name"
                    onChange={formik.handleChange}
                    value={formik.values.holidayName}
                    error={
                      formik.touched.holidayName &&
                      Boolean(formik.errors.holidayName)
                    }
                    helperText={formik.errors.holidayName}
                    autoComplete="off"
                  />
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <CancelButton action={() => setOpen(false)}>
                      Cancel
                    </CancelButton>
                    {params ? (
                      <UpdateButton type="submit">
                        {loading ? "Updating..." : "Update"}
                      </UpdateButton>
                    ) : (
                      <SubmitButton type="submit">
                        {loading ? "Adding..." : "Add"}
                      </SubmitButton>
                    )}
                  </Stack>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </>
      }
    ></GlobalDialog>
  );
};

export default AddOrUpdateHoliday;
