import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const ImageContainer = ({ src, alt, containerStyle, imageStyle }) => {
    return (
        <Box
            sx={{
                width: "100%", // Adjusts to the width of its parent
                height: "auto", // Adjusts to maintain aspect ratio
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",

                ...containerStyle,
            }}
        >
            <Box
                component="img"
                src={src}
                alt={alt}
                sx={{
                    width: "100%",
                    height: "auto", // Maintains aspect ratio
                    objectFit: "cover", // Ensures the image covers the entire container
                    borderRadius: "20px",
                    ...imageStyle,
                }}
            />
        </Box>
    );
};

ImageContainer.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    containerStyle: PropTypes.object,
    imageStyle: PropTypes.object,
};

export default ImageContainer;
