import React from "react";
import { courseAvailableListing } from "../../redux/features/mastersSlice";
import { StatusToggleSwitch } from "../StatusToggleSwitch";

export const CourseListingStatus = ({ params }) => {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.is_listed}
                api={courseAvailableListing(params.course_available_id)}
            />
        </div>
    );
};
