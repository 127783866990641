import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { RejectButton } from '../../../mui/Buttons/Buttons'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'

const RejectGlobal = ({isDisabled,loading,upDateCall,statusChangeApiCall}) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch=useDispatch()
    const { paginationData } = useSelector((state) => state.globalDataGrid)
    const rejectAction=()=>{
        dispatch(statusChangeApiCall).then((res) => {
            if (res.payload.status === 'error') {
              enqueueSnackbar(res.payload.message, {
                variant: 'error',
              })
            } else if (res.payload.status === 'success') {
              dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
              dispatch(upDateCall)
              enqueueSnackbar(res.payload.message, {
                variant: 'success',
              })
            }
          })
    }
  return (
    <RejectButton disabled={isDisabled || loading} action={rejectAction}/>
  )
}

export default RejectGlobal