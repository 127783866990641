import CreateUpdateLevel from "./CreateUpdateLevel";
import DeleteLevel from "./DeleteLevel";
import LevelStatusSwitch from "./LevelStatusSwitch";

export const LEVEL_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "level_name",
        headerName: "Level Name",
        width: 200,
    },
    {
        field: "matrix_name",
        headerName: "Matrix Name",
        width: 200,
    },
    {
        field: "level_created_at",
        headerName: "Level created At",
        width: 200,
    },
    {
        field: "Status",
        headerName: "Status",
        width: 120,
        renderCell: (params) => <LevelStatusSwitch params={params.row} />,
    },
    {
        field: "Update",
        headerName: "Update",
        width: 120,
        renderCell: (params) => <CreateUpdateLevel params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 120,
        renderCell: (params) => (
            <DeleteLevel
                id={params.row.level_id}
                childFlag={params.row.has_child_element}
            />
        ),
    },
];
