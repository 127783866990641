import React from "react";
import GlobalDialog from "../../../../../components/mui/Dialogue/GlobalDialog";
import { Typography } from "@mui/material";
import {
  CancelButton,
  SaveButton,
} from "../../../../../components/mui/Buttons/Buttons";

const ReportConfirmationAlert = ({ setOpenConfirmation, openConfirmation ,setConfirmAlert}) => {
  return (
    <GlobalDialog
      title="Alert...!"
      open={openConfirmation}
      setOpen={setOpenConfirmation}
      closeButton={true}
      fullWidth
      maxWidth={"sm"}
      content={
        <>
          <Typography>
            Are you sure you want to generate the PDF? Once the PDF is
            generated, you should save it. Please do not cancel the preview
            page.
          </Typography>
        </>
      }
      actionButton={
        <>
          <CancelButton action={() => setOpenConfirmation(false)}>
            Cancel
          </CancelButton>
          <SaveButton action={()=>setConfirmAlert(true)}>Confirm</SaveButton>
        </>
      }
    />
  );
};

export default ReportConfirmationAlert;
