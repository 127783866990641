import React from "react";
import TrainersPortal from "../TrainersPortal/TrainersPortal";

export const TrainersForCros = () => {
    return (
        <div>
            <TrainersPortal />
        </div>
    );
};
