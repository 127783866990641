import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    users: [],
    details: [],
    data: [],
    preData: [],
    loading: false,
    error: "",
    callData: [],
    totalExpectedSessions: [],
    admissionRegister: [],
    SubjectSessionCount: [],
    studentProfile: [],
    croEnquiryLog: [],
    preEvaluationReport: [],
    AssignedCros: [],
    coursesPreData: [],
    terminationClearance: [],
    courseTcDetails: [],
    courseInactiveReasons: [],
    admissionRegData: [],
    dataId: [],
    wiseAdmin: [],
};

export const getNewAdmissions = createAsyncThunk(
    "crm/getNewAdmissions",
    async (date) => {
        const res = await apiRequest({
            method: "get",
            url: `lead/getCrmLeads/${date}`,
        });
        return res;
    }
);

export const getDetailedOgaRemarks = createAsyncThunk(
    " crm/getDetailedOgaRemarks",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `lead/getDetailedOgaRemarks/${id}`,
        });
        return res;
    }
);

export const getCrosPreData = createAsyncThunk(
    "cro/getCrosPreData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "pre-data/getCROs",
        });
        return res;
    }
);

export const assignCro = createAsyncThunk("cro/assignCro", async (data) => {
    const res = await apiRequest({
        method: "put",
        url: `lead/assignCrmLead/${data.croAssignmentId}`,
        data: data,
    });
    return res;
});

export const getExpectedSessions = createAsyncThunk(
    "cro/getExpectedSessions",
    async (data) => {
        const res = await apiRequest({
            method: "get",
            url: `students/getExpectedSessions/${data.date}/${data.croId}`,
        });
        return res;
    }
);

export const addExpectedSessions = createAsyncThunk(
    "crm/addExpectedSessions",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: "students/addExpectedSessions",
            data: data,
        });
        return res;
    }
);

export const getAdmissionRegister = createAsyncThunk(
    "crm/getAdmissionRegister",
    async (data) => {
        const res = await apiRequest({
            method: "get",
            url: `students/getAdmissionRegister/${data.croId}/${data.courseId}/${data.courseStatusId}`,
        });
        return res;
    }
);

export const getSubjectSessionCount = createAsyncThunk(
    "crm/getSubjectSessionCount",
    async (courseEnrollmentId) => {
        const res = await apiRequest({
            method: "get",
            url: `students/getSubjectSessionCount/${courseEnrollmentId}`,
        });
        return res;
    }
);

export const updateSubjectSessionCount = createAsyncThunk(
    "crm/updateSubjectSessionCount",
    async (data) => {
        const res = await apiRequest({
            method: "put",
            url: `students/updateSubjectSessionCount/${data.subjectEnrollmentId}`,
            data: data,
        });
        return res;
    }
);

export const deleteSubjectEnrollment = createAsyncThunk(
    "crm/deleteSubjectEnrollment",
    async (subjectEnrollmentId) => {
        const res = await apiRequest({
            method: "delete",
            url: `students/deleteSubjectEnrollment/${subjectEnrollmentId}`,
        });
        return res;
    }
);
export const getStudentProfile = createAsyncThunk(
    "crm/getStudentProfile",
    async (admissionNumber) => {
        console.log("courseEnrollmentId",admissionNumber); 
        const res = await apiRequest({
            method: "get",
            url: `students/getStudentProfile/${admissionNumber}`,
        });
        console.log(res);
        
        return res;
    }
);

export const getCroEnquiryLog = createAsyncThunk(
    "crm/getCroEnquiryLog",
    async (courseEnrollmentId) => {
        const res = await apiRequest({
            method: "get",
            url: `students/getCroEnquiryLog/${courseEnrollmentId}`,
        });
        return res;
    }
);

export const getPreEvaluationReport = createAsyncThunk(
    "crm/getPreEvaluationReport",
    async (jqaAssignmentId) => {
        const res = await apiRequest({
            method: "get",
            url: `evaluation/getPreEvaluationReport/${jqaAssignmentId}`,
        });
        return res;
    }
);

export const getAssignedCros = createAsyncThunk(
    "crm/getAssignedCros",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "pre-data/getAssignedCROs",
        });
        return res;
    }
);

export const getCourses = createAsyncThunk("crm/getCourses", async () => {
    const res = await apiRequest({
        method: "get",
        url: "pre-data/getCourses",
    });
    return res;
});

export const getTerminationClearance = createAsyncThunk(
    "crm/getTerminationClearance",
    async (courseEnrollmentId) => {
        const res = await apiRequest({
            method: "get",
            url: `students/getTerminationClearance/${courseEnrollmentId}`,
        });
        return res;
    }
);

export const getCourseTcDetails = createAsyncThunk(
    "crm/getCourseTcDetails",
    async (courseEnrollmentId) => {
        const res = await apiRequest({
            method: "get",
            url: `students/getCourseTcDetails/${courseEnrollmentId}`,
        });
        return res;
    }
);

export const getCourseInactiveReasons = createAsyncThunk(
    "crm/getCourseInactiveReasons",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "pre-data/getCourseInactiveReasons",
        });
        return res;
    }
);

export const updateCourseEnrollmentStatus = createAsyncThunk(
    "crm/updateCourseEnrollmentStatus",
    async (data) => {
        const res = await apiRequest({
            method: "put",
            url: `students/updateCourseEnrollmentStatus/${data.courseEnrollmentId}`,
            data: data,
        });
        return res;
    }
);

export const wiseAdminget = createAsyncThunk(
    "crm/wiseAdminget",
    async (data) => {
        const res = await apiRequest({
            method: "get",
            url: `wiseApp/getAllCros`,
        });
        return res;
    }
);
export const createWiseAdmin = createAsyncThunk(
    "crm/createWiseAdmin",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `wiseApp/createUser`,
            data: data,
        });
        console.log(res);
        return res;
    }
);

const crmSlice = createSlice({
    name: "crm",
    initialState,

    reducers: {
        apiCallData: (state, action) => {
            state.callData = { ...action.payload };
        },
        admissionReg: (state, action) => {
            state.admissionRegData = { ...action.payload };
        },
        storeId: (state, action) => {
            state.dataId = { ...action.payload };
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getNewAdmissions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getNewAdmissions.fulfilled, (state, action) => {
            state.loading = false;
            state.data = formatDataForDataTable(action.payload.data.crmLeads);
        });
        builder.addCase(getNewAdmissions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------getDetailedSroRemarks------------------------------------

        builder.addCase(getDetailedOgaRemarks.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDetailedOgaRemarks.fulfilled, (state, action) => {
            state.loading = false;
            state.details = action.payload.data;
        });
        builder.addCase(getDetailedOgaRemarks.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------------getCrosPreData-----------------------------------------------
        builder.addCase(getCrosPreData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCrosPreData.fulfilled, (state, action) => {
            state.loading = false;
            state.preData = action.payload.data;
        });
        builder.addCase(getCrosPreData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------------------assignCro---------------------------------------
        builder.addCase(assignCro.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(assignCro.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(assignCro.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -------------------------------getExpectedSessions---------------------------------------------
        builder.addCase(getExpectedSessions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getExpectedSessions.fulfilled, (state, action) => {
            state.data = formatDataForDataTable(
                action.payload.data.expectedSessions
            );
            state.totalExpectedSessions =
                action.payload.data.totalExpectedSessions;

            state.loading = false;
        });
        builder.addCase(getExpectedSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------addExpectedSessions------------------------------------
        builder.addCase(addExpectedSessions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addExpectedSessions.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addExpectedSessions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------------------getAdmissionRegister----------------------------------
        builder.addCase(getAdmissionRegister.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAdmissionRegister.fulfilled, (state, action) => {
            state.loading = false;
            state.admissionRegister = formatDataForDataTable(
                action.payload.data.students
            );
            console.log(state.admissionRegister);
        });

        builder.addCase(getAdmissionRegister.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------------------getSubjectSessionCount--------------------
        builder.addCase(getSubjectSessionCount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSubjectSessionCount.fulfilled, (state, action) => {
            state.loading = false;
            state.SubjectSessionCount = formatDataForDataTable(
                action.payload.data.subjects
            );
        });
        builder.addCase(getSubjectSessionCount.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------------updateSubjectSessionCount-------------------------------
        builder.addCase(updateSubjectSessionCount.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            updateSubjectSessionCount.fulfilled,
            (state, action) => {
                state.loading = false;
            }
        );
        builder.addCase(updateSubjectSessionCount.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------------------deleteSubjectEnrollment--------------------------------
        builder.addCase(deleteSubjectEnrollment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteSubjectEnrollment.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(deleteSubjectEnrollment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------------getStudentProfile--------------------------------------
        builder.addCase(getStudentProfile.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getStudentProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.studentProfile = action.payload.data.studentProfile;
        });
        builder.addCase(getStudentProfile.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------------------------getCroEnquiryLog-------------------------------------
        builder.addCase(getCroEnquiryLog.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCroEnquiryLog.fulfilled, (state, action) => {
            state.loading = false;
            state.croEnquiryLog = formatDataForDataTable(
                action.payload.data.enquiryLog
            );
        });
        builder.addCase(getCroEnquiryLog.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -------------------------getPreEvaluationReport--------------------------
        builder.addCase(getPreEvaluationReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPreEvaluationReport.fulfilled, (state, action) => {
            state.loading = false;
            state.preEvaluationReport = action.payload.data;
        });
        builder.addCase(getPreEvaluationReport.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------------getAssignedCros--------------------------------------
        builder.addCase(getAssignedCros.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAssignedCros.fulfilled, (state, action) => {
            state.loading = false;
            state.AssignedCros = [
                { id: -1, name: "All" },
                ...action.payload.data.cros,
            ];
        });
        builder.addCase(getAssignedCros.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------getCourses-------------------------------------
        builder.addCase(getCourses.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCourses.fulfilled, (state, action) => {
            state.loading = false;
            state.coursesPreData = [
                { courseId: -1, courseName: "All" },
                ...action.payload.data.courses,
            ];
        });
        builder.addCase(getCourses.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------------------getTerminationClearance------------------------------
        builder.addCase(getTerminationClearance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTerminationClearance.fulfilled, (state, action) => {
            state.loading = false;
            state.terminationClearance = action.payload.data;
        });
        builder.addCase(getTerminationClearance.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------------getCourseTcDetails---------------------------------------------

        builder.addCase(getCourseTcDetails.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCourseTcDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.courseTcDetails = action.payload.data;
        });
        builder.addCase(getCourseTcDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------getCourseInactiveReasons------------------------------------------
        builder.addCase(getCourseInactiveReasons.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCourseInactiveReasons.fulfilled, (state, action) => {
            state.loading = false;
            state.courseInactiveReasons = action.payload.data;
        });
        builder.addCase(getCourseInactiveReasons.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------------updateCourseEnrollmentStatus--------------------------
        builder.addCase(updateCourseEnrollmentStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            updateCourseEnrollmentStatus.fulfilled,
            (state, action) => {
                state.loading = false;
            }
        );
        builder.addCase(
            updateCourseEnrollmentStatus.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }
        );
        // -----------------------------wiseAdminget--------------------------------
        builder.addCase(wiseAdminget.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(wiseAdminget.fulfilled, (state, action) => {
            state.loading = false;
            state.wiseAdmin = formatDataForDataTable(
                action.payload.data.allCrosDetails
            );
        });
        builder.addCase(wiseAdminget.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------createWiseAdmin-------------------------------------
        builder.addCase(createWiseAdmin.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createWiseAdmin.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(createWiseAdmin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});

export const { apiCallData, admissionReg, storeId } = crmSlice.actions;

export default crmSlice.reducer;
