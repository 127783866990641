import { Chip } from "@mui/material";
import { AssessmentDetails } from "./AssessmentDetails/AssessmentDetails";
import { CroEnquiry } from "./CroEnquiryLog/CroEnquiry";
import { Profile } from "./StudentProfile/Profile";
import { TotalSessions } from "./TotalSession/TotalSessions";
import { Icon } from "@iconify/react";
import { AdmissionStatus } from "./AdmissionStatus/AdmissionStatus";
import ToolTip from "../../../ToolTip/ToolTip";

export const ADMISSION_REGISTER_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "studentRegion",
        headerName: "Student Region",
        width: 150,
    },
    {
        field: "admnNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "contactNumber",
        headerName: "Contact Number",
        width: 170,
    },
    {
        field: "dob",
        headerName: "Date of Birth",
        width: 170,
    },
    {
        field: "className",
        headerName: "Class",
        width: 170,
    },
    {
        field: "courseName",
        headerName: "Course",
        width: 200,
    },
    {
        field: "courseEnrolledDate",
        headerName: "Course Enrollment Date",
        width: 170,
    },
    // {
    //     field: "totalSessions",
    //     headerName: "Total Sessions",
    //     width: 170,
    //     disableClickEventBubbling: true,
    //     renderCell: (params) => (
    //         <TotalSessions
    //             courseEnrollmentId={params.row.courseEnrollmentId}
    //             totalSessions={params.row.totalSessions}
    //         />
    //     ),
    // },

    {
        field: "expectedSessions",
        headerName: "Expected Sessions",
        width: 150,
    },
    {
        field: "sessionsCompleted",
        headerName: "Sessions Completed",
        width: 150,
    },
    {
        field: "lastSessionDate",
        headerName: "Last Session Date",
        width: 150,
    },
    {
        field: "totalSalesAmount",
        headerName: "Total Sales Amount",
        width: 150,
    },
    {
        field: "totalFeeCollected",
        headerName: "Total Fee Collected",
        width: 150,
    },
    {
        field: "courseInactiveDate",
        headerName: "Course Inactive Date",
        width: 150,
    },
    // {
    //     field: "profile",
    //     headerName: "Profile",
    //     width: 150,
    //     disableClickEventBubbling: true,
    //     renderCell: (params) => (
    //         <Profile params={params.row.courseEnrollmentId} />
    //     ),
    // },
    // {
    //     field: "classRoomMaterials",
    //     headerName: "class Room Materials",
    //     width: 200,
    //     disableClickEventBubbling: true,
    //     renderCell: (params) => <AssessmentDetails params={params.row} />,
    // },
    // {
    //     field: "croEnquiryLog",
    //     headerName: "Cro Enquiry Log",
    //     width: 150,
    //     disableClickEventBubbling: true,
    //     renderCell: (params) => <CroEnquiry params={params.row} />,
    // },
    {
        field: "croNotes",
        headerName: "CRO Notes",
        width: 200,
        renderCell: (params) => {
            return (
                <ToolTip
                    expand={params.row.croNotes}
                    content={params.row.croNotes}
                />
            );
        },
    },
    {
        field: "admnFeeStatus",
        headerName: "Admission Fee",
        width: 150,
        renderCell: (params) => {
            return (
                <>
                    <Chip
                        size="small"
                        color={
                            params.row.admnFeeStatus === "Paid"
                                ? "success"
                                : "warning"
                        }
                        label={params.row.admnFeeStatus}
                        variant="outlined"
                        icon={
                            params.row.admnFeeStatus === "Paid" ? (
                                <Icon icon="mdi:tick" />
                            ) : (
                                <Icon
                                    icon="basil:cross-outline"
                                    height="20"
                                    width="20"
                                />
                            )
                        }
                    />
                </>
            );
        },
    },
    // {
    //     field: "courseStatus",
    //     headerName: "Admission Status",
    //     width: 170,
    //     renderCell: (params) => (
    //         <AdmissionStatus
    //             params={params.row.courseStatus}
    //             courseEnrollmentId={params.row.courseEnrollmentId}
    //         />
    //     ),
    // },
    {
        field: "mappingStatus",
        headerName: "Mapping Status",
        width: 180,
        renderCell: (params) => {
            return (
                <>
                    <Chip
                        size="small"
                        color={
                            params.row.mappingStatus === "Completed"
                                ? "success"
                                : "info" &&
                                  params.row.mappingStatus ===
                                      "Partially Completed"
                                ? "warning"
                                : "info" &&
                                  params.row.mappingStatus === "Not Completed"
                                ? "error"
                                : "info"
                        }
                        label={params.row.mappingStatus}
                        variant="outlined"
                        icon={
                            params.row.mappingStatus === "Completed" ? (
                                <Icon icon="mdi:tick" />
                            ) : (
                                <Icon
                                    icon="basil:cross-outline"
                                    height="20"
                                    width="20"
                                />
                            )
                        }
                    />
                </>
            );
        },
    },
    {
        field: "sessionLog",
        headerName: "Session Log",
        width: 150,
    },
    {
        field: "ledgerView",
        headerName: "ledger View",
        width: 150,
    },
    {
        field: "isFeeStructureAvailable",
        headerName: "Fee Structure",
        width: 150,
    },
    {
        field: "courseCertificate",
        headerName: "Course Certificate",
        width: 150,
    },
];
