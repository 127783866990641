import React, { useState } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { LogButton } from "../../../mui/Buttons/Buttons";
import { Box } from "@mui/material";

export const IncomeScreenshotView = (params) => {
    const [open, setOpen] = useState(false);
 
    
    return (
        <div>
            <GlobalDialog
                title={params.studentName}
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                closeButton={true}
                popupButton={
                    <LogButton size="small" action={() => setOpen(true)} />
                }
                content={
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img
                            alt={params.screenshotLink}
                            src={`https://workspace.teaminterval.net/assets/income/screenshot/${params.screenshotLink.split("/")[3]}`}
                            // src="https://portal.teaminterval.net/public/resources/assets/income/screenshot/najim.jpg"
                            // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPQL8XS-x9b-2T8AcDIQZLcu5lQ3upSdzOlKRfkh0yNQ&s"
                        />
                    </Box>
                }
            />
        </div>
    );
};
