import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Box, Card, Typography, Stack, Chip } from "@mui/material";
// utils
import { fNumber, fPercent } from "../../../utils/formatNumber";
// components
import Iconify from "../../Iconify";
import { DepartmentEmpDetailsPopUp } from "../../../views/user/Dashboard/DashboadIndex/DepartmentEmpDetailsPopUp";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
    width: 24,
    height: 24,
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

// ----------------------------------------------------------------------

DashboardWidgetCard.propTypes = {
    chartColor: PropTypes.string.isRequired,
    chartData: PropTypes.arrayOf(PropTypes.number).isRequired,
    title: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    reportingHead: PropTypes.string.isRequired,
};

export default function DashboardWidgetCard({
    title,
    total,
    chartColor,
    chartData,
    reportingHead,
    poupButton,
    action,
    item,
}) {
    const theme = useTheme();

    const chartOptions = {
        colors: [chartColor],
        chart: { sparkline: { enabled: true } },
        plotOptions: { bar: { columnWidth: "68%", borderRadius: 2 } },
        tooltip: {
            x: { show: false },
            y: {
                formatter: (seriesName) => fNumber(seriesName),
                title: {
                    formatter: () => "",
                },
            },
            marker: { show: false },
        },
    };

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 3,
                    height: 180,
                }}
                onClick={action}
            >
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle2">{title}</Typography>

                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ mt: 2, mb: 1 }}
                    >
                        {/* <IconWrapperStyle
                        sx={{
                            ...(percent < 0 && {
                                color: "error.main",
                                bgcolor: alpha(theme.palette.error.main, 0.16),
                            }),
                        }}
                    >
                        <Iconify
                            width={16}
                            height={16}
                            icon={
                                percent >= 0
                                    ? "eva:trending-up-fill"
                                    : "eva:trending-down-fill"
                            }
                        />
                    </IconWrapperStyle> */}
                        {/* <Typography component="span" variant="subtitle2">
                        {percent > 0 && "+"}
                        {fPercent(percent)}
                    </Typography> */}
                        <Box>
                            <Chip
                                sx={{ height: 20, width: 150, fontSize: 8 }}
                                variant="outlined"
                                size="small"
                                color="success"
                                label={reportingHead}
                            />
                        </Box>

                        <ReactApexChart
                            type="bar"
                            series={[{ data: chartData }]}
                            options={chartOptions}
                            width={60}
                            height={36}
                        />
                    </Stack>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography variant="h3">{fNumber(total)}</Typography>
                        <Box pt={2}>{poupButton}</Box>
                    </Box>
                </Box>
            </Card>
        </>
    );
}
