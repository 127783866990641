import { Box } from "@mui/material";
import React from "react";
import {
    ResponsiveContainer,
    FunnelChart,
    Funnel,
    Legend,
    LabelList,
    Tooltip,
    Cell,
} from "recharts";

const FunnelChartComponent = ({ recruitmentFunnel, sx }) => {
    const colors = [
        "#8884d8",
        "#83a6ed",
        "#8dd1e1",
        "#82ca9d",
        "#a4de6c",
        "#80dfff",
        "#007399",
    ];
    return (
        <Box
            sx={{
                ...sx,
            }}
        >
            <h2 style={{ marginLeft: "30px" }}>Recruitment Funnel</h2>
            <ResponsiveContainer width="100%" height={500}>
                <FunnelChart>
                    <Tooltip />
                    <Funnel
                        dataKey="value"
                        data={recruitmentFunnel}
                        isAnimationActive={false}
                        nameKey="name"
                    >
                        {recruitmentFunnel?.map((entry, index) => (
                            <Cell
                                onClick={() => {
                                    console.log("hiii");
                                }}
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                            />
                        ))}
                    </Funnel>
                </FunnelChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default FunnelChartComponent;
