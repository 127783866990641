import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import { Box } from '@mui/material'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import dayjs from 'dayjs'
import { getIncome, getIncomeScreenshot } from '../../../../redux/features/incomeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { IncomeTableHeaders as columns } from '../../../../components/datagrid/myworkspace/ogd/income/IncomeTableHeaders'
import Actions from './Actions'
import AddOrUpdateIncome from '../../../../components/datagrid/myworkspace/ogd/income/AddOrUpdateIncome'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import ActionDetails from '../../../../components/mui/GlobalAnchor/ActionDetails'
import ViewScreenShot from '../Invoice/ViewScreenShot'
import GlobalAnchorList from '../../../../components/mui/GlobalAnchor/GlobalAnchorList'
import IncomeTemplatePrint from './IncomeTemplatePrint'
import DeleteIncome from '../../../../components/datagrid/myworkspace/ogd/income/DeleteIncome'

const IncomeUpdatedPagination = () => {
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM'))
  const { loading, incomeData, rowCount } = useSelector((store) => store.income)
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData

  const paginationDetails = {
    pageNumber: pageNum,
    pageLimit: gridSize,
    selectedDate: selectedDate,
    searchValue: search,
  }
  

  useEffect(() => {
    dispatch(savePaginationData({ ...paginationData, pageNum: 1 }))
  }, [selectedDate, search])

  useEffect(() => {
    dispatch(getIncome(paginationDetails))
  }, [pageNum, gridSize, selectedDate, search])

  return (
    <GlobalWrapper title={'Income'} actions={<AddOrUpdateIncome />}>
      <Box>
        <DatePickerCustom
          value={dayjs(selectedDate)}
          setValue={(date) => setSelectedDate(date.format('YYYY-MM'))}
          viewDetails={['year', 'month']}
        />
      </Box>

      <Box sx={{ mt: 2 }}>
        <GlobalDatagrid
          tableHead={columns}
          rows={incomeData}
          meta={rowCount}
          rowUniqueId="incomeId"
          loading={loading}
          rowClick={true}
          actionComponent={
            <>
              <ActionDetails val={rowData?.studentName} />
              <GlobalAnchorList
                label="Payment"
                component={<ViewScreenShot pageName="income" api={getIncomeScreenshot(rowData?.screenshotLink)} />}
              />
              <GlobalAnchorList
                label="Edit"
                component={
                  <AddOrUpdateIncome
                    isVerified={rowData?.incomeVerification}
                    paginationDetails={paginationDetails}
                    row={rowData}
                  />
                }
              />
              {/* <GlobalAnchorList
                label="Delete"
                component={
                  <DeleteIncome
                    isVerified={rowData?.incomeVerification}
                    paginationDetails={paginationDetails}
                    incomeId={rowData?.incomeId}
                  />
                }
              /> */}
               <GlobalAnchorList
                label="Fee Reciept"
                component={  <IncomeTemplatePrint isVerified={rowData?.incomeVerification} row={rowData}  />}
              />
            </>
          }
        />
      </Box>
    </GlobalWrapper>
  )
}

export default IncomeUpdatedPagination
