import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
  loading: false,
  data: [],
  leadDetails: {},
  leadDetailsLoading: false,
  cartItems: [],
};

export const createCartItem = createAsyncThunk(
  "cart/createCartItem",
  async (data) => {
    const res = await apiRequest({
      url: `cart/add-items`,
      method: "post",
      data: data,
    });
    return res;
  }
);
export const getLeadDetails = createAsyncThunk(
  "cart/getLeadDetails",
  async (id) => {
    const res = await apiRequest({
      url: `lead/getLead/${id}`,
      method: "get",
    });
    return res;
  }
);

export const getCartItems = createAsyncThunk(
  "cart/getCartItems",
  async (id) => {
    const res = await apiRequest({
      url: `cart/get-cart/${id}`,
      method: "get",
    });
    return res;
  }
);
export const UpdateCartItems = createAsyncThunk(
  "cart/UpdateCartItems",
  async (data) => {
    const res = await apiRequest({
      url: `cart/patch-items`,
      method: "patch",
      data: data,
    });
    return res;
  }
);
export const deleteCart = createAsyncThunk("cart/deleteCart", async (id) => {
  const res = await apiRequest({
    url: `cart/delete-items/${id}`,
    method: "delete",
  });
  return res;
});
const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //.................createCartItem..............................
    builder.addCase(createCartItem.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(createCartItem.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // -------------------getLeadDetails-----------------------------
    builder.addCase(getLeadDetails.pending, (state, action) => {
      return {
        ...state,
        leadDetailsLoading: true,
      };
    });
    builder.addCase(getLeadDetails.fulfilled, (state, action) => {
      return {
        ...state,
        leadDetails: action.payload.data,
        leadDetailsLoading: false,
      };
    });
    // --------------------getCartItems----------------------------
    builder.addCase(getCartItems.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getCartItems.fulfilled, (state, action) => {
      return {
        ...state,
        cartItems: action.payload.data,
        loading: false,
      };
    });
    // --------------------UpdateCartItems---------------------------
    builder.addCase(UpdateCartItems.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(UpdateCartItems.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // -----------------deleteCart---------------------------------
    builder.addCase(deleteCart.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(deleteCart.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export default cartSlice.reducer;
