import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";

const initialState = {
  loading: false,
  resData:{
    data:[],
    meta:{}
  },
  techUsers:[]

};

export const getAllRequests = createAsyncThunk(
  "helpAndSupportSlice/getAllRequests",
  async (paginationDetails) => {
    const res = await apiRequest({
      method: "get",
      url: `general/help-support/?page=${paginationDetails?.pageNum}&limit=${paginationDetails?.gridSize}&search=${encodeURIComponent(paginationDetails?.search)}`,
    }); 
    return res;
  }
);

export const RaiseRequest = createAsyncThunk(
  "helpAndSupportSlice/RaiseRequest",
  async (data) => {
    const res = await apiRequest({
      method: "post",
      url: `general/help-support`,
      data: data,
    });
    return res;
  }
);

export const updateRaiseRequest = createAsyncThunk(
  "helpAndSupportSlice/updateRaiseRequest",
  async (data) => {
    const res = await apiRequest({
      method: "patch",
      url:`general/help-support/${data.ticketId}`,
      data: data,
    });
    return res;
  }
);


export const getTechUsers = createAsyncThunk(
  "helpAndSupportSlice/getTechUsers",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `general/all-employees-of-head`,
    });
    return res;
  }
);

export const assignToTechUser = createAsyncThunk(
  "helpAndSupportSlice/assignToTechUser",
  async (data) => {
    const res = await apiRequest({
      method: "patch",
      url:`general/help-support/assign/${data.ticketId}`,
      data: data,
    });
    return res;
  }
);

export const deleteRequest=createAsyncThunk(
  "helpAndSupportSlice/deleteRequest",
  async(id)=>{
    const res=await apiRequest({
      method:"delete",
      url:`general/help-support/${id}`
    })
    return res
  }
)

export const approveOrReject = createAsyncThunk(
  "helpAndSupportSlice/approveOrReject",
  async (data) => {
    const res = await apiRequest({
      method: "patch",
      url:`general/help-support/approve/${data.ticketId}`,
      data: data,
    });
    return res;
  }
);


const helpAndSupportSlice = createSlice({
  name: "helpAndSupportSlice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //..................GETING ALL RAISE REQUESTS................
    builder.addCase(getAllRequests.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(getAllRequests.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        resData:{
            ...state.resData,
            data:action.payload?.data,
            meta:action.payload?.meta
        }
      };
    });

      //...............RAISING A REQUEST...........................
    builder.addCase(RaiseRequest.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });

    builder.addCase(RaiseRequest.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });

          //...............UPDATING A REQUEST...........................
          builder.addCase(updateRaiseRequest.pending, (state, action) => {
            return {
              ...state,
              loading: true,
            };
          });
      
          builder.addCase(updateRaiseRequest.fulfilled, (state, action) => {
            return {
              ...state,
              loading: false,
            };
          });



          //...............DELETING A REQUEST...........................
          builder.addCase(deleteRequest.pending, (state, action) => {
            return {
              ...state,
              loading: true,
            };
          });
      
          builder.addCase(deleteRequest.fulfilled, (state, action) => {
            return {
              ...state,
              loading: false,
            };
          });


            //...............APPROVE OR REJECT A REQUEST...........................
            builder.addCase(approveOrReject.pending, (state, action) => {
              return {
                ...state,
                loading: true,
              };
            });
        
            builder.addCase(approveOrReject.fulfilled, (state, action) => {
              return {
                ...state,
                loading: false,
              };
            });


                    //..............GETTING TECH USERS...........................
                    builder.addCase(getTechUsers.pending, (state, action) => {
                      return {
                        ...state,
                        loading: true,
                      };
                    });
                
                    builder.addCase(getTechUsers.fulfilled, (state, action) => {
                      return {
                        ...state,
                        loading: false,
                        techUsers:action.payload?.data,           
                      };
                    });
        



                               //...............ASSIGNING TO  A USER...........................
                               builder.addCase(assignToTechUser.pending, (state, action) => {
                                return {
                                  ...state,
                                  loading: true,
                                };
                              });
                          
                              builder.addCase(assignToTechUser.fulfilled, (state, action) => {
                                return {
                                  ...state,
                                  loading: false,
                                };
                              });
                    
            
                      
            
  },
});

export default helpAndSupportSlice.reducer;
