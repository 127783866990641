import React from "react";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
import { packageClassListing } from "../../redux/features/mastersSlice";

export const PackageClassesListing = ({ params }) => {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.isListed}
                api={packageClassListing(params.packageClassId)}
            />
        </div>
    );
};
