import React, { useState } from "react";
import { ApproveButton, CancelButton } from "../../../mui/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
    getMentorRequest,
    requestApproval,
} from "../../../../redux/features/mentoringSlice";
import { useSnackbar } from "notistack";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import dayjs from "dayjs";

export const ApproveRequest = ({ params }) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { loading } = useSelector((state) => state.mentoring);
    const dispatch = useDispatch();
    const handleClick = () => {
        const data = {
            mentor_suggested_date: params.booking_date,
            mentor_suggested_from_time: dayjs(params.from_time).format(
                "HH:mm:00"
            ),
            mentor_suggested_to_time: dayjs(params.to_time).format("HH:mm:00"),
            mentoring_bookings_id: params.mentoring_bookings_id,
        };
        dispatch(requestApproval(data)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                dispatch(getMentorRequest());
                setOpen(false);
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
            }
        });
    };
    return (
        <div>
            <GlobalDialog
                title="Approve Request"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                popupButton={
                    <>
                        {params.status === 0 ? (
                            <ApproveButton
                                size="small"
                                action={() => setOpen(true)}
                            >
                                Approve
                            </ApproveButton>
                        ) : null}
                    </>
                }
                content={
                    <>
                        <p>Are you sure. You want to approve...?</p>
                    </>
                }
                actionButton={
                    <>
                        <CancelButton
                            size="small"
                            action={() => setOpen(false)}
                        >
                            Cancel
                        </CancelButton>
                        <ApproveButton size="small" action={handleClick}>
                            {loading ? "Approving..." : "Approve"}
                        </ApproveButton>
                    </>
                }
            />
        </div>
    );
};
