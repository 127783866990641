import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    data: [],
    matrixData: [],
    objectives: [],
    loading: false,
    levelData: [],
    gradeData: [],
    descriptions: [],
    courseData: [],
    foundationData: [],
    speakProData: [],
    leadStudents: [],
    assessmentFormData: [],
    gradePreData: [],
    levelPreData: [],
    matricesPreData: [],
    objectivePredata: [],
    sroAssignmentStudents: [],
    syllabusPredata: [],
    classPreData: [],
    assessmentReports: [],
    courseData: [],
    isAvailableLevelData: [],
    isAvailableObjectiveData: [],
    error: "",
    reportSubmitLoading: false,
    induvidualReport:[]
};

export const getAllMatrices = createAsyncThunk(
    "assessmentTool/getAllMatrices",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-matrices`,
        });
        return res;
    }
);

export const updateMatrix = createAsyncThunk(
    "assessmentTool/updateMatrix",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/update-matrix/${data.id}`,
            data: data,
        });
        return res;
    }
);

export const createMatrix = createAsyncThunk(
    "assessmentTool/createMatrix",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `assessmentReport/create-matrix`,
            data: data,
        });
        return res;
    }
);

export const deleteMatrix = createAsyncThunk(
    "assessmentTool/deleteMatrix",
    async (id) => {
        console.log("id", id);
        const res = await apiRequest({
            method: "delete",
            url: `assessmentReport/delete-matrix/${id}`,
        });
        return res;
    }
);

export const inactiveMatrix = createAsyncThunk(
    "assessmentTool/inactiveMatrix",
    async (id) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/inactive-matrix/${id}`,
        });
        return res;
    }
);

export const getAllLevels = createAsyncThunk(
    "assessmentTool/getAllLevels",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-levels/`,
        });
        return res;
    }
);

export const inactiveLevel = createAsyncThunk(
    "assessmentTool/inactiveLevel",
    async (id) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/inactive-level/${id}`,
        });
        return res;
    }
);

export const createLevel = createAsyncThunk(
    "assessmentTool/createLevel",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `assessmentReport/add-level`,
            data: data,
        });
        return res;
    }
);

export const updateLevel = createAsyncThunk(
    "assessmentTool/updateLevel",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/update-level/${data.id}`,
            data: data,
        });
        return res;
    }
);

export const deleteLevel = createAsyncThunk(
    "assessmentTool/deleteLevel",
    async (id) => {
        const res = await apiRequest({
            method: "delete",
            url: `assessmentReport/delete-level/${id}`,
        });
        return res;
    }
);

export const getAllObjectives = createAsyncThunk(
    "assessmentTool/getAllObjectives",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-objectives`,
        });
        return res;
    }
);
export const createObjective = createAsyncThunk(
    "assessmentTool/createObjective",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `assessmentReport/create-objective`,
            data: data,
        });
        return res;
    }
);

export const updateObjective = createAsyncThunk(
    "assessmentTool/updateObjective",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/update-objective/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const deleteObjective = createAsyncThunk(
    "assessmentTool/deleteObjective",
    async (id) => {
        const res = await apiRequest({
            method: "delete",
            url: `assessmentReport/delete-objective/${id}`,
        });
        return res;
    }
);
export const inactiveObjective = createAsyncThunk(
    "assessmentTool/inactiveObjective",
    async (id) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/inactive-objective/${id}`,
        });
        return res;
    }
);

export const getAllGrades = createAsyncThunk(
    "assessmentTool/getAllGrades",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-grades`,
        });
        return res;
    }
);

export const inactiveGrade = createAsyncThunk(
    "assessmentTool/inactiveGrade",
    async (id) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/inactive-grade/${id}`,
        });
        return res;
    }
);
export const createGrade = createAsyncThunk(
    "assessmentTool/createGrade",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `assessmentReport/add-grade`,
            data: data,
        });
        return res;
    }
);
export const updateGrade = createAsyncThunk(
    "assessmentTool/updateGrade",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/update-grade/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const deleteGrade = createAsyncThunk(
    "assessmentTool/deleteGrade",
    async (id) => {
        const res = await apiRequest({
            method: "delete",
            url: `assessmentReport/delete-grade/${id}`,
        });
        return res;
    }
);

export const getAllDescriptions = createAsyncThunk(
    "assessmentTool/getAllDescriptions",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-description`,
        });
        return res;
    }
);
export const createDescription = createAsyncThunk(
    "assessmentTool/createDescription",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `assessmentReport/create-description`,
            data: data,
        });
        return res;
    }
);
export const updateDescription = createAsyncThunk(
    "assessmentTool/updateDescription",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `assessmentReport/update-description/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const deleteDescription = createAsyncThunk(
    "assessmentTool/deleteDescription",
    async (id) => {
        const res = await apiRequest({
            method: "delete",
            url: `assessmentReport/delete-description/${id}`,
        });
        return res;
    }
);
export const getAllCourse = createAsyncThunk(
    "assessmentTool/getAllCourse",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-course`,
        });
        return res;
    }
);
export const getFoundationAssessmentData = createAsyncThunk(
    "assessmentTool/getFoundationAssessmentData",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-foundation-assessment-data/${id}`,
        });
        return res;
    }
);
export const getLeadStudents = createAsyncThunk(
    "assessmentTool/getLeadStudents",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-students/${id}`,
        });
        return res;
    }
);
export const getGradePreData = createAsyncThunk(
    "assessmentTool/getGradePreData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-grades-pre-data`,
        });
        return res;
    }
);
export const getLevelPreData = createAsyncThunk(
    "assessmentTool/getLevelPreData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-levels-pre-data`,
        });
        return res;
    }
);
export const getSpeakProAssessmentData = createAsyncThunk(
    "assessmentTool/getSpeakProAssessmentData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-speakpro-assessment-data`,
        });
        return res;
    }
);
export const getMatricesPreData = createAsyncThunk(
    "assessmentTool/getMatricesPreData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-matrices-pre-data`,
        });
        return res;
    }
);
export const getAllObjectivePreData = createAsyncThunk(
    "assessmentTool/getAllObjectivePreData",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-objectives-pre-data`,
        });
        return res;
    }
);

export const getSroAssignmetStudents = createAsyncThunk(
    "assessmentTool/getSroAssignmetStudents",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-sro-assignment-students/${id}`,
        });
        return res;
    }
);

export const getSyllabusPreData = createAsyncThunk(
    "assessmentTool/getSyllabusPreData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `cr-pre-data/getSyllabus`,
        });
        return res;
    }
);
export const getClassPreData = createAsyncThunk(
    "assessmentTool/getClassPreData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `cr-pre-data/getClasses`,
        });
        return res;
    }
);
export const createAssessmentReports = createAsyncThunk(
    "assessmentTool/createAssessmentReports",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `assessmentReport/create-assessment-report`,
            data: data,
        });
        return res;
    }
);
export const getAssessmentReports = createAsyncThunk(
    "assessmentTool/getAssessmentReports",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-assessment-report-data`,
        });
        return res;
    }
);
export const getCourseData = createAsyncThunk(
    "assessmentTool/getCourseData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-all-course`,
        });
        return res;
    }
);
export const getIsAvailableLevel = createAsyncThunk(
    "assessmentTool/getIsAvailableLevel",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-IsAvailablePreData-matrix-levels/${id}`,
        });
        return res;
    }
);
export const getIsAvailableObjective = createAsyncThunk(
    "assessmentTool/getIsAvailableObjective",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/get-IsAvailablePreData-levels-objectives/${id}`,
        });
        return res;
    }
);
export const getInduvidualReportBySroId = createAsyncThunk(
    "assessmentTool/getInduvidualReportBySroId",
    async (leadId) => {
        const res = await apiRequest({
            method: "get",
            url: `assessmentReport/getAssesmentReportForSro/${leadId}`,
        });
        return res;
    }
);
const assessmentToolSlice = createSlice({
    name: "assessmentTool",
    initialState,
    reducers: {
        createAssessmentData: (state, action) => {
            return {
                ...state,
                assessmentFormData: action.payload,
            };
        },
        clearIsAvailableData:(state,action)=>{
            return{
                ...state,
                isAvailableObjectiveData:[],
                isAvailableLevelData:[]

            }
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getAllMatrices.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllMatrices.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data.matrices);

            return {
                ...state,
                matrixData: data,
                loading: false,
            };
        });
        // ---------------------updateMatrix-------------------------------
        builder.addCase(updateMatrix.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateMatrix.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------------createMatrix-----------------------------
        builder.addCase(createMatrix.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(createMatrix.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------deleteMatrix---------------------------

        builder.addCase(deleteMatrix.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteMatrix.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ---------------inactiveMatrix--------------------

        builder.addCase(inactiveMatrix.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(inactiveMatrix.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ---------------------------getAllLevels------------------------

        builder.addCase(getAllLevels.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllLevels.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data.levels);
            return {
                ...state,
                levelData: data,
                loading: false,
            };
        });
        // --------------------inactiveLevel-----------------------

        builder.addCase(inactiveLevel.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(inactiveLevel.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -----------------------createLevel----------------------------
        builder.addCase(createLevel.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(createLevel.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -----------------------------updateLevel------------------------------
        builder.addCase(updateLevel.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateLevel.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // --------------------------------deleteLevel-----------------------------
        builder.addCase(deleteLevel.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteLevel.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------getAllObjectives------------------------

        builder.addCase(getAllObjectives.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllObjectives.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data.objectives);
            return {
                ...state,
                objectives: data,
                loading: false,
            };
        });
        // ---------------------createObjective------------------------------
        builder.addCase(createObjective.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(createObjective.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ----------------------updateObjective-------------------------------
        builder.addCase(updateObjective.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateObjective.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------------deleteObjective----------------------------------
        builder.addCase(deleteObjective.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteObjective.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------------------inactiveObjective----------------------------
        builder.addCase(inactiveObjective.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(inactiveObjective.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------------getAllGrades------------------------
        builder.addCase(getAllGrades.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllGrades.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data.gradeData);
            return {
                ...state,
                gradeData: data,
                loading: false,
            };
        });
        // ------------------------------inactiveGrade-----------------------
        builder.addCase(inactiveGrade.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(inactiveGrade.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ---------------------------------createGrade---------------------------------
        builder.addCase(createGrade.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(createGrade.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ----------------------------updateGrade----------------------------
        builder.addCase(updateGrade.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateGrade.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // --------------------------deleteGrade------------------------------
        builder.addCase(deleteGrade.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteGrade.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // --------------------------getAllDescriptions-----------------------------
        builder.addCase(getAllDescriptions.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllDescriptions.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.descriptions
            );
            return {
                ...state,
                descriptions: data,
                loading: false,
            };
        });
        // ---------------------createDescription----------------------------------
        builder.addCase(createDescription.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(createDescription.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------updateDescription---------------------------
        builder.addCase(updateDescription.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateDescription.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------------deleteDescription---------------------
        builder.addCase(deleteDescription.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteDescription.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------------getAllCourse----------------------------------
        builder.addCase(getAllCourse.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllCourse.fulfilled, (state, action) => {
            const data = action.payload.data.courses;
            return {
                ...state,
                courseData: data,
                loading: false,
            };
        });
        // ------------------------getFoundationAssessmentData---------------------
        builder.addCase(
            getFoundationAssessmentData.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getFoundationAssessmentData.fulfilled,
            (state, action) => {
                const data = action.payload.data.assessmentData;
                return {
                    ...state,
                    foundationData: data,
                    loading: false,
                };
            }
        );
        // ---------------------------------getLeadStudents-----------------------------
        builder.addCase(getLeadStudents.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getLeadStudents.fulfilled, (state, action) => {
            const data = action.payload.data?.studentsData;
            return {
                ...state,
                leadStudents: data ? data : [],
                loading: false,
            };
        });
        // -------------------------------getGradePreData-----------------------------------
        builder.addCase(getGradePreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getGradePreData.fulfilled, (state, action) => {
            const data = action.payload.data.gradeData;
            return {
                ...state,
                gradePreData: data,
                loading: false,
            };
        });
        // --------------------getLevelPreData------------------------------
        builder.addCase(getLevelPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getLevelPreData.fulfilled, (state, action) => {
            const data = action.payload.data.levelsPreData;
            return {
                ...state,
                levelPreData: data,
                loading: false,
            };
        });
        // ----------------------getSpeakProAssessmentData-------------------------
        builder.addCase(getSpeakProAssessmentData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getSpeakProAssessmentData.fulfilled,
            (state, action) => {
                const data = action.payload.data.dataWithGradesAndDescriptions;
                return {
                    ...state,
                    speakProData: data,
                    loading: false,
                };
            }
        );
        // ---------------------------------getMatricesPreData---------------------------
        builder.addCase(getMatricesPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getMatricesPreData.fulfilled, (state, action) => {
            const data = action.payload.data.matrices;
            return {
                ...state,
                matricesPreData: data,
                loading: false,
            };
        });
        // ---------------------------getAllObjectivePreData------------------------------
        builder.addCase(getAllObjectivePreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllObjectivePreData.fulfilled, (state, action) => {
            const data = action.payload.data.objectives;
            return {
                ...state,
                objectivePredata: data,
                loading: false,
            };
        });
        // ----------------getSroAssignmetStudents---------------------------
        builder.addCase(getSroAssignmetStudents.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSroAssignmetStudents.fulfilled, (state, action) => {
            const data = action.payload.data.students;
            return {
                ...state,
                sroAssignmentStudents: data,
                loading: false,
            };
        });
        // -----------------------------getSyllabusPreData-------------------------
        builder.addCase(getSyllabusPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSyllabusPreData.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                syllabusPredata: data,
                loading: false,
            };
        });
        // ------------------------getClassPreData------------------------------
        builder.addCase(getClassPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getClassPreData.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                classPreData: data,
                loading: false,
            };
        });
        // -------------------------------create-assessmentReports----------------------------
        builder.addCase(createAssessmentReports.pending, (state, action) => {
            return {
                ...state,
                reportSubmitLoading: true,
            };
        });
        builder.addCase(createAssessmentReports.fulfilled, (state, action) => {
            return {
                ...state,
                reportSubmitLoading: false,
            };
        });
        // -------------------------------getAssessmentReports------------------------------
        builder.addCase(getAssessmentReports.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAssessmentReports.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.assessmentReportData
            );
            return {
                ...state,
                assessmentReportData: data,
                loading: false,
            };
        });
        // --------------------getCourseData--------------------------------
        builder.addCase(getCourseData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCourseData.fulfilled, (state, action) => {
            const data = action.payload.data.courses;

            return {
                ...state,
                courseData: data,
                loading: false,
            };
        });
        // ----------------------isAvailableLevelData--------------------------
        builder.addCase(getIsAvailableLevel.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getIsAvailableLevel.fulfilled, (state, action) => {
            const data = action.payload.data.predata;

            return {
                ...state,
                isAvailableLevelData: data,
                loading: false,
            };
        });
        // --------------------------getIsAvailableObjective----------------------

        builder.addCase(getIsAvailableObjective.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getIsAvailableObjective.fulfilled, (state, action) => {
            const data = action.payload.data.predata;

            return {
                ...state,
                isAvailableObjectiveData: data,
                loading: false,
            };
        });
              // --------------------------getAssesment report of induvidual lead by sro id----------------------

              builder.addCase(getInduvidualReportBySroId.pending, (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            });
            builder.addCase(getInduvidualReportBySroId.fulfilled, (state, action) => {
               
    
                return {
                    ...state,
                    induvidualReport: action.payload?.data,
                    loading: false,
                };
            });
    },
});
export const { createAssessmentData,clearIsAvailableData } = assessmentToolSlice.actions;

export default assessmentToolSlice.reducer;
