import React from "react";
import { Route, Routes } from "react-router-dom";
import RaiseRequest from "../../views/user/HelpAndSupport/RaiseRequest";
import GeneratedRequests from "../../views/user/HelpAndSupport/GeneratedRequests";

const HelpAndSupportRouter = () => {
  return (
    <Routes>
      <Route path="raise-request" element={<RaiseRequest />} />
      <Route path="generated-requests" element={<GeneratedRequests />} />
    </Routes>
  );
};

export default HelpAndSupportRouter;
