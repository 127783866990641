import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { CancelButton } from "../../../../components/mui/Buttons/Buttons";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { motion } from "framer-motion";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";
import DayTimePreference from "./DayTimePreference";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import useResponsive from "../../../../Hooks/useResponsive";
import {
  createCartItem,
  getCartItems,
} from "../../../../redux/features/cartSlice";
import { useSnackbar } from "notistack";

const PopuForm = ({ totalCourses, setTotalCourses }) => {
  console.log("form Totalc courses===", totalCourses);
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = ["Malayalam", "English", "Hindi", "Arabic"];
  const [personName, setPersonName] = React.useState([]);
  const location = useLocation();
  console.log("form location", location);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const { classes, mediums, syllabuses } = useSelector((state) => state.cro);
  const { loading } = useSelector((state) => state.cart);
  const [open, setOpen] = useState(false);
  const [admissionFee, setAdmissionFee] = React.useState(false);

  const handleChangeSwitch = (event) => {
    setAdmissionFee(event.target.checked);
  };
  const Schema = Yup.object().shape({
    // sessionsPerWeek: Yup.string().required("sessionsPerWeek is required"),
    // trainerPrepreference: Yup.string().required(
    //   "trainerPrepreference is required"
    // ),
    // syllabus: Yup.string().required("syllabus is required"),
    // medium: Yup.string().required("medium is required"),
    // class: Yup.string().required("class is required"),
    // languageFuency: Yup.string().required("languageFuency is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      leadId: location?.state.leadId || "",
      admissionFee: 500,
      paymentMode: 0,
      items: [...totalCourses],
      // ----------------------------------
      sessionsPerWeek: "",
      trainerPreference: "",
      totalFee: "",
      totalPayingFee: "",
      languageFluency: [],
      syllabus: "",
      medium: "",
      class: "",
      preferredDayTime: [],
      admissionFee: 0,
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      console.log("formik values", values);
      dispatch(createCartItem(values)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          dispatch(getCartItems(values.leadId));
          setTotalCourses([]);
          resetForm();
          setOpen(false);
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    },
  });
  console.log("formik .values", formik.values);
  useEffect(() => {
    if (admissionFee) {
      formik.setFieldValue("admissionFee", 500);
    }
  }, [admissionFee]);
  const handleClick = () => {
    let hasEmptyFields = false;

    if (totalCourses && totalCourses.length > 0) {
      // Perform validation
      totalCourses.forEach((item) => {
        if (
          !item.MRP ||
          !item.discount ||
          !item.noOfSessions ||
          (!item.paidAmt && item.wishList == false)
        ) {
          enqueueSnackbar("Some fields are empty...!", {
            variant: "error",
          });
          hasEmptyFields = true; // Mark validation failure
        }
      });

      // If any fields are empty, close the dialog
      if (hasEmptyFields) {
        setOpen(false); // Close if validation fails
      } else {
        setOpen(true); // Open if validation passes
      }
    } else {
      // If there are no courses, open the dialog
      setOpen(true);
    }
  };

  return (
    <Box>
      <GlobalDialog
        title={"Cart Confirmation Details"}
        open={open}
        setOpen={setOpen}
        closeButton={true}
        fullWidth
        maxWidth={"md"}
        popupButton={
          <Button
            onClick={handleClick}
            sx={{
              color: "white",
              background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
              borderRadius: 3,
            }}
          >
            <Icon icon="mdi:cart-outline" height={22} width={22} />
            Confirm Purchase
          </Button>
        }
        content={
          <Box>
            {open ? (
              <GlobalWrapper>
                <Box
                  sx={{
                    background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                    padding: smUp ? 3 : 1.5,
                    borderRadius: 3,
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "10px 100px 50px 10px",
                        //   position: "relative",
                        padding: smUp ? 5 : 2,
                        // overflow: "hidden",
                      }}
                    >
                      <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                          <Box>
                            <Stack direction={"column"} spacing={2}>
                              <Box>
                                <Stack
                                  direction={smUp ? "row" : "column"}
                                  spacing={2}
                                >
                                  <MotionContainer delay={0.8}>
                                    <TextField
                                      name="sessionsPerWeek"
                                      label="session Per Week"
                                      fullWidth
                                      value={formik.values.sessionsPerWeek}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.sessionsPerWeek &&
                                        Boolean(formik.errors.sessionsPerWeek)
                                      }
                                      helperText={formik.errors.sessionsPerWeek}
                                    />
                                  </MotionContainer>
                                  <MotionContainer delay={0.5}>
                                    <GlobalSelectField
                                      options={{
                                        name: "trainerPreference",
                                        formik,
                                        sx: { width: "100%" },
                                        label: "Trainer Preference",
                                        data: [
                                          { id: "Male", gender: "Male" },
                                          { id: "Female", gender: "Female" },
                                        ],
                                        keys: {
                                          id: "id",
                                          value: "gender",
                                        },
                                      }}
                                    />
                                  </MotionContainer>
                                </Stack>
                              </Box>

                              <Box>
                                <Stack
                                  direction={smUp ? "row" : "column"}
                                  spacing={2}
                                >
                                  <MotionContainer delay={0.8}>
                                    {" "}
                                    <TextField
                                      variant="filled"
                                      name="totalFee"
                                      label="Total Fee"
                                      fullWidth
                                      value={formik.values.totalFee}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.totalFee &&
                                        Boolean(formik.errors.totalFee)
                                      }
                                      helperText={formik.errors.totalFee}
                                    />
                                  </MotionContainer>
                                  <MotionContainer delay={0.5}>
                                    <TextField
                                      name="totalPayingFee"
                                      label="Total Paying Fee"
                                      variant="filled"
                                      fullWidth
                                      value={formik.values.totalPayingFee}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.totalPayingFee &&
                                        Boolean(formik.errors.totalPayingFee)
                                      }
                                      helperText={formik.errors.totalPayingFee}
                                    />
                                  </MotionContainer>
                                </Stack>
                              </Box>
                              <MotionContainer>
                                <Box>
                                  <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="demo-multiple-chip-label">
                                      Language Fluency
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-chip-label"
                                      id="demo-multiple-chip"
                                      multiple
                                      value={personName}
                                      onChange={handleChange}
                                      input={
                                        <OutlinedInput
                                          id="select-multiple-chip"
                                          label="Language Fuency"
                                        />
                                      }
                                      renderValue={(selected) => (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                          }}
                                        >
                                          {selected.map((value) => (
                                            <>
                                              <motion.div
                                                initial={{ opacity: 0, x: -80 }}
                                                animate={{ opacity: 1, x: 0 }}
                                                exit={{ opacity: 0, x: -50 }}
                                                transition={{ duration: 0.4 }}
                                              >
                                                <Chip
                                                  size="small"
                                                  // color="primary"
                                                  sx={{
                                                    background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                                                    color: "white",
                                                  }}
                                                  icon={
                                                    <Icon
                                                      color="white"
                                                      icon="clarity:language-line"
                                                    />
                                                  }
                                                  variant="contained"
                                                  key={value}
                                                  label={value}
                                                />
                                              </motion.div>
                                            </>
                                          ))}
                                        </Box>
                                      )}
                                      MenuProps={MenuProps}
                                    >
                                      {names.map((name) => (
                                        <MenuItem
                                          key={name}
                                          value={name}
                                          //   style={getStyles(name, personName, theme)}
                                        >
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </MotionContainer>
                              <MotionContainer delay={0.8}>
                                <DayTimePreference />
                              </MotionContainer>
                            </Stack>
                            {/* -------------------------------------------------- */}
                            <Box pt={2}>
                              <Stack
                                direction={mdUp ? "row" : "column"}
                                spacing={2}
                              >
                                <MotionContainer delay={0.8}>
                                  <GlobalSelectField
                                    options={{
                                      name: "syllabus",
                                      formik,
                                      sx: { width: "100%" },
                                      label: "Syllabus",
                                      data: syllabuses,
                                      keys: {
                                        id: "id",
                                        value: "name",
                                      },
                                    }}
                                  />
                                </MotionContainer>
                                <MotionContainer delay={0.5}>
                                  <GlobalSelectField
                                    options={{
                                      name: "medium",
                                      formik,
                                      sx: { width: "100%" },
                                      label: "Medium",
                                      data: mediums,
                                      keys: {
                                        id: "id",
                                        value: "name",
                                      },
                                    }}
                                  />
                                </MotionContainer>
                                <MotionContainer delay={0.5}>
                                  <GlobalSelectField
                                    options={{
                                      name: "class",
                                      formik,
                                      sx: { width: "100%" },
                                      label: "Class",
                                      data: classes,
                                      keys: {
                                        id: "classId",
                                        value: "className",
                                      },
                                    }}
                                  />
                                </MotionContainer>
                              </Stack>
                              <Stack
                                direction={smUp ? "row" : "column"}
                                spacing={2}
                                mt={2}
                              >
                                <MotionContainer delay={0.5}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={admissionFee}
                                          onChange={handleChangeSwitch}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                      }
                                      label="Admissin Fee"
                                    />
                                  </FormGroup>
                                </MotionContainer>
                                {admissionFee ? (
                                  <MotionContainer delay={0.5}>
                                    <TextField
                                      type="number"
                                      name="admissionFee"
                                      label="Admission Fee"
                                      value={formik.values.admissionFee}
                                      onChange={formik.handleChange}
                                      fullWidth
                                    />
                                  </MotionContainer>
                                ) : null}
                              </Stack>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                pt: 2,
                              }}
                            >
                              <Stack direction={"row"} spacing={1}>
                                <CancelButton action={() => setOpen(false)}>
                                  Cancel
                                </CancelButton>
                                <Button
                                  type="submit"
                                  sx={{
                                    color: "white",
                                    background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                                    borderRadius: 3,
                                  }}
                                  disabled={loading ? true : false}
                                >
                                  <Icon
                                    icon="mdi:cart-outline"
                                    height={22}
                                    width={22}
                                  />
                                  {loading
                                    ? "Please Wait..!"
                                    : " Confirm and Purchase Courses"}
                                </Button>
                              </Stack>
                            </Box>
                          </Box>
                        </Form>
                      </FormikProvider>
                    </Box>
                  </motion.div>
                </Box>
              </GlobalWrapper>
            ) : (
              <Box>null</Box>
            )}
          </Box>
        }
      />
    </Box>
  );
};

export default PopuForm;
