import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../mui/Dialogue/GlobalDialog'
import { AddButton, CancelButton, SubmitButton, UpdateButton } from '../../../mui/Buttons/Buttons'
import GlobalSelectField from '../../../mui/GlobalSelectField'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import { Box, Stack } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import { assignToTechUser, getAllRequests, getTechUsers } from '../../../../redux/features/helpAndSupportSlice'
import { useSnackbar } from 'notistack'
import { debounce } from 'lodash'

const AssigningTechUser = ({ row }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { loading, techUsers } = useSelector((store) => store.helpAndSupport)
  const userProfile = JSON.parse(localStorage.getItem('cred')).profile
  const techUsersSpread = [...techUsers, { userID: userProfile.userId, name: userProfile.name }]
  console.log('tech', techUsersSpread)

  const debouncedFetchData = debounce(() => {
    dispatch(getTechUsers())
  }, 40)

  useEffect(() => {
    if (open) {
      debouncedFetchData()
    }
  }, [open])

  const initialValues = {
    user: techUsersSpread?.find((user) => user.name == row?.assignedTo)?.userID || '',
    ticketId: row?.id,
  }

  const schema = Yup.object({
    user: Yup.number().required('User Required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(assignToTechUser(values)).then((res) => {
        if (res.payload.status === 'error') {
          enqueueSnackbar(res.payload.message, {
            variant: 'error',
          })
        } else if (res.payload.status === 'success') {
          setOpen(false)
          dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
          dispatch(getAllRequests(paginationData))
          enqueueSnackbar(res.payload.message, {
            variant: 'success',
          })
        }
      })
    },
  })

  const handleCancel = () => {
    setOpen(false)
    formik.resetForm()
  }
  return (
    <GlobalDialog
      maxWidth={'sm'}
      fullWidth
      title={'Assigning To'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={
        <>
          <AddButton
            variant={'outlined'}
            size="small"
            action={() => {
              setOpen(true)
            }}></AddButton>
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <GlobalSelectField
                options={{
                  formik: formik,
                  label: 'User',
                  name: 'user',
                  data: techUsersSpread,
                  keys: {
                    id: 'userID',
                    value: 'name',
                  },
                  sx: {
                    width: '100%',
                  },
                }}
              />
              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Stack direction={'row'} spacing={1}>
                    <CancelButton action={handleCancel}>Cancel</CancelButton>
                    <UpdateButton disabled={loading} type="submit">
                      {loading ? 'Updating...' : 'Update'}
                    </UpdateButton>
                  </Stack>
                </Box>
              </Box>
            </Form>
          </FormikProvider>
        </>
      }
    />
  )
}

export default AssigningTechUser
