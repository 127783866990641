import { Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteSubjectEnrollments } from "../../../../redux/features/croSlice";

const DeleteConfirmationPopUp = ({ row, setOpen }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleConfirm = () => {
        setOpen(false);
        dispatch(deleteSubjectEnrollments(row.subjectEnrollmentId)).then(
            (res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                } else {
                    enqueueSnackbar(res.payload.message, {
                        variant: "error",
                    });
                }
            }
        );
    };

    return (
        <Stack gap={3}>
            <Typography variant="h6">
                Are you sure want to delete the subject?
            </Typography>
            <Stack direction="row" gap={2} justifyContent="right">
                <Button variant="contained" onClick={handleConfirm}>
                    Ok
                </Button>
            </Stack>
        </Stack>
    );
};

export default DeleteConfirmationPopUp;
