import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useFormik, FormikProvider, Form } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  updateObjective,
  getAllObjectives,
  getAllLevels,
  createObjective,
  getLevelPreData,
  getMatricesPreData,
  getIsAvailableLevel,
} from "../../../../../redux/features/assessmentToolSlice";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import LinearProgress from "@material-ui/core/LinearProgress";

const CreateUpdateObjective = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    loading,
    levelData,
    levelPreData,
    matricesPreData,
    isAvailableLevelData,
  } = useSelector((state) => state.assessmentTool);
  const [open, setOpen] = useState(false);

  const Schema = Yup.object().shape({
    objectiveLevelId: Yup.string().required("objectiveLevelId is required"),
    objectiveName: Yup.string().required("objectiveName is required"),
    objectiveDescription: Yup.string(),
  });
  const profile = JSON.parse(localStorage.getItem("cred")).profile;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // matrix_id: params ? params.matrix_id : "",
      matrix_id:
        params &&
        matricesPreData?.some((matrix) => matrix.matrix_id === params.matrix_id)
          ? params.matrix_id
          : "",
      // objectiveLevelId: params ? params.objective_level_id : "",
      objectiveLevelId:
        params &&
        isAvailableLevelData?.some(
          (level) => level.level_id === params.objective_level_id
        )
          ? params.objective_level_id
          : "",
      objectiveName: params ? params.objective_name : "",
      objectiveDescription: params ? params.objective_description : "",
      objectiveUpdatedBy: profile.userId,
      objectiveCreatedBy: profile.userId,
      id: params ? params.objective_id : "",
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      if (params) {
        dispatch(updateObjective(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllObjectives());
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(createObjective(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllObjectives());
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.matrix_id) {
      dispatch(getIsAvailableLevel(formik.values.matrix_id));
    }
  }, [formik.values.matrix_id]);
  return (
    <GlobalDialog
      title={params ? "Update objective" : "Create Objective"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <>
          {params ? (
            <UpdateButton
              size="small"
              disabled={params.has_child_element === 1 ? true : false}
              action={() => {
                dispatch(
                  getMatricesPreData(profile?.departmentId == 22 ? 22 : 221)
                );
                dispatch(getLevelPreData());
                setOpen(true);
              }}
            >
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size="large"
              action={() => {
                dispatch(
                  getMatricesPreData(profile?.departmentId == 22 ? 22 : 221)
                );
                dispatch(getLevelPreData());
                setOpen(true);
              }}
            >
              Add objective
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <Box>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box>
                  <Stack direction={"column"} spacing={2}>
                    {loading && (
                      <Box>
                        {" "}
                        Loading....
                        <LinearProgress />
                      </Box>
                    )}
                    <GlobalSelectField
                      disabled={loading}
                      options={{
                        formik: formik,
                        label: "Matrix",
                        name: "matrix_id",
                        sx: {
                          width: "100%",
                          // "& .MuiInputBase-root": {
                          //     height: 50,
                          // },
                        },
                        data: matricesPreData,

                        keys: {
                          id: "matrix_id",
                          value: "matrix_name",
                        },
                      }}
                    />

                    <GlobalSelectField
                      disabled={loading}
                      options={{
                        formik: formik,
                        label: "Level",
                        name: "objectiveLevelId",
                        sx: {
                          width: "100%",
                          // "& .MuiInputBase-root": {
                          //     height: 50,
                          // },
                        },
                        data: isAvailableLevelData,

                        keys: {
                          id: "level_id",
                          value: "level_name",
                        },
                      }}
                    />
                    <TextField
                      name="objectiveName"
                      label="Objective Name"
                      onChange={formik.handleChange}
                      value={formik.values.objectiveName}
                      fullWidth
                      autoComplete="off"
                      error={
                        formik.touched.objectiveName &&
                        Boolean(formik.errors.objectiveName)
                      }
                      helperText={formik.errors.objectiveName}
                    />
                    <TextField
                      name="objectiveDescription"
                      label="Description"
                      onChange={formik.handleChange}
                      value={formik.values.objectiveDescription}
                      fullWidth
                      autoComplete="off"
                      error={
                        formik.touched.objectiveDescription &&
                        Boolean(formik.errors.objectiveDescription)
                      }
                      helperText={formik.errors.objectiveDescription}
                    />
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 2,
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      <CancelButton action={() => setOpen(false)}>
                        Cancel
                      </CancelButton>
                      {params ? (
                        <UpdateButton type="submit">
                          {loading ? "Updating..." : "Update"}
                        </UpdateButton>
                      ) : (
                        <SubmitButton type="submit">
                          {loading ? "Saving..." : "Save"}
                        </SubmitButton>
                      )}
                    </Stack>
                  </Box>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </>
      }
    />
  );
};

export default CreateUpdateObjective;
