import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { EditButton } from "../../../mui/Buttons/Buttons";
import React, { useEffect, useState } from "react";
import { Button, Paper, Stack, TextField, Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import dayjs from "dayjs";
import {
    getCurrentLedger,
    addExpense,
} from "../../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

export const EditExpensePopup = ({ params }) => {
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { currentLedger, expenseTypes, studentsforAddIncome } = useSelector(
        (state) => state.cro
    );

    const [formatedDate, setFormatedDAte] = useState("");

    useEffect(() => {
        if (params) {
            function formatDate(inputDateStr) {
                // Parse the input date string
                const date = dayjs(inputDateStr, "DD-MM-YYYY");

                // Format the date to the desired format
                const formattedDate = date.format("MM-DD-YYYY");

                return formattedDate;
            }

            const formattedDate = formatDate(params.transactionDate);
            setFormatedDAte(formattedDate);
        }
    }, [params]);

    const [expenseTypeStatus, setExpenseTypeStatus] = useState("");

    const Schema = Yup.object().shape({
        expenseTypeId: Yup.string().required("expenseTypeId is required"),
        courseEnrollmentId: Yup.string().required(
            "courseEnrollmentId is required"
        ),
        term: Yup.string().required("term is required"),
        year: Yup.string().required("year is required"),
        month: Yup.string().required("month is required"),
        refundAccount: Yup.string().required("refundAccount is required"),
        amount: Yup.string().required("amount is required"),
        expenseRemarks: Yup.string().required("expenseRemarks is required"),
        accountHolder: Yup.string().required("accountHolder is required"),
        ifsc: Yup.string().required("ifsc is required"),
        accountNumber: Yup.string().required("accountNumber is required"),
    });

    const badDeptSchema = Yup.object().shape({
        expenseTypeId: Yup.string().required("expenseTypeId is required"),
        courseEnrollmentId: Yup.string().required(
            "courseEnrollmentId is required"
        ),
        year: Yup.string().required("year is required"),
        month: Yup.string().required("month is required"),
        amount: Yup.string().required("amount is required"),
        expenseRemarks: Yup.string().required("expenseRemarks is required"),
    });

    const termFeeSchema = Yup.object().shape({
        expenseTypeId: Yup.string().required("expenseTypeId is required"),
        courseEnrollmentId: Yup.string().required(
            "courseEnrollmentId is required"
        ),
        term: Yup.string().required("term is required"),
        year: Yup.string().required("year is required"),
        month: Yup.string().required("month is required"),

        amount: Yup.string().required("amount is required"),
        expenseRemarks: Yup.string().required("expenseRemarks is required"),
    });

    const formik = useFormik({
        initialValues: {
            expenseTypeId: "",
            courseEnrollmentId: "",
            year: "",
            month: "",
            amount: "",
            transactionDate: dayjs().format("YYYY-MM-DD"),
            term: "",
            expenseRemarks: "",
            refundAccount: "",
            accountHolder: "",
            ifsc: "",
            accountNumber: "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            console.log("submitted", values);
            dispatch(addExpense(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    useEffect(() => {
        if (formik.values.courseEnrollmentId) {
            dispatch(getCurrentLedger(formik.values.courseEnrollmentId));
        }
    }, [formik.values.courseEnrollmentId]);

    useEffect(() => {
        if (currentLedger) {
            formik.setFieldValue("year", currentLedger.year);
            formik.setFieldValue("month", currentLedger.month);
        }
    }, [currentLedger, formik.values.courseEnrollmentId]);

    return (
        <div>
            <GlobalDialog
                title="Edit Expense"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                closeButton={true}
                popupButton={
                    <>
                        <EditButton size="small" action={() => setOpen(true)} />
                    </>
                }
                content={
                    <div>
                        <Wrapper
                            Content={
                                <>
                                    <Paper
                                        elevation={4}
                                        sx={{ width: "100%", padding: 3 }}
                                    >
                                        <FormikProvider value={formik}>
                                            <Form
                                                onSubmit={formik.handleSubmit}
                                            >
                                                <Stack gap={2}>
                                                    <GlobalSelectField
                                                        options={{
                                                            name: "expenseTypeId",
                                                            formik,
                                                            label: "Expense Type",
                                                            data: expenseTypes,
                                                            keys: {
                                                                id: "id",
                                                                value: "name",
                                                            },
                                                        }}
                                                    />
                                                    <GlobalSelectField
                                                        options={{
                                                            name: "courseEnrollmentId",
                                                            formik,
                                                            label: "Student Name",
                                                            data: studentsforAddIncome,

                                                            keys: {
                                                                id: "id",
                                                                value: "name",
                                                            },
                                                        }}
                                                    />
                                                    <Box>
                                                        <Stack
                                                            direction={"row"}
                                                            spacing={2}
                                                        >
                                                            <TextField
                                                                name="year"
                                                                fullWidth
                                                                label="Year"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .year ||
                                                                    ""
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .year &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .year
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .year
                                                                }
                                                                disabled
                                                            />
                                                            <TextField
                                                                name="month"
                                                                fullWidth
                                                                label="Month"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .month ||
                                                                    ""
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .month &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .month
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .month
                                                                }
                                                                disabled
                                                            />
                                                        </Stack>
                                                    </Box>

                                                    <TextField
                                                        label="Amount"
                                                        name="amount"
                                                        value={
                                                            formik.values.amount
                                                        }
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        error={
                                                            formik.touched
                                                                .amount &&
                                                            Boolean(
                                                                formik.errors
                                                                    .amount
                                                            )
                                                        }
                                                        helperText={
                                                            formik.errors.amount
                                                        }
                                                    />

                                                    <TextField
                                                        label="Remarks"
                                                        name="expenseRemarks"
                                                        placeholder="Type your Remarks here"
                                                        value={
                                                            formik.values
                                                                .expenseRemarks
                                                        }
                                                        multiline
                                                        rows={3}
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        error={
                                                            formik.touched
                                                                .expenseRemarks &&
                                                            Boolean(
                                                                formik.errors
                                                                    .expenseRemarks
                                                            )
                                                        }
                                                        helperText={
                                                            formik.errors
                                                                .expenseRemarks
                                                        }
                                                    />

                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        sx={{
                                                            width: "300px",
                                                            margin: "0 auto",
                                                        }}
                                                    >
                                                        Add Expense
                                                    </Button>
                                                </Stack>
                                            </Form>
                                        </FormikProvider>
                                    </Paper>
                                </>
                            }
                        />
                    </div>
                }
            />
        </div>
    );
};
