import React from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";
import { Form } from "./Form";

export const Notes = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                fullWidth
                maxWidth={"sm"}
                title="Add Notes"
                open={open}
                setOpen={setOpen}
                closeButton={true}
                popupButton={
                    <Button
                        onClick={() => {
                            setOpen(true);
                        }}
                        variant="outlined"
                    >
                        Add Note
                    </Button>
                }
                content={<Form />}
            />
        </div>
    );
};
