import { Box, Button } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

const RedirectToCart = (params) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/my-workspace/cart`, {
      state: params,
    });
  };
  return (
    <Box>
      <Button
        size="small"
        onClick={handleNavigate}
        sx={{
          color: "white",
          background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
          borderRadius: 3,
        }}
      >
        <Icon icon="mdi:cart-outline" height={22} width={22} />
        Go to Cart
      </Button>
    </Box>
  );
};

export default RedirectToCart;
