import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";
import moment from "moment";

const initialState = {
    status: "success",
    message: [],
    data: [],
    departments: [],
    taskType: [],
    filteredTaskTypes: [],
    priority: [],
    filtered: [],
    approvalPreData: [],
    statistics: {
        pending: 0,
        inprogress: 0,
        completed: 0,
    },
    task: {},
    loading: false,
    error: "",
};

export const getPreData = createAsyncThunk("todo/getPreData", async () => {
    const res = await apiRequest({
        method: "GET",
        url: "todo/pre-data",
    });

    return res;
});

export const deptEmployees = createAsyncThunk(
    "employees/deptEmployees",
    async (id) => {
        const res = apiRequest({
            method: "get",
            url: `todo/${id}/employees`,
        });

        return res;
    }
);

export const getMyTask = createAsyncThunk("todo/getMyTask", async () => {
    const res = await apiRequest({
        method: "GET",
        url: "/todo/my-tasks",
    });

    return res;
});

export const getCreatedTask = createAsyncThunk(
    "todo/getCreatedTask",
    async () => {
        const res = await apiRequest({
            method: "GET",
            url: "/todo/created-tasks",
        });
        return res;
    }
);

export const deleteTask = createAsyncThunk("todo/deleteTask", async (id) => {
    const res = await apiRequest({
        method: "DELETE",
        url: `/todo/tasks/${id}`,
    });
    return res;
});

export const editTask = createAsyncThunk("todo/editTask", async (e) => {
    e.body.taskDetails.deadLineTime = moment(e.body.taskDetails.deadLineTime, [
        "h:mm A",
    ]).format("HH:mm:ss");

    const res = await apiRequest({
        method: "put",
        url: `/todo/tasks/${e.id}`,
        data: e.body,
    });

    return res;
});

export const createTask = createAsyncThunk("todo/createTask", async (e) => {
    const res = await apiRequest({
        method: "post",
        url: "todo",
        data: e,
    });

    return res;
});

export const getTask = createAsyncThunk("todo/getTask", async (id) => {
    const res = await apiRequest({
        method: "GET",
        url: `/todo/tasks/${id}`,
    });

    return res;
});

//----department task-----
export const getDepartmentTasks = createAsyncThunk(
    "todo/department_tasks",
    async (e) => {
        const res = await apiRequest({
            method: "GET",
            url: "todo/department-tasks",
        });

        return res;
    }
);

// ---------inward tasks--------

export const getInwardTasks = createAsyncThunk(
    "todo/inwardTasks",
    async (e) => {
        const res = await apiRequest({
            method: "get",
            url: "todo/inward-tasks",
        });

        return res;
    }
);

// ---------outward-tasks--------
export const getOutwardTasks = createAsyncThunk(
    "todo/outwardTasks",
    async (e) => {
        const res = await apiRequest({
            method: "get",
            url: "todo/outward-tasks",
        });

        return res;
    }
);
// approval task pre-data

export const approvalTaskPreData = createAsyncThunk(
    "todo/pre-data",
    async (e) => {
        const res = await apiRequest({
            method: "get",
            url: `todo/approval-pre-data/${e}`,
        });

        return res;
    }
);

// update Task employ level

export const updateTask = createAsyncThunk("todo/updateTask", async (e) => {
    const { taskId, ...others } = e;

    const res = await apiRequest({
        method: "put",
        url: `todo/tasks/${taskId}/status`,
        data: others,
    });

    return res;
});

// approve task for reporting head and HOD

export const approveTask = createAsyncThunk("todo/approveInward", async (e) => {
    const { taskId, ...others } = e;

    const data = {
        ...others,
        deadLineTime: moment(others.deadLineTime, ["h:mm A"]).format(
            "HH:mm:ss"
        ),
    };

    const res = await apiRequest({
        method: "put",
        url: `todo/tasks/${taskId}/approve`,
        data: data,
    });
    return res;
});

const todoSlice = createSlice({
    name: "todo",
    initialState,
    reducers: {
        filterTasks(state, action) {
            state.filtered = state.data.filter(
                (item) => item.taskStatus === action.payload
            );
        },
        filterTaskTypes(state, action) {
            state.filteredTaskTypes = state.taskType.filter(
                (item) => item.task_type_dept === action.payload
            );
        },
        clearData(state) {
            state = {
                ...state,
                data: [],
            };
        },
        clearAll(state) {
            state = initialState;

            return state;
        },
        clearRes(state) {
            return {
                ...state,
                loading: false,
                data: [],
            };
        },
        clearTask(state) {
            return (state = {
                ...state,
                task: {},
            });
        },
    },
    extraReducers: (builder) => {
        //----------------GET PRE DATA-------------------------------
        builder.addCase(getPreData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPreData.fulfilled, (state, action) => {
            //
            state.departments = action.payload.data.departments;
            state.taskType = action.payload.data.taskTypes;
            state.priority = action.payload.data.priorities;
            //
            //
            state.loading = false;
        });
        builder.addCase(getPreData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // ---------------GET TASK--------------------------
        builder.addCase(getTask.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getTask.fulfilled, (state, action) => {
            state.task = action.payload.data.task;

            state.loading = false;
        });
        builder.addCase(getTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //----------------CREATE TASK-------------------------------

        builder.addCase(createTask.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(createTask.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(createTask.rejected, (state, action) => {
            state.loading = false;
        });

        //   ----------------------------getmytask-----------------------
        builder.addCase(getMyTask.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getMyTask.fulfilled, (state, action) => {
            const formatedData = formatDataForDataTable(
                action.payload.data.myTasks
            );
            state.statistics = { ...initialState.statistics };
            state.data = formatedData;
            state.filtered = state.data.filter((item) => {
                if (item.taskStatus === "In Progress") {
                    state.statistics.inprogress++;
                    return null;
                }
                if (item.taskStatus === "Pending") {
                    state.statistics.pending++;
                    return item;
                }
                if (item.taskStatus === "Completed") {
                    state.statistics.completed++;
                    return null;
                }
            });
            state.loading = false;
        });
        builder.addCase(getMyTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //   -----------------getcreateedtask--------------------------------
        builder.addCase(getCreatedTask.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCreatedTask.fulfilled, (state, action) => {
            state.loading = false;

            const formatedData = formatDataForDataTable(
                action.payload.data.createdTasks
            );
            state.data = formatedData;
        });
        builder.addCase(getCreatedTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------deleteTask-----------------------------
        builder.addCase(deleteTask.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteTask.fulfilled, (state, action) => {
            if (action.payload.status === "failed") {
                state.error = action.payload.message;
                state.loading = false;
            } else if (action.payload.status === "success") {
                const rowToUpdateIndex = state.data.findIndex(function (item) {
                    return item.taskId === action.meta.arg;
                });
                state.data.splice(rowToUpdateIndex, 1);
                state.message.push(action.payload.message);
            }
            state.loading = false;
            state.res = action.payload;
        });
        builder.addCase(deleteTask.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //----department tasks--------
        builder.addCase(getDepartmentTasks.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getDepartmentTasks.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                message: action.error.message,
            };
        });
        builder.addCase(getDepartmentTasks.fulfilled, (state, action) => {
            const formatedData = formatDataForDataTable(
                action.payload.data.departmentTasks
            );
            return {
                ...state,
                loading: false,
                data: formatedData,
            };
        });
        //------inwardTasks---------
        builder.addCase(getInwardTasks.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getInwardTasks.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                message: action.error.message,
            };
        });
        builder.addCase(getInwardTasks.fulfilled, (state, action) => {
            const formatedData = formatDataForDataTable(
                action.payload.data.inwardTasks
            );
            return {
                ...state,
                loading: false,
                data: formatedData,
            };
        });

        //------Outward-tasks---------
        builder.addCase(getOutwardTasks.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getOutwardTasks.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                message: action.error.message,
            };
        });
        builder.addCase(getOutwardTasks.fulfilled, (state, action) => {
            const formatedData = formatDataForDataTable(
                action.payload.data.outwardTasks
            );
            return {
                ...state,
                data: formatedData,
                loading: false,
            };
        });

        builder.addCase(approvalTaskPreData.pending, (state, action) => {
            return { ...state, loading: true };
        });
        builder.addCase(approvalTaskPreData.rejected, (state, action) => {
            return {
                ...state,
                message: action.error.message,
                loading: false,
            };
        });
        builder.addCase(approvalTaskPreData.fulfilled, (state, action) => {
            return {
                ...state,
                approvalPreData: action.payload.data.status,
                loading: false,
            };
        });

        //----approve at hod or reporting head---------

        builder.addCase(approveTask.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(approveTask.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        builder.addCase(approveTask.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        //----------- update task-----------------

        builder.addCase(updateTask.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateTask.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        builder.addCase(updateTask.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        builder.addCase(editTask.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editTask.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        builder.addCase(editTask.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
    },
});

// emplotyiee initial state;

const employeeIntialState = {
    status: "success",
    data: [],
    message: "",
    loading: false,
};
const EmploySlice = createSlice({
    name: "employees",
    initialState: employeeIntialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(deptEmployees.pending, (state, action) => {
            return {
                ...state,
                loading: true,
                message: "Employees",
            };
        });
        builder.addCase(deptEmployees.fulfilled, (state, action) => {
            return {
                ...state,
                data: action.payload.data.employees,
                loading: false,
                message: "Employees",
            };
        });
        builder.addCase(deptEmployees.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                message: "Employees",
            };
        });
    },
});
export const {
    filterTasks,
    filterTaskTypes,
    clearData,
    clearAll,
    clearRes,
    clearTask,
} = todoSlice.actions;
export const employeReducer = EmploySlice.reducer;
export default todoSlice.reducer;
