import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { Box, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GlobalDatagrid from "../../../../components/mui/DataGrid/GlobalDatagrid";
import { getCompWorkReq } from "../../../../redux/features/leaveSlice";
import AddCompWorkReq from "./AddCompWorkReq";
import { EMP_COMP_WORK_REQ } from "../../../../components/datagrid/EmployeeService/EpmCompWork/EpmCompWorkTableHead";
import DeleteCompReq from "../../../../components/datagrid/EmployeeService/EpmCompWork/DeleteCompReq";
import GlobalAnchorList from "../../../../components/mui/GlobalAnchor/GlobalAnchorList";
import ActionDetails from "../../../../components/mui/GlobalAnchor/ActionDetails";

const CompensatoryWorkRequest = () => {
  const dispatch = useDispatch();
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData;

  const { loading, empCompData } = useSelector((state) => state.leave);
  const data = {
    page: pageNum,
    limit: gridSize,
    search: search,
    openAnchor,
  };

  useEffect(() => {
    const datas = {
      ...data,
    };
    if (pageNum && gridSize) {
      dispatch(getCompWorkReq(datas));
    }
  }, [pageNum, gridSize, search]);

  return (
    <GlobalWrapper
      title={"Request for Compensatory Work"}
      actions={<AddCompWorkReq update={data} />}
    >
      <Paper elevation={3}>
        <Box sx={{ width: "100%", padding: 0 }}>
          <GlobalDatagrid
            tableHead={EMP_COMP_WORK_REQ}
            rows={empCompData?.data}
            meta={empCompData?.meta?.totalRecords || 0}
            rowUniqueId="sl_no"
            rowClick={true}
            loading={loading}
            actionComponent={
              <>
                <ActionDetails val={rowData?.date} />
                <GlobalAnchorList
                  label={"Delete"}
                  component={
                    <DeleteCompReq
                      data={data}
                      id={rowData}
                      paginationData={paginationData}
                    />
                  }
                />
                <GlobalAnchorList
                  label={"Update Request"}
                  component={
                    <AddCompWorkReq
                      update={data}
                      params={rowData}
                      paginationData={paginationData}
                    />
                  }
                />
              </>
            }
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  );
};

export default CompensatoryWorkRequest;
