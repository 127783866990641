import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import PopUps from "./PopUps";
import CompleatedSessionsTable from "./CompleatedSessionsTable";

const SessionsCompleatedAction = ({ row }) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const componentProps = {
        setOpen: setOpen,
        row: row,
    };
    return (
        <Box>
            <Button onClick={handleClickOpen}>{row.sessionsCompleted}</Button>
            <PopUps
                open={open}
                setOpen={setOpen}
                component={CompleatedSessionsTable}
                componentProps={componentProps}
            />
        </Box>
    );
};

export default SessionsCompleatedAction;
