import { React, useEffect } from "react";
import {
    Container,
    Button,
    Typography,
    Box,
    Stack,
    TextField,
    Paper,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Skeleton,
    Switch,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import Page from "../../../components/Page";
import { useFormik, Form, FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { fetchStudents } from "../../../redux/features/studentsSlice";
import { fetchCoursesOfStudent } from "../../../redux/features/coursesSlice";
import { fetchModulesOfCourse } from "../../../redux/features/modulesSlice";
import { useParams, useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import {
    clearRes,
    scheduleSession,
    clearSchedule,
} from "../../../redux/features/scheduleSlice";
import Iconify from "../../../components/Iconify";
import { useState } from "react";
import { useSnackbar } from "notistack";
import Tag from "../../../components/mui/ChipAutocomplete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import useResponsive from "../../../../src/Hooks/useResponsive";
import {
    editSchedule,
    getScheduleById,
} from "../../../redux/features/scheduleSlice";
import * as Yup from "yup";
import BasicButton from "../../../components/mui/Button";
export default function EditSchedule() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const { id } = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const schedule = useSelector((state) => state.schedule);
    const singleschedule = useSelector(
        (state) => state.schedule.singleschedule
    );

    //Local States
    const [tags, setTags] = useState();
    const [skip, setskip] = useState(false);
    // const EditSchema = Yup.object().shape({
    //   moduleId: Yup.string().required("Please select a module"),
    // });
    useEffect(() => {
        dispatch(getScheduleById(id));
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: id,
            startDate: singleschedule.sessionDate || "",
            startTime: singleschedule.fromTime || "",
            sessionContent: singleschedule.sessionContent || "",
            meetLink: singleschedule.meetLink || "",
        },
        // validationSchema: EditSchema,
        onSubmit: (values) => {
            dispatch(editSchedule(values))
                .then((res) => {
                    navigate("/schedules/Sessions", { replace: true });
                    enqueueSnackbar(res.payload.message);
                })
                .catch((err) => {
                    enqueueSnackbar(err);
                });
        },
    });

    useEffect(() => {
        dispatch(fetchStudents());
        return () => {
            dispatch(clearRes());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleswitch = (e) => {
        setskip([{ skip: e.target.checked }]);
    };

    console.log(schedule.loading);

    return schedule.loading ? (
        <Typography>loading...</Typography>
    ) : (
        <Page title="Create Additional Session">
            {smUp && (
                <Container component="main">
                    {/*..................................HEADING.............................*/}
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Edit Schedule
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: { md: 4, xs: 2 },
                        }}
                    >
                        <Paper elevation={4} sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Stack
                                        sx={{ p: { md: 8, xs: 4 } }}
                                        spacing={3}
                                    >
                                        <Stack direction={"row"} spacing={3}>
                                            {/* -----------------------DATE-PICKER----------------------------- */}
                                            {schedule.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    sx={{
                                                        width: "100%",
                                                        height: 56,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            ) : (
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker
                                                        sx={{
                                                            width: "100%",
                                                            flex: 1,
                                                        }}
                                                        disablePast
                                                        label="Session Date"
                                                        views={[
                                                            "year",
                                                            "month",
                                                            "day",
                                                        ]}
                                                        value={
                                                            formik.values
                                                                .startDate
                                                        }
                                                        name="startDate"
                                                        format="YYYY-MM-DD"
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "startDate",
                                                                e
                                                                    .toISOString()
                                                                    .split(
                                                                        "T"
                                                                    )[0]
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                sx={{
                                                                    width: "50%",
                                                                    flex: 1,
                                                                }}
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            )}

                                            {/* -----------------------FROM-TIME----------------------------- */}
                                            {schedule.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    sx={{
                                                        width: "100%",
                                                        height: 56,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            ) : (
                                                <TextField
                                                    label="Start Time"
                                                    type="time"
                                                    name="startTime"
                                                    value={
                                                        formik.values.startTime
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            "startTime",
                                                            `${e.target.value}:00`
                                                        );
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300,
                                                    }}
                                                    sx={{
                                                        width: "100%",
                                                        flex: 1,
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                        <Stack direction={"row"} spacing={3}>
                                            {/* -----------------------DESCRIPTION----------------------------- */}
                                            {schedule.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    sx={{
                                                        width: "100%",
                                                        height: 130,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            ) : (
                                                <TextField
                                                    disabled={
                                                        singleschedule.isAdditionalSession
                                                            ? false
                                                            : true
                                                    }
                                                    label="Session Content"
                                                    name="sessionContent"
                                                    value={
                                                        formik.values
                                                            .sessionContent
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    multiline
                                                    rows={4}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300,
                                                    }}
                                                    sx={{ width: "100%" }}
                                                />
                                            )}
                                        </Stack>
                                        {/* --------------------------------------------------------------- */}

                                        <Box
                                            sx={{
                                                paddingTop: 5,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <BasicButton
                                                variant="outlined"
                                                size="large"
                                            >
                                                Reset
                                            </BasicButton>
                                            <BasicButton
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                                value="Save"
                                                sx={{ marginLeft: 2 }}
                                            >
                                                Save
                                            </BasicButton>
                                        </Box>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    </Box>
                </Container>
            )}
            {/* ----------------------------------------------responsive-code------------------------------------------------------------------ */}
            {!smUp && (
                <Container component="main">
                    {/*..................................HEADING.............................*/}
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Edit Schedule
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: { md: 4, xs: 2 },
                        }}
                    >
                        <Paper elevation={4} sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Stack
                                        sx={{ p: { md: 8, xs: 4 } }}
                                        spacing={3}
                                    >
                                        <Stack direction={"column"} spacing={3}>
                                            {/* -----------------------DATE-PICKER----------------------------- */}
                                            {schedule.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    sx={{
                                                        width: "100%",
                                                        height: 56,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            ) : (
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DatePicker
                                                        sx={{
                                                            width: "100%",
                                                            flex: 1,
                                                        }}
                                                        disablePast
                                                        label="Session Date"
                                                        views={[
                                                            "year",
                                                            "month",
                                                            "day",
                                                        ]}
                                                        value={
                                                            formik.values
                                                                .startDate
                                                        }
                                                        name="startDate"
                                                        format="YYYY-MM-DD"
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "startDate",
                                                                e
                                                                    .toISOString()
                                                                    .split(
                                                                        "T"
                                                                    )[0]
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            )}

                                            {/* -----------------------FROM-TIME----------------------------- */}
                                            {schedule.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    sx={{
                                                        width: "100%",
                                                        height: 56,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            ) : (
                                                <TextField
                                                    label="Start Time"
                                                    type="time"
                                                    name="startTime"
                                                    value={
                                                        formik.values.startTime
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300,
                                                    }}
                                                    sx={{
                                                        width: "100%",
                                                        flex: 1,
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                        <Stack direction={"row"} spacing={3}>
                                            {/* -----------------------DESCRIPTION----------------------------- */}
                                            {schedule.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    sx={{
                                                        width: "100%",
                                                        height: 130,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            ) : (
                                                <TextField
                                                    disabled={
                                                        singleschedule.isAdditionalSession
                                                            ? false
                                                            : true
                                                    }
                                                    label="Session Content"
                                                    name="sessionContent"
                                                    value={
                                                        formik.values
                                                            .sessionContent
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    multiline
                                                    rows={4}
                                                    type="text"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        step: 300,
                                                    }}
                                                    sx={{ width: "100%" }}
                                                />
                                            )}
                                        </Stack>
                                        {/* --------------------------------------------------------------- */}

                                        <Box
                                            sx={{
                                                paddingTop: 5,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <BasicButton
                                                variant="outlined"
                                                size="medium"
                                            >
                                                Reset
                                            </BasicButton>
                                            <BasicButton
                                                variant="contained"
                                                size="medium"
                                                type="submit"
                                                value="Save"
                                                sx={{ marginLeft: 2 }}
                                            >
                                                Reset
                                            </BasicButton>
                                        </Box>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    </Box>
                </Container>
            )}
        </Page>
    );
}
