import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { Box, Button } from "@mui/material";
import { getScreenshot } from "../../../../redux/features/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import SimpleLoader from "../../../../components/Loader/SimpleLoader";

const ViewScreenShot = ( {pageName,api}) => {
  const [open, setOpen] = useState(false);
  const { loading, screenshotSrc } = useSelector((store) => store[pageName]);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (open ) {
      dispatch(api);
    }
  }, [open]);


  return (
    <GlobalDialog
      maxWidth={"sm"}
      fullWidth={true}
      title={"Screenshot"}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      popupButton={
        <>
          <Button
           size='small'
          variant="outlined"
            sx={{ backgroundColor: "#eff4f9", color: "#3173b4" }}
            onClick={() => setOpen(true)}
          >
            Screenshot
          </Button>
        </>
      }
      content={
        <>
          {loading ? <SimpleLoader/> : (<img src={screenshotSrc} alt="" />)}
        
        </>
      }
    />
  );
};

export default ViewScreenShot;
