import React from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    SaveButton,
} from "../../../mui/Buttons/Buttons";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
    addSessionsForBaseCourses,
    clearModulesData,
    clearSessionsData,
    getSessionsForBaseCourses,
    sessionsData,
} from "../../../../redux/features/researchSlice";
import * as yup from "yup";
import { useSnackbar } from "notistack";

export const AddSessionForm = ({ coursesBaseId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { modulesPreData, sessionsForBaseCourses, loading } = useSelector(
        (state) => state.research
    );
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [time, setTime] = React.useState([{ time: new Date() }]);

    const initialSessionSrlNo = sessionsForBaseCourses?.length + 1;

    const validationSchema = yup.object().shape({
        modules: yup.array().of(
            yup.object().shape({
                sessionSrlNo: yup.number().required("please fill sessionno"),
                // moduleContent: yup
                //     .string()
                //     .required("please fill moduleContent"),
            })
        ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            sessions: [
                {
                    moduleId: "",
                    sessionSrlNo: initialSessionSrlNo,
                    sessionContent: "",
                },
            ],
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(addSessionsForBaseCourses(values)).then((res) => {
                if (
                    res.payload.status === "failed" ||
                    res.payload.status === "error"
                ) {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    resetForm();
                    setOpen(false);
                    dispatch(getSessionsForBaseCourses(coursesBaseId));

                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    // console.log("formik.values", formik.values);

    const handleAdd = () => {
        let timeItem = {
            time: "",
        };

        let newTime = [...time];
        newTime.push(timeItem);

        setTime(newTime);

        let item = {
            moduleId: "",
            sessionSrlNo: initialSessionSrlNo + formik.values.sessions.length,
            sessionContent: "",
        };

        let values = [...formik.values.sessions];
        values.push(item);
        formik.setFieldValue("sessions", values);
    };

    const handleRemove = (i) => {
        if (formik.values.sessions.length > 1) {
            let values = [...formik.values.sessions];
            let newTime = [...time];

            newTime.splice(i, 1);
            values.splice(i, 1);

            formik.setFieldValue("sessions", values);
            setTime(newTime);
        } else {
            formik.setFieldValue("sessions", [
                {
                    moduleId: "",
                    sessionSrlNo: initialSessionSrlNo,
                    sessionContent: "",
                },
            ]);
            setTime([{ time: new Date() }]);
        }
    };

    const handleSlNoChange = (i, e) => {
        let values = [...formik.values.sessions];
        values[i] = { ...values[i], sessionSrlNo: e.target.value };
        formik.setFieldValue("sessions", values);
    };

    const handleModuleNameChange = (i, e) => {
        let values = [...formik.values.sessions];
        values[i] = { ...values[i], sessionContent: e.target.value };
        formik.setFieldValue("sessions", values);
    };

    const handleModuleChange = (i, e) => {
        let values = [...formik.values.sessions];
        values[i] = { ...values[i], moduleId: e.target.value };
        formik.setFieldValue("sessions", values);
    };
    return (
        <div>
            <GlobalDialog
                title="Add Session"
                fullWidth
                maxWidth={"md"}
                open={open}
                setOpen={setOpen}
                popupButton={
                    <AddButton
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Add New Sessions
                    </AddButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    {formik.values.sessions.map((item, i) => {
                                        return (
                                            <Box
                                                key={i}
                                                sx={{
                                                    backgroundColor: "grey.200",
                                                    padding: 2,
                                                    marginBottom: 2,
                                                    borderRadius: 2,
                                                }}
                                            >
                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                >
                                                    <TextField
                                                        type="number"
                                                        name="sessionSrlNo"
                                                        value={
                                                            formik.values
                                                                .sessions[i]
                                                                .sessionSrlNo
                                                        }
                                                        label="slNo"
                                                        onChange={(e) =>
                                                            handleSlNoChange(
                                                                i,
                                                                e
                                                            )
                                                        }
                                                        error={
                                                            formik.touched
                                                                .sessionSrlNo &&
                                                            Boolean(
                                                                formik.errors
                                                                    .sessionSrlNo
                                                            )
                                                        }
                                                        helperText={
                                                            formik.errors
                                                                .sessionSrlNo
                                                        }
                                                        disabled
                                                    />
                                                    {/* -------------------------------------------------------------------- */}
                                                    <FormControl
                                                        variant="outlined"
                                                        sx={{
                                                            minWidth: 160,
                                                            flex: 1,
                                                        }}
                                                        error={
                                                            formik.errors
                                                                .sessions &&
                                                            Boolean(
                                                                formik.errors
                                                                    .sessions[i]
                                                            )
                                                        }
                                                    >
                                                        <InputLabel>
                                                            Modules
                                                        </InputLabel>
                                                        <Select
                                                            label="Modules"
                                                            value={
                                                                formik.values
                                                                    .sessions[i]
                                                                    .moduleId
                                                            }
                                                            onChange={(e) =>
                                                                handleModuleChange(
                                                                    i,
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            {modulesPreData.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            option.moduleId
                                                                        }
                                                                    >
                                                                        {
                                                                            option.module
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                    {/* ------------------------------------------------ */}
                                                    <TextField
                                                        name="sessionContent"
                                                        value={
                                                            formik.values
                                                                .sessions[i]
                                                                .sessionContent
                                                        }
                                                        fullWidth
                                                        label="Session Content"
                                                        onChange={(e) =>
                                                            handleModuleNameChange(
                                                                i,
                                                                e
                                                            )
                                                        }
                                                        error={
                                                            formik.touched
                                                                .sessionContent &&
                                                            Boolean(
                                                                formik.errors
                                                                    .sessionContent
                                                            )
                                                        }
                                                        helperText={
                                                            formik.errors
                                                                .sessionContent
                                                        }
                                                        required
                                                    />
                                                </Stack>
                                                {formik.values.sessions.length +
                                                    initialSessionSrlNo -
                                                    1 ===
                                                formik.values.sessions[i]
                                                    .sessionSrlNo ? (
                                                    <Stack
                                                        direction={"row"}
                                                        gap={2}
                                                    >
                                                        <Button
                                                            onClick={handleAdd}
                                                        >
                                                            <Icon
                                                                icon="material-symbols:add"
                                                                height="28"
                                                            />
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                handleRemove(i)
                                                            }
                                                        >
                                                            <Icon
                                                                icon="material-symbols:remove"
                                                                height="28"
                                                            />
                                                        </Button>
                                                    </Stack>
                                                ) : null}
                                            </Box>
                                        );
                                    })}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={1}>
                                            <CancelButton
                                                action={() => {
                                                    if (
                                                        formik.values
                                                            .sessions[0]
                                                            .sessionSrlNo ===
                                                            1 &&
                                                        formik.values
                                                            .sessions[0]
                                                            .sessionContent ===
                                                            ""
                                                    ) {
                                                        dispatch(
                                                            clearSessionsData()
                                                        );
                                                    }
                                                    setOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton
                                                action={() => {}}
                                                type="submit"
                                            >
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
