import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";
// import { OgaEnquiryLogView, OgaStatusLogView } from "./RenderCellMain";
import ToolTip from "../../../../ToolTip/ToolTip";
import UpdateLead from "./UpdateLead";
import { UpdateLeavdView } from "./RenderCellMain";
import { useCallback } from "react";

// const ogmStatus = (params) => {
//   return (
//     <Chip
//       variant="outlined"
//       size="small"
//       icon={
//         params.row.sro_assigned ? (
//           <Icon icon="mdi:tick" />
//         ) : (
//           <Icon icon="gridicons:cross" />
//         )
//       }
//       color={params.row.sro_assigned ? "success" : "error"}
//       label={params.row.sro_assigned ? "Assigned" : "Not Assigned"}
//     />
//   );
// };

const ogaLeadStatus = (params) => {
  return (
    <Chip
      variant="outlined"
      size="small"
      icon={
        params.row.sro_lead_status_name == "Admission Confirmed" ? (
          <Icon icon="mdi:tick" />
        ) : (
          <Icon icon="gridicons:cross" />
        )
      }
      color={
        params.row.sro_lead_status_name == "Admission Confirmed"
          ? "success"
          : "warning"
      }
      label={
        params.row.sro_lead_status_name
          ? params.row.sro_lead_status_name
          : "Not Updated"
      }
    />
  );
};

// const ogaStatusLog = (params) => {
//   return (
//     <>{params.row.sro_assigned ? <OgaStatusLogView {...params.row} /> : ""}</>
//   );
// };

// const ogaEnquiryLog = (params) => {
//   return (
//     <>{params.row.sro_assigned ? <OgaEnquiryLogView {...params.row} /> : ""}</>
//   );
// };

const ogaRemarksToolTip = (params) => {
  return (
    <ToolTip content={params.row.sro_remarks} expand={params.row.sro_remarks} />
  );
};
// const UpdateLeads = (params) => {
//   return <UpdateLeavdView />;
// };
// const UpdateLeads = useCallback((params) => {
//   return <UpdateLeavdView {...params} />;
// }, []);
export const OGA_LEADS_TABLE_HEAD = [
  {
    field: "sl_no",
    headerName: "slNo",
    width: 20,
  },
  {
    field: "lead_type_name",
    headerName: "Lead Type",
    width: 150,
  },
  {
    field: "source_name",
    headerName: "Source",
    width: 120,
  },
  {
    field: "lead_name",
    headerName: "Lead Name",
    width: 200,
  },
  {
    field: "lead_contact_person",
    headerName: "contact Person",
    width: 200,
  },
  {
    field: "lead_mob_no",
    headerName: "Contact",
    width: 120,
  },
  {
    field: "lead_place",
    headerName: "Place",
    width: 140,
  },
  {
    field: "user",
    headerName: "user",
    width: 200,
  },
  {
    field: "lead_timestamp",
    headerName: "Lead Came On",
    width: 180,
  },
  {
    field: "course_name",
    headerName: "Course Name",
    width: 150,
  },
  // {
  //   field: "sro_lead_status_name",
  //   headerName: "OGA Lead Status",
  //   width: 200,
  //   renderCell: ogaLeadStatus,
  // },
  // {
  //   field: "SROStatusLog",
  //   headerName: "OGA Status Log",
  //   width: 120,
  //   renderCell: ogaStatusLog,
  // },
  // {
  //   field: "sro_lead_enquiry_status_name",
  //   headerName: "OGA Lead Status Name",
  //   width: 160,
  // },
  {
    field: "sro_lead_enquiry_date",
    headerName: "Next Enquiry Date",
    width: 160,
  },
  // {
  //   field: "enquiry_log",
  //   headerName: "Enquiry_log",
  //   width: 120,
  //   renderCell: ogaEnquiryLog,
  // },
  {
    field: "sro_last_updated_on",
    headerName: "OGA Last Updated On",
    width: 200,
  },
  {
    field: "sro_remarks",
    headerName: "OGA Remarks",
    width: 300,
    disableClickEventBubbling: true,
    renderCell: ogaRemarksToolTip,
  },
  // {
  //   field: "update_lead",
  //   headerName: "Update Lead",
  //   width: 300,
  //   disableClickEventBubbling: true,
  //   renderCell: UpdateLeads,
  // },

  {
    field: "cro_name",
    headerName: "CRO",
    width: 200,
  },
  {
    field: "cro_lead_status_name",
    headerName: "CRO Lead Status",
    width: 200,
  },
];
