import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    SaveButton,
} from "../../../../mui/Buttons/Buttons";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Alert, Box, Stack, TextField } from "@mui/material";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import { useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";

export const AddSubjectPopup = ({ subEnroll, courseEnrollmentDtldId }) => {
    const [open, setOpen] = useState(false);
    const { levelTypePreData, subjectsByClassPreData } = useSelector(
        (state) => state.cro
    );
    const [value, setValue] = React.useState(1);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const formik = useFormik({
        initialValues: {
            sessionsTypeId: "",
            categoryId: "",
            noOfSessions: "",
            baseCourseId: "",
            Subject: "",
        },
        onSubmit: (values) => {},
    });
    return (
        <div>
            <GlobalDialog
                title="Add Subject"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                closeButton={courseEnrollmentDtldId ? false : true}
                popupButton={
                    <AddButton action={() => setOpen(true)}>
                        Add Subject
                    </AddButton>
                }
                content={
                    <>
                        <Box>
                            <Box>
                                {/* <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <FormControlLabel
                                                value={1}
                                                control={<Radio />}
                                                label="Base Subjects"
                                            />
                                            <FormControlLabel
                                                value={2}
                                                control={<Radio />}
                                                label="Add On Subjects"
                                            />
                                            <FormControlLabel
                                                value={3}
                                                control={<Radio />}
                                                label="Addisional Sessions"
                                            />
                                        </Stack>
                                    </RadioGroup>
                                </FormControl> */}
                            </Box>
                            {courseEnrollmentDtldId ? (
                                <Box>
                                    <Box
                                        sx={{
                                            backgroundColor: "grey.200",
                                            borderRadius: 1,
                                            p: 2,
                                        }}
                                    >
                                        <Box>
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <GlobalSelectField
                                                    options={{
                                                        name: "Subject",
                                                        formik,
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                        label: "Subject",
                                                        data: subjectsByClassPreData,
                                                        keys: {
                                                            id: "subjId",
                                                            value: "subjectName",
                                                        },
                                                    }}
                                                />
                                                {subEnroll?.length <=
                                                1 ? null : (
                                                    <GlobalSelectField
                                                        options={{
                                                            name: "categoryId",
                                                            // formik,
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                            label: "Level",
                                                            data: levelTypePreData,
                                                            keys: {
                                                                id: "miscMastersId",
                                                                value: "mastersName",
                                                            },
                                                        }}
                                                    />
                                                )}

                                                <TextField
                                                    label="No Of Sessions"
                                                    width={"50%"}
                                                />
                                            </Stack>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            mt: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton>Save</SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            ) : (
                                <Alert severity="warning">
                                    No Course Enrollments Details Found..!
                                </Alert>
                            )}
                        </Box>
                    </>
                }
            />
        </div>
    );
};
