import { divide } from "lodash";
import UpdateSessionCountActions from "./UpdateSessionCountActions";

export const UPDT_SESSN_TBL_HEAD = [
    {
        field: "subjectName",
        headerName: "Subject",
        width: 150,
    },
    {
        field: "totalSessions",
        headerName: "Total Sessions",
        minWidth: 300,
        flex: 1,
        renderCell: (params) => <UpdateSessionCountActions row={params.row} />,
    },
];
