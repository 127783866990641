import React from "react";
import { Autocomplete } from "@mui/material";

export default function BasicAutocomplete({
  sx,
  name,
  options,
  getOptionLabel,
  action,
  renderInput,
  renderOption,
}) {
  return (
    <Autocomplete
      sx={sx}
      name={name}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={action}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  );
}
