import React from "react";
import CustomGridToolbar from "../../GridToolBar";
import { DataGrid } from "@mui/x-data-grid";
import { EMPLOYEES_TABLE_HEAD } from "./EmployeesTableHead";

const EmployeesTable = ({ data, loading }) => {
    return (
        <>
            <h2
                style={{
                    marginBottom: "10px",
                }}
            >
                List of Employees
            </h2>
            <DataGrid
                loading={loading}
                rows={data}
                columns={EMPLOYEES_TABLE_HEAD}
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
                pageSize={5}
                autoHeight
                rowsPerPageOptions={[5, 10, 25, 50]}
                getRowId={(row) => row.serialNumber}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </>
    );
};

export default EmployeesTable;
