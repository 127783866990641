import React from 'react'
import { ApproveButton, RejectButton } from '../../../../mui/Buttons/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import { getEveningFoodList, getFoodList ,verifyStatus} from '../../../../../redux/features/foodSlice'
import { useSnackbar } from "notistack";


const VerifyAction = ({status,id,date,shift}) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch=useDispatch()
     const handleAction=()=>{
       dispatch(verifyStatus(id)).then((res)=>{
        if(res.payload.status == "failed" || res.payload.status == "error"){
            enqueueSnackbar(`${res.payload.message}`, { variant: "error" });
        }else{
            if(shift==1){
                dispatch(getFoodList({date:date}))
            }else if(shift==2){
                dispatch(getEveningFoodList({date:date}))
            }
        }
       })
     }
  return (
   <>
   {status==0 ? (
    <ApproveButton action={handleAction}>Verify</ApproveButton>
   ):(
    <RejectButton sx={{  marginLeft: 2,}} action={handleAction}>Revert</RejectButton>
   )}
   </>
  )
}

export default VerifyAction