import React from "react";
import Wrapper from "../../../../../components/Wrapper/Wrapper";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import GlobalSelectField from "../../../../../components/mui/GlobalSelectField";
import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
    LogButton,
    SubmitButton,
} from "../../../../../components/mui/Buttons/Buttons";

import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomDataGrid from "../../../../../components/mui/DataGrid/CustomDataGrid";
import { Details } from "./Details";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { getCroEnquiryLog } from "../../../../../redux/features/croSlice";
import { CRO_ENQUIRY_LOG_TABLE_HEAD as columns } from "../../../../../components/datagrid/myworkspace/cro/CroEnquiryLog/CroEnquiryLogTableHead";

export default function CroEnquiryLog() {
    const dispatch = useDispatch();
    const cros = useSelector((state) => state.crm);
    const data = useSelector((state) => state.cro);
    const [value, setValue] = React.useState(dayjs("2022-04-07"));

    const navigate = useNavigate();

    const pro = [
        {
            id: 1,
            name: "Foundation Programmes",
        },
        {
            id: 2,
            name: "Classroom Programmes",
        },
    ];
    const profile = JSON.parse(localStorage.getItem("cred")).profile;

    const validation = () => {
        if (profile.roleID === 106) {
            return Yup.object().shape({
                croId: Yup.string().required("croId is required"),
                CourseTypeId: Yup.string().required("CourseTypeId is required"),
            });
        } else {
            return Yup.object().shape({
                CourseTypeId: Yup.string().required("CourseTypeId is required"),
            });
        }
    };

    const formik = useFormik({
        initialValues: {
            CourseTypeId: "",
            croId: "",
            date: "",
        },
        validationSchema: validation,
        onSubmit: (values) => {
            dispatch(getCroEnquiryLog(values));
        },
    });

    const Head = [
        {
            field: "name",
            headerName: "Student Name",
            width: 250,
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <div>
                <Wrapper
                    title="CRO Enquiry Log"
                    Content={
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: 2,
                                }}
                            >
                                <LogButton
                                    action={() => {
                                        navigate("/my-tasks/cro-enquiries");
                                    }}
                                >
                                    Pending Enquiries
                                </LogButton>
                            </Box>
                            <Paper>
                                <FormikProvider value={formik}>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Box
                                            sx={{
                                                backgroundColor: "grey.200",
                                                borderRadius: "8px 8px 0 0",
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                padding: 2,
                                            }}
                                        >
                                            <Box width={"60%"} padding={2}>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                >
                                                    <GlobalSelectField
                                                        options={{
                                                            formik: formik,
                                                            label: "Course Type",
                                                            name: "CourseTypeId",
                                                            // sx: { width: "100%" },
                                                            data: pro,

                                                            keys: {
                                                                id: "id",
                                                                value: "name",
                                                            },
                                                        }}
                                                    />
                                                    {profile.roleID == 106 ? (
                                                        <GlobalSelectField
                                                            options={{
                                                                formik: formik,
                                                                label: "CRO",
                                                                name: "croId",
                                                                // sx: { width: "100%" },
                                                                data: cros?.AssignedCros,

                                                                keys: {
                                                                    id: "id",
                                                                    value: "name",
                                                                },
                                                            }}
                                                        />
                                                    ) : null}
                                                    <Box>
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                name="date"
                                                                views={[
                                                                    "year",
                                                                    "month",
                                                                ]}
                                                                label="Year and Month"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .date
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "date",
                                                                        e.format(
                                                                            "YYYY/MM"
                                                                        )
                                                                    );
                                                                }}
                                                                inputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        helperText={
                                                                            null
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>

                                                    <Box paddingTop={1.5}>
                                                        <SubmitButton type="submit">
                                                            Submit
                                                        </SubmitButton>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Form>
                                </FormikProvider>
                            </Paper>

                            <Box>
                                <CustomDataGrid
                                    loading={data.loading}
                                    data={data.CroEnquiryLog}
                                    rowId={"serialNumber"}
                                    columns={columns}
                                    Head={Head}
                                    setSelectedRows={setSelectedRows}
                                    selectedRows={selectedRows}
                                    chipValue={selectedRows[0]?.admissionNo}
                                    label={"AddNo"}
                                    name={selectedRows[0]?.name}
                                    details={<Details data={selectedRows[0]} />}
                                    actions={
                                        <Typography variant="h4">
                                            There is no Actions available...!
                                        </Typography>
                                    }
                                />
                            </Box>
                        </>
                    }
                />
            </div>
        </div>
    );
}
