import React from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


export default function BasicDatePicker({sx,label,format,views,value,action,disablePast,shouldDisableDate,error,renderInput,readOnly}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
        sx={sx}
        label={label}
        format={format}
        views={views}
        value={value}
        onChange={action}
        renderInput={renderInput}
        disablePast={disablePast}
        shouldDisableDate={shouldDisableDate}
        error={error}
        readOnly={readOnly}
      />
      </LocalizationProvider>
  )
}
