import React, { useEffect } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Box, Chip, Paper, Stack } from "@mui/material";
import {
    AddButton,
    CancelButton,
    SaveButton,
} from "../../../mui/Buttons/Buttons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddSessionForm } from "./AddSessionForm";
import { useSelector, useDispatch } from "react-redux";
import NoDataFound from "../../../NodataIndication/NoDataFound";
import {
    addModulesToBaseCourses,
    addSessionsToBaseCourses,
    clearModulesData,
    getModulesPreData,
    getSessionsForBaseCourses,
    updateBaseCourseSession,
} from "../../../../redux/features/researchSlice";
import { useSnackbar } from "notistack";

export const AddSessionPopup = ({ params }) => {
    // console.log("params.params", params);
    const dispatch = useDispatch();
    const { sessionsData, baseCourseId, loading, sessionsForBaseCourses } =
        useSelector((state) => state.research);

    useEffect(() => {
        console.log("sessionsForBaseCourses", sessionsForBaseCourses);
    }, [sessionsForBaseCourses]);

    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState();
    const { enqueueSnackbar } = useSnackbar();

    const newData = [...sessionsForBaseCourses];

    const myNewData = [];

    for (const item of newData) {
        myNewData.push({
            moduleId: item.moduleId,
            moduleSrlNo: item.moduleSrlNo,
            SessionId: item.SessionId,
            sessionSrlNo: item.sessionSrlNo,
            sessionContent: item.sessionContent,
        });
    }

    //   ----------------------------------------------------------------------------------------------
    const itemData = [...myNewData];

    const [items, setItems] = React.useState(itemData);

    useEffect(() => {
        setItems(itemData);
    }, [sessionsForBaseCourses]);

    const onDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside of the list

        const srcI = result.source.index + 1;
        const desI = result.destination.index + 1;
        let sourceIndx, destIndx;
        items.map((item, index) => {
            if (item.sessionSrlNo == srcI) sourceIndx = index;
            if (item.sessionSrlNo == desI) destIndx = index;
        });
        const temp = items[sourceIndx].sessionContent;
        items[sourceIndx].sessionContent = items[destIndx].sessionContent;
        items[destIndx].sessionContent = temp;
        const tempSessionId = items[sourceIndx].SessionId;
        items[sourceIndx].SessionId = items[destIndx].SessionId;
        items[destIndx].SessionId = tempSessionId;

        const tempModuleId = items[sourceIndx].moduleId;
        items[sourceIndx].moduleId = items[destIndx].moduleId;
        items[destIndx].moduleId = tempModuleId;

        const updateLists = [...items];
        console.log("updateLists", updateLists);
        setItems(updateLists);
    };
    const sessionsList = {
        sessions: items,
    };
    console.log("sessionsList", sessionsList);

    const handleClick = () => {
        dispatch(updateBaseCourseSession(sessionsList)).then((res) => {
            if (
                res.payload.status === "failed" ||
                res.payload.status === "error"
            ) {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                setOpen(false);
                dispatch(clearModulesData());
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
            }
        });
    };

    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                title="Sessions"
                maxWidth={"md"}
                closeButton={true}
                popupButton={
                    <Box padding={1}>
                        <AddButton
                            size="small"
                            action={() => {
                                dispatch(
                                    getSessionsForBaseCourses(
                                        params.baseCourseId
                                    )
                                );
                                dispatch(
                                    getModulesPreData(params.baseCourseId)
                                );

                                setOpen(true);
                            }}
                        >
                            Sessions
                        </AddButton>
                    </Box>
                }
                content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end   ",
                                marginBottom: 2,
                            }}
                        >
                            <AddSessionForm
                                coursesBaseId={params.baseCourseId}
                            />
                        </Box>
                        {sessionsForBaseCourses ? (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        backgroundColor: "#4a84bd54",
                                        borderRadius: 1,
                                        padding: 1,
                                    }}
                                >
                                    <Stack direction={"column"} spacing={2}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Chip
                                                label={<h2>Sessions List</h2>}
                                                variant="outlined"
                                                color="primary"
                                                size="large"
                                            />
                                        </Box>

                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                                {(provided) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        {items.map(
                                                            (item, index) => {
                                                                return (
                                                                    <>
                                                                        <Stack
                                                                            direction={
                                                                                "row"
                                                                            }
                                                                            spacing={
                                                                                2
                                                                            }
                                                                            padding={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Box>
                                                                                <Paper
                                                                                    elevation={
                                                                                        3
                                                                                    }
                                                                                    sx={{
                                                                                        height: 50,
                                                                                        width: 70,
                                                                                        display:
                                                                                            "flex",
                                                                                        justifyContent:
                                                                                            "center",
                                                                                        alignItems:
                                                                                            "center",
                                                                                        backgroundColor:
                                                                                            "#4a84bd54",
                                                                                    }}
                                                                                    key={
                                                                                        item.sessionSrlNo
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.sessionSrlNo
                                                                                    }
                                                                                </Paper>
                                                                            </Box>
                                                                            <Draggable
                                                                                key={
                                                                                    item.sessionSrlNo
                                                                                }
                                                                                draggableId={
                                                                                    "draggable-" +
                                                                                    item.sessionSrlNo
                                                                                }
                                                                                index={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {(
                                                                                    provided,
                                                                                    snapshot
                                                                                ) => (
                                                                                    <Box
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        style={{
                                                                                            ...provided
                                                                                                .draggableProps
                                                                                                .style,
                                                                                            boxShadow:
                                                                                                snapshot.isDragging
                                                                                                    ? "0 0 .4rem #666"
                                                                                                    : "none",
                                                                                        }}
                                                                                    >
                                                                                        <Stack
                                                                                            direction={
                                                                                                "row"
                                                                                            }
                                                                                            spacing={
                                                                                                2
                                                                                            }
                                                                                        >
                                                                                            <Paper
                                                                                                elevation={
                                                                                                    3
                                                                                                }
                                                                                                sx={{
                                                                                                    height: 50,
                                                                                                    minWidth: 500,
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    justifyContent:
                                                                                                        "center",
                                                                                                    alignItems:
                                                                                                        "center",
                                                                                                }}
                                                                                            >
                                                                                                {`${item.sessionContent} (${item.SessionId})`}
                                                                                            </Paper>
                                                                                            <Paper
                                                                                                elevation={
                                                                                                    3
                                                                                                }
                                                                                                sx={{
                                                                                                    height: 50,
                                                                                                    minWidth: 50,
                                                                                                    display:
                                                                                                        "flex",
                                                                                                    justifyContent:
                                                                                                        "center",
                                                                                                    alignItems:
                                                                                                        "center",
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    item.moduleSrlNo
                                                                                                }
                                                                                            </Paper>
                                                                                        </Stack>
                                                                                    </Box>
                                                                                )}
                                                                            </Draggable>
                                                                        </Stack>
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                    </Box>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Stack>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: 2,
                                    }}
                                >
                                    <Stack direction={"row"} spacing={1}>
                                        <CancelButton
                                            action={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <SaveButton action={handleClick}>
                                            {loading ? "Saving..." : "Save"}
                                        </SaveButton>
                                    </Stack>
                                </Box>
                            </>
                        ) : (
                            <NoDataFound />
                        )}
                    </>
                }
            />
        </div>
    );
};
