import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { DashboardWidgetSummaryCardView } from "../DashboadIndex";
import DashboardWidgetCard from "../../../../components/Dashboard/GlobalComponents/DashboardWidgetCard";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GlobalBarChart } from "../../../../components/Dashboard/GlobalComponents/GlobalBarChart";
import GlobalDonutChart from "../../../../components/Dashboard/GlobalComponents/GlobalDonutChart";
import GlobalPieChart from "../../../../components/Dashboard/GlobalComponents/GlobalPieChart";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";

const DigitalMarketingAnalytics = () => {
    const attendanceData = {
        present: 25172,
        absentCount: 110,
        weekoffCount: 5324,
        CompensatoryOff: 302.5,
        onDuty: 154,
        workFromHome: 694.5,
        halfDay: 0,
        holiday: 1015,
        leaveCasualLeave: 632.5,
        leaveSickLeave: 677,
        leavePaidLeave: 215.5,
        leaveMaternityLeave: 15,
        leaveWithoutPay: 253.5,
        Dot: 457,
    };
    const theme = useTheme();
    const chartData = [
        { label: "hello", value: 100 },
        { label: "hai", value: 200 },
    ];
    const data = [
        { label: "hello", value: 100 },
        { label: "hai", value: 200 },
    ];
    return (
        <div>
            <GlobalWrapper title="hello">
                <Box mt={3}>
                    <GlobalBarChart
                        title={"test"}
                        subheader={"test bar"}
                        chartData={attendanceData}
                        horizontal={true}
                    />
                </Box>
            </GlobalWrapper>
            <Wrapper
                title={"Digital Marketing Analytics"}
                Content={
                    <>
                        <DashboardWidgetSummaryCardView
                            title="test"
                            total={300}
                            icon={"grommet-icons:test"}
                        />
                        <Box mt={2}>
                            <DashboardWidgetCard
                                title="test"
                                total={300}
                                reportingHead="test"
                                chartData={[1, 4, 2, 6, 8, 9, 4]}
                                chartColor={theme.palette.primary.main}
                            />
                        </Box>
                        <Box mt={3}>
                            <GlobalBarChart
                                title={"test"}
                                subheader={"test bar"}
                                chartData={attendanceData}
                                horizontal={true}
                            />
                        </Box>
                        <Box mt={3}>
                            <GlobalDonutChart
                                title={"test"}
                                chipValue={"test"}
                                chartData={chartData}
                            />
                        </Box>
                        <Box mt={2}>
                            <GlobalPieChart title={"test"} data={data} />
                        </Box>
                    </>
                }
            />
        </div>
    );
};

export default DigitalMarketingAnalytics;
