import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";
import AddIncome from "../../../../views/cro/myworkspace/AddIncome";
import { getStudentsforAddIncome } from "../../../../redux/features/croSlice";
import { useDispatch } from "react-redux";
import { EditButton } from "../../../mui/Buttons/Buttons";

export const EditIncomePopup = ({ params }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) {
            dispatch(getStudentsforAddIncome());
        }
    }, [open]);

    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                title="Edit Income"
                closeButton={true}
                maxWidth={"md"}
                popupButton={
                    <EditButton size="small" action={() => setOpen(true)} />
                }
                fullWidth
                content={<AddIncome params={params} />}
            />
        </div>
    );
};
