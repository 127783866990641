import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";

export const fetchModulesOfCourse = createAsyncThunk(
    "modules/fetchModulesOfCourse",
    async (id) => {
        const res = await apiRequest({
            method: "GET",
            url: `courses/course-modules/${id}`,
        });
        res.data.modules.sort((a, b) => a.module_num > b.module_num);
        //
        return res.data;
    }
);

const modulesSlice = createSlice({
    name: "modules",
    initialState: {
        loading: false,
        data: [],
        error: "",
    },
    extraReducers: (builder) => {
        builder.addCase(fetchModulesOfCourse.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchModulesOfCourse.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.modules;
        });
        builder.addCase(fetchModulesOfCourse.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});
export default modulesSlice.reducer;
