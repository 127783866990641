import { Chip } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";

export const SessionsStatus = ({ status }) => {
    if (status === "Completed") {
        return (
            <Chip
                icon={<Icon icon="charm:tick" width="22" height="22" />}
                label="Completed"
                size="small"
                variant="outlined"
                color="success"
            />
        );
    } else if (status === "Incomplete") {
        return (
            <Chip
                icon={
                    <Icon icon="radix-icons:cross-2" width="22" height="22" />
                }
                label="Incomplete"
                size="small"
                variant="outlined"
                color="warning"
            />
        );
    } else if (status === "Trainer Cancelled") {
        return (
            <Chip
                icon={
                    <Icon icon="radix-icons:cross-2" width="22" height="22" />
                }
                label="Trainer Cancelled"
                size="small"
                variant="outlined"
                color="error"
            />
        );
    } else if (status === "CRO Cancelled") {
        return (
            <Chip
                icon={
                    <Icon icon="radix-icons:cross-2" width="22" height="22" />
                }
                label="CRO Cancelled"
                size="small"
                variant="outlined"
                color="error"
            />
        );
    } else if (status === "Expired") {
        return (
            <Chip
                icon={
                    <Icon icon="radix-icons:cross-2" width="22" height="22" />
                }
                label="Expired"
                size="small"
                variant="outlined"
                color="error"
            />
        );
    }
};
