import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Button, Stack } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import {
    CancelButton,
    SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";

export const Form = () => {
    const [value, setValue] = React.useState(null);
    return (
        <div>
            <Box padding={3}>
                <Stack direction={"column"} spacing={2}>
                    <Box>
                        <Autocomplete
                            disablePortal
                            id="Trainer Name"
                            options={top100Films}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    {...params}
                                    label="Trainer Name"
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Transaction Date "
                                value={value}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField fullWidth {...params} />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box>
                        <TextField label="Amount" fullWidth />
                    </Box>
                    <Box>
                        <GlobalSelectField
                            options={{
                                // formik: formik,
                                label: "Payment Mode",
                                name: "PaymentMode",
                                sx: { width: "100%" },
                                // data: data.coursesPreData,

                                keys: {
                                    id: "courseId",
                                    value: "courseName",
                                },
                            }}
                        />
                    </Box>
                    <Box>
                        <TextField fullWidth name="upload-photo" type="file" />
                    </Box>
                    <Box>
                        <TextField
                            label="Remark"
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </Box>
                </Stack>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 2,
                    }}
                >
                    <Stack direction={"row"} spacing={2}>
                        <CancelButton>Cancel</CancelButton>
                        <SubmitButton>Submit</SubmitButton>
                    </Stack>
                </Box>
            </Box>
        </div>
    );
};

const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
];
