import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
  loading: false,
  leadType: [],
  ogmLeads: [],
  ogaList: [],
  sroStatusLog: [],
  ogaEnquiryLog: [],
  subjectWiseAssessmentdata: [],
  countryData: [],
  ogaLeadStatus: [],
  ogaLeadEnquiryStatus: [],
  leadDataById: [],
  admissionRegisterData: [],
  meta: {},
  employeesList: [],
  courses: [],
  studentProfile: {},
  studentFeeStructure: [],
  ogaLeadStudents: [],
};
export const getLeadTypes = createAsyncThunk("ogd/getLeadTypes", async () => {
  const res = await apiRequest({
    method: "get",
    url: `ogd/get-lead-types`,
  });
  return res;
});

export const getOgmLeads = createAsyncThunk("ogd/getOgmLeads", async (data) => {
  const res = await apiRequest({
    method: "get",
    url: `ogd/get-ogm-leads/${data.date}/${data.leadType}?page=${
      data.page
    }&limit=${data.limit}&search=${
      data.search ? encodeURIComponent(data.search) : ""
    }`,
  });
  return res;
});
export const getOgaList = createAsyncThunk("ogd/getOgaList", async () => {
  const res = await apiRequest({
    method: "get",
    url: `ogd/get-oga-list`,
  });
  return res;
});
export const assignOga = createAsyncThunk("ogd/assignOga", async (data) => {
  const res = await apiRequest({
    method: "post",
    url: `ogd/assign-oga`,
    data: data,
  });
  return res;
});
export const getOgaStatusLog = createAsyncThunk(
  "ogd/getOgaStatusLog",
  async (id) => {
    const res = await apiRequest({
      method: "get",
      url: `ogd/get-oga-status-log/${id}`,
    });
    return res;
  }
);
export const getOgaEnquiryLog = createAsyncThunk(
  "ogd/getOgaEnquiryLog",
  async (id) => {
    const res = await apiRequest({
      method: "get",
      url: `ogd/get-oga-enquiry-log/${id}`,
    });
    return res;
  }
);
export const getSubjectWiseAssessmentData = createAsyncThunk(
  "ogd/getSubjectWiseAssessmentData",
  async (id) => {
    const res = await apiRequest({
      method: "get",
      url: `ogd/get-oga-lead-subject-wise-assessment/${id}`,
    });
    return res;
  }
);
export const UpdateExpenses = createAsyncThunk(
  "ogd/UpdateExpenses",
  async (data) => {
    const res = await apiRequest({
      method: "put",
      url: `general/updateExpense/${data.id}`,
      data: data,
    });
    return res;
  }
);

export const getAdmissionRegisterOgd = createAsyncThunk(
  "ogd/getAdmissionRegister",
  async (data) => {
    // console.log(`ogd/admission-register?page=${data.page}&limit=${data.limit}&year=${data.year}&id=${data.id}&search=${data.search? encodeURIComponent(data.search):""}`);
    const res = await apiRequest({
      method: "get",
      url: `ogd/admission-register?page=${data.page}&limit=${data.limit}&year=${
        data.year
      }&id=${data.id}&search=${
        data.search ? encodeURIComponent(data.search) : ""
      }`,
    });
    // console.log(res);
    return res;
  }
);

export const getEmployeesUnderHead = createAsyncThunk(
  "ogd/getEmployeesUnderHead",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `general/all-employees-of-head`,
    });
    return res;
  }
);

export const getCourses = createAsyncThunk("ogd/getCourses", async () => {
  const res = await apiRequest({
    method: "get",
    url: `ogd/all-courses`,
  });
  return res;
});

export const getStudentsProfile = createAsyncThunk(
  "ogd/getStudentsProfile",
  async (enrolmentnId) => {
    const res = await apiRequest({
      method: "get",
      url: `ogd/get-students/${enrolmentnId}`,
    });
    return res;
  }
);

export const feeStructureTable = createAsyncThunk(
  "ogd/feeStructureTable",
  async (enrolmentnId) => {
    const res = await apiRequest({
      method: "get",
      url: `cr/getStudentFeeStructure/${enrolmentnId}`,
    });
    return res;
  }
);

export const updateTermFee = createAsyncThunk(
  "ogd/updateTermFee",
  async (data) => {
    const res = await apiRequest({
      method: "patch",
      // url: `ogd/update-term-fee/${data.admissionNo}`,
      url: `ogd/update-term-fee`,
      data: data,
    });
    return res;
  }
);

export const updateStudentDetails = createAsyncThunk(
  "ogd/updateStudentDetails",
  async (data) => {
    const res = await apiRequest({
      method: "patch",
      // url: `ogd/update-student-sessions/${data.admissionNo}`,
      url: `ogd/update-student-sessions`,
      data: data,
    });
    return res;
  }
);

export const getCountry = createAsyncThunk("ogd/getCountry", async () => {
  const res = await apiRequest({
    method: "get",
    url: `ogd/get-country`,
  });
  return res;
});
export const getOgaLeadStatus = createAsyncThunk(
  "ogd/getOgaLeadStatus",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `ogd/get-oga-lead-status`,
    });
    return res;
  }
);
export const getOgaLeadEnquiryStatus = createAsyncThunk(
  "ogd/getOgaLeadEnquiryStatus",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `ogd/get-oga-lead-enquiry-status`,
    });
    return res;
  }
);
export const getLeadById = createAsyncThunk("ogd/getLeadById", async (id) => {
  const res = await apiRequest({
    method: "get",
    url: `ogd/lead-data/${id}`,
  });
  return res;
});
export const updateLead = createAsyncThunk("ogd/updateLead", async (data) => {
  console.log(data);

  const res = await apiRequest({
    method: "patch",
    url: `ogd/lead-data/${data.leadId}`,
    data: data,
  });
  return res;
});

export const getOgaLeadsStudents = createAsyncThunk(
  "ogd/getOgaLeadsStudents",
  async (data) => {
    const res = await apiRequest({
      method: "get",
      url: `lead/getLeadsUnder?page=${data.page}&limit=${data.limit}&search=${
        data.search ? encodeURIComponent(data.search) : ""
      }`,
    });
    return res;
  }
);
const ogdSlice = createSlice({
  name: "ogdSlice",
  initialState,
  reducers: {},
  //.................GET HOLIDAY..............................
  extraReducers: (builder) => {
    builder.addCase(getLeadTypes.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getLeadTypes.fulfilled, (state, action) => {
      return {
        ...state,
        leadType: action.payload.data.leadTypes,
        loading: false,
      };
    });
    // ----------------------ogmleads------------------------
    builder.addCase(getOgmLeads.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getOgmLeads.fulfilled, (state, action) => {
      return {
        ...state,
        ogmLeads: action.payload,
        loading: false,
      };
    });
    // -------------------get-ogas-------------------------------------
    builder.addCase(getOgaList.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getOgaList.fulfilled, (state, action) => {
      const data = action.payload.data.ogaList;
      return {
        ...state,
        ogaList: data,
        loading: false,
      };
    });
    // ----------------assignOga------------------------------------
    builder.addCase(assignOga.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(assignOga.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // ------------------getOgaStatusLog----------------------------
    builder.addCase(getOgaStatusLog.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getOgaStatusLog.fulfilled, (state, action) => {
      const data = action.payload.data.log;
      return {
        ...state,
        sroStatusLog: data,
        loading: false,
      };
    });
    // --------------------getOgaEnquiryLog-----------------------------
    builder.addCase(getOgaEnquiryLog.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getOgaEnquiryLog.fulfilled, (state, action) => {
      const data = action.payload.data.logData;
      return {
        ...state,
        ogaEnquiryLog: data,
        loading: false,
      };
    });
    // ----------------------getSubjectWiseAssessmentData-------------------------
    builder.addCase(getSubjectWiseAssessmentData.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getSubjectWiseAssessmentData.fulfilled, (state, action) => {
      const data = action.payload.data.subjectWisedata;
      return {
        ...state,
        subjectWiseAssessmentdata: data,
        loading: false,
      };
    });
    // ----------------UpdateExpense--------------------------
    builder.addCase(UpdateExpenses.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(UpdateExpenses.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // -------------getCountry------------------------
    builder.addCase(getCountry.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getCountry.fulfilled, (state, action) => {
      const data = action.payload.data.Country;
      return {
        ...state,
        countryData: data,
        loading: false,
      };
    });
    // -------------------getOgaLeadStatus-------------------------
    builder.addCase(getOgaLeadStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getOgaLeadStatus.fulfilled, (state, action) => {
      const data = action.payload.data;
      return {
        ...state,
        ogaLeadStatus: data,
        loading: false,
      };
    });
    // -------------------getOgaLeadEnquiryStatus--------------------------
    builder.addCase(getOgaLeadEnquiryStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getOgaLeadEnquiryStatus.fulfilled, (state, action) => {
      const data = action.payload.data;
      return {
        ...state,
        ogaLeadEnquiryStatus: data,
        loading: false,
      };
    });
    // ------------------getLeadById-----------------------
    builder.addCase(getLeadById.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getLeadById.fulfilled, (state, action) => {
      const data = action.payload.data;
      return {
        ...state,
        leadDataById: data,
        loading: false,
      };
    });
    // ----------------updateLead---------------------------/
    builder.addCase(updateLead.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(updateLead.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });

    // ----------------OGD ADMISSION REGISTER--------------------------
    builder.addCase(getAdmissionRegisterOgd.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getAdmissionRegisterOgd.fulfilled, (state, action) => {
      return {
        ...state,
        admissionRegisterData: action.payload?.data,
        meta: action.payload?.meta,
        loading: false,
      };
    });

    // ----------------GET ALL EMPLOYEES UNDER HEAD--------------------------
    builder.addCase(getEmployeesUnderHead.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getEmployeesUnderHead.fulfilled, (state, action) => {
      return {
        ...state,
        employeesList: action.payload?.data,
        loading: false,
      };
    });

    // ----------------GET STUDENT PROFILE--------------------------
    builder.addCase(getStudentsProfile.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getStudentsProfile.fulfilled, (state, action) => {
      return {
        ...state,
        studentProfile: action.payload?.data,
        loading: false,
      };
    });

    // ----------------GET STUDENT FEE STRUCTURE TABLE--------------------------
    builder.addCase(feeStructureTable.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(feeStructureTable.fulfilled, (state, action) => {
      return {
        ...state,
        studentFeeStructure: action.payload?.data?.studentFeeStructure,
        loading: false,
      };
    });

    // ----------------GET ALL COURSES--------------------------
    builder.addCase(getCourses.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getCourses.fulfilled, (state, action) => {
      return {
        ...state,
        courses: action.payload?.data,
        loading: false,
      };
    });

    // ----------------UPDATE TERM FEE--------------------------
    builder.addCase(updateTermFee.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(updateTermFee.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });

    // ----------------UPDATE STUDENT DETAILS --------------------------
    builder.addCase(updateStudentDetails.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(updateStudentDetails.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      };
    });
    // -----------------getOgaLeadsStudents--------------------------------
    builder.addCase(getOgaLeadsStudents.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getOgaLeadsStudents.fulfilled, (state, action) => {
      return {
        ...state,
        ogaLeadStudents: action.payload,
        loading: false,
      };
    });
  },
});

export default ogdSlice.reducer;
