import React, { useEffect, useState } from "react";
import { Button, Paper, Stack, TextField, Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Wrapper from "../../../components/Wrapper/Wrapper";
import dayjs from "dayjs";
import { getCurrentLedger, addExpense } from "../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const AddExpense = ({ data, setOpen, open, params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { currentLedger, expenseTypes, studentsforAddIncome } = useSelector(
        (state) => state.cro
    );

    const [formatedDate, setFormatedDAte] = useState("");

    useEffect(() => {
        if (params) {
            function formatDate(inputDateStr) {
                // Parse the input date string
                const date = dayjs(inputDateStr, "DD-MM-YYYY");

                // Format the date to the desired format
                const formattedDate = date.format("MM-DD-YYYY");

                return formattedDate;
            }

            const formattedDate = formatDate(params.transactionDate);
            setFormatedDAte(formattedDate);
        }
    }, [params]);

    const [gcc, setGcc] = useState(false);
    const [expenseTypeStatus, setExpenseTypeStatus] = useState("");

    const Schema = Yup.object().shape({
        expenseTypeId: Yup.string().required("expenseTypeId is required"),
        courseEnrollmentId: Yup.string().required(
            "courseEnrollmentId is required"
        ),
        term: Yup.string().required("term is required"),
        year: Yup.string().required("year is required"),
        month: Yup.string().required("month is required"),
        refundAccount: Yup.string().required("refundAccount is required"),
        amount: Yup.string().required("amount is required"),
        expenseRemarks: Yup.string().required("expenseRemarks is required"),
        accountHolder: Yup.string().required("accountHolder is required"),
        ifsc: Yup.string().required("ifsc is required"),
        accountNumber: Yup.string().required("accountNumber is required"),
    });

    const badDeptSchema = Yup.object().shape({
        expenseTypeId: Yup.string().required("expenseTypeId is required"),
        courseEnrollmentId: Yup.string().required(
            "courseEnrollmentId is required"
        ),
        year: Yup.string().required("year is required"),
        month: Yup.string().required("month is required"),
        amount: Yup.string().required("amount is required"),
        expenseRemarks: Yup.string().required("expenseRemarks is required"),
    });

    const termFeeSchema = Yup.object().shape({
        expenseTypeId: Yup.string().required("expenseTypeId is required"),
        courseEnrollmentId: Yup.string().required(
            "courseEnrollmentId is required"
        ),
        term: Yup.string().required("term is required"),
        year: Yup.string().required("year is required"),
        month: Yup.string().required("month is required"),

        amount: Yup.string().required("amount is required"),
        expenseRemarks: Yup.string().required("expenseRemarks is required"),
    });

    const formik = useFormik({
        initialValues: {
            expenseTypeId: "",
            courseEnrollmentId: "",
            year: "",
            month: "",
            amount: "",
            transactionDate: dayjs().format("YYYY-MM-DD"),
            term: "",
            expenseRemarks: "",
            refundAccount: "",
            accountHolder: "",
            ifsc: "",
            accountNumber: "",
        },
        validationSchema:
            expenseTypeStatus === 3
                ? badDeptSchema
                : expenseTypeStatus === 4
                ? termFeeSchema
                : Schema,
        onSubmit: (values, { resetForm }) => {
            console.log("submitted", values);
            dispatch(addExpense(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    useEffect(() => {
        if (formik.values.expenseTypeId) {
            setExpenseTypeStatus(formik.values.expenseTypeId);
        } else {
            setExpenseTypeStatus("");
        }
        if (formik.values.expenseTypeId === 3) {
            formik.setFieldValue("refundAccount", 0);
        } else {
            formik.setFieldValue("refundAccount", "");
        }
    }, [formik.values.expenseTypeId]);

    useEffect(() => {
        if (formik.values.courseEnrollmentId) {
            dispatch(getCurrentLedger(formik.values.courseEnrollmentId));
        }
    }, [formik.values.courseEnrollmentId]);

    useEffect(() => {
        if (currentLedger) {
            formik.setFieldValue("year", currentLedger.year);
            formik.setFieldValue("month", currentLedger.month);
        }
    }, [currentLedger, formik.values.courseEnrollmentId]);

    useEffect(() => {
        if (formik.values.paymentAccountId === 2) {
            setGcc(true);
        } else {
            setGcc(false);
        }
    }, [formik.values.paymentAccountId]);

    const handleFileUpload = (e) => {
        formik.setFieldValue("incomeScreenshot", e.target.files[0]);
    };

    //<------------ trial datas for the form -------->

    const accountType = [
        { id: 2, type: "GCC Bank A/C" },
        { id: 1, type: "Indian Bank A/C" },
    ];

    const paymentMode = [
        { mode: "Bank Transfer" },
        { mode: "UPI" },
        { mode: "Cash" },
    ];

    return (
        <div>
            <Wrapper
                Content={
                    <>
                        <Paper elevation={4} sx={{ width: "100%", padding: 3 }}>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Stack gap={2}>
                                        <GlobalSelectField
                                            options={{
                                                name: "expenseTypeId",
                                                formik,
                                                label: "Expense Type",
                                                data: expenseTypes,
                                                keys: {
                                                    id: "id",
                                                    value: "name",
                                                },
                                            }}
                                        />
                                        <GlobalSelectField
                                            options={{
                                                name: "courseEnrollmentId",
                                                formik,
                                                label: "Student Name",
                                                data: studentsforAddIncome,

                                                keys: {
                                                    id: "id",
                                                    value: "name",
                                                },
                                            }}
                                        />
                                        <Box>
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <TextField
                                                    name="year"
                                                    fullWidth
                                                    label="Year"
                                                    value={
                                                        formik.values.year || ""
                                                    }
                                                    error={
                                                        formik.touched.year &&
                                                        Boolean(
                                                            formik.errors.year
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.year
                                                    }
                                                    disabled
                                                />
                                                <TextField
                                                    name="month"
                                                    fullWidth
                                                    label="Month"
                                                    value={
                                                        formik.values.month ||
                                                        ""
                                                    }
                                                    error={
                                                        formik.touched.month &&
                                                        Boolean(
                                                            formik.errors.month
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.month
                                                    }
                                                    disabled
                                                />
                                            </Stack>
                                        </Box>
                                        {expenseTypeStatus === 3 ? null : (
                                            <>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        name="transactionDate"
                                                        label="Transaction Date"
                                                        value={dayjs(
                                                            formik.values
                                                                .transactionDate
                                                        )}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            formik.setFieldValue(
                                                                "transactionDate",
                                                                dayjs(
                                                                    newValue
                                                                ).format(
                                                                    "YYYY-MM-DD"
                                                                )
                                                            );
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                                <TextField
                                                    name="term"
                                                    label="Term"
                                                    value={formik.values.term}
                                                    fullWidth
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched.term &&
                                                        Boolean(
                                                            formik.errors.term
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.term
                                                    }
                                                />
                                            </>
                                        )}
                                        {expenseTypeStatus === 3 ||
                                        expenseTypeStatus === 4 ? null : (
                                            <GlobalSelectField
                                                options={{
                                                    name: "refundAccount",
                                                    formik,
                                                    label: "Account",
                                                    data: accountType,
                                                    keys: {
                                                        id: "id",
                                                        value: "type",
                                                    },
                                                }}
                                            />
                                        )}

                                        <TextField
                                            label="Amount"
                                            name="amount"
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.amount &&
                                                Boolean(formik.errors.amount)
                                            }
                                            helperText={formik.errors.amount}
                                        />
                                        {expenseTypeStatus === 3 ||
                                        expenseTypeStatus === 4 ? null : (
                                            <>
                                                <TextField
                                                    label="Account Holder"
                                                    name="accountHolder"
                                                    value={
                                                        formik.values
                                                            .accountHolder
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched
                                                            .accountHolder &&
                                                        Boolean(
                                                            formik.errors
                                                                .accountHolder
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors
                                                            .accountHolder
                                                    }
                                                />
                                                <TextField
                                                    label="IFSC"
                                                    name="ifsc"
                                                    value={formik.values.ifsc}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched.ifsc &&
                                                        Boolean(
                                                            formik.errors.ifsc
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.ifsc
                                                    }
                                                />
                                                <TextField
                                                    label="Account Number"
                                                    name="accountNumber"
                                                    value={
                                                        formik.values
                                                            .accountNumber
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched
                                                            .accountNumber &&
                                                        Boolean(
                                                            formik.errors
                                                                .accountNumber
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors
                                                            .accountNumber
                                                    }
                                                />
                                                <TextField
                                                    label="Confirm Account Number"
                                                    name="accountNumber"
                                                    value={
                                                        formik.values
                                                            .accountNumber
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={
                                                        formik.touched
                                                            .accountNumber &&
                                                        Boolean(
                                                            formik.errors
                                                                .accountNumber
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors
                                                            .accountNumber
                                                    }
                                                />
                                            </>
                                        )}

                                        {/* {gcc ? (
                                            <TextField
                                                label="Amount (AED)"
                                                name="amountInAED"
                                                value={
                                                    formik.values.amountInAED
                                                }
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched
                                                        .amountInAED &&
                                                    Boolean(
                                                        formik.errors
                                                            .amountInAED
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.amountInAED
                                                }
                                            />
                                        ) : null} */}

                                        {/* <GlobalSelectField
                                            options={{
                                                name: "paymentMode",
                                                formik,
                                                label: "Payment Mode",
                                                data: paymentMode,
                                                keys: {
                                                    id: "mode",
                                                    value: "mode",
                                                },
                                            }}
                                        /> */}

                                        <TextField
                                            label="Remarks"
                                            name="expenseRemarks"
                                            placeholder="Type your Remarks here"
                                            value={formik.values.expenseRemarks}
                                            multiline
                                            rows={3}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.expenseRemarks &&
                                                Boolean(
                                                    formik.errors.expenseRemarks
                                                )
                                            }
                                            helperText={
                                                formik.errors.expenseRemarks
                                            }
                                        />

                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{
                                                width: "300px",
                                                margin: "0 auto",
                                            }}
                                        >
                                            Add Expense
                                        </Button>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    </>
                }
            />
        </div>
    );
};

export default AddExpense;
