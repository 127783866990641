import { Box } from "@mui/material";
import * as React from "react";
import { GlobalBarChart } from "../../../../components/Dashboard/GlobalComponents/GlobalBarChart";
import GlobalDonutChart from "../../../../components/Dashboard/GlobalComponents/GlobalDonutChart";
import GlobalPieChart from "../../../../components/Dashboard/GlobalComponents/GlobalPieChart";
import SpeedoMeter from "./SpeedoMeter";

const ReportCharts = ({ data, chartId, speakProChartData }) => {
    const [chartDatas, setChartDatas] = React.useState([]);

    const storedStudentData = JSON.parse(localStorage.getItem("studentData"));
    const [ymaxValue,setYmaxValue]=React.useState("")
   
    React.useEffect(()=>{
        if(storedStudentData.courseName==1){ //BAR CHART Y AXIS MAXIMUM VALUE IS 30 EXCEPT SPEAKPRO(50)
            setYmaxValue(50)
        }else{
            setYmaxValue(30)
        }
        },[storedStudentData])


    React.useEffect(() => {
        if (data || speakProChartData) {
            if (storedStudentData?.courseName === 1) {
                setChartDatas(speakProChartData);
            } else {
                let out = [];
                data.forEach((subjectData) => {
                    const objectivesArray = subjectData.levels?.[0]?.objectives;
                    if (objectivesArray && objectivesArray.length > 0) {
                        let maximumMark = objectivesArray.length * 30;
                        let achievedScore = objectivesArray.reduce(
                            (acc, objective) =>
                                (objective.starRating || 0) + acc,
                            0
                        );
                        let totalPercentage =
                            (achievedScore / maximumMark) * 100;
                        let outOfThirty = (totalPercentage / 100) * 30;
                        out.push({
                            label: subjectData.subject,
                            value: Math.round(outOfThirty),
                        });
                    }
                });
                setChartDatas(out);
            }
        }
    }, [data, speakProChartData]);

    if (chartId === 1) {
        return <GlobalBarChart horizontalView={false} chartData={chartDatas} maxY={ymaxValue} chartEdit={true}  />;
    } else if (chartId === 2) {
        return <GlobalDonutChart chartData={chartDatas} chipValue={`Max Score Per Subject : ${ymaxValue}`}/>;
    } else if(chartId === 3) {
        return <GlobalPieChart data={chartDatas} chipValue={`Max Score Per Subject : ${ymaxValue}`} customPercentage={true} maxY={ymaxValue} />;
    }else{
        return <SpeedoMeter data={chartDatas} maxY={ymaxValue}/>
    }
};

export default ReportCharts;
