import React from "react";
import { useLocation } from "react-router-dom";

const DepartmentEmployeesDetails = () => {
    const location = useLocation();
    const { state } = location;
    console.log("hai...", state);
    return <div>DepartmentEmployeesDetails</div>;
};

export default DepartmentEmployeesDetails;
