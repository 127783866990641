import { CourseListingStatus } from "./CourseListingStatus";
import { CourseStatus } from "./CourseStatus";
import { DeleteCourse } from "./DeleteCourse";
import { EditCourse } from "./EditCourse";

export const COURSES_AVAILABLE_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "course_name",
        headerName: "Course Name",
        width: 150,
    },
    {
        field: "course_outcomes",
        headerName: "Course Outcomes ",
        width: 90,
    },
    {
        field: "batch_availability",
        headerName: "Batch Availability",
        width: 250,
    },

    {
        field: "isActive",
        headerName: "isActive",
        width: 150,
        renderCell: (params) => <CourseStatus params={params.row} />,
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 150,
        renderCell: (params) => <CourseListingStatus params={params.row} />,
    },
    {
        field: "Edit",
        headerName: "Edit",
        width: 150,
        renderCell: (params) => <EditCourse params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => (
            <DeleteCourse params={params.row.course_available_id} />
        ),
    },
];
