import { Box, Paper, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalSelectField from "../mui/GlobalSelectField";
import { useFormik, FormikProvider, Form } from "formik";
import { CancelButton, SaveButton } from "../mui/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

const fields = [
    {
        id: 1,
        fields: "text",
        label: "Name",
        value: "studentName",
    },
    {
        id: 2,
        fields: "text",
        label: "Email",
        value: "studentEmail",
    },
    {
        id: 3,
        fields: "text",
        label: "Phone",
        value: "studentPhone",
    },
    {
        id: 4,
        fields: "select",
        label: "Age",
        value: "studentAge",
        data: [
            { id: 1, values: "one" },
            { id: 2, values: "two" },
            { id: 3, values: "three" },
        ],
    },
    {
        id: 5,
        fields: "select",
        label: "genter",
        value: "studentGenter",
        data: [
            { id: 1, values: "male" },
            { id: 2, values: "female" },
        ],
    },
];
const age = [
    { id: 1, values: "one" },
    { id: 2, values: "two" },
    { id: 3, values: "three" },
];
const gender = [
    { id: 1, values: "male" },
    { id: 2, values: "female" },
];

const FormTextField = ({ label, value, action }) => {
    return (
        <TextField
            required
            label={label}
            fullWidth
            value={value}
            onChange={action}
        />
    );
};

const FormSelectField = ({ name, formik, label, data, id, value }) => {
    return (
        <GlobalSelectField
            options={{
                name: name,
                formik,
                sx: {
                    width: "100%",
                },
                label: label,
                data: data,
                keys: {
                    id: id,
                    value: value,
                },
            }}
        />
    );
};

export const DynamicForm = ({ api }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    console.log(data);
    useEffect(() => {
        const val = {};
        fields.forEach((field) => {
            val[field.value] = "";
        });
        setData(val);

        console.log(val);
    }, []);
    const formik = useFormik({
        initialValues: { ...data },
        onSubmit: (values) => {
            dispatch(api).then((res) => {
                if (
                    res.payload.status === "failed" ||
                    res.payload.status === "error"
                ) {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    console.log(formik.values);

    const handleInputChange = (e, field) => {
        const value = e.target.value;
        setData((prevData) => ({
            ...prevData,
            [field.value]: value,
        }));
    };

    useEffect(() => {
        setData({
            ...data,
            studentAge: formik.values.studentAge,
            studentGenter: formik.values.studentGenter,
        });
        formik.setValues({
            ...data,
            studentAge: formik.values.studentAge,
            studentGenter: formik.values.studentGenter,
        });
    }, [formik.values.studentAge, formik.values.studentGenter]);

    return (
        <div>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Paper elevation={3} sx={{ padding: 2 }}>
                        <Stack direction={"column"} spacing={2}>
                            {fields.map((field) => {
                                return (
                                    <Box key={field.id}>
                                        {field.fields === "text" ? (
                                            <FormTextField
                                                label={field.label}
                                                key={field.id}
                                                value={data[field.value]}
                                                action={(e) =>
                                                    handleInputChange(e, field)
                                                }
                                            />
                                        ) : (
                                            <FormSelectField
                                                formik={formik}
                                                name={field.value}
                                                label={field.label}
                                                data={field.data}
                                                id="id"
                                                value="values"
                                            />
                                        )}
                                    </Box>
                                );
                            })}
                        </Stack>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
                                <CancelButton size="small">Cancel</CancelButton>
                                <SaveButton size="small" type="submit">
                                    Save
                                </SaveButton>
                            </Stack>
                        </Box>
                    </Paper>
                </Form>
            </FormikProvider>
        </div>
    );
};
