import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllCabinsForAdministration } from "../../../redux/features/cabinBookingSlice";
import { CABIN_SETTINGS_TABLE_HEAD } from "../../../components/datagrid/myworkspace/hr/CabinSettingsTableHead";
import { AddCabin } from "./AddCabin";

export const CabinSettings = () => {
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const { cabinsForAdministration, loading } = useSelector(
        (state) => state.cabin
    );
    useEffect(() => {
        dispatch(getAllCabinsForAdministration());
    }, []);

    return (
        <div>
            <Wrapper
                title="Cabin Settings"
                actions={<AddCabin />}
                Content={
                    <>
                        <Box>
                            <Paper elevation={3}>
                                <Box sx={{ width: "100%", padding: 0 }}>
                                    <DataGrid
                                        components={{
                                            Toolbar: CustomGridToolbar,
                                        }}
                                        loading={loading}
                                        autoHeight
                                        sx={{
                                            "& .MuiDataGrid-columnHeaderTitle":
                                                {
                                                    textOverflow: "clip",
                                                    whiteSpace: "break-spaces",
                                                    lineHeight: 1,
                                                },
                                        }}
                                        rows={cabinsForAdministration}
                                        columns={CABIN_SETTINGS_TABLE_HEAD}
                                        getRowId={(row) => row.serialNumber}
                                        pageSize={gridSize}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        onPageSizeChange={(newGridSize) =>
                                            setGridSize(newGridSize)
                                        }
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </>
                }
            />
        </div>
    );
};
