import { CreateUpdateGradeView, DeleteGradeView } from "../RenderCellMain";
import CreateUpdateGrade from "./CreateUpdateGrade";
import DeleteGrade from "./DeleteGrade";
import GradeStatus from "./GradeStatus";

export const getFilteredTableHead = (data) => {
  const hasLevelName = data.some((row) => row.level_name !== null);
  const hasObjectiveName = data.some((row) => row.objective_name !== null);

  return [
    {
      field: "serialNumber",
      headerName: "slNo",
    },
    {
      field: "grade_name",
      headerName: "Grade Name",
      width: 200,
    },
    {
      field: "matrix_name",
      headerName: "Matrix Name",
      width: 200,
    },
    // Conditionally include level_name column
    ...(hasLevelName
      ? [
          {
            field: "level_name",
            headerName: "Level Name",
            width: 200,
          },
        ]
      : []),
    // Conditionally include objective_name column
    ...(hasObjectiveName
      ? [
          {
            field: "objective_name",
            headerName: "Objective Name",
            width: 200,
          },
        ]
      : []),
    {
      field: "grade_created_at",
      headerName: "Grade Created At",
      width: 200,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => <GradeStatus params={params.row} />,
    },
    {
      field: "Update",
      headerName: "Update",
      width: 120,
      renderCell: (params) => <CreateUpdateGradeView params={params.row} />,
    },
    {
      field: "Delete",
      headerName: "Delete",
      width: 120,
      renderCell: (params) => (
        <DeleteGradeView
          id={params.row.grade_id}
          childFlag={params.row.has_child_element}
        />
      ),
    },
  ];
};
