import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiRequest from '../../api/request'


const initialState={
    loading: false,
    studentJourney:[],
    leadDetails:{}
}


export const getLeadDetails=createAsyncThunk('studentTrackSlice/leadDetails',
    async(leadId)=>{
        const res=await apiRequest({
            method: 'get',
            url: `lead/getLead/${leadId}`,
        })
        // console.log(res);      
        return res
    }
)

export const getStudentJourney=createAsyncThunk('studentTrackSlice/getStudentJourney',
    async(leadId)=>{
        const res=await apiRequest({
            method: 'get',
            url: `lead/leadJourney/${leadId}`,
        })
        // console.log(res);      
        return res
    }
)
const studentTrackSlice=createSlice({
    name:"studentTrackSlice",
    initialState,
    reducers:{},

    extraReducers:(builder)=>{
       
        //GETTING STUDENT OR LEAD DETAILS
        builder.addCase(getLeadDetails.pending, (state, action) => {
            return {
              ...state,
              loading: true,
            }
          })
      
          builder.addCase(getLeadDetails.fulfilled, (state, action) => {
            return {
              ...state,
              loading: false,
              leadDetails:action.payload?.data
            }
          })

                  //GETTING STUDENT JOURNEY 
        builder.addCase(getStudentJourney.pending, (state, action) => {
            return {
              ...state,
              loading: true,
            }
          })
      
          builder.addCase(getStudentJourney.fulfilled, (state, action) => {
            return {
              ...state,
              loading: false,
              studentJourney:action.payload?.data
            }
          })







    }
})

export default studentTrackSlice.reducer