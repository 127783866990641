import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";


const GlobalSelectFieldWithoutFormik = ({
  label,
  data,
  selectedDetails,
  setChange,
  keys,
  sx,
  reset

}) => {  
  const { itemId, itemValue } = keys;
  const handleChange = (e) => {
    const selectedId = e.target.value;
    const objectIs = data.find((objects) => objects[itemId] == selectedId);
    setChange((pvs) => ({
      ...pvs,
      id: objectIs[itemId],
      value: objectIs[itemValue],
    }));
  };

  useEffect(() => {
    if (data?.length > 0 && !selectedDetails.id) {
      const initialObject = data[0];
      setChange({
        id: initialObject[itemId],
        value: initialObject[itemValue],
      });
    }
  }, []);

  useEffect(() => {
    if (reset) {
      const initialObject = data[0];
      setChange({
        id: initialObject[itemId],
        value: initialObject[itemValue],
      });
    }
  }, [reset, data]);


  return (
    
        <FormControl sx={sx}>
          <InputLabel>{label}</InputLabel>
          <Select
            name="selectField"
            label={label}
            value={selectedDetails.id || data[0][itemId]}
            onChange={handleChange}
          >
            {data?.map((item) => (
              <MenuItem key={item[itemId]} value={item[itemId]}>
                {item[itemValue]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  );
};

export default GlobalSelectFieldWithoutFormik;
