import * as Yup from "yup";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../../components/color-utils/Iconify";
import { useDispatch, useSelector } from "react-redux";
import { isLogin } from "../../../../redux/features/authSlice";
import { saveUserProfile } from "../../../../redux/features/roleSlice";
import { useSnackbar } from "notistack";
import typography from "../../../../Theme/overrides/Typography";
import { getProfile } from "../../../../redux/features/profileSlice";
import { getUserPreData } from "../../../../redux/features/authSlice";
import Loader3D from "../../../../components/Loader/Loader3D";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();

    const loading = useSelector((state) => state.login.isLoading);

    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
        password: Yup.string().required("Password is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: email,
            password: password,
            remember: true,
        },
        validationSchema: LoginSchema,
        onSubmit: (e) => {
            dispatch(
                isLogin({
                    email: e.email,
                    password: e.password,
                })
            ).then((res) => {
                if (
                    res.payload.status === "failed" ||
                    res.payload.status === "error"
                ) {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    dispatch(saveUserProfile());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });

                    navigate("/dashboard", { replace: true });
                    //   navigate("/employeeservices/attendance", { replace: true });
                }
            });
        },
    });

    const {
        errors,
        touched,
        values,
        isSubmitting,
        handleSubmit,
        getFieldProps,
    } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email address"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        {...getFieldProps("password")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleShowPassword}
                                        edge="end"
                                    >
                                        <Iconify
                                            icon={
                                                showPassword
                                                    ? "eva:eye-fill"
                                                    : "eva:eye-off-fill"
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ my: 2 }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...getFieldProps("remember")}
                                checked={values.remember}
                            />
                        }
                        label="Remember me"
                    />

                    <Link
                        component={RouterLink}
                        variant="subtitle2"
                        to="/auth/forgott"
                        underline="hover"
                    >
                        Forgot password?
                    </Link>
                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading ? true : false}
                    style={typography.button}
                >
                    {loading ? <Loader3D /> : "Login"}
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}
