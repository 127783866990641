import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
  loading: false,
  invoices: [],
  rowCount: 0,
  invoicesDetails: [],
  screenshotSrc: "",
  studentsList: [],
  subjectList: [],
  invoiceTemplate:[]
};

export const getInvoice = createAsyncThunk(
  "invoice/getInvoice",  
  async ({ selectedDate, pageNumber, pageLimit, searchValue }) => {
    const res = await apiRequest({
      method: "get",
      url: `general/invoices/${selectedDate}?&limit=${pageLimit}&page=${pageNumber}&search=${
        searchValue ? encodeURIComponent(searchValue) : ""
      }`,
    });
    return res;
  }
);

export const getInvoiceDetails = createAsyncThunk(
  "invoice/getInvoiceDetails",
  async (invoiceId) => {
    const res = await apiRequest({
      method: "get",
      url: `general/invoice_detail/${invoiceId}`,
    });
    return res;
  }
);

export const getScreenshot = createAsyncThunk(
  "invoice/getScreenshot",
  async (imageId) => {
    const res = await apiRequest({
      method: "get",
      url: `general/invoices/img/${imageId}`,
    });
    return res;
  }
);

export const deleteInvoice = createAsyncThunk(
  "invoice/deleteInvoice",
  async (id) => {
    const res = await apiRequest({
      method: "delete",
      url: `general/invoices/${id}`,
    });
    return res;
  }
);

export const getStudentsList = createAsyncThunk(
  "invoice/getStudentsList",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `general/getStudents/1`,
    });
    return res;
  }
);

export const getSubjects = createAsyncThunk("invoice/getSubjects", async () => {
  const res = await apiRequest({
    method: "get",
    url: `cr-pre-data/getSubjects/0`,
  });
  return res;
});

export const createInvoice = createAsyncThunk(
  "invoice/createInvoice",
  async (data) => {    
    const res = await apiRequest({
      method: "post",
      url: `general/invoices`,
      data: data,
      headers: {
        'Accept':'application/json',
        'Content-Type': 'multipart/form-data'
      },
    });
    return res;
  }
);

export const updateInvoice = createAsyncThunk(
  "invoice/updateInvoice",
  async (data) => {
    const res = await apiRequest({
      method: "patch",
      url: `general/invoices/${data.id}`,
      data: data,
      headers: {
        'Accept':'application/json',
        'Content-Type': 'multipart/form-data'
      },
    });
    return res;
  }
);


export const getInvoiceTemplate = createAsyncThunk("invoice/getInvoiceTemplate", async  (invoiceId)=> {
  const res = await apiRequest({
    method: "get",
    url: `general/invoice/print/${invoiceId}`,
  });
  return res;
});


const invoiceSlice = createSlice({
  name: "invoiceSlice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //.................GET INVOICES..............................
    builder.addCase(getInvoice.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        invoices: action?.payload?.data,
        rowCount: action.payload.meta?.totalRecords,
        loading: false,
      };
    });

    //.................GET INVOICES DETAILS..............................
    builder.addCase(getInvoiceDetails.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getInvoiceDetails.fulfilled, (state, action) => {
      return {
        ...state,
        invoicesDetails: formatDataForDataTable(action?.payload?.data),
        loading: false,
      };
    });

    //.................GET SCREENSHOT IMAGE..............................
    builder.addCase(getScreenshot.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getScreenshot.fulfilled, (state, action) => {
      return {
        ...state,
        screenshotSrc: action?.payload?.imageUrl,
        loading: false,
      };
    });

    //.................DELETE INVOICE ..............................
    builder.addCase(deleteInvoice.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(deleteInvoice.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });

    //.................GET STUDENTS LIST..............................
    builder.addCase(getStudentsList.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getStudentsList.fulfilled, (state, action) => {
      return {
        ...state,
        studentsList: action?.payload?.data?.students,
        loading: false,
      };
    });

    //.................GET SUBJECTS LIST..............................
    builder.addCase(getSubjects.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getSubjects.fulfilled, (state, action) => {
      return {
        ...state,
        subjectList: action?.payload?.data,
        loading: false,
      };
    });

    //.................CREATE  INVOICE..............................
    builder.addCase(createInvoice.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(createInvoice.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });

    //.................UPDATE  INVOICE..............................
    builder.addCase(updateInvoice.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(updateInvoice.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });

    
   //.................GET INVOICE TEMPLATE..............................
   builder.addCase(getInvoiceTemplate.pending, (state, action) => {
    return {
      ...state,
      loading: true,
    };
  });
  builder.addCase(getInvoiceTemplate.fulfilled, (state, action) => {
    return {
      ...state,
      invoiceTemplate: action?.payload?.data,
      loading: false,
    };
  });
  },
});





export default invoiceSlice.reducer;
