import React, { useEffect, useMemo, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Box, Button, Stack, TextField } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  CancelButton,
  EditButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { getAdmissionRegisterOgd, updateTermFee } from "../../../../../redux/features/ogdSlice";
import { savePaginationData } from "../../../../../redux/features/globalDatagridSlice";

const EditTer1Fee = React.memo(({ row, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [term1fee, setTerm1fee] = useState("");
  const [error, setError] = useState("");
  const { loading } = useSelector((store) => store.ogd);
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const handleChange = (e) => {
    const value = e.target.value;
    setTerm1fee(value);
    validateFee(value);
  };
useEffect(()=>{
if(open){
  setTerm1fee(row.firstTermFee)
}
},[open])
  const validateFee = (value) => {
    if (value === "") {
      setError("Fee is required");
    } else if (value <= 0) {
      setError("Fee must be greater than 0");
    } else {
      setError("");
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setTerm1fee("");
    setError("");
  };

  const update = () => {
    validateFee(term1fee);
    if (term1fee !== "" && term1fee > 0) {
      const dataToSend = {
        firstTermFee: term1fee,
        courseEnrollmentId: row.courseEnrollmentId,
        croAssignmentId:row.croAssignmentId,
        admissionNo:row.admissionNo,
        termId:row.termId
      };
      dispatch(updateTermFee(dataToSend)).then((res) => {
        if (res.payload.status === "error") {
          enqueueSnackbar(res.payload.message, {
            variant: "error",
          });
        } else if (res.payload.status === "success") {
          setOpen(false);
          dispatch(getAdmissionRegisterOgd(data));
          dispatch(savePaginationData({ ...paginationData, openAnchor: false }));
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    }
  };

  return (
    <GlobalDialog
      maxWidth={"xs"}
      fullWidth={true}
      title={"Edit Term 1 Fee"}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      popupButton={
        <>
          <EditButton
           size="small" 
          disabled={row.firstTermFee!==0 && !row?.firstTermFee} 
          action={() => setOpen(true)}>Edit</EditButton>
        </>
      }
      content={
        <>
          <Box>
            <TextField
              onChange={handleChange}
              autoComplete="off"
              name="term1Fee"
              fullWidth
              type="number"
              value={term1fee}
              label="Term 1 Fee"
              error={Boolean(error)} // Shows red border if error exists
              helperText={error} // Displays the error message as helper text
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Stack direction={"row"} spacing={1}>
              <CancelButton action={handleCancel}>Cancel</CancelButton>
              <UpdateButton action={update} type="submit">
                {loading ? "Updating..." : "Update"}
              </UpdateButton>
            </Stack>
          </Box>
        </>
      }
    />
  );
})

export default EditTer1Fee;
