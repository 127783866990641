import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import navConfig from "../../components/sidebar/NavConfig";

export const initialState = {
  loading: false,
  userProfile: {},
  navConfig: [
    // {
    //     title: "todo",
    //     path: "/todo",
    //     children: [
    //         {
    //             title: "My Tasks",
    //             path: "/todo/my-tasks",
    //         },
    //         {
    //             title: "Created Tasks",
    //             path: "/todo/created-tasks",
    //         },
    //         {
    //             title: "Department Tasks",
    //             path: "/todo/department-tasks",
    //         },
    //         {
    //             title: "Inward Tasks",
    //             path: "/todo/inward-tasks",
    //         },
    //         {
    //             title: "Outward Tasks",
    //             path: "/todo/outward-tasks",
    //         },
    //     ],
    // },
    {
      title: "employee Services",
      name: "employeeServices",
      path: "/employeeservices",
      children: [
        {
          title: "Attendance",
          path: "/employeeservices/attendance",
        },
        {
          title: "Leave",
          path: "/employeeservices/leave",
        },
        {
          title: "Compensatory Work",
          path: "/employeeservices/compensatory-work-request",
        },
        // {
        //   title: "Cabin Booking",
        //   path: "/employeeservices/cabin-booking",
        // },
        // {
        //     title: "MentoringBookings",
        //     path: "/employeeservices/mentoring-bookings",
        // },
        {
          title: "Holiday Calendar",
          path: "/employeeservices/holidays-calendar",
        },
        {
          title: "Food",
          path: "/employeeservices/food",
        },
        // {
        //   title: "Post Working",
        //   path: "/employeeservices/postWorking",
        // },
      ],
    },

    // {
    //     title: "privileges",
    //     path: "/privileges",
    // },
    // {
    //     title: "reffer And Earn",
    //     name: "reffer",
    //     path: "/reffer",
    //     children: [
    //         {
    //             title: "Add Referral",
    //             path: "/reffer/addrefferel",
    //         },
    //         {
    //             title: "Referral Status",
    //             path: "/reffer/refferelStatus",
    //         },
    //     ],
    // },
  ],
  otherConfig: [],
  permissions: [],
  navChild: [],
};

export const getNavConfig = createAsyncThunk("role/nav-config", async () => {
  const res = await apiRequest({
    method: "GET",
    url: "permissions/byUserType",
  });

  const userProfile = JSON.parse(localStorage.getItem("cred")).profile;
  let flag = false;
  res.data.navConfig.forEach((item) => {
    // console.log(item.title);
    if (item.title === "my workspace") {
      flag = true;
    }
  });

  // const isTech=res.data.other.find((data)=>data.title=="tech support") //TECH SUPPORT IS ADDING TO SIDE BAR IF THE PERSON IS TECH ADDITIONALLY GENERATED REQUEST CHILDREN WILL BE ADDED OTHER WISE ONLY SHOW REQUEST SUPPORT
  const isTech = userProfile?.departmentId == 5;
  res.data.navConfig.push({
    title: "Help And Support",
    name: "helpandsupport",
    path: "/helpandsupport",
    children: [
      ...(!isTech
        ? [
            {
              title: "Request support",
              path: "/helpandsupport/raise-request",
            },
          ]
        : []),
      ...(isTech
        ? [
            {
              title: "Generated Requests",
              path: "/helpandsupport/generated-requests",
            },
          ]
        : []),
    ],
  });

  if (userProfile.isReportingHead && !flag) {
    res.data.navConfig.push({
      id: 3,
      path: "/my-workspace",
      title: "my workspace",
      children: [],
    });
  }
  res.data.navConfig.forEach((item) => {
    if (item.title === "my workspace" && userProfile.isReportingHead) {
      item.children.push(
        {
          title: "department Leaves",
          path: "my-workspace/reporting_head_or_hod/hod-leave-applications",
        },
        {
          title: "department Attendance",
          path: "my-workspace/reporting_head_or_hod/reporting-head-attendance",
        }
      );
    }
  });
  return res;
});

const roleSlice = createSlice({
  name: "role",
  initialState: initialState,
  reducers: {
    settingPermissions: (state, action) => {
      action.payload.forEach((item) => {
        state.permissions.push(item.title);
      });
      return state;
    },
    filterNavChild: (state, action) => {
      const navBar = state.navConfig?.filter(
        (item) => item.title === action.payload
      );
      if (navBar.length > 0) {
        navBar[0].children?.forEach((item) => {
          state.navChild.push(item.title);
        });
      }
      return state;
    },
    cleanUpPermissions: (state) => {
      return {
        ...state,
        permissions: [],
        navChild: [],
      };
    },
    clearRoles: (state) => {
      state = initialState;
      return state;
    },
    saveUserProfile: (state) => {
      return {
        ...state,
        userProfile: JSON.parse(localStorage.getItem("cred")).profile,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNavConfig.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getNavConfig.fulfilled, (state, action) => {
      return {
        ...state,
        navConfig: [...action.payload.data.navConfig, ...state.navConfig],
        otherConfig: action.payload.data.other,
        loading: false,
      };
    });
  },
});
export const {
  settingPermissions,
  cleanUpPermissions,
  filterNavChild,
  saveUserProfile,
  clearRoles,
} = roleSlice.actions;
export default roleSlice.reducer;
