import React from "react";
import {
  Box,
  TextField,
  Stack,
  Typography,
  Divider,
  Switch,
  Button,
} from "@mui/material";
import { Icon } from "@iconify/react";
import useResponsive from "../../../../../Hooks/useResponsive";

import { Form, FormikProvider } from "formik";

export default function AddressInformation({ formik, setActiveStep, step }) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  console.log("formik.address", formik);
  const [checked, setChecked] = React.useState(false);

  const handleChecked = (e) => {
    if (e.target.checked) {
      setChecked(e.target.checked);
      formik.setFieldValue("PSThouseName", formik.values.houseName);
      formik.setFieldValue("PSTstreetOrArea", formik.values.streetOrArea);
      formik.setFieldValue("PSTcity", formik.values.city);
      formik.setFieldValue("PSTpostOffice", formik.values.postOffice);
      formik.setFieldValue("PSTdistrict", formik.values.district);
      formik.setFieldValue("PSTstate", formik.values.state);
      formik.setFieldValue("PSTcountry", formik.values.country);
      formik.setFieldValue("PSTpinCode", formik.values.pinCode);
    } else if (!e.target.checked) {
      setChecked(false);
      formik.setFieldValue("PSThouseName", "");
      formik.setFieldValue("PSTstreetOrArea", "");
      formik.setFieldValue("PSTcity", "");
      formik.setFieldValue("PSTpostOffice", "");
      formik.setFieldValue("PSTdistrict", "");
      formik.setFieldValue("PSTstate", "");
      formik.setFieldValue("PSTcountry", "");
      formik.setFieldValue("PSTpinCode", "");
    }
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {!smUp ? (
            <>
              <Typography padding={1} variant="h6">
                Address Information
              </Typography>
            </>
          ) : null}
          <Box sx={{ margin: !smUp ? 0.5 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="House No. / Name "
                variant="outlined"
                fullWidth
                name="houseName"
                value={formik.values.houseName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.houseName && formik.errors.houseName
                )}
                helperText={formik.touched.houseName && formik.errors.houseName}
              />

              <TextField
                label="Street / Area"
                variant="outlined"
                fullWidth
                name="streetOrArea"
                value={formik.values.streetOrArea}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.streetOrArea && formik.errors.streetOrArea
                )}
                helperText={
                  formik.touched.streetOrArea && formik.errors.streetOrArea
                }
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 0.5 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="City / Town "
                variant="outlined"
                fullWidth
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.city && formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />

              <TextField
                label="Post Office"
                variant="outlined"
                fullWidth
                name="postOffice"
                value={formik.values.postOffice}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.postOffice && formik.errors.postOffice
                )}
                helperText={
                  formik.touched.postOffice && formik.errors.postOffice
                }
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 0.5 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="District "
                variant="outlined"
                fullWidth
                name="district"
                value={formik.values.district}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.district && formik.errors.district
                )}
                helperText={formik.touched.district && formik.errors.district}
              />

              <TextField
                label="State "
                variant="outlined"
                fullWidth
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.state && formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 0.5 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="Country"
                variant="outlined"
                fullWidth
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.country && formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />

              <TextField
                label="Pincode"
                variant="outlined"
                fullWidth
                name="pinCode"
                value={formik.values.pinCode}
                onChange={formik.handleChange}
                // required
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 6);
                }}
                type="number"
                error={Boolean(formik.touched.pinCode && formik.errors.pinCode)}
                helperText={formik.touched.pinCode && formik.errors.pinCode}
              />
            </Stack>
          </Box>
          <Divider variant="middle" />
          {/* ---------------------------------------------------------------------------------- */}
          <Box>
            <Stack direction={"row"}>
              <Typography>
                Is Permanent Address and Communication <br />
                Address are Same ? *
              </Typography>
              <Switch
                checked={checked}
                onChange={(e) => handleChecked(e)}
                inputProps={{
                  "aria-label": "controlled",
                }}
              />
            </Stack>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ margin: !smUp ? 0.5 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="House No. / Name "
                variant="outlined"
                fullWidth
                name="PSThouseName"
                value={formik.values.PSThouseName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.PSThouseName && formik.errors.PSThouseName
                )}
                helperText={
                  formik.touched.PSThouseName && formik.errors.PSThouseName
                }
              />
              <TextField
                label="Street / Area"
                variant="outlined"
                fullWidth
                name="PSTstreetOrArea"
                value={formik.values.PSTstreetOrArea}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.PSTstreetOrArea &&
                    formik.errors.PSTstreetOrArea
                )}
                helperText={
                  formik.touched.PSTstreetOrArea &&
                  formik.errors.PSTstreetOrArea
                }
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 0.5 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="City / Town "
                variant="outlined"
                fullWidth
                name="PSTcity"
                value={formik.values.PSTcity}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.PSTcity && formik.errors.PSTcity)}
                helperText={formik.touched.PSTcity && formik.errors.PSTcity}
              />

              <TextField
                label="Post Office"
                variant="outlined"
                fullWidth
                name="PSTpostOffice"
                value={formik.values.PSTpostOffice}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.PSTpostOffice && formik.errors.PSTpostOffice
                )}
                helperText={
                  formik.touched.PSTpostOffice && formik.errors.PSTpostOffice
                }
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 0.5 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="District "
                variant="outlined"
                fullWidth
                name="PSTdistrict"
                value={formik.values.PSTdistrict}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.PSTdistrict && formik.errors.PSTdistrict
                )}
                helperText={
                  formik.touched.PSTdistrict && formik.errors.PSTdistrict
                }
              />

              <TextField
                label="State "
                variant="outlined"
                fullWidth
                name="PSTstate"
                value={formik.values.PSTstate}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.PSTstate && formik.errors.PSTstate
                )}
                helperText={formik.touched.PSTstate && formik.errors.PSTstate}
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 0.5 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="Country"
                variant="outlined"
                fullWidth
                name="PSTcountry"
                value={formik.values.PSTcountry}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.PSTcountry && formik.errors.PSTcountry
                )}
                helperText={
                  formik.touched.PSTcountry && formik.errors.PSTcountry
                }
              />

              <TextField
                label="Pincode"
                variant="outlined"
                fullWidth
                name="PSTpinCode"
                value={formik.values.PSTpinCode}
                onChange={formik.handleChange}
                type="number"
                error={Boolean(
                  formik.touched.PSTpinCode && formik.errors.PSTpinCode
                )}
                helperText={
                  formik.touched.PSTpinCode && formik.errors.PSTpinCode
                }
              />
            </Stack>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box margin={1}>
              <Button
                onClick={() => setActiveStep(step - 1)}
                variant="outlined"
              >
                Back
              </Button>
            </Box>
            <Box margin={1}>
              <Button variant="contained" type="submit">
                Next
                <Icon icon="tabler:player-track-next" width="25" height="25" />
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
