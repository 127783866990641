import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Button, Stack } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
    preferenceAvailability,
    createSchedulePreference,
} from "../../../redux/features/sessionSlice";
import { useSnackbar } from "notistack";

export const ScheduleAvailabilityCheck = ({ params, data }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(params.row.fromDate);
    const [time, setFromTime] = React.useState(
        dayjs(params.row.fromTime, "HH:mm:ss")
    );
    const schedules = useSelector((state) => state.sessions.schedulePreview);
    const preferences = useSelector((state) => state.sessions.preference);
    const subjectEnrollmentId = useSelector(
        (state) => state.sessions.subjectEnrollmentId
    );

    const previewData = useSelector((state) => state.sessions.preview);

    const [res, setRes] = React.useState(schedules);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            sessionNo: params.id,
            fromDate: params.row.fromDate || "",
            fromTime: params.row.fromTime || "",
            isAvailable: params.row.isAvailable,
        },
        onSubmit: (values) => {
            const id = params.id;
            const newData111 = [...res];

            const i = newData111.findIndex((x) => x.sessionNo === id);
            newData111[i] = values;

            const data = {
                subjectEnrollmentId: subjectEnrollmentId,
                // preferences: preferences.preference,
                schedules: newData111,
                sessionNo: params.id,
            };
            dispatch(preferenceAvailability(data)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Box padding={2}>
                        <Stack direction={"row"} spacing={2}>
                            <Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["year", "month", "day"]}
                                        label="From Date"
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                            formik.setFieldValue(
                                                "fromDate",
                                                dayjs(newValue).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <TimePicker
                                        label="From Time"
                                        value={dayjs(time)}
                                        onChange={(newValue) => {
                                            setFromTime(
                                                dayjs(newValue),
                                                formik.setFieldValue(
                                                    "fromTime",
                                                    dayjs(newValue).format(
                                                        "HH:mm:00"
                                                    )
                                                )
                                            );
                                            console.log("time", time);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    size="small"
                                    type="submit"
                                    variant="contained"
                                >
                                    Availability
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Form>
            </FormikProvider>
        </div>
    );
};
