import AttendanceNameImg from "./AttendanceNameImg";
import ApproveAction from "./ApproveAction";
import EditEmployeeAttendance from "./EditEmployeeAttendance";
export const START_PORTION_TABLE_HEAD = [
  {
    field: "name",
    flex: 1,
    minWidth: 300,
    headerName: "Name",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => AttendanceNameImg(params),
  },
  {
    field: "joinedDate",
    flex: 1,
    minWidth: 150,
    headerName: "Join Date",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
];
export const END_PORTION_TABLE_HEAD = [
  {
    field: "totalP",
    minWidth: 100,
    headerName: "Total Present",
    type: "string",
  },
  {
    field: "totalWO",
    minWidth: 100,
    headerName: "Total WO",
    type: "string",
  },
  {
    field: "totalHD",
    minWidth: 100,
    headerName: "Total HD",
    type: "string",
  },
  {
    field: "totalHF",
    minWidth: 100,
    headerName: "Total HF",
    type: "string",
  },
  {
    field: "totalA",
    minWidth: 100,
    headerName: "Total A",
    type: "string",
  },
  {
    field: "totalLWP",
    minWidth: 100,
    headerName: "Total LWP",
    type: "string",
  },
  {
    field: "totalCL",
    minWidth: 100,
    headerName: "Total CL",
    type: "string",
  },
  {
    field: "totalSL",
    minWidth: 100,
    headerName: "Total SL",
    type: "string",
  },
  {
    field: "totalPL",
    minWidth: 100,
    headerName: "Total PL",
    type: "string",
  },
  {
    field: "totalML",
    minWidth: 100,
    headerName: "Total ML",
    type: "string",
  },
  {
    field: "payableDays",
    minWidth: 120,
    headerName: "Total Payable Days",
    type: "string",
  },
  {
    field: "Approve",
    minWidth: 150,
    headerName: "Approve",
    renderCell: (params) => ApproveAction(params),
  },
  // {
  //   field: "Edit",
  //   minWidth: 150,
  //   headerName: "Edit",
  //   renderCell: (params) => EditEmployeeAttendance(params),
  // },
];
