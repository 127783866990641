import React, { useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import {
  CancelButton,
  LogButton,
  SaveButton,
} from "../../../../components/mui/Buttons/Buttons";
import { Box, Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";

const RemarkPopup = ({
  item,
  handleInputChange,
  handleClose,
  update,
  paid,
}) => {
  const [open, setOpen] = useState(false);
  const [remarks, setRemarks] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    // setOpen(false);
    // handleClose();
    enqueueSnackbar("Remarks Added Successfully...!", {
      variant: "success",
    });
    setOpen(false);
    // handleClose();
  };
  return (
    <div>
      <GlobalDialog
        title={"Remarks"}
        open={open}
        setOpen={setOpen}
        closeButton={true}
        maxWidth={"sm"}
        fullWidth
        popupButton={
          <LogButton size="small" action={() => setOpen(true)}>
            {update ? "View Remarks" : paid ? "View Remarks" : "Add Remarks"}
          </LogButton>
        }
        content={
          <>
            <Box>
              <TextField
                label="Remarks"
                multiline
                fullWidth
                rows={3}
                onChange={(e) => handleInputChange(e, item.id, "remarks")}
                value={item?.remarks || remarks?.target?.value || ""}
              />
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", padding: 1 }}
              >
                <Stack direction={"row"} spacing={1}>
                  <CancelButton size="small" action={() => setOpen(false)}>
                    Cancel
                  </CancelButton>
                </Stack>
                <SaveButton
                  type="submit"
                  size="small"
                  disabled={paid ? true : false}
                  action={() => {
                    enqueueSnackbar("Remarks Added Successfully...!", {
                      variant: "success",
                    });
                    setOpen(false);
                    handleClose();
                  }}
                >
                  Save
                </SaveButton>
              </Box>
            </Box>
          </>
        }
      />
    </div>
  );
};

export default RemarkPopup;
