import { Box, Stack } from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import GlobalSelectField from "../mui/GlobalSelectField";
import { useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { CancelButton, SubmitButton } from "../mui/Buttons/Buttons";
import dayjs from "dayjs";
import { reScheduleSession } from "../../redux/features/croSlice";
import { useDispatch } from "react-redux";
import { previousSession } from "../../redux/features/scheduleSlice";
import { useSnackbar } from "notistack";
import useResponsive from "../../Hooks/useResponsive";

export const RescheduleSessionForm = ({ params, handleClose, setOpen }) => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.cro);
    const schedule = useSelector((state) => state.schedule);

    const [value, setValue] = React.useState();
    const scheduleSchema = Yup.object().shape({
        // meetLink: Yup.string().required("Please add meet link"),
        startDate: Yup.string().required("start date required"),
        startTime: Yup.string().required("start time required"),
        reasonId: Yup.string().required("reason required"),
        remarks: Yup.string().required("remarks required"),
    });

    const formik = useFormik({
        initialValues: {
            scheduleId: params,
            startDate: "",
            startTime: "",
            reasonId: "",
            remarks: "",
        },
        validationSchema: scheduleSchema,
        onSubmit: (values) => {
            const data = {
                scheduleId: params,
                startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
                startTime: dayjs(values.startTime).format("HH:mm:ss"),
                reasonId: values.reasonId,
                remarks: values.remarks,
            };
            dispatch(reScheduleSession(data)).then((res) => {
                if (
                    res.payload.status === "error" ||
                    res.payload.status === 422 ||
                    res.payload.status === 400
                ) {
                    console.log("test");
                    setOpen(false);
                    handleClose();
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    handleClose();
                    dispatch(previousSession(schedule.subjectEnrollmentId));
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <Box
                sx={{
                    backgroundColor: "grey.200",
                    padding: 2,
                    borderRadius: 1,
                }}
            >
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Stack direction={smUp ? "row" : "column"} spacing={2}>
                            <Box sx={{ width: "100%" }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label="Start Date"
                                        name="startDate"
                                        value={formik.values.startDate}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "startDate",
                                                e
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                error={Boolean(
                                                    formik.touched.startDate &&
                                                        formik.errors.startDate
                                                )}
                                                helperText={
                                                    formik.touched.startDate &&
                                                    formik.errors.startDate
                                                }
                                                fullWidth
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <TimePicker
                                        label="Start Time"
                                        name="startTime"
                                        value={formik.values.startTime}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "startTime",
                                                e
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                helperText={
                                                    formik.touched.startTime &&
                                                    formik.errors.startTime
                                                }
                                                error={Boolean(
                                                    formik.touched.startTime &&
                                                        formik.errors.startTime
                                                )}
                                                fullWidth
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Stack>
                        <Box sx={{ marginTop: 2 }}>
                            <Stack direction={"column"} spacing={2}>
                                <Box>
                                    <GlobalSelectField
                                        options={{
                                            name: "reasonId",
                                            formik,
                                            sx: {
                                                width: "100%",
                                                "& .MuiInputBase-root": {
                                                    height: 50,
                                                },
                                            },
                                            label: "Reason",
                                            data: data.sessionCancellationReasons,
                                            keys: {
                                                id: "id",
                                                value: "name",
                                            },
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label="Remarks"
                                        fullWidth
                                        name="remarks"
                                        onChange={formik.handleChange}
                                        value={formik.values.remarks}
                                        multiline
                                        rows={3}
                                        error={Boolean(
                                            formik.touched.remarks &&
                                                formik.errors.remarks
                                        )}
                                        helperText={
                                            formik.touched.remarks &&
                                            formik.errors.remarks
                                        }
                                    />
                                </Box>
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 2,
                            }}
                        >
                            <Stack direction={"row"} spacing={2}>
                                <CancelButton
                                    action={() => {
                                        handleClose();
                                        setOpen(false);
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                                <SubmitButton type="submit">
                                    {data.loading
                                        ? "Scheduling..."
                                        : "Schedule"}
                                </SubmitButton>
                            </Stack>
                        </Box>
                    </Form>
                </FormikProvider>
            </Box>
        </div>
    );
};
