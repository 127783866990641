import { Button, Box, Stack } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Chip from "@mui/material/Chip";
import { Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useResponsive from "../../../../Hooks/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { OGA_REMARKS_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/CRM Leads/OgaRemarksTableHead";
import { getDetailedOgaRemarks } from "../../../../redux/features/crmSlice";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SimpleLoader from "../../../Loader/SimpleLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SroRemarks(params) {
  const {details,loading} = useSelector((state) => state.crm);
  const dispatch = useDispatch();
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    dispatch(getDetailedOgaRemarks(params.params.croAssignmentId));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button variant="contained" size="small" onClick={handleClickOpen}>
        Remarks
        <Icon
          style={{ marginLeft: "5px" }}
          icon="ep:view"
          width="15"
          height="15"
        />
      </Button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
      >
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ borderRadius: 200 }}
          >
            <Icon icon="radix-icons:cross-2" width="18" height="18" />
          </Button>
        </DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            letterSpacing: 1,
            fontWeight: "bolder",
            margin: 2,
          }}
        >
          <Stack direction={smUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
            <Stack direction={mdUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
              <Chip
                label={`Name:- ${params.params.studentName}`}
                color="primary"
                variant="outlined"
                icon={
                  <Icon
                    icon="icon-park-outline:edit-name"
                    width="15"
                    height="15"
                  />
                }
              />
              <Chip
                label={`Contact:- ${params.params.contactNumber ||params.params.contactNo }`}
                color="primary"
                variant="outlined"
                icon={<Icon icon="bx:phone" width="15" height="15" />}
              />
            </Stack>
            <Stack direction={mdUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
              <Chip
                label={`Class:- ${params.params.className}`}
                color="primary"
                variant="outlined"
                icon={
                  <Icon
                    icon="material-symbols:school-outline"
                    width="15"
                    height="15"
                  />
                }
              />

              <Chip
                label={`Course:- ${params.params.courseName}`}
                color="primary"
                variant="outlined"
                icon={
                  <Icon
                    icon="material-symbols:subject"
                    width="15"
                    height="15"
                  />
                }
              />
            </Stack>
          </Stack>
        </Box>
        <Divider />
        <Box sx={{ backgroundColor: "grey.200" }}>
          <DialogContent>
            {/* <Box width={1300}>
              <Box sx={{ height: 450, width: "100%" }}>
                <DataGrid
                  loading={data.loading}
                  rows={[{ ...data.details.remarks, id: 1 }]}
                  columns={columns.map((field)=>({
                    ...field,headerAlign:"center",align:"center"
                  }))}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  getRowId={(row) => row.id}
                  hideFooter={true} 
                  getRowHeight={() => 'auto'}
                  sx={{
                    '& .MuiDataGrid-cell': {
                      border: '1px solid #ccc', // Apply border to cells
                    },
                    '& .MuiDataGrid-columnHeaders': {
                      borderBottom: '2px solid #ccc', // Apply border below the header
                    },
                    '& .MuiDataGrid-row': {
                      borderBottom: '1px solid #ccc', // Add border between rows
                    },
                  }}
                />
              </Box>
            </Box> */}
{ loading ? (<SimpleLoader/>) : details?.remarks && (
  <Box>
  <TableContainer component={Paper} sx={{
    border: '1px solid #ccc', // Outer border for the entire table
  }}>
    <Table sx={{ minWidth: 650, borderCollapse: 'collapse' }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 300 ,borderRight: '1px solid #ccc'}} align="center">
            Student Details (In Brief)
          </TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">
            Subjects & Session Count
          </TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">Fee Per Session</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">Sessions Per Week</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">Time Prefered</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">Trainer Prefered</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">Total Fee</TableCell>
          <TableCell align="center">Fee Status</TableCell>

        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
        >
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">{ details.remarks.studentDetails}</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">{ details.remarks.subjectSessions}</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">{ details.remarks.feePerSession}</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">{ details.remarks.sessionsPerWeek}</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">{ details.remarks.timePrefered}</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">{ details.remarks.trainerPrefered}</TableCell>
          <TableCell sx={{borderRight: '1px solid #ccc'}} align="center">{ details.remarks.totalFee}</TableCell>
          <TableCell align="center">{details.remarks.feeStatus}</TableCell>

        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
</Box>
)}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
