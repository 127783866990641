import React from "react";
import "simplebar/src/simplebar.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import ThemeProvider from "./context/ThemeContext/";
import { SnackBarProvider } from "./components/snackbar/SnackBar";
import ModalWrapper from "./components/modal/index";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <ReduxProvider store={store}>
        <ThemeProvider>
            <HelmetProvider>
                <SnackBarProvider>
                    <BrowserRouter>
                        <ModalWrapper />
                        <App />
                    </BrowserRouter>
                </SnackBarProvider>
            </HelmetProvider>
        </ThemeProvider>
    </ReduxProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
