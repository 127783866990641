import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import useResponsive from "../../../Hooks/useResponsive";
import {
  deleteDepartment,
  getAllDepartment,
} from "../../../redux/features/departmentSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDepartment({
  setOpenDelete,
  openDelete,
  params,
}) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.departments.loading);

  const handleClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    dispatch(deleteDepartment(params.row.departmentId)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === 200) {
        enqueueSnackbar(res.payload.message, { variant: "success" });
        dispatch(getAllDepartment());
        setOpenDelete(false);
      }
    });
  };

  return (
    <div>
      <Dialog
        open={openDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Department.."}</DialogTitle>
        <Divider />
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description"> */}
            {smUp && (
              <Box sx={{ width: 380 }}>
                Are you sure you want to delete this Department..?
              </Box>
            )}
            {!smUp && (
              <Box>Are you sure you want to delete this Department..?</Box>
            )}
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "#eff4f9", color: "#2f72b3" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ backgroundColor: "#ffe4de", color: "#c03530" }}
            onClick={handleDelete}
          >
            <Icon
              icon="material-symbols:delete-outline-rounded"
              width="25"
              height="25"
            />
            {loading ? "Deleting.." : "Delete"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
