import React, { useEffect } from 'react'
import GlobalWrapper from '../../../components/Wrapper/GlobalWrapper'
import AddOrUpdateRequest from '../../../components/datagrid/HelpAndSupport/AddOrUpdateRequest'
import { getAllRequests } from '../../../redux/features/helpAndSupportSlice'
import { useDispatch, useSelector } from 'react-redux'
import GlobalDatagrid from '../../../components/mui/DataGrid/GlobalDatagrid'
import { RequsetRaisingTableHeader } from '../../../components/datagrid/HelpAndSupport/RequsetRaisingTableHeader'
import { Paper } from '@mui/material'
import ActionDetails from '../../../components/mui/GlobalAnchor/ActionDetails'
import GlobalAnchorList from '../../../components/mui/GlobalAnchor/GlobalAnchorList'
import DeleteRaiseRequest from '../../../components/datagrid/HelpAndSupport/DeleteRaiseRequest'
import ApproveRequest from '../../../components/datagrid/HelpAndSupport/ApproveRequest'
import RejectRequest from '../../../components/datagrid/HelpAndSupport/RejectRequest'

const RaiseRequest = () => {
  const { resData, loading } = useSelector((store) => store.helpAndSupport)
  const { data, meta } = resData
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData
  // console.log(data, meta)
  const userProfile = JSON.parse(localStorage.getItem('cred')).profile
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllRequests(paginationData))
  }, [gridSize, pageNum, search])

  return (
    <GlobalWrapper title={'Raise Request'} actions={<AddOrUpdateRequest />}>
      <Paper elevation={4}>
        <GlobalDatagrid
          tableHead={RequsetRaisingTableHeader}
          rows={data || []}
          meta={meta?.totalRecords}
          rowUniqueId="id"
          loading={loading}
          rowClick={true}
          actionComponent={
            <>
              <ActionDetails val={rowData?.status?.charAt(0).toUpperCase() + rowData?.status?.slice(1)} />
              <GlobalAnchorList
                label="Update"
                component={<AddOrUpdateRequest paginationData={paginationData} row={rowData} />}
              />
              <GlobalAnchorList
                label="Delete"
                component={<DeleteRaiseRequest requestId={rowData?.id} isDisabled={rowData?.status !== 'pending'} />}
              />
              {meta?.showActions && rowData?.status == 'pending' && (
                <>
                  <GlobalAnchorList
                    label="Approve"
                    component={
                      <ApproveRequest
                        requestId={rowData?.id}
                        isDisabled={rowData?.approver == 0 || rowData?.assignedTo}
                        status={rowData?.status}
                      />
                    }
                  />
                  <GlobalAnchorList
                    label="Reject"
                    component={
                      <RejectRequest
                        requestId={rowData?.id}
                        isDisabled={rowData?.approver == 0 || rowData?.assignedTo}
                        status={rowData?.status}
                      />
                    }
                  />
                </>
              )}
            </>
          }
        />
      </Paper>
    </GlobalWrapper>
  )
}

export default RaiseRequest
