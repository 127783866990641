import { Tooltip } from "@mui/material";
import React from "react";

const TooltipRemarkViewer = ({ value }) => {
    return (
        <Tooltip
            title={
                <p
                    style={{
                        fontSize: "16px",
                        lineHeight: "1.2rem",
                        padding: "5px",
                    }}
                >
                    {value}
                </p>
            }
            followCursor
        >
            <div>{value}</div>
        </Tooltip>
    );
};

export default React.memo(TooltipRemarkViewer);
