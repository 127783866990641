import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Box, Button, Paper, Stack } from "@mui/material";
import { UpdateButton } from "../../../../mui/Buttons/Buttons";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../GridToolBar";
import { useSelector, useDispatch } from "react-redux";
import {
    getCourseAvailable,
    getSubjectsByClass,
    getMediums,
    getSyllabus,
    getBaseCourseListingFiltered,
    getSessionsForBaseCourses,
    getStudentAcademicSessions,
} from "../../../../../redux/features/researchSlice";
import { useFormik, Form, FormikProvider } from "formik";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import { useSnackbar } from "notistack";
import { getClassPreData } from "../../../../../redux/features/mastersSlice";
import useResponsive from "../../../../../Hooks/useResponsive";
import { AcademicSessionsPopupCheck } from "../RenderCellMain";
import { getDetailedCourseEnrollementsByCourse } from "../../../../../redux/features/croSlice";
import StudentAcademicSessions from "./StudentAcademicSessions";

export const UpdateBaseCoursePopup = ({
    dataFiltered,
    category,
    subEnrllDldId,
}) => {
    const [stateData, setStateData] = useState([]);

    const BASE_COURSES_TABLE_HEAD = [
        {
            field: "serialNumber",
            headerName: "SlNo",
            width: 90,
        },
        {
            field: "customName",
            headerName: "Custom Name",
            width: 250,
        },
        {
            field: "textPublisher",
            headerName: "Text Publisher",
            width: 250,
        },
        {
            field: "moduleCount",
            headerName: "No.of Modules",
            width: 200,
        },
        {
            field: "sessionCount",
            headerName: "Session Count",
            width: 90,
        },
        {
            field: "",
            headerName: "Actions",
            width: 90,
            renderCell: (params) => {
                return (
                    <Box padding={1}>
                        <AcademicSessionsPopupCheck {...params.row} />
                    </Box>
                );
            },
        },
    ];
    const smUp = useResponsive("up", "sm");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [classData, setClassData] = useState([]);
    const [gridSize, setGridSize] = useState(5);
    // const [filteredData, setFilterData] = useState();
    const {
        courseAvailablePreData,
        subjectsByClassPreData,
        mediumsPreData,
        syllabusPreData,
        baseCourseFilteredData,
        loading,
    } = useSelector((state) => state.research);
    const { classPreData } = useSelector((state) => state.masters);
    const { detailedCourseEnrollementsByCourse } = useSelector(
        (state) => state.cro
    );
    const { subjectEnrollmentDetailedId, studentAcademicSessions } =
        useSelector((state) => state.research);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseAvailableId: "",
            classId: detailedCourseEnrollementsByCourse.classId || "",
            subjectId: dataFiltered?.subjectId || "",
            mediumId: detailedCourseEnrollementsByCourse.mediumId || "",
            syllabusId: detailedCourseEnrollementsByCourse.syllabusId || "",
            levelId: category ? category : dataFiltered?.categoryId,
        },
        onSubmit: (values) => {
            const newData = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    value === "" ? 0 : value,
                ])
            );
            dispatch(getBaseCourseListingFiltered(newData)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    useEffect(() => {
        if (open === true) {
            const newData = Object.fromEntries(
                Object.entries(formik.values).map(([key, value]) => [
                    key,
                    value === "" ? 0 : value,
                ])
            );

            setTimeout(() => {
                dispatch(getBaseCourseListingFiltered(newData));
            }, 500);
        }
    }, [open]);
    return (
        <div>
            <GlobalDialog
                title="Update Base Course"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"lg"}
                closeButton={true}
                popupButton={
                    <UpdateButton
                        size="small"
                        action={() => {
                            dispatch(
                                getStudentAcademicSessions(
                                    subEnrllDldId
                                        ? subEnrllDldId
                                        : subjectEnrollmentDetailedId
                                )
                            );
                            // dispatch(getClassPreData());
                            // dispatch(getCourseAvailable());
                            // dispatch(getMediums());
                            // dispatch(getSyllabus());
                            setOpen(true);
                        }}
                    ></UpdateButton>
                }
                content={
                    <>
                        {studentAcademicSessions?.length == 0 ? (
                            <Paper elevation={3}>
                                <Box sx={{ width: "100%", padding: 0 }}>
                                    <DataGrid
                                        components={{
                                            Toolbar: CustomGridToolbar,
                                        }}
                                        loading={loading}
                                        autoHeight
                                        sx={{
                                            "& .MuiDataGrid-columnHeaderTitle":
                                                {
                                                    textOverflow: "clip",
                                                    whiteSpace: "break-spaces",
                                                    lineHeight: 1,
                                                },
                                        }}
                                        getRowHeight={() => "auto"}
                                        rows={baseCourseFilteredData}
                                        columns={BASE_COURSES_TABLE_HEAD}
                                        getRowId={(row) => row.serialNumber}
                                        pageSize={gridSize}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        onPageSizeChange={(newGridSize) =>
                                            setGridSize(newGridSize)
                                        }
                                    />
                                </Box>
                            </Paper>
                        ) : (
                            <StudentAcademicSessions />
                        )}
                    </>
                }
            />
        </div>
    );
};
