import { Stack, Typography, Avatar, Box } from "@mui/material";
import React from "react";

export default function AttendanceNameImg(params) {
    //
    return (
        <div>
            <Stack direction={"row"} spacing={1}>
                <Avatar
                    src={`https://dev.teaminterval.net/assets/employee/photo/passport_size/${params.row.avatar}.jpg`}
                    sx={{
                        width: 50,
                        height: 50,
                        border: 3,
                        borderColor: "#e5e8eb",
                    }}
                />
                <Box sx={{ paddingTop: 1 }}>
                    <Typography>{params.row.name}</Typography>
                </Box>
            </Stack>
        </div>
    );
}
