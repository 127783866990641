import { Box, Grid } from "@mui/material";
import React from "react";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";

export const Details = ({ data }) => {
    return (
        <div>
            <Box>
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Mobile" value={data?.mobile} />
                                <Rows label="Class" value={data?.class} />
                                <Rows label="Course" value={data?.course} />
                                <Rows label="CRO Name" value={data?.croName} />
                                <Rows
                                    label="inactive Type"
                                    value={data?.inactiveType}
                                />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows
                                    label="Inactive Date"
                                    value={data?.inactiveDate}
                                />
                                <Rows
                                    label="Inactive Marked Time"
                                    value={data?.inactiveMarkedTime}
                                />
                                <Rows
                                    label="inactive Remark"
                                    value={data?.inactiveRemark}
                                />
                            </>
                        }
                    />
                </Grid>
            </Box>
        </div>
    );
};
