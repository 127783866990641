import React from "react";
import GlobalDeleteDialog from "../../../mui/Dialogue/GlobalDeleteDialog";

export const DeleteExpensePopup = () => {
    return (
        <div>
            <GlobalDeleteDialog
                title="Delete Expense"
                deleteNote="Are you sure.? you want to delete this expense..!"
                btnName={false}
            />
        </div>
    );
};
