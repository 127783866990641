import { Key } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Button,
    Container,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik, validateYupSchema } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import BasicTextField from "../../../components/mui/TextField";
import Page from "../../../components/Page";
import {
    clearState,
    filterDistrict,
    filterStates,
    getPreData,
    saveCroLead,
    saveLead,
} from "../../../redux/features/referrelSlice";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import {
    ADMISSION_FEE_STATUS,
    FEE_STATUS,
    TRAINER_PREFFERED,
} from "../../../utils/constants/InputValues";
import Loader from "../../../components/Loader/Loader";
import {
    ResetButton,
    SaveButton,
} from "../../../components/mui/Buttons/Buttons";

const AddReferel = () => {
    const state = useSelector((state) => state.referrel);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem("cred")).profile;

    const [isCro, setCro] = useState(false);
    const [value, setValue] = useState(dayjs(new Date()));

    const { enqueueSnackbar } = useSnackbar();
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const createCroschema = () => {
        if (isCro) {
            return {
                emailId: yup.string().required("required"),
                name: yup.string().required("Please enter name"),
                classId: yup.number().required("Please select a field"),
                parentName: yup.string().required("Please enter parent name"),
                phoneCode: yup.number().required("This field is required"),
                phoneNum: yup
                    .string()
                    .required("This field is required")
                    .matches(phoneRegExp, "Phone number is not valid")
                    .min(10),
                districtId: yup.number().required("This field is required"),
                stateId: yup.number().required("This field is required"),
                countryId: yup.number().required("This field is required"),
                locality: yup.string().required("Please enter parent Locality"),
                courseId: yup.number().required("This field is required"),
                studentDetails: yup.string().required("This field is required"),
                trainerPrefered: yup
                    .string()
                    .required("This field is required"),
                syllabusId: yup.number().required("This field is required"),
                mediumId: yup.number().required("This field is required"),
                subjectsSessions: yup
                    .string()
                    .required("This field is required"),
                feePerSession: yup.number().required("This field is required"),
                classPerWeek: yup.number().required("This field is required"),
                timePrefered: yup.string().required("This field is required"),
                totalSessions: yup.number().required("This field is required"),
                totalFees: yup.number().required("This field is required"),
                admissionFeeStatus: yup
                    .string()
                    .required("This field is required"),
                admissionFeeReason: yup.string().when("admissionFeeStatus", {
                    is: (admissionFeeStatus) => {
                        return admissionFeeStatus === "Not Paid";
                    },
                    then: yup.string().required("This field is required"),
                }),
                feeStatus: yup.string().required("This field is required"),
                publications: yup.string().required("This field is required"),
                sroAssessmentRemarks: yup
                    .string()
                    .required("This field is required"),
                sroRemarks: yup.string().required("This field is required"),
                admissionConfirmedOn: yup
                    .string()
                    .required("This field is required"),
            };
        } else {
            return {
                name: yup.string().required("Please enter name"),
                classId: yup.number().required("Please select a field"),
                parentName: yup.string().required("Please enter parent name"),
                phoneCode: yup.number().required("This field is required"),
                phoneNum: yup
                    .string()
                    .required("This field is required")
                    .matches(phoneRegExp, "Phone number is not valid")
                    .min(10),
                districtId: yup.number().required("This field is required"),
                stateId: yup.number().required("This field is required"),
                countryId: yup.number().required("This field is required"),
                locality: yup.string().required("Please enter parent Locality"),
                courseId: yup.number().required("This field is required"),
                note: yup.string().required("Please enter parent Locality"),
            };
        }
    };

    const schema = yup.object().shape(createCroschema());

    const formik = useFormik({
        initialValues: {
            name: "",
            classId: "",
            parentName: "",
            phoneCode: "",
            phoneNum: "",
            emailId: "",
            districtId: "",
            stateId: "",
            countryId: "",
            locality: "",
            courseId: "",
            note: "",
            studentDetails: "",
            trainerPrefered: "",
            admissionFeeStatus: "",
            syllabusId: "",
            mediumId: "",
            subjectsSessions: "",
            feePerSession: "",
            classPerWeek: "",
            timePrefered: "",
            totalSessions: "",
            totalFees: "",
            admissionFeeStatus: "",
            admissionFeeReason: "",
            feeStatus: "",
            publications: "",
            sroAssessmentRemarks: "",
            admissionConfirmedOn: new Date().toISOString().split("T")[0],
        },
        validationSchema: schema,
        onSubmit: (values, { resetForm }) => {
            const {
                emailId,
                studentDetails,
                trainerPrefered,
                admissionFeeStatus,
                syllabusId,
                mediumId,
                subjectsSessions,
                feePerSession,
                classPerWeek,
                timePrefered,
                totalSessions,
                totalFees,
                admissionFeeReason,
                feeStatus,
                publications,
                sroAssessmentRemarks,
                admissionConfirmedOn,
                ...others
            } = values;

            if (!isCro) {
                dispatch(saveLead(others)).then((res) => {
                    if (res.payload.status === "success") {
                        resetForm();
                        navigate("/reffer/refferelStatus");
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                    } else {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    }
                });
            } else {
                dispatch(saveCroLead(values)).then((res) => {
                    if (res.payload.status === "success") {
                        resetForm();
                        navigate("/reffer/refferelStatus");
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                    } else {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    }
                });
            }
        },
    });

    const handleChange = (e) => {
        formik.setFieldValue([e.target.name], e.target.value);
    };

    const handleChangeDate = (e) => {
        setValue(e);
        // solving the backspace error of mui date picker
        if (e !== null && JSON.stringify(e.$d) !== "null") {
            const formated = e.toISOString().split("T")[0];
            formik.setFieldValue("admissionConfirmedOn", formated);
        }
    };

    useEffect(() => {
        dispatch(getPreData());
    }, []);

    useEffect(() => {
        if (formik.values.countryId) {
            dispatch(filterStates(formik.values.countryId));
        }
    }, [formik.values.countryId]);

    useEffect(() => {
        dispatch(filterDistrict(formik.values.stateId));
    }, [formik.values.stateId]);

    useEffect(() => {
        if (
            userData?.roleID === 10 ||
            userData?.roleID === 69 ||
            userData?.roleID === 79
        )
            setCro(true);
    }, [userData]);

    return state.loading ? (
        <Loader />
    ) : (
        <Page title="Create Notification">
            <Container component="main">
                {/*..................................HEADING.............................*/}
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Add Referral
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box
                    sx={{
                        paddingLeft: "70%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                ></Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",

                        mt: { md: 4, xs: 2 },
                    }}
                >
                    <Paper elevation={4} sx={{ width: "100%" }}>
                        {/* GRAY-HEADING */}
                        <Box
                            sx={{
                                height: 48,
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                            }}
                        ></Box>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Stack
                                    sx={{
                                        width: "85%",
                                        margin: "0 auto",
                                        p: 4,
                                    }}
                                    gap={2}
                                >
                                    <BasicTextField
                                        name="name"
                                        sx={{ flex: 2 }}
                                        id="outlined-basic"
                                        value={formik.values.name}
                                        label="Name of Student"
                                        variant="outlined"
                                        action={handleChange}
                                        error={Boolean(formik.errors.name)}
                                        helperText={formik.errors.name}
                                    />
                                    <GlobalSelectField
                                        options={{
                                            label: "select class",
                                            formik: formik,
                                            name: "classId",
                                            data: state.classes,
                                            keys: {
                                                id: "classId",
                                                value: "class",
                                            },
                                        }}
                                    />

                                    <BasicTextField
                                        sx={{ flex: 2 }}
                                        id="outlined-basic"
                                        name="parentName"
                                        value={formik.values.parentName}
                                        label="Name of Parent"
                                        variant="outlined"
                                        action={handleChange}
                                        error={Boolean(
                                            formik.errors.parentName
                                        )}
                                        helperText={formik.errors.parentName}
                                    />

                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={state.country}
                                        getOptionLabel={(option) =>
                                            `(+${option.phonecode}) ` +
                                            option.countryName
                                        }
                                        onChange={(e, value) => {
                                            if (value) {
                                                formik.setFieldValue(
                                                    "phoneCode",
                                                    value.phonecode
                                                );
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={Boolean(
                                                    formik.errors.phoneCode
                                                )}
                                                helperText={
                                                    formik.errors.phoneCode
                                                }
                                                label="Country Code"
                                            />
                                        )}
                                    />

                                    <BasicTextField
                                        name="phoneNum"
                                        sx={{ flex: 2 }}
                                        type="tel"
                                        id="outlined-basic"
                                        value={formik.values.phoneNum}
                                        label="mobile Number"
                                        variant="outlined"
                                        action={handleChange}
                                        error={Boolean(formik.errors.phoneNum)}
                                        helperText={formik.errors.phoneNum}
                                    />
                                    {isCro && (
                                        <BasicTextField
                                            name="emailId"
                                            sx={{ flex: 2 }}
                                            type="email"
                                            id="outlined-basic"
                                            value={formik.values.emailId}
                                            label="Email id"
                                            variant="outlined"
                                            action={handleChange}
                                            error={Boolean(
                                                formik.errors.emailId
                                            )}
                                            helperText={formik.errors.emailId}
                                        />
                                    )}
                                    {/* selecting country */}
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={state.country}
                                        getOptionLabel={(option) =>
                                            option.countryName
                                        }
                                        onChange={(e, value) => {
                                            if (value) {
                                                formik.setFieldValue(
                                                    "countryId",
                                                    value.countryId
                                                );
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Country"
                                                error={Boolean(
                                                    formik.errors.countryId
                                                )}
                                                helperText={
                                                    formik.errors.countryId
                                                }
                                            />
                                        )}
                                    />
                                    {/* selecting state */}
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={state.states || []}
                                        getOptionLabel={(option) =>
                                            option.stateName
                                        }
                                        onChange={(e, value) => {
                                            formik.setFieldValue(
                                                "stateId",
                                                value.stateId
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="State"
                                                error={Boolean(
                                                    formik.errors.stateId
                                                )}
                                                helperText={
                                                    formik.errors.stateId
                                                }
                                            />
                                        )}
                                    />
                                    {/* selecting district */}
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={state.districts || []}
                                        getOptionLabel={(option) =>
                                            option.districtName
                                        }
                                        onChange={(e, value) => {
                                            if (value) {
                                                formik.setFieldValue(
                                                    "districtId",
                                                    value.districtId
                                                );
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="District"
                                                error={Boolean(
                                                    formik.errors.districtId
                                                )}
                                                helperText={
                                                    formik.errors.districtId
                                                }
                                            />
                                        )}
                                    />

                                    <BasicTextField
                                        sx={{ flex: 2 }}
                                        id="outlined-basic"
                                        name="locality"
                                        value={formik.values.locality}
                                        label="Locality"
                                        variant="outlined"
                                        action={handleChange}
                                        error={Boolean(formik.errors.locality)}
                                        helperText={formik.errors.locality}
                                    />

                                    {/* this feilds are only for cro Refferel */}

                                    {isCro && (
                                        <>
                                            <GlobalSelectField
                                                options={{
                                                    name: "syllabusId",
                                                    label: "Sylabus",
                                                    formik: formik,
                                                    data: state.syllabus,
                                                    keys: {
                                                        id: "syllabusId",
                                                        value: "syllabus",
                                                    },
                                                }}
                                            />
                                            <GlobalSelectField
                                                options={{
                                                    name: "mediumId",
                                                    label: "Medium",
                                                    formik: formik,
                                                    data: state.medium,
                                                    keys: {
                                                        id: "mediumId",
                                                        value: "medium",
                                                    },
                                                }}
                                            />
                                        </>
                                    )}

                                    <GlobalSelectField
                                        options={{
                                            label: "Course Preffered",
                                            name: "courseId",
                                            formik: formik,
                                            data: state.courses,
                                            keys: {
                                                id: "courseAvailableId",
                                                value: "courseName",
                                            },
                                        }}
                                    />

                                    {!isCro ? (
                                        <BasicTextField
                                            sx={{ flex: 2 }}
                                            id="outlined-basic"
                                            name="note"
                                            value={formik.values.note}
                                            label="Note"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            action={handleChange}
                                            error={Boolean(formik.errors.note)}
                                            helperText={formik.errors.note}
                                        />
                                    ) : (
                                        <BasicTextField
                                            sx={{ flex: 2 }}
                                            id="outlined-basic"
                                            name="studentDetails"
                                            value={formik.values.studentDetails}
                                            label="Student Details (In Brief)"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            action={handleChange}
                                            error={Boolean(
                                                formik.errors.studentDetails
                                            )}
                                            helperText={
                                                formik.errors.studentDetails
                                            }
                                        />
                                    )}

                                    {isCro && (
                                        <>
                                            <BasicTextField
                                                name="subjectsSessions"
                                                sx={{ flex: 2 }}
                                                id="outlined-basic"
                                                label="Subjects & Session Count per Subject"
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                action={handleChange}
                                                error={Boolean(
                                                    formik.errors
                                                        .subjectsSessions
                                                )}
                                                helperText={
                                                    formik.errors
                                                        .subjectsSessions
                                                }
                                            />

                                            <BasicTextField
                                                name="feePerSession"
                                                sx={{ flex: 2 }}
                                                type="number"
                                                id="outlined-basic"
                                                label="Fee per Session"
                                                variant="outlined"
                                                action={handleChange}
                                                error={Boolean(
                                                    formik.errors.feePerSession
                                                )}
                                                helperText={
                                                    formik.errors.feePerSession
                                                }
                                            />

                                            <BasicTextField
                                                name="classPerWeek"
                                                sx={{ flex: 2 }}
                                                type="number"
                                                id="outlined-basic"
                                                action={handleChange}
                                                label="Session per Week"
                                                variant="outlined"
                                                error={Boolean(
                                                    formik.errors.classPerWeek
                                                )}
                                                helperText={
                                                    formik.errors.classPerWeek
                                                }
                                            />

                                            <BasicTextField
                                                name="timePrefered"
                                                sx={{ flex: 2 }}
                                                id="outlined-basic"
                                                label="Time Prefered"
                                                action={handleChange}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                error={Boolean(
                                                    formik.errors.timePrefered
                                                )}
                                                helperText={
                                                    formik.errors.timePrefered
                                                }
                                            />

                                            <GlobalSelectField
                                                options={{
                                                    name: "trainerPrefered",
                                                    label: "Trainer Preffered",
                                                    formik: formik,
                                                    data: TRAINER_PREFFERED,
                                                    keys: {
                                                        id: "value",
                                                        value: "value",
                                                    },
                                                }}
                                            />

                                            <BasicTextField
                                                name="totalSessions"
                                                sx={{ flex: 2 }}
                                                type="number"
                                                id="outlined-basic"
                                                action={handleChange}
                                                label="Total Sessions"
                                                variant="outlined"
                                                error={Boolean(
                                                    formik.errors.totalSessions
                                                )}
                                                helperText={
                                                    formik.errors.totalSessions
                                                }
                                            />

                                            <BasicTextField
                                                name="totalFees"
                                                sx={{ flex: 2 }}
                                                type="number"
                                                id="outlined-basic"
                                                label="Total Fees"
                                                action={handleChange}
                                                variant="outlined"
                                                error={Boolean(
                                                    formik.errors.totalFees
                                                )}
                                                helperText={
                                                    formik.errors.totalFees
                                                }
                                            />

                                            <GlobalSelectField
                                                options={{
                                                    label: "Admission Fee Status",
                                                    name: "admissionFeeStatus",
                                                    formik: formik,
                                                    data: ADMISSION_FEE_STATUS,
                                                    keys: {
                                                        id: "value",
                                                        value: "value",
                                                    },
                                                }}
                                            />

                                            {formik.values
                                                .admissionFeeStatus ===
                                                "Not Paid" && (
                                                <BasicTextField
                                                    name="admissionFeeReason"
                                                    sx={{ flex: 2 }}
                                                    id="outlined-basic"
                                                    action={handleChange}
                                                    label="Reason (if not paid)"
                                                    multiline
                                                    rows={4}
                                                    variant="outlined"
                                                    error={Boolean(
                                                        formik.errors
                                                            .admissionFeeReason
                                                    )}
                                                    helperText={
                                                        formik.errors
                                                            .admissionFeeReason
                                                    }
                                                />
                                            )}

                                            <GlobalSelectField
                                                options={{
                                                    label: "Fee Status",
                                                    name: "feeStatus",
                                                    formik: formik,
                                                    data: FEE_STATUS,
                                                    keys: {
                                                        id: "value",
                                                        value: "value",
                                                    },
                                                }}
                                            />
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <DesktopDatePicker
                                                    label="Admission Confirmed On"
                                                    inputFormat="DD/MM/YYYY"
                                                    value={value}
                                                    minDate={dayjs().add(
                                                        -1,
                                                        "day"
                                                    )}
                                                    maxDate={dayjs()}
                                                    onChange={handleChangeDate}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>

                                            <BasicTextField
                                                name="publications"
                                                sx={{ flex: 2 }}
                                                type="text"
                                                action={handleChange}
                                                id="outlined-basic"
                                                label="Required Books & Publications"
                                                variant="outlined"
                                                error={Boolean(
                                                    formik.errors.publications
                                                )}
                                                helperText={
                                                    formik.errors.publications
                                                }
                                            />

                                            <BasicTextField
                                                name="sroAssessmentRemarks"
                                                sx={{ flex: 2 }}
                                                id="outlined-basic"
                                                label="SRO Assessment Remarks"
                                                multiline
                                                action={handleChange}
                                                rows={4}
                                                variant="outlined"
                                                error={Boolean(
                                                    formik.errors
                                                        .sroAssessmentRemarks
                                                )}
                                                helperText={
                                                    formik.errors
                                                        .sroAssessmentRemarks
                                                }
                                            />

                                            <BasicTextField
                                                name="sroRemarks"
                                                sx={{ flex: 2 }}
                                                id="outlined-basic"
                                                label="SRO Remarks"
                                                multiline
                                                action={handleChange}
                                                rows={4}
                                                variant="outlined"
                                                error={Boolean(
                                                    formik.errors.sroRemarks
                                                )}
                                                helperText={
                                                    formik.errors.sroRemarks
                                                }
                                            />
                                        </>
                                    )}
                                    <Stack
                                        sx={{
                                            width: "100%",
                                            margin: "0 auto",
                                        }}
                                        flexDirection="row"
                                        justifyContent="center"
                                        gap={2}
                                    >
                                        <ResetButton
                                            action={() => formik.resetForm()}
                                        >
                                            Reset
                                        </ResetButton>

                                        <SaveButton type="submit">
                                            Save
                                        </SaveButton>
                                    </Stack>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Paper>
                </Box>
            </Container>
        </Page>
    );
};

export default AddReferel;
