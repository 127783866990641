import { Box } from '@mui/material'
import ToolTip from '../../../ToolTip/ToolTip'
import dayjs from 'dayjs'
import { Icon } from '@iconify/react/dist/iconify.js'

export const postWorkingTableHeader=[
  { field: 'sl_no', headerName: 'Serial No', width: 80 },
  { field: 'date', headerName: 'Date', width: 150 },
  { field: 'name', headerName: 'Employee Name', width: 150 },
  { field: 'department', headerName: 'Department', width: 150 },
  { field: 'status', headerName: 'Status', width: 150,
    renderCell:(params)=>{
      if(params.row.status=='pending'){
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              icon="material-symbols:pending"
              width="20"
              height="20"
              style={{
                color: ' #f1f139',
                marginRight: '4px',
              }}
            />
            Pending
          </Box>
        )
      }else if(params.row.status == 'approved'){
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              icon="icon-park-twotone:success"
              width="20"
              height="20"
              style={{
                color: ' #2acc1e',
                marginRight: '4px',
              }}
            />
            Approved
          </Box>
        )
      }else if(params.row.status == 'rejected'){
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              icon="fluent:text-change-reject-20-filled"
              width="20"
              height="20"
              style={{ color: '#eb0000', marginRight: '4px' }}
            />
            Rejected
          </Box>
        )
      }else{
        return params.row.status
      }
    }
   },
  { field: 'time', headerName: 'Working Hours', width: 150 ,
    renderCell: (params) => {
      return `${dayjs(params.row.from, "HH:mm").format("h:mm A")} - ${dayjs(params.row.to, "HH:mm").format("h:mm A")}`;
    }, 
  },
  { field: 'remarks', headerName: 'Remarks', width: 550 ,
    renderCell: (params) => {
        return <ToolTip expand={params.row.remarks} content={params.row.remarks} />
      },
  },
  
]