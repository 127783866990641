import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CancelButton, SaveButton } from "../../mui/Buttons/Buttons";
import { Divider, Paper, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import useResponsive from "../../../Hooks/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCvStatus,
  getCvStatusPreData,
  getJobApplications,
} from "../../../redux/features/jobApplicationSlice";
import { useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { FormHelperText } from "@mui/material";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CvUpdateForm({ open, setOpen, params, singleData }) {
  const preData = useSelector((state) => state.jobApplication);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const handleClose = () => {
    setOpen(false);
  };

  const date = `${params.params.row.appliedOn.split("T")[0].split("-")[0]}/${
    params.params.row.appliedOn.split("T")[0].split("-")[1]
  }`;
  const CreateSchema = Yup.object().shape({
    status: Yup.string().required("Type Required"),

    // remarks: Yup.string().required("remarks Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: params.params.row.CVStatusId || "",
      remarks: params.params.row.remarks || "",
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const data = {
        id: params.params.row.id,
        status: values.status,
        remarks: values.remarks,
      };
      dispatch(updateCvStatus(data)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
          dispatch(getCvStatusPreData());

          setOpen(false);

          dispatch(getJobApplications(date));
        }
      });
    },
  });

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Paper sx={{ backgroundColor: "grey.200" }}>
          <DialogTitle>{"Update CV status.."}</DialogTitle>
        </Paper>
        <Divider />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <DialogContent>
              {smUp && (
                <Box sx={{ width: 500, padding: 3 }}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="filled"
                        name="status"
                        label="Status"
                        value={formik.values.status}
                        onChange={(e) => {
                          formik.setFieldValue("status", e.target.value);
                        }}
                        error={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                      >
                        {preData.preData.data?.map((item) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{formik.errors.remarks}</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="Remark"
                      rows={3}
                      name="remarks"
                      value={formik.values.remarks}
                      onChange={formik.handleChange}
                      multiline
                      placeholder="Enter your remark..."
                      error={
                        formik.touched.remarks && Boolean(formik.errors.remarks)
                      }
                      helperText={formik.errors.remarks}
                    />
                  </Box>
                </Box>
              )}
              {!smUp && (
                <Box sx={{ width: "100%", padding: 3 }}>
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="filled"
                        label="Status"
                        name="status"
                        value={formik.values.status}
                        onChange={(e) => {
                          formik.setFieldValue("status", e.target.value);
                        }}
                        error={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                      >
                        {preData.preData.data.map((item) => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{formik.errors.remarks}</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <TextField
                      variant="filled"
                      fullWidth
                      label="Remark"
                      rows={3}
                      multiline
                      name="remarks"
                      value={formik.values.remarks}
                      onChange={formik.handleChange}
                      placeholder="Enter your remark..."
                      error={
                        formik.touched.remarks && Boolean(formik.errors.remarks)
                      }
                      helperText={formik.errors.remarks}
                    />
                  </Box>
                </Box>
              )}

              <Divider sx={{ borderBottomWidth: 3 }} />
            </DialogContent>

            <DialogActions>
              <CancelButton action={handleClose}>Cancel</CancelButton>
              <SaveButton type="submit">save</SaveButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
