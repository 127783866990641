import React from "react";
import { useFormik, FormikProvider, Form } from "formik";
import { Autocomplete, Box, Paper, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
    CancelButton,
    SaveButton,
} from "../../../../components/mui/Buttons/Buttons";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
    getUserBookings,
    oneToOneMentorBooking,
} from "../../../../redux/features/mentoringSlice";
import { useSnackbar } from "notistack";

export const BookingForm = ({ setOpen }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(dayjs());
    const { mentors, loading } = useSelector((state) => state.mentoring);
    const [fromTime, setFromTime] = React.useState(dayjs("12:00:00"));
    const [toTime, setToTime] = React.useState(dayjs("12:00:00"));

    const Schema = Yup.object().shape({
        mentor_id: Yup.string().required("mentor_id is required"),
        purpose: Yup.string().required("purpose is required"),
        remarks: Yup.string().required("remarks is required"),
    });

    const formik = useFormik({
        initialValues: {
            mentor_id: "",
            booking_date: dayjs(value).format("YYYY/MM/DD") || "",
            from_time: dayjs(fromTime).format("HH:mm:00") || "",
            to_time: dayjs(toTime).format("HH:mm:00") || "",
            purpose: "",
            remarks: "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            dispatch(oneToOneMentorBooking(values)).then((res) => {
                if (res.payload.status === "error") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "error",
                    });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    setValue(dayjs());
                    setToTime(dayjs("12:00:00"));
                    setFromTime(dayjs("12:00:00"));
                    dispatch(getUserBookings());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    const handleOnChange = (event, value) => {
        // Update the local state with the selected admission number
        if (value) {
            formik.setFieldValue("mentor_id", value.userID);
        } else {
            formik.setFieldValue(" mentor_id", null);
        }
    };

    return (
        <div>
            <Box>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Paper elevation={3} sx={{ padding: 3 }}>
                            <Stack direction={"column"} spacing={2}>
                                <Box>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        sx={{ width: "100%" }}
                                        options={mentors}
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleOnChange}
                                        value={
                                            mentors.find(
                                                (mentor) =>
                                                    mentor.id ===
                                                    formik.values.mentor_id
                                            ) || null
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Mentor"
                                                error={
                                                    formik.touched.mentor_id &&
                                                    Boolean(
                                                        formik.errors.mentor_id
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.mentor_id
                                                }
                                            />
                                        )}
                                    />
                                </Box>
                                <Box>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DemoContainer
                                            components={["DatePicker"]}
                                        >
                                            <DatePicker
                                                name="booking_date"
                                                sx={{ width: "100%" }}
                                                label="Date"
                                                value={value}
                                                disablePast
                                                onChange={(newValue) => {
                                                    // setValue(newValue),
                                                    formik.setFieldValue(
                                                        "booking_date",
                                                        dayjs(newValue).format(
                                                            "YYYY/MM/DD"
                                                        )
                                                    );
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                            {/* ------------------------------------------- */}
                            <Stack direction={"row"} spacing={2} pt={2}>
                                <Box width={"100%"}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DemoContainer
                                            components={["TimePicker"]}
                                        >
                                            <TimePicker
                                                minutesStep={30}
                                                sx={{ width: "100%" }}
                                                name="from_time"
                                                label="From Time"
                                                value={fromTime}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue(
                                                        "from_time",
                                                        dayjs(newValue).format(
                                                            "HH:mm:00"
                                                        )
                                                    );
                                                    setFromTime(newValue);
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                                <Box width={"100%"}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DemoContainer
                                            components={["TimePicker"]}
                                        >
                                            <TimePicker
                                                minutesStep={30}
                                                sx={{ width: "100%" }}
                                                name="to_time"
                                                label="To Time"
                                                value={toTime}
                                                onChange={(newValue) => {
                                                    formik.setFieldValue(
                                                        "to_time",
                                                        dayjs(newValue).format(
                                                            "HH:mm:00"
                                                        )
                                                    );
                                                    setToTime(newValue);
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                            {/* ------------------------------------------------------- */}
                            <Stack direction={"column"} spacing={2} pt={3}>
                                <Box>
                                    <TextField
                                        name="purpose"
                                        label="Purpose"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        value={formik.values.purpose}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.purpose &&
                                            Boolean(formik.errors.purpose)
                                        }
                                        helperText={formik.errors.purpose}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        name="remarks"
                                        label="Remarks"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        value={formik.values.remarks}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.remarks &&
                                            Boolean(formik.errors.remarks)
                                        }
                                        helperText={formik.errors.remarks}
                                    />
                                </Box>
                            </Stack>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    pt: 2,
                                }}
                            >
                                <Stack direction={"row"} spacing={2}>
                                    <CancelButton action={() => setOpen(false)}>
                                        Cancel
                                    </CancelButton>
                                    <SaveButton type="submit">
                                        {loading ? "Saving..." : "Save"}
                                    </SaveButton>
                                </Stack>
                            </Box>
                        </Paper>
                    </Form>
                </FormikProvider>
            </Box>
        </div>
    );
};
