import React from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { set } from "lodash";
import { Button } from "@mui/material";
import { UpdateButton } from "../../../../components/mui/Buttons/Buttons";
import { Form } from "./Form";

export const UpdateRemarks = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                title="Update"
                closeButton={true}
                popupButton={
                    <>
                        <UpdateButton
                            action={() => {
                                setOpen(true);
                            }}
                        >
                            Update
                        </UpdateButton>
                    </>
                }
                content={<Form setOpen={setOpen} />}
            />
        </div>
    );
};
