import React, { useEffect, useState } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { Box, Button, Paper, Typography } from "@mui/material";
import { CourseEnroll } from "./CourseEnroll";
import { SubjectEnrollmentPopup } from "../../../../components/datagrid/myworkspace/cro/StudentEnrollment/SubjectEnrollmentPopup";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { AllStudentsByCroId } from "../../../../redux/features/croSlice";
import { useSelector } from "react-redux";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { STUDENT_ENROLLMENT_TABLE_HEAD } from "../../../../components/datagrid/myworkspace/cro/StudentEnrollment/Enrollment/StudentEnrollmentTableHead";

export default function Enrollment() {
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const { studentsByCroId, croLeads, loading } = useSelector(
        (state) => state.cro
    );
    useEffect(() => {
        dispatch(AllStudentsByCroId());
    }, []);

    return (
        <div>
            <Wrapper
                title="Student Enrollment"
                Content={
                    <>
                        {/* <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <CourseEnroll />
                        </Box> */}
                        <Paper elevation={3}>
                            <Box>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    loading={loading}
                                    autoHeight
                                    rows={studentsByCroId}
                                    columns={STUDENT_ENROLLMENT_TABLE_HEAD}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
