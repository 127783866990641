import React from "react";
import { MarkAttendancePopup } from "./MarkAttendancePopup";
import { DeleteTrainerAttendance } from "./DeleteTrainerAttendance";
import { AddCourseEnrollmentDetailed } from "./AddCourseEnrollmentDetailed";
import { memo } from "react";
import { TpVerificationDetailed } from "./TpVerificationDetailed";
import { useCallback } from "react";
import { AcademicSessionsPopup } from "./StudentEnrollment/AcademicSessionsPopup";
import { SubjectEnrollmentPopup } from "./StudentEnrollment/SubjectEnrollmentPopup";
import { EnrollmentForm } from "./StudentEnrollment/Enrollment/EnrollmentForm";
import SubjectEnrollmentPopUp from "./StudentEnrollment/Enrollment/SubjectEnrollmentPopUp";
import { AssignTutorPopup } from "./AssignTutor/AssignTutorPopup";
import { EditIncomePopup } from "./EditIncomePopup";
import { EditExpensePopup } from "./EditExpensePopup";

const AddAttendance = memo((params) => {
    return <MarkAttendancePopup params={params} />;
});

const TrainerAttendanceDelete = memo((params) => {
    return <DeleteTrainerAttendance params={params} />;
});
const AddEnrollmentDetails = memo((params) => {
    return <AddCourseEnrollmentDetailed params={params} />;
});
const TpVerificationDetail = memo((params) => {
    return <TpVerificationDetailed params={params.tpInvoiceId} />;
});

const AcademicSessionsPopupCheck = memo((params) => {
    return <AcademicSessionsPopup params={params} />;
});
const SubjectEnrollmentPopupView = memo((params) => {
    return <SubjectEnrollmentPopup params={params} />;
});
const EnrollemtFormView = memo((params) => {
    return <SubjectEnrollmentPopUp params={params} />;
});
const AssignTutorPopupView = memo((params) => {
    return <AssignTutorPopup params={params} />;
});
const EditIncomePopupView = memo((params) => {
    return <EditIncomePopup params={params} />;
});
const EditExpensePopupView = memo((params) => {
    return <EditExpensePopup params={params} />;
});
export {
    AddAttendance,
    TrainerAttendanceDelete,
    AddEnrollmentDetails,
    TpVerificationDetail,
    AcademicSessionsPopupCheck,
    SubjectEnrollmentPopupView,
    EnrollemtFormView,
    AssignTutorPopupView,
    EditIncomePopupView,
    EditExpensePopupView,
};
