import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { useState } from "react";
import { Chip, Button } from "@mui/material";
import { AssessmentData } from "./AssessmentData";
import { getPreEvaluationReport } from "../../../../../redux/features/crmSlice";
import { useDispatch } from "react-redux";

export const AssessmentDetails = React.memo(({ params }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    return (
        <div>
            <GlobalDialog
                title={"Assessment Details"}
                maxWidth={"lg"}
                fullWidth={true}
                closeButton={true}
                popupButton={
                    <>
                        {params.classRoomMaterialsName ? (
                            <Chip
                                sx={{
                                    color: "#fff",
                                    backgroundColor:
                                        params.classRoomMaterialsColor,
                                    "&:hover": {
                                        backgroundColor: "#fff",
                                        color: params.classRoomMaterialsColor,
                                    },
                                }}
                                label={params.classRoomMaterialsName}
                                onClick={() => {
                                    dispatch(
                                        getPreEvaluationReport(
                                            params.jqaAssignmentId
                                        )
                                    );
                                    setOpen(true);
                                }}
                            />
                        ) : null}
                    </>
                }
                open={open}
                setOpen={setOpen}
                content={<AssessmentData />}
            />
        </div>
    );
});
