import React from "react";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
import { projectPackageStatus } from "../../redux/features/mastersSlice";

export const ProjectPackageStatus = ({ params }) => {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.isActive}
                api={projectPackageStatus(params.packageId)}
            />
        </div>
    );
};
