import { DeleteSubjectSessionCount } from "./DeleteSubjectSessionCount";
import Update from "./Update";

export const SUBJECT_SESSION_COUNT = [
  { field: "serialNumber", headerName: "Sl No", width: 90 },
  {
    field: "subjectName",
    type: "string",
    headerName: "Subject",
    width: 150,
  },
  {
    field: "totalSessions",
    headerName: "Total Sessions",
    width: 250,
    renderCell: (params) => <Update {...params} />,
  },
  {
    field: "deletedata",
    headerName: "Delete",
    width: 150,
    renderCell: (params) => <DeleteSubjectSessionCount {...params} />,
  },
];
