import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { CabinBooking } from "../../views/user/EmployeeServices";
import useRoles from "../../Hooks/useRoles";

const CabinBookingRouter = () => {
  useRoles("cabin booking");
  const { role } = useSelector((state) => state);
  return (
    <Routes>
      <Route path="/cabin-booking" element={<CabinBooking />} />
    </Routes>
  );
};

export default CabinBookingRouter;
