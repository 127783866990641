import { Button } from "@mui/material";
import { Icon } from "@iconify/react";

export const CRO_EVALUATIONS_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "admnNo",
        headerName: "Admission Number",
        width: 150,
    },
    {
        field: "studentName",
        headerName: "Name",
        width: 150,
    },
    {
        field: "studentMobNo",
        headerName: "Mobile No",
        width: 150,
    },
    {
        field: "className",
        headerName: "Class",
        width: 150,
    },
    {
        field: "syllabusName",
        headerName: "Sylabus",
        width: 150,
    },
    {
        field: "courseName",
        headerName: "Course",
        width: 150,
    },
    {
        field: "courseEnrollmentStatus",
        headerName: "Course Status",
        width: 150,
        renderCell: (params) => (
            <div
                style={{
                    color:
                        params.row.courseEnrollmentStatus === 0
                            ? "red"
                            : params.row.courseEnrollmentStatus === 1
                            ? "orange"
                            : "green",
                }}
            >
                {params.row.courseEnrollmentStatus === 0
                    ? "in-active"
                    : params.row.courseEnrollmentStatus === 1
                    ? "Active"
                    : "temporarly-inactive"}
            </div>
        ),
    },
    {
        field: "subjectName",
        headerName: "Subject",
        width: 150,
    },
    {
        field: "sroPublications",
        headerName: "Publications",
        width: 150,
    },
    {
        field: "jqaEvaluationPriority",
        headerName: "Priority",
        width: 150,
        renderCell: ({ row }) => (
            <div
                style={{
                    color:
                        row.jqaEvaluationPriority === "Very Low"
                            ? "green"
                            : row.jqaEvaluationPriority === "Low"
                            ? "#ff9a00"
                            : row.jqaEvaluationPriority === "Medium"
                            ? "orange"
                            : row.jqaEvaluationPriority === "High" ||
                              row.jqaEvaluationPriority === "Critical"
                            ? "red"
                            : null,
                }}
            >
                {row.jqaEvaluationPriority}
            </div>
        ),
    },
    {
        field: "trainerName",
        headerName: "Trainer Name",
        width: 150,
    },
    {
        field: "mobNo",
        headerName: "Mobile",
        width: 150,
    },
    {
        field: "jqaEvaluationTrainerZeroKaHero",
        headerName: "Zero ka Hero",
        width: 150,
        renderCell: ({ row }) =>
            row.jqaEvaluationTrainerZeroKaHero === 0 ? (
                <Button variant="contained">
                    <Icon icon="raphael:cross" />
                </Button>
            ) : (
                <Button variant="contained">
                    <Icon icon="teenyicons:tick-small-solid" />
                </Button>
            ),
    },
    {
        field: "jqaEvaluationSessionsCompleted",
        headerName: "Compleated Sessions Date (when Asigned)",
        width: 150,
    },
    {
        field: "jqaEvaluationLastSession",
        headerName: "Last Sessions Date (when Asigned)",
        width: 150,
    },
    {
        field: "sessionLog",
        headerName: "Sessions Log (full)",
        width: 150,
        renderCell: ({ row }) => <Button variant="contained">View</Button>,
    },
    {
        field: "jqaEvaluationNo",
        headerName: "Evaluation No",
        width: 150,
    },
    {
        field: "jqaName",
        headerName: "JQA",
        width: 150,
    },
    {
        field: "parenentFeedBack",
        headerName: "parent Feedback",
        width: 150,
        renderCell: ({ row }) => <Button variant="contained">View</Button>,
    },
    {
        field: "trainerFeedback",
        headerName: "Trainer Feedback",
        width: 150,
        renderCell: ({ row }) => <Button variant="contained">View</Button>,
    },
    {
        field: "qaReport",
        headerName: "QA Report",
        width: 150,
        renderCell: ({ row }) => <Button variant="contained">View</Button>,
    },
    {
        field: "jqaEvaluationReportUpdateTimestamp",
        headerName: "JQA Last Updated On",
        width: 150,
    },
];
