import React, { useEffect, useState } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Paper, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { useSelector, useDispatch } from "react-redux";
import { getAllRegisteredTrainers } from "../../../../redux/features/croSlice";
import { ASSIGN_TUTOR_TABLE_HEAD as columns } from "../../../../components/datagrid/myworkspace/cro/AssignTutor/AssignTutorTableHead";
import { da } from "date-fns/locale";
import { DynamicForm } from "../../../../components/Form/DynamicForm";

export default function AssignTutor() {
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const { registeredTrainers, loading } = useSelector((state) => state.cro);
    useEffect(() => {
        dispatch(getAllRegisteredTrainers());
    }, []);

    return (
        <div>
            <Wrapper
                title="Assign Tutor"
                Content={
                    <>
                        <Paper elevation={3}>
                            <Box>
                                <DataGrid
                                    loading={loading}
                                    rows={registeredTrainers}
                                    columns={columns}
                                    columnBuffer={30}
                                    pageSize={gridSize}
                                    autoHeight
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    getRowId={(row) => {
                                        return row.serialNumber;
                                    }}
                                    components={{
                                        Toolbar: CustomGridToolbar,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
            <DynamicForm />
        </div>
    );
}
