import React from "react";
import { CustomRow, Mytable, Rows } from "../../../mui/DataGrid/Details";
import { Box, Button, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import SessionsLogPopUp from "./SessionsLogPopUp";
import ParentFeedbackPopUp from "./ParentFeedbackPopUp";
import TrainerFeedbackPopUp from "./TrainerFeedbackPopUp";
import QaReportPopUp from "./QaReportPopUp";

const CRoEvaluationsDetails = ({ data, filter }) => {
    return (
        <Box>
            <Grid container spacing={1}>
                <Mytable
                    tableRow={
                        <>
                            <Rows label="mobile" value={data?.studentMobNo} />
                            <Rows label="Class" value={data?.className} />
                            <Rows label="Syllabus" value={data?.syllabusName} />
                            <Rows label="Course" value={data?.courseName} />

                            <CustomRow
                                label="Course Status"
                                component={
                                    <div
                                        style={{
                                            color:
                                                data.courseEnrollmentStatus ===
                                                0
                                                    ? "red"
                                                    : data.courseEnrollmentStatus ===
                                                      1
                                                    ? "orange"
                                                    : "green",
                                        }}
                                    >
                                        {data.courseEnrollmentStatus === 0
                                            ? "in-active"
                                            : data.courseEnrollmentStatus === 1
                                            ? "Active"
                                            : "temporarly-inactive"}
                                    </div>
                                }
                            />

                            <Rows label="Subject" value={data?.subjectName} />
                            <Rows
                                label="Publications"
                                value={data?.sroPublications}
                            />

                            <Rows
                                label="Trainer Name"
                                value={data?.trainerName}
                            />

                            <Rows label="Trainer Mobile" value={data?.mobNo} />

                            <Rows
                                label="Compleated Sessions Date (when Asigned)"
                                value={data?.jqaEvaluationSessionsCompleted}
                            />
                        </>
                    }
                />

                <Mytable
                    tableRow={
                        <>
                            <Rows
                                label="Last Sessions Date (when Asigned)"
                                value={data?.jqaEvaluationLastSession}
                            />
                            <Rows
                                label="Evaluation No"
                                value={data?.jqaEvaluationNo}
                            />
                            <Rows label="JQA" value={data?.jqaName} />

                            <Rows
                                label="JQA Last Updated On"
                                value={data?.jqaEvaluationReportUpdateTimestamp}
                            />

                            <CustomRow
                                label="Priority"
                                component={
                                    <div
                                        style={{
                                            color:
                                                data.jqaEvaluationPriority ===
                                                "Very Low"
                                                    ? "green"
                                                    : data.jqaEvaluationPriority ===
                                                      "Low"
                                                    ? "#ff9a00"
                                                    : data.jqaEvaluationPriority ===
                                                      "Medium"
                                                    ? "orange"
                                                    : data.jqaEvaluationPriority ===
                                                          "High" ||
                                                      data.jqaEvaluationPriority ===
                                                          "Critical"
                                                    ? "red"
                                                    : null,
                                        }}
                                    >
                                        {data.jqaEvaluationPriority}
                                    </div>
                                }
                            />

                            <CustomRow
                                label="Zero ka Hero"
                                component={
                                    data.jqaEvaluationTrainerZeroKaHero ===
                                    0 ? (
                                        <Button variant="contained">
                                            <Icon icon="raphael:cross" />
                                        </Button>
                                    ) : (
                                        <Button variant="contained">
                                            <Icon icon="teenyicons:tick-small-solid" />
                                        </Button>
                                    )
                                }
                            />

                            <CustomRow
                                label="Sessions Log (full)"
                                component={<SessionsLogPopUp data={data} />}
                            />

                            <CustomRow
                                label="parent Feedback"
                                component={<ParentFeedbackPopUp data={data} />}
                            />

                            <CustomRow
                                label="Trainer Feedback"
                                component={<TrainerFeedbackPopUp data={data} />}
                            />

                            <CustomRow
                                label="QA Report"
                                component={<QaReportPopUp data={data} />}
                            />

                            {filter === "compleated" && (
                                <CustomRow
                                    label="Cro Remarks"
                                    component={
                                        <p>{data?.croEvaluationRemarks}</p>
                                    }
                                />
                            )}

                            {filter === "compleated" && (
                                <CustomRow
                                    label="CRO Acknowledged on"
                                    component={
                                        <p>
                                            {data?.croEvaluationAcknowledgedAt}
                                        </p>
                                    }
                                />
                            )}
                        </>
                    }
                />
            </Grid>
        </Box>
    );
};

export default CRoEvaluationsDetails;
