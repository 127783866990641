import { Box, Typography, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import CustomGridToolbar from "../datagrid/GridToolBar";
import { PRIVILAGE_TABLE_HEAD as columns } from "../datagrid/privileges/privilegeTableHead";

const PrivilageViews = ({ data }) => {
    return (
        <div>
            <Typography variant="h4" sx={{ mb: 1, m: 3 }}>
                Privilages
            </Typography>

            <Paper
                elevation={3}
                sx={{ borderRadius: 1, width: "100%", marginTop: 3 }}
            >
                <Box
                    sx={{
                        backgroundColor: "grey.200",
                        borderRadius: "8px 8px 0 0",
                        display: "flex",
                        height: 44,
                    }}
                ></Box>
                <Box sx={{ width: "100%" }}>
                    <DataGrid
                        loading={
                            data.privileges.loading ||
                            data.privileges.permissions.loading
                        }
                        rows={data.privileges.permissions.data}
                        columns={columns}
                        autoHeight
                        getRowId={(row) => row.serialNumber}
                        components={{ Toolbar: CustomGridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {
                                    debounceMs: 500,
                                },
                            },
                        }}
                    />
                </Box>
            </Paper>
        </div>
    );
};

export default PrivilageViews;
