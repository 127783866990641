import React, { useEffect, useState } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Chip, Grid, Paper, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MENTORING_BOOKINGS_TABLE_HEAD } from "../../../../components/datagrid/EmployeeService/Mentoring/MentoringBookingTableHead";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { BookingPopUp } from "./BookingPopUp";
import { useSelector, useDispatch } from "react-redux";
import {
    getAllMentors,
    getUserBookings,
} from "../../../../redux/features/mentoringSlice";
import dayjs from "dayjs";
import AttendanceCalender from "../AttendanceCalender";

const dataHead = [
    {
        field: "id",
        headerName: "Sl No",
        width: 30,
    },
    {
        field: "title",
        headerName: "title",
        width: 150,
    },
    {
        field: "actualPrice",
        headerName: "actualPrice",
        width: 250,
    },
    {
        field: "description1",
        headerName: "description1",
        width: 250,
    },
    {
        field: "description2",
        headerName: "description2",
        width: 120,
    },

    {
        field: "description3",
        headerName: "description3",
        width: 120,
    },
    {
        field: "description4",
        headerName: "description4",
        width: 90,
    },

    {
        field: "description5",
        headerName: "description5",
        width: 150,
    },
    {
        field: "main_image",
        headerName: "main_image",
        width: 150,
    },
    {
        field: "sub_image1",
        headerName: "sub_image1",
        width: 150,
    },
    {
        field: "sub_image2",
        headerName: "sub_image2",
        width: 150,
    },
    {
        field: "sub_image3",
        headerName: "sub_image3",
        width: 150,
    },
    {
        field: "sub_image4",
        headerName: "sub_image4",
        width: 150,
    },
];

export const MentoringBookings = () => {
    const dispatch = useDispatch();
    const { userBookings, loading } = useSelector((state) => state.mentoring);
    const [gridSize, setGridSize] = useState(5);

    useEffect(() => {
        dispatch(getUserBookings());
        dispatch(getAllMentors());
    }, []);

    return (
        <Wrapper
            title="Mentoring Bookings"
            actions={<BookingPopUp />}
            Content={
                <>
                    <Paper elevation={3}>
                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                components={{ Toolbar: CustomGridToolbar }}
                                loading={loading}
                                autoHeight
                                sx={{
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1,
                                    },
                                }}
                                // getRowHeight={() => "auto"}
                                rows={userBookings}
                                columns={MENTORING_BOOKINGS_TABLE_HEAD}
                                getRowId={(row) => row.serialNumber}
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                            />
                        </Box>
                    </Paper>
                    {/* ------------------------------------------------------------------------------- */}
                    {/* <AttendanceCalender /> */}
                    {/* --------------------------------------------------------- */}
                    {/* <DataGrid
                        components={{ Toolbar: CustomGridToolbar }}
                        loading={loading}
                        autoHeight
                        sx={{
                            "& .MuiDataGrid-columnHeaderTitle": {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1,
                            },
                        }}
                        // getRowHeight={() => "auto"}
                        rows={datas.categories[4].products}
                        columns={dataHead}
                        getRowId={(row) => row.id}
                        pageSize={gridSize}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        onPageSizeChange={(newGridSize) =>
                            setGridSize(newGridSize)
                        }
                    /> */}
                </>
            }
        />
    );
};
