import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import { AddButton, CancelButton, SaveButton } from "../mui/Buttons/Buttons";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
    getProjectPackagesPreData,
    getClassPreData,
    addPackageClasses,
    getPackageClasses,
} from "../../redux/features/mastersSlice";
import { useSnackbar } from "notistack";

export const AddPackageClass = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const { projectPackagesPreData, classPreData, loading } = useSelector(
        (state) => state.masters
    );

    const Schema = Yup.object().shape({
        classId: Yup.string().required("classId is required"),
        packageId: Yup.string().required("packageId is required"),
    });

    const formik = useFormik({
        initialValues: {
            classId: "",
            packageId: "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            dispatch(addPackageClasses(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getPackageClasses());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                title="Add Package Class"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <AddButton
                        action={() => {
                            dispatch(getClassPreData());
                            dispatch(getProjectPackagesPreData());
                            setOpen(true);
                        }}
                    >
                        Add Package Class
                    </AddButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <Autocomplete
                                            disablePortal
                                            name="classId"
                                            id="combo-box-demo"
                                            options={classPreData}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            onChange={(e, option, value) => {
                                                formik.setFieldValue(
                                                    "classId",
                                                    option.id
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    {...params}
                                                    label="class"
                                                    error={
                                                        formik.touched
                                                            .classId &&
                                                        Boolean(
                                                            formik.errors
                                                                .classId
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.classId
                                                    }
                                                />
                                            )}
                                        />

                                        <Autocomplete
                                            disablePortal
                                            name="packageId"
                                            id="combo-box-demo"
                                            options={projectPackagesPreData}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            onChange={(e, option) => {
                                                formik.setFieldValue(
                                                    "packageId",
                                                    option.id
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    {...params}
                                                    label="Package"
                                                    error={
                                                        formik.touched
                                                            .packageId &&
                                                        Boolean(
                                                            formik.errors
                                                                .packageId
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.packageId
                                                    }
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack spacing={2} direction={"row"}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
