import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { Box, Paper } from "@mui/material";

import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { RETAIN_CALL_ANALYTICS_TABLE_HEAD as columns } from "../../../components/datagrid/CRM/Analytics/RetainCallAnalytics/RetainCallAnalyticsTableHead";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";

const data = [
    {
        serialNumber: 1,
        cro: "AJVAD SABAH K C",
        totalAssigned: 133,
        pending: 12,
        retained: 34,
        retainable: 22,
        notRetainable: 46,
        satisfiedNotRetainable: 2,
        feeIssue: 2,
    },
];

export default function RetainCallAnalytics() {
    const [gridSize, setGridSize] = React.useState(5);
    const [value, setValue] = React.useState(null);
    return (
        <div>
            <Wrapper
                title="Retain Call Analytics"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From"
                                    value={value}
                                    openTo="year"
                                    views={["year", "month", "day"]}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To"
                                    value={value}
                                    openTo="year"
                                    views={["year", "month", "day"]}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        // formik: formik,
                                        label: "User Type",
                                        name: "courseId",
                                        sx: { width: "100%" },
                                        // data: data.coursesPreData,

                                        keys: {
                                            id: "courseId",
                                            value: "courseName",
                                        },
                                    }}
                                    variant="filled"
                                />
                            </Box>
                        </Box>
                        <Paper elevation={3}>
                            <Box width="100%">
                                <Box
                                    sx={{
                                        backgroundColor: "grey.200",
                                        borderRadius: "8px 8px 0 0",
                                        display: "flex",
                                        height: 44,
                                    }}
                                ></Box>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    // loading={loading}
                                    rows={data}
                                    columns={columns}
                                    autoHeight
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
