import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Button, Chip, Stack } from "@mui/material";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { CRO_INCOME_FORECAST_TBL_HEAD as columns } from "../../../../components/datagrid/myworkspace/cro/CROIncomeForecast/CroIncomeForecastTableHead";
import { Actions } from "./Actions";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";

const Head = [
    {
        field: "Name of student",
        headerName: "Student Name",
        width: 250,
    },
];

export default function CROIncomeForecast() {
    const [value, setValue] = React.useState(dayjs("2022-04-07"));
    const [selectedRows, setSelectedRows] = React.useState([]);

    return (
        <div>
            <Wrapper
                title="CRO Income Forecast"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 2,
                            }}
                        >
                            <Stack direction={"column"} spacing={1}>
                                <Chip
                                    sx={{
                                        color: "#fff",
                                        backgroundColor: "#26b99a",
                                    }}
                                    label="Total Fee Collected :- 35000"
                                />
                                <Chip
                                    sx={{
                                        color: "#fff",
                                        backgroundColor: "#26b99a",
                                    }}
                                    label="Total Sessions :- 20"
                                />
                                <Chip
                                    sx={{
                                        color: "#fff",
                                        backgroundColor: "#dc3545",
                                    }}
                                    label="Next Month Total Income Forecast :- 35000"
                                />
                            </Stack>
                            <Stack
                                direction={"column"}
                                spacing={1}
                                paddingLeft={2}
                            >
                                <Chip
                                    sx={{
                                        color: "#fff",
                                        backgroundColor: "#dc3545",
                                    }}
                                    label="Total Fee Collected :- 35000"
                                />
                                <Chip
                                    sx={{
                                        color: "#fff",
                                        backgroundColor: "#dc3545",
                                    }}
                                    label="Total Sessions :- 20"
                                />
                                <Chip
                                    sx={{
                                        color: "#fff",
                                        backgroundColor: "#dc3545",
                                    }}
                                    label="Next Month Total Income Forecast :- 35000"
                                />
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 2,
                                paddingRight: 5,
                            }}
                        >
                            <Stack direction={"row"} spacing={2}>
                                <Box>
                                    <GlobalSelectField
                                        options={{
                                            // formik: formik,
                                            label: "Course",
                                            name: "courseId",
                                            sx: { width: "100%" },
                                            // data: data.coursesPreData,

                                            keys: {
                                                id: "courseId",
                                                value: "courseName",
                                            },
                                        }}
                                        variant="filled"
                                    />
                                </Box>
                                <Box>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            views={["year", "month"]}
                                            label="Year and Month"
                                            minDate={dayjs("2012-03-01")}
                                            maxDate={dayjs("2023-06-01")}
                                            value={value}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="filled"
                                                    {...params}
                                                    helperText={null}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Stack>
                        </Box>

                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"Sl No."}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.course}
                                label="course"
                                name={selectedRows[0]?.Name_of_student}
                                // details={<Details data={selectedRows[0]} />}
                                actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}

const data = [
    {
        "Sl No.": 1,
        "Admission No": 8877,
        "Name of student": "AJSAL",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "He is mainly facing issue to understand hindi and writing difficult words.",
        "First Session Date": "2023-04-26",
        "June Expected Sessions": 1,
        "June Sessions": 1,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "June Notes": "Cross sell",
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 20,
        "Total Required Sessions": 20,
        "Total Sessions Completed": 20,
        "Sessions Completion %": "100 %",
        "Fee Collected %": "100 %",
        "SRO Sales Amount": 6799,
        "Expected Total Income": 6780,
        "Total Fee Paid": 6799,
        Balance: 0,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 2,
        "Admission No": 4686,
        "Name of student": "ASHLIN SUDES",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "New Course Enroll - CRO",
        "First Session Date": "2023-05-20",
        "June Expected Sessions": 8,
        "June Sessions": 2,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 5,
        "Total Required Sessions": 80,
        "Total Sessions Completed": 5,
        "Sessions Completion %": "6.25 %",
        "Fee Collected %": "29.07 %",
        "SRO Sales Amount": 34400,
        "Expected Total Income": 2150,
        "Total Fee Paid": 10000,
        Balance: 24400,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 3,
        "Lead Type": "GCC",
        "Admission No": 4976,
        "Name of student": "CHRISS NIXON",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "New Course Enroll - CRO",
        "First Session Date": "2023-04-11",
        "June Expected Sessions": 32,
        "June Sessions": 5,
        "June Fee Paid": 0,
        "June Income Forecast": 20000,
        "June Income Forecasted Week": "Week 3",
        "July Expected Sessions": 30,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 80,
        "Total Required Sessions": 260,
        "Total Sessions Completed": 50,
        "Sessions Completion %": "30.77 %",
        "Fee Collected %": "23.24 %",
        "SRO Sales Amount": 86060,
        "Expected Total Income": 26480,
        "Total Fee Paid": 20000,
        Balance: 66060,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 4,
        "Admission No": 3819,
        "Name of student": "FATHIMA",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "need to start class on monday . paid 10000 in advance . no admission fee . cause choosed for one year.",
        "First Session Date": "2022-03-15",
        "June Expected Sessions": 106,
        "June Sessions": 0,
        "June Fee Paid": 5000,
        "June Income Forecast": 6850,
        "June Income Forecasted Week": "Week 2",
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 217,
        "Total Required Sessions": 192,
        "Total Sessions Completed": 217,
        "Sessions Completion %": "113.02 %",
        "Fee Collected %": "97.59 %",
        "SRO Sales Amount": 76800,
        "Expected Total Income": 76350,
        "Total Fee Paid": 74950,
        Balance: 1850,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 5,
        "Admission No": 3819,
        "Name of student": "FATHIMA",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "New Course Enroll - CRO",
        "June Expected Sessions": 20,
        "June Sessions": 0,
        "June Fee Paid": 20000,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 0,
        "Total Required Sessions": 290,
        "Total Sessions Completed": 0,
        "Sessions Completion %": "0 %",
        "Fee Collected %": "20.1 %",
        "SRO Sales Amount": 99500,
        "Total Fee Paid": 20000,
        Balance: 79500,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 6,
        "Admission No": 6071,
        "Name of student": "FEZIN",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "New Course Enroll - CRO",
        "First Session Date": "2023-05-08",
        "June Expected Sessions": 15,
        "June Sessions": 2,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 16,
        "Total Required Sessions": 160,
        "Total Sessions Completed": 16,
        "Sessions Completion %": "10 %",
        "Fee Collected %": "42.3 %",
        "SRO Sales Amount": 50240,
        "Expected Total Income": 5024,
        "Total Fee Paid": 21252,
        Balance: 28988,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 7,
        "Admission No": 8843,
        "Name of student": "FIDA P",
        "Course Enrolled": "Classroom - Short Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "ASSIGN TO AJVAD",
        "First Session Date": "2023-04-14",
        "June Expected Sessions": 10,
        "June Sessions": 1,
        "June Fee Paid": 0,
        "June Income Forecast": 10000,
        "June Income Forecasted Week": "Week 3",
        "July Expected Sessions": 10,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 28,
        "Total Required Sessions": 50,
        "Total Sessions Completed": 18,
        "Sessions Completion %": "56 %",
        "Fee Collected %": "44.71 %",
        "SRO Sales Amount": 22365,
        "Expected Total Income": 12516,
        "Total Fee Paid": 10000,
        Balance: 12365,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 8,
        "Admission No": 8942,
        "Name of student": "GIA PAUL",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "Full amount paid.  They need grammar areas only. Please focus on grammar part.  Need communication completely in English.",
        "First Session Date": "2023-05-14",
        "June Expected Sessions": 10,
        "June Sessions": 2,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 7,
        "Total Required Sessions": 40,
        "Total Sessions Completed": 7,
        "Sessions Completion %": "17.5 %",
        "Fee Collected %": "100 %",
        "SRO Sales Amount": 16550,
        "Expected Total Income": 2891,
        "Total Fee Paid": 16550,
        Balance: 0,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 9,
        "Admission No": 8839,
        "Name of student": "HAFIZ ZAMAN",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "paid Amount : 13249/-( admission fee included) need special attention & care he needs a good active experienced teacher please connect today,",
        "First Session Date": "2023-04-19",
        "June Expected Sessions": 13,
        "June Sessions": 2,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 28,
        "Total Required Sessions": 40,
        "Total Sessions Completed": 28,
        "Sessions Completion %": "70 %",
        "Fee Collected %": "100 %",
        "SRO Sales Amount": 12749,
        "Expected Total Income": 8904,
        "Total Fee Paid": 12749,
        Balance: 0,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 10,
        "Admission No": 8728,
        "Name of student": "HANNA FATHIMA",
        "Course Enrolled": "Classroom - Short Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "Please assign this data CRO: ajvad (already mentioned to him) This is a special case..  trust issues ullath kond mathraman avar epo one quarter payment chaithittullath. Class ok annegil balance payment cheyunnathayirikum. Ath njan thenne ayirikum collect cheyuga. Oru paad negativity spread ayath kond thenne avark trust issues und. Ath evidenn maatti kodukanam. Because eni aghne onnum undavilla enn njan promise chaithittund.",
        "First Session Date": "2023-05-05",
        "June Expected Sessions": 16,
        "June Sessions": 2,
        "June Fee Paid": 0,
        "June Income Forecast": 10000,
        "June Income Forecasted Week": "Week 2",
        "July Expected Sessions": 16,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 33,
        "Total Required Sessions": 186,
        "Total Sessions Completed": 17,
        "Sessions Completion %": "17.74 %",
        "Fee Collected %": "10.17 %",
        "SRO Sales Amount": 58997,
        "Expected Total Income": 10494,
        "Total Fee Paid": 6000,
        Balance: 52997,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 11,
        "Admission No": 8046,
        "Name of student": "HAZIM",
        "Course Enrolled": "Classroom - Short Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "need to start",
        "First Session Date": "2023-03-07",
        "June Expected Sessions": 4,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 5000,
        "June Income Forecasted Week": "Week 3",
        "July Expected Sessions": 4,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 88,
        "Total Required Sessions": 20,
        "Total Sessions Completed": 84,
        "Sessions Completion %": "440 %",
        "Fee Collected %": "427.27 %",
        "SRO Sales Amount": 8800,
        "Expected Total Income": 38720,
        "Total Fee Paid": 37600,
        Balance: -28800,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 12,
        "Admission No": 6122,
        "Name of student": "HEERA",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "New Course Enroll - CRO",
        "First Session Date": "2023-06-06",
        "June Expected Sessions": 8,
        "June Sessions": 1,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 1,
        "Total Required Sessions": 145,
        "Total Sessions Completed": 1,
        "Sessions Completion %": "0.69 %",
        "Fee Collected %": "18.15 %",
        "SRO Sales Amount": 55100,
        "Expected Total Income": 380,
        "Total Fee Paid": 10000,
        Balance: 45100,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 13,
        "Admission No": 5991,
        "Name of student": "IZZA NAZNIN",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "Please connect this data today or tomorrow. Don't get delay for the process of starting classes. This a referral data from SANAFIRKa, so please assign a good CRO and handle this data  properly.",
        "First Session Date": "2022-10-12",
        "June Expected Sessions": 10,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 78,
        "Total Required Sessions": 180,
        "Total Sessions Completed": 78,
        "Sessions Completion %": "43.33 %",
        "Fee Collected %": "76.2 %",
        "SRO Sales Amount": 54396,
        "Expected Total Income": 23556,
        "Total Fee Paid": 41448,
        Balance: 12948,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 14,
        "Admission No": 3447,
        "Name of student": "JERWIN",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "ASSIGN TO AJVAD",
        "First Session Date": "2023-03-29",
        "June Expected Sessions": 20,
        "June Sessions": 5,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "June Notes": "Next Month",
        "July Expected Sessions": 20,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 73,
        "Total Required Sessions": 245,
        "Total Sessions Completed": 53,
        "Sessions Completion %": "29.8 %",
        "Fee Collected %": "21.87 %",
        "SRO Sales Amount": 68600,
        "Expected Total Income": 20440,
        "Total Fee Paid": 15000,
        Balance: 53600,
        "July Income Forecast": 15000,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 15,
        "Admission No": 4695,
        "Name of student": "JERWIS",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "ASSIGN TO AJVAD",
        "First Session Date": "2023-04-03",
        "June Expected Sessions": 20,
        "June Sessions": 3,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "June Notes": "Next Month",
        "July Expected Sessions": 20,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 57,
        "Total Required Sessions": 175,
        "Total Sessions Completed": 37,
        "Sessions Completion %": "32.57 %",
        "Fee Collected %": "30.61 %",
        "SRO Sales Amount": 49000,
        "Expected Total Income": 15960,
        "Total Fee Paid": 15000,
        Balance: 34000,
        "July Income Forecast": 15000,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 16,
        "Admission No": 8594,
        "Name of student": "JUEL",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "English Foundation 1,2 level fee: 16540 40 sessions Admission fee: 500 Full payment collected  nalla active aaytle mingleyth samsarikunna teacher aayrknm enn parnjitund, ipol ulla shy maati edukanmnn.Communication English ulla teacher aayrknm.",
        "First Session Date": "2023-04-11",
        "June Expected Sessions": 13,
        "June Sessions": 3,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 29,
        "Total Required Sessions": 40,
        "Total Sessions Completed": 29,
        "Sessions Completion %": "72.5 %",
        "Fee Collected %": "100 %",
        "SRO Sales Amount": 16540,
        "Expected Total Income": 11977,
        "Total Fee Paid": 16540,
        Balance: 0,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 17,
        "Admission No": 4239,
        "Name of student": "KHALFAN ABDUL KALAM",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "ASSIGN TO AJVAD",
        "First Session Date": "2023-03-13",
        "June Expected Sessions": 30,
        "June Sessions": 7,
        "June Fee Paid": 0,
        "June Income Forecast": 10000,
        "June Income Forecasted Week": "Week 2",
        "July Expected Sessions": 30,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 128,
        "Total Required Sessions": 240,
        "Total Sessions Completed": 98,
        "Sessions Completion %": "53.33 %",
        "Fee Collected %": "27.92 %",
        "SRO Sales Amount": 72000,
        "Expected Total Income": 38400,
        "Total Fee Paid": 20104,
        Balance: 51896,
        "July Income Forecast": 10000,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 18,
        "Admission No": 3934,
        "Name of student": "MARITTA BOBEN",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "ASSIGHN TO AJVAD",
        "First Session Date": "2023-03-23",
        "June Expected Sessions": 24,
        "June Sessions": 5,
        "June Fee Paid": 0,
        "June Income Forecast": 15000,
        "June Income Forecasted Week": "Week 3",
        "July Expected Sessions": 24,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 80,
        "Total Required Sessions": 304,
        "Total Sessions Completed": 56,
        "Sessions Completion %": "26.32 %",
        "Fee Collected %": "18.72 %",
        "SRO Sales Amount": 85000,
        "Expected Total Income": 22400,
        "Total Fee Paid": 15912,
        Balance: 69088,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 19,
        "Admission No": 7510,
        "Name of student": "MARYAM SHAMSUDDIN",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "New Course Enroll - CRO",
        "First Session Date": "2023-05-09",
        "June Expected Sessions": 16,
        "June Sessions": 4,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 13,
        "Total Required Sessions": 140,
        "Total Sessions Completed": 13,
        "Sessions Completion %": "9.29 %",
        "Fee Collected %": "33.22 %",
        "SRO Sales Amount": 60200,
        "Expected Total Income": 5590,
        "Total Fee Paid": 20000,
        Balance: 40200,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 20,
        "Admission No": 6549,
        "Name of student": "MAX MATHEWS",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "paid amount : 29500/- need special attention & care, provide a purely english communicating teacher. balance amount they will pay 2 terms. please connect tomorrow 18/11 wtsap:+18326126220",
        "First Session Date": "2022-11-30",
        "June Expected Sessions": 0,
        "June Sessions": 1,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 42,
        "Total Required Sessions": 120,
        "Total Sessions Completed": 42,
        "Sessions Completion %": "35 %",
        "Fee Collected %": "60.51 %",
        "SRO Sales Amount": 48749,
        "Expected Total Income": 17052,
        "Total Fee Paid": 29500,
        Balance: 19249,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 21,
        "Admission No": 5377,
        "Name of student": "MINHAJ KV",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "Paid amount :15000/- Need to special care & attention Please assign a friendly good teacher Admission fee included.",
        "First Session Date": "2022-08-19",
        "June Expected Sessions": 10,
        "June Sessions": 3,
        "June Fee Paid": 0,
        "June Income Forecast": 15000,
        "June Income Forecasted Week": "Week 3",
        "July Expected Sessions": 10,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 160,
        "Total Required Sessions": 200,
        "Total Sessions Completed": 150,
        "Sessions Completion %": "80 %",
        "Fee Collected %": "57.86 %",
        "SRO Sales Amount": 60496,
        "Expected Total Income": 48320,
        "Total Fee Paid": 35000,
        Balance: 25496,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 22,
        "Admission No": 6546,
        "Name of student": "MIRAYA MATHEWS",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "paid amount : 29800/-( including admission fee ) need special attention & care. assign a good english communicating active teacher. balance amount they will pay 2 terms please connect today night.",
        "First Session Date": "2022-11-30",
        "June Expected Sessions": 16,
        "June Sessions": 3,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 68,
        "Total Required Sessions": 120,
        "Total Sessions Completed": 68,
        "Sessions Completion %": "56.67 %",
        "Fee Collected %": "101.41 %",
        "SRO Sales Amount": 48749,
        "Expected Total Income": 27608,
        "Total Fee Paid": 49435,
        Balance: -686,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 23,
        "Admission No": 1586,
        "Name of student": "MOHAMMED SINAN",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "First Session Date": "2023-04-01",
        "June Expected Sessions": 14,
        "June Sessions": 1,
        "June Fee Paid": 0,
        "June Income Forecast": 3000,
        "June Income Forecasted Week": "Week 4",
        "July Expected Sessions": 14,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 30,
        "Total Required Sessions": 200,
        "Total Sessions Completed": 16,
        "Sessions Completion %": "15 %",
        "Fee Collected %": "12.5 %",
        "SRO Sales Amount": 51999,
        "Expected Total Income": 7800,
        "Total Fee Paid": 6500,
        Balance: 45499,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 24,
        "Admission No": 8710,
        "Name of student": "MUHAMMAD MINHAJ",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "Foundation course English L2 L3 40 sessions 12749 Admission fee 500",
        "First Session Date": "2023-04-10",
        "June Expected Sessions": 0,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 3374,
        "June Income Forecasted Week": "Week 2",
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 40,
        "Total Required Sessions": 40,
        "Total Sessions Completed": 40,
        "Sessions Completion %": "100 %",
        "Fee Collected %": "73.54 %",
        "SRO Sales Amount": 12749,
        "Expected Total Income": 12720,
        "Total Fee Paid": 9375,
        Balance: 3374,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 25,
        "Admission No": 8595,
        "Name of student": "MUHAMMAD MUSTHAFA",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "English - 1,2 levels - 12749 admsn fee - 500",
        "First Session Date": "2023-04-11",
        "June Expected Sessions": 9,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 31,
        "Total Required Sessions": 40,
        "Total Sessions Completed": 31,
        "Sessions Completion %": "77.5 %",
        "Fee Collected %": "100 %",
        "SRO Sales Amount": 12749,
        "Expected Total Income": 9858,
        "Total Fee Paid": 12749,
        Balance: 0,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 26,
        "Admission No": 8622,
        "Name of student": "MUHAMMAD RAYYAN",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "Total fee: 20049( including admission fee) Paid amt : 10500 ( including admission fee) Online Classil erikan korach budhimut und pakshe assessment time il ok ayirunu. Friendly ayit ola tutor venam assign cheyan.",
        "First Session Date": "2023-04-14",
        "June Expected Sessions": 15,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 9549,
        "June Income Forecasted Week": "Week 4",
        "July Expected Sessions": 15,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 30,
        "Total Required Sessions": 60,
        "Total Sessions Completed": 15,
        "Sessions Completion %": "50 %",
        "Fee Collected %": "51.15 %",
        "SRO Sales Amount": 19549,
        "Expected Total Income": 9750,
        "Total Fee Paid": 10000,
        Balance: 9549,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 27,
        "Admission No": 8616,
        "Name of student": "MUHAMMAD SWALIH",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "Paid amount 14500(including admission fee) Need special attention and care. Wanted to clear his English basic difficulties in the vacation itself. Plz connect today They will pay balance amount in next term.",
        "First Session Date": "2023-04-13",
        "June Expected Sessions": 15,
        "June Sessions": 2,
        "June Fee Paid": 0,
        "June Income Forecast": 5549,
        "June Income Forecasted Week": "Week 3",
        "July Expected Sessions": 15,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 48,
        "Total Required Sessions": 60,
        "Total Sessions Completed": 33,
        "Sessions Completion %": "80 %",
        "Fee Collected %": "71.61 %",
        "SRO Sales Amount": 19549,
        "Expected Total Income": 15600,
        "Total Fee Paid": 14000,
        Balance: 5549,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 28,
        "Admission No": 4463,
        "Name of student": "NADHIYA ABULKALAM",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "New Course Enroll - CRO",
        "First Session Date": "2023-05-17",
        "June Expected Sessions": 15,
        "June Sessions": 4,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 13,
        "Total Required Sessions": 120,
        "Total Sessions Completed": 13,
        "Sessions Completion %": "10.83 %",
        "Fee Collected %": "26.04 %",
        "SRO Sales Amount": 38400,
        "Expected Total Income": 4160,
        "Total Fee Paid": 10000,
        Balance: 28400,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 29,
        "Admission No": 8642,
        "Name of student": "NEHAN MOHAMMED",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "HE WANTS TO READ & WTITE ENGLISH FLUENTLY..NEED TO FOCUC IN ADDITION,SUBTRACTION,MULTIPLICATION & DIVISION",
        "First Session Date": "2023-04-26",
        "June Expected Sessions": 18,
        "June Sessions": 4,
        "June Fee Paid": 0,
        "June Income Forecast": 4299,
        "June Income Forecasted Week": "Week 2",
        "July Expected Sessions": 18,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 41,
        "Total Required Sessions": 40,
        "Total Sessions Completed": 23,
        "Sessions Completion %": "102.5 %",
        "Fee Collected %": "69.93 %",
        "SRO Sales Amount": 14299,
        "Expected Total Income": 14637,
        "Total Fee Paid": 10000,
        Balance: 4299,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 30,
        "Admission No": 6661,
        "Name of student": "NEVIN MATHEW",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "paid amount : 20000/-( including admission fee ) need special attention & care. please connect through whatsapp. he needs a good active male teacher. please connect today.",
        "First Session Date": "2023-01-01",
        "June Expected Sessions": 16,
        "June Sessions": 2,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 44,
        "Total Required Sessions": 60,
        "Total Sessions Completed": 44,
        "Sessions Completion %": "73.33 %",
        "Fee Collected %": "100 %",
        "SRO Sales Amount": 19500,
        "Expected Total Income": 14300,
        "Total Fee Paid": 19500,
        Balance: 0,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 31,
        "Admission No": 6323,
        "Name of student": "NIMAI",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "NEED CLASSES FROM 4TH NOVEMBER FRIDAY, CONNECT BY TODAY TO SCHEDULE CLASS.",
        "First Session Date": "2023-04-11",
        "June Expected Sessions": 8,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 7,
        "Total Required Sessions": 80,
        "Total Sessions Completed": 7,
        "Sessions Completion %": "8.75 %",
        "Fee Collected %": "31.25 %",
        "SRO Sales Amount": 32000,
        "Expected Total Income": 2800,
        "Total Fee Paid": 10000,
        Balance: 22000,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 32,
        "Admission No": 8222,
        "Name of student": "NITHIN ROY",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "need to start today",
        "First Session Date": "2023-03-22",
        "June Expected Sessions": 14,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 20,
        "Total Required Sessions": 100,
        "Total Sessions Completed": 20,
        "Sessions Completion %": "20 %",
        "Fee Collected %": "37.5 %",
        "SRO Sales Amount": 40000,
        "Expected Total Income": 8000,
        "Total Fee Paid": 15000,
        Balance: 25000,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 33,
        "Admission No": 4823,
        "Name of student": "RAMZI ASIF",
        "Course Enrolled": "Classroom - Short Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "English Foundation class : Level 1+Level 2+ Level 3 60 session class   Total fee: 22999 discount : 15% Discounted Fee : 19499 Advance fee : 8000",
        "First Session Date": "2022-06-27",
        "June Expected Sessions": 88,
        "June Sessions": 2,
        "June Fee Paid": 0,
        "June Income Forecast": 6000,
        "June Income Forecasted Week": "Week 3",
        "July Expected Sessions": 16,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 148,
        "Total Required Sessions": 60,
        "Total Sessions Completed": 132,
        "Sessions Completion %": "246.67 %",
        "Fee Collected %": "228.22 %",
        "SRO Sales Amount": 19499,
        "Expected Total Income": 48524,
        "Total Fee Paid": 44500,
        Balance: -25001,
        "July Income Forecast": 0,
        "Bad Debt": 0,
        "Cross Sell Course": "Foundation Course",
        "Cross Sell Amount": 5000,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 34,
        "Admission No": 5501,
        "Name of student": "REECE JOEL",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "ASSIGN TO AJVAD",
        "First Session Date": "2023-04-07",
        "June Expected Sessions": 18,
        "June Sessions": 4,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": 18,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 46,
        "Total Required Sessions": 165,
        "Total Sessions Completed": 28,
        "Sessions Completion %": "27.88 %",
        "Fee Collected %": "32.76 %",
        "SRO Sales Amount": 61050,
        "Expected Total Income": 17020,
        "Total Fee Paid": 20000,
        Balance: 41050,
        "July Income Forecast": 15000,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 35,
        "Admission No": 7578,
        "Name of student": "RUFAIDA",
        "Course Enrolled": "Classroom - Short Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "New Course Enroll (Via Inactive Student Update)",
        "First Session Date": "2023-06-02",
        "June Expected Sessions": 50,
        "June Sessions": 14,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "Expected Total Sessions": 14,
        "Total Sessions Completed": 14,
        "Expected Total Income": 0,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 36,
        "Admission No": 6413,
        "Name of student": "SAANVI DIYA SHINE",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks":
            "She need a trainer who knows to communicate in English perfectly. They chose 6 pm sweden time, because she will reach at home at that time only. English foundation level 1 20 sessions  Maths foundation level 1 20 sessions  Total fee: 17802 (including admission fee) Amount paid: 17965",
        "First Session Date": "2022-11-24",
        "June Expected Sessions": 13,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 86,
        "Total Required Sessions": 40,
        "Total Sessions Completed": 86,
        "Sessions Completion %": "215 %",
        "Fee Collected %": "247.64 %",
        "SRO Sales Amount": 17465,
        "Expected Total Income": 37496,
        "Total Fee Paid": 43251,
        Balance: -25786,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 37,
        "Admission No": 2452,
        "Name of student": "SANA FATHIMA",
        "Course Enrolled": "Foundation Course",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "First Session Date": "2023-04-05",
        "June Expected Sessions": 3,
        "June Sessions": 0,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": "Add",
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 17,
        "Total Required Sessions": 20,
        "Total Sessions Completed": 17,
        "Sessions Completion %": "85 %",
        "Fee Collected %": "98.04 %",
        "SRO Sales Amount": 5600,
        "Expected Total Income": 4760,
        "Total Fee Paid": 5490,
        Balance: 110,
        "July Income Forecast": "Expected Sessions Not Added",
        "July Notes": "Expected Sessions Not Added",
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
    {
        "Sl No.": 38,
        "Admission No": 5500,
        "Name of student": "SERAH ROSE",
        "Course Enrolled": "Classroom - Long Term",
        "CRO Course Status": "Active",
        "Accountant Course Status": "Active",
        "Student Status": "Not Set",
        "SRO Remarks": "ASSIGN TO AJVAD",
        "First Session Date": "2023-03-23",
        "June Expected Sessions": 16,
        "June Sessions": 1,
        "June Fee Paid": 0,
        "June Income Forecast": 0,
        "July Expected Sessions": 16,
        "July Sessions": 0,
        "July Fee Paid": 0,
        "Expected Total Sessions": 53,
        "Total Required Sessions": 150,
        "Total Sessions Completed": 37,
        "Sessions Completion %": "35.33 %",
        "Fee Collected %": "34.19 %",
        "SRO Sales Amount": 58500,
        "Expected Total Income": 20670,
        "Total Fee Paid": 20000,
        Balance: 38500,
        "July Income Forecast": 20000,
        "Bad Debt": 0,
        "Cross Sell Course": "nill",
        "Cross Sell Amount": 0,
        "August Income Forecast": 0,
    },
];
