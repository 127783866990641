import { Box, Button, Grid, Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { SlotBookingForm } from "./SlotBookingForm";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Loader from "../../../../components/Loader/Loader";
import NoDataFound from "../../../../components/NodataIndication/NoDataFound";
import { getBookingDetailsById } from "../../../../redux/features/cabinBookingSlice";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";
import { DeleteBookings } from "./DeleteBookings";
import { TuesDaySlots } from "./TuesDaySlots";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BookingSlots = ({ data, cabinDetails, value, asset }) => {
  const dispatch = useDispatch();
  const [viewForm, setViewForm] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [bookingId, setBookingId] = useState("");
  const [tuesdaySlots, setTuesdaySlots] = useState(false);
  const { assetTimeSlotsForADay, loading, bookingDetailsById } = useSelector(
    (state) => state.cabin
  );

  useEffect(() => {
    const dayName = value.format("dddd");
    if (cabinDetails.asset_id === 21 && dayName === "Tuesday") {
      setTuesdaySlots(true);
    } else {
      setTuesdaySlots(false);
    }
  }, [cabinDetails, value]);
  const handleClickOpen = (item) => {
    if (item != "Booking Not available") {
      setBookingId(item);
      setOpen(true);
      dispatch(getBookingDetailsById(item));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const profile = JSON.parse(localStorage.getItem("cred")).profile;

  useEffect(() => {
    if (bookingDetailsById == []) {
      handleClose();
    }
  }, [bookingDetailsById]);

  useEffect(() => {
    setViewForm(false);
  }, [cabinDetails, value]);

  return (
    <div>
      {tuesdaySlots ? (
        <TuesDaySlots
          data={data}
          cabinDetails={cabinDetails}
          value={value}
          asset={asset}
          tuesdaySlots={tuesdaySlots}
        />
      ) : (
        <>
          {" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: 3,
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <Button
                sx={{ borderRadius: 4 }}
                variant={viewForm == false ? "contained" : "outlined"}
                onClick={() => setViewForm(false)}
              >
                Slots
              </Button>
              <Button
                sx={{ borderRadius: 4 }}
                variant={viewForm == true ? "contained" : "outlined"}
                onClick={() => setViewForm(true)}
              >
                Booking Form
              </Button>
            </Stack>
          </Box>
          <Box>
            {viewForm === false ? (
              <>
                {loading ? (
                  <Loader />
                ) : (
                  <Box>
                    {assetTimeSlotsForADay.length != 0 ? (
                      <Grid container spacing={2}>
                        {assetTimeSlotsForADay.map((item) => {
                          return (
                            <Grid
                              item
                              key={item.slot_id}
                              xs={12}
                              sm={6}
                              md={6}
                              lg={4}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  disabled={
                                    item.timeStatus === "Time over"
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    handleClickOpen(item.booking_id);
                                    // setBookingId(
                                    //     item.booking_id
                                    // );
                                  }}
                                  sx={{
                                    display: "flex",
                                    textAlign: "justify",
                                    width: 210,
                                    color:
                                      item.status === "not Booked"
                                        ? "#0db05d"
                                        : "#c03530",
                                    backgroundColor:
                                      item.status === "not Booked"
                                        ? "#c8facd"
                                        : "#ffe4de",
                                  }}
                                >
                                  <Icon
                                    icon="wpf:alarm-clock"
                                    width="22"
                                    height="22"
                                  />
                                  {item.fromTime}
                                  <Icon
                                    icon="typcn:arrow-up"
                                    width="22"
                                    height="22"
                                    rotate={1}
                                  />
                                  {item.toTime}
                                </Button>
                              </Box>

                              <Paper elevation={3} padding={3}></Paper>
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : (
                      <NoDataFound />
                    )}
                  </Box>
                )}
              </>
            ) : (
              <SlotBookingForm
                asset={asset}
                setViewForm={setViewForm}
                cabinDetails={cabinDetails}
              />
            )}
          </Box>
        </>
      )}

      {/* ---------------------------------Dialogue---------------------------------------- */}
      <React.Fragment>
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
          <DialogTitle>{"Booking Deatils"}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                padding: 1,
                backgroundColor: "grey.200",
                borderRadius: 2,
              }}
            >
              <Mytable
                tableRow={
                  <>
                    <Rows
                      label={"Booked User"}
                      value={bookingDetailsById?.userName}
                    />
                    <Rows
                      label={"From Time"}
                      value={bookingDetailsById?.from_time}
                    />
                    <Rows
                      label={"To Time"}
                      value={bookingDetailsById?.to_time}
                    />
                    <Rows
                      label={"Purpose"}
                      value={bookingDetailsById?.purpose}
                    />
                    <Rows
                      label={"Remarks"}
                      value={bookingDetailsById?.remarks}
                    />
                  </>
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {profile.userId === bookingDetailsById?.created_by ? (
              <DeleteBookings
                bookingDetailsById={bookingDetailsById}
                bookingId={bookingId}
                setOpen={setOpen}
              />
            ) : null}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
};
