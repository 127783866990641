
export const FoodTableHead = [
  {
    field: "serialNumber",
    headerName: "SlNo",
    width: 60,
  },
  {
    field: "name",
    headerName: "Name",
    width: 250,
  },
  {
    field: "designation",
    headerName: "Designation / Role",
    width: 300,
  },
  {
    field: "department",
    headerName: "Department",
    width: 250,
  },
  {
    field: "office_name",
    headerName: "Office",
    width: 160,
  },
  {
    field: "tbl_food_slot",
    headerName: "Slot",
    width: 180,
  },
];
