import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSlotesById } from "../../../redux/features/cabinBookingSlice";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Stack } from "@mui/material";
import { Icon } from "@iconify/react";
import { AddAndEditSlots } from "./AddAndEditSlots";
import { DeleteTimeSlot } from "./DeleteTimeSlot";
export const SlotSettings = () => {
    const { slotesById } = useSelector((state) => state.cabin);
    const { id } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSlotesById(id));
    }, []);

    return (
        <div>
            <Wrapper
                title="Slot Settings"
                actions={<AddAndEditSlots cabinId={id} />}
                Content={
                    <>
                        <Box>
                            <Grid
                                container
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {slotesById.map((item, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        md={4}
                                        key={item.slot_id}
                                    >
                                        <Box>
                                            <Stack
                                                direction={"row"}
                                                spacing={1}
                                            >
                                                <Button
                                                    sx={{ width: 230 }}
                                                    variant="contained"
                                                >
                                                    <Icon
                                                        icon="wpf:alarm-clock"
                                                        width="22"
                                                        height="22"
                                                    />
                                                    {item.fromTime}
                                                    <Icon
                                                        icon="typcn:arrow-up"
                                                        width="22"
                                                        height="22"
                                                        rotate={1}
                                                    />
                                                    {item.toTime}
                                                </Button>
                                                <DeleteTimeSlot data={item} />
                                                <AddAndEditSlots data={item} />
                                            </Stack>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </>
                }
            />
        </div>
    );
};
