import { Icon } from "@iconify/react";
import { Chip } from "@mui/material";
import { EditExpensePopupView } from "./RenderCellMain";
import { DeleteExpensePopup } from "./DeleteExpensePopup";

export const EXPENSE_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "expenseYear",
        headerName: "Year",
        width: 90,
    },
    {
        field: "expenseMonth",
        headerName: "Month",
        width: 90,
    },
    {
        field: "expenseName",
        headerName: "ExpenseType",
        width: 90,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },

    {
        field: "expenseAmount",
        headerName: "Amount",
        width: 90,
    },
    {
        field: "expenseRemarks",
        headerName: "Remarks",
        width: 250,
    },
    {
        field: "timestamp",
        headerName: "timestamp",
        width: 180,
    },
    {
        field: "accountHolder",
        headerName: "A/C Holder",
        width: 250,
    },
    {
        field: "ifsc",
        headerName: "IFSC",
        width: 150,
    },
    {
        field: "accountNumber",
        headerName: "A/C Number",
        width: 180,
    },
    {
        field: "transactionDate",
        headerName: "Transaction Date",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "paymentMode",
        headerName: "Payment Mode",
        width: 150,
    },
    {
        field: "expenseApprovalStatus",
        headerName: "Approval Status",
        width: 160,
        renderCell: (params) => {
            return (
                <Chip
                    label={params.row.expenseApprovalStatus}
                    size="small"
                    color={
                        params.row.expenseApprovalStatus === "Approved"
                            ? "success"
                            : "error"
                    }
                    icon={
                        params.row.expenseApprovalStatus === "Approved" ? (
                            <Icon icon="charm:tick" />
                        ) : (
                            <Icon icon="bitcoin-icons:cross-filled" />
                        )
                    }
                    variant="outlined"
                />
            );
        },
    },
    {
        field: "action",
        headerName: "Action",

        width: 160,
        renderCell: (params) => {
            return (
                <>
                    <EditExpensePopupView {...params.row} />
                    <DeleteExpensePopup />
                </>
            );
        },
    },
];

export const TP_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "expenseName",
        headerName: "expense Tpe",
        width: 180,
    },
    {
        field: "transactionDate",
        headerName: "Transaction Date",
        width: 120,
    },
    {
        field: "expenseAmount",
        headerName: "Amount",
        width: 90,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "subjectName",
        headerName: "Subject",
        width: 150,
    },
    {
        field: "tutorName",
        headerName: "Tutor Name",
        width: 250,
    },
    {
        field: "tutorPayment",
        headerName: "tutor Payment",
        width: 90,
    },
    {
        field: "timestamp",
        headerName: "Time Stamp",
        width: 200,
    },
    {
        field: "actionButton",
        headerName: "Action ",
        width: 90,
    },
];
