import React from "react";
import { Typography, Box, Paper, Button, Stack } from "@mui/material";
import { Icon } from "@iconify/react";

export default function InterviewInitialCard2({
  title,
  message,
  disabled,
  upadteaction,
  updateform,
}) {
  return (
    <div>
      {" "}
      <Paper
        elevation={3}
        sx={{
          backgroundImage: `url(${"https://media.istockphoto.com/id/1139397586/vector/abstract-background.jpg?s=612x612&w=0&k=20&c=_5e5aIftqcC1toShclOkhV0S5RoXE42ZEa1BQ9Ri024="})`,
          "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
          transition: "transform 0.15s ease-in-out",
          height: 310,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack direction={"column"}>
            <Box
              padding={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 2,
              }}
            >
              <Typography variant="title" sx={{ fontWeight: "bolder" }}>
                {title}
              </Typography>
            </Box>
            <Box>
              <Paper elevation={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 2,
                  }}
                >
                  <Icon icon="ic:round-warning-amber" width="60" height="60" />
                </Box>
                <Typography variant="h6" padding={3}>
                  {message}
                </Typography>
              </Paper>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 2,
              }}
            >
              <Button
                disabled={disabled}
                sx={{ backgroundColor: "#f0dfa4", color: "#b78103" }}
                onClick={upadteaction}
              >
                <Icon icon="radix-icons:update" width="20" height="20" />
                Update
              </Button>
            </Box>
            {updateform}
          </Stack>
        </Box>
      </Paper>
    </div>
  );
}
