import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Box, Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import cabin from "../../../src/img/cabin.jpg";
import Barcelona from "../../../src/components/CabinCardImages/Barcelona.jpg";
import Helsinki from "../../../src/components/CabinCardImages/Helsinki.jpg";
import London from "../../../src/components/CabinCardImages/London.jpg";
import Amsterdam from "../../../src/components/CabinCardImages/Amsterdam.jpg";
import Beijing from "../../../src/components/CabinCardImages/Beijing.jpg";
import Dubai from "../../../src/components/CabinCardImages/dubai.jpg";
import ThoughtForge from "../../../src/components/CabinCardImages/ThoughtForge.jpg";
import homeDen from "../../../src/components/CabinCardImages/homeDen.jpg";
import eatscape from "../../../src/components/CabinCardImages/eatscape.jpg";
import TV from "../../../src/components/CabinCardImages/TV.jpg";
import { SlideLoader } from "../Loader/SlideLoader";
import { motion, AnimatePresence } from "framer-motion";

export const Cards = ({ data, actions, isActive, cabinImg }) => {
  const [filterImg, setFilterImg] = useState("");
  const cabinImgData = [
    {
      id: 1,
      img: Barcelona,
      cabinName: "Barcelona",
    },
    {
      id: 2,
      img: Helsinki,
      cabinName: "Helsinki",
    },
    {
      id: 3,
      img: London,
      cabinName: "London",
    },
    {
      id: 4,
      img: Amsterdam,
      cabinName: "Amsterdam",
    },
    {
      id: 5,
      img: Beijing,
      cabinName: "Beijing",
    },
    { id: 6, img: Dubai, cabinName: "Dubai" },
    { id: 7, img: ThoughtForge, cabinName: "Thought Forge" },
    { id: 8, img: homeDen, cabinName: "HomeDen" },
    { id: 9, img: eatscape, cabinName: "Eatscape" },
    { id: 10, img: TV, cabinName: "TV" },
  ];

  useEffect(() => {
    const idToFind = data.asset_name;

    const foundData = cabinImgData.find((item) => item.cabinName === idToFind);

    // Check if the data is found
    if (foundData) {
      // Assign the found data to a new variable
      const newDataVariable = foundData;
      setFilterImg(newDataVariable);

      // Now you can use newDataVariable for further operations
    } else {
      setFilterImg({
        id: 0,
        img: cabin,
      });
    }
  }, [data]);

  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cardImgLoader, setCardImgLoader] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Set the delay in milliseconds (2 seconds in this case)

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCardImgLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [isLoading]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {isLoading ? (
          <>
            <Box>
              <Stack direction={"column"} spacing={2}>
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: 4 }}
                  width={345}
                  height={150}
                />
                <Skeleton />
                <Skeleton width="60%" />
              </Stack>
            </Box>
          </>
        ) : (
          <Card
            sx={{
              // maxWidth: 345,
              borderRadius: 4,
              // minWidth: 345,
              // minHeight: 400,
              backgroundColor: isActive == true ? "#eff4f9" : "white",
            }}
          >
            {cardImgLoader === true ? (
              <Box
                sx={{
                  height: 140,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SlideLoader />
              </Box>
            ) : (
              <AnimatePresence>
                <motion.div
                  initial={{ scale: 0 }} // Initial scale
                  animate={{ scale: 1 }} // Target scale on enter
                  exit={{ scale: 0 }} // Target scale on exit
                  transition={{ duration: 0.4 }} // Animation duration
                >
                  <CardMedia
                    sx={{
                      height: 140,
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    image={filterImg.img}
                  >
                    <Stack direction={"column"} spacing={2}>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          color: "white",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          padding: "8px",
                          textAlign: "center",
                          borderRadius: 2,
                        }}
                      >
                        {data.asset_name}
                      </Typography>
                      <Box
                        sx={{
                          color: "white",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          padding: "1px",
                          textAlign: "center",
                          borderRadius: 2,
                        }}
                      >
                        {data.status}
                      </Box>
                    </Stack>
                  </CardMedia>
                </motion.div>
              </AnimatePresence>
            )}

            <Box>{actions}</Box>
          </Card>
        )}
      </Box>
    </>
  );
};
