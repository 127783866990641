import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { FormikProvider, Form, useFormik } from "formik";
import { Divider, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";
import useResponsive from "../../../../Hooks/useResponsive";
import {
  getCvStatusPreData,
  getSingleJobApplication,
  updateSecondInterviewStatus,
} from "../../../../redux/features/jobApplicationSlice";
import { updateTestStatus } from "../../../../redux/features/jobApplicationSlice";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { timeLine } from "../../../../redux/features/jobApplicationSlice";
import { SaveButton } from "../../../../components/mui/Buttons/Buttons";
import {
  CancelButton,
  SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";
import * as Yup from "yup";

import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const labels = {
  1: "Useless",

  2: "Poor",

  3: "Ok",

  4: "Good",

  5: "Excellent",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Interview2Form = ({
  secondInterview,
  setSecondInterview,
  params,
  singleData,
}) => {
  const [hover, setHover] = React.useState(-1);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const preData = useSelector((state) => state.jobApplication.preData);
  const loading = useSelector((state) => state.jobApplication.loading);
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const handleCloseForm = () => {
    setSecondInterview(false);
  };

  const InterviewSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    remarks: Yup.string().required("Remark is required"),
    rating: Yup.string().required("Rating is required"),
  });

  const formik = useFormik({
    initialValues: {
      status: singleData.interview2StatusId
        ? singleData.interview2StatusId || ""
        : "",
      remarks: singleData.interview2Remarks
        ? singleData.interview2Remarks || ""
        : "",
      rating: singleData.interview2Rating
        ? singleData.interview2Rating || ""
        : null,
    },
    validationSchema: InterviewSchema,
    onSubmit: (values) => {
      const data = {
        id: params.id,
        status: values.status,
        remarks: values.remarks,
        rating: values.rating,
      };
      dispatch(updateSecondInterviewStatus(data)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
          dispatch(timeLine(params.id));
          dispatch(getSingleJobApplication(params.id));
          setSecondInterview(false);
        }
      });
    },
  });

  return (
    <div>
      <Dialog
        open={secondInterview}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseForm}
        aria-describedby="alert-dialog-slide-description"
      >
        <Paper sx={{ backgroundColor: "grey.200", display: "flex" }}>
          <DialogTitle>{"Upadate Status.."}</DialogTitle>
        </Paper>
        <Divider />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box sx={{ width: !smUp ? "100%" : 500, padding: !smUp ? 0 : 3 }}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="filled"
                      name="status"
                      label="Status"
                      value={formik.values.status}
                      onChange={(e) => {
                        formik.setFieldValue("status", e.target.value);
                      }}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                    >
                      {preData.data?.map((item) => {
                        return (
                          <MenuItem value={item.id} key={item.id}>
                            {item.status}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{formik.errors.status}</FormHelperText>
                  </FormControl>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    variant="filled"
                    fullWidth
                    label="Remark"
                    rows={3}
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    multiline
                    placeholder="Enter your remark..."
                    error={
                      formik.touched.remarks && Boolean(formik.errors.remarks)
                    }
                    helperText={formik.errors.remarks}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "grey.200",
                    padding: 2,
                  }}
                >
                  <Typography variant="subtitle2">Rating</Typography>
                  <Rating
                    required
                    size="large"
                    name="rating"
                    value={formik.values.rating}
                    getLabelText={getLabelText}
                    onChange={(e) => {
                      formik.setFieldValue("rating", e.target.value);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                  {formik.values.rating !== null && (
                    <Box sx={{ ml: 2 }}>
                      {labels[hover !== -1 ? hover : formik.values.rating]}
                    </Box>
                  )}
                </Box>
              </Box>

              <Divider sx={{ borderBottomWidth: 3 }} />
            </DialogContent>

            <DialogActions>
              <CancelButton action={handleCloseForm}>Cancel</CancelButton>
              <SaveButton type="submit">
                {loading ? "Savings.." : "Save"}
              </SaveButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
};

export { Interview2Form };
