import { Button, dividerClasses } from "@mui/material";
import EditDateAction from "./EditDateAction";
import PrefferedSchedulesAction from "./PrefferedSchedulesAction";

export const COURSE_TABLE_HEAD = [
    {
        field: "serialNumber",
        flex: 0,
        minWidth: 40,
        headerName: "Serial No",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "course",
        headerName: "course",
        flex: 5,
        minWidth: 90,
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: ({ row }) => (
            <div>{`${row.course}(${row.courseDetailed})${row.subject}-class ${row.class} ${row.syllabus} ${row.medium}`}</div>
        ),
    },
    {
        field: "startDate",
        headerName: "Start Date",
        flex: 1,
        minWidth: 90,
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => <EditDateAction params={params} />,
    },
    {
        field: "Action",
        headerName: "Action",
        flex: 1,
        minWidth: 90,
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => <PrefferedSchedulesAction params={params} />,
    },
];
