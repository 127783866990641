import { Box } from "@mui/material";
import React, { useState } from "react";
import useResponsive from "../../Hooks/useResponsive";
import {
    PieChart,
    Pie,
    Sector,
    Cell,
    ResponsiveContainer,
    Label,
} from "recharts";
const PieChartComponent = ({ data, sx }) => {
    const smUp = useResponsive("up", "sm");

    const COLORS = ["#00C49F", "#FFBB28", "#FF8042", "#0088FE"];
    const [activeIndex, setActiveIndex] = useState(null);

    const handleMouseEnter = (data, index) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    data = [
        {
            name: "Working",
            value: 100,
        },
        {
            name: "Resigned",
            value: 30,
        },
        {
            name: "Terminated",
            value: 2,
        },
        {
            name: "Long Leave",
            value: 13,
        },
    ];
    return (
        <>
            <h2 style={{ marginLeft: `${!smUp ? "90" : "110"}` }}>
                Employment Status
            </h2>

            <Box
                sx={{
                    ...sx,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: `${!smUp ? "column" : "row"}`,
                    gap: "3px",
                    padding: 0,
                    height: "550px",
                }}
            >
                <ResponsiveContainer
                    width={!smUp ? "100%" : "70%"}
                    height={!smUp ? "70%" : "100%"}
                >
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={100}
                            outerRadius={160}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                            {data.map((entry, index) => (
                                <Label
                                    key={`label-${index}`}
                                    position="center"
                                    value={
                                        activeIndex === index
                                            ? `${entry.name}-${entry.value}`
                                            : ""
                                    }
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
                <ul
                    style={{
                        width: 100,
                        listStyle: "none",
                    }}
                >
                    {data.map((element, index) => (
                        <li key={index}>
                            <div
                                style={{
                                    height: "10px",
                                    width: "10px",
                                    background: `${
                                        COLORS[index % COLORS.length]
                                    }`,
                                    display: "inline-block",
                                }}
                            ></div>{" "}
                            <h6
                                style={{
                                    color: `${COLORS[index % COLORS.length]}`,
                                    display: "inline-block",
                                    fontSize: "14px",
                                }}
                            >
                                {element.name}
                            </h6>
                        </li>
                    ))}
                </ul>
            </Box>
        </>
    );
};

export default PieChartComponent;
