import React from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import Page from "../../../components/Page";
import { motion } from "framer-motion";
// components

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));
const keyframesExample = {
    hidden: { opacity: 0, x: -100 },
    halfway: { opacity: 0.5, x: 50 },
    visible: { opacity: 1, x: 0 },
};
const EmptyDashboard = ({ message }) => {
    return (
        <motion.div
            initial="hidden"
            animate="visible"
            variants={keyframesExample}
        >
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Page title="Dashboard">
                    <Container maxWidth="xl">
                        <Typography variant="h4" sx={{ mb: 5 }}>
                            Hi, Welcome back
                        </Typography>
                    </Container>
                </Page>
            </Box>
        </motion.div>
    );
};

export default EmptyDashboard;
