import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import CourseDetailsAction from "./CourseDetailsAction";

export const STUDENT_VIEW_TABLE_HEAD = [
    {
        field: "serialNumber",
        flex: 0,
        minWidth: 40,
        headerName: "Serial No",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "admissionNumber",
        flex: 1,
        minWidth: 90,
        headerName: "Admission No",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "name",
        flex: 1,
        minWidth: 90,
        headerName: "Name",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "phoneNumber",
        flex: 1,
        minWidth: 90,
        headerName: "Phone Number",
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
            <div>
                +{params.row.phoneCode} {params.row.phoneNum}
            </div>
        ),
    },
    {
        field: "class",
        flex: 1,
        minWidth: 90,
        headerName: "Class",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "action",
        flex: 1,
        minWidth: 90,
        headerName: "Action",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => <CourseDetailsAction params={params} />,
    },
];
