import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

export const getAllRoles = createAsyncThunk("roles/getAllRoles", async () => {
    const res = await apiRequest({
        method: "get",
        url: `roles`,
    });
    return formatDataForDataTable(res.data.userTypes);
});

export const deleteRole = createAsyncThunk("roles/deleteRole", async (id) => {
    const res = await apiRequest({
        method: "delete",
        url: `roles/${id}`,
    });
    return res;
});

export const getRoleById = createAsyncThunk("roles/getRoleById", async (id) => {
    const res = await apiRequest({
        method: "get",
        url: `roles/${id}`,
    });
    return res;
});
export const editRole = createAsyncThunk("roles/editRole", async (data) => {
    const res = await apiRequest({
        method: "put",
        url: `roles/${data.id}`,
        data: data,
    });
    return res;
});

const rolesSlice = createSlice({
    name: "roles",
    initialState: {
        data: [],
        singleRole: {},
        loading: false,
        error: "",
    },

    extraReducers: (builder) => {
        builder.addCase(getAllRoles.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllRoles.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getAllRoles.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------DeleteRole---------------------------
        builder.addCase(deleteRole.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteRole.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------getRoleById--------------------------------
        builder.addCase(getRoleById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getRoleById.fulfilled, (state, action) => {
            state.loading = false;
            state.singleRole = action.payload.data[0];
        });
        builder.addCase(getRoleById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -------------------edit-role------------------------------
        builder.addCase(editRole.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editRole.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(editRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});

export default rolesSlice.reducer;
