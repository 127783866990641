import { Button, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPermissions,
  deletePermission,
  getPermissions,
} from "../../../redux/features/privilegesSlice";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { DeleteButton, CancelButton } from "../../mui/Buttons/Buttons";

const DeleteAction = (params) => {
  const { enqueueSnackbar } = useSnackbar();
  const loading = useSelector((state) => state.privileges.loading);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAgree = (row) => {
    dispatch(deletePermission(row.relationId)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        dispatch(clearPermissions());
        dispatch(getPermissions());
        setOpen(false);
        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
      }
    });
  };

  return (
    <>
      <DeleteButton action={handleClickOpen}>Delete</DeleteButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Delete Permission"}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete this Permission?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton action={handleClose}>Cancel</CancelButton>

          <DeleteButton action={() => handleAgree(params.row)}>
            {loading ? "Deleting..." : "Delete"}
          </DeleteButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAction;
