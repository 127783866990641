import * as React from "react";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Iconify from "../Iconify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/features/modalSlice";
import {
    getNotificationByID,
    approveNotification,
    viewNotification,
} from "../../redux/features/notificationSlice";
import { Link } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { Button, Divider, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationUtilsMenu(params) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.notification.loading);
    const { enqueueSnackbar } = useSnackbar();
    const { permissions } = useSelector((state) => state.role);

    function handleKebabClick(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }
    function handleDeleteOpen(e) {
        handleClose();
        dispatch(
            openModal({
                variant: "danger",
                type: "notification",
                title: "Delete Notification",
                content: "Are you sure you want to delete this notification",
                buttonText: "Delete",
                id: params.id,
            })
        );
    }

    const [openapprove, setOpenApprove] = React.useState(false);

    const handleClickOpen = () => {
        setAnchorEl(null);
        setOpenApprove(true);
    };

    const handleCloseApprove = () => {
        setOpenApprove(false);
    };

    const handleApprove = () => {
        dispatch(approveNotification(params.id)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                enqueueSnackbar(res.payload.message, { variant: "success" });
                dispatch(viewNotification("CUSTOM_SINGLE"));
                setOpenApprove(false);
            }
        });
    };

    // ...............................POP MENU.............................................//
    function PopMenu() {
        return (
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {/* ......................EditButton........................... */}
                {permissions.includes("edit") && (
                    <MenuItem>
                        <IconButton
                            component={Link}
                            to={`/notifications/edit/${params.id}`}
                            aria-label="edit"
                            size="small"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                        >
                            <Iconify
                                sx={{ marginRight: 1 }}
                                color="primary.main"
                                icon="eva:edit-fill"
                            />
                            Edit
                        </IconButton>
                    </MenuItem>
                )}
                {/* .....................DeleteButton........................... */}
                {permissions.includes("delete") && (
                    <MenuItem>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleDeleteOpen}
                        >
                            <Iconify
                                sx={{ marginRight: 1 }}
                                color="primary.main"
                                icon="eva:trash-2-outline"
                            />
                            Delete
                        </IconButton>
                    </MenuItem>
                )}
                {params.row.crm_approved === !true &&
                permissions.includes("approve") ? (
                    <MenuItem>
                        <IconButton
                            aria-label="edit"
                            size="small"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickOpen}
                        >
                            <Iconify
                                sx={{ marginRight: 1 }}
                                color="primary.main"
                                icon="mdi:approve"
                            />
                            Approve
                        </IconButton>
                    </MenuItem>
                ) : null}
            </Menu>
        );
    }

    return (
        <div>
            <IconButton
                aria-label="options"
                size="small"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleKebabClick}
            >
                <Iconify icon="eva:more-vertical-outline" />
            </IconButton>
            <PopMenu />
            {/* ------------------------------------------------------------------------------ */}

            <Dialog
                open={openapprove}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseApprove}
                aria-describedby="alert-dialog-slide-description"
            >
                <Typography variant="h5" sx={{ padding: 2 }}>
                    Approve Notification
                </Typography>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to approve this notification?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ backgroundColor: "#eff4f9", color: "#3476b5" }}
                        onClick={handleCloseApprove}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{ backgroundColor: "#c8facd", color: "#0eb15e" }}
                        onClick={handleApprove}
                    >
                        <Icon icon="mdi:approve" width="25" height="25" />
                        {loading ? "Approving..." : "Approve"}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
