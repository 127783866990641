export const CLASS_RESTART_DATES = [
    {
        field: "serialNumber",
        headerName: "id",
        width: 50,
    },
    {
        field: "adminNo",
        headerName: "Admission No",
        width: 100,
    },
    {
        field: "studentName",
        headerName: "Name",
        width: 250,
    },
    {
        field: "studentMobNo",
        headerName: "mobile",
        width: 150,
    },
    {
        field: "className",
        headerName: "class",
        width: 150,
    },
    {
        field: "courseName",
        headerName: "Course",
        width: 200,
    },
    {
        field: "courseEnrollmentDate",
        headerName: "Course Enrollment Date",
        width: 180,
    },
    {
        field: "totalSessions",
        headerName: "Total Sessions",
        width: 100,
    },

    {
        field: "lastDate",
        headerName: "Last Session Date",
        width: 120,
    },
    {
        field: "courseInactiveDate",
        headerName: "Inactive Date",
        width: 150,
    },
    {
        field: "courseRestartDate",
        headerName: "Course Restart Date",
        width: 120,
    },
    // {
    //     field: "profile",
    //     headerName: "profile",
    //     width: 150,
    // },
];
