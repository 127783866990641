import React from "react";
import { projectClassListingStatus } from "../../redux/features/mastersSlice";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
export const ProjectClassListingStatus = ({ params }) => {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.isListed}
                api={projectClassListingStatus(params.classId)}
            />
        </div>
    );
};
