import React, { useState } from "react";
import GlobalDialog from "../../../../../components/mui/Dialogue/GlobalDialog";
import { CancelButton, UpdateButton } from "../../../../mui/Buttons/Buttons";
import { useFormik, Form, FormikProvider } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { UpdateExpenses } from "../../../../../redux/features/ogdSlice";
import { getExpenses } from "../../../../../redux/features/croSlice";
import { savePaginationData } from "../../../../../redux/features/globalDatagridSlice";

export const UpdateExpense = ({ params, paginationData, paramsData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.ogd);
  const [open, setOpen] = useState(false);

  const Schema = Yup.object().shape({
    amount: Yup.string().required("amount is required"),
    remarks: Yup.string().required("remarks is required"),
  });

  const formik = useFormik({
    initialValues: {
      id: params.expenseId || "",
      studentName: params.studentName || "",
      expenseName: params.expenseName || "",
      expenseTypeId: 2,
      courseEnrollmentId: "",
      year: params.expenseYear || "",
      month: params.expenseMonth || "",
      amount: params.expenseAmount || "",
      remarks: params.expenseRemarks || "",
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      dispatch(UpdateExpenses(values)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          dispatch(
            savePaginationData({ ...paginationData, openAnchor: false })
          );
          const data = {
            page: paginationData?.pageNum,
            limit: paginationData?.gridSize,
            search: paginationData?.search,
            expenseTypeId: values.expenseTypeId,
            year: values.year,
            month: values.month,
          };
          dispatch(getExpenses(data));

          setOpen(false);
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    },
  });
  return (
    <div>
      <GlobalDialog
        title="Update Expense"
        open={open}
        setOpen={setOpen}
        fullWidth
        maxWidth={"sm"}
        closeButton={true}
        popupButton={<UpdateButton size="small" action={() => setOpen(true)} />}
        content={
          <>
            <Box>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <Stack direction={"column"} spacing={2}>
                    <TextField
                      disabled
                      fullWidth
                      label="Type"
                      value={formik.values.expenseName}
                    />
                    <TextField
                      disabled
                      fullWidth
                      label="Student"
                      value={formik.values.studentName}
                    />
                    <TextField
                      disabled
                      fullWidth
                      label="Year"
                      value={formik.values.year}
                    />
                    <TextField
                      disabled
                      fullWidth
                      label="Month"
                      value={formik.values.month}
                    />
                    <TextField
                      name="amount"
                      fullWidth
                      onChange={formik.handleChange}
                      label="Amount"
                      value={formik.values.amount}
                      error={
                        formik.touched.amount && Boolean(formik.errors.amount)
                      }
                      helperText={formik.errors.amount}
                    />
                    <TextField
                      name="remarks"
                      onChange={formik.handleChange}
                      fullWidth
                      multiline
                      rows={3}
                      label="Remarks"
                      value={formik.values.remarks}
                      error={
                        formik.touched.remarks && Boolean(formik.errors.remarks)
                      }
                      helperText={formik.errors.remarks}
                    />
                  </Stack>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      <CancelButton size="small" action={() => setOpen(false)}>
                        Cancel
                      </CancelButton>
                      <UpdateButton size="small" type="submit">
                        {loading ? "Please wait..." : "Update"}
                      </UpdateButton>
                    </Stack>
                  </Box>
                </Form>
              </FormikProvider>
            </Box>
          </>
        }
      />
    </div>
  );
};
