import { Box, Grid, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../../Wrapper/GlobalWrapper";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import { CancelButton, UpdateButton } from "../../../../mui/Buttons/Buttons";
import { BeforeDemoCheckList } from "./BeforeDemoCheckList";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { getCroLeadStatus } from "../../../../../redux/features/croSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DemoSessionDate from "./DemoSessionDate";

const LeadUpdateForm = ({ data, setOpen }) => {
    const dispatch = useDispatch();
    const { croLeadStatus } = useSelector((state) => state.cro);

    const [Status, setStatus] = useState("");
    const [Remark, setRemark] = useState("");

    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    useEffect(() => {
        dispatch(getCroLeadStatus());
    }, []);

    return (
        <div>
            <Box padding={2}>
                <GlobalWrapper>
                    <Box>
                        {/* --------------------------------------------------------- */}
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Name" />
                            <TextField fullWidth label="Contact Person" />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Relationship" />
                            <TextField fullWidth label="Email ID" />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Country Code" />
                            <TextField fullWidth label="Contact No" />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Place" />
                            <TextField
                                fullWidth
                                label="Panchayat / Muncipality / Corporation"
                            />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="District" />
                            <TextField fullWidth label="Lead Came On" />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <GlobalSelectField
                                options={{
                                    label: "Class",
                                    name: "class",
                                    data: [],
                                    keys: {
                                        id: "id",
                                        value: "feeType",
                                    },
                                }}
                            />
                            <GlobalSelectField
                                options={{
                                    label: "Syllabus",
                                    name: "syllabus",
                                    data: [],
                                    keys: {
                                        id: "id",
                                        value: "feeType",
                                    },
                                }}
                            />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Course" />
                            <TextField fullWidth label="Levels Opted" />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Session Count " />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                label="Student Details (In Brief)"
                            />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                label="Subjects & Session Count per Subject"
                            />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Fee per Session" />
                            <TextField fullWidth label="Session per Week" />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Time Prefered" />
                            <TextField fullWidth label="Trainer Prefered" />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField fullWidth label="Total Fees" />
                            <TextField fullWidth label="Fee Status" />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                label="SRO Remarks"
                            />
                        </Stack>
                        <Stack direction={"row"} spacing={2} pt={2}>
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                label="SRO Assessment Remarks"
                            />
                        </Stack>
                        <Stack direction={"column"} spacing={2} pt={2}>
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                label="CRM Remarks "
                            />
                            {/* <GlobalSelectField
                                options={{
                                    label: "Admission Status",
                                    name: "syllabus",
                                    data: croLeadStatus,
                                    keys: {
                                        id: "cro_lead_status_id",
                                        value: "cro_lead_status_name",
                                    },
                                }}
                            /> */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={Status}
                                    label="Status"
                                    onChange={handleChange}
                                >
                                    {croLeadStatus.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.cro_lead_status_id}
                                                value={item.cro_lead_status_id}
                                            >
                                                {item.cro_lead_status_name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        fullWidth
                                        sx={{ width: "100%" }}
                                        label="Add Demo Session Date"
                                    />
                                </DemoContainer>
                            </LocalizationProvider> */}
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                label="CRO Remarks"
                                value={Remark}
                                onChange={(e) => setRemark(e.target.value)}
                            />
                        </Stack>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <CancelButton size="small">Cancel</CancelButton>
                            {Status === 8 ? (
                                <DemoSessionDate
                                    Remark={Remark}
                                    Status={Status}
                                    id={data.croAssignmentId}
                                    openStatus={setOpen}
                                />
                            ) : (
                                <BeforeDemoCheckList data={data} />
                            )}
                        </Box>
                    </Box>
                </GlobalWrapper>
            </Box>
        </div>
    );
};

export default LeadUpdateForm;
