import { Button } from "@mui/material";
import React, { useState } from "react";
import PopUps from "./PopUps";
import EvaluationsSessionsLog from "./EvaluationsSessionsLog";

const SessionsLogPopUp = ({ data }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const componetProps = {
        row: data,
    };

    return (
        <div>
            <Button variant="contained" onClick={handleClick}>
                View
            </Button>

            <PopUps
                open={open}
                setOpen={setOpen}
                component={EvaluationsSessionsLog}
                componentProps={componetProps}
            />
        </div>
    );
};

export default SessionsLogPopUp;
