import { DeleteProjectCategory } from "./DeleteProjectCategory";
import { EditprojectCategory } from "./EditprojectCategory";
import { ProjectCategoryListingStatus } from "./ProjectCategoryListingStatus";
import { ProjectCategoryStatus } from "./ProjectCategoryStatus";

export const PROJECT_CATEGORIES_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "projectName",
        headerName: "Project Name",
        width: 150,
    },
    {
        field: "categoryName",
        headerName: "Category Name",
        width: 200,
    },
    {
        field: "categoryDescription",
        headerName: "Category Description",
        width: 300,
    },
    {
        field: "isActive",
        headerName: "isActive",
        width: 90,
        renderCell: (params) => <ProjectCategoryStatus params={params.row} />,
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 90,
        renderCell: (params) => (
            <ProjectCategoryListingStatus params={params.row} />
        ),
    },
    {
        field: "edit",
        headerName: "Edit",
        width: 90,
        renderCell: (params) => <EditprojectCategory params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 90,
        renderCell: (params) => (
            <DeleteProjectCategory params={params.row.categoryId} />
        ),
    },
];
