import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box } from "@mui/material";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { CancelButton } from "../../../../components/mui/Buttons/Buttons";
import { AddMou } from "./AddMou";

const data = [
    {
        id: 1,
        trainerId: "INT2112TR00033",
        name: "DR.AMRUTHA KK",
        mobile: "8086455308",
        dateOfAcceptance: "10/10/2023",
        amount: 1000,
        transactionDate: "2023-05-19",
        paymentMode: "upi",
        screenShot: "",
        remark: "paid",
        collectedBy: "SHIBILA SALIN",
        addedOn: "2023-05-19 14:57:49",
        verification: "Verified",
        refund: "",
        actions: "",
    },
];

const columns = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },
    {
        field: "trainerId",
        headerName: "trainerId",
        width: 150,
    },
    {
        field: "name",
        headerName: "name",
        width: 150,
    },

    {
        field: "mobile",
        headerName: "mobile",
        width: 150,
    },

    {
        field: "dateOfAcceptance",
        headerName: "dateOfAcceptance",
        width: 150,
    },
    {
        field: "amount",
        headerName: "amount",
        width: 250,
    },
    {
        field: "transactionDate",
        headerName: "transactionDate",
        width: 250,
    },
    {
        field: "paymentMode",
        headerName: "paymentMode",
        width: 250,
    },
    {
        field: "screenShot",
        headerName: "screenShot",
        width: 250,
    },
    {
        field: "remark",
        headerName: "remark",
        width: 250,
    },
    {
        field: "collectedBy",
        headerName: "collectedBy",
        width: 250,
    },
    {
        field: "addedOn",
        headerName: "addedOn",
        width: 250,
    },
    {
        field: "verification",
        headerName: "verification",
        width: 250,
    },
    {
        field: "refund",
        headerName: "refund",
        width: 250,
    },

    {
        field: "actions",
        headerName: "actions",
        width: 150,
    },
];

const Head = [
    {
        field: "name",
        headerName: "Trainer Name",
        width: 250,
    },
];

export default function TrainerMoUDetails() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Trainer MoU Details"
                Content={
                    <>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <AddMou />
                        </Box>
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.trainerId}
                                label="trainerId"
                                name={selectedRows[0]?.name}
                                // details={<Details data={selectedRows[0]} />}
                                // actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
