import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Button, Chip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Icon } from "@iconify/react";
import { BeforeDemoCheckListUndo } from "./BeforeDemoCheckListUndo";
import { BeforeDemoCheckListMarkAsDone } from "./BeforeDemoCheckListMarkAsDone";
import { useDispatch, useSelector } from "react-redux";
import {
    clearTaskData,
    getTasks,
} from "../../../../../redux/features/croSlice";
import Loader3D from "../../../../Loader/Loader3D";
import { UpdateButton } from "../../../../mui/Buttons/Buttons";

export const BeforeDemoCheckList = ({ data }) => {
    const dispatch = useDispatch();
    const { tasks, loading } = useSelector((state) => state.cro);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (open) {
            dispatch(getTasks(data ? data.courseEnrollmentId : ""));
        } else {
            dispatch(clearTaskData());
        }
    }, [open]);
    return (
        <div>
            <GlobalDialog
                title="Before Demo Check List"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                closeButton={true}
                popupButton={
                    // <Button
                    //     size="small"
                    //     sx={{ backgroundColor: "#eff4f9" }}
                    //     onClick={() => setOpen(true)}
                    // >
                    //     <Icon icon="prime:list" height={22} width={22} />
                    // </Button>
                    <UpdateButton action={() => setOpen(true)} size="small">
                        Update
                    </UpdateButton>
                }
                content={
                    <>
                        {loading ? (
                            <Loader3D />
                        ) : (
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Check list</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {tasks?.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Chip
                                                        variant="outlined"
                                                        size="small"
                                                        icon={
                                                            row.completed ===
                                                            false ? (
                                                                <Icon icon="bitcoin-icons:cross-filled" />
                                                            ) : (
                                                                <Icon icon="charm:tick" />
                                                            )
                                                        }
                                                        color={
                                                            row.completed ===
                                                            false
                                                                ? "error"
                                                                : "success"
                                                        }
                                                        label={
                                                            row.completed ===
                                                            false
                                                                ? "Pending"
                                                                : "Completed"
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.completed ? (
                                                        <BeforeDemoCheckListUndo
                                                            croAssId={
                                                                data.courseEnrollmentId
                                                            }
                                                            taskName={row.name}
                                                        />
                                                    ) : (
                                                        <BeforeDemoCheckListMarkAsDone
                                                            croAssId={
                                                                data.courseEnrollmentId
                                                            }
                                                            taskName={row.name}
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </>
                }
            />
        </div>
    );
};
