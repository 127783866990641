import React, { useEffect } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Grid, Typography } from "@mui/material";
import Chart from "../../../../components/DashBoardCharts/ReactApexCharts/Chart";
import PieChart from "../../../../components/DashBoardCharts/ReactApexCharts/PieChart";
import { BarChart } from "../../../../components/DashBoardCharts/ReactApexCharts/BarChart";
import { RadarChart } from "../../../../components/DashBoardCharts/ReactApexCharts/RadarChart";
import { useDispatch, useSelector } from "react-redux";
import { getUserGenterData } from "../../../../redux/features/dashboardAnalyticsSlice";

import { DepartmentEmployees } from "./DepartmentEmployees";
import TotalAttandanceByDay from "./TotalAttandanceByDay";
import { DashboardWidgetSummaryCardView } from ".";

export const DashBoard = () => {
    const dispatch = useDispatch();
    const { userGenderData } = useSelector((state) => state.dashboardAnalytics);
    useEffect(() => {
        dispatch(getUserGenterData());
    }, []);
    console.log("userGenderData",userGenderData);
    

    return (
        <div>
            <Wrapper
                title="Dashboard"
                Content={
                    <>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DashboardWidgetSummaryCardView
                                        title="Total Employees"
                                        total={userGenderData?.totalEmployees}
                                        icon={"clarity:group-solid"}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <DashboardWidgetSummaryCardView
                                        title="Male Employees"
                                        total={userGenderData?.male}
                                        color="info"
                                        icon={"ic:outline-male"}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <DashboardWidgetSummaryCardView
                                        title="Female Employees"
                                        total={userGenderData?.female}
                                        color="warning"
                                        icon={"game-icons:female"}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <DashboardWidgetSummaryCardView
                                        title="Resigned Employees"
                                        total={userGenderData?.resigned}
                                        color="error"
                                        icon={"ri:indeterminate-circle-fill"}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                                <Box padding={1}>
                                    <Typography variant="h4">
                                        Department Employees
                                    </Typography>
                                </Box>
                                <Box>
                                    <DepartmentEmployees />
                                </Box>
                            </Box>

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={8}>
                                    <TotalAttandanceByDay />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <PieChart data={userGenderData} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={12}>
                                    <BarChart />
                                </Grid>
                                <Grid item xs={12} md={6} lg={8}>
                                    <Chart />
                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>
                                    <RadarChart />
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
            />
        </div>
    );
};

// const {
//     ProjectClassroom,
//     ProjectFoundation,
//     ProjectLittleGenie,
//     LinguisticPrograms,
//     TopManagement,
//     ProjectLinguisticProgram,
// } = cleanedObject;

// const {
//     TechnicalDepartment,
//     GCC,
//     MarketingMarketingWing,
//     MEL,
//     Finance,
//     CreativeDepartment,
//     OperationsOGD,
//     HumanResourceHR,
//     Procurement,
//     TalentAcquisitions,
//     ClientAcquisitionsWing,
//     OperationsOfficeAdministration,
//     DigitalMarketingDepartment,
//     GeneralAdministration,
//     StrategyDevelopmentCell,
// } = departmentEmployees;
