import { Delete } from "./Delete";

export const STUDY_MATERIALS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "courseId",
        headerName: "Course Id ",
        width: 300,
    },
    {
        field: "materialType",
        headerName: "Material Type",
        width: 300,
    },
    {
        field: "view",
        headerName: "View",
        width: 250,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => <Delete />,
    },
];
