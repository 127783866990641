import {
    Autocomplete,
    Button,
    Menu,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import {
    DesktopDatePicker,
    LocalizationProvider,
    TimePicker,
} from "@mui/x-date-pickers";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import {
    approvalTaskPreData,
    approveTask,
    clearData,
    clearRes,
    clearTask,
    deptEmployees,
    getPreData,
    getTask,
} from "../../../redux/features/todoSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DateTimePicker } from "@mui/lab";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import useResponsive from "../../../Hooks/useResponsive";
import Loader from "../../../components/Loader/Loader";

import { role } from "../../../components/sidebar/roleConfig";
import moment from "moment";
import {
    CancelButton,
    SubmitButton,
} from "../../../components/mui/Buttons/Buttons";

const ApproveTask = () => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const [value, setValue] = useState("");
    const [task, setTask] = useState({});
    const [time, setTime] = useState(dayjs(""));
    const [date, setDate] = useState(dayjs(""));

    let location = useLocation();

    const navigate = useNavigate();

    const params = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const state = useSelector((state) => state.todo);
    const employees = useSelector((state) => state.employees);

    const [assignedTo, setAssignedTo] = useState({
        name: "",
        userId: null,
    });

    const schema = yup.object().shape({
        statusId: yup.number().required("This field is required"),
        deadLineDate: yup.string().when("statusId", {
            is: (statusId) => {
                return statusId === 2;
            },
            then: yup.string().required("This field is required"),
        }),
        deadLineTime: yup.string().when("statusId", {
            is: (statusId) => {
                return statusId === 2;
            },
            then: yup.string().required("This field is required"),
        }),
        remarks: yup.string().required("This field is required"),
    });

    const formik = useFormik({
        initialValues: {
            employeeId: "",
            statusId: "",
            priorityId: "",
            deadLineDate: "",
            deadLineTime: "",
            remarks: "",
        },
        validationSchema: schema,
        onSubmit: (values, { resetForm }) => {
            let data = {
                ...values,
                taskId: state.task.taskId,
            };
            if (data.deadLineDate === "" || data.deadLineTime === "") {
                data = {
                    ...data,
                    deadLineDate: state.task.taskDeadLineDate,
                    deadLineTime: state.task.taskDeadLineTime,
                };
            }

            dispatch(approveTask(data)).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message);

                    navigate(`/todo/${location.state.split("/")[2]}`);
                } else {
                    enqueueSnackbar(res.payload.message);
                }
            });

            // dispatch(approveTask(data)).then((res) => {
            //
            // });
        },
    });

    const handleChange = (e) => {
        formik.setFieldValue(e.target.name, e.target.value);
    };

    const handleChangeDate = (e) => {
        setDate(e);

        const date = e.toISOString().split("T")[0];
        formik.setFieldValue("deadLineDate", date);
    };

    const handleChangeTime = (e) => {
        setTime(e);
        const Ftime = `${new Date(e).getHours()}:${new Date(
            e
        ).getMinutes()}:00`;

        formik.setFieldValue("deadLineTime", Ftime);
    };

    useEffect(() => {
        dispatch(getTask(params.id));
        dispatch(getPreData());
        let time = "11 30 PM";
        var dt = moment(time, ["h:mm A"]).format("HH:mm:ss");

        // var parsedDate = moment("20:14:15", "H m s");
        //
        return () => {
            dispatch(clearTask());
            dispatch(clearRes());
        };
    }, []);
    useEffect(() => {
        if (state.task.taskId) {
            setTask(state.task);
            formik.setFieldValue("statusId", state.task.taskStatusId);
            formik.setFieldValue("priorityId", state.task.taskPriorityId);
            formik.setFieldValue("employeeId", state.task.assignedUserId);
            setAssignedTo({
                ...assignedTo,
                name: state.task.assignedTo,
                userId: state.task.assignedUserId,
            });
            if (state.task.taskLevel) {
                dispatch(approvalTaskPreData(state.task.taskLevel));
            }
        }
        if (state.task.departmentId) {
            dispatch(deptEmployees(state.task.departmentId));
        }
    }, [state.task]);

    useEffect(() => {}, [state]);

    return (
        <div>
            <div>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Approve Task
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                {state.loading ? (
                    <Loader />
                ) : (
                    <Box sx={{ marginTop: 4 }}>
                        <Paper elevation={3}>
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            {smUp && (
                                <FormikProvider value={formik}>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Stack
                                            gap={2}
                                            sx={{
                                                width: "85%",
                                                margin: "35px auto 0 auto",
                                            }}
                                        >
                                            <TextField
                                                label="Task"
                                                multiline
                                                rows={4}
                                                disabled
                                                variant="filled"
                                                value={task.task}
                                            />
                                            <Stack direction="row" gap={2}>
                                                <TextField
                                                    name="statusId"
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                    select
                                                    label="Action"
                                                    value={task.taskStatusId}
                                                    onChange={(e) => {
                                                        setTask({
                                                            ...task,
                                                            taskStatusId:
                                                                e.target.value,
                                                        });
                                                        handleChange(e);
                                                    }}
                                                >
                                                    {state.approvalPreData?.map(
                                                        (item) => (
                                                            <MenuItem
                                                                value={item.id}
                                                                key={item.id}
                                                            >
                                                                {item.status}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                                <TextField
                                                    name="priorityId"
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                    select
                                                    label="Priority"
                                                    defaultValue={
                                                        task.taskPriorityId
                                                    }
                                                    value={
                                                        task.taskPriorityId ||
                                                        ""
                                                    }
                                                    onChange={(e) => {
                                                        setTask({
                                                            ...task,
                                                            taskPriorityId:
                                                                e.target.value,
                                                        });
                                                        handleChange(e);
                                                    }}
                                                >
                                                    {state.priority?.map(
                                                        (item) => (
                                                            <MenuItem
                                                                value={
                                                                    item.task_priority_id
                                                                }
                                                                key={
                                                                    item.task_priority_id
                                                                }
                                                            >
                                                                {
                                                                    item.task_priority_name
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {!employees.loading && (
                                                        <Autocomplete
                                                            freeSolo
                                                            id="free-solo-2-demo"
                                                            disableClearable
                                                            options={
                                                                employees.data
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.name}
                                                            defaultValue={
                                                                assignedTo
                                                            }
                                                            onChange={(
                                                                e,
                                                                value
                                                            ) => {
                                                                formik.setFieldValue(
                                                                    "employeeId",
                                                                    value.userID
                                                                );
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Proposed employee"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        type: "search",
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </Box>
                                            </Stack>
                                            <Stack direction="row" gap={2}>
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    label="employee Dead Line"
                                                    disabled
                                                    value={
                                                        task.taskDeadLineDate
                                                    }
                                                />
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    label="Time"
                                                    disabled
                                                    value={
                                                        task.taskDeadLineTime
                                                    }
                                                />
                                            </Stack>
                                            {formik.values.statusId === 2 ? (
                                                <Stack direction="row" gap={2}>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDayjs
                                                        }
                                                    >
                                                        <DesktopDatePicker
                                                            label="Your Dead Line"
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    helperText={
                                                                        formik
                                                                            .errors
                                                                            .deadLineDate
                                                                    }
                                                                    sx={{
                                                                        flex: 1,
                                                                    }}
                                                                    {...params}
                                                                />
                                                            )}
                                                            value={date}
                                                            onChange={
                                                                handleChangeDate
                                                            }
                                                        />
                                                        <TimePicker
                                                            label="Time"
                                                            value={time}
                                                            onChange={
                                                                handleChangeTime
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    helperText={
                                                                        formik
                                                                            .errors
                                                                            .deadLineTime
                                                                    }
                                                                    sx={{
                                                                        flex: 1,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>
                                            ) : null}

                                            <TextField
                                                name="remarks"
                                                type="text"
                                                label="Remarks"
                                                onChange={handleChange}
                                                multiline
                                                rows={4}
                                                helperText={
                                                    formik.errors.remarks
                                                }
                                                error={Boolean(
                                                    formik.errors.remarks
                                                )}
                                            />
                                        </Stack>
                                        <Box
                                            sx={{
                                                padding: 5,
                                                display: "flex",
                                                gap: 2,
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <CancelButton
                                                component={Link}
                                                to={`/todo/outward-tasks`}
                                                variant="contained"
                                                size="medium"
                                            >
                                                Cancel
                                            </CancelButton>

                                            <SubmitButton
                                                type="submit"
                                                size="medium"
                                            >
                                                Submit
                                            </SubmitButton>
                                        </Box>
                                    </Form>
                                </FormikProvider>
                            )}
                            {!smUp && (
                                <FormikProvider value={formik}>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Stack
                                            direction={"column"}
                                            gap={2}
                                            sx={{
                                                width: "85%",
                                                margin: "35px auto 0 auto",
                                            }}
                                        >
                                            <TextField
                                                label="Task"
                                                multiline
                                                rows={4}
                                                disabled
                                                variant="filled"
                                                value={task.task}
                                            />
                                            <Stack direction="column" gap={2}>
                                                <TextField
                                                    name="statusId"
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                    select
                                                    label="Action"
                                                    value={task.taskStatusId}
                                                    onChange={(e) => {
                                                        setTask({
                                                            ...task,
                                                            taskStatusId:
                                                                e.target.value,
                                                        });
                                                        handleChange(e);
                                                    }}
                                                >
                                                    {state.approvalPreData?.map(
                                                        (item) => (
                                                            <MenuItem
                                                                value={item.id}
                                                                key={item.id}
                                                            >
                                                                {item.status}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                                <TextField
                                                    name="priorityId"
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                    select
                                                    label="Priority"
                                                    defaultValue={
                                                        task.taskPriorityId
                                                    }
                                                    value={
                                                        task.taskPriorityId ||
                                                        ""
                                                    }
                                                    onChange={(e) => {
                                                        setTask({
                                                            ...task,
                                                            taskPriorityId:
                                                                e.target.value,
                                                        });
                                                        handleChange(e);
                                                    }}
                                                >
                                                    {state.priority?.map(
                                                        (item) => (
                                                            <MenuItem
                                                                value={
                                                                    item.task_priority_id
                                                                }
                                                                key={
                                                                    item.task_priority_id
                                                                }
                                                            >
                                                                {
                                                                    item.task_priority_name
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </TextField>
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {!employees.loading && (
                                                        <Autocomplete
                                                            freeSolo
                                                            id="free-solo-2-demo"
                                                            disableClearable
                                                            options={
                                                                employees.data
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) => option.name}
                                                            defaultValue={
                                                                assignedTo
                                                            }
                                                            onChange={(
                                                                e,
                                                                value
                                                            ) => {
                                                                formik.setFieldValue(
                                                                    "employeeId",
                                                                    value.userID
                                                                );
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Proposed employee"
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        type: "search",
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </Box>
                                            </Stack>
                                            <Stack direction="row" gap={2}>
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    label="employee Dead Line"
                                                    disabled
                                                    value={
                                                        task.taskDeadLineDate
                                                    }
                                                />
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    label="Time"
                                                    disabled
                                                    value={
                                                        task.taskDeadLineTime
                                                    }
                                                />
                                            </Stack>
                                            {formik.values.statusId === 2 ? (
                                                <Stack direction="row" gap={2}>
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDayjs
                                                        }
                                                    >
                                                        <DesktopDatePicker
                                                            label="Your Dead Line"
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    helperText={
                                                                        formik
                                                                            .errors
                                                                            .deadLineDate
                                                                    }
                                                                    sx={{
                                                                        flex: 1,
                                                                    }}
                                                                    {...params}
                                                                />
                                                            )}
                                                            value={date}
                                                            onChange={
                                                                handleChangeDate
                                                            }
                                                        />
                                                        <TimePicker
                                                            label="Time"
                                                            value={time}
                                                            onChange={
                                                                handleChangeTime
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    helperText={
                                                                        formik
                                                                            .errors
                                                                            .deadLineTime
                                                                    }
                                                                    sx={{
                                                                        flex: 1,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Stack>
                                            ) : null}

                                            <TextField
                                                name="remarks"
                                                type="text"
                                                label="Remarks"
                                                onChange={handleChange}
                                                multiline
                                                rows={4}
                                                helperText={
                                                    formik.errors.remarks
                                                }
                                                error={Boolean(
                                                    formik.errors.remarks
                                                )}
                                            />
                                        </Stack>
                                        <Box
                                            sx={{
                                                padding: 5,
                                                display: "flex",
                                                gap: 2,
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <CancelButton
                                                component={Link}
                                                to={`/todo/outward-tasks`}
                                                variant="contained"
                                                size="medium"
                                            >
                                                Cancel
                                            </CancelButton>

                                            <SubmitButton
                                                type="submit"
                                                size="medium"
                                            >
                                                Submit
                                            </SubmitButton>
                                        </Box>
                                    </Form>
                                </FormikProvider>
                            )}
                        </Paper>
                    </Box>
                )}
            </div>
        </div>
    );
};

export default ApproveTask;
