import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

export const fetchCoursesOfStudent = createAsyncThunk(
  "courses/fetchCoursesOfStudent",
  async (subEnID) => {
    const res = await apiRequest({
      method: "GET",
      url: `courses/course-base/${subEnID}`,
    });
    //
    return formatDataForDataTable(res.data.courses);
  }
);

export const getAvailableCourses = createAsyncThunk(
  "courses/getAvailableCourses",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `general/courses/2?page=1&limit=500`,
    });
    //
    return res;
  }
);

const coursesSlice = createSlice({
  name: "courses",
  initialState: {
    loading: false,
    data: [],
    availableCourse: [],
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCoursesOfStudent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCoursesOfStudent.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchCoursesOfStudent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // ----------------------------------getAvailableCourses------------------------
    builder.addCase(getAvailableCourses.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getAvailableCourses.fulfilled, (state, action) => {
      return {
        ...state,
        availableCourse: action.payload.data,
        loading: false,
      };
    });
  },
});
export default coursesSlice.reducer;
