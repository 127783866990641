import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { deleteTask, getTask } from "../../../redux/features/todoSlice";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import Iconify from "../../color-utils/Iconify";
import { Box } from "@mui/system";
import { DeleteButton, CancelButton } from "../../mui/Buttons/Buttons";
import { Divider, Paper } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CreatedTaskActions(params) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const loading = useSelector((state) => state.todo.loading);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleDelete = () => {
    dispatch(deleteTask(params.row.taskId)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        setOpenDialog(false);
        enqueueSnackbar(res.payload.message, { variant: "success" });
      }
    });
  };

  const getIcon = (name, color) => (
    <Iconify icon={name} width={30} height={30} color={color} />
  );
  const actionBox = {
    display: "flex",
    width: "100%",
    gap: 6,
    alignItems: "center",
  };
  return (
    <div>
      <div style={actionBox}>
        {params.row.uiElements.map((item) => {
          if (item === "inProcessIcon") {
            return (
              <Box
                sx={{
                  cursor: "not-allowed",
                }}
                key={item}
                title="in-progress"
              >
                {getIcon("carbon:in-progress-error", "orange")}
              </Box>
            );
          } else if (item === "deleteBtn") {
            return (
              <Box
                sx={{
                  cursor: "pointer",
                }}
                key={item}
                onClick={handleClickOpen}
                title="delete"
              >
                {getIcon("material-symbols:delete", "red")}
              </Box>
            );
          } else if (item === "editBtn") {
            return (
              <Box
                key={item}
                title="edit"
                component={Link}
                to={`/todo/edit-task/${params.row.taskId}`}
              >
                {getIcon("material-symbols:edit-note", "green")}
              </Box>
            );
          } else if (item === "rejectedIcon") {
            return (
              <Box
                key={item}
                sx={{
                  cursor: "not-allowed",
                }}
                title="rejected"
              >
                {getIcon("fluent:text-change-reject-20-filled", "red")}
              </Box>
            );
          }
        })}
      </div>

      <div>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          aria-describedby="alert-dialog-slide-description"
        >
          <Paper sx={{ backgroundColor: "grey.200" }}>
            <DialogTitle>{"Delete Task...!"}</DialogTitle>
          </Paper>
          <Divider />
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure.You want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CancelButton action={handleCloseDialog}> Cancel</CancelButton>
            <DeleteButton action={handleDelete}>
              {loading ? "Deleting..." : "Delete"}
            </DeleteButton>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
