import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { Box } from "@mui/material";
import { Details } from "./Details";
import { Actions } from "./Actions";

const data = [
    {
        id: 1,
        trainerId: "INT2112TR00033",
        name: "DR.AMRUTHA KK",
        mobile: "8086455308",
        ifsc: "SBIN0000822",
        accountNo: "001001574070",
        pan: "",
        actions: "",
    },
];

const columns = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },
    {
        field: "trainerId",
        headerName: "trainerId",
        width: 150,
    },
    {
        field: "name",
        headerName: "name",
        width: 150,
    },

    {
        field: "mobile",
        headerName: "mobile",
        width: 150,
    },

    {
        field: "ifsc",
        headerName: "ifsc",
        width: 150,
    },
    {
        field: "accountNo",
        headerName: "accountNo",
        width: 250,
    },
    {
        field: "pan",
        headerName: "pan",
        width: 250,
    },

    {
        field: "actions",
        headerName: "actions",
        width: 150,
    },
];

const Head = [
    {
        field: "name",
        headerName: "Trainer Name",
        width: 250,
    },
];

export default function TrainerDetails() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Trainer Details"
                Content={
                    <>
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.trainerId}
                                label="trainerId"
                                name={selectedRows[0]?.name}
                                details={<Details data={selectedRows[0]} />}
                                actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
