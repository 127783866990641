import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useDispatch, useSelector } from "react-redux";
import { feeStructureTable } from "../../../../../redux/features/ogdSlice";
import SimpleLoader from "../../../../Loader/SimpleLoader";

const FeeStructureViewAdmissionRegister = ({
  courseEnrollmentId,
  noFirstTermFee,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { studentFeeStructure, loading } = useSelector((store) => store.ogd);

  return (
    <GlobalDialog
      maxWidth={"md"}
      fullWidth={true}
      title={"Fee Structure"}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      popupButton={
        <>
          <Button
            // disabled={noFirstTermFee}
            variant="contained"
            size="small"
            onClick={() => {
              dispatch(feeStructureTable(courseEnrollmentId));
              setOpen(true);
            }}
          >
            View
            <Icon
              style={{ marginLeft: "5px" }}
              icon="ep:view"
              width="15"
              height="15"
            />
          </Button>
        </>
      }
      content={
        <>
          <Box>
            {loading ? (
              <Box sx={{ mt: "-150px" }}>
                <SimpleLoader />
              </Box>
            ) : studentFeeStructure.length == 0 ? (
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: "bold" }}
              >
                Fee structure is not available for the current student.
              </Typography>
            ) : (
              <TableContainer
                // component={Paper}
                sx={{
                  border: "1px solid #ccc",
                }}
              >
                <Table
                  sx={{ minWidth: 650, borderCollapse: "collapse" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ width: 300, borderRight: "1px solid #ccc" }}
                        align="center"
                      >
                        Term No
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid #ccc" }}
                        align="center"
                      >
                        Course Span (Days)
                      </TableCell>
                      <TableCell
                        sx={{ borderRight: "1px solid #ccc" }}
                        align="center"
                      >
                        Fee To Collect
                      </TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {studentFeeStructure.map((obj, index) => (
                      <TableRow key={obj.termNo}>
                        <TableCell
                          sx={{
                            borderRight: "1px solid #ccc",
                            fontWeight:
                              index == studentFeeStructure.length - 1
                                ? "bold"
                                : "normal",
                          }}
                          align="center"
                        >
                          {obj.termNo}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: "1px solid #ccc",
                            fontWeight:
                              index == studentFeeStructure.length - 1
                                ? "bold"
                                : "normal",
                          }}
                          align="center"
                        >
                          {obj.courseSpan}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: "1px solid #ccc",
                            fontWeight:
                              index == studentFeeStructure.length - 1
                                ? "bold"
                                : "normal",
                          }}
                          align="center"
                        >
                          {obj.feeToCollect}
                        </TableCell>
                        <TableCell align="center">
                          {obj.status == "Paid" && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                icon="icon-park-twotone:success"
                                width="20"
                                height="20"
                                style={{
                                  color: " #2acc1e",
                                  marginRight: "4px",
                                }}
                              />
                              {obj.status}
                            </Box>
                          )}
                          {obj.status == "Not Paid" && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                icon="icon-park-twotone:success"
                                width="20"
                                height="20"
                                style={{ color: "#eb0000", marginRight: "4px" }}
                              />
                              {obj.status}
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </>
      }
    />
  );
};

export default FeeStructureViewAdmissionRegister;
