import React, { useState } from "react";
import GlobalDialog from "../../../components/mui/Dialogue/GlobalDialog";
import { AddButton } from "../../../components/mui/Buttons/Buttons";
import AddExpense from "./AddExpense";

export const AddExpensePopup = () => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <GlobalDialog
                title="Add Expense"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                closeButton={true}
                popupButton={
                    <AddButton action={() => setOpen(true)}>
                        Add Expense
                    </AddButton>
                }
                content={<AddExpense open={open} setOpen={setOpen} />}
            />
        </div>
    );
};
