import { DeleteProjectClassPopup } from "./DeleteProjectClassPopup";
import { EditProjectClassPopup } from "./EditProjectClassPopup";
import { ProjectClassListingStatus } from "./ProjectClassListingStatus";
import { ProjectClassesStatus } from "./ProjectClassesStatus";

export const PROJECT_CLASSES_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "projectName",
        headerName: "Project Name",
        width: 150,
    },
    {
        field: "className",
        headerName: "className",
        width: 90,
    },
    {
        field: "classDescription",
        headerName: "Class Description ",
        width: 250,
    },
    {
        field: "isActive",
        headerName: "isActive",
        width: 150,
        renderCell: (params) => <ProjectClassesStatus params={params.row} />,
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 150,
        renderCell: (params) => (
            <ProjectClassListingStatus params={params.row} />
        ),
    },
    {
        field: "Edit",
        headerName: "Edit",
        width: 150,
        renderCell: (params) => <EditProjectClassPopup params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => (
            <DeleteProjectClassPopup params={params.row.classId} />
        ),
    },
];
