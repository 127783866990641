export const role = {
    hod: (items) => {
        const profile = JSON.parse(localStorage.getItem("cred")).profile;
        if (profile.isHod === true || profile.isReportingHead === true) {
            return items;
        } else {
            if (items) {
                return items.filter(
                    (item) =>
                        item.title !== "Inward Tasks" &&
                        item.title !== "Outward Tasks" &&
                        item.title !== "Department Tasks"
                );
            }
        }
    },
    itemControl: (items) => {
        const profile = JSON.parse(localStorage.getItem("cred")).profile;
        if (profile.isHod === true) {
            return items;
        } else {
            if (items) {
                return items.filter((item) => item.title !== "privileges");
            }
        }
    },
};
