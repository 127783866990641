import React from "react";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSingleUser } from "../../../redux/features/userSlice";
import { useEffect } from "react";

export default function ActionsMenu(params) {
    const dispatch = useDispatch();
    const { role } = useSelector((state) => state);
    return (
        <div>
            <Button
                component={Link}
                to={`/my-workspace/editemployee/${params.params.id}`}
                sx={{ backgroundColor: "#3a7ab730" }}
                disabled={!role.permissions.includes("employee edit")}
            >
                <Icon
                    icon="material-symbols:edit-document-outline"
                    width="20"
                    height="20"
                />
                Edit
            </Button>
        </div>
    );
}
