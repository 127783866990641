import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "../Iconify";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../redux/features/modalSlice";
import { deleteNotification } from "../../redux/features/notificationSlice";
import { useSnackbar } from "notistack";


const ModalWrapper = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const modal = useSelector((state) => state.modal);
  function handleModalClose() {
    dispatch(closeModal());
  }
  function handleModalAction() {
    if (modal.variant === "danger") {
      if (modal.type === "notification") {
        dispatch(deleteNotification(modal.id)).then((res) =>{
          dispatch(closeModal());
          enqueueSnackbar(res.payload.message,{ 
            variant: 'success',
          });
        }
          

        );
      }
    }
  }
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="eva:close-fill" />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  return (
    <div>
    <BootstrapDialog onClose={handleModalClose} open={modal.isOpen}>
      <BootstrapDialogTitle onClose={handleModalClose}>
        {modal.title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            px: 6,
            py: 4,
          }}
        >
          {modal.content}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleModalClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleModalAction}>
          {modal.buttonText}
        </Button>
      </DialogActions>
    </BootstrapDialog>
    
    </div>
  );
};
export default ModalWrapper;
