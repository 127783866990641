export const COMPLTD_SESNS_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "SlNo",
    },
    {
        field: "subjectName",
        headerName: "Subject",
        minWidth: 150,
    },
    {
        field: "sessionsCompleted",
        headerName: "Compleated sessions count",
        minWidth: 200,
    },
];
