import { Chip } from "@mui/material";
import React from "react";

export default function NotificationStatus(params) {
  if (params.row.crm_approved === true) {
    return (
      <div>
        <Chip
          label="Approved"
          size="small"
          sx={{ backgroundColor: "#c8facd", color: "#0db05d", fontSize: 12 }}
        />
      </div>
    );
  } else if (params.row.crm_approved === !true) {
    return (
      <div>
        <Chip
          label="Pending"
          size="small"
          sx={{ backgroundColor: "#dfaa0d3b", color: "#dfaa0d" }}
        />
      </div>
    );
  }
}
