import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiRequest from '../../api/request'

const initialState = {
  loading: false,
  resData: {
    data: [],
    meta: {},
  },
  departments: [],
}

export const getAllPostWorkingHours = createAsyncThunk(
  'postWorkingHoursSlice/getAllRequests',
  async (dispatchParams) => {
    const { selectedDate, paginationData, departmentName } = dispatchParams
    const url = `general/help-support/${selectedDate ? selectedDate : ''}?page=${paginationData?.pageNum}&limit=${
      paginationData?.gridSize
    }&search=${encodeURIComponent(paginationData?.search)} &filter=${
      departmentName ? 'department :equals:' + departmentName : ''
    }`
    console.log(url)

    // const res = await apiRequest({
    //   method: "get",
    //   url: `general/help-support/?page=${paginationDetails?.pageNum}&limit=${paginationDetails?.gridSize}&search=${encodeURIComponent(paginationDetails?.search)}`,
    // });
    // return res;
  },
)

export const getAllDepartmenst = createAsyncThunk('postWorkingHoursSlice/getAllDepartmenst', async () => {
  const res = await apiRequest({
    method: 'get',
    url: `departments/`,
  })
  return res
})

export const createPostWorkingRequest = createAsyncThunk(
  'postWorkingHoursSlice/createPostWorkingRequest',
  async (data) => {
    console.log('data to post', data)

    // const res = await apiRequest({
    //   method: "post",
    //   url: `general/help-support`,
    //   data: data,
    // });
    // return res;
  },
)

export const updatePostWorking = createAsyncThunk('postWorkingHoursSlice/updatePostWorking', async (data) => {
  const res = await apiRequest({
    method: 'patch',
    url: `general/help-support/${data.id}`,
    data: data,
  })
  return res
})

export const deleteWorkingHourRequest = createAsyncThunk(
  'postWorkingHoursSlice/deleteWorkingHourRequest',
  async (id) => {
    console.log('delte call with id', id)

    // const res=await apiRequest({
    //   method:"delete",
    //   url:`general/help-support/${id}`
    // })
    // return res
  },
)

export const PostWorkingApproveOrReject = createAsyncThunk(
  'postWorkingHoursSlice/PostWorkingApproveOrReject',
  async (data) => {
    console.log('approve or reject', data)

    // const res = await apiRequest({
    //   method: "patch",
    //   url:`general/help-support/approve/${data.id}`,
    //   data: data,
    // });
    // return res;
  },
)

export const postWorkingBulkApprove = createAsyncThunk(
  'postWorkingHoursSlice/postWorkingBulkApprove',
  async (data) => {
    console.log('bulk approve', data)

    // const res = await apiRequest({
    //   method: "patch",
    //   url:`general/help-support/approve/${data.id}`,
    //   data: data,
    // });
    // return res;
  },
)

const postWorkingHoursSlice = createSlice({
  name: 'postWorkingHoursSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //...............................getAllPostWorkingHours......................//
    builder.addCase(getAllPostWorkingHours.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })
    builder.addCase(getAllPostWorkingHours.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })

    //...............................GET ALL DEPARTMENTS......................//
    builder.addCase(getAllDepartmenst.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })
    builder.addCase(getAllDepartmenst.fulfilled, (state, action) => {
      return {
        ...state,
        departments: action.payload?.data?.departments,
        loading: false,
      }
    })

    //...............RAISING A REQUEST...........................
    builder.addCase(createPostWorkingRequest.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(createPostWorkingRequest.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })

    //...............DELETING A REQUEST...........................
    builder.addCase(deleteWorkingHourRequest.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(deleteWorkingHourRequest.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })

    //...............APPROVE OR REJECT A REQUEST...........................
    builder.addCase(PostWorkingApproveOrReject.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      }
    })

    builder.addCase(PostWorkingApproveOrReject.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
      }
    })
        //...............BULK APPROVE REQUEST...........................
        builder.addCase(postWorkingBulkApprove.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          }
        })
    
        builder.addCase(postWorkingBulkApprove.fulfilled, (state, action) => {
          return {
            ...state,
            loading: false,
          }
        })
  },
})

export default postWorkingHoursSlice.reducer
