import React from "react";
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";

const GridBox = ({ listItem }) => {
    return (
        <>
            <Box padding={3}>
                <Grid container spacing={1}>
                    {listItem}
                </Grid>
            </Box>
        </>
    );
};

const ListItemBox = ({ label, component }) => {
    return (
        <>
            <Grid item xs={12} lg={6} md={6}>
                <Box
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: 3,
                    }}
                >
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <Icon
                                    icon="uil:arrow-up"
                                    rotate={1}
                                    color="#2f72b3"
                                    width="25"
                                    height="25"
                                />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography fontWeight="bolder" fontSize={14}>
                                    {label}
                                </Typography>
                            </ListItemText>
                            {component}
                        </ListItem>
                    </List>
                </Box>
            </Grid>
        </>
    );
};
export { GridBox, ListItemBox };
