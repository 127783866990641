import { DeleteMiscMaster } from "./DeleteMiscMaster";
import { EditMiscMaster } from "./EditMiscMaster";
import { MiscMasterListingStatus } from "./MiscMasterListingStatus";
import { MiscMasterStatus } from "./MiscMasterStatus";
export const MISC_MASTER_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "masterName",
        headerName: "Master Name",
        width: 200,
    },
    {
        field: "masterType",
        headerName: "Master Type",
        width: 250,
    },
    {
        field: "masterDescription",
        headerName: "master Description",
        width: 250,
    },
    {
        field: "isActive",
        headerName: "isActive",
        width: 150,
        renderCell: (params) => <MiscMasterStatus params={params.row} />,
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 150,
        renderCell: (params) => <MiscMasterListingStatus params={params.row} />,
    },
    {
        field: "Edit",
        headerName: "Edit",
        width: 150,
        renderCell: (params) => <EditMiscMaster params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => (
            <DeleteMiscMaster params={params.row.miscMasterId} />
        ),
    },
];
