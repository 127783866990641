import React from "react";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import useResponsive from "../../../Hooks/useResponsive";
export default function PersonalDetails() {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const user = useSelector((state) => state.profile);
    return (
        <div>
            {smUp && (
                <Box>
                    <Paper elevation={3} padding={2}>
                        <Box sx={{ padding: 2 }}>
                            <Typography variant="h5">
                                Personal Details
                            </Typography>
                        </Box>
                        <Box>
                            <Box>
                                <Stack direction={"row"}>
                                    <Box>
                                        <Paper
                                            sx={{
                                                width: "100%",

                                                borderRadius: 3,
                                            }}
                                        >
                                            <Box sx={{ marginLeft: 1 }}>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                    sx={{ margin: 1 }}
                                                >
                                                    <Icon
                                                        icon="clarity:email-outline-badged"
                                                        width="30"
                                                        height="30"
                                                        color="#2f72b3"
                                                    />
                                                    <Typography
                                                        variant="h6"
                                                        paddingTop={0}
                                                    >
                                                        {user.email}
                                                    </Typography>
                                                </Stack>
                                                <Divider variant="middle" />

                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                    sx={{ margin: 1 }}
                                                >
                                                    <Icon
                                                        icon="cil:birthday-cake"
                                                        width="30"
                                                        height="30"
                                                        color="#2f72b3"
                                                    />
                                                    <Typography
                                                        variant="h6"
                                                        paddingTop={1}
                                                    >
                                                        {user.dob}
                                                    </Typography>
                                                </Stack>

                                                <Divider variant="middle" />

                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                    sx={{ margin: 1 }}
                                                >
                                                    <Icon
                                                        icon="eva:phone-call-outline"
                                                        width="30"
                                                        height="30"
                                                        color="#2f72b3"
                                                    />
                                                    <Typography
                                                        variant="h6"
                                                        paddingTop={0}
                                                    >
                                                        +91 {user.mobileNo}
                                                    </Typography>
                                                </Stack>

                                                <Divider variant="middle" />

                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                    sx={{ margin: 1 }}
                                                >
                                                    <Icon
                                                        icon="ph:gender-intersex-bold"
                                                        width="30"
                                                        height="30"
                                                        color="#2f72b3"
                                                    />
                                                    <Typography
                                                        variant="h6"
                                                        paddingTop={0}
                                                    >
                                                        {user.sex}
                                                    </Typography>
                                                </Stack>

                                                <Divider variant="middle" />

                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                    sx={{ margin: 1 }}
                                                >
                                                    <Icon
                                                        icon="bx:donate-blood"
                                                        width="30"
                                                        height="30"
                                                        color="#2f72b3"
                                                    />
                                                    <Typography
                                                        variant="h6"
                                                        paddingTop={0}
                                                    >
                                                        {user.bloodGroup}
                                                    </Typography>
                                                </Stack>

                                                <Divider variant="middle" />

                                                <Stack
                                                    direction={"row"}
                                                    spacing={1}
                                                    sx={{ margin: 1 }}
                                                >
                                                    <Icon
                                                        icon="fluent-mdl2:family"
                                                        width="30"
                                                        height="30"
                                                        color="#2f72b3"
                                                    />
                                                    <Typography
                                                        variant="h6"
                                                        paddingTop={0}
                                                    >
                                                        {user.maritalStatus}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            )}
        </div>
    );
}
