import React from 'react'
import GlobalDialog from './GlobalDialog'
import { Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { BulkApprove, CancelButton, SubmitButton } from '../Buttons/Buttons'

const GlobalBulkDialog = ({
    api,
    loading,
    title,
    dialogNote,
    upDateCall,
    otherActions,
    updatePaginationCall,
    popUpButton,
    openModal,
    setOpen
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
  
  return (
    <div>
      <GlobalDialog
        title={title}
        open={openModal}
        setOpen={setOpen}
        fullWidth
        maxWidth={'xs'}
        popupButton={
            popUpButton
        }
        content={
          <>
            <Typography>{dialogNote}</Typography>
          </>
        }
        actionButton={
          <>
            <CancelButton
              action={() => {
                setOpen(false)
              }}>
              Cancel
            </CancelButton>
            <SubmitButton
              action={() => {
                dispatch(api).then((res) => {
                  if (res.payload.status === 'failed' || res.payload.status === 'error') {
                    setOpen(false)
                    enqueueSnackbar(res.payload.message, {
                      variant: 'error',
                    })
                  } else if (res.payload.status === 'success') {
                    dispatch(upDateCall)
                    if (otherActions) {
                        otherActions(false);
                      }
                    if (updatePaginationCall) {
                      dispatch(updatePaginationCall)
                    }
                    setOpen(false)
                    enqueueSnackbar(res.payload.message, {
                      variant: 'success',
                    })
                  }
                })
              }}>
             {loading ? 'Submitting...' : 'Submit'}
            </SubmitButton>
          </>
        }
      />
    </div>
  )
}

export default GlobalBulkDialog