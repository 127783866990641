import { Icon } from "@iconify/react/dist/iconify.js";
import { Box } from "@mui/material";
import ApprovalOrReject from "./ApprovalOrReject";

export const evenigFoodApprovalTableHeader = [
    { field: 'serialNumber', headerName: 'Sl No', width: 50 },
    {
      field: 'food_date',
      headerName: 'Date',
      width: 100,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
    },
    {
      field: 'department',
      headerName: 'Department',
      width: 200,
    },
    {
      field: 'designation',
      headerName: 'Designation',
      width: 250,
    },
    {
      field: 'tbl_food_slot',
      headerName: 'Slot Time',
      width: 180,
    },
    {
      field: 'office_name',
      headerName: 'Office Name',
      width: 120,
    },
    {
      field: 'food_approval_status',
      headerName: 'Approval Status',
      width: 130,
      renderCell:(params)=>{
        const status=params.row.food_approval_status
        if(status==0){
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Icon
                icon="material-symbols:pending"
                width="20"
                height="20"
                style={{
                  color: ' #f1f139',
                  marginRight: '4px',
                }}
              />
              Pending
            </Box>)
        }else if(status==1){
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Icon
                icon="icon-park-twotone:success"
                width="20"
                height="20"
                style={{
                  color: ' #2acc1e',
                  marginRight: '4px',
                }}
              />
              Approved
            </Box>
          )
        }else if(status==2){
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Icon
                icon="fluent:text-change-reject-20-filled"
                width="20"
                height="20"
                style={{ color: '#eb0000', marginRight: '4px' }}
              />
              Rejected
            </Box>
          )
        }else{
          return status
        }
      }
    },
    {
      field: "Verify",
      headerName: "Actions",
      width: 230,
      renderCell:(params)=><ApprovalOrReject row={params.row}/>
    },
   
  ];