import React from "react";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
import { courseAvailableStatus } from "../../redux/features/mastersSlice";

export const CourseStatus = ({ params }) => {
    return (
        <div>
            {" "}
            <StatusToggleSwitch
                initialValue={params.is_active}
                api={courseAvailableStatus(params.course_available_id)}
            />
        </div>
    );
};
