import { Icon } from '@iconify/react/dist/iconify.js'
import { Avatar, Box, Button, Stack, Table, TableCell, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'

const StudentProfile = ({ title, subTitle, personalDetails,otherDetails, profilePic }) => {

  const capitalize = (keyName) => {
    return keyName
      .replace(/([A-Z])/g, ' $1') // Insert space before each capital letter
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
  }

  const[isShowingProfile,setIsShowingProfile]=useState(true)
  const [details,setDetails]=useState([])
  const changeDetails=()=>{
    setIsShowingProfile((prev)=>!prev)
  }

  useEffect(()=>{
    if(isShowingProfile){
      setDetails(Object.entries(personalDetails))
    }else{
      setDetails(Object.entries(otherDetails))
    }
  },[isShowingProfile,personalDetails])
  return (
    <motion.div
    key={isShowingProfile ? 'personalDetails' : 'otherDetails'} // key to trigger the flip animation
    initial={{ rotateY: 90, opacity: 0 }}
    animate={{ rotateY: 0, opacity: 1 }}
    exit={{ rotateY: -90, opacity: 0 }}
    transition={{ duration: 0.9, ease: 'easeInOut' }} // flip effect with duration
  >
      <Box>
        <Avatar
          sx={{
            width: 80,
            height: 80,
            mx: 'auto',
            mt: -6,
            mb: 1,
            border: '4px solid white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          }}
          alt="Student Profile"
          src={profilePic ? profilePic : ''}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black' }}>
            {title?.toUpperCase()}
          </Typography>
          <Typography variant="body1">{subTitle}</Typography>
          <Button onClick={changeDetails}>{isShowingProfile ? "← Personal Details →":"← Other Details →"}</Button>
        </Box>

        <Box sx={{ p: 1 }}>
          {/* {Object.entries(object).map(([key, value]) => (
            <Box key={key} sx={{ mb: '5px', display: 'flex',alignItems:"center" }}>
              <Box>
                <Icon icon="ph:dot-duotone" width="35" height="35" style={{ color: 'black', marginRight: '2px' }} />
              </Box>

              <Box sx={{mt:-1}}>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}>
                  {capitalize(key)} : {value}
                </Typography>
              </Box>
            </Box>
          ))} */}

          <Table>
          <tbody>
          {details?.map(([key, value]) => {
              return (
                <TableRow key={key}>
                  <TableCell variant="head" sx={{ padding: 1 }}>
                    <Stack direction={'row'} spacing={1}>
                      <Box>
                        <Icon color="#0089D0" icon="hugeicons:paragraph-bullets-point-01" width="20" height="20" />
                      </Box>
                      <Box>{capitalize(key)}</Box>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ padding: 0.5 }}>{value || "Nill"}</TableCell>
                </TableRow>
              )
            })}
            </tbody>
          </Table>
        </Box>
      </Box>
    </motion.div>
  )
}

export default StudentProfile
