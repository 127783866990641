import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { CancelButton, SaveButton } from "../../../../mui/Buttons/Buttons";
import { Box, Button, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { getTasks, markTask } from "../../../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

export const BeforeDemoCheckListMarkAsDone = ({ taskName, croAssId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.cro);
    const [open, setOpen] = useState(false);

    const handleSubmit = () => {
        const data = {
            croAssignmentId: croAssId,
            taskName: taskName,
            completed: true,
        };
        dispatch(markTask(data)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (
                res.payload.message ===
                "Task completion status updated successfully"
            ) {
                dispatch(getTasks(croAssId));
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
            }
        });
    };
    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                title="Mark Status"
                closeButton={true}
                popupButton={
                    <>
                        <Button
                            size="small"
                            sx={{
                                color: "#0db05d",
                                backgroundColor: "#c8facd",
                            }}
                            onClick={() => setOpen(true)}
                        >
                            <Icon icon="charm:tick" height={20} width={20} />
                        </Button>
                    </>
                }
                content={
                    <>
                        <Box>
                            <Typography>
                                Are you sure?. you want to mark as done.?
                            </Typography>
                        </Box>
                    </>
                }
                actionButton={
                    <>
                        <CancelButton
                            size="small"
                            action={() => setOpen(false)}
                        />
                        <SaveButton action={handleSubmit}>
                            {loading ? "Saving..." : "Save"}
                        </SaveButton>
                    </>
                }
            />
        </div>
    );
};
