import AddOrUpdateHoliday from "./AddOrUpdateHoliday";
import DeleteHoliday from "./DeleteHoliday";

export const HolidayCalendarTableHead = [
  {
    field: "serialNumber",
    headerName: "slNo",
  },
  {
    field: "holiday_date",
    headerName: "Date",
    width: 200,
  },
  {
    field: "holiday_name",
    headerName: "Holiday Name",
    width: 200,
  },
  {
    field: "created_on",
    headerName: "Created On",
    width: 200,
  },
  {
    field: "modified_on",
    headerName: "Modified On",
    width: 200,
    renderCell: (params) =>
      params.row.modified_on == null
        ? "Not Yet Modified"
        : params.row.modified_on,
  },
  {
    field: "Update",
    headerName: "Update",
    width: 180,
    renderCell: (params) => <AddOrUpdateHoliday params={params.row} year={params.row.holiday_date.split("-")[0]} />,
  },
  {
    field: "Delete",
    headerName: "Delete",
    width: 120,
    renderCell: (params) => <DeleteHoliday id={params.row.holiday_id} year={params.row.holiday_date.split("-")[0]} />,
  },
];
