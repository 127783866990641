import React from 'react'
import {
    Box,
    Typography,
  } from "@mui/material";

const AttendenceObjectiveComponent = ({backgroundColor,objectiveName,values}) => { //CREATED A COMPONENT TO DISPLAY THE ATTENDANCE OBJECTIVE AS KEY VALUE PAIRS
    return (
    <Box display="flex" alignItems="center">
    <Box
      sx={{
        backgroundColor: backgroundColor,
        height: "12px",
        width: "12px",
        marginRight: 1,
      }}
    />
    <Typography >{objectiveName} : {values}</Typography>
  </Box>
  )
}

export default AttendenceObjectiveComponent