import { Icon } from '@iconify/react/dist/iconify.js'
import ToolTip from '../../ToolTip/ToolTip'
import { Box } from '@mui/material'
import { requestFor } from './AddOrUpdateRequest' //JSON FILE TO CHOOSE WHAT KIND OF REQUEST

export const RequsetRaisingTableHeader = [
  { field: 'sl_no', headerName: 'Serial No', width: 80 },
  {
    field: 'type',
    headerName: 'Type',
    width: 200,
    renderCell: (params) => requestFor.find((rqst) => rqst.idNo == params.row.type)?.valueNo,
  },
  { field: 'title', headerName: 'Title', width: 150,
    renderCell: (params) => {
      return <ToolTip expand={params.row.title} content={params.row.title} />
    }
   },
  {
    field: 'description',
    headerName: 'Description',
    width: 200,
    renderCell: (params) => {
      return <ToolTip expand={params.row.description} content={params.row.description} />
    },
  },
  
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    renderCell: (params) => {
      if (params.row.status == 'pending') {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              icon="material-symbols:pending"
              width="20"
              height="20"
              style={{
                color: ' #f1f139',
                marginRight: '4px',
              }}
            />
            Pending
          </Box>
        )
      } else if (params.row.status == 'approved') {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              icon="icon-park-twotone:success"
              width="20"
              height="20"
              style={{
                color: ' #2acc1e',
                marginRight: '4px',
              }}
            />
            Approved
          </Box>
        )
      } else if (params.row.status == 'rejected') {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              icon="fluent:text-change-reject-20-filled"
              width="20"
              height="20"
              style={{ color: '#eb0000', marginRight: '4px' }}
            />
            Rejected
          </Box>
        )
      } else if (params.row.status == 'assigned') {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              icon="clarity:assign-user-solid"
              width="20"
              height="20"
              style={{ color: '#dacc34', marginRight: '4px' }}
            />
            Assigned
          </Box>
        )
      } else if (params.row.status == 'started') {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              icon="fluent-mdl2:progress-ring-dots"
              width="20"
              height="20"
              style={{ color: '#eb9500', marginRight: '4px' }}
            />
            Started
          </Box>
        )
      } else if (params.row.status == 'resolved') {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon icon="ep:finished" width="20" height="20" style={{ color: ' #04eb00', marginRight: '4px' }} />
            Resolved
          </Box>
        )
      } else {
        return params.row.status
      }
    },
  },

  { field: 'deliveryDate', headerName: 'Delivery Date', width: 110,
    renderCell: (params) => params?.row?.deliveryDate.split(" ")[0]
   },
  { field: 'createdBy', headerName: 'Requested By', width: 200 },

  {
    field: 'assignedTo',
    headerName: 'Assigned To',
    width: 200,
    renderCell: (params) => params?.row?.assignedTo || "It hasn't been assigned yet.",
  },
]
