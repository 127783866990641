import React from "react";
import { TextField } from "@mui/material";

export default function BasicTextField({
    sx,
    name,
    label,
    value,
    action,
    error,
    helperText,
    rows,
    id,
    multiline,
    type,
    InputLabelProps,
    inputProps,
    fullWidth,
    disabled,
    variant,
}) {
    return (
        <TextField
            sx={sx}
            name={name}
            label={label}
            value={value}
            onChange={action}
            error={error}
            helperText={helperText}
            rows={rows}
            id={id}
            multiline={multiline}
            type={type}
            InputLabelProps={InputLabelProps}
            inputProps={inputProps}
            fullWidth={fullWidth}
            disabled={disabled}
            variant={variant}
        />
    );
}
