import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const SnackbarSlice = createSlice({
    name:"snackbar",
    initialState:{
        open:false,
        timeout:2000,
        variant:"info",
        message:""
    },
    reducers: {
        setNotification(state,action) {
          state.message = action.message;
        },
      },
})

export const { setNotification } =
SnackbarSlice.actions;
export default SnackbarSlice.reducer;
