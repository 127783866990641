import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getTextPublisher } from "../../../redux/features/mastersSlice";
import { useState } from "react";
import { TEXT_PUBLISHER_TABLE_HEAD } from "../../../components/Masters/TextPublisherTableHead";
import { AddProjectClassPopup } from "../../../components/Masters/AddProjectClassPopup";
import { AddTextPublisher } from "../../../components/Masters/AddTextPublisher";

export default function TextPublisher() {
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const { textPublisher, loading } = useSelector((state) => state.masters);

    useEffect(() => {
        dispatch(getTextPublisher());
    }, []);

    return (
        <div>
            <Wrapper
                title="Text Publisher"
                actions={<AddTextPublisher />}
                Content={
                    <>
                        <Paper elevation={3}>
                            <Box sx={{ width: "100%", padding: 0 }}>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    loading={loading}
                                    autoHeight
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    getRowHeight={() => "auto"}
                                    rows={textPublisher}
                                    columns={TEXT_PUBLISHER_TABLE_HEAD}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
