import React from "react";
import useResponsive from "../../../../../Hooks/useResponsive";
import { Chip, Stack, Box, Divider } from "@mui/material";
import { Icon } from "@iconify/react";
import { TableData } from "./TableData";

export const LogData = React.memo((params) => {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          letterSpacing: 1,
          fontWeight: "bolder",
          margin: 2,
        }}
      >
        <Stack direction={smUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
          <Stack direction={mdUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
            <Chip
              label={`Admn No:- ${params.params.admnNo}`}
              color="primary"
              variant="outlined"
              icon={<Icon icon="solar:user-id-linear" width="15" height="15" />}
            />
            <Chip
              label={`Name:- ${params.params.studentName}`}
              color="primary"
              variant="outlined"
              icon={
                <Icon
                  icon="icon-park-outline:edit-name"
                  width="15"
                  height="15"
                />
              }
            />
          </Stack>
          <Stack direction={mdUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
            <Chip
              label={`Class:- ${params.params.className}`}
              color="primary"
              variant="outlined"
              icon={
                <Icon
                  icon="material-symbols:school-outline"
                  width="15"
                  height="15"
                />
              }
            />

            <Chip
              label={`Course:- ${params.params.courseName}`}
              color="primary"
              variant="outlined"
              icon={
                <Icon icon="material-symbols:subject" width="15" height="15" />
              }
            />
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 2 }} />
      </Box>

      <Box>
        <TableData params={params} />
      </Box>
    </div>
  );
});
