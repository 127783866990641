import React from "react";
import { miscMasterStatus } from "../../redux/features/mastersSlice";
import { StatusToggleSwitch } from "../StatusToggleSwitch";

export const MiscMasterStatus = ({ params }) => {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.isActive}
                api={miscMasterStatus(params.miscMasterId)}
            />
        </div>
    );
};
