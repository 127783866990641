import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Paper, TextField, Skeleton, FormControl } from "@mui/material";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik, Form, FormikProvider } from "formik";
import { Divider } from "@mui/material";
import SimpleLoader from "../../Loader/SimpleLoader";
import {
    getDepartmentById,
    editDepartment,
    getAllDepartment,
} from "../../../redux/features/departmentSlice";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import useResponsive from "../../../Hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditDepartment({ setOpenEdit, openEdit, params }) {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const loading = useSelector((state) => state.departments.loading);
    const users = useSelector((state) => state.users);
    const department = useSelector(
        (state) => state.departments.singleDepartment
    );
    const handleClose = () => {
        setOpenEdit(false);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            deptName: department.departmentName || "",
            hodId: department.hodId || "",
            id: params.row.departmentId,
        },
        // validationSchema: CreateSchema,
        onSubmit: (values) => {
            dispatch(editDepartment(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === 200) {
                    enqueueSnackbar("Created Successfully..", {
                        variant: "success",
                    });
                    dispatch(getAllDepartment());
                    setOpenEdit(false);
                }
            });
        },
    });

    return (
        <div>
            <Dialog
                open={openEdit}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <DialogTitle>{"Edit Department"}</DialogTitle>
                        <Divider />
                        {loading ? (
                            <SimpleLoader sx={{ width: 500, height: 300 }} />
                        ) : (
                            <>
                                <DialogContent>
                                    <Paper sx={{ backgroundColor: "#f2f3f5" }}>
                                        {smUp && (
                                            <Box
                                                sx={{ width: 500, padding: 4 }}
                                            >
                                                <Box margin={2}>
                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : (
                                                        <TextField
                                                            label="Department"
                                                            name="deptName"
                                                            placeholder="Enter new Department Name"
                                                            variant="filled"
                                                            fullWidth
                                                            value={
                                                                formik.values
                                                                    .deptName
                                                            }
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                                <Box margin={2}>
                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : (
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            name="hodId"
                                                            options={users.data}
                                                            defaultValue={{
                                                                name:
                                                                    department?.hod ||
                                                                    "",
                                                                hodId:
                                                                    department?.hodId ||
                                                                    "",
                                                            }}
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.name ||
                                                                ""
                                                            }
                                                            onChange={(
                                                                e,
                                                                value
                                                            ) => {
                                                                formik.setFieldValue(
                                                                    "hodId",
                                                                    value.id
                                                                );
                                                            }}
                                                            renderOption={(
                                                                props,
                                                                option
                                                            ) => (
                                                                <Box
                                                                    component="li"
                                                                    {...props}
                                                                    key={
                                                                        option.id
                                                                    }
                                                                >
                                                                    {
                                                                        option.name
                                                                    }
                                                                </Box>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select HOD"
                                                                    fullWidth
                                                                    variant="filled"
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </Box>
                                            </Box>
                                        )}
                                        {!smUp && (
                                            <Box
                                                sx={{ width: 290, padding: 2 }}
                                            >
                                                {loading ? (
                                                    <SimpleLoader
                                                        sx={{
                                                            width: 300,
                                                            height: 200,
                                                        }}
                                                    />
                                                ) : (
                                                    <>
                                                        <Box margin={1}>
                                                            {loading ? (
                                                                <FormControl
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Skeleton
                                                                        variant="rectangular"
                                                                        sx={{
                                                                            width: "100%",
                                                                            height: 56,
                                                                            borderRadius: 1,
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            ) : (
                                                                <TextField
                                                                    label="Department"
                                                                    name="deptName"
                                                                    placeholder="Enter new Department Name"
                                                                    variant="filled"
                                                                    fullWidth
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .deptName
                                                                    }
                                                                    onChange={
                                                                        formik.handleChange
                                                                    }
                                                                />
                                                            )}
                                                        </Box>
                                                        <Box margin={1}>
                                                            {loading ? (
                                                                <FormControl
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Skeleton
                                                                        variant="rectangular"
                                                                        sx={{
                                                                            width: "100%",
                                                                            height: 56,
                                                                            borderRadius: 1,
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            ) : (
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    name="hodId"
                                                                    options={
                                                                        users.data
                                                                    }
                                                                    defaultValue={{
                                                                        name:
                                                                            department?.hod ||
                                                                            "",
                                                                        hodId:
                                                                            department?.hodId ||
                                                                            "",
                                                                    }}
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) =>
                                                                        option.name ||
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                        value
                                                                    ) => {
                                                                        formik.setFieldValue(
                                                                            "hodId",
                                                                            value.id
                                                                        );
                                                                    }}
                                                                    renderOption={(
                                                                        props,
                                                                        option
                                                                    ) => (
                                                                        <Box
                                                                            component="li"
                                                                            {...props}
                                                                            key={
                                                                                option.id
                                                                            }
                                                                        >
                                                                            {
                                                                                option.name
                                                                            }
                                                                        </Box>
                                                                    )}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Select HOD"
                                                                            fullWidth
                                                                            variant="filled"
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                        )}
                                    </Paper>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            marginLeft: 2,
                                            backgroundColor: "#eff4f9",
                                            color: "#008fc3",
                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        sx={{
                                            marginLeft: 2,
                                            backgroundColor: "#c8facd",
                                            color: "#0db05d",
                                        }}
                                        // onClick={handleClose}
                                        type="submit"
                                    >
                                        <Icon
                                            icon="mdi:content-save-check-outline"
                                            width="25"
                                            height="25"
                                        />
                                        {loading
                                            ? "Updating..."
                                            : "Update Department"}
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Form>
                </FormikProvider>
            </Dialog>
        </div>
    );
}
