export const SOURCE_TABLE_HEAD = [
    {
        field: "serialNumber",
        flex: 0.4,
        minWidth: 90,
        headerName: "No.",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "sourceName",
        flex: 1,
        minWidth: 90,
        headerName: "Source",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "count",
        flex: 1,
        minWidth: 150,
        headerName: "Recorded Count",
        type: "string",
        headerAlign: "left",
        align: "left",
        // editable: true,
    },
];
