import React, { useEffect } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    SaveButton,
} from "../../../../mui/Buttons/Buttons";
import { Alert, Box, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import {
    addSubjectEnrollmentDetailed,
    getProjectCategories,
} from "../../../../../redux/features/croSlice";
import { useFormik, Form, FormikProvider } from "formik";

export const AddDetailsPopup = ({
    subEnroll,
    subjectEnrollmentId,
    foundationId,
    courseEnrollmentDtldId,
}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [filterdCategories, setFilteredCategories] = useState([]);
    const {
        levelTypePreData,
        subjectsByClassPreData,
        projectCategoriesPreData,
    } = useSelector((state) => state.cro);

    const purchaseType = [
        { id: 8, type: "Subject Base Sessions" },
        { id: 9, type: "Subject Additional Sessions" },
    ];

    useEffect(() => {
        // function deleteObjectByName(name, inputArray) {
        //     const updatedArray = [...inputArray];
        //     const indexToDelete = updatedArray.findIndex(
        //         (item) => item.categoryName === name
        //     );

        //     if (indexToDelete !== -1) {
        //         updatedArray.splice(indexToDelete, 1);
        //     }

        //     return updatedArray;
        // }
        // const findValue = subEnroll.length;
        // console.log("findValue", findValue);
        // const updatedArray = deleteObjectByName(
        //     findValue,
        //     projectCategoriesPreData
        // );

        const levelKey = subEnroll?.length;

        const myarr = projectCategoriesPreData;
        const index = myarr.findIndex((item) => item.id === levelKey);

        if (levelKey === 0) {
            setFilteredCategories(projectCategoriesPreData);
        } else {
            const newArray = myarr.slice(index + 1);
            setFilteredCategories(newArray);
        }
    }, [projectCategoriesPreData]);

    const formik = useFormik({
        initialValues: {
            sessionsTypeId: "",
            categoryId: "",
            noOfSessions: "",
            // baseCourseId: 0,
        },
        onSubmit: (values) => {
            const data = {
                subjectEnrollmentId: subjectEnrollmentId,
                detailedSubjectEnrollments: [values],
            };
            dispatch(addSubjectEnrollmentDetailed(data));
        },
    });

    return (
        <div>
            <GlobalDialog
                title="Add Details"
                open={open}
                setOpen={setOpen}
                fullWidth
                closeButton={true}
                maxWidth={"md"}
                popupButton={
                    <AddButton
                        action={() => {
                            dispatch(getProjectCategories());
                            setOpen(true);
                        }}
                    >
                        Add Details
                    </AddButton>
                }
                content={
                    <>
                        {courseEnrollmentDtldId ? (
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box>
                                        <Box>
                                            <Stack
                                                direction={"column"}
                                                spacing={2}
                                            >
                                                {foundationId === 2 ? (
                                                    <GlobalSelectField
                                                        options={{
                                                            name: "categoryId",
                                                            formik,
                                                            sx: {
                                                                width: "100%",
                                                            },
                                                            label: "Level",
                                                            data: filterdCategories,
                                                            keys: {
                                                                id: "id",
                                                                value: "name",
                                                            },
                                                        }}
                                                    />
                                                ) : null}

                                                <TextField
                                                    name="noOfSessions"
                                                    label="No of Sessions"
                                                    fullWidth
                                                    value={
                                                        formik.values
                                                            .noOfSessions
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                                <GlobalSelectField
                                                    options={{
                                                        name: "sessionsTypeId",
                                                        formik,
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                        label: "Session Type",
                                                        data: purchaseType,
                                                        keys: {
                                                            id: "id",
                                                            value: "type",
                                                        },
                                                    }}
                                                />
                                            </Stack>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                mt: 2,
                                            }}
                                        >
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <CancelButton
                                                    action={() =>
                                                        setOpen(false)
                                                    }
                                                >
                                                    Cancel
                                                </CancelButton>
                                                <SaveButton type="submit">
                                                    Save
                                                </SaveButton>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        ) : (
                            <Alert severity="warning">
                                No Course Enrollments Details Found..!
                            </Alert>
                        )}
                    </>
                }
            />
        </div>
    );
};
