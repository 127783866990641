import React, { useState } from 'react'
import { ActiveButton, InactiveButton } from '../../mui/Buttons/Buttons'
import { useDispatch, useSelector } from 'react-redux'
import { savePaginationData } from '../../../redux/features/globalDatagridSlice'
import { getAllCourses, updateCourse } from '../../../redux/features/courseSlice'
import { useSnackbar } from 'notistack'

const ActiveOrInactiveButton = ({status,id}) => {
    const { enqueueSnackbar } = useSnackbar()
    const { paginationData } = useSelector((state) => state.globalDataGrid)
    const { loading } = useSelector((store) => store.course)
     const dispatch=useDispatch()
     const update=(sts)=>{
     dispatch(updateCourse({active:sts,id:id})).then((res) => {
        if (res.payload.status === 'error') {
          enqueueSnackbar(res.payload.message, {
            variant: 'error',
          })
        } else if (res.payload.status === 'success') {
          dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
          dispatch(getAllCourses(paginationData))
          enqueueSnackbar(res.payload.message, {
            variant: 'success',
          })
        }
      })
     }

  return (
    <>
    {status==1 ? (
        <InactiveButton disabled={loading} action={()=>update(0)}  size={"small"}>Inactive</InactiveButton>
    ) :(
        <ActiveButton disabled={loading} action={()=>update(1)} size={"small"}>Active</ActiveButton>
    )}  
    </>
   
  )
}

export default ActiveOrInactiveButton