import React from "react";
import { TerminationClearance } from "../AdmissionRegister/AdmissionStatus/TerminationClearance";
import useResponsive from "../../../../Hooks/useResponsive";
import { Form, FormikProvider, useFormik } from "formik";
import {
    Box,
    Card,
    CardContent,
    Chip,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Rating,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { styled } from "@mui/material/styles";
import GlobalSelectField from "../../../mui/GlobalSelectField";
import { UpdateButton } from "../../../mui/Buttons/Buttons";

export const Switches = ({
    content,
    label,
    firstLabel,
    secondLabel,
    setSwitch,
}) => {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    return (
        <Chip
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                padding: smUp ? 2 : 1,
            }}
            size={smUp ? "large" : "small"}
            variant="outlined"
            color="primary"
            icon={
                <Icon icon="ei:arrow-left" width="25" height="25" rotate={2} />
            }
            label={
                <>
                    <FormGroup>
                        <FormControlLabel
                            label={
                                <Typography fontSize={smUp ? null : 13}>
                                    {label}:-
                                </Typography>
                            }
                            labelPlacement="start"
                            control={
                                <>
                                    {smUp ? (
                                        <Typography
                                            fontSize={smUp ? null : 10}
                                            fontWeight="bolder"
                                        >
                                            {firstLabel}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}

                                    {<>{setSwitch}</>}
                                    {smUp ? (
                                        <Typography
                                            fontSize={smUp ? null : 10}
                                            fontWeight="bolder"
                                        >
                                            {secondLabel}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </>
                            }
                        />
                    </FormGroup>
                </>
            }
        />
    );
};

const MyCard = ({ rating, title1, title2, remark }) => {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");

    return (
        <Card sx={{ minWidth: "100%" }}>
            <CardContent>
                <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                >
                    {title1}
                </Typography>
                <Box>
                    <Rating
                        size={smUp ? "large" : "small"}
                        name="customized-10"
                        value={rating}
                        max={10}
                        readOnly
                    />
                </Box>

                <Typography variant="body2">{title2}</Typography>
                <Box padding={1}>
                    <Chip variant="outlined" color="primary" label={remark} />
                </Box>
            </CardContent>
        </Card>
    );
};

const data = {
    student: 5,
    parent: 7,
    tutor: 4,
    studentRemarks: "GOOD",
    parentRemarks: "GOOD",
    tutorRemarks: "GOOD",
};

export const Forms = () => {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
    });

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        "& .MuiSwitch-track": {
            borderRadius: 22 / 2,
            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 16,
            },
            "&:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main)
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            "&:after": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main)
                )}" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /></svg>')`,
                right: 12,
            },
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 16,
            height: 16,
            margin: 2,
        },
    }));
    return (
        <div>
            <Box>
                <TerminationClearance />
                <Box sx={{ width: "100%", backgroundColor: "grey.200" }}>
                    <Box
                        sx={{
                            paddingTop: 9,
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <Typography variant="h4">Rating...</Typography>
                    </Box>
                    <Divider
                        sx={{ borderStyle: "dashed", borderBottomWidth: 3 }}
                    />
                    <Box sx={{ padding: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={4}>
                                <MyCard
                                    title1={"Student Rating"}
                                    rating={data.student}
                                    title2={"Student Remarks"}
                                    remark={
                                        data?.studentRemarks
                                            ? data.studentRemarks
                                            : "No data Found...!"
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MyCard
                                    title1={"Parent Rating"}
                                    rating={data.parent}
                                    title2={"Parent Remarks"}
                                    remark={
                                        data?.parentRemarks
                                            ? data.parentRemarks
                                            : "No data Found...!"
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MyCard
                                    title1={"Tutor Rating"}
                                    rating={data.tutor}
                                    title2={"Tutor Remarks "}
                                    remark={
                                        data?.tutorRemarks
                                            ? data.tutorRemarks
                                            : "No data Found...!"
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    {/* --------------------------------------------------- */}
                    <Box padding={smUp ? 3 : 1}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                                padding: smUp ? 5 : 1,
                            }}
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12} lg={6}>
                                    <Switches
                                        label="Admission Fee"
                                        firstLabel="Paid"
                                        setSwitch={
                                            <>
                                                <Android12Switch
                                                    name="admissionFeeStatus"
                                                    checked={Boolean(
                                                        formik.values
                                                            .admissionFeeStatus
                                                    )}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.checked ===
                                                            true
                                                        ) {
                                                            formik.setFieldValue(
                                                                "admissionFeeStatus",
                                                                1
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                "admissionFeeStatus",
                                                                0
                                                            );
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                        secondLabel=" Not Paid"
                                    />
                                </Grid>
                                {/* ---------------------------------------------------------------- */}
                                <Grid item xs={12} md={12} lg={6}>
                                    <Switches
                                        label="Course Fee"
                                        firstLabel="Paid"
                                        setSwitch={
                                            <>
                                                <Android12Switch
                                                    name="courseFeeStatus"
                                                    checked={Boolean(
                                                        formik.values
                                                            .courseFeeStatus
                                                    )}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.checked ===
                                                            true
                                                        ) {
                                                            formik.setFieldValue(
                                                                "courseFeeStatus",
                                                                1
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                "courseFeeStatus",
                                                                0
                                                            );
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                        secondLabel=" Not Paid"
                                    />
                                </Grid>

                                {/* ---------------------------------------------------------------- */}

                                <Grid item xs={12} md={12} lg={6}>
                                    <Switches
                                        label="Certificate"
                                        firstLabel="Issued"
                                        setSwitch={
                                            <>
                                                <Android12Switch
                                                    name="certificateStatus"
                                                    checked={Boolean(
                                                        formik.values
                                                            .certificateStatus
                                                    )}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.checked ===
                                                            true
                                                        ) {
                                                            formik.setFieldValue(
                                                                "certificateStatus",
                                                                1
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                "certificateStatus",
                                                                0
                                                            );
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                        secondLabel=" Not Issued"
                                    />
                                </Grid>

                                {/* ---------------------------------------------------------------- */}
                                <Grid item xs={12} md={12} lg={6}>
                                    <Switches
                                        label="Parent Freebie"
                                        firstLabel="Given"
                                        setSwitch={
                                            <>
                                                <Android12Switch
                                                    name="parentFreebieStatus"
                                                    checked={Boolean(
                                                        formik.values
                                                            .parentFreebieStatus
                                                    )}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.checked ===
                                                            true
                                                        ) {
                                                            formik.setFieldValue(
                                                                "parentFreebieStatus",
                                                                1
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                "parentFreebieStatus",
                                                                0
                                                            );
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                        secondLabel="Not Given"
                                    />
                                </Grid>

                                {/* ---------------------------------------------------------------- */}
                                <Grid item xs={12} md={12} lg={6}>
                                    <Switches
                                        label="Student Freebie"
                                        firstLabel="Given"
                                        setSwitch={
                                            <>
                                                <Android12Switch
                                                    name="studentFreebieStatus"
                                                    checked={Boolean(
                                                        formik.values
                                                            .studentFreebieStatus
                                                    )}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.checked ===
                                                            true
                                                        ) {
                                                            formik.setFieldValue(
                                                                "studentFreebieStatus",
                                                                1
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                "studentFreebieStatus",
                                                                0
                                                            );
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                        secondLabel="Not Given"
                                    />
                                </Grid>

                                {/* ---------------------------------------------------------------- */}
                                <Grid item xs={12} md={12} lg={6}>
                                    <Switches
                                        label="CRO Retain Call"
                                        firstLabel="Called"
                                        setSwitch={
                                            <>
                                                <Android12Switch
                                                    name="croRetainCallStatus"
                                                    checked={Boolean(
                                                        formik.values
                                                            .croRetainCallStatus
                                                    )}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.checked ===
                                                            true
                                                        ) {
                                                            formik.setFieldValue(
                                                                "croRetainCallStatus",
                                                                1
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                "croRetainCallStatus",
                                                                0
                                                            );
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                        secondLabel="Not Called"
                                    />
                                </Grid>

                                {/* ---------------------------------------------------------------- */}
                                <Grid item xs={12} md={12} lg={6}>
                                    <Switches
                                        label="OGA Retain Call"
                                        firstLabel="Called"
                                        setSwitch={
                                            <>
                                                <Android12Switch
                                                    name="ogaRetainCallStatus"
                                                    checked={Boolean(
                                                        formik.values
                                                            .ogaRetainCallStatus
                                                    )}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.checked ===
                                                            true
                                                        ) {
                                                            formik.setFieldValue(
                                                                "ogaRetainCallStatus",
                                                                1
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                "ogaRetainCallStatus",
                                                                0
                                                            );
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                        secondLabel="Not Called"
                                    />
                                </Grid>

                                {/* ---------------------------------------------------------------- */}
                                <Grid item xs={12} md={12} lg={6}>
                                    <Switches
                                        label={"CRO Exit Call"}
                                        firstLabel="Called"
                                        setSwitch={
                                            <>
                                                <Android12Switch
                                                    //   size="small"
                                                    name="croExitCallStatus"
                                                    checked={false}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.checked ===
                                                            true
                                                        ) {
                                                            formik.setFieldValue(
                                                                "croExitCallStatus",
                                                                1
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                "croExitCallStatus",
                                                                0
                                                            );
                                                        }
                                                    }}
                                                />
                                            </>
                                        }
                                        secondLabel="Not Called"
                                    />
                                </Grid>

                                {/* ---------------------------------------------------------------- */}
                            </Grid>
                        </Box>
                    </Box>
                    <Box
                        width={"100%"}
                        padding={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        <GlobalSelectField
                            options={{
                                formik: formik,
                                name: "courseEnrollmentStatusId",
                                label: "Action",
                                sx: { width: "70%" },
                                data: "",

                                keys: {
                                    id: "id",
                                    value: "status",
                                },
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: 2,
                        }}
                    >
                        <UpdateButton>Upadate</UpdateButton>
                    </Box>
                </Box>
                {/* ------------------------------------------------------------ */}
            </Box>
        </div>
    );
};
