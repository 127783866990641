import { Button } from "@mui/material";
import { Icon } from "@iconify/react";
import React from "react";
import Page from "../../../../components/Page";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllDepartment } from "../../../../redux/features/departmentSlice";
import { Paper, Box } from "@mui/material";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { DEPARTMENT_TABLE_HEAD as columns } from "../../../../components/datagrid/Department/DepartmentTableHead";
import CreateDepartment from "./CreateDepartment";
import useResponsive from "../../../../Hooks/useResponsive";
import Wrapper from "../../../../components/Wrapper/Wrapper";

export default function Department() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const departments = useSelector((state) => state.departments.data);
    const loading = useSelector((state) => state.departments.loading);

    useEffect(() => {
        dispatch(getAllDepartment());
    }, []);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    return (
        <div>
            <Wrapper
                title=" Departments"
                Content={
                    <Page>
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    onClick={handleClickOpen}
                                    sx={{
                                        backgroundColor: "#7e4d8b45",
                                        color: "#7e4d8b",
                                    }}
                                >
                                    <Icon
                                        icon="carbon:group-presentation"
                                        width="25"
                                        height="25"
                                    />
                                    Add New Department
                                </Button>
                            </Box>
                            <Paper
                                elevation={3}
                                sx={{
                                    borderRadius: 1,
                                    width: "100%",
                                    marginTop: 3,
                                }}
                            >
                                <Box sx={{ width: "100%" }}>
                                    <DataGrid
                                        loading={loading}
                                        rows={departments}
                                        columns={columns}
                                        sx={{
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0,
                                        }}
                                        pageSize={gridSize}
                                        onPageSizeChange={(newGridSize) =>
                                            setGridSize(newGridSize)
                                        }
                                        autoHeight
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        getRowId={(row) => row.slNo}
                                        components={{
                                            Toolbar: CustomGridToolbar,
                                        }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: {
                                                    debounceMs: 500,
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </>
                    </Page>
                }
            />

            {/* --------------create-department------------------------------- */}
            <CreateDepartment open={open} setOpen={setOpen} />
        </div>
    );
}
