import React from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";
import { Form, Forms } from "./Forms";

export const TcPopup = () => {
    const [open, setopen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Termination Clearance"
                open={open}
                setOpen={setopen}
                fullWidth
                maxWidth={"lg"}
                closeButton={true}
                popupButton={
                    <Button
                        onClick={() => {
                            setopen(true);
                        }}
                    >
                        View
                    </Button>
                }
                content={<Forms />}
            />
        </div>
    );
};
