import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";
import { Divider, Paper, Box, TextField } from "@mui/material";
import { approveAttendance } from "../../../redux/features/attendanceSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import useResponsive from "../../../Hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ApproveActionDialogue(params) {
    const smUp = useResponsive("up", "sm");

    const disptch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const loading = useSelector((state) => state.attendance.loading);
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState("");
    const [remark, setRemark] = React.useState("");
    const remarks = (e) => {
        setData(e.target.value);
        setRemark(e.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleApprove = () => {
        const datas = {
            remarks: data,
            status: 1,
            year: params.params.row.year,
            month: params.params.row.month,
            empId: params.params.row.userId,
        };

        disptch(approveAttendance(datas)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
                setOpen(false);
                setData("");
            }
        });
    };

    return (
        <div>
            {params.params.isEditable === true ? (
                <Button onClick={handleClickOpen}>
                    <Icon icon="mdi:approve" width="25" height="25" />
                    Approve
                </Button>
            ) : (
                <Button sx={{ color: "#00ab55" }}>
                    <Icon icon="mdi:approve" width="25" height="25" />
                    Approved
                </Button>
            )}

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Approve Attendance"}</DialogTitle>
                <Divider />

                <DialogContent>
                    <Box>
                        {smUp && (
                            <Paper
                                elevation={3}
                                sx={{
                                    width: 500,
                                    backgroundColor: "grey.200",
                                    padding: 3,
                                }}
                            >
                                <Box>
                                    <TextField
                                        fullWidth
                                        label="Remark"
                                        placeholder="Enter Remark"
                                        multiline
                                        rows={2}
                                        required
                                        onChange={remarks}
                                    />
                                </Box>
                            </Paper>
                        )}
                        {!smUp && (
                            <Paper
                                elevation={3}
                                sx={{
                                    width: "100%",
                                    backgroundColor: "grey.200",
                                    padding: 2,
                                }}
                            >
                                <Box>
                                    <TextField
                                        fullWidth
                                        label="Remark"
                                        placeholder="Enter Remark"
                                        multiline
                                        rows={2}
                                        required
                                        onChange={remarks}
                                    />
                                </Box>
                            </Paper>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ backgroundColor: "#eff4f9", color: "#2f72b3" }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        disabled={remark === "" ? true : false}
                        sx={{ backgroundColor: "#ade1c9", color: "#00ab55" }}
                        onClick={handleApprove}
                    >
                        <Icon icon="mdi:approve" width="20" height="20" />{" "}
                        {loading ? "Approving..." : "Approve"}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
