import React from "react";
import Wrapper from "../../../../Wrapper/Wrapper";
import { Box, Stack } from "@mui/material";
import { Icon } from "@iconify/react";
import { CancelButton, UpdateButton } from "../../../../mui/Buttons/Buttons";
import { useFormik, FormikProvider, Form } from "formik";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import { useSelector, useDispatch } from "react-redux";
import {
    getDetailedCourseEnrollementsByCourse,
    updateCourseEnrollmentDetailed,
} from "../../../../../redux/features/croSlice";
import { useSnackbar } from "notistack";

export const EditCourseEnrollmentDetailed = ({
    data,
    setOpen,
    courseEnrollmentId,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    console.log("data", data);
    const [view, setView] = React.useState("Table");

    const {
        classes,
        mediums,
        syllabuses,
        invoicesByCourseEnrollmentPreData,
        loading,
        detailedCourseEnrollementsByStudent,
        detailedCourseEnrollementsByCourse,
    } = useSelector((state) => state.cro);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseEnrollmentDetailedId: data.courseEnrollmentDetailedId,
            classId: data?.classId || "",
            mediumId: data?.mediumId || "",
            syllabusId: data?.syllabusId || "",
        },
        onSubmit: (values, { resetForm }) => {
            dispatch(updateCourseEnrollmentDetailed(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    // resetForm();
                    dispatch(
                        getDetailedCourseEnrollementsByCourse(
                            courseEnrollmentId
                        )
                    );
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    return (
        <div>
            <Wrapper
                Content={
                    <>
                        <Box>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box
                                        sx={{
                                            padding: 2,
                                            backgroundColor: "grey.200",
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Stack direction={"column"} spacing={2}>
                                            <GlobalSelectField
                                                options={{
                                                    name: "classId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                        "& .MuiInputBase-root":
                                                            {
                                                                height: 50,
                                                            },
                                                    },
                                                    label: "Class",
                                                    data: classes,
                                                    keys: {
                                                        id: "classId",
                                                        value: "className",
                                                    },
                                                }}
                                            />
                                            <GlobalSelectField
                                                options={{
                                                    name: "mediumId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                        "& .MuiInputBase-root":
                                                            {
                                                                height: 50,
                                                            },
                                                    },
                                                    label: "Medium",
                                                    data: mediums,
                                                    keys: {
                                                        id: "id",
                                                        value: "name",
                                                    },
                                                }}
                                            />
                                            <GlobalSelectField
                                                options={{
                                                    name: "syllabusId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                        "& .MuiInputBase-root":
                                                            {
                                                                height: 50,
                                                            },
                                                    },
                                                    label: "Syllabus",
                                                    data: syllabuses,
                                                    keys: {
                                                        id: "id",
                                                        value: "name",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                pt: 2,
                                            }}
                                        >
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <CancelButton
                                                    action={() =>
                                                        setOpen(false)
                                                    }
                                                >
                                                    Cancel
                                                </CancelButton>
                                                <UpdateButton type="submit">
                                                    {loading
                                                        ? "Updating..."
                                                        : "Update"}
                                                </UpdateButton>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Box>
                    </>
                }
            />
        </div>
    );
};
