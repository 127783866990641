import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
const initialState = {
  PSTcity: "",
  PSTcountry: "",
  PSTdistrict: "",
  PSThouseName: "",
  PSTpinCode: "",
  PSTpostOffice: "",
  PSTstate: "",
  PSTstreetOrArea: "",
  aadhaarCardNo: "",
  accedemicRemarks: "",
  bloodGroup: "",
  city: "",
  country: "",
  courseId: "",
  courseStatus: "",
  courseStream: "",
  courseTypeId: "",
  district: "",
  dob: "",
  doj: "",
  email: "",
  esicNo: "",
  houseName: "",
  maritalStatus: "",
  mobileNo: "",
  name: "",
  officialEmail: "",
  officialMobileNo: "",
  panCardNo: "",
  parentContactNo: "",
  parentName: "",
  pfNo: "",
  photo: "",
  pinCode: "",
  postOffice: "",
  sex: "",
  state: "",
  streetOrArea: "",
  suggestion: "",
  userId: "",
  yearOfPass: "",
  isLoading: false,
  isLogin: false,
};

// initial state for pre profile
const initialPreProfile = {
  courseStream: [],
  course: [],
  courseType: [],
  isLoading: false,
};

// getting user details
export const getProfile = createAsyncThunk("profile/get_profile", async (e) => {
  const res = await apiRequest({
    method: "get",
    url: "users",
  });

  return res;
});

// getting preProfile

export const getPreprofile = createAsyncThunk(
  "preprofile/get_Preprofile",
  async (e) => {
    const res = await apiRequest({
      method: "get",
      url: "users/pre-profile-details",
    });

    return res;
  }
);

export const updateProfile = createAsyncThunk("update_profile", async (e) => {
  const res = await apiRequest({
    method: "put",
    url: "users",
    data: e.body,
  });

  return res;
});

export const updateEmployee = createAsyncThunk(
  "profile/updateEmployee",
  async (values) => {
    const res = await apiRequest({
      method: "put",
      url: "users",
      data: values,
    });

    return res;
  }
);
export const userBankDetails = createAsyncThunk(
  "profile/userBankDetails",
  async (values) => {
    const res = await apiRequest({
      method: "post",
      url: "users/bank-details",
      data: values,
    });

    return res;
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    getProfilePending: (state) => {
      state.isLoading = true;
    },
    getProfileSuccess: (state) => {
      state.isLoading = false;
    },
    getProfileReject: (state) => {
      state.isLoading = false;
    },
    clearProfile: (state) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload.data.profile,
        isLoading: false,
        isLogin: true,
      };
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

// creating preprofile slice
const preprofileSlice = createSlice({
  name: "preprofile",
  initialState: initialPreProfile,
  reducers: {
    preProfilePending: (state) => {
      state.isLoading = true;
    },
    preProfileFullfilled: (state) => {
      state.isLoading = false;
    },
    preProfileRejected: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPreprofile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPreprofile.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload.data,
        isLoading: false,
      };
    });
    builder.addCase(getPreprofile.rejected, (state, action) => {
      state.isLoading = false;
    });

    // -----------------------------------------------
    builder.addCase(updateEmployee.pending, (state) => {
      state.isloading = true;
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      state.isloading = false;
      state.data = action.payload.data;
    });
    builder.addCase(updateEmployee.rejected, (state, action) => {
      state.isloading = false;
      state.error = action.error.message;
    });
    //   -------------------user-bank-----------------------------
    builder.addCase(userBankDetails.pending, (state) => {
      state.isloading = true;
    });
    builder.addCase(userBankDetails.fulfilled, (state, action) => {
      state.isloading = false;
      state.data = action.payload.data;
    });
    builder.addCase(userBankDetails.rejected, (state, action) => {
      state.isloading = false;
      state.error = action.error.message;
    });
  },
});

// creating update profile slice....

export default profileSlice.reducer;
export const {
  getProfilePending,
  getProfileSuccess,
  getProfileReject,
  clearProfile,
} = profileSlice.actions;
export const preprofileReducer = preprofileSlice.reducer;
