import { Icon } from "@iconify/react";
import { Box, Button, Paper } from "@mui/material";
import React from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { useDispatch, useSelector } from "react-redux";
import { getTpVerificationDataDetailed } from "../../../../redux/features/croSlice";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../GridToolBar";
import { useState } from "react";
import { TP_VERIFICATION_DETAILED_TABLE_HEAD } from "./TpVerificationDetailedTableHead";
import { UpdateTpVerification } from "./UpdateTpVerification";
import { useEffect } from "react";

export const TpVerificationDetailed = ({ params }) => {
    const dispatch = useDispatch();
    const { tpVerificationDataDetailed, loading } = useSelector(
        (state) => state.cro
    );
    const [gridSize, setGridSize] = useState(5);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open == true) {
            dispatch(getTpVerificationDataDetailed(params));
        }
    }, [open]);

    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                title="Tp Verification"
                maxWidth={"md"}
                closeButton={true}
                popupButton={
                    <Box padding={1}>
                        <Button
                            size="small"
                            sx={{
                                backgroundColor: "#c8facd",
                                color: "#0db05d",
                                margin: 2,
                            }}
                            onClick={() => {
                                console.log(params);
                                setOpen(true);
                                // dispatch(
                                //     getTpVerificationDataDetailed(
                                //         params.tpInvoiceId
                                //     )
                                // );
                            }}
                        >
                            <Icon icon="mdi:approve" width="18" height="18" />
                            Verified
                        </Button>
                    </Box>
                }
                content={
                    <>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <UpdateTpVerification
                                poupStatus={setOpen}
                                tpInvoiceId={params.tpInvoiceId}
                            />
                        </Box>
                        <Paper elevation={3}>
                            <Box sx={{ width: "100%", padding: 0 }}>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    loading={loading}
                                    autoHeight
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    getRowHeight={() => "auto"}
                                    rows={tpVerificationDataDetailed}
                                    columns={
                                        TP_VERIFICATION_DETAILED_TABLE_HEAD
                                    }
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
};
