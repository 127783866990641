import { Button } from "@mui/material";
import { Icon } from "@iconify/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
    deletePreferrence,
    getBaseCoursePreferedSchedule,
} from "../../../redux/features/sessionSlice";
import { useSnackbar } from "notistack";

const PreferrenceAction = ({ params }) => {
    const { singleStudentDetails } = useSelector((state) => state.sessions);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEdit = () => {
        navigate(`/schedules/edit-schedules-pref/${params.row.id}`, {
            state: { ...singleStudentDetails, row: params.row },
        });
    };
    const handleAgree = () => {
        handleClose();
        dispatch(deletePreferrence(params.row.id)).then((res) => {
            if (res.payload.status === "success") {
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });

                dispatch(
                    getBaseCoursePreferedSchedule(
                        singleStudentDetails.subjectEnrollmentId
                    )
                );
            } else {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            }
        });
    };

    return (
        <div>
            <Button onClick={handleEdit}>
                <Icon
                    icon="material-symbols:edit-note-rounded"
                    width="45"
                    height="45"
                />
            </Button>
            <Button onClick={handleClickOpen}>
                <Icon
                    icon="material-symbols:delete"
                    width="35"
                    height="35"
                    color="red"
                />
            </Button>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure want to delete ?
                    </DialogTitle>

                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={handleAgree} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

export default PreferrenceAction;
