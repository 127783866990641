import React, { useState } from 'react'
import GlobalDialog from '../../mui/Dialogue/GlobalDialog'
import { Button, Typography } from '@mui/material'
import { Icon } from '@iconify/react/dist/iconify.js'
import { ViewButton } from '../../mui/Buttons/Buttons'

const ClickToShowImage = ({ url }) => {
  const [open, setOpen] = useState(false)

  return (
    <GlobalDialog
      open={open}
      setOpen={setOpen}
      maxWidth={'sm'}
      fullWidth
      closeButton={true}
      popupButton={
        <>  
           <>
          <ViewButton action={()=>setOpen(true)}>View</ViewButton>
        </>
        </>
      }
      content={
        <>
          {url ? (
            <img
              src={url}
              alt="course image"
            />
          ) : (
            <>
              <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
                Image is not available.
              </Typography>
            </>
          )}
        </>
      }
    />
  )
}

export default ClickToShowImage
