import { Assign } from "./Assign";
import { SroRemark } from "./SroRemark";
import ToolTip from "../../../ToolTip/ToolTip";
import { AssignCroSatus } from "./AssinCro";
import { Typography } from "@mui/material";
import { JqaStatus } from "./JqaStatus";
const profile = JSON.parse(localStorage.getItem("cred"))?.profile;
export const CRM_LEADS_TABLE_HEAD = [
  {
    field: "serialNumber",
    headerName: "slNo",
  },

  {
    field: "studentName",
    headerName: "Student Name",
    width: 250,
  },
  {
    field: "className",
    headerName: "Class",
    width: 150,
  },
  {
    field: "courseName",
    headerName: "Course Name",
    width: 250,
  },
  {
    field: "contactNumber",
    headerName: "Contact",
    width: 150,
  },
  {
    field: "place",
    headerName: "Place",
    width: 150,
  },
  {
    field: "leadType",
    headerName: "Lead Type",
    width: 250,
  },
  {
    field: "leadCameOn",
    headerName: "Came On",
    width: 200,
  },
  {
    field: "ogaAdmissionConfirmedOn",
    headerName: "OGA Admission Confirmed On",
    width: 220,
  },
  {
    field: "oga",
    headerName: "OGA",
    width: 180,
  },
  {
    field: "ogaRemarks",
    headerName: "OGA Remarks",
    width: 300,
    renderCell: (params) => {
      return (
        <ToolTip
          expand={params.row.ogaRemarks}
          content={params.row.ogaRemarks}
        />
      );
    },
  },
  {
    field: "sroRemarksDetailed",
    headerName: "OGA Remarks Detailed",
    width: 180,
    disableClickEventBubbling: true,
    renderCell: (params) => <SroRemark {...params.row} />,
  },
  {
    field: "crmLeadStatus",
    headerName: "CRM Status",
    width: 170,
    disableClickEventBubbling: true,
    renderCell: (params) => <AssignCroSatus {...params.row} />,
  },

  {
    field: "croLeadStatusName",
    headerName: "CRO Status",
    width: 200,
    renderCell: (params) => {
      return (
        <Typography color={params.row.croLeadStatusColor}>
          {params.row.croLeadStatusName}
        </Typography>
      );
    },
  },

  {
    field: "assignCro",
    headerName: "Assign CRO",
    width: 250,
    disableClickEventBubbling: true,
    renderCell: (params) => {
      return (
        <>{profile?.departmentId == 3 ? <Assign {...params.row} /> : null}</>
      );
    },
  },

  {
    field: "croLastUpdatedOn",
    headerName: "CRO Last Updated On",
    width: 200,
  },
  {
    field: "jqa",
    headerName: "JQA",
    width: 200,
    renderCell: (params) => <JqaStatus {...params.row} />,
  },
  {
    field: "jqaLeadStatusName",
    headerName: "JQA Status",
    width: 200,
    renderCell: (params) => {
      return (
        <Typography color={params.row.jqaLeadStatusColor}>
          {params.row.jqaLeadStatusName}
        </Typography>
      );
    },
  },

  {
    field: "booksAndPublications",
    headerName: "Books and Publications",
    width: 200,
  },
  {
    field: "levelsOpted",
    headerName: "Levels Opted",
    width: 200,
  },
  {
    field: "sessionCount",
    headerName: "Session Count",
    width: 200,
  },
  {
    field: "academicCalendar",
    headerName: "Academic Calendar",
    width: 200,
    renderCell: (params) => {
      if (params.row.academicCalendar == 1) {
        return "YES";
      } else if (params.row.academicCalendar == 0) {
        return "NO";
      } else {
        return params.row.academicCalendar;
      }
    },
  },
  {
    field: "raRemarks",
    headerName: "RA Remarks",
    width: 200,
  },
];
