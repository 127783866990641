import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ScheduleRoute = ({ children }) => {
    const { role } = useSelector((state) => state);
    return children;
};

export default ScheduleRoute;
