import { id } from "date-fns/locale";
import React from "react";

const CreateRemaining = (params) => {
    if (params.field === "CL") {
        return <div>{`${params.row.CL}  ( ${params.row.clRemaining} )`}</div>;
    }
    if (params.field === "SL") {
        return <div>{`${params.row.SL}  ( ${params.row.slRemaining} )`}</div>;
    }
};

export default CreateRemaining;
