import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const AddButtonCroStudents = ({ row }) => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(`/my-tasks/cro-student-enroll/${row.courseEnrollmentId}`);
    };
    return (
        <Button variant="contained" onClick={handleNavigate}>
            Add
        </Button>
    );
};

export default AddButtonCroStudents;
