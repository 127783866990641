import React, { useState } from "react";
import Page from "../../../components/Page";
import { Box, Paper, TextField, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { CAMPAIGN_TBL_HEAD } from "../../../components/datagrid/CRM/CampaingnAnalyticsTableHead";
import BasicButton from "../../../components/mui/Button";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CrmCampaignAnalytics = () => {
    const [gridSize, setGridSize] = useState(5);

    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Campaign Analytics
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                        }}
                    >
                        <BasicButton
                            variant="contained"
                            sx={{
                                display: "flex",
                                gap: 1,
                            }}
                        >
                            Unassigned Leads :-0
                        </BasicButton>
                    </Box>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                p: 2,
                                gap: 2,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="From Date"
                                    inputFormat="YYYY/MM/DD"
                                    // onChange={handleDateDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                        />
                                    )}
                                />
                                <DesktopDatePicker
                                    label="To Date"
                                    inputFormat="YYYY/MM/DD"
                                    // onChange={handleDateDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <GlobalSelectField
                                options={{
                                    label: "Course",
                                }}
                            />
                            <GlobalSelectField
                                options={{
                                    label: "lead Type",
                                }}
                            />
                        </Box>

                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                rows={[]}
                                columns={CAMPAIGN_TBL_HEAD}
                                components={{ Toolbar: CustomGridToolbar }}
                                autoHeight
                                sx={{
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1,
                                    },
                                }}
                                // getRowId={(row) => row.serialNumber}
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default CrmCampaignAnalytics;
