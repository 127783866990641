import PreferrenceAction from "./PreferrenceAction";

export const PREFERED_SCHEDULE_HEAD = [
    {
        field: "serialNumber",
        flex: 0,
        minWidth: 40,
        headerName: "Serial No",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "weekDay",
        flex: 1,
        minWidth: 40,
        headerName: "Day",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "fromTime",
        flex: 1,
        minWidth: 40,
        headerName: "From Time",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "toTime",
        flex: 1,
        minWidth: 40,
        headerName: "To Time",
        type: "number",
        headerAlign: "left",
        align: "left",
    },

    {
        field: "Action",
        flex: 1,
        minWidth: 40,
        headerName: "Action",
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => <PreferrenceAction params={params} />,
    },
];
