import { Autocomplete, Box, Paper, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";
import { CRO_LEADS_TABLE_HEAD } from "../../../components/datagrid/myworkspace/cro/CroLeadsTableHead";
import { useDispatch, useSelector } from "react-redux";
import { getCroLeads } from "../../../redux/features/croSlice";
import CustomDataGrid from "../../../components/mui/DataGrid/CustomDataGrid";
import { CroLeadsDetails } from "../../../components/datagrid/myworkspace/cro/CroLeads/CroLeadsDetails";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { CroLeadsActions } from "../../../components/datagrid/myworkspace/cro/CroLeads/CroLeadsActions";
import { getCourseAvailableData } from "../../../redux/features/researchSlice";
import { ListOfDetails } from "../../../components/mui/DataGrid/ListOfDetails";

const CroLeads = () => {
    const dispatch = useDispatch();
    const { croLeads, loading } = useSelector((state) => state.cro);
    const { courseAvailablePreData } = useSelector((state) => state.research);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [course, setCourse] = React.useState("");
    const [filteredCourse, setFilteredCourse] = React.useState([]);
    const [selectedData, setSelectedData] = React.useState({});

    useEffect(() => {
        dispatch(getCroLeads());
        dispatch(getCourseAvailableData());
    }, []);

    useEffect(() => {
        const filteredData = croLeads.filter((item) => {
            if (course) {
                return item.courseName === course.option.courseName;
            } else {
                return item;
            }
        });
        setFilteredCourse(filteredData);
    }, [course, croLeads]);

    const Head = [
        {
            field: "studentName",
            headerName: "Student Name",
            width: 250,
        },
    ];
    const datas = selectedRows ? selectedRows[0] : {};
    useEffect(() => {
        if (datas) {
            const {
                serialNumber,
                croAssignmentId,
                courseEnrollmentId,
                courseEnrollmentStatus,
                classId,
                syllabusId,
                mediumId,
                projectId,
                croLeadStatusId,
                croLeadStatusColor,
                ...newData
            } = datas;
            setSelectedData(newData);
        }
    }, [datas]);

    return (
        <Wrapper
            title="CRO Leads"
            Content={
                <>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={courseAvailablePreData}
                                sx={{ width: 300 }}
                                getOptionLabel={(option) => option.courseName}
                                onChange={(e, option, value, newValue) => {
                                    setCourse(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Course" />
                                )}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Paper
                            elevation={3}
                            sx={{ borderRadius: 1, width: "100%" }}
                        >
                            {/* --------------------------------------------------------------- */}
                            <Box>
                                <CustomDataGrid
                                    loading={loading}
                                    data={filteredCourse}
                                    rowId={"serialNumber"}
                                    columns={CRO_LEADS_TABLE_HEAD}
                                    Head={Head}
                                    setSelectedRows={setSelectedRows}
                                    selectedRows={selectedRows}
                                    chipValue={selectedRows[0]?.admissionNo}
                                    label="AdNo"
                                    name={selectedRows[0]?.studentName}
                                    details={
                                        <ListOfDetails data={selectedData} />
                                    }
                                    actions={
                                        <CroLeadsActions
                                            data={selectedRows[0]}
                                        />
                                    }
                                />
                            </Box>
                        </Paper>
                    </Box>
                </>
            }
        />
    );
};

export default CroLeads;
