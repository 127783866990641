import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import BasicTextField from "../../../mui/TextField";

const TrainerFeedbackForm = () => {
    return (
        <Box
            sx={{
                minWidth: "600px",
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontSize: "20px",
                    pb: "20px",
                    mb: "10px",
                    borderBottom: "1px solid #000",
                }}
            >
                Trainer Feedback
            </Typography>
            <Stack
                gap={2}
                sx={{
                    borderBottom: "1px solid #000",
                    pb: "15px",
                    mb: "20px",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "20px",
                        textAlign: "center",
                    }}
                >
                    CRO COMMUNICATION
                </Typography>

                <BasicTextField label="Academic Requirements" disabled />
                <BasicTextField label="Parent-Student Requirement" disabled />
                <BasicTextField label="Method of Communication" disabled />
                <BasicTextField label="Proper and Timely Response" disabled />
                <BasicTextField label="Proper Tracking" disabled />
                <BasicTextField
                    label="Trainer Management (Attendance, Salary)"
                    disabled
                />
                <BasicTextField label="Overall Satisfaction" disabled />
            </Stack>

            <Stack
                gap={2}
                sx={{
                    borderBottom: "1px solid #000",
                    pb: "15px",
                    mb: "20px",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "20px",
                        textAlign: "center",
                    }}
                >
                    STUDENT REMARKS
                </Typography>

                <BasicTextField label="Courteousness" disabled />
                <BasicTextField label="Learning Ability" disabled />
                <BasicTextField label="Student Progress" disabled />
                <BasicTextField label="Portion Coverage" disabled />
                <BasicTextField label="HomeWorks/Activities Status" disabled />
                <BasicTextField label="Exam/Dictation" disabled />
                <BasicTextField
                    label="Academic Calendar Tracking"
                    multiline
                    rows={3}
                    disabled
                />
            </Stack>

            <Stack
                gap={2}
                sx={{
                    borderBottom: "1px solid #000",
                    pb: "15px",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: "20px",
                        textAlign: "center",
                    }}
                >
                    FEEDBACK FROM TRAINER
                </Typography>

                <BasicTextField label="Student Progress" disabled />
                <BasicTextField label="Interval Feedback" disabled />
                <BasicTextField
                    label="Material Feedback"
                    disabled
                    multiline
                    rows={3}
                />
                <BasicTextField
                    label="Suggestions (if any)"
                    disabled
                    multiline
                    rows={3}
                />
            </Stack>
        </Box>
    );
};

export default TrainerFeedbackForm;
