import React from "react";
import { CustomRow, Mytable, Rows } from "../../../mui/DataGrid/Details";
import { Box, Button, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import EvaluationActionPopUp from "./EvaluationActionPopUp";
const CroEvaluationsActions = ({ data, filter }) => {
    return (
        <Box>
            {filter === "compleated" && (
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <CustomRow
                                    label="Action"
                                    component={
                                        data?.croEvaluationAcknowledged > 0 ? (
                                            <p style={{ color: "green" }}>
                                                Acknowledged
                                            </p>
                                        ) : (
                                            <EvaluationActionPopUp
                                                data={data}
                                            />
                                        )
                                    }
                                />
                            </>
                        }
                    />
                </Grid>
            )}
        </Box>
    );
};

export default CroEvaluationsActions;
