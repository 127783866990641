import { useState, forwardRef } from "react";
import IconButton from "@mui/material/IconButton";
import Iconify from "../Iconify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { deleteSession } from "../../redux/features/scheduleSlice";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import {
    Button,
    Typography,
    Box,
    Modal,
    Paper,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
} from "@mui/material";
import {
    editSchedule,
    getScheduleById,
} from "../../redux/features/scheduleSlice";
import { useSnackbar } from "notistack";

// import { singleschedule } from "../../redux/features/scheduleSlice";
import { useEffect } from "react";
import { EditSessionPopup } from "./EditSessionPopup";
import { RescheduleSessionPopup } from "./RescheduleSessionPopup";
import { CancelScheduledSession } from "./CancelScheduledSession";
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UtilsMenu(params) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };
    // const schedule = useSelector((state) => state.schedule);
    const singleschedule = useSelector(
        (state) => state.schedule.singleschedule
    );
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [opennew, setOpennew] = useState(false);

    const handleDeleteModal = () => setOpenDeleteModal(true);
    const handleClosenew = () => setOpenDeleteModal(false);

    const { role } = useSelector((state) => state);

    const handleClickOpennew = () => {
        dispatch(deleteSession(params.id)).then((res) => {
            setAnchorEl(null);
            enqueueSnackbar(res.payload.message);
        });

        setOpennew(true);
    };

    const handleDeleteUpdate = () => {
        dispatch(deleteSession(params.id));
    };

    const handleDeleteClose = () => {
        setOpennew(false);
    };

    // -------------------------------------------------------------------
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickOpen = () => {
        // dispatch(getScheduleById(params.id))
        //     .then((res) => {
        //         if (res.payload.status == "error") {
        //             navigate("/schedules/Sessions", { replace: true });
        //             enqueueSnackbar(res.payload.message);
        //         } else {
        //             navigate(`/schedules/session-edit/${params.id}`, {
        //                 replace: true,
        //             });
        //         }
        //     })
        //     .catch((err) => {
        //         enqueueSnackbar(err);
        //     });

        setAnchorEl(null);
    };
    return (
        <div>
            <IconButton
                aria-label="options"
                size="small"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Iconify
                    icon="eva:more-vertical-outline"
                    width={22}
                    height={22}
                    fontSize="small"
                />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {/* ......................EditButton........................... */}
                {params.row.status === "Incomplete" ? (
                    <MenuItem>
                        <EditSessionPopup
                            handleClose={handleClose}
                            params={params.id}
                        />
                    </MenuItem>
                ) : null}
                {params.row.status === "Trainer Cancelled" ||
                params.row.status === "CRO Cancelled" ||
                params.row.status === "Expired" ? (
                    <MenuItem>
                        <RescheduleSessionPopup
                            handleClose={handleClose}
                            params={params.row.schedule_id}
                        />
                    </MenuItem>
                ) : null}
                {params.row.status === "Incomplete" ? (
                    <MenuItem>
                        <CancelScheduledSession
                            handleClose={handleClose}
                            params={params.id}
                        />
                    </MenuItem>
                ) : null}
                {/* .....................DeleteButton........................... */}
                {role.permissions.includes("delete") && (
                    <MenuItem>
                        <IconButton
                            aria-label="delete"
                            size="small"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClickOpennew}
                        >
                            <Iconify
                                sx={{ marginRight: 1 }}
                                color="primary.main"
                                icon="eva:trash-2-outline"
                                width={22}
                                height={22}
                            />
                            Delete
                        </IconButton>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}
