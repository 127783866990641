import { useDispatch, useSelector } from "react-redux";
import Page from "../../../components/Page";
import { Container, Typography, Paper, Box, TextField } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { useEffect, useState } from "react";
import BasicTextField from "../../../components/mui/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import {
    deptEmployees,
    editTask,
    getPreData,
    getTask,
} from "../../../redux/features/todoSlice";
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
} from "@mui/material";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { Form, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../../components/mui/Button";
import moment from "moment";
import Loader from "../../../../src/components/Loader/Loader";
import {
    CancelButton,
    SubmitButton,
} from "../../../components/mui/Buttons/Buttons";
import { Link } from "react-router-dom";

export default function EditTask() {
    const [time, setTime] = useState("");
    const [date, setDate] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const params = useParams();
    const task = useSelector((state) => state.todo);
    const empState = useSelector((state) => state.employees);

    const department = useSelector((state) => state.todo.departments);
    const navigate = useNavigate();

    // -----------------------------------------------------------------------------------------
    const formSchema = yup.object().shape({
        departmentId: yup.number().required("This feild is required"),
        taskTypeId: yup.number().required("This feild is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentId: task.task.departmentId || "",
            taskTypeId: task.task.taskTypeId || "",
            task: task.task.task || "",
            deadLineDate: task.task.taskDeadLineDate || "",
            deadLineTime: task.task.taskDeadLineTime || "",
            priorityId: task.task.taskPriorityId || "",
            assignedTo: task.task.assignedUserId || "",
        },
        validationSchema: formSchema,
        onSubmit: (values, { resetForm }) => {
            const { departmentId, taskTypeId, ...taskDetails } = values;

            const body = { departmentId, taskTypeId, taskDetails };

            dispatch(editTask({ body, id: task.task.taskId })).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    navigate("/todo/created-tasks");
                } else {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                }
            });
        },
    });

    useEffect(() => {
        dispatch(getPreData()).then(() => {
            dispatch(getTask(params.id));
        });
    }, []);
    useEffect(() => {
        setDate(new Date(task.task.taskDeadLineDate));

        var dt = moment(task.task.taskDeadLineTime, ["h:mm A"]).format(
            "HH:mm:ss"
        );
        setTime(`${task.task.taskDeadLineDate}T${dt}`);
    }, [task.task]);

    useEffect(() => {
        if (task.task.departmentId) {
            dispatch(deptEmployees(task.task.departmentId));
        }
    }, [task.task.departmentId]);

    const handleChange = (e) => {
        formik.setFieldValue("departmentId", e.target.value);
        dispatch(deptEmployees(e.target.value));
    };

    const handletask = (e) => {
        formik.setFieldValue("task", e.target.value);
    };
    const handleDate = (e) => {
        setDate(e);
        const date = e.toISOString().split("T")[0];
        formik.setFieldValue("deadLineDate", date);
    };
    const handleTime = (e) => {
        setTime(e);
        const Ftime = `${new Date(e).getHours()}:${new Date(
            e
        ).getMinutes()}:00`;
        formik.setFieldValue("deadLineTime", Ftime);
    };
    const handlePriority = (e) => {
        formik.setFieldValue("priorityId", e.target.value);
    };
    const handleEmp = (e) => {
        formik.setFieldValue("assignedTo", e.target.value);
    };
    // ---------------------------------------------------------------------------------------

    // useEffect(() => {
    //     if (formik.values.departmentId) {
    //         dispatch(deptEmployees(formik.values.departmentId)).then((res) => {
    //             // setEmployees(res.payload.data.employees);
    //         });

    //     }
    // }, [formik.values.departmentId]);

    return (
        <Page title="Edit Task">
            <Container component="main">
                {/*..................................HEADING.............................*/}
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Edit Task
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />

                {task.loading ? (
                    <Loader />
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",

                            mt: { md: 4, xs: 2 },
                        }}
                    >
                        <Paper elevation={4} sx={{ width: "100%" }}>
                            {/* GRAY-HEADING */}
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box sx={{ p: { md: 8, xs: 4 } }}>
                                        <Stack direction="column">
                                            <Stack
                                                direction={{
                                                    md: "row",
                                                    xs: "column",
                                                }}
                                                spacing={2}
                                                sx={{
                                                    display: "flex",
                                                    pb: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                {task.loading ? (
                                                    <Skeleton
                                                        variant="rectangular"
                                                        height={56}
                                                        sx={{
                                                            m: 1,
                                                            minWidth: 160,
                                                            flex: 1,
                                                            borderRadius: 1,
                                                        }}
                                                        size="medium"
                                                    />
                                                ) : (
                                                    <FormControl fullWidth>
                                                        <InputLabel id="department-select-label">
                                                            department
                                                        </InputLabel>
                                                        <Select
                                                            labelId="department-select-label"
                                                            id="department-select"
                                                            value={
                                                                formik.values
                                                                    .departmentId
                                                            }
                                                            label="Department"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        >
                                                            {department.map(
                                                                (item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                item.dept_name
                                                                            }
                                                                            value={
                                                                                item.dept_id
                                                                            }
                                                                        >
                                                                            {
                                                                                item.dept_name
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}

                                                {/* -------------------------------------------------------------------------------------------- */}
                                                {empState.loading ? (
                                                    <Skeleton
                                                        variant="rectangular"
                                                        height={56}
                                                        sx={{
                                                            m: 1,
                                                            minWidth: 160,
                                                            flex: 1,
                                                            borderRadius: 1,
                                                        }}
                                                        size="medium"
                                                    />
                                                ) : (
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Assigned To
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                formik.values
                                                                    .assignedTo
                                                            }
                                                            label="department"
                                                            onChange={handleEmp}
                                                        >
                                                            {empState.data.map(
                                                                (item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                item.name
                                                                            }
                                                                            value={
                                                                                item.userID
                                                                            }
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}

                                                {/* ------------------------------TASK TYPE-------------------------------------------- */}

                                                {task.loading ? (
                                                    <Skeleton
                                                        variant="rectangular"
                                                        height={56}
                                                        sx={{
                                                            m: 1,
                                                            minWidth: 160,
                                                            flex: 1,
                                                            borderRadius: 1,
                                                        }}
                                                        size="medium"
                                                    />
                                                ) : (
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Task Type
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                formik.values
                                                                    .departmentId
                                                            }
                                                            label="Task Type"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        >
                                                            {task.taskType.map(
                                                                (item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                item.task_type_id
                                                                            }
                                                                            value={
                                                                                item.task_type_id
                                                                            }
                                                                        >
                                                                            {
                                                                                item.task_type_name
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Stack>
                                            <Stack
                                                direction={{
                                                    md: "row",
                                                    xs: "column",
                                                }}
                                                spacing={2}
                                                sx={{
                                                    pb: 2,
                                                    alignItems: "center",
                                                }}
                                            >
                                                {task.loading ? (
                                                    <Skeleton
                                                        variant="rectangular"
                                                        height={56}
                                                        sx={{
                                                            m: 1,
                                                            minWidth: 160,
                                                            flex: 1,
                                                            borderRadius: 1,
                                                        }}
                                                        size="medium"
                                                    />
                                                ) : (
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            priority
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                formik.values
                                                                    .priorityId
                                                            }
                                                            label="priority"
                                                            onChange={
                                                                handlePriority
                                                            }
                                                        >
                                                            {task.priority.map(
                                                                (item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                item.task_priority_id
                                                                            }
                                                                            value={
                                                                                item.task_priority_id
                                                                            }
                                                                        >
                                                                            {
                                                                                item.task_priority_name
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )}

                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DesktopDatePicker
                                                        label="Deadline Date"
                                                        inputFormat="MM/DD/YYYY"
                                                        value={date}
                                                        onChange={handleDate}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />

                                                    <TimePicker
                                                        label="Time"
                                                        value={time}
                                                        onChange={handleTime}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>

                                            <BasicTextField
                                                label="Task"
                                                id="outlined-multiline-static"
                                                rows={4}
                                                sx={{ pb: 2 }}
                                                value={formik.values.task}
                                                action={handletask}
                                                error={
                                                    formik.touched.content &&
                                                    Boolean(
                                                        formik.errors.content
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.content
                                                }
                                                multiline
                                            />
                                        </Stack>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Box margin={1}>
                                                <CancelButton
                                                    component={Link}
                                                    to={`/todo/created-tasks`}
                                                >
                                                    Cancel
                                                </CancelButton>
                                            </Box>
                                            <Box margin={1}>
                                                <SubmitButton
                                                    type="submit"
                                                    value="save"
                                                >
                                                    Submit
                                                </SubmitButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    </Box>
                )}
            </Container>
        </Page>
    );
}
