import React, { useEffect, useState } from 'react'
import { ApproveButton, RejectButton } from '../../mui/Buttons/Buttons'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { allEveningFoodRequests, eveningFoodApproveOrRejectOrBulk } from '../../../redux/features/foodSlice'
import dayjs from 'dayjs'

const ApprovalOrReject = ({ row }) => {
  const {loading}=useSelector((store)=>store.food)
  const [isDisabled, setIsDisabled] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const handleAction = (status) => {
    const data = { id: row.food_id, status: status }
    dispatch(eveningFoodApproveOrRejectOrBulk(data)).then((res) => {
      if (res.payload.status == 'failed' || res.payload.status == 'error') {
        enqueueSnackbar(`${res.payload.message}`, { variant: 'error' })
      } else {
        dispatch(allEveningFoodRequests(row.food_date))
        enqueueSnackbar(`${res.payload.message}`, { variant: 'success' })
      }
    })
  }

  useEffect(() => {
      const now = dayjs()
      const startTime = dayjs().set('hour', 14).set('minute', 0) // 2 PM
      const endTime = dayjs().set('hour', 16).set('minute', 0) // 4 PM
      if (now.isAfter(startTime) && now.isBefore(endTime)) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }, [loading])
  return (
    <Box>
      {row.food_approval_status == 0 && (
        <>
          <ApproveButton disabled={loading ||isDisabled } action={() => handleAction(1)}>Approve</ApproveButton>
          <RejectButton disabled={loading || isDisabled} action={() => handleAction(2)} sx={{ ml: 2 }}>
            Reject
          </RejectButton>
        </>
      )}
      {row.food_approval_status == 1 && (
        <RejectButton disabled={loading || isDisabled} action={() => handleAction(2)} sx={{ ml: 2 }}>
          Reject
        </RejectButton>
      )}
      {row.food_approval_status == 2 && <ApproveButton disabled={loading || isDisabled} action={() => handleAction(1)}>Approve</ApproveButton>}
    </Box>
  )
}

export default ApprovalOrReject
