import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Paper, Stack } from "@mui/material";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { UPDATE_EXPECTED_SESSION_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/UpdateExpectedSession/UpdateExpectedSession";
import GlobalSelect from "../../../../components/mui/GlobalSelect";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useResponsive from "../../../../Hooks/useResponsive";
import { useState } from "react";
import Slide from "@mui/material/Slide";

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getExpectedSessions,
    getCrosPreData,
    apiCallData,
} from "../../../../redux/features/crmSlice";
import { SubmitButton } from "../../../../components/mui/Buttons/Buttons";
import { Icon } from "@iconify/react";

export default function UpdateExpectedSession() {
    const [checked, setChecked] = React.useState(false);
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const data = useSelector((state) => state.crm);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState();
    const [gridSize, setGridSize] = useState(5);
    const containerRef = React.useRef(null);

    useEffect(() => {
        dispatch(getCrosPreData());
        let date = dayjs().format("YYYY/MM");
        formik.setFieldValue("date", date);
    }, []);

    const handleChange = (newValue) => {
        setValue(newValue);

        if (newValue !== null && JSON.stringify(newValue.$d) !== "null") {
            const formated = newValue.toISOString().split("T")[0];
        }
        let date = dayjs(newValue).format("YYYY/MM");
        formik.setFieldValue("date", date);
    };

    const CreateSchema = Yup.object().shape({
        date: Yup.string().required("CRO  Required"),
        croId: Yup.string().required("CRO  Required"),
    });

    const formik = useFormik({
        initialValues: {
            date: new Date(),
            croId: "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values) => {
            dispatch(getExpectedSessions(values)).then((res) => {
                if (res.payload.status === "failed") {
                } else if (res.payload.status === "success") {
                    setChecked(true);
                }
            });
            dispatch(apiCallData({ date: values.date, croId: values.croId }));
        },
    });

    return (
        <Wrapper
            title={"Expected Sessions"}
            Content={
                <>
                    <Box>
                        <Box marginTop={5}>
                            {data.totalExpectedSessions !== null ? (
                                <Slide
                                    direction="right"
                                    in={checked}
                                    container={containerRef.current}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginBottom: 2,
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                backgroundColor: "#c8facd",
                                                color: "#0db05d",
                                            }}
                                        >
                                            <Icon
                                                icon="la:wpforms"
                                                width="20"
                                                height="20"
                                            />
                                            Total Expected Sessions -{" "}
                                            {data.totalExpectedSessions}
                                        </Button>
                                    </Box>
                                </Slide>
                            ) : null}

                            <Paper elevation={3}>
                                <Box
                                    sx={{
                                        backgroundColor: "grey.200",
                                        borderRadius: "8px 8px 0 0",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: smUp
                                                ? "flex-end"
                                                : "center",
                                            padding: 1,
                                        }}
                                    >
                                        <FormikProvider value={formik}>
                                            <Form
                                                onSubmit={formik.handleSubmit}
                                            >
                                                <Stack
                                                    direction={
                                                        smUp ? "row" : "column"
                                                    }
                                                    spacing={2}
                                                >
                                                    <LocalizationProvider
                                                        dateAdapter={
                                                            AdapterDayjs
                                                        }
                                                    >
                                                        <DatePicker
                                                            name="date"
                                                            views={[
                                                                "year",
                                                                "month",
                                                            ]}
                                                            label="Year and Month"
                                                            value={
                                                                formik.values
                                                                    .date
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="filled"
                                                                    helperText={
                                                                        null
                                                                    }
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        readOnly: true,
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                    <GlobalSelect
                                                        name="croId"
                                                        label="CRO"
                                                        variant="filled"
                                                        data={data.preData.cros}
                                                        value={
                                                            formik.values.croId
                                                        }
                                                        action={(e) => {
                                                            formik.setFieldValue(
                                                                "croId",
                                                                e.target.value
                                                            );
                                                            console.log(
                                                                "e.target.value",
                                                                e.target.value
                                                            );
                                                        }}
                                                        sx={{ width: 300 }}
                                                        error={
                                                            formik.touched
                                                                .croId &&
                                                            Boolean(
                                                                formik.errors
                                                                    .croId
                                                            )
                                                        }
                                                        helperText={
                                                            formik.errors.croId
                                                        }
                                                    />
                                                    <Box
                                                        sx={{
                                                            paddingTop: 1.5,
                                                            display: smUp
                                                                ? ""
                                                                : "flex",
                                                            justifyContent: smUp
                                                                ? ""
                                                                : "center",
                                                        }}
                                                    >
                                                        <SubmitButton type="submit">
                                                            Submit
                                                        </SubmitButton>
                                                    </Box>
                                                </Stack>
                                            </Form>
                                        </FormikProvider>
                                    </Box>
                                </Box>

                                <Box>
                                    <DataGrid
                                        loading={data.loading}
                                        rows={data.data}
                                        columns={columns}
                                        pageSize={gridSize}
                                        autoHeight
                                        onPageSizeChange={(newGridSize) =>
                                            setGridSize(newGridSize)
                                        }
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        getRowId={(row) => row.serialNumber}
                                        components={{
                                            Toolbar: CustomGridToolbar,
                                        }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: {
                                                    debounceMs: 500,
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </>
            }
        />
    );
}
