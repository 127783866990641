import React from 'react'
import GlobalDeleteDialog from '../../../../mui/Dialogue/GlobalDeleteDialog'
import { getEveningFoodList, getFoodList, removeFood } from '../../../../../redux/features/foodSlice'
import { useSelector } from 'react-redux'

const DeleteFood = ({id,shift,date}) => {
    const {loading}=useSelector((store)=>store.food)
  return (
    <GlobalDeleteDialog
    title={"Delete"}
    deleteNote={"Are you sure? you want to remove this person from foodlist..?"}
    api={removeFood(id)}
    upDateCall={shift==1 ?getFoodList({date:date}):getEveningFoodList({date:date})}
    loading={loading}
    />
  )
}

export default DeleteFood