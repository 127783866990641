import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProfileCompleationCheck = ({ children }) => {
  const { data } = useSelector((state) => state.login);
  const profile = JSON.parse(localStorage.getItem("cred"));
  if (profile === null) {
    <Navigate to="/auth/login" />;
  }

  if (
    !data.profile?.profileCompletion ||
    !data.profile?.bankAccountCompletion
  ) {
    return <Navigate to="/fill/detailsnew" />;
  } else {
    return children;
  }
};

export default ProfileCompleationCheck;
