import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";

const GlobalAnchorList = ({ label, component }) => {
  return (
    <div>
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <Box padding={1}>
          <Box
            sx={{
              backgroundColor: "grey.200",
              borderRadius: 3,
            }}
          >
            <List>
              <ListItem>
                {/* <ListItemIcon>
                  <Icon
                    icon="uil:arrow-up"
                    rotate={1}
                    color="#2f72b3"
                    width="25"
                    height="25"
                  />
                </ListItemIcon> */}
                <ListItemText>
                  <Typography fontWeight="bolder" fontSize={12}>
                    {label}
                  </Typography>
                </ListItemText>
                {component}
              </ListItem>
            </List>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default GlobalAnchorList;
