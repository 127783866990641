import {
    Box,
    Button,
    Divider,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import PersonalDetails from "./PersonalDetails";
import AddressDetails from "./AddressDetails";

export const ProfileBodySection = () => {
    const profile = JSON.parse(localStorage.getItem("cred")).profile;
    console.log(profile);
    return (
        <div>
            {/* <Box>
                <Stack direction={"row"} spacing={2}>
                    <Box>
                        <Paper
                            elevation={3}
                            sx={{
                                height: 100,
                                // width: "100%",
                                padding: 3,
                            }}
                        >
                            <Stack direction={"row"} spacing={5}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Stack direction={"column"} spacing={0.5}>
                                        <Typography variant="h4">
                                            1,967
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            Test
                                        </Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Stack direction={"column"} spacing={0.5}>
                                        <Typography variant="h4">
                                            1,967
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            Test
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Paper>
                    </Box>
                    <Box>
                        <Paper elevation={3} sx={{ padding: 2 }}>
                            <Box>
                                <TextField fullWidth multiline rows={4} />
                            </Box>
                        </Paper>
                    </Box>
                </Stack>
            </Box> */}
            {/* ------------------------------------------------------------------------------------------------- */}
            <Stack direction={"row"} spacing={2} sx={{ pt: 3 }}>
                <Box sx={{ width: "30%" }}>
                    <Box>
                        <Paper
                            elevation={3}
                            sx={{
                                height: 100,
                                // width: "100%",
                                padding: 3,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Stack direction={"row"} spacing={5}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Stack direction={"column"} spacing={0.5}>
                                        <Typography variant="h4">
                                            1,967
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            Test
                                        </Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Stack direction={"column"} spacing={0.5}>
                                        <Typography variant="h4">
                                            1,967
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            Test
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Paper>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <PersonalDetails />
                    </Box>
                </Box>
                <Box sx={{ width: "70%" }}>
                    <Box>
                        <Paper elevation={3} sx={{ padding: 3 }}>
                            <Box>
                                <TextField
                                    label="Share what are you thinking here..."
                                    placeholder="Share what are you thinking here..."
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        color: "white",
                                        backgroundColor: "black",
                                        mt: 1,
                                    }}
                                >
                                    Post
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                    <Box sx={{ pt: 2 }}>
                        <AddressDetails />
                    </Box>
                </Box>
            </Stack>
        </div>
    );
};
