import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { CRO_ENQUIRY_TABLE_HEAD } from "../../../components/datagrid/CRM/CroEnquiries/CroEnquiriesTableHead";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getClassroomPendingEnquiries } from "../../../redux/features/croSlice";

const CroEnquiriesClassRoomProgramsPending = () => {
    const profile = useSelector((state) => state.login.data.profile);
    const dispatch = useDispatch();
    const { croEnquiriesClassroom, loading } = useSelector(
        (state) => state.cro
    );

    const [gridSize, setGridSize] = useState(5);

    const [croId, setCroId] = useState(
        profile.roleID === 10 ? profile.userId : ""
    );

    useEffect(() => {
        dispatch(getClassroomPendingEnquiries(croId));
    }, []);

    return (
        <Box>
            <Box
                sx={{
                    mb: 2,
                }}
            >
                <Typography variant="h4">
                    Cro Enquiries Pending Classroom Programmes
                </Typography>
                <Breadcrumb />
            </Box>

            <DataGrid
                loading={loading}
                rows={croEnquiriesClassroom}
                columns={CRO_ENQUIRY_TABLE_HEAD}
                getRowId={(row) => row.serialNumber}
                pageSize={gridSize}
                onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
                rowsPerPageOptions={[5, 10, 25, 50]}
                components={{ Toolbar: CustomGridToolbar }}
                autoHeight
            />
        </Box>
    );
};

export default CroEnquiriesClassRoomProgramsPending;
