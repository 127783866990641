import React, { useState } from "react";
import Page from "../../../components/Page";
import { Typography, Container, Box, Paper } from "@mui/material";
import BasicButton from "../../../components/mui/Button";
import { CREATEDTASK_TABLE_HEAD as columns } from "../../../components/datagrid/Todo/CreatedTaskTableHead";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCreatedTask } from "../../../redux/features/todoSlice";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";

import { Link } from "react-router-dom";
import { clearRes } from "../../../redux/features/todoSlice";

export default function CreatedTasks() {
    const [createdTasks, setTasks] = useState({ loading: false, data: [] });
    const dispatch = useDispatch();
    const state = useSelector((state) => state.todo);
    useEffect(() => {
        dispatch(getCreatedTask());
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => dispatch(clearRes());
    }, []);

    useEffect(() => {
        setTasks(state);
    }, [state]);

    return (
        <div>
            <Page title="My Tasks">
                <Container component="main">
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Created Task
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                    <Box
                        sx={{
                            padding: 2,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <BasicButton
                            variant="contained"
                            component={Link}
                            to={`/todo/createtask`}
                        >
                            Create Task
                        </BasicButton>
                    </Box>
                    <Box>
                        <Paper
                            elevation={3}
                            sx={{ borderRadius: 1, width: "100%" }}
                        >
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>

                            <Box sx={{ width: "100%" }}>
                                <DataGrid
                                    loading={createdTasks.loading}
                                    rows={createdTasks.data}
                                    columns={columns}
                                    pageSize={5}
                                    autoHeight
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    getRowId={(row) => row.serialNumber}
                                    components={{ Toolbar: CustomGridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </Page>
        </div>
    );
}
