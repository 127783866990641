import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { FormikProvider, Form, useFormik } from "formik";
import { Divider, Paper, TextField } from "@mui/material";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useResponsive from "../../../../Hooks/useResponsive";
import {
    getInterviewrs,
    initialScreeningAssigning,
    getSingleJobApplication,
    firstInterviewAssigning,
    secondInterviewAssigning,
    assignThirdInterview,
} from "../../../../redux/features/jobApplicationSlice";
import {
    CancelButton,
    SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import Autocomplete from "@mui/material/Autocomplete";
import SimpleLoader from "../../../../components/Loader/SimpleLoader";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const InterviewAssignForm = ({ setAssignForm, assignForm, params }) => {
    const interviewers = useSelector((state) => state.jobApplication);
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const handleClose = () => {
        setAssignForm(false);
    };

    const CreateSchema = Yup.object().shape({
        date: Yup.string().required("date Required"),

        userId: Yup.string().required("user Required"),
    });

    const formik = useFormik({
        initialValues: {
            date: new Date(),
            userId: "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values) => {
            const data = {
                date: values.date.toISOString().split("T")[0],
                userId: values.userId,
                id: params.id,
            };
            dispatch(initialScreeningAssigning(data)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(getSingleJobApplication(params.id));
                    setAssignForm(false);
                }
            });
        },
    });

    return (
        <div>
            <Dialog
                open={assignForm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Paper sx={{ backgroundColor: "grey.200", display: "flex" }}>
                    <DialogTitle>{"Assign Initial Screening..."}</DialogTitle>
                </Paper>
                <Divider />
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <DialogContent>
                            {smUp && (
                                <Box sx={{ width: 400, padding: 1 }}>
                                    {interviewers.loading ? (
                                        <SimpleLoader />
                                    ) : (
                                        <>
                                            <Box margin={1}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DesktopDatePicker
                                                        label="Date"
                                                        inputFormat="YYYY/MM/DD"
                                                        mask=""
                                                        name="date"
                                                        value={dayjs(
                                                            formik.values.date
                                                        )}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "date",
                                                                e
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                variant="outlined"
                                                                fullWidth
                                                                {...params}
                                                                error={Boolean(
                                                                    formik
                                                                        .touched
                                                                        .date &&
                                                                        formik
                                                                            .errors
                                                                            .date
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                    <FormHelperText>
                                                        {formik.errors.date}
                                                    </FormHelperText>
                                                </LocalizationProvider>
                                            </Box>
                                            <Box margin={1}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        User
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        variant="outlined"
                                                        required
                                                        label="User"
                                                        name="userId"
                                                        value={
                                                            formik.values.userId
                                                        }
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "userId",
                                                                e.target.value
                                                            );
                                                        }}
                                                        error={
                                                            formik.touched
                                                                .userId &&
                                                            Boolean(
                                                                formik.errors
                                                                    .userId
                                                            )
                                                        }
                                                    >
                                                        {interviewers.interviewers?.map(
                                                            (item) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            item.userId
                                                                        }
                                                                        key={
                                                                            item.userId
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                    <FormHelperText>
                                                        {formik.errors.remarks}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Box>
                                        </>
                                    )}
                                    <Divider sx={{ borderBottomWidth: 3 }} />
                                </Box>
                            )}
                            {!smUp && (
                                <Box sx={{ width: "100%", padding: 1 }}>
                                    <Box margin={1}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="YYYY/MM/DD"
                                                mask=""
                                                name="date"
                                                value={dayjs(
                                                    formik.values.date
                                                )}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "date",
                                                        e
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.touched
                                                                .date &&
                                                                formik.errors
                                                                    .date
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>
                                                {formik.errors.date}
                                            </FormHelperText>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box margin={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                User
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant="outlined"
                                                label="User"
                                                name="userId"
                                                value={formik.values.userId}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "userId",
                                                        e.target.value
                                                    );
                                                }}
                                                error={
                                                    formik.touched.userId &&
                                                    Boolean(
                                                        formik.errors.userId
                                                    )
                                                }
                                            >
                                                {interviewers.interviewers.data?.map(
                                                    (item) => {
                                                        return (
                                                            <MenuItem
                                                                value={item.id}
                                                                key={item.id}
                                                            >
                                                                {item.status}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                            <FormHelperText>
                                                {formik.errors.remarks}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Divider sx={{ borderBottomWidth: 3 }} />
                                </Box>
                            )}
                        </DialogContent>

                        <DialogActions>
                            <CancelButton action={handleClose}>
                                Cancel
                            </CancelButton>
                            <SubmitButton type="submit">
                                {interviewers.loading
                                    ? "Assigning..."
                                    : "Assign"}
                            </SubmitButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </Dialog>
        </div>
    );
};

const FirstInterviewAssignForm = ({
    setFirstInterviewAssignForm,
    firstInterviewAssignForm,
    params,
}) => {
    const interviewers = useSelector((state) => state.jobApplication);
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const handleClose = () => {
        setFirstInterviewAssignForm(false);
    };

    // const CreateSchema = Yup.object().shape({
    //   date: Yup.string().required("date Required"),

    //   userId: Yup.string().required("user Required"),
    // });

    useEffect(() => {
        dispatch(getInterviewrs());
    }, []);

    const formik = useFormik({
        initialValues: {
            date: new Date(),
            interviewerId: "",
        },
        // validationSchema: CreateSchema,
        onSubmit: (values) => {
            const data = {
                date: values.date.toISOString().split("T")[0],
                interviewerId: values.interviewerId,
                id: params.id,
            };
            dispatch(firstInterviewAssigning(data)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(getSingleJobApplication(params.id));
                    setFirstInterviewAssignForm(false);
                }
            });
        },
    });

    return (
        <div>
            <Dialog
                open={firstInterviewAssignForm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Paper sx={{ backgroundColor: "grey.200", display: "flex" }}>
                    <DialogTitle>{"Assign First Interview..."}</DialogTitle>
                </Paper>
                <Divider />
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <DialogContent>
                            {smUp && (
                                <Box sx={{ width: 400, padding: 1 }}>
                                    <Box margin={1}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="YYYY/MM/DD"
                                                mask=""
                                                name="date"
                                                value={dayjs(
                                                    formik.values.date
                                                )}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "date",
                                                        e
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.touched
                                                                .date &&
                                                                formik.errors
                                                                    .date
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>
                                                {formik.errors.date}
                                            </FormHelperText>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box margin={1}>
                                        <FormControl fullWidth>
                                            <InputLabel>User</InputLabel>
                                            <Select
                                                variant="outlined"
                                                required
                                                label="User"
                                                name="interviewerId"
                                                value={
                                                    formik.values.interviewerId
                                                }
                                                onChange={(e, value) => {
                                                    formik.setFieldValue(
                                                        "interviewerId",
                                                        e.target.value
                                                    );
                                                }}
                                                error={
                                                    formik.touched
                                                        .interviewerId &&
                                                    Boolean(
                                                        formik.errors
                                                            .interviewerId
                                                    )
                                                }
                                            >
                                                {interviewers.interviewers?.map(
                                                    (item) => {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    item.userId
                                                                }
                                                                key={
                                                                    item.userId
                                                                }
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                            <FormHelperText>
                                                {formik.errors.interviewerId}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Divider sx={{ borderBottomWidth: 3 }} />
                                </Box>
                            )}
                            {!smUp && (
                                <Box sx={{ width: "100%", padding: 1 }}>
                                    <Box margin={1}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="YYYY/MM/DD"
                                                mask=""
                                                name="date"
                                                value={dayjs(
                                                    formik.values.date
                                                )}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "date",
                                                        e
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.touched
                                                                .date &&
                                                                formik.errors
                                                                    .date
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>
                                                {formik.errors.date}
                                            </FormHelperText>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box margin={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                User
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant="outlined"
                                                label="User"
                                                name="interviewerId"
                                                value={
                                                    formik.values.interviewerId
                                                }
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "interviewerId",
                                                        e.target.value
                                                    );
                                                }}
                                                error={
                                                    formik.touched
                                                        .interviewerId &&
                                                    Boolean(
                                                        formik.errors
                                                            .interviewerId
                                                    )
                                                }
                                            >
                                                {interviewers.interviewers.map(
                                                    (item) => {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    item.userId
                                                                }
                                                                key={
                                                                    item.userId
                                                                }
                                                            >
                                                                {item.status}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                            <FormHelperText>
                                                {formik.errors.interviewerId}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Divider sx={{ borderBottomWidth: 3 }} />
                                </Box>
                            )}
                        </DialogContent>

                        <DialogActions>
                            <CancelButton action={handleClose}>
                                Cancel
                            </CancelButton>
                            <SubmitButton type="submit">
                                {interviewers.loading
                                    ? "Assigning..."
                                    : "Assign"}
                            </SubmitButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </Dialog>
        </div>
    );
};

const SecondInterviewAssignForm = ({
    setSecondInterviewAssignForm,
    secondInterviewAssignForm,
    params,
}) => {
    const interviewers = useSelector((state) => state.jobApplication);
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const handleClose = () => {
        setSecondInterviewAssignForm(false);
    };

    const CreateSchema = Yup.object().shape({
        date: Yup.string().required("date Required"),

        userId: Yup.string().required("user Required"),
    });

    useEffect(() => {
        dispatch(getInterviewrs());
    }, []);

    const formik = useFormik({
        initialValues: {
            date: new Date(),
            interviewerId: "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values) => {
            const data = {
                date: values.date.toISOString().split("T")[0],
                interviewerId: values.userId,
                id: params.id,
            };
            dispatch(secondInterviewAssigning(data)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(getSingleJobApplication(params.id));
                    setSecondInterviewAssignForm(false);
                }
            });
        },
    });

    return (
        <div>
            <Dialog
                open={secondInterviewAssignForm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Paper sx={{ backgroundColor: "grey.200", display: "flex" }}>
                    <DialogTitle>
                        {"Upadate Initial Screening Status.."}
                    </DialogTitle>
                </Paper>
                <Divider />
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <DialogContent>
                            {smUp && (
                                <Box sx={{ width: 400, padding: 1 }}>
                                    <Box margin={1}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="YYYY/MM/DD"
                                                mask=""
                                                name="date"
                                                value={dayjs(
                                                    formik.values.date
                                                )}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "date",
                                                        e
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.touched
                                                                .date &&
                                                                formik.errors
                                                                    .date
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>
                                                {formik.errors.date}
                                            </FormHelperText>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box margin={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                User
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant="outlined"
                                                required
                                                label="User"
                                                name="userId"
                                                value={formik.values.userId}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "userId",
                                                        e.target.value
                                                    );
                                                }}
                                                error={
                                                    formik.touched.userId &&
                                                    Boolean(
                                                        formik.errors.userId
                                                    )
                                                }
                                            >
                                                {interviewers.interviewers?.map(
                                                    (item) => {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    item.userId
                                                                }
                                                                key={
                                                                    item.userId
                                                                }
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                            <FormHelperText>
                                                {formik.errors.remarks}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Divider sx={{ borderBottomWidth: 3 }} />
                                </Box>
                            )}
                            {!smUp && (
                                <Box sx={{ width: "100%", padding: 1 }}>
                                    <Box margin={1}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="YYYY/MM/DD"
                                                mask=""
                                                name="date"
                                                value={dayjs(
                                                    formik.values.date
                                                )}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "date",
                                                        e
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.touched
                                                                .date &&
                                                                formik.errors
                                                                    .date
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>
                                                {formik.errors.date}
                                            </FormHelperText>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box margin={1}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                User
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                variant="outlined"
                                                label="User"
                                                name="userId"
                                                value={formik.values.userId}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "userId",
                                                        e.target.value
                                                    );
                                                }}
                                                error={
                                                    formik.touched.userId &&
                                                    Boolean(
                                                        formik.errors.userId
                                                    )
                                                }
                                            >
                                                {interviewers.interviewers.map(
                                                    (item) => {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    item.userId
                                                                }
                                                                key={
                                                                    item.userId
                                                                }
                                                            >
                                                                {item.status}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                            <FormHelperText>
                                                {formik.errors.remarks}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                    <Divider sx={{ borderBottomWidth: 3 }} />
                                </Box>
                            )}
                        </DialogContent>

                        <DialogActions>
                            <CancelButton action={handleClose}>
                                Cancel
                            </CancelButton>
                            <SubmitButton type="submit">
                                {interviewers.loading
                                    ? "Assigning..."
                                    : "Assign"}
                            </SubmitButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </Dialog>
        </div>
    );
};

const ThirdInterviewAssignForm = ({
    setThirdInterviewAssignForm,
    thirdInterviewAssignForm,
    params,
}) => {
    const interviewers = useSelector((state) => state.jobApplication.users);
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const handleClose = () => {
        setThirdInterviewAssignForm(false);
    };

    const CreateSchema = Yup.object().shape({
        date: Yup.string().required("date Required"),

        userId: Yup.string().required("user Required"),
    });

    useEffect(() => {}, []);

    const formik = useFormik({
        initialValues: {
            date: new Date(),
            interviewerId: "",
        },
        // validationSchema: CreateSchema,

        onSubmit: (values) => {
            const data = {
                date: values.date.toISOString().split("T")[0],
                interviewerId: values.interviewerId,
                id: params.id,
            };

            dispatch(assignThirdInterview(data)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(getSingleJobApplication(params.id));
                    setThirdInterviewAssignForm(false);
                }
            });
        },
    });

    return (
        <div>
            <Dialog
                open={thirdInterviewAssignForm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Paper sx={{ backgroundColor: "grey.200", display: "flex" }}>
                    <DialogTitle>{"Assign Third Interview "}</DialogTitle>
                </Paper>
                <Divider />
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <DialogContent>
                            {smUp && (
                                <Box sx={{ width: 400, padding: 1 }}>
                                    <Box margin={1}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="YYYY/MM/DD"
                                                mask=""
                                                name="date"
                                                value={dayjs(
                                                    formik.values.date
                                                )}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "date",
                                                        e
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.touched
                                                                .date &&
                                                                formik.errors
                                                                    .date
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>
                                                {formik.errors.date}
                                            </FormHelperText>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box margin={1}>
                                        <Autocomplete
                                            disablePortal
                                            name="interviewerId"
                                            id="combo-box-demo"
                                            onChange={(e, value) => {
                                                formik.setFieldValue(
                                                    "interviewerId",
                                                    value.userId
                                                );
                                            }}
                                            options={interviewers}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            renderOption={(props, option) => {
                                                return (
                                                    <li
                                                        {...props}
                                                        key={
                                                            option.interviewerId
                                                        }
                                                    >
                                                        {option.name}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </Box>
                                    <Divider sx={{ borderBottomWidth: 3 }} />
                                </Box>
                            )}
                            {!smUp && (
                                <Box sx={{ width: "100%", padding: 1 }}>
                                    <Box margin={1}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DesktopDatePicker
                                                label="Date"
                                                inputFormat="YYYY/MM/DD"
                                                mask=""
                                                name="date"
                                                value={dayjs(
                                                    formik.values.date
                                                )}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "date",
                                                        e
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.touched
                                                                .date &&
                                                                formik.errors
                                                                    .date
                                                        )}
                                                    />
                                                )}
                                            />
                                            <FormHelperText>
                                                {formik.errors.date}
                                            </FormHelperText>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box margin={1}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={interviewers?.map(
                                                (option) => option.name
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </Box>
                                    <Divider sx={{ borderBottomWidth: 3 }} />
                                </Box>
                            )}
                        </DialogContent>

                        <DialogActions>
                            <CancelButton action={handleClose}>
                                Cancel
                            </CancelButton>
                            <SubmitButton type="submit">
                                {interviewers.loading
                                    ? "Assigning..."
                                    : "Assign"}
                            </SubmitButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </Dialog>
        </div>
    );
};
export {
    InterviewAssignForm,
    FirstInterviewAssignForm,
    SecondInterviewAssignForm,
    ThirdInterviewAssignForm,
};
