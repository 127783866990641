import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Link, Container, Typography, Stack, Box } from "@mui/material";
// hooks
import useResponsive from "../../../../../Hooks/useResponsive";
// components
import Page from "../../../../../components/Page";
import Logo from "../../../../../components/color-utils/Logo";
// sections
import VerificationForm from "./VerificationForm";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import genieImg from "../../../../../img/map.gif";
import otpImg from "../../../../../img/otp.jpg";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    padding: theme.spacing(3),
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
        alignItems: "flex-start",
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: "100%",
    maxWidth: 464,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function MailVerification() {
    const [logoRotated, setLogoRotated] = useState(false);
    const controls = useAnimation();
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    useEffect(() => {
        if (!logoRotated) {
            controls.start({
                rotateX: 360,
                transition: { duration: 1, ease: "easeInOut" },
            });
            setLogoRotated(true);
        }
    }, [logoRotated, controls]);
    const keyframesExample = {
        hidden: { opacity: 0, x: -100 },
        halfway: { opacity: 0.5, x: 50 },
        visible: { opacity: 1, x: 0 },
    };
    const formKeyFrames = {
        hidden: { opacity: 0, y: -100 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeInOut" },
        },
    };

    return (
        <Page title="Register">
            <RootStyle>
                <HeaderStyle>
                    <Stack direction={"row"}>
                        <Box sx={{ paddingTop: 1.6 }}>
                            <motion.div animate={controls}>
                                <Logo />
                            </motion.div>
                        </Box>

                        <Box>
                            <img
                                src={genieImg}
                                alt="login Img"
                                height={50}
                                width={50}
                            />
                        </Box>
                    </Stack>
                    {smUp && (
                        <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                            Already have an account? {""}
                            <Link
                                variant="subtitle2"
                                component={RouterLink}
                                to="/auth/login"
                            >
                                Login
                            </Link>
                        </Typography>
                    )}
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={keyframesExample}
                        >
                            <Typography
                                variant="h3"
                                sx={{ px: 5, mt: 10, mb: 5 }}
                            >
                                Let's Verify Your Email...
                            </Typography>
                            <img alt="register" src={otpImg} />
                        </motion.div>
                    </SectionStyle>
                )}

                <Container>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={formKeyFrames}
                    >
                        <ContentStyle>
                            <Typography variant="h4" gutterBottom>
                                Get started
                            </Typography>

                            <Typography sx={{ color: "text.secondary", mb: 5 }}>
                                Enter your email below.
                            </Typography>

                            <VerificationForm />
                            {!smUp && (
                                <Typography
                                    variant="body2"
                                    sx={{ mt: 3, textAlign: "center" }}
                                >
                                    Already have an account?{" "}
                                    <Link
                                        variant="subtitle2"
                                        to="/auth/login"
                                        component={RouterLink}
                                    >
                                        Login
                                    </Link>
                                </Typography>
                            )}
                        </ContentStyle>
                    </motion.div>
                </Container>
            </RootStyle>
        </Page>
    );
}
