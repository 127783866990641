import { useRef, useState } from "react";
import React from "react";
import { Link as RouterLink, NavLink } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    Avatar,
    IconButton,
    Button,
} from "@mui/material";
// components
import MenuPopover from "../../components/sidebar/MenuPopover";
// mocks_
import account from "../../UserAccount/account";
import { clearUserInfo, logOut } from "../../../src/Common";
import { clearProfile } from "../../redux/features/profileSlice";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { getNavConfig, clearRoles } from "../../redux/features/roleSlice";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
    clearUser,
    getUserPreData,
    switchRole,
} from "../../redux/features/authSlice";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import useResponsive from "../../Hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: "Profile",
        icon: "eva:settings-2-fill",
        linkTo: "/menu/profile",
    },
    {
        label: "Settings",
        icon: "eva:settings-2-fill",
        linkTo: "/menu/settings",
    },
];

// ----------------------------------------------------------------------

export default function AccountPopover({
    userDetails,
    user,
    loading,
    setOpenSideBar,
}) {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const anchorRef = useRef(null);
    const dispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = React.useState(false);

    const [role, setrole] = useState("");

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleSubmit = () => {
        dispatch(switchRole(role)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                enqueueSnackbar(res.payload.message, { variant: "success" });

                setOpenSideBar(true);

                setOpenModal(false);
                dispatch(clearRoles());
                dispatch(getNavConfig());
            }
        });
    };

    // --------------------------------------------------------------------

    const handleClickOpen = () => {
        setOpenSideBar(false);
        setOpenModal(true);
    };

    const handleClickClose = (event, reason) => {
        if (reason !== "backdropClick") {
            setOpenModal(false);
        }
    };

    useEffect(() => {
        dispatch(getUserPreData());
    }, []);

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) =>
                                alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                {user.image ? (
                    <Avatar
                        src={`https://workspace.teaminterval.net/assets/employee/photo/passport_size/${user.image}.jpg`}
                        sx={{ width: 56, height: 56 }}
                    />
                ) : (
                    <Avatar sx={{ width: 56, height: 56 }}>
                        {user.name[0]}
                    </Avatar>
                )}
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    "& .MuiMenuItem-root": {
                        typography: "body2",
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user.name}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                    >
                        {user.official_email_id}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: "dashed" }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem
                            key={option.label}
                            to={option.linkTo}
                            component={RouterLink}
                            onClick={handleClose}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: "dashed" }} />

                <MenuItem onClick={handleClose} sx={{ m: 1 }}>
                    <Stack direction={"column"}>
                        {userDetails.user.length >= 2 ? (
                            <Button
                                size="small"
                                //  disabled={userDetails.user.length<=1 ? true:false}

                                onClick={handleClickOpen}
                                sx={{
                                    backgroundColor: "#2f72b35c",
                                    color: "#001c92",
                                    borderRadius: 1,
                                    padding: 1,
                                    margin: 1,
                                }}
                            >
                                Change Role{" "}
                                <Icon
                                    icon="ic:baseline-supervisor-account"
                                    width="30"
                                    height="30"
                                />
                            </Button>
                        ) : null}

                        <NavLink
                            to="/auth/login"
                            onClick={() => {
                                dispatch(clearRoles());
                                dispatch(clearProfile());
                                dispatch(clearUser());
                                logOut();
                            }}
                        >
                            <Button
                                size="small"
                                sx={{
                                    backgroundColor: "#ffe4de",
                                    color: "#c03530",
                                    borderRadius: 1,
                                    padding: 1,
                                    margin: 1,
                                }}
                            >
                                <Icon
                                    icon="heroicons-outline:logout"
                                    width="25"
                                    height="25"
                                    rotate={2}
                                />
                                Logout
                            </Button>
                        </NavLink>
                    </Stack>
                </MenuItem>
            </MenuPopover>

            {/* -------------------------------------------------------------- */}
            <div>
                {smUp && (
                    <Dialog
                        disableEscapeKeyDown
                        open={openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClickClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>
                            <Stack direction={"row"} marginTop={1}>
                                <Box marginRight={1}>
                                    <Icon
                                        icon="fluent:people-sync-16-regular"
                                        width="30"
                                        height="30"
                                        color="#7e4d8b"
                                    />
                                </Box>
                                <Box sx={{ marginTop: 0.5, color: "#7e4d8b" }}>
                                    {"Change Your Role..."}
                                </Box>
                            </Stack>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Box sx={{ width: 500, padding: 3 }}>
                                {userDetails.user.length <= 0 ? (
                                    <FormControl sx={{ width: 350 }}>
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{
                                                width: "100%",
                                                height: 56,
                                                borderRadius: 1,
                                            }}
                                        />
                                    </FormControl>
                                ) : (
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Select Role
                                        </InputLabel>
                                        <Select
                                            variant="filled"
                                            name="role"
                                            value={role || ""}
                                            label="Select Role"
                                            onChange={(e) =>
                                                setrole(e.target.value)
                                            }
                                        >
                                            {userDetails.user?.map((item) => {
                                                return (
                                                    <MenuItem
                                                        value={item.roleId}
                                                        key={item.roleId}
                                                    >
                                                        {item.role}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: "#eff4f9",
                                    color: "#407db9",
                                }}
                                onClick={handleClickClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: "#8657923b",
                                    color: "#804f8c",
                                }}
                                onClick={handleSubmit}
                            >
                                <Icon
                                    icon="material-symbols:change-circle-outline-rounded"
                                    width="25"
                                    height="25"
                                />
                                {loading ? "Submitting..." : "Submit"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
                {!smUp && (
                    <Dialog
                        open={openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClickClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>
                            <Stack direction={"row"} marginTop={1}>
                                <Box marginRight={1}>
                                    <Icon
                                        icon="fluent:people-sync-16-regular"
                                        width="30"
                                        height="30"
                                        color="#7e4d8b"
                                    />
                                </Box>
                                <Box sx={{ marginTop: 0.5, color: "#7e4d8b" }}>
                                    {"Change Your Role..."}
                                </Box>
                            </Stack>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Box sx={{ width: 280, padding: 3 }}>
                                {userDetails.user.length <= 0 ? (
                                    <FormControl sx={{ width: 350 }}>
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{
                                                width: "100%",
                                                height: 56,
                                                borderRadius: 1,
                                            }}
                                        />
                                    </FormControl>
                                ) : (
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Select Role
                                        </InputLabel>
                                        <Select
                                            variant="filled"
                                            name="role"
                                            value={role || ""}
                                            label="Select Role"
                                            onChange={(e) =>
                                                setrole(e.target.value)
                                            }
                                        >
                                            {userDetails.user?.map((item) => {
                                                return (
                                                    <MenuItem
                                                        value={item.roleId}
                                                        key={item.roleId}
                                                    >
                                                        {item.role}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                sx={{
                                    backgroundColor: "#eff4f9",
                                    color: "#407db9",
                                }}
                                onClick={handleClickClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    backgroundColor: "#8657923b",
                                    color: "#804f8c",
                                }}
                                onClick={handleSubmit}
                            >
                                <Icon
                                    icon="material-symbols:change-circle-outline-rounded"
                                    width="25"
                                    height="25"
                                />
                                {loading ? "Submitting..." : "Submit"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </div>
        </>
    );
}
