import * as React from "react";
import Button from "@mui/material/Button";
import {
    Navigate,
    NavLink,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { useEffect } from "react";

export default function InAndOutAction(params) {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClickApprove = (row) => {
        navigate(`/todo/approve-task/${row.taskId}`, {
            state: location.pathname,
        });
    };

    return (
        <div>
            {params.row.uiElements.map((item) => {
                if (item === "statusUpdateBtn") {
                    return (
                        <Button
                            variant="contained"
                            size="small"
                            color="warning"
                            sx={{
                                minWidth: "110px",
                            }}
                            onClick={() => handleClickApprove(params.row)}
                        >
                            Approve
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            variant="contained"
                            size="small"
                            color="success"
                            sx={{
                                minWidth: "110px",
                            }}
                        >
                            Approved
                        </Button>
                    );
                }
            })}
        </div>
    );
}
