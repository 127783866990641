import React, { useState } from "react";
import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import { Button, Box, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LOGS_TABLE_HEAD as columns } from "../Todo/LogsTableHead";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyTaskStatus(params) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const logBox = (
        <Dialog
            maxWidth={"lg"}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogActions>
                <Button onClick={handleClose}>
                    <Icon icon="iconoir:cancel" width="30" height="30" />
                </Button>
            </DialogActions>
            <DialogTitle>{"Approval Status"}</DialogTitle>

            <DialogContent>
                <Box width={900}>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%" }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                            }}
                        >
                            <DataGrid
                                loading={params.row.loading}
                                rows={params.row.taskLog}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                getRowId={(row) => row.cameOn}
                                autoHeight
                                getRowHeight={() => "auto"}
                                // components={{ Toolbar: GridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: {
                                            debounceMs: 500,
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                </Box>
            </DialogContent>
        </Dialog>
    );
    if (params.row.taskStatus === "Pending") {
        return (
            <>
                <div onClick={handleClickOpen}>
                    <Chip
                        sx={{
                            backgroundColor: "#ffe4de",
                            color: "#c03530",
                            height: 20,
                            width: 75,
                            fontSize: 11,
                            fontWeight: 800,
                            py: 1.4,
                        }}
                        label="Pending"
                    />
                </div>
                {logBox}
            </>
        );
    } else if (params.row.taskStatus === "In Progress") {
        return (
            <>
                <div onClick={handleClickOpen}>
                    <Chip
                        sx={{
                            backgroundColor: "#ffab0045",
                            color: "#ffbb30",
                            height: 20,
                            width: 90,
                            fontSize: 11,
                            fontWeight: 800,
                            py: 1.4,
                        }}
                        label="In Progress"
                    />
                </div>
                {logBox}
            </>
        );
    } else if (params.row.taskStatus === "Completed") {
        return (
            <>
                <div onClick={handleClickOpen}>
                    <Chip
                        sx={{
                            backgroundColor: "#dff3ea",
                            color: "#20836e",
                            height: 20,
                            width: 85,
                            fontSize: 11,
                            fontWeight: 800,
                            py: 1.4,
                        }}
                        label="Completed"
                    />
                </div>
                {logBox}
            </>
        );
    }
}
