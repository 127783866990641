import React from "react";
import Wrapper from "../../../../../components/Wrapper/Wrapper";
import { Box } from "@mui/material";
import CustomDataGrid from "../../../../../components/mui/DataGrid/CustomDataGrid";

const data = [
    {
        id: 1,
        name: "ABDULLA NIHAL(4385)",
        mobile: "919526711574",
        course: "Classroom - Short Term",
        openingBalance: 1180,
        feePaid: 3000,
        expectedSessions: 9,
        noOfSessions: 7,
        totalFee: 5000,
        balance: 3000,
        ledger: "",
        notes: "",
    },
];

const columns = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },
    {
        field: "name",
        headerName: "name",
        width: 150,
    },
    {
        field: "mobile",
        headerName: "mobile",
        width: 150,
    },
    {
        field: "course",
        headerName: "course",
        width: 100,
    },
    {
        field: "openingBalance",
        headerName: "openingBalance",
        width: 200,
    },
    {
        field: "feePaid",
        headerName: "feePaid",
        width: 150,
    },

    {
        field: "expectedSessions",
        headerName: "expectedSessions",
        width: 150,
    },

    {
        field: "noOfSessions",
        headerName: "noOfSessions",
        width: 250,
    },
    {
        field: "totalFee",
        headerName: "totalFee",
        width: 150,
    },
    {
        field: "balance",
        headerName: "balance",
        width: 150,
    },
    {
        field: "ledger",
        headerName: "ledger",
        width: 150,
    },
    {
        field: "notes",
        headerName: "notes",
        width: 150,
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function LedgerView() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Ledger View"
                Content={
                    <>
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.course}
                                label="course"
                                name={selectedRows[0]?.name}
                                // details={<Details data={selectedRows[0]} />}
                                // actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
