import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";
 
const initialState = {
  loading: false,
  holidays: [],
};

export const getHolidays = createAsyncThunk(
    "holidays/getHolidays", async (year) => {
  const res = await apiRequest({
    method: "get",
    url: `holiday_calendar/get-all-holidays/${year}`,
  });
  return res;
});

export const deleteHoliday = createAsyncThunk(
    "holidays/deleteHoliday", async (id) => {        
  const res = await apiRequest({
    method: "delete",
    url: `holiday_calendar/delete-holiday/${id}`,
  });
  return res;
});

export const addHoliday = createAsyncThunk(
  "holidays/addHoliday", async (data) => {
const res = await apiRequest({
  method: "post",
  url: `holiday_calendar/create-holiday`,
  data: data,
});
return res;
});


export const updateHoliday = createAsyncThunk(
  "holidays/updateHoliday", async (data) => {
const res = await apiRequest({
  method: "patch",
  url: `holiday_calendar/update-holiday/${data.id}`,
  data: data,
});
return res;
});

const holidaysSlice = createSlice({
  name: "holidaysSlice",
  initialState,
  reducers: {},
  //.................GET HOLIDAY..............................
  extraReducers: (builder) => {
    builder.addCase(getHolidays.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getHolidays.fulfilled, (state, action) => {
      return {
        ...state,
        holidays: formatDataForDataTable(action.payload.data.holidays),
        loading: false,
      };
    });

//.................DELETE HOLIDAY..............................
    builder.addCase(deleteHoliday.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        };
      });
      builder.addCase(deleteHoliday.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
//.................ADD HOLIDAY..............................
      builder.addCase(addHoliday.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        };
      });
      builder.addCase(addHoliday.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
        };
      });

      //.................UPDATE HOLIDAY..............................
      builder.addCase(updateHoliday.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        };
      });
      builder.addCase(updateHoliday.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
        };
      });
  },
});

export default holidaysSlice.reducer;
