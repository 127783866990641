import * as Yup from "yup";
import * as React from "react";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector, useDispatch } from "react-redux";
import {
    mailVerification,
    verifyRegisterOtp,
    resendRegisterOtp,
} from "../../../../../redux/features/authSlice";
// component
import Iconify from "../../../../../components/Iconify";
import Loader3D from "../../../../../components/Loader/Loader3D";

import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { Box, Paper, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import OtpInput from "react-otp-input";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Component } from "react";
import Divider from "@mui/material/Divider";
import OtpTimer from "otp-timer";
import useResponsive from "../../../../../Hooks/useResponsive";

// ----------------------------------------------------------------------
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function VerificationForm() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const submit = () => {
        dispatch(resendRegisterOtp()).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                enqueueSnackbar(res.payload.message, { variant: "success" });

                // navigate("/dashboard", { replace: true });
            }
        });
    };

    const [OTP, setOTP] = useState("");

    const [open, setOpen] = useState(false);

    // const handleClickOpen = () => {

    // };

    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            setOpen(false);
        }
    };
    const loading = useSelector((state) => state.login.isLoading);

    const [showPassword, setShowPassword] = useState(false);
    const [otp, setOtp] = useState("");

    const RegisterSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            dispatch(mailVerification(values.email)).then((res) => {
                if (res.payload.status === "failed") {
                    setOpen(true);
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    setOpen(true);

                    // navigate("/dashboard", { replace: true });
                }
            });
        },
    });

    const handleVerify = () => {
        dispatch(verifyRegisterOtp(OTP)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                enqueueSnackbar(res.payload.message, { variant: "success" });

                navigate("/auth/register", { replace: true });
            }
        });
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } =
        formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email address"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={loading ? true : false}
                    >
                        {loading ? <Loader3D /> : "Send Otp"}
                    </LoadingButton>
                </Stack>
            </Form>
            {/* loading={isSubmitting} */}

            <div>
                {smUp && (
                    <Dialog
                        disableEscapeKeyDown
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Button onClick={handleClose} sx={{ padding: 2 }}>
                                <Icon
                                    icon="gridicons:cross"
                                    width="25"
                                    height="25"
                                />
                            </Button>
                        </Box>

                        <DialogTitle>{"Verify E-Mail"}</DialogTitle>
                        <Divider />
                        <DialogContent>
                            {/* <TextField 
            sx={{width:300,margin:5}}
            label="Enter your Otp"
            value={otp}
            onChange={(e)=> setOtp(e.target.value)}

            
            
          /> */}
                            <Paper
                                elevation={3}
                                sx={{
                                    height: 150,
                                    width: 500,
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: "#f9fafb",
                                }}
                            >
                                <Box sx={{ paddingTop: 4 }}>
                                    <Typography sx={{ paddingBottom: 2 }}>
                                        Enter your Otp below..
                                    </Typography>
                                    <OTPInput
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        OTPLength={4}
                                        otpType="number"
                                        disabled={false}
                                        inputStyles={{
                                            height: 45,
                                            width: 45,
                                            borderRadius: 10,
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Box margin={4}>
                                <Button variant="contained">
                                    <Icon
                                        icon="mdi:email-resend-outline"
                                        width="30"
                                        height="30"
                                    />
                                    <OtpTimer
                                        color={"white"}
                                        background={"#00000000"}
                                        minutes={1}
                                        seconds={0}
                                        text="Time:"
                                        ButtonText="Resend"
                                        resend={submit}
                                    />
                                </Button>
                            </Box>

                            <LoadingButton
                                variant="contained"
                                onClick={handleVerify}
                            >
                                <Icon
                                    icon="uil:comment-verify"
                                    width="30"
                                    height="30"
                                />
                                {loading ? <Loader3D /> : "Verify"}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                )}
                {!smUp && (
                    <Dialog
                        disableEscapeKeyDown
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Button onClick={handleClose} sx={{ padding: 2 }}>
                                <Icon
                                    icon="gridicons:cross"
                                    width="25"
                                    height="25"
                                />
                            </Button>
                        </Box>

                        <DialogTitle>{"Verify E-Mail"}</DialogTitle>
                        <Divider />
                        <DialogContent>
                            {/* <TextField 
            sx={{width:300,margin:5}}
            label="Enter your Otp"
            value={otp}
            onChange={(e)=> setOtp(e.target.value)}

            
            
          /> */}
                            <Paper
                                elevation={3}
                                sx={{
                                    height: 200,
                                    width: 265,
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: "#f9fafb",
                                }}
                            >
                                <Box sx={{ paddingTop: 4 }}>
                                    <Typography sx={{ paddingBottom: 2 }}>
                                        Enter your Otp below..
                                    </Typography>
                                    <OTPInput
                                        value={OTP}
                                        onChange={setOTP}
                                        autoFocus
                                        OTPLength={4}
                                        otpType="number"
                                        disabled={false}
                                        inputStyles={{
                                            height: 45,
                                            width: 45,
                                            borderRadius: 10,
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Box margin={4}>
                                <Button variant="contained">
                                    <Icon
                                        icon="mdi:email-resend-outline"
                                        width="30"
                                        height="30"
                                    />
                                    <OtpTimer
                                        color={"white"}
                                        background={"#00000000"}
                                        minutes={1}
                                        seconds={0}
                                        text="Time:"
                                        ButtonText="Resend"
                                        resend={submit}
                                    />
                                </Button>
                            </Box>

                            <LoadingButton
                                variant="contained"
                                onClick={handleVerify}
                            >
                                <Icon
                                    icon="uil:comment-verify"
                                    width="30"
                                    height="30"
                                />
                                {loading ? <Loader3D /> : "Verify"}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                )}
            </div>
        </FormikProvider>
    );
}
