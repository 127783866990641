import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../components/mui/Dialogue/GlobalDialog";
import { LogButton } from "../../../components/mui/Buttons/Buttons";
import { Box, Paper, Stack, Table, TableCell, TableRow } from "@mui/material";
import { Icon } from "@iconify/react";
import GlobalWrapper from "../../../components/Wrapper/GlobalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getLeadDetails } from "../../../redux/features/cartSlice";

const LeadSingleView = ({ data, rowData }) => {
  const { leadDetails } = useSelector((state) => state.cart);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (rowData.lead_id && open) {
      dispatch(getLeadDetails(rowData.lead_id));
    }
  }, [open, rowData]);

  const mappableArray = Object.entries(leadDetails ? leadDetails : {}).map(
    ([key, value]) => ({
      key,
      value,
    })
  );

  return (
    <GlobalDialog
      title="Lead View"
      open={open}
      setOpen={setOpen}
      fullWidth
      closeButton={true}
      maxWidth={"sm"}
      popupButton={
        <LogButton size="small" action={() => setOpen(true)}>
          Lead View
        </LogButton>
      }
      content={
        <>
          <GlobalWrapper>
            <Paper
              sx={{
                margin: 1,
                borderRadius: 4,
              }}
            >
              <Table>
                {mappableArray.map((item) => {
                  return (
                    <TableRow key={item.key}>
                      <TableCell variant="head" sx={{ padding: 1 }}>
                        <Stack direction={"row"} spacing={1}>
                          <Box>
                            <Icon
                              color="#0089d0"
                              icon="hugeicons:paragraph-bullets-point-01"
                              width="20"
                              height="20"
                            />
                          </Box>

                          <Box>{item.key}</Box>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ padding: 0.5 }}>{item.value}</TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Paper>
          </GlobalWrapper>
        </>
      }
    />
  );
};

export default LeadSingleView;
