import React from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Box, Button } from "@mui/material";
import { LogData } from "./LogData";
import { useDispatch } from "react-redux";
import { getCroStudentEnquiryLog } from "../../../../redux/features/croSlice";

export const EnquiryLog = ({ data }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                maxWidth="lg"
                fullWidth
                closeButton={true}
                open={open}
                setOpen={setOpen}
                title="Enquiry Log"
                popupButton={
                    <Box>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setOpen(true);
                                dispatch(
                                    getCroStudentEnquiryLog({
                                        courseType: "1",
                                        subjectEnrollmentId:
                                            data.subjectEnrollmentId,
                                    })
                                );
                            }}
                        >
                            View
                        </Button>
                    </Box>
                }
                content={<LogData />}
            />
        </div>
    );
};
