export const VALUES = [
    { id: "CUSTOM_SINGLE", value: "Single" },
    { id: "CUSTOM_GROUP", value: "Group" },
    { id: "BROADCAST", value: "Broadcast" },
];
export const STATUS = [
    { id: "complete", content: "Compleated" },
    { id: "incomplete", content: "Incomplete" },
];

const TRAINER_PREFFERED = [
    { value: "Male" },
    { value: "Female" },
    { value: "Not Preffered" },
];
const ADMISSION_FEE_STATUS = [{ value: "Paid" }, { value: "Not Paid" }];
const FEE_STATUS = [{ value: "Pending" }, { value: "Paid" }];

export { TRAINER_PREFFERED, ADMISSION_FEE_STATUS, FEE_STATUS };
