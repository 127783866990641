import { Box, List, Paper, Typography } from "@mui/material";
import React from "react";

const SpeakProReportData = ({ data }) => {
  return (
    <Box>
      <Paper
        style={{
          margin: "16px 0",
          padding: "16px",
        }}
      >
        {data?.map((item) => {
          return (
            <Box key={item.matrixId}>
              <Typography variant="h4" gutterBottom>
                {item.matrixName}
              </Typography>
              <List sx={{ padding: 4 }}>
                <Box>
                  <li>
                    <ul>
                      <li>
                        <Box
                         component="span"
                         sx={{
                           display: "block", 
                           textAlign: "justify", 
                         }}
                        >{item.checkedDescription}</Box>
                      </li>
                    </ul>
                  </li>
                </Box>
              </List>
            </Box>
          );
        })}
      </Paper>
    </Box>
  );
};

export default SpeakProReportData;
