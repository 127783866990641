import { Box, Container, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import ListItem from './ListItem'
import { useSelector } from 'react-redux'

const Details = ({ storedStudentData }) => {
  // const { assessmentFormData } = useSelector((state) => state.assessmentTool);
  // const storedStudentData = localStorage.getItem("studentData");
  let assessmentFormData
  if (storedStudentData) {
    assessmentFormData = storedStudentData
  } else {
    assessmentFormData = JSON.parse(localStorage.getItem('studentData'))
  }

  return (
    <Box pt={3}>
      <Container sx={{ margin: 8 }}>
        <Box>
          <Typography
            variant="h4"
            sx={{
              color: '#3a6bae',
              fontFamily: 'Lora, sans-serif',
            }}>
            DEMOGRAPHIC DETAILS
          </Typography>
          {assessmentFormData ? (
            <>
              <ListItem
                keyName={'Name'}
                val={assessmentFormData.name}
                variant="default"
                fontFamily="Lora, sans-serif"
                fontSize="18px"
              />
              <ListItem
                keyName={'Place'}
                val={assessmentFormData.place}
                variant="default"
                fontFamily="Lora, sans-serif"
                fontSize="18px"
              />
              <ListItem
                keyName={'Phone'}
                val={assessmentFormData.contactNumber}
                variant="default"
                fontFamily="Lora, sans-serif"
                fontSize="18px"
              />
            </>
          ) : null}
        </Box>
        <Box pt={1}>
          <Typography
            variant="h4"
            sx={{
              color: '#3a6bae',
              fontFamily: 'Lora, sans-serif',
            }}>
            COURSE INFORMATION
          </Typography>
          {assessmentFormData ? (
            <>
              <ListItem
                keyName={'Course Name'}
                val={assessmentFormData?.courseFullName}
                variant="default"
                fontFamily="Lora, sans-serif"
                fontSize="18px"
              />
              <ListItem
                keyName={'Combination'}
                val={assessmentFormData.combination}
                variant="default"
                fontFamily="Lora, sans-serif"
                fontSize="18px"
              />
              <ListItem
                keyName={'Course Duration'}
                val={assessmentFormData.courseDuration}
                variant="default"
                fontFamily="Lora, sans-serif"
                fontSize="18px"
              />
              <ListItem
                keyName={'Date Of Assessment'}
                val={assessmentFormData.dateOfAssessment}
                variant="default"
                fontFamily="Lora, sans-serif"
                fontSize="18px"
              />
            </>
          ) : null}
        </Box>
      </Container>
    </Box>
  )
}

export default Details
