import React, { useEffect, useState } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import { Autocomplete, Box, Paper, Stack, TextField } from '@mui/material'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import dayjs from 'dayjs'
import { ogaAdmissionRegisterTableHeader } from '../../../../components/datagrid/myworkspace/ogd/AdmissionRegister/ogaAdmissionRegisterTableHeader'
import { getAdmissionRegisterOgd, getEmployeesUnderHead, getStudentsProfile } from '../../../../redux/features/ogdSlice'
import { useDispatch, useSelector } from 'react-redux'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import EditTer1Fee from '../../../../components/datagrid/myworkspace/ogd/AdmissionRegister/EditTer1Fee'
import EditStudentAction from '../../../../components/datagrid/myworkspace/ogd/AdmissionRegister/EditStudentAction'
import ActionDetails from '../../../../components/mui/GlobalAnchor/ActionDetails'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import GlobalAnchorList from '../../../../components/mui/GlobalAnchor/GlobalAnchorList'
import ProfileViewAdmissionRegister from '../../../../components/datagrid/myworkspace/ogd/AdmissionRegister/ProfileViewAdmissionRegister'
import { SroRemark } from '../../../../components/datagrid/CRM/CRM Leads/SroRemark'
import FeeStructureViewAdmissionRegister from '../../../../components/datagrid/myworkspace/ogd/AdmissionRegister/FeeStructureViewAdmissionRegister'
import PdfView from '../../../../components/datagrid/myworkspace/ogd/AdmissionRegister/PdfView'

const AdmissionRegisterOgd = () => {
  const [rowCount, setRowCount] = useState(0)
  const [selectedYear, setSelectedYear] = useState(dayjs())
  const [selectedSroId, setSelectedSroId] = useState('')
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData

  const data = {
    page: pageNum,
    limit: gridSize,
    year: selectedYear.format('YYYY'),
    id: selectedSroId,
    search,
  }

  const { loading, admissionRegisterData, meta, employeesList } = useSelector((state) => state.ogd)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEmployeesUnderHead())
  }, [])

  useEffect(() => {
    dispatch(savePaginationData({ ...paginationData, pageNum: 1 }))
  }, [search, selectedYear, selectedSroId])

  useEffect(() => {
    dispatch(getAdmissionRegisterOgd(data))
  }, [gridSize, pageNum, selectedYear, selectedSroId, search])

  useEffect(() => {
    setRowCount(meta?.totalRecords || 0)
  }, [meta])

  const handleSroChange = (e, option) => {
    setSelectedSroId(option?.userID || '')
  }

  return (
    <Wrapper
      title={'Admission Register'}
      Content={
        <>
          <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
            <DatePickerCustom
              maxWidth="sm"
              fullWidth={true}
              value={selectedYear}
              setValue={setSelectedYear}
              viewDetails={['year']}
            />
            <Box>
              <Autocomplete
                sx={{ minWidth: 270 }}
                name="sroName"
                id="combo-box-demo"
                options={employeesList}
                getOptionLabel={(option) => option.name || ''}
                value={employeesList.find((sro) => sro.userID === selectedSroId) || null}
                onChange={handleSroChange}
                renderInput={(params) => (
                  <TextField disabled fullWidth autoComplete="off" {...params} label="Select OGA" />
                )}
              />
            </Box>
          </Stack>

          <Paper sx={{ mt: 2 }} elevation={3}>
            <Box sx={{ width: '100%', padding: 0 }}>
              <GlobalDatagrid
                tableHead={ogaAdmissionRegisterTableHeader}
                rows={admissionRegisterData}
                meta={rowCount}
                rowUniqueId="sl_no"
                loading={loading}
                rowClick={true}
                actionComponent={
                  <>
                    <ActionDetails val={rowData?.studentName} />
                    <GlobalAnchorList
                      label="Profile"
                      component={
                        <ProfileViewAdmissionRegister
                          url={getStudentsProfile}
                          storeName={'ogd'}
                          courseEnrollmentId={rowData?.courseEnrollmentId}
                        />
                      }
                    />
                    <GlobalAnchorList label="Assessment Report" component={PdfView(rowData)} />
                    <GlobalAnchorList label="SRO" component={<SroRemark {...rowData} />} />
                    <GlobalAnchorList
                      label="Fee Details"
                      component={
                        <FeeStructureViewAdmissionRegister
                          noFirstTermFee={rowData?.firstTermFee == undefined || rowData?.firstTermFee == null}
                          courseEnrollmentId={rowData?.courseEnrollmentId}
                        />
                      }
                    />
                    <GlobalAnchorList label="Term 1 Fee" component={<EditTer1Fee data={data} row={rowData} />} />
                    <GlobalAnchorList label="Student" component={<EditStudentAction data={data} row={rowData} />} />
                  </>
                }
              />
            </Box>
          </Paper>
        </>
      }
    />
  )
}

export default AdmissionRegisterOgd
