import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import useResponsive from "../../../../../Hooks/useResponsive";

export const SubjectEnrollmentStudentOgaDetails = ({ params }) => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const MyTextField = ({ label, value, multiline, rows }) => {
        return (
            <TextField
                label={label}
                inputProps={{ readOnly: true }}
                fullWidth
                variant="filled"
                defaultValue={value}
                multiline={multiline}
                rows={rows}
            />
        );
    };
    return (
        <div>
            <Box>
                <Stack direction={smUp ? "row" : "column"} spacing={1}>
                    <MyTextField label="OGA" value={params.oga} />
                    <MyTextField
                        label="Fee Per Session"
                        value={params.ogaRemarksFeePerSession}
                    />
                </Stack>
                <Stack direction={smUp ? "row" : "column"} spacing={1} mt={1}>
                    <MyTextField
                        label="Class Per Week"
                        value={params.ogaRemarksClassPerWeek}
                    />
                    <MyTextField
                        label="Time Preferred"
                        value={params.ogaRemarksTimePreferred}
                    />
                </Stack>
                <Stack direction={smUp ? "row" : "column"} spacing={1} mt={1}>
                    <MyTextField
                        label="Trainer Preferred"
                        value={params.ogaRemarksTrainerPreferred}
                    />
                    <MyTextField
                        label="Total Sessions"
                        value={params.ogaRemarksTotalSessions}
                    />
                    <MyTextField
                        label="Total Fee"
                        value={params.ogaRemarksTotalFee}
                    />
                </Stack>
                <Stack direction={smUp ? "row" : "column"} spacing={1} mt={1}>
                    <MyTextField
                        label="OGA Remarks"
                        value={params.ogaRemarks}
                        multiline
                        rows={3}
                    />
                    <MyTextField
                        label="OGA Assessment Remarks"
                        value={params.ogaAssessmentRemarks}
                        multiline
                        rows={3}
                    />
                </Stack>
            </Box>
        </div>
    );
};
