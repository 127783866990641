import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import HrRoute from "../route/myworkspace/HrRoute";
import { HodRouter, HrRouter } from "./myworkspace";
import useRoles from "../../Hooks/useRoles";
import HodRoute from "../route/HodRoute";
import MyStudentsTable from "../../views/cro/MyStudents";
import { useSelector } from "react-redux";
import CourseRegistration from "../../views/speakpro/myworkspace/CourseRegistration";
import Subject from "../../views/speakpro/myworkspace/Subject";
import MatrixLevel from "../../views/speakpro/myworkspace/MatrixLevel";
import Objectives from "../../views/speakpro/myworkspace/Objectives";
import Grade from "../../views/speakpro/myworkspace/Grade";
import Description from "../../views/speakpro/myworkspace/Description";
import ReportGeneration from "../../views/speakpro/myworkspace/Report/ReportGeneration";
import AssessmentReports from "../../views/speakpro/myworkspace/AssessmentReports";

const SpeakProRouter = () => {
    useRoles("speakpro tool");
    const { role } = useSelector((state) => state);

    return (
        <Routes>
            <Route
                path="student-registration"
                element={<CourseRegistration />}
            />
            <Route path="create-subject" element={<Subject />} />
            <Route path="matrix-level" element={<MatrixLevel />} />
            <Route path="objectives" element={<Objectives />} />
            <Route path="grade" element={<Grade />} />
            <Route path="description" element={<Description />} />
            <Route path="report-generation" element={<ReportGeneration />} />
            <Route path="assessment-reports" element={<AssessmentReports />} />
        </Routes>
    );
};

export default SpeakProRouter;
