import React from "react";
import "../css/loader/slideloader.css";
import { Box } from "@mui/material";

export const SlideLoader = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="loader"></div>
        </Box>
    );
};
