import React from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";
import { InactiveData } from "../AdmissionRegister/AdmissionStatus/InactiveData";

export const TerminationClearance = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Termination Clearance"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"lg"}
                closeButton={true}
                popupButton={
                    <Button
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        view
                    </Button>
                }
                content={<InactiveData />}
            />
        </div>
    );
};
