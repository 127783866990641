export const BYPASS_COUNT_TABLE_HEAD = [
    {
        field: "serialNumber",
        minWidth: 90,
        headerName: "Sl No",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "reportingHeadName",
        flex: 1,
        minWidth: 200,
        headerName: "Reporting Head Name",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "bypassCount",
        flex: 1,
        minWidth: 200,
        headerName: "Bypass Count",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
];
