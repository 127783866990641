import Page from "../../../components/Page";
import {
    Typography,
    Container,
    Box,
    Paper,
    Stack,
    Button,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getMyTask,
    filterTasks,
    clearAll,
} from "../../../redux/features/todoSlice";
import { MYTASK_TABLE_HEAD as columns } from "../../../components/datagrid/Todo/MytaskTableHead";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useResponsive from "../../../Hooks/useResponsive";
import BasicBadge from "../../../components/mui/Badge";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function MyTasks() {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const mytask = useSelector((state) => state.todo);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            dispatch(filterTasks("Pending"));
        } else if (newValue === 1) {
            dispatch(filterTasks("In Progress"));
        }
        if (newValue === 2) {
            dispatch(filterTasks("Completed"));
        }
    };
    useEffect(() => {
        dispatch(getMyTask());
        return () => {
            dispatch(clearAll());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const data = {
        value1: "12",
        value2: "88",
        value3: "100",
    };
    // useEffect(() => {
    //
    // },[mytask])

    return (
        <div>
            {smUp && (
                <Page title="My Tasks">
                    <Container component="main">
                        <Typography variant="h4" sx={{ mb: 1 }}>
                            My Tasks
                        </Typography>
                        <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                        <Box>
                            <Paper
                                elevation={3}
                                sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                            >
                                <Box
                                    sx={{
                                        backgroundColor: "grey.200",
                                        borderRadius: "8px 8px 0 0",
                                        display: "flex",
                                    }}
                                >
                                    <Box sx={{ width: "100%" }}>
                                        <Box
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                            }}
                                        >
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="basic tabs example"
                                            >
                                                <Tab
                                                    sx={{ minHeight: 48 }}
                                                    icon={
                                                        <BasicBadge
                                                            color={"primary"}
                                                            content={
                                                                mytask
                                                                    .statistics
                                                                    .pending
                                                            }
                                                        >
                                                            <Icon
                                                                icon="ic:baseline-pending-actions"
                                                                width="25"
                                                                height="16"
                                                            />
                                                        </BasicBadge>
                                                    }
                                                    iconPosition="end"
                                                    label="Pending"
                                                    {...a11yProps(0)}
                                                />
                                                <Tab
                                                    sx={{ minHeight: 48 }}
                                                    icon={
                                                        <BasicBadge
                                                            color={"primary"}
                                                            content={
                                                                mytask
                                                                    .statistics
                                                                    .inprogress
                                                            }
                                                        >
                                                            <Icon
                                                                icon="mdi:progress-clock"
                                                                width="25"
                                                                height="16"
                                                            />
                                                        </BasicBadge>
                                                    }
                                                    iconPosition="end"
                                                    label="In Progress"
                                                    {...a11yProps(1)}
                                                />

                                                <Tab
                                                    sx={{ minHeight: 48 }}
                                                    icon={
                                                        <BasicBadge
                                                            color={"primary"}
                                                            content={
                                                                mytask
                                                                    .statistics
                                                                    .completed
                                                            }
                                                        >
                                                            <Icon
                                                                icon="fluent-mdl2:completed"
                                                                width="25"
                                                                height="16"
                                                            />
                                                        </BasicBadge>
                                                    }
                                                    iconPosition="end"
                                                    label="Completed"
                                                    {...a11yProps(2)}
                                                />
                                            </Tabs>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{ width: "100%", padding: 0 }}>
                                    <DataGrid
                                        loading={mytask.loading}
                                        rows={mytask.filtered}
                                        columns={columns}
                                        pageSize={5}
                                        autoHeight
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        getRowId={(row) => row.serialNumber}
                                        components={{
                                            Toolbar: CustomGridToolbar,
                                        }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: {
                                                    debounceMs: 500,
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </Container>
                </Page>
            )}
            {!smUp && (
                <Page title="My Tasks">
                    <Container component="main">
                        <Typography variant="h4" sx={{ mb: 1 }}>
                            My Tasks
                        </Typography>
                        <Breadcrumb sx={{ paddingLeft: 0.3 }} />

                        <Box>
                            <Paper
                                elevation={3}
                                sx={{ borderRadius: 1, width: "100%" }}
                            >
                                <Box
                                    sx={{
                                        height: 48,
                                        backgroundColor: "grey.200",
                                        borderRadius: "8px 8px 0 0",
                                    }}
                                >
                                    <Box sx={{ width: "100%" }}>
                                        <Box
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: "divider",
                                            }}
                                        >
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                aria-label="basic tabs example"
                                            >
                                                <Tab
                                                    sx={{ minHeight: 48 }}
                                                    icon={
                                                        <BasicBadge
                                                            color={"primary"}
                                                            content={
                                                                mytask
                                                                    .statistics
                                                                    .pending
                                                            }
                                                        >
                                                            <Icon
                                                                icon="ic:baseline-pending-actions"
                                                                width="25"
                                                                height="16"
                                                            />
                                                        </BasicBadge>
                                                    }
                                                    iconPosition="end"
                                                    label="Pending"
                                                    {...a11yProps(0)}
                                                />
                                                <Tab
                                                    sx={{ minHeight: 48 }}
                                                    icon={
                                                        <BasicBadge
                                                            color={"primary"}
                                                            content={
                                                                mytask
                                                                    .statistics
                                                                    .inprogress
                                                            }
                                                        >
                                                            <Icon
                                                                icon="mdi:progress-clock"
                                                                width="25"
                                                                height="16"
                                                            />
                                                        </BasicBadge>
                                                    }
                                                    iconPosition="end"
                                                    label="In Progress"
                                                    {...a11yProps(1)}
                                                />

                                                <Tab
                                                    sx={{ minHeight: 48 }}
                                                    icon={
                                                        <BasicBadge
                                                            color={"primary"}
                                                            content={
                                                                mytask
                                                                    .statistics
                                                                    .completed
                                                            }
                                                        >
                                                            <Icon
                                                                icon="fluent-mdl2:completed"
                                                                width="25"
                                                                height="16"
                                                            />
                                                        </BasicBadge>
                                                    }
                                                    iconPosition="end"
                                                    label="Completed"
                                                    {...a11yProps(2)}
                                                />
                                            </Tabs>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box sx={{ width: "100%", padding: 0 }}>
                                    <DataGrid
                                        loading={mytask.loading}
                                        rows={mytask.data}
                                        columns={columns}
                                        pageSize={5}
                                        autoHeight
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        getRowId={(row) => row.serialNumber}
                                        components={{
                                            Toolbar: CustomGridToolbar,
                                        }}
                                        componentsProps={{
                                            toolbar: {
                                                showQuickFilter: true,
                                                quickFilterProps: {
                                                    debounceMs: 500,
                                                },
                                            },
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </Container>
                </Page>
            )}
        </div>
    );
}
