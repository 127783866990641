import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import { Form, FormikProvider, useFormik } from "formik";
import { Box, IconButton, Stack, TextField } from "@mui/material";
import GlobalSelectField from "../mui/GlobalSelectField";
import { CancelButton, SubmitButton } from "../mui/Buttons/Buttons";
import {
    cancelSession,
    getSessionCancellationReasons,
} from "../../redux/features/croSlice";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import { previousSession } from "../../redux/features/scheduleSlice";

export const CancelScheduledSession = ({ params, handleClose }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.cro);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const schedule = useSelector((state) => state.schedule);

    const Validation = Yup.object().shape({
        reasonId: Yup.string().required("reason is required"),
        remarks: Yup.string().required("remark is required"),
    });

    const formik = useFormik({
        initialValues: {
            reasonId: "",
            remarks: "",
            scheduleId: params,
        },
        validationSchema: Validation,
        onSubmit: (values) => {
            dispatch(cancelSession(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    dispatch(previousSession(schedule.subjectEnrollmentId));
                    setOpen(false);
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            {" "}
            <GlobalDialog
                title="Cancel Session"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                popupButton={
                    <>
                        <IconButton
                            size="small"
                            onClick={() => {
                                dispatch(getSessionCancellationReasons());
                                setOpen(true);
                            }}
                        >
                            <Icon
                                icon="fluent:calendar-cancel-16-regular"
                                width="22"
                                height="22"
                                color="#2f72b3"
                            />
                            Cancel Schedule
                        </IconButton>
                    </>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form
                                autoComplete="off"
                                noValidate
                                onSubmit={formik.handleSubmit}
                            >
                                <Box>
                                    <GlobalSelectField
                                        options={{
                                            name: "reasonId",
                                            formik,
                                            sx: {
                                                width: "100%",
                                                "& .MuiInputBase-root": {
                                                    height: 50,
                                                },
                                            },
                                            label: "Reason",
                                            data: data.sessionCancellationReasons,
                                            keys: {
                                                id: "id",
                                                value: "name",
                                            },
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label="Remarks"
                                        fullWidth
                                        name="remarks"
                                        onChange={formik.handleChange}
                                        value={formik.values.remarks}
                                        multiline
                                        rows={3}
                                        error={Boolean(
                                            formik.touched.remarks &&
                                                formik.errors.remarks
                                        )}
                                        helperText={
                                            formik.touched.remarks &&
                                            formik.errors.remarks
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: 3,
                                    }}
                                >
                                    <Stack direction={"row"} spacing={2}>
                                        <CancelButton
                                            size="small"
                                            action={() => {
                                                handleClose();
                                                setOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <SubmitButton
                                            type="submit"
                                            size="small"
                                        >
                                            {data.loading
                                                ? "Canceling..."
                                                : "Cancel Session"}
                                        </SubmitButton>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
