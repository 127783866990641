import { Icon } from "@iconify/react";
import { Chip, Stack } from "@mui/material";
import { UpdateExpenseView } from "../ogmLeads/RenderCellMain";
import DeleteExpense from "./DeleteExpense";
import ToolTip from "../../../../ToolTip/ToolTip";

const approvalStatu = (params) => {
  return (
    <Chip
      label={params.row.expenseApprovalStatus}
      size="small"
      color={
        params.row.expenseApprovalStatus === "Approved" ? "success" : "error"
      }
      icon={
        params.row.expenseApprovalStatus === "Approved" ? (
          <Icon icon="charm:tick" />
        ) : (
          <Icon icon="bitcoin-icons:cross-filled" />
        )
      }
      variant="outlined"
    />
  );
};

// const actions = (params) => {
//   return (
//     <>
//       <Stack direction={"row"} spacing={1}>
//         {/* <UpdateExpenseView {...params.row} /> */}
//         <DeleteExpense params={params.row} />
//       </Stack>
//     </>
//   );
// };
export const EXPENSE_TABLE_HEAD = [
  {
    field: "sl_no",
    headerName: "slNo",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "expenseYear",
    headerName: "Year",
    width: 90,
  },
  {
    field: "expenseMonth",
    headerName: "Month",
    width: 90,
  },
  {
    field: "expenseName",
    headerName: "ExpenseType",
    width: 90,
  },
  {
    field: "studentName",
    headerName: "Student Name",
    width: 300,
  },

  {
    field: "expenseAmount",
    headerName: "Amount",
    width: 90,
  },
  {
    field: "expenseRemarks",
    headerName: "Remarks",
    width: 250,
    renderCell: (params) => {
      return (
        <ToolTip
          content={params.row.expenseRemarks}
          expand={params.row.expenseRemarks}
        />
      );
    },
  },
  {
    field: "timestamp",
    headerName: "timestamp",
    width: 180,
  },
  {
    field: "accountHolder",
    headerName: "A/C Holder",
    width: 250,
  },
  {
    field: "ifsc",
    headerName: "IFSC",
    width: 150,
  },
  {
    field: "accountNumber",
    headerName: "A/C Number",
    width: 250,
  },
  {
    field: "transactionDate",
    headerName: "Transaction Date",
    width: 150,
    // headerAlign: "left",
    // align: "left",
  },
  {
    field: "paymentMode",
    headerName: "Payment Mode",
    width: 150,
  },
  {
    field: "expenseApprovalStatus",
    headerName: "Approval Status",
    width: 160,
    renderCell: approvalStatu,
  },
  // {
  //   field: "action",
  //   headerName: "Action",

  //   width: 170,
  //   renderCell: actions,
  // },
];

export const TP_TABLE_HEAD = [
  {
    field: "sl_no",
    headerName: "slNo",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "expenseName",
    headerName: "expense Tpe",
    width: 180,
  },
  {
    field: "transactionDate",
    headerName: "Transaction Date",
    width: 120,
  },
  {
    field: "expenseAmount",
    headerName: "Amount",
    width: 90,
  },
  {
    field: "studentName",
    headerName: "Student Name",
    width: 250,
  },
  {
    field: "subjectName",
    headerName: "Subject",
    width: 150,
  },
  {
    field: "tutorName",
    headerName: "Tutor Name",
    width: 250,
  },
  {
    field: "tutorPayment",
    headerName: "tutor Payment",
    width: 90,
  },
  {
    field: "timestamp",
    headerName: "Time Stamp",
    width: 200,
  },
  {
    field: "actionButton",
    headerName: "Action ",
    width: 90,
  },
];
