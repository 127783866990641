import { memo } from "react";
import AssignOga from "./AssignOga";
import OgaStatusLog from "./OgaStatusLog";
import OgaEnquiryLog from "./OgaEnquiryLog";
import OgaSubjectWiseAssessmentData from "./OgaSubjectWiseAssessmentData";
import { UpdateExpense } from "../expense/UpdateExpense";
import UpdateLeads from "./UpdateLead";

const AssignOgaView = memo((params) => {
  return <AssignOga params={params} />;
});
const OgaStatusLogView = memo((params) => {
  return <OgaStatusLog params={params} />;
});
const OgaEnquiryLogView = memo((params) => {
  return <OgaEnquiryLog params={params} />;
});
const OgaSubjectWiseAssessmentDataView = memo((params) => {
  return <OgaSubjectWiseAssessmentData params={params} />;
});
const UpdateExpenseView = memo(({ paginationData, params, paramsData }) => {
  return (
    <UpdateExpense
      params={params}
      paginationData={paginationData}
      paramsData={paramsData}
    />
  );
});
const UpdateLeavdView = memo(({ updateData, rowData }) => {
  return <UpdateLeads params={updateData} rowData={rowData} />;
});
export {
  AssignOgaView,
  OgaStatusLogView,
  OgaEnquiryLogView,
  OgaSubjectWiseAssessmentDataView,
  UpdateExpenseView,
  UpdateLeavdView,
};
