import { useState } from "react";
import BasicTextField from "../../../components/mui/TextField";
import BasicButton from "../../../components/mui/Button";

import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import useResponsive from "../../../Hooks/useResponsive";
import { Divider } from "@mui/material";
import {
    Stack,
    Box,
    Autocomplete,
    Chip,
    Skeleton,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    MenuItem,
} from "@mui/material";

import { useEffect } from "react";
import { DateTimePicker } from "@mui/lab";

function Tasks({
    priorities,
    formik,
    employees,
    loading,
    error,
    errorManeger,
}) {
    const [empoloys, setEmploys] = useState([]);
    const [dateTime, setDateTime] = useState("");

    const handleTask = (e, index) => {
        const temp = [...formik.values.taskDetails];
        temp[index].task = e.target.value;
        formik.setFieldValue("taskDetails", temp);
        errorManeger(formik.values.taskDetails);
    };

    const handleDateAndTime = (e, index) => {
        setDateTime(e);
        if (e !== null) {
            const date = e.toISOString().split("T")[0];
            const Ftime = `${new Date(e).getHours()}:${new Date(
                e
            ).getMinutes()}:00`;
            const temp = [...formik.values.taskDetails];
            temp[index].deadLineTime = Ftime;
            temp[index].deadLineDate = date;
            formik.setFieldValue("taskDetails", temp);
            errorManeger(formik.values.taskDetails);
        }
    };
    const handleChangePriority = (e, index) => {
        const temp = [...formik.values.taskDetails];
        temp[index].priorityId = e.target.value;
        formik.setFieldValue("taskDetails", temp);
        errorManeger(formik.values.taskDetails);
    };

    const handleAssignedTo = (items, index) => {
        const temp = [...formik.values.taskDetails];
        temp[index].assignedTo = items;
        formik.setFieldValue("taskDetails", temp);
        errorManeger(formik.values.taskDetails);
    };

    const handleRemove = (index) => {
        const temp = [...formik.values.taskDetails];
        temp.splice(index, 1);
        formik.setFieldValue("taskDetails", temp);
        errorManeger(formik.values.taskDetails);
    };

    const handleAdd = () => {
        formik.setFieldValue("taskDetails", [
            ...formik.values.taskDetails,
            {
                priorityId: "",
                task: "",
                deadLineDate: "",
                deadLineTime: "",
                assignedTo: [],
            },
        ]);
    };

    useEffect(() => {
        if (employees.length > 0) {
            setEmploys(employees);
        }
    }, [employees]);

    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");

    return (
        // <form autoComplete="off">
        <div>
            {smUp && (
                <>
                    <Typography
                        variant="h4"
                        sx={{ marginLeft: 5, marginBottom: 3 }}
                    >
                        Task Details
                        <Divider
                            sx={{ borderBottomWidth: 4, marginRight: 6 }}
                            variant="middle"
                        />
                    </Typography>

                    {formik.values.taskDetails.map((singleService, index) => (
                        <div key={index}>
                            <Paper
                                sx={{
                                    backgroundColor: "grey.200",
                                    width: "95%",
                                    paddingTop: 1,
                                    paddingBottom: 2,
                                    marginBottom: 2,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    border: `${
                                        error.index.includes(index)
                                            ? "1px solid red"
                                            : null
                                    }`,
                                }}
                            >
                                <Box sx={{ margin: 5 }}>
                                    <Stack direction="row" alignItems="center">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DateTimePicker
                                                label="Dead Line"
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                                value={dateTime}
                                                onChange={(e) => {
                                                    handleDateAndTime(e, index);
                                                }}
                                            />
                                        </LocalizationProvider>

                                        <FormControl
                                            sx={{
                                                m: 1,
                                                minWidth: 160,
                                                flex: 1,
                                            }}
                                            size="medium"
                                        >
                                            <InputLabel id="demo-simple-select-label">
                                                Priority
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                label="Priority"
                                                onChange={(e) => {
                                                    handleChangePriority(
                                                        e,
                                                        index
                                                    );
                                                }}
                                                value={singleService.priorityId}
                                            >
                                                {priorities?.map((item) => (
                                                    <MenuItem
                                                        value={
                                                            item.task_priority_id
                                                        }
                                                        key={
                                                            item.task_priority_id
                                                        }
                                                    >
                                                        {
                                                            item.task_priority_name
                                                        }
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText id="course-helper"></FormHelperText>
                                        </FormControl>

                                        {loading ? (
                                            <Skeleton
                                                variant="rectangular"
                                                height={56}
                                                sx={{
                                                    m: 1,
                                                    minWidth: 160,
                                                    flex: 2,
                                                    borderRadius: 1,
                                                }}
                                                size="medium"
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    m: 1,
                                                    minWidth: 160,
                                                    flex: 2,
                                                }}
                                            >
                                                <Autocomplete
                                                    name="assignedTo"
                                                    multiple
                                                    id="tags-filled"
                                                    options={empoloys}
                                                    getOptionLabel={(option) =>
                                                        option.name
                                                    }
                                                    onChange={(e, value) => {
                                                        formik.handleChange(e);
                                                        let items = [];
                                                        value.map((item) => {
                                                            items.push(
                                                                item.userID
                                                            );
                                                        });
                                                        handleAssignedTo(
                                                            items,
                                                            index
                                                        );
                                                    }}
                                                    freeSolo
                                                    renderTags={(
                                                        value,
                                                        getTagProps
                                                    ) =>
                                                        value.map(
                                                            (option, index) => (
                                                                <Chip
                                                                    variant="outlined"
                                                                    label={
                                                                        option.name
                                                                    }
                                                                    {...getTagProps(
                                                                        {
                                                                            index,
                                                                        }
                                                                    )}
                                                                />
                                                            )
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Assigned to"
                                                            placeholder="name"
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        )}
                                    </Stack>
                                    <BasicTextField
                                        name="task"
                                        type="text"
                                        id="service"
                                        multiline
                                        placeholder="Enter task..."
                                        rows={4}
                                        value={singleService.task}
                                        action={(e) => handleTask(e, index)}
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ step: 300 }}
                                        sx={{
                                            width: "100%",
                                            marginTop: "15px",
                                        }}
                                        label="Task"
                                        error={Boolean(formik.errors.task)}
                                    />
                                </Box>
                                <Box sx={{ margin: 5 }}>
                                    <Box
                                        sx={{
                                            marginTop: "10px",
                                            display: "flex",
                                            gap: 1,
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        {formik.values.taskDetails.length !==
                                            1 && (
                                            <Box>
                                                <BasicButton
                                                    type="button"
                                                    variant="contained"
                                                    action={() =>
                                                        handleRemove(index)
                                                    }
                                                >
                                                    Delete Task
                                                </BasicButton>
                                            </Box>
                                        )}
                                        {formik.values.taskDetails.length -
                                            1 ===
                                            index &&
                                            formik.values.taskDetails.length <
                                                4 && (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: 1,
                                                    }}
                                                >
                                                    <BasicButton
                                                        variant="contained"
                                                        type="button"
                                                        action={handleAdd}
                                                    >
                                                        Add Task
                                                    </BasicButton>
                                                </Box>
                                            )}
                                    </Box>
                                </Box>
                            </Paper>
                            {error.index.includes(index) ? (
                                <p
                                    style={{
                                        marginLeft: "33px",
                                        fontSize: "13px",
                                        color: "red",
                                    }}
                                >
                                    Please fill all fields
                                </p>
                            ) : null}
                        </div>
                    ))}
                </>
            )}
            {!smUp && (
                <>
                    <Typography
                        variant="h4"
                        sx={{ marginLeft: 5, marginBottom: 3 }}
                    >
                        Task Details
                        <Divider
                            sx={{ borderBottomWidth: 4, marginRight: 6 }}
                            variant="middle"
                        />
                    </Typography>
                    {formik.values.taskDetails.map((singleService, index) => (
                        <div key={index}>
                            <Paper
                                sx={{
                                    backgroundColor: "grey.200",
                                    width: "95%",
                                    paddingTop: 1,
                                    paddingBottom: 2,
                                    marginBottom: 2,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    border: `${
                                        error.index.includes(index)
                                            ? "1px solid red"
                                            : null
                                    }`,
                                }}
                            >
                                <Box sx={{ margin: 1 }}>
                                    <Stack
                                        direction="column"
                                        alignItems="center"
                                    >
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DateTimePicker
                                                label="Dead Line"
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                                value={
                                                    singleService.deadLineDate
                                                }
                                                onChange={(e) => {
                                                    handleDateAndTime(e, index);
                                                }}
                                            />
                                        </LocalizationProvider>

                                        <FormControl
                                            sx={{
                                                m: 1,
                                                minWidth: "100%",
                                                flex: 1,
                                            }}
                                            size="medium"
                                        >
                                            <InputLabel id="demo-simple-select-label">
                                                Priority
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                label="Priority"
                                                onChange={(e) => {
                                                    handleChangePriority(
                                                        e,
                                                        index
                                                    );
                                                }}
                                                value={singleService.priorityId}
                                            >
                                                {priorities?.map((item) => (
                                                    <MenuItem
                                                        value={
                                                            item.task_priority_id
                                                        }
                                                        key={
                                                            item.task_priority_id
                                                        }
                                                    >
                                                        {
                                                            item.task_priority_name
                                                        }
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText id="course-helper"></FormHelperText>
                                        </FormControl>

                                        {loading ? (
                                            <Skeleton
                                                variant="rectangular"
                                                height={56}
                                                sx={{
                                                    m: 1,
                                                    minWidth: 160,
                                                    flex: 2,
                                                    borderRadius: 1,
                                                }}
                                                size="medium"
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    m: 0,
                                                    minWidth: "100%",
                                                    flex: 2,
                                                }}
                                            >
                                                <Autocomplete
                                                    name="assignedTo"
                                                    multiple
                                                    id="tags-filled"
                                                    options={empoloys}
                                                    getOptionLabel={(option) =>
                                                        option.name
                                                    }
                                                    onChange={(e, value) => {
                                                        formik.handleChange(e);
                                                        let items = [];
                                                        value.map((item) => {
                                                            items.push(
                                                                item.userID
                                                            );
                                                        });
                                                        handleAssignedTo(
                                                            items,
                                                            index
                                                        );
                                                    }}
                                                    freeSolo
                                                    renderTags={(
                                                        value,
                                                        getTagProps
                                                    ) =>
                                                        value.map(
                                                            (option, index) => (
                                                                <Chip
                                                                    variant="outlined"
                                                                    label={
                                                                        option.name
                                                                    }
                                                                    {...getTagProps(
                                                                        {
                                                                            index,
                                                                        }
                                                                    )}
                                                                />
                                                            )
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Assigned to"
                                                            placeholder="name"
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        )}
                                    </Stack>
                                    <BasicTextField
                                        name="task"
                                        type="text"
                                        id="service"
                                        multiline
                                        placeholder="Enter task..."
                                        rows={4}
                                        value={singleService.task}
                                        action={(e) => handleTask(e, index)}
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ step: 300 }}
                                        sx={{
                                            width: "100%",
                                            marginTop: "15px",
                                        }}
                                        label="Task"
                                        error={Boolean(formik.errors.task)}
                                    />
                                </Box>
                                <Box sx={{ margin: 5 }}>
                                    <Box
                                        sx={{
                                            marginTop: "10px",
                                            display: "flex",
                                            gap: 1,
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        {formik.values.taskDetails.length !==
                                            1 && (
                                            <Box>
                                                <BasicButton
                                                    type="button"
                                                    variant="contained"
                                                    action={() =>
                                                        handleRemove(index)
                                                    }
                                                >
                                                    Delete Task
                                                </BasicButton>
                                            </Box>
                                        )}
                                        {formik.values.taskDetails.length -
                                            1 ===
                                            index &&
                                            formik.values.taskDetails.length <
                                                4 && (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: 1,
                                                    }}
                                                >
                                                    <BasicButton
                                                        variant="contained"
                                                        type="button"
                                                        action={handleAdd}
                                                    >
                                                        Add Task
                                                    </BasicButton>
                                                </Box>
                                            )}
                                    </Box>
                                </Box>
                            </Paper>
                            {error.index.includes(index) ? (
                                <p
                                    style={{
                                        marginLeft: "33px",
                                        fontSize: "13px",
                                        color: "red",
                                    }}
                                >
                                    Please fill all fields
                                </p>
                            ) : null}
                        </div>
                    ))}
                </>
            )}
        </div>
        // </form>
    );
}

export default Tasks;
