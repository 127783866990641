import ToolTip from "../../../../ToolTip/ToolTip";

export const IncomeTableHeaders = [
  {
    field: "sl_no",
    headerName: "Sl No",
    width: 50,
  },
  {
    field: "incomeYear",
    headerName: "Year",
    width: 60,
  },
  {
    field: "incomeMonth",
    headerName: "Month",
    width: 70,
  },
  {
    field: "feeType",
    headerName: "Fee Type",
    width: 150,
  },
  {
    field: "studentName",
    headerName: "Student Name",
    width: 250,
  },
  {
    field: "courseName",
    headerName: "Course",
    width: 230,
  },
  {
    field: "transactionDate",
    headerName: "Transaction Date",
    width: 130,
  },
  {
    field: "incomeAmount",
    headerName: "Amount",
    width: 140,
  },

  {
    field: "creditedAccount",
    headerName: "Credited Ac",
    width: 150,
  },

  {
    field: "paymentMode",
    headerName: "Payment Mode",
    width: 150,
  },
  {
    field: "incomeRemarks",
    headerName: "Remarks",
    width: 150,
    renderCell: (params) => {
      return (
        <ToolTip
          expand={params.row.incomeRemarks}
          content={params.row.incomeRemarks}
        />
      );
    },
  },

  {
    field: "incomeAddedUser",
    headerName: "Added By",
    width: 150,
  },
  // {
  //     field: "cro",
  //     headerName: "CRO",
  //     width: 150,
  // },
  // {
  //     field: "reflectedAtBankOn",
  //     headerName: "Reflected At BankOn",
  //     width: 150,
  // },
  {
    field: "incomeVerification",
    headerName: "Verification",
    width: 100,
  },
  {
    field: "incomeTimestamp",
    headerName: "Time Stamp",
    width: 200,
  },
  // {
  //     field: "duplicate",
  //     headerName: "duplicate",
  //     width: 150,
  // },
];
