import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import headerImg from "./headerimg.png";
import useResponsive from "../../../../Hooks/useResponsive";
import { ReactTyped } from "react-typed";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
const CartHeader = ({
  setSelectedCourse,
  selectedCourse,
  CourseDetails,
  setTotalCourses,
  availableCartItems,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { leadDetails, loading } = useSelector((state) => state.cart);
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const handleChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedCourse) {
      const isAlreadyInCart = availableCartItems.some(
        (item) => item.id === selectedCourse.id
      );

      if (isAlreadyInCart) {
        enqueueSnackbar("Already Exist in Cart..!", { variant: "error" });
      } else {
        setTotalCourses((prevArray) => {
          const exists = prevArray.some(
            (item) => item.id === selectedCourse.id
          );
          if (!exists) {
            enqueueSnackbar("Course Added Successfully...!", {
              variant: "success",
            });
            return [...prevArray, selectedCourse];
          } else {
            enqueueSnackbar("Already Exist..!", {
              variant: "error",
            });
          }
          return prevArray;
        });
      }
    }
  };
  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }} 
      animate={{ y: 0, opacity: 1 }}
      transition={{ type: "spring", stiffness: 100, damping: 20 }}
    >
      <Box sx={{ position: "relative" }}>
        <Paper
          sx={{
            width: "100%",
            height: smUp ? 210 : 300,
            backgroundColor: "#702dff",
            borderRadius: 3,
            position: "relative", // Important for positioning the image within Paper
          }}
        >
          <Stack direction={mdUp ? "row" : "column"}>
            <Box pt={2}>
              <Typography
                variant="caption"
                sx={{ color: "grey.200", fontSize: 11, pl: 4, pt: 3 }}
              >
                ONLINE COURSE
              </Typography>
              <Box sx={{ padding: 2 }}>
                <Typography variant="h5" sx={{ color: "white" }}>
                  Hello..Choosing the Right Online <br />
                  <ReactTyped
                    strings={[
                      "Course For Students",
                      `Course For ${
                        leadDetails ? leadDetails.name : "Course For Students"
                      }`,
                    ]}
                    typeSpeed={30}
                    backSpeed={30}
                    // attr="placeholder"
                    loop
                  />
                </Typography>
                <Box pt={2}>
                  <Stack direction={smUp ? "row" : "column"} spacing={2}>
                    <Box pt={1.5}>
                      <Button
                        variant="contained"
                        sx={{
                          borderRadius: 4,
                          backgroundColor: "black",
                          color: "white",
                        }}
                      >
                        <Icon icon="hugeicons:course" />
                        <Typography variant="caption">View Courses</Typography>
                      </Button>
                    </Box>
                    <Box>
                      <Box sx={{ minWidth: 300 }}>
                        <FormControl fullWidth>
                          <InputLabel
                            sx={{
                              color: "white", // Default label color
                              "&.Mui-focused": {
                                color: "white", // Focused label color
                              },
                              "&.MuiInputLabel-shrink": {
                                color: "white", // Shrunk label color
                              },
                              "& .MuiSelect-icon": {
                                color: "white", // Change the color of the dropdown arrow icon
                              },
                            }}
                            id="demo-simple-select-label"
                          >
                            Available Courses
                          </InputLabel>
                          <Select
                            sx={{
                              borderRadius: "16px",
                              height: "40px", // Reduce height of the Select component
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: 2,
                                borderColor: "white", // Change border color
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "white", // Change hover border color
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "white", // Change focused border color
                                },
                              "& .MuiInputBase-input": {
                                color: "white", // Ensure the input text is white
                              },
                              transform: "translateY(15%)", // Adjust the label position
                            }}
                            shrink="true"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCourse?.courseName}
                            label="Available Courses"
                            onChange={handleChange}
                          >
                            {CourseDetails.map((item) => {
                              return (
                                <MenuItem key={item.id} value={item}>
                                  {item.courseName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    {/* {selectedCourse != {} ? ( */}
                    <Box pt={1.4}>
                      <Button
                        onClick={handleSubmit}
                        sx={{
                          color: "#702dff",
                          backgroundColor: "white",
                          borderRadius: 3,
                          zIndex: 2,
                          "&:hover": {
                            backgroundColor: "#a125c2",
                            color: "white",
                          },
                        }}
                      >
                        <Icon
                          icon="line-md:plus-circle"
                          height={22}
                          width={22}
                        />
                      </Button>
                    </Box>
                    {/* ) : null} */}
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Stack>
          {/* Image positioned at the bottom-right with reduced opacity */}
          {mdUp ? (
            <Box
              sx={{
                position: "absolute",
                right: 20,
                bottom: 20,
                opacity: 0.6,
                // zIndex: 1,
              }}
            >
              <img src={headerImg} alt="header img" height={180} width={180} />
            </Box>
          ) : null}
        </Paper>
      </Box>
    </motion.div>
  );
};

export default CartHeader;
