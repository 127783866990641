import CopyEmail from "./CopyEmail";
import ContactAction from "./ContactAction";
import ResumeView from "./ResumeView";
import Status from "./Status";
import InterviewAction from "./InterviewAction";
import InterviewStatus from "./InterviewStatus";
import UpdateStatus from "./UpdateStatus";
import { Remarks } from "./Remarks";
export const JOB_APPLICATION_TABLE_HEAD = [
  {
    field: "serialNumber",
    minWidth: 30,
    headerName: "slNo.",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "fullName",
    minWidth: 250,
    headerName: "Name",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "your-email",
    minWidth: 350,
    headerName: "Email",
    type: "number",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => CopyEmail(params),
  },
  {
    field: "phone",
    minWidth: 150,
    headerName: "Phone",
    type: "number",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => ContactAction(params),
  },
  {
    field: "place",
    minWidth: 200,
    headerName: "Place",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "jobPositionName",
    minWidth: 150,
    headerName: "Post",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "appliedOn",
    minWidth: 200,
    headerName: "Applied on",
    type: "date",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "qualification",
    minWidth: 200,
    headerName: "Qualification",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "resume",
    minWidth: 90,
    headerName: "Resume",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => ResumeView(params),
  },
  {
    field: "status",
    minWidth: 150,
    headerName: " CV Status",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => Status(params),
  },
  {
    field: "currentStatus",
    minWidth: 260,
    headerName: " Current Status",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => InterviewStatus(params),
  },
  {
    field: "remarks",
    minWidth: 200,
    headerName: "Remark",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => Remarks(params),
  },
  {
    field: "Interview",
    minWidth: 200,
    headerName: "Take Interview",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => InterviewAction(params),
  },
];
