import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { Chip, Divider, Paper, Stack } from "@mui/material";
import useResponsive from "../../../../../Hooks/useResponsive";
import { CRO_ENQUIRY_TABLE_HEAD as columns } from "../../../../datagrid/CRM/AdmissionRegister/CroEnquiryLog/CroEnquiryTableHead";
import NoDataFound from "../../../../NodataIndication/NoDataFound";

export const TableData = React.memo((params) => {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const data = useSelector((state) => state.crm);
  return (
    <Box sx={{ height: 400, width: "100%", backgroundColor: "grey.200" }}>
      {params.params.params.croEnquiryLogButton === "ClassRoom" ? (
        <>
          <DataGrid
            loading={data.loading}
            rows={data.croEnquiryLog}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            onCellClick={(row, e) => e.stopPropagation()}
            getRowId={(row) => row.serialNumber}
            disableRowSelectionOnClick
          />
        </>
      ) : (
        <>
          {data?.data?.croEnquiryLog === null ? (
            ""
          ) : (
            <Box>
              <NoDataFound />
            </Box>
          )}
          <Box>
            <Paper>
              {data?.data?.croEnquiryLog?.map((eLogs) => {
                return (
                  <Box sx={{ color: "grey.200" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        letterSpacing: 1,
                        fontWeight: "bolder",
                        margin: 2,
                      }}
                    >
                      <Stack
                        direction={smUp ? "row" : "column"}
                        spacing={smUp ? 2 : 1}
                      >
                        <Stack
                          direction={mdUp ? "row" : "column"}
                          spacing={smUp ? 2 : 1}
                        >
                          <Chip
                            label={`Subject:- ${eLogs.subjectName}`}
                            color="primary"
                            variant="filled"
                          />
                          <Chip
                            label={`Trainer:- ${eLogs.trainerName}`}
                            color="primary"
                            variant="filled"
                          />
                        </Stack>
                        <Stack
                          direction={mdUp ? "row" : "column"}
                          spacing={smUp ? 2 : 1}
                        >
                          <Chip
                            label={`Session Complteted:- ${eLogs.sessionsCompleted}`}
                            color="primary"
                            variant="filled"
                          />

                          <Chip
                            label={`Last Session Date:- ${eLogs.lastSessionDate}`}
                            color="primary"
                            variant="filled"
                          />
                        </Stack>
                      </Stack>
                    </Box>
                    <Box height={400} backgroundColor={"grey.200"}>
                      <DataGrid
                        rows={eLogs?.log}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5]}
                        onCellClick={(row, e) => e.stopPropagation()}
                        getRowId={(row) => row.slNo}
                        disableRowSelectionOnClick
                      />
                    </Box>
                  </Box>
                );
              })}
            </Paper>
          </Box>
        </>
      )}
    </Box>
  );
});
