import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import { RecheduleBooking } from "./RecheduleBooking";
import { DeleteBooking } from "./DeleteBooking";
import { color } from "@mui/system";
import { DeleteBookingView, RecheduleBookingView } from "../RendercellMain";
import ToolTip from "../../../ToolTip/ToolTip";

export const MENTORING_BOOKINGS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
        width: 30,
    },
    {
        field: "mentee_name",
        headerName: "User Name",
        width: 250,
    },
    {
        field: "mentor_name",
        headerName: "Mentor Name",
        width: 250,
    },
    {
        field: "booking_date",
        headerName: "Booking Date",
        width: 120,
    },

    {
        field: "from_time",
        headerName: "From Time",
        width: 120,
    },
    {
        field: "to_time",
        headerName: "To Time",
        width: 90,
    },

    {
        field: "purpose",
        headerName: "Purpose",
        width: 150,
    },
    {
        field: "remarks",
        headerName: "Remarks",
        width: 150,
        renderCell: (params) => {
            return (
                <ToolTip
                    content={params.row.remarks}
                    expand={params.row.remarks}
                />
            );
        },
    },
    {
        field: "status",
        headerName: "status",
        width: 150,
        renderCell: (params) => {
            if (params.row.expair_status === 0 && params.row.status === 1) {
                return (
                    <Chip
                        label="Meeting Over"
                        size="small"
                        color="primary"
                        variant="outlined"
                        icon={
                            <Icon
                                icon="fluent-mdl2:reminder-group"
                                size="small"
                                variant="outlined"
                                color="success"
                            />
                        }
                    />
                );
            } else if (params.row.expair_status === 0) {
                return (
                    <Chip
                        label="Expired"
                        variant="outlined"
                        color="error"
                        size="small"
                        icon={
                            <Icon
                                icon="radix-icons:cross-2"
                                variant="outlined"
                            />
                        }
                    />
                );
            } else {
                return (
                    <Chip
                        size="small"
                        variant="outlined"
                        icon={
                            params.row.status === 0 ? (
                                <Icon icon="mdi:account-pending-outline" />
                            ) : params.row.status === 1 ? (
                                <Icon icon="mdi:approve" />
                            ) : params.row.status === 2 ? (
                                <Icon icon="radix-icons:cross-2" />
                            ) : (
                                ""
                            )
                        }
                        color={
                            params.row.status === 0
                                ? "warning"
                                : params.row.status === 1
                                ? "success"
                                : params.row.status === 2
                                ? "error"
                                : ""
                        }
                        label={
                            params.row.status === 0
                                ? "Pending"
                                : params.row.status === 1
                                ? "Approved"
                                : params.row.status === 2
                                ? "Rejected"
                                : ""
                        }
                    />
                );
            }
        },
    },
    {
        field: "reschedule",
        headerName: "Reschedule",
        width: 150,
        renderCell: (params) => <RecheduleBookingView {...params.row} />,
    },
    {
        field: "Remove",
        headerName: "Remove",
        width: 150,
        renderCell: (params) => <DeleteBookingView {...params.row} />,
    },
];
