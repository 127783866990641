import { Box, Container, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeaveAplictions,
  getLeaveAplictionsforReportingHead,
} from "../../../../redux/features/leaveSlice";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { HOD_LEAVE_APPLICATION_TABLE_HEAD } from "../../../../components/datagrid/leave/HodLeaveApplicationView";
import dayjs from "dayjs";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";

const HodLeaveApplications = () => {
  const [gridSize, setGridSize] = useState(5);
  const [value, setValue] = useState(new Date());

  const dispatch = useDispatch();
  const { leave } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      getLeaveAplictionsforReportingHead(
        `${new Date().getFullYear()}/${new Date().getMonth() + 1}/0/0`
      )
    );
  }, []);

  const handleDate = (e) => {
    setValue(e);
    const date = `${new Date(e).getFullYear()}/${
      new Date(e).getMonth() + 1
    }/0/0`;
    console.log("dateeeeee,", date);

    dispatch(getLeaveAplictionsforReportingHead(date));
  };

  useEffect(() => {}, [leave.applications]);

  return (
    <div>
      <GlobalWrapper title="Department Leave Applications">
        <Paper elevation={3} sx={{ marginTop: 4 }}>
          <Box
            sx={{
              height: 75,
              backgroundColor: "grey.200",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <Box sx={{ padding: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  views={["year", "month"]}
                  label="Year and Month"
                  inputFormat="YYYY/MM"
                  value={dayjs(value)}
                  onChange={handleDate}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} variant="filled" />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <DataGrid
            loading={leave.loading}
            rows={leave.applications}
            columns={HOD_LEAVE_APPLICATION_TABLE_HEAD}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            pageSize={gridSize}
            onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
            autoHeight
            rowsPerPageOptions={[5, 10, 25, 50]}
            getRowId={(row) => row.leaveId}
            components={{ Toolbar: CustomGridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Paper>
      </GlobalWrapper>
    </div>
  );
};

export default HodLeaveApplications;
