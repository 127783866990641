import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const CourseDetailsAction = ({ params: { row } }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/schedules/student-courses/${row.admissionNumber}`, {
            state: row,
        });
    };
    return (
        <Button variant="contained" onClick={handleClick}>
            Details
        </Button>
    );
};

export default CourseDetailsAction;
