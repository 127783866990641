import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha, useTheme } from "@mui/material/styles";
import { NavLink, matchPath, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import { role } from "./roleConfig";
export const TestMenu = ({ icon, children, active, title }) => {
    // console.log("children", children);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const ListItemStyle = styled((props) => (
        <ListItemButton disableGutters {...props} />
    ))(({ theme }) => ({
        ...theme.typography.body2,
        height: 60,
        textTransform: "capitalize",
        color: theme.palette.text.secondary,
        borderRadius: theme.shape.borderRadius,
        marginBottom: 5,
        zIndex: 9999,
    }));
    const ListItemIconStyle = styled(ListItemIcon)({
        width: 22,
        height: 22,
        color: "inherit",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    });
    const activeRootStyle = {
        color: "primary.main",
        fontWeight: "fontWeightMedium",
        bgcolor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
        ),
    };

    const activeSubStyle = {
        color: "text.primary",
        fontWeight: "fontWeightMedium",
    };
    const useStyles = makeStyles((theme) => ({
        button: {
            color: "inherit", // Use 'inherit' to inherit the text color from the parent
            backgroundColor: "transparent", // Set the background color to transparent
            "&:hover, &:active": {
                backgroundColor: "transparent", // Set hover and active background color to transparent
            },
        },
    }));
    const classes = useStyles();

    return (
        <div>
            <Stack direction={"column"}>
                <Box>
                    <Button
                        className={classes.button}
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                    >
                        <Stack direction={"column"} spacing={1}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {icon}
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography sx={{ fontSize: 9 }}>
                                    {title}
                                </Typography>
                            </Box>
                        </Stack>
                    </Button>
                </Box>
            </Stack>

            {/* ------------------------------------------------------------ */}

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <List component="div" sx={{ padding: 1 }}>
                    {role.hod(children)?.map((item) => {
                        const { title, path } = item;

                        const isActiveSub = active(path);
                        return (
                            <>
                                <ListItemStyle
                                    onClick={() => handleClose()}
                                    key={title}
                                    component={NavLink}
                                    to={path || "#"}
                                    sx={{
                                        ...(isActiveSub && activeSubStyle),
                                    }}
                                >
                                    <ListItemIconStyle>
                                        <Box
                                            component="span"
                                            sx={{
                                                width: 4,
                                                height: 4,
                                                display: "flex",
                                                borderRadius: "50%",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                bgcolor: "text.disabled",
                                                transition: (theme) =>
                                                    theme.transitions.create(
                                                        "transform"
                                                    ),
                                                ...(isActiveSub && {
                                                    transform: "scale(2)",
                                                    bgcolor: "primary.main",
                                                }),
                                            }}
                                        />
                                    </ListItemIconStyle>
                                    <ListItemText
                                        disableTypography
                                        primary={title}
                                    />
                                </ListItemStyle>
                            </>
                        );
                    })}
                </List>
            </Menu>
        </div>
    );
};
