import React from "react";
import { AddModule, AddSession } from "./RenderCellMain";

export const BASE_COURSES_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "SlNo",
        width: 90,
    },
    {
        field: "customName",
        headerName: "Custom Name",
        width: 250,
    },
    {
        field: "textPublisherName",
        headerName: "Text Publisher Name",
        width: 250,
    },
    {
        field: "moduleCount",
        headerName: "No.of Modules",
        width: 200,
    },
    {
        field: "sessionCount",
        headerName: "Session Count",
        width: 90,
    },
    {
        field: "Module",
        headerName: "Module",
        width: 110,
        renderCell: (params) => <AddModule {...params.row} />,
    },

    {
        field: "Session",
        headerName: "sessions",
        width: 90,
        renderCell: (params) => <AddSession {...params.row} />,
    },
    // {
    //     field: "isListed",
    //     headerName: "isListed",
    //     width: 150,
    //     renderCell: (params) => <MiscMasterListingStatus params={params.row} />,
    // },
    // {
    //     field: "Edit",
    //     headerName: "Edit",
    //     width: 150,
    //     renderCell: (params) => <EditMiscMaster params={params.row} />,
    // },
    // {
    //     field: "Delete",
    //     headerName: "Delete",
    //     width: 150,
    //     renderCell: (params) => (
    //         <DeleteMiscMaster params={params.row.miscMasterId} />
    //     ),
    // },
];

// const CustomCellRenderer = ({ value }) => {
//     return value;
//   };

//   const MemoizedCellRenderer = React.memo(
//     CustomCellRenderer,
//     (prevProps, nextProps) => {
//       return prevProps.value === nextProps.value;
//     }
//   );

//   const columns = [
//     {
//       field: 'id',
//       renderCell: (params) => {
//         return <MemoizedCellRenderer {...params} />;
//       },
//     },
//   ];
