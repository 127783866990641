import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useFormik, FormikProvider, Form } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  updateDescription,
  createDescription,
  getAllDescriptions,
  getAllGrades,
  getGradePreData,
} from "../../../../../redux/features/assessmentToolSlice";
import GlobalSelectField from "../../../../mui/GlobalSelectField";

const CreateUpdateDescription = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading, matrixData, gradePreData } = useSelector(
    (state) => state.assessmentTool
  );
  const [open, setOpen] = useState(false);
  const [cleanedGradeName, setCleanedGradeName] = useState([]);

  useEffect(() => {
    if (gradePreData) {
      const cleanedGrades = gradePreData.map((grade) => ({
        ...grade,
        grade_name: grade.grade_name.replace(/\(\),\s*/g, ""),
      }));
      setCleanedGradeName(cleanedGrades);
    }
  }, [gradePreData]);

  const Schema = Yup.object().shape({
    description: Yup.string().required("description is required"),
    descriptionCreatedBy: Yup.string().required(
      "descriptionCreatedBy is required"
    ),
    descriptionGradeId: Yup.string().required("descriptionGradeId is required"),
  });
  const profile = JSON.parse(localStorage.getItem("cred")).profile;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      descriptionGradeId: params
        ? cleanedGradeName.some(
            (grade) => grade.grade_id === params.description_grade_id
          )
          ? params.description_grade_id
          : ""
        : "",
      description: params ? params.description : "",
      descriptionCreatedBy: profile.userId,
      descriptionUpdatedBy: profile.userId,
      id: params ? params.description_id : "",
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      if (params) {
        dispatch(updateDescription(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllDescriptions(profile?.departmentId));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(createDescription(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllDescriptions(profile?.departmentId));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });
  return (
    <GlobalDialog
      title={params ? "Update description" : "Create Description"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <>
          {params ? (
            <Box padding={1}>
              <UpdateButton
                size="small"
                action={() => {
                  dispatch(getGradePreData(profile?.departmentId));
                  setOpen(true);
                }}
              >
                Update
              </UpdateButton>
            </Box>
          ) : (
            <AddButton
              size="large"
              action={() => {
                dispatch(getGradePreData(profile?.departmentId));
                setOpen(true);
              }}
            >
              Add Description
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <Box>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box>
                  <Stack direction={"column"} spacing={2}>
                    <GlobalSelectField
                      options={{
                        formik: formik,
                        label: "Grade",
                        name: "descriptionGradeId",
                        sx: {
                          width: "100%",
                          // "& .MuiInputBase-root": {
                          //     height: 50,
                          // },
                        },
                        data: cleanedGradeName,

                        keys: {
                          id: "grade_id",
                          value: "grade_name",
                        },
                      }}
                    />
                    <TextField
                      name="description"
                      label="Description"
                      onChange={formik.handleChange}
                      multiline
                      rows={3}
                      value={formik.values.description}
                      fullWidth
                      autoComplete="off"
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={formik.errors.description}
                    />
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 2,
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      <CancelButton action={() => setOpen(false)}>
                        Cancel
                      </CancelButton>
                      {params ? (
                        <UpdateButton type="submit">
                          {loading ? "Updating..." : "Update"}
                        </UpdateButton>
                      ) : (
                        <SubmitButton type="submit">
                          {loading ? "Saving..." : "Save"}
                        </SubmitButton>
                      )}
                    </Stack>
                  </Box>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </>
      }
    />
  );
};

export default CreateUpdateDescription;
