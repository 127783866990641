import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import dayjs from "dayjs";
import TopSection from "./TopSection";
import AttendanceDetails from "./AttendanceDetails";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import useResponsive from "../../../../Hooks/useResponsive";
import MarkedAttendance from "./MarkedAttendance";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttendance,
  clearStates,
} from "../../../../redux/features/attendanceSlice";
import NoDataFound from "../../../../components/NodataIndication/NoDataFound";
import SimpleLoader from "../../../../components/Loader/SimpleLoader";

const AttendanceView = () => {
  const [selectedDate, setSelectedDate] = useState(dayjs()); //INTIALLY SAVING CURRENT DAY
  const [direction, setDirection] = useState("");
  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.attendance.loading);
  const TotalData = useSelector((state) => state.attendance.TotalData);

  useEffect(() => {
    if (mdUp) {
      setDirection("row");
    } else {
      setDirection("column");
    }
  }, [mdUp]);

  useEffect(() => {
    let YYYYMM = selectedDate.format("YYYY/MM");//SELECTED DATE CONVERTED TO MONTH AND YEAR FORMAT TO FETCH THE API
    dispatch(getAttendance(YYYYMM));
    return () => dispatch(clearStates()); //CLEARING THE ATTENDANCDE STORE DATA
  }, [selectedDate]);

  const handleDateChange = (date) => { //WHENEVER PICKING DATE IT WILL TRIGGER AND SELECTED DATE WILL BE GETS CHANGED
    setSelectedDate(date);
  };

  return (
    <Wrapper
      title={"Attendance"}
      Content={
        <>
          <Box>
            <Stack
              direction={"row"}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <TopSection //TOP SECTION INCLUDE PICKING DATE AND ATTENDENCE MARKING SECTION
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
              />
            </Stack>
          </Box>
          {loading ? ( //WHILE LOADING IT WILL DISPLAY A SPINNER
            <SimpleLoader />
          ) : TotalData?.attendance?.length === 0 ? (//IF THE FETCHED DATA ARRAY LENGTH 0 THEN NO DATA TO DISPLAY SO DISPLAYING THIS COMPONENT
            <Box sx={{ mt: 10 }}>
              <NoDataFound /> 
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
                flexDirection: direction,
                marginTop: 2,
              }}
            >
              <Box sx={{ flexBasis: "80%", flexGrow: 1 }}>
                {TotalData?.attendance?.length > 0 && ( //MAKE SURE THE ATTENDANCE DATA RECIEVED AND PASSING THE ARRAY TO THIS COMPONENT FOR DISPLAYING ATTENDANCE MARKED DETAILS
                  <MarkedAttendance
                    data={TotalData?.attendance}
                    monthAndYear={selectedDate}
                  />
                )}
              </Box>
              <Box sx={{ flexBasis: "20%", flexGrow: 0 }}>
                {TotalData?.attendance?.length > 0 && ( //THIS COMPONENT WILL DISPLAY THE ALL ATTENDANCE OBJECTIVES 
                  <AttendanceDetails data={TotalData} />
                )}
              </Box>
            </Box>
          )}
        </>
      }
    />
  );
};

export default AttendanceView;
