import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { Box, Button, Chip, Paper, TextField, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { EXPECTED_SESSIONS_TABLE_HEAD } from "../../../components/datagrid/myworkspace/cro/ExpectedSessionsTableHead";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formatDateToYearAndMonth } from "../../../utils/formatDate";
import { useDispatch, useSelector } from "react-redux";
import {
    getExpectedSessions,
    storeDate,
} from "../../../redux/features/croSlice";

const ExpectedSessions = () => {
    const [gridSize, setGridSize] = useState(5);
    const [date, setDate] = useState(new Date());
    const dispatch = useDispatch();
    const { data: userDetails } = useSelector((state) => state.login);
    const { expectedSessions, loading, totalExpectedSessions } = useSelector(
        (state) => state.cro
    );

    const handleChangeDate = (e) => {
        setDate(e);
        console.log(e);
    };

    const handleApply = () => {
        const formatedDate = formatDateToYearAndMonth(date);
        console.log(formatedDate);
        const croId = userDetails.profile.userId;
        dispatch(
            getExpectedSessions({
                date: formatedDate,
                croId: croId,
            })
        );
        dispatch(storeDate(formatedDate));
    };

    useEffect(() => {
        const formatedDate = formatDateToYearAndMonth(date);
        const croId = userDetails.profile.userId;

        if (formatedDate && croId) {
            dispatch(
                getExpectedSessions({
                    date: formatedDate,
                    croId: croId,
                })
            );
            dispatch(storeDate(formatedDate));
        }
    }, []);

    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "85%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Expected sessions
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "20px",
                                alignItems: "center",
                                padding: "0 10px",
                            }}
                        >
                            <Box>
                                {totalExpectedSessions && (
                                    <Chip
                                        label={`Total Expected Sessions :${totalExpectedSessions}`}
                                        color="success"
                                    />
                                )}
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "20px",
                                }}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["year", "month"]}
                                        label="Year and Month"
                                        inputFormat="YYYY/MM"
                                        disableFuture
                                        value={date}
                                        onChange={handleChangeDate}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                helperText={null}
                                                variant="filled"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                <Button
                                    variant="contained"
                                    onClick={handleApply}
                                >
                                    Apply
                                </Button>
                            </Box>
                        </Box>
                        <Box width="100%">
                            <DataGrid
                                components={{ Toolbar: CustomGridToolbar }}
                                loading={loading}
                                rows={expectedSessions}
                                columns={EXPECTED_SESSIONS_TABLE_HEAD}
                                autoHeight
                                getRowId={(row) => row.serialNumber}
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default ExpectedSessions;
