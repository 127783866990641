export const PAID_ASSESSMENT_ANALYTICS = [
    {
        field: "serialNumber",
        headerName: "Sl No",
        width: 90,
    },
    {
        field: "ogaName",
        headerName: "OGA Name",
        width: 120,
    },
    {
        field: "totalLeads",
        headerName: "Total Leads",
        width: 120,
    },
    {
        field: "assessmentConfirmed",
        headerName: "Assessment Confirmed",
        width: 120,
    },
    {
        field: "confirmAdmission",
        headerName: "Confirm Admission",
        width: 120,
    },
    {
        field: "pendingResponse",
        headerName: "Pending Response",
        width: 120,
    },
    {
        field: "callNotAttended",
        headerName: "Call Not Attended",
        width: 120,
    },
    {
        field: "notReachable",
        headerName: "Not Reachable",
        width: 150,
    },
    {
        field: "notAttended",
        headerName: "Not Attended",
        width: 150,
    },
    {
        field: "notNeededAssessment",
        headerName: "Not Needed Assessment",
        width: 150,
    },
    {
        field: "admissionConfirmed",
        headerName: "Admission Confirmed",
        width: 150,
    },
];
