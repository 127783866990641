import React, { useState, useEffect } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Stack, Paper, Typography, Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import useResponsive from "../../../../Hooks/useResponsive";
import { getHolidays } from "../../../../redux/features/holidaysSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";

const HolidaysCalendar = () => {
  const smDown = useResponsive("down", "sm");
  const dispatch = useDispatch();
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const { holidays,loading } = useSelector((state) => state.holidays); //SETTING A STATE TO RENDER ALL THE HOLIDAYS

  useEffect(() => {
    dispatch(getHolidays(currentYear)); //CALLING AN API TO FETCH ALL THE HOLIDAYS OF THE CURRENT YEAR
  }, []);


  return (
    <Wrapper
      title={"Holidays Calendar"}
      Content={
        <>         
          <Paper elevation={0}>
            {loading ? (
              <Box  spacing={1} sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 1
              }}>
                {Array.from({ length: 30 }).map((value, index) => (
                  <Skeleton variant="rectangular" width={210} height={150} />
                ))}
              </Box>
            ) : (
              <Box sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  {holidays.map((holiday, index) => {
                    const dateObject = dayjs(holiday.holiday_date);
                    const dayOfWeek = dateObject.format("dddd"); // Format to get the day of the week
                    const formattedDate = dateObject.format("DD MMM YYYY"); // Format to get a readable date
                    return (
                      <Grid
                        item
                        xs={12 / 2}
                        sm={4}
                        md={3}
                        lg={2}
                        //   xl={12 / 6}
                        key={index}
                      >
                        <Paper
                          elevation={6}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            padding: 1,
                          }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                backgroundColor: "#f0f4f8",
                              }}
                            >
                              <Icon
                                icon="ant-design:pushpin-filled"
                                style={{
                                  color: "#919eab",
                                  marginRight: 1,
                                  marginTop: 1,
                                  fontSize: "20px",
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                // height: smDown ? 180 : 150,
                                height: 150,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#f0f4f8",
                                padding: 2,
                                textAlign: "center",
                              }}
                            >
                              <Typography
                                variant={smDown ? "h6" : "h5"}
                                sx={{ color: "#1976d2", fontWeight: "bold" }}
                              >
                                {formattedDate}
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "#757575", marginBottom: 1 }}
                              >
                                {dayOfWeek}
                              </Typography>
                              <Typography
                                variant={smDown ? "h6" : "h5"}
                                sx={{ color: "#424242", marginTop: "auto" }}
                              >
                                {holiday.holiday_name}
                              </Typography>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
          </Paper>
        </>
      }
    />
  );
};

export default HolidaysCalendar;
