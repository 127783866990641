import { Autocomplete, Avatar, Box, Button, Paper, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Typography from '@mui/material/Typography'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'
import useResponsive from '../../Hooks/useResponsive'
import { motion } from 'framer-motion'
import StudentProfile from './StudentProfile'
import profilePic from '../../img/studentProfile.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { getLeadDetails, getStudentJourney } from '../../redux/features/studentTrackSlice'
import InduvidualAssessmentReport from '../assessmentReportInduvidual/InduvidualAssessmentReport'
import Loader3D from '../../components/Loader/Loader3D'
import ToolTip from '../../components/ToolTip/ToolTip'
import { Icon } from '@iconify/react/dist/iconify.js'
import NoDataFound from '../../components/NodataIndication/NoDataFound'
import GlobalWrapper from '../../components/Wrapper/GlobalWrapper'

const students = [
  {
    id: 1,
    value: 'Suhail',
  },
  {
    id: 2,
    value: 'Athul',
  },
  {
    id: 3,
    value: 'Subhin',
  },
]

const StudentTrack = () => {
  const [direction, setDirection] = useState({})
  const mdUp = useResponsive('up', 'md')
  const [modifiedData, setModifiedData] = useState([])
  const navigate = useNavigate('')
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const leadId = queryParams.get('leadId')
  const dispatch = useDispatch()
  const { loading, leadDetails, studentJourney } = useSelector((store) => store.studentTrack)



  useEffect(() => {
    if (leadId) {
      dispatch(getLeadDetails(leadId))
      dispatch(getStudentJourney(leadId))
    }
  }, [leadId])


  const {
    name,
    contactPerson,
    email,
    district,
    place,
    contactNo,
    fatherName,
    leadGenerated,
    challenges,
    tuitionHistory,
    syllabus,
    medium,
    course,
  } = leadDetails
  const personalDetails = { name, contactPerson, email, district, place, contactNo, fatherName }
  const otherDetails = {
    leadGenerated,
    challenges,
    tuitionHistory,
    syllabus,
    medium,
    course,
    class: leadDetails?.class,
  }

  useEffect(() => {
    // if (studentJourney.length > 0) {
    //   const modified = studentJourney?.map((obj, index) => {
    //     const currentTimestamp = dayjs(obj.timestamp)
    //     const nextTimeStamp = studentJourney[index + 1] ? dayjs(studentJourney[index + 1].timestamp) : null
    //     const daysGap = nextTimeStamp ? nextTimeStamp.diff(currentTimestamp, 'day') : null

    //     return {
    //       ...obj,
    //       daysGap: daysGap !== null ? `${daysGap} days` : null,
    //     }
    //   })

    //   setModifiedData(modified)
    // } 

    if (studentJourney.length > 0) {
      const modified = studentJourney.map((obj, index) => {
        const currentDate = dayjs(obj.timestamp).startOf('day'); // Start of day to focus only on the date
        const nextDate = studentJourney[index + 1] ? dayjs(studentJourney[index + 1].timestamp).startOf('day') : null;
        const daysGap = nextDate ? nextDate.diff(currentDate, 'day') : null;
  
        return {
          ...obj,
          daysGap: daysGap !== null && daysGap > 0 ? `${daysGap} days` : "0 day", // Set only if day has changed
        };
      });
  
      setModifiedData(modified);
    }
  }, [studentJourney])

  useEffect(() => {
    if (mdUp) {
      setDirection('row')
    } else {
      setDirection('column')
    }
  }, [mdUp])

  const handleStudentChange = (e, option) => {
    console.log(option.id)
  }

  return (
    <GlobalWrapper title={"Student Track"}>
      <Box sx={{ width: mdUp ? '33%' : '100%' }}>
        {/* <Autocomplete
          //   disabled={loading}
          name="student"
          options={students}
          getOptionLabel={(option) => option.value || ''}
          //   value={students.find((student) => student.id == formik.values.student) || null}
          onChange={handleStudentChange}
          renderInput={(params) => (
            <TextField
              disabled
              //   fullWidth
              autoComplete="off"
              {...params}
              label="Select Student"
              //   error={formik.touched.projectId && Boolean(formik.errors.projectId)}
              //   helperText={formik.touched.projectId && formik.errors.projectId}
            />
          )}
        /> */}
      </Box>
      <Stack  direction={direction} gap={2} sx={{ justifyContent: 'space-between' }}>
        <motion.div
          initial={{ opacity: 0, rotateY: 90 }}
          animate={{ opacity: 1, rotateY: 0 }}
          exit={{ opacity: 0, rotateY: 90 }}
          transition={{ duration: 1, ease: 'linear' }}
          style={{ flex: 2.5 }}>
          <Paper elevation={5}>
            {loading ? (
              <Loader3D />
            ) : studentJourney?.length === 0 ? (//IF THE FETCHED DATA ARRAY LENGTH 0 THEN NO DATA TO DISPLAY SO DISPLAYING THIS COMPONENT
              <Box sx={{height: '470px',display:"flex",justifyContent:"center",alignItems:"center"}}>
                <NoDataFound /> 
              </Box>
            ) : (
              <Box
                sx={{
                  p: 1,
                  height: '520px',
                  overflowY: 'auto',
                  paddingRight: '10px',
                  scrollbarWidth: 'thin',
                }}>
                <Box sx={{ p: mdUp ? 1 : 0 }}>
                  <Timeline position="alternate">
                    {modifiedData.map((data, index) => (
                      <TimelineItem key={index}>
                        <TimelineOppositeContent
                          sx={{
                            display:"flex",
                            alignItems:"center",
                            justifyContent: index % 2 == 0 ? 'flex-end' : 'flex-start',
                            typography: 'body2',
                            color: 'gray',
                            fontSize: '0.9rem',
                            textAlign: 'right',
                            fontStyle: 'italic',
                            fontWeight: 'lighter',
                          }}>
                            
                          {index !== modifiedData.length - 1? data.daysGap :null}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot
                            variant="filled"
                            color="secondary"
                            sx={{
                              mx: '5px',
                              borderWidth: 4,
                              // borderColor: index % 2 === 0 ? 'gray' : 'black',
                              // backgroundColor: index % 2 === 0 ? 'black' : 'gray',
                              borderColor: 'black',
                              backgroundColor: 'gray',
                            }}
                          />
                          {index !== modifiedData.length - 1 && (
                            <TimelineConnector sx={{ backgroundColor: 'gray', width: 2 }} />
                          )}
                        </TimelineSeparator>
                        <TimelineContent
                          sx={
                            {
                              // py: '12px',
                              // backgroundColor: '#caf0f873',
                              // backgroundColor: '#e9ecef',
                              // borderRadius: 2,
                              // boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                            }
                          }>
                          <Typography
                            variant="h6"
                            component="span"
                            sx={{
                              fontWeight: 'bold',
                              color: 'black',
                              fontSize: '1.1rem',
                            }}>
                            {data.action}
                          </Typography>
                          <Typography sx={{ fontSize: '0.85rem', color: 'text.secondary', mt: 1 }}>
                            {dayjs(data.timestamp).format('DD-MM-YYYY')}
                          </Typography>
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              component={'span'}
                              sx={{ color: '#03045e', fontSize: '0.9rem', backgroundColor: '#caf0f8', p: 1 }}>
                              {`Handled by: ${data.createdUser || "Not Mentioned"}`}
                            </Typography>
                          </Box>

                          <Box sx={{ mt: 2 }}>
                            <ToolTip
                              expand={data.remarks || 'Nill'}
                              content={
                                <Box
                                  component={'span'}
                                  border={1}
                                  sx={{
                                    borderRadius: '5px',
                                    px: 1,
                                    display: 'inline-flex',
                                    justifyContent: index % 2 !== 0 ? 'flex-end' : 'flex-start',
                                  }}>
                                  Remarks
                                  <Icon icon="raphael:view" width="25" height="25" style={{ color: 'black' }} />
                                </Box>
                              }
                            />
                          </Box>

                          {data.action =="Assessment Completed" && (
                            <Box sx={{ mt: 1 }}>
                              <InduvidualAssessmentReport
                                sx={{ backgroundColor: '#caf0f8', color: '#03045e' }}
                                leadId={leadId}
                                buttonName={"View Assessment Report"}
                                // leadId={6002}
                              />
                            </Box>
                          )}
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Box>
              </Box>
            )}
          </Paper>
        </motion.div>

        <Paper elevation={5} sx={{ flex: 1.2, mt: mdUp ? 0 : 10 }}>
          {loading ? (
            <Loader3D />
          ) : (
            <StudentProfile
              profilePic={profilePic}
              title={personalDetails?.name}
              subTitle={personalDetails?.place}
              personalDetails={personalDetails}
              otherDetails={otherDetails}
            />
          )}
        </Paper>
      </Stack>
    </GlobalWrapper>
  )
}

export default StudentTrack
