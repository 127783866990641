import React from 'react'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box} from "@mui/material";

const DatePickerCustomFormik = ({viewDetails,label,name,readOnly=false,fullWidth=false,maxWidth,formik}) => {
    const isError=formik?.touched[name] && Boolean(formik?.errors[name]) ? true :false
    return (   
    <Box sx={{ maxWidth }}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        readOnly={readOnly}
        slotProps={{ textField: { fullWidth: fullWidth ,label:label,error:isError,helperText:isError?formik.errors[name]:""} }}
        displayStaticWrapperAs="desktop"
        views={viewDetails}
        value={formik?.values[name]} //NEEDED FULL DATE TO DISPLAY
        onChange={(newValue) => formik?.setFieldValue(name, newValue)} 
      />
    </LocalizationProvider>
  </Box>
  )
}

export default DatePickerCustomFormik