export const CRO_ANALYTICS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "croName",
        headerName: "CRO Name",
        width: 150,
    },
    {
        field: "totalLeads",
        headerName: "Total Leads",
        width: 150,
    },
    {
        field: "notAttended",
        headerName: "Not Attended",
        width: 150,
    },
    {
        field: "admissionConfirmed",
        headerName: "Admission Confirmed",
        width: 150,
    },
    {
        field: "waitingForResponse",
        headerName: "Waiting For Response",
        width: 150,
    },
    {
        field: "demoClassScheduled",
        headerName: "Demo Class Scheduled",
        width: 150,
    },
    {
        field: "postponed",
        headerName: "Postponed",
        width: 150,
    },
    {
        field: "responseIssue",
        headerName: "Response Issue",
        width: 150,
    },
    {
        field: "notReachable",
        headerName: "Not Reachable",
        width: 150,
    },
    {
        field: "cancelled",
        headerName: "Cancelled",
        width: 150,
    },
    {
        field: "expelled",
        headerName: "Expelled",
        width: 150,
    },
    {
        field: "percentageOfConversion",
        headerName: "Percentage Of Conversion",
        width: 150,
    },
    {
        field: "percentageOfPendingresponse",
        headerName: "Percentage Of Pendingresponse",
        width: 150,
    },
];
