import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import BasicTextField from "../../../mui/TextField";
import GlobalSelectField from "../../../mui/GlobalSelectField";
import {
    addOgaRetainSchedule,
    getOgaList,
} from "../../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const OgaRetainForm = ({ data, setOpen }) => {
    const dispatch = useDispatch();
    const { OgaList, loading } = useSelector((state) => state.cro);

    const { enqueueSnackbar } = useSnackbar();

    const schema = Yup.object().shape({
        ogaId: Yup.string().required("Please select an OGA"),
        remarks: Yup.string().required("Please enter remarks"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            admnNo: data?.admnNo,
            name: data?.studentName,
            course: data?.courseName,
            sessionsCompleated: data.sessionsCompleted,
            courseEnrollmentId: data?.courseEnrollmentId,
            ogaId: "",
            remarks: "",
        },
        validationSchema: schema,
        onSubmit: (values) => {
            const { admnNo, name, course, sessionsCompleated, ...others } =
                values;

            dispatch(addOgaRetainSchedule(others)).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    setOpen(false);
                } else {
                    enqueueSnackbar(res.payload.message, {
                        variant: "error",
                    });
                }
            });
        },
    });

    useEffect(() => {
        dispatch(getOgaList());
    }, []);

    return (
        <Box
            sx={{
                minWidth: "500px",
                textAlign: "center",
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    marginBottom: "15px",
                }}
            >
                Schedule OGA Retain Call
            </Typography>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Stack
                        gap={2}
                        sx={{
                            borderBottom: "1px solid #000",
                            pb: "15px",
                            mb: "20px",
                        }}
                    >
                        <BasicTextField
                            label="Admission Number"
                            value={formik.values.admnNo}
                            disabled
                        />
                        <BasicTextField
                            label="Name"
                            value={formik.values.name}
                            disabled
                        />
                        <BasicTextField
                            label="Course"
                            value={formik.values.course}
                            disabled
                        />
                        <BasicTextField
                            label="Sessions Compleated"
                            value={formik.values.sessionsCompleated}
                            disabled
                        />
                    </Stack>
                    <Stack gap={2} mb={2}>
                        <GlobalSelectField
                            options={{
                                label: "OGA",
                                name: "ogaId",
                                formik: formik,
                                data: OgaList,
                                keys: {
                                    id: "id",
                                    value: "name",
                                },
                                sx: {
                                    textAlign: "left",
                                },
                            }}
                        />

                        <BasicTextField
                            label="Remarks"
                            multiline
                            rows={3}
                            value={formik.values.remarks}
                            action={(e) =>
                                formik.setFieldValue("remarks", e.target.value)
                            }
                            error={Boolean(formik.errors.remarks)}
                            helperText={formik.errors.remarks}
                        />
                    </Stack>
                    {data?.ogaRetainCallStatus === "Not Assigned" ? (
                        <Button
                            disabled={loading}
                            type="submit"
                            variant="contained"
                        >
                            Schedule
                        </Button>
                    ) : (
                        <Button variant="contained">
                            {data?.ogaRetainCallStatus}
                        </Button>
                    )}
                </Form>
            </FormikProvider>
        </Box>
    );
};

export default OgaRetainForm;
