import { Box, Button, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import BasicTextField from "../../../mui/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteSubjectEnrollments,
    getSubjectSessionCount,
    updateSubjectSessionCount,
} from "../../../../redux/features/croSlice";
import { useSnackbar } from "notistack";
import Loader from "../../../Loader/Loader";
import PopUps from "./PopUps";
import DeleteConfirmationPopUp from "./DeleteConfirmationPopUp";

const useStyles = makeStyles({
    textField: {
        "& input": {
            height: "7px", // Adjust the height as per your requirement
        },
    },
});

const UpdateSessionCountActions = ({ row }) => {
    const [sessionCount, setSessionCount] = useState(row.totalSessions);
    const [disable, setDisable] = useState(false);
    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.cro);

    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClick = () => {
        if (sessionCount > 0) {
            dispatch(
                updateSubjectSessionCount({
                    sEId: row.subjectEnrollmentId,
                    sessionCount: sessionCount,
                })
            ).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    setDisable(true);
                } else {
                    enqueueSnackbar("something went wrong please try again!", {
                        variant: "error",
                    });
                    setDisable(false);
                }
            });
        }
    };

    const handleDelete = (row) => {
        handleClickOpen();
        // dispatch(deleteSubjectEnrollments(row.subjectEnrollmentId)).then(
        //     (res) => {}
        // );
    };

    return loading ? (
        <Loader />
    ) : (
        <Stack flexDirection="row" gap={2} justifyContent={"center"}>
            <Box>
                <TextField
                    value={sessionCount}
                    disabled={row.totalSessions > 0 || disable}
                    className={classes.textField}
                    type="number"
                    onChange={(e) => setSessionCount(e.target.value)}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "20px",
                }}
            >
                <Button
                    variant="contained"
                    color="success"
                    disabled={row.totalSessions > 0 || disable}
                    onClick={handleClick}
                >
                    Update
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    disabled={row.totalSessions > 0 || disable}
                    onClick={() => handleDelete(row)}
                >
                    Delete
                </Button>
            </Box>
            <PopUps
                open={open}
                setOpen={setOpen}
                componentProps={{
                    setOpen: setOpen,
                    row: row,
                }}
                component={DeleteConfirmationPopUp}
            />
        </Stack>
    );
};

export default UpdateSessionCountActions;
