import React from "react";
import { StatusToggleSwitch } from "../../../../StatusToggleSwitch";
import { inactiveMatrix } from "../../../../../redux/features/assessmentToolSlice";

const StatusSwitch = ({ params }) => {
    return (
        <StatusToggleSwitch
            disabled={params.has_child_element === 1 ? true : false}
            api={inactiveMatrix(params.matrix_id)}
            initialValue={params.matrix_status === 0 ? true : false}
        />
    );
};

export default StatusSwitch;
