import React from "react";
import GlobalDeleteDialog from "../../../../mui/Dialogue/GlobalDeleteDialog";
import {
    deleteLevel,
    getAllLevels,
} from "../../../../../redux/features/assessmentToolSlice";
import { useSelector } from "react-redux";

const DeleteLevel = ({ id, childFlag }) => {
    const { loading } = useSelector((state) => state.assessmentTool);
    return (
        <GlobalDeleteDialog
            title={"Delete Level"}
            disabled={childFlag === 1 ? true : false}
            deleteNote={"Are you sure? you wnat to delete this level..?"}
            api={deleteLevel(id)}
            upDateCall={getAllLevels()}
            loading={loading}
        />
    );
};

export default DeleteLevel;
