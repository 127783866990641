import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import Page from "../../../components/Page";
import { getStudentsWithPrefference } from "../../../redux/features/sessionSlice";
import { STUDENT_VIEW_TABLE_HEAD as columns } from "../../../components/datagrid/schedules/StudentsViewTableHead";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { Link } from "react-router-dom";

const StudentSessionShedulesView = () => {
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);

    const { studentsWithPreff: students, loading } = useSelector(
        (state) => state.sessions
    );

    useEffect(() => {
        dispatch(getStudentsWithPrefference());
    }, []);

    return (
        <Page>
            <Typography variant="h4" sx={{ mb: 1 }}>
                Student Schedules
            </Typography>
            <Breadcrumb sx={{ marginBottom: 1 }} />

            <Stack
                sx={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "right",
                    marginBottom: "10px",
                }}
            >
                <Button
                    sx={{ width: "200px" }}
                    component={Link}
                    to="/schedules/add-sessions"
                    variant="contained"
                >
                    Add Schedules
                </Button>
            </Stack>
            <Paper elevation={3} sx={{ borderRadius: 1, width: "100%" }}>
                <DataGrid
                    loading={loading}
                    rows={students}
                    columns={columns}
                    getRowId={(row) => row.admissionNumber}
                    autoHeight
                    pageSize={gridSize}
                    onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    components={{ Toolbar: CustomGridToolbar }}
                />
            </Paper>
        </Page>
    );
};

export default StudentSessionShedulesView;
