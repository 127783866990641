import React from "react";
import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import CvUpdateForm from "./CvUpdateForm";

function CvStatus(params) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  if (params.params.row.CVStatus === "Pending") {
    return (
      <div>
        <Chip
          onClick={handleClickOpen}
          icon={<Icon icon="material-symbols:edit-outline-rounded" />}
          label="pending"
          size="small"
          sx={{ backgroundColor: "#eecc1429", color: "#eecc14" }}
        />
        <CvUpdateForm open={open} setOpen={setOpen} params={params} />
      </div>
    );
  } else if (params.params.row.CVStatus === "Selected") {
    return (
      <div>
        <Chip
          onClick={handleClickOpen}
          icon={<Icon icon="material-symbols:edit-outline-rounded" />}
          size="small"
          sx={{ backgroundColor: "#c8facd", color: "#0db05d" }}
          label="selected"
        />
        <CvUpdateForm open={open} setOpen={setOpen} params={params} />
      </div>
    );
  } else if (params.params.row.CVStatus === 26) {
    return (
      <div>
        <Chip
          onClick={handleClickOpen}
          icon={<Icon icon="material-symbols:edit-outline-rounded" />}
          size="small"
          sx={{ backgroundColor: "#ffe4de", color: "Rejected" }}
          label="Rejected"
        />
        <CvUpdateForm open={open} setOpen={setOpen} params={params} />
      </div>
    );
  } else if (params.params.row.CVStatus === "Future Reference") {
    return (
      <div>
        <Chip
          onClick={handleClickOpen}
          icon={<Icon icon="material-symbols:edit-outline-rounded" />}
          size="small"
          sx={{ backgroundColor: "#7e4d8b36", color: "#7e4d8b" }}
          label="futureReference"
        />
        <CvUpdateForm open={open} setOpen={setOpen} params={params} />
      </div>
    );
  } else if (params.params.row.CVStatus === "Shortlisted") {
    return (
      <div>
        <Chip
          onClick={handleClickOpen}
          icon={<Icon icon="material-symbols:edit-outline-rounded" />}
          size="small"
          sx={{ backgroundColor: "#eff4f9", color: "#3173b4" }}
          label="shortlist"
        />
        <CvUpdateForm open={open} setOpen={setOpen} params={params} />
      </div>
    );
  }
}

export default CvStatus;
