import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CREATE_TUTOR_TABLE_HEAD as columns } from "../../../../components/datagrid/myworkspace/cro/CreateTutuor/CreateTutorTableHead";
import { useDispatch, useSelector } from "react-redux";
import { getallTrainers } from "../../../../redux/features/croSlice";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import Wrapper from "../../../../components/Wrapper/Wrapper";

export default function CreateTutor() {
    const dispatch = useDispatch();
    const { allTrainers, loading } = useSelector((state) => state.cro);
    const [gridSize, setGridSize] = useState(5);
    useEffect(() => {
        dispatch(getallTrainers());
    }, []);
    return (
        <Wrapper
            title="Create Tutor In Wise"
            Content={
                <>
                    <Paper elevation={3}>
                        <Box>
                            <DataGrid
                                loading={loading}
                                rows={allTrainers}
                                columns={columns}
                                columnBuffer={30}
                                pageSize={gridSize}
                                autoHeight
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                getRowId={(row) => {
                                    return row.serialNumber;
                                }}
                                components={{
                                    Toolbar: CustomGridToolbar,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: {
                                            debounceMs: 500,
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                </>
            }
        />
    );
}
