import { Button } from "@mui/material";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

export const UpdateTaskActions = (params) => {
    const navigate = useNavigate();

    const handleUpdate = (row) => {
        navigate(`/todo/update-task/${row.taskId}`);
    };

    return params.row.uiElements.map((item) =>
        item === "statusUpdateBtn" ? (
            <Button
                sx={{ backgroundColor: "#ffc1072e", color: "#ffc107" }}
                size="small"
                onClick={() => handleUpdate(params.row)}
            >
                <Icon icon="carbon:update-now" width="15" height="15" />
                update
            </Button>
        ) : item === "completedBtn" ? (
            <Button variant="contained" color="success" disabled>
                updated
            </Button>
        ) : null
    );
};
