import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// component
import Iconify from "../../../../components/Iconify";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../../redux/features/authSlice";
import { useSnackbar } from "notistack";
import { clearUserInfo } from "../../../../Common";
import PasswordChecklist from "react-password-checklist";

// ----------------------------------------------------------------------

export default function RegisterForm() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [showPassword, setShowPassword] = useState(false);
    const loading = useSelector((state) => state.login.isLoading);

    const RegisterSchema = Yup.object().shape({
        fullName: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("First name required"),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),

        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("passwordConfirmation is required"),
    });

    const formik = useFormik({
        initialValues: {
            fullName: "",
            password: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            dispatch(registerUser(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    clearUserInfo();

                    navigate("/auth/login", { replace: true });
                }
            });
            // navigate('/details', { replace: true });
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } =
        formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Full name"
              name='fullName'
              type="text"
              onChange={formik.handleChange}
              value={formik.values.fullName}
              {...getFieldProps('fullName')}
              error={Boolean(touched.fullName && errors.fullName)}
              helperText={touched.fullName && errors.fullName}
            />
          </Stack>

                    <TextField
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        {...getFieldProps("password")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() =>
                                            setShowPassword((prev) => !prev)
                                        }
                                    >
                                        <Iconify
                                            icon={
                                                showPassword
                                                    ? "eva:eye-fill"
                                                    : "eva:eye-off-fill"
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />

                    <TextField
                        name="passwordConfirmation"
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange}
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? "text" : "passwordConfirmation"}
                        label="Confirm-password"
                        {...getFieldProps("passwordConfirmation")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() =>
                                            setShowPassword((prev) => !prev)
                                        }
                                    >
                                        <Iconify
                                            icon={
                                                showPassword
                                                    ? "eva:eye-fill"
                                                    : "eva:eye-off-fill"
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(
                            touched.passwordConfirmation &&
                                errors.passwordConfirmation
                        )}
                        helperText={
                            touched.passwordConfirmation &&
                            errors.passwordConfirmation
                        }
                    />

                    <PasswordChecklist
                        rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                            "match",
                        ]}
                        minLength={6}
                        value={formik.values.password}
                        valueAgain={formik.values.passwordConfirmation}
                        // onChange={(isValid) => {}}
                        style={{ fontSize: 10, marginLeft: 20 }}
                        iconSize={10}
                    />

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        {loading ? "Registering..." : "Register"}
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
