import { Button } from "@mui/material";
import { TpVerificationDetail } from "./RenderCellMain";
export const TRAINER_PAYMENT_VERIFICATION = [
    {
        field: "serialNumber",
        headerName: "slNo",
        width: 90,
    },
    {
        field: "courseEnrollmentId",
        headerName: "Course Enrollment Id",
        width: 90,
    },
    {
        field: "student",
        headerName: "Student Name",
        width: 150,
    },
    {
        field: "tutor",
        headerName: "Tutor",
        width: 180,
    },
    {
        field: "feePaid",
        headerName: "feePaid",
        width: 120,
    },
    {
        field: "noOfSessions",
        headerName: "No Of Sessions",
        width: 100,
    },

    {
        field: "totalTp",
        headerName: "totalTp",
        width: 100,
    },
    {
        field: "walletBalance",
        headerName: "Wallet Balance",
        width: 120,
    },
    {
        field: "totalTpByCourse",
        headerName: "Total Tp By Course",
        width: 120,
    },
    {
        field: "verify",
        headerName: "verify",
        width: 130,
        renderCell: (params) => <TpVerificationDetail {...params.row} />,
    },

    {
        field: "acountantRemark",
        headerName: "acountantRemark",
        width: 150,
    },
];
