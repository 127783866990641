import { DeletePackageSubjectSession } from "./DeletePackageSubjectSession";
import { PackageSubjectSessionListingStatus } from "./PackageSubjectSessionListingStatus";
import { PackageSubjectSessionStatus } from "./PackageSubjectSessionStatus";

export const PACKAGE_SUBJECT_SESSION_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "packageName",
        headerName: "Package Name",
        width: 150,
    },
    {
        field: "subjectName",
        headerName: "Subject Name",
        width: 200,
    },
    {
        field: "baseCourseString",
        headerName: "BaseCourse String",
        width: 300,
    },
    {
        field: "packageSubjectSessions",
        headerName: "Package Subject Sessions",
        width: 300,
    },
    {
        field: "isActive",
        headerName: "isActive",
        width: 90,
        renderCell: (params) => (
            <PackageSubjectSessionStatus params={params.row} />
        ),
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 90,
        renderCell: (params) => (
            <PackageSubjectSessionListingStatus params={params.row} />
        ),
    },
    // {
    //     field: "edit",
    //     headerName: "Edit",
    //     width: 90,
    //     renderCell: (params) => <EditprojectCategory params={params.row} />,
    // },
    {
        field: "Delete",
        headerName: "Delete",
        width: 90,
        renderCell: (params) => (
            <DeletePackageSubjectSession
                params={params.row.packageSubjectSessionId}
            />
        ),
    },
];
