import React, { useState } from "react";
import { EVALUATION_ANALYTICS_TBL_HEAD } from "../../../components/datagrid/CRM/EvaluationAnalyticsTableHead";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { DataGrid } from "@mui/x-data-grid";
import Page from "../../../components/Page";
import { Box, Paper, TextField, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import BasicButton from "../../../components/mui/Button";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CrmEvaluationAnalytics = () => {
    const [gridSize, setGridSize] = useState(5);

    const dummyDataEvaluationAnalytics = [
        {
            serialNumber: 1,
            croName: "Dummy CRO 1",
            totalEvaluations: 50,
            compleated: 40,
            notCompleated: 10,
            croAcknowledged: 35,
            croNotAcknowledged: 5,
        },
        {
            serialNumber: 2,
            croName: "Dummy CRO 2",
            totalEvaluations: 60,
            compleated: 50,
            notCompleated: 10,
            croAcknowledged: 45,
            croNotAcknowledged: 5,
        },
        {
            serialNumber: 3,
            croName: "Dummy CRO 3",
            totalEvaluations: 70,
            compleated: 60,
            notCompleated: 10,
            croAcknowledged: 55,
            croNotAcknowledged: 5,
        },
        {
            serialNumber: 4,
            croName: "Dummy CRO 4",
            totalEvaluations: 80,
            compleated: 70,
            notCompleated: 10,
            croAcknowledged: 65,
            croNotAcknowledged: 5,
        },
        {
            serialNumber: 5,
            croName: "Dummy CRO 5",
            totalEvaluations: 90,
            compleated: 80,
            notCompleated: 10,
            croAcknowledged: 75,
            croNotAcknowledged: 5,
        },
    ];

    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Evaluation Analytics
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                        }}
                    >
                        <BasicButton
                            variant="contained"
                            sx={{
                                display: "flex",
                                gap: 1,
                            }}
                        >
                            Unassigned Leads :-0
                        </BasicButton>
                    </Box>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                p: 2,
                                gap: 2,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="From Date"
                                    inputFormat="YYYY/MM/DD"
                                    // onChange={handleDateDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            sx={{
                                                flex: 1,
                                            }}
                                        />
                                    )}
                                />
                                <DesktopDatePicker
                                    label="To Date"
                                    inputFormat="YYYY/MM/DD"
                                    // onChange={handleDateDateChange}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            sx={{
                                                flex: 1,
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <GlobalSelectField
                                options={{
                                    label: "Course",
                                }}
                            />
                        </Box>

                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                rows={dummyDataEvaluationAnalytics}
                                columns={EVALUATION_ANALYTICS_TBL_HEAD}
                                components={{ Toolbar: CustomGridToolbar }}
                                autoHeight
                                sx={{
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1,
                                    },
                                }}
                                getRowId={(row) => row.serialNumber}
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default CrmEvaluationAnalytics;
