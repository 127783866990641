import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Button, Paper, Stack, TextField } from "@mui/material";

import { ADMISSION_REGISTER_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/AdmissionRegister/AdmissionRegisterTableHead";

import {
    getAdmissionRegister,
    getAssignedCros,
    getCourses,
    admissionReg,
} from "../../../../redux/features/crmSlice";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import {
    SubmitButton,
    UpdateButton,
} from "../../../../components/mui/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import useResponsive from "../../../../Hooks/useResponsive";
import { DetailsView } from "./DetailsView";
import { ActionsView } from "./ActionsView";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";

const status = [
    { id: -1, name: "All" },
    { id: 1, name: "Active" },
    { id: 0, name: "Inactive" },
    { id: 2, name: "Temporary Inactive" },
];

export default function AdmissionRegister() {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");

    const data = useSelector((state) => state.crm);
    const dispatch = useDispatch();

    const [selectedRows, setSelectedRows] = React.useState([]);
    console.log(selectedRows);

    const [open, setOpen] = React.useState(false);

    const Schema = Yup.object().shape({
        croId: Yup.string().required("croId is required"),
        courseId: Yup.string().required("courseId is required"),
        courseStatusId: Yup.string().required("courseStatusId is required"),
    });

    const formik = useFormik({
        initialValues: {
            croId: "",
            courseId: "",
            courseStatusId: "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            dispatch(getAdmissionRegister(values));
            dispatch(admissionReg(values));
        },
    });

    useEffect(() => {
        dispatch(getCourses());
        dispatch(getAssignedCros());
    }, []);

    const navigate = useNavigate();

    const Head = [
        {
            field: "studentName",
            headerName: "Student Name",
            width: 250,
        },
    ];
    return (
        <Wrapper
            title={"Admission Register"}
            actions={
                <UpdateButton
                    action={() => {
                        navigate("/my-workspace/update-expected-session");
                    }}
                >
                    Update Expected Session
                </UpdateButton>
            }
            Content={
                <>
                    <Box>
                        <Paper sx={{ backgroundColor: "grey.200", padding: 1 }}>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Stack
                                        direction={smUp ? "row" : "column"}
                                        spacing={2}
                                    >
                                        <GlobalSelectField
                                            options={{
                                                formik: formik,
                                                label: "Course",
                                                name: "courseId",
                                                sx: {
                                                    width: "100%",
                                                    "& .MuiInputBase-root": {
                                                        height: 50,
                                                    },
                                                },
                                                data: data.coursesPreData,

                                                keys: {
                                                    id: "courseId",
                                                    value: "courseName",
                                                },
                                            }}
                                        />
                                        <GlobalSelectField
                                            options={{
                                                formik: formik,
                                                label: "CRO",
                                                name: "croId",
                                                sx: {
                                                    width: "100%",
                                                    "& .MuiInputBase-root": {
                                                        height: 50,
                                                    },
                                                },
                                                data: data.AssignedCros,

                                                keys: {
                                                    id: "id",
                                                    value: "name",
                                                },
                                            }}
                                        />
                                        <GlobalSelectField
                                            options={{
                                                formik: formik,
                                                label: "Status",
                                                name: "courseStatusId",
                                                sx: {
                                                    width: "100%",
                                                    "& .MuiInputBase-root": {
                                                        height: 50,
                                                    },
                                                },
                                                data: status,

                                                keys: {
                                                    id: "id",
                                                    value: "name",
                                                },
                                            }}
                                        />

                                        <Box
                                            paddingTop={1}
                                            sx={{
                                                display: smUp ? "" : "flex",
                                                justifyContent: smUp
                                                    ? ""
                                                    : "center",
                                            }}
                                        >
                                            <SubmitButton type="submit">
                                                Submit
                                            </SubmitButton>
                                        </Box>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    </Box>
                    {/* ---------------------------------------------------------------------------- */}
                    <Box>
                        <CustomDataGrid
                            loading={data.loading}
                            data={data.admissionRegister}
                            rowId={"serialNumber"}
                            columns={columns}
                            Head={Head}
                            setSelectedRows={setSelectedRows}
                            selectedRows={selectedRows}
                            chipValue={selectedRows[0]?.admnNo}
                            label="AdNo"
                            name={selectedRows[0]?.studentName}
                            details={<DetailsView data={selectedRows[0]} />}
                            actions={<ActionsView data={selectedRows[0]} />}
                        />
                    </Box>
                </>
            }
        />
    );
}
