import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import BasicTextField from "../../../mui/TextField";
import GlobalSelectField from "../../../mui/GlobalSelectField";

const VideoEvaluationForm = () => {
    return (
        <Box
            sx={{
                minWidth: "600px",
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    mb: 2,
                }}
            >
                Evaluate Video
            </Typography>
            <Stack gap={2} mb={2}>
                <BasicTextField label="Name Of Trainer" />
                <BasicTextField label="EmailId" />
                <BasicTextField label="Mobile Number" />
                <Stack gap={2} direction="row">
                    <Button variant="contained">Watch Video</Button>
                    <GlobalSelectField
                        options={{
                            label: "Video Evaluation Status",
                        }}
                    />
                </Stack>
            </Stack>
            <Stack gap={2} direction="row" justifyContent="center">
                <Button variant="contained">Reset</Button>
                <Button variant="contained">Save</Button>
            </Stack>
        </Box>
    );
};

export default VideoEvaluationForm;
