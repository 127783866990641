import React from "react";
import GlobalDeleteDialog from "../../../mui/Dialogue/GlobalDeleteDialog";
import { useSelector } from "react-redux";
import {
  deleteComWorkreq,
  getCompWorkReq,
} from "../../../../redux/features/leaveSlice";
import { savePaginationData } from "../../../../redux/features/globalDatagridSlice";

const DeleteCompReq = ({ id, data, paginationData }) => {
  const { loading } = useSelector((state) => state.leave);
  return (
    <GlobalDeleteDialog
      disabled={id?.status == 0 ? false : true}
      title="Delete Work Request"
      loading={loading}
      deleteNote={"Are you sure you want delete this request..!"}
      api={deleteComWorkreq(id.id)}
      upDateCall={getCompWorkReq(data)}
      updatePaginationCall={savePaginationData({
        ...paginationData,
        openAnchor: false,
      })}
    />
  );
};

export default DeleteCompReq;
