import React, { useEffect, useState } from 'react'
import GlobalWrapper from '../../../../components/Wrapper/GlobalWrapper'
import AddOrUpdateWorkingHours from '../../../../components/datagrid/EmployeeService/PostWorkingHours/AddOrUpdateWorkingHours'
import { Box, Paper, Stack, Typography } from '@mui/material'
import GlobalDatagrid from '../../../../components/mui/DataGrid/GlobalDatagrid'
import { useDispatch, useSelector } from 'react-redux'
import { postWorkingTableHeader } from '../../../../components/datagrid/EmployeeService/PostWorkingHours/postWorkingTableHeader'
import {
  getAllDepartmenst,
  getAllPostWorkingHours,
  PostWorkingApproveOrReject,
  postWorkingBulkApprove,
} from '../../../../redux/features/postWorkingHoursSlice'
import DatePickerCustom from '../../../../components/DatePicker/DatePickerCustom'
import dayjs from 'dayjs'
import GlobalSelectFieldWithoutFormik from '../../../../components/mui/GlobalSelectFieldWithoutFormik'
import ActionDetails from '../../../../components/mui/GlobalAnchor/ActionDetails'
import GlobalAnchorList from '../../../../components/mui/GlobalAnchor/GlobalAnchorList'
import DeleteWorkingHour from '../../../../components/datagrid/EmployeeService/PostWorkingHours/DeleteWorkingHour'
import ApproveGlobal from '../../../../components/datagrid/EmployeeService/PostWorkingHours/ApproveGlobal'
import RejectGlobal from '../../../../components/datagrid/EmployeeService/PostWorkingHours/RejectGlobal'
import useResponsive from '../../../../Hooks/useResponsive'
import { AddButton, BulkApprove } from '../../../../components/mui/Buttons/Buttons'
import PostWorkingBulkApprove from './PostWorkingBulkApprove'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'

const isHead = true

// const data = [
//   {
//     id: 1,
//     sl_no: 1,
//     date: '2024-10-20',
//     name: 'Subhin',
//     department: 'Technical',
//     status: 'pending',
//     from: '20:00',
//     to: '23:00',
//     remarks: 'Ui designing pending',
//     approver: 1,
//   },
//   {
//     id: 2,
//     sl_no: 2,
//     date: '2024-10-20',
//     name: 'Suhail',
//     department: 'Oga',
//     status: 'approved',
//     remarks: 'please approve',
//     from: '20:00',
//     to: '21:00',
//     approver: 1,
//   },
//   {
//     id: 3,
//     sl_no: 3,
//     date: '2024-10-20',
//     name: 'Athul',
//     department: 'Technical',
//     status: 'rejected',
//     remarks: 'Ui designing pending',
//     from: '20:00',
//     to: '22:00',
//     approver: 1,
//   },
// ]

const data = [
  {
    id: 1,
    sl_no: 1,
    date: '2024-10-01',
    name: 'Subhin',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 2,
    sl_no: 2,
    date: '2024-10-02',
    name: 'Suhail',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 3,
    sl_no: 3,
    date: '2024-10-03',
    name: 'Athul',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 4,
    sl_no: 4,
    date: '2024-10-04',
    name: 'Ravi',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 5,
    sl_no: 5,
    date: '2024-10-05',
    name: 'Anil',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 6,
    sl_no: 6,
    date: '2024-10-06',
    name: 'Neha',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 7,
    sl_no: 7,
    date: '2024-10-07',
    name: 'Priya',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 8,
    sl_no: 8,
    date: '2024-10-08',
    name: 'Vikram',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 9,
    sl_no: 9,
    date: '2024-10-09',
    name: 'Sonia',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 10,
    sl_no: 10,
    date: '2024-10-10',
    name: 'Amit',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 11,
    sl_no: 11,
    date: '2024-10-11',
    name: 'Subhin',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 12,
    sl_no: 12,
    date: '2024-10-12',
    name: 'Suhail',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 13,
    sl_no: 13,
    date: '2024-10-13',
    name: 'Athul',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 14,
    sl_no: 14,
    date: '2024-10-14',
    name: 'Ravi',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 15,
    sl_no: 15,
    date: '2024-10-15',
    name: 'Anil',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 16,
    sl_no: 16,
    date: '2024-10-16',
    name: 'Neha',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 17,
    sl_no: 17,
    date: '2024-10-17',
    name: 'Priya',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 18,
    sl_no: 18,
    date: '2024-10-18',
    name: 'Vikram',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 19,
    sl_no: 19,
    date: '2024-10-19',
    name: 'Sonia',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 20,
    sl_no: 20,
    date: '2024-10-20',
    name: 'Amit',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 21,
    sl_no: 21,
    date: '2024-10-21',
    name: 'Subhin',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 22,
    sl_no: 22,
    date: '2024-10-22',
    name: 'Suhail',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 23,
    sl_no: 23,
    date: '2024-10-23',
    name: 'Athul',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 24,
    sl_no: 24,
    date: '2024-10-24',
    name: 'Ravi',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 25,
    sl_no: 25,
    date: '2024-10-25',
    name: 'Anil',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 26,
    sl_no: 26,
    date: '2024-10-26',
    name: 'Neha',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 27,
    sl_no: 27,
    date: '2024-10-27',
    name: 'Priya',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 28,
    sl_no: 28,
    date: '2024-10-28',
    name: 'Vikram',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 29,
    sl_no: 29,
    date: '2024-10-29',
    name: 'Sonia',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 30,
    sl_no: 30,
    date: '2024-10-30',
    name: 'Amit',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 31,
    sl_no: 31,
    date: '2024-10-01',
    name: 'Subhin',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 32,
    sl_no: 32,
    date: '2024-10-02',
    name: 'Suhail',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 33,
    sl_no: 33,
    date: '2024-10-03',
    name: 'Athul',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 34,
    sl_no: 34,
    date: '2024-10-04',
    name: 'Ravi',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 35,
    sl_no: 35,
    date: '2024-10-05',
    name: 'Anil',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 36,
    sl_no: 36,
    date: '2024-10-06',
    name: 'Neha',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 37,
    sl_no: 37,
    date: '2024-10-07',
    name: 'Priya',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 38,
    sl_no: 38,
    date: '2024-10-08',
    name: 'Vikram',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 39,
    sl_no: 39,
    date: '2024-10-09',
    name: 'Sonia',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 40,
    sl_no: 40,
    date: '2024-10-10',
    name: 'Amit',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 41,
    sl_no: 41,
    date: '2024-10-11',
    name: 'Subhin',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 42,
    sl_no: 42,
    date: '2024-10-12',
    name: 'Suhail',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 43,
    sl_no: 43,
    date: '2024-10-13',
    name: 'Athul',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 44,
    sl_no: 44,
    date: '2024-10-14',
    name: 'Ravi',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 45,
    sl_no: 45,
    date: '2024-10-15',
    name: 'Anil',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 46,
    sl_no: 46,
    date: '2024-10-16',
    name: 'Neha',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 47,
    sl_no: 47,
    date: '2024-10-17',
    name: 'Priya',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 48,
    sl_no: 48,
    date: '2024-10-18',
    name: 'Vikram',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 49,
    sl_no: 49,
    date: '2024-10-19',
    name: 'Sonia',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 50,
    sl_no: 50,
    date: '2024-10-20',
    name: 'Amit',
    department: 'HR',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'urgent',
    approver: 5,
  },
  {
    id: 51,
    sl_no: 51,
    date: '2024-10-21',
    name: 'Subhin',
    department: 'Sales',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'Ui designing pending',
    approver: 1,
  },
  {
    id: 52,
    sl_no: 52,
    date: '2024-10-22',
    name: 'Suhail',
    department: 'Finance',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'please approve',
    approver: 2,
  },
  {
    id: 53,
    sl_no: 53,
    date: '2024-10-23',
    name: 'Athul',
    department: 'Operations',
    status: 'approved',
    from: '20:00',
    to: '22:00',
    remarks: 'needs revision',
    approver: 3,
  },
  {
    id: 54,
    sl_no: 54,
    date: '2024-10-24',
    name: 'Ravi',
    department: 'Oga',
    status: 'rejected',
    from: '20:00',
    to: '23:00',
    remarks: 'follow-up required',
    approver: 4,
  },
  {
    id: 55,
    sl_no: 55,
    date: '2024-10-25',
    name: 'Anil',
    department: 'Technical',
    status: 'pending',
    from: '20:00',
    to: '21:00',
    remarks: 'urgent',
    approver: 5,
  },
]

const PostWorking = ({ isHr }) => {
  const [direction, setDirection] = useState('row')
  const mdDown = useResponsive('down', 'md')
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { resData, loading, departments } = useSelector((store) => store.postWorking)
  const [updatedDepartments, setUpdatedDepartments] = useState([])
  // const { data, meta } = resData
  const { meta } = resData
  const dispatch = useDispatch()
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search, checkedRowsIds } = paginationData
  const [department, setDepart] = useState({
    id: '',
    value: '',
  })
  const dispatchParams = {
    selectedDate,
    paginationData,
    departmentName: department.value == 'All Department' ? null : department.value,
  }

  useEffect(() => {
    if (mdDown) {
      setDirection('column')
    } else {
      setDirection('row')
    }
  }, [mdDown])

  useEffect(() => {
    dispatch(getAllDepartmenst())
    if (!departments.find((dept) => dept?.departmentName == 'All Department')) {
      //EXPLICITLY ADDED ALL DEPARTMENT AT INDEX 0
      const dept = [{ departmentId: 0, departmentName: 'All Department' }, ...departments]
      setUpdatedDepartments(dept)
    }
  }, [])

  // useEffect(() => {
  //   if (meta) {
  //     dispatch(savePaginationData({ ...paginationData, openAnchor: false, gridSize: meta?.totalRecords }))
  //   }
  // }, [meta])

  useEffect(() => {
    //DATE OR PAGINATION STATE CHANGE IT WILL TRIGGER API CALL
    if (pageNum) {
      dispatch(getAllPostWorkingHours(dispatchParams))
    }
  }, [gridSize, pageNum, search, selectedDate, department.value])

  return (
    <GlobalWrapper
      title={'Post Working Hours'}
      actions={
        <>
          {!isHr && (
            <Stack gap={1} direction={direction}>
              <AddOrUpdateWorkingHours dispatchParams={dispatchParams} />
              {checkedRowsIds?.length > 1 && (
                <PostWorkingBulkApprove
                  checkedRowsIds={checkedRowsIds}
                  upDateCall={getAllPostWorkingHours(dispatchParams)}
                />
              )}
            </Stack>
          )}
        </>
      }>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: direction }}>
        <Stack gap={1} direction={direction}>
          <Box>
            <DatePickerCustom
              value={dayjs(selectedDate)}
              setValue={(date) => setSelectedDate(date.format('YYYY-MM-DD'))}
              viewDetails={['year', 'month', 'day']}
              error={false}
            />
          </Box>
          {isHr && (
            <Box>
              <GlobalSelectFieldWithoutFormik
                data={updatedDepartments}
                label={'Department Filter'}
                keys={{ itemId: 'departmentId', itemValue: 'departmentName' }}
                selectedDetails={department}
                setChange={setDepart}
                sx={{ minWidth: '260px' }}
              />
            </Box>
          )}
        </Stack>
        {isHr && (
          <Box sx={{ display: 'flex', alignItems: 'center', pt: direction == 'row' ? 3 : 1, px: 1 }}>
            <Typography variant="subtitle1">Total Approved Employees : {meta?.totalRecords || 100}</Typography>
          </Box>
        )}
      </Box>
      <Paper sx={{ mt: 1 }}>
        <GlobalDatagrid
          checkboxSelection={isHr ? false : true}
          tableHead={postWorkingTableHeader}
          rows={data || []}
          meta={meta?.totalRecords}
          rowUniqueId="id"
          loading={loading}
          rowClick={isHr ? false : true}
          actionComponent={
            <>
              <ActionDetails val={rowData?.name} />
              <GlobalAnchorList
                label="Update"
                component={<AddOrUpdateWorkingHours dispatchParams={dispatchParams} row={rowData} />}
              />
              <GlobalAnchorList
                label="Delete"
                component={
                  <DeleteWorkingHour
                    requestId={rowData?.id}
                    isDisabled={rowData?.status !== 'pending'}
                    dispatchParams={dispatchParams}
                  />
                }
              />

              {isHead && rowData?.status == 'pending' && (
                <>
                  <GlobalAnchorList
                    label="Approve"
                    component={
                      <ApproveGlobal
                        statusChangeApiCall={PostWorkingApproveOrReject({ id: rowData?.id, status: 1 })}
                        isDisabled={rowData?.approver == 0}
                        loading={loading}
                        upDateCall={getAllPostWorkingHours(dispatchParams)}
                      />
                    }
                  />
                  <GlobalAnchorList
                    label="Reject"
                    component={
                      <RejectGlobal
                        statusChangeApiCall={PostWorkingApproveOrReject({ id: rowData?.id, status: 2 })}
                        isDisabled={rowData?.approver == 0}
                        loading={loading}
                        upDateCall={getAllPostWorkingHours(dispatchParams)}
                      />
                    }
                  />
                </>
              )}
            </>
          }
        />
      </Paper>
    </GlobalWrapper>
  )
}

export default PostWorking
