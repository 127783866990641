import React from "react";
import { Route, Routes } from "react-router-dom";
import { AddReferel, ReferelStatus } from "../../views/user/reffer";
import Page404 from "../../views/user/Auth/Page404";

const RefferelRouter = () => {
    return (
        <Routes>
            <Route path="addrefferel" element={<AddReferel />} />
            <Route path="refferelStatus" element={<ReferelStatus />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default RefferelRouter;
