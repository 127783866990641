import { useEffect } from "react";
import React from "react";
import { Icon } from "@iconify/react";
import Personalnformation from "./Forms/Personalnformation";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { Typography, Box, Stack } from "@mui/material";
import {
  getPreprofile,
  updateEmployee,
  userBankDetails,
} from "../../../../redux/features/profileSlice";
import { useSelector, useDispatch } from "react-redux";
import AddressInformation from "./Forms/AddressInformation";
import Education from "./Forms/Education";
import OfficialInfo from "./Forms/OfficialInfo";
import BankInfo from "./Forms/BankInfo";
import { useFormik } from "formik";
import {
  bankAccntCompleate,
  profileUpdateSuccess,
} from "../../../../redux/features/authSlice";

export default function Form({ activeStep: step, setActiveStep }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const preprofile = useSelector((state) => state.preprofile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPreprofile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [preprofile]);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const CreateSchema1 = Yup.object().shape({
    name: Yup.string().required("Name Required"),
    parentName: Yup.string().required("ParentName Required"),
    sex: Yup.string().required("Gender Required").oneOf(["Male", "Female"]),
    bloodGroup: Yup.string().required("blood Group Required"),
    maritalStatus: Yup.string().required("marital Status Required"),
    dob: Yup.string().required("DOB Required"),
    parentContactNo: Yup.string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
  });
  const formik1 = useFormik({
    initialValues: {
      name: "",
      parentName: "",
      sex: "",
      bloodGroup: "",
      maritalStatus: "",
      dob: "",
      parentContactNo: "",
    },
    validationSchema: CreateSchema1,
    onSubmit: (values) => {
      console.log("mdjjkjjj");
      setActiveStep(step + 1);
    },
  });

  const CreateSchema2 = Yup.object().shape({
    houseName: Yup.string().required("House Name Required"),
    streetOrArea: Yup.string().required("Street Or Area Required"),
    city: Yup.string().required("city Required"),
    postOffice: Yup.string().required("Post Office Required"),
    district: Yup.string().required("district Required"),
    state: Yup.string().required("state Required"),
    country: Yup.string().required("country Required"),
    pinCode: Yup.string()
      .required("pinCode Required")
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),

    PSThouseName: Yup.string().required("House Name Required"),
    PSTstreetOrArea: Yup.string().required("Street Or Area Required"),
    PSTcity: Yup.string().required("city Required"),
    PSTpostOffice: Yup.string().required("Post Office Required"),
    PSTdistrict: Yup.string().required("district Required"),
    PSTstate: Yup.string().required("state Required"),
    PSTcountry: Yup.string().required("country Required"),
    PSTpinCode: Yup.string()
      .required("pinCode Required")
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits")
      .max(6, "Must be exactly 6 digits"),
  });
  const formik2 = useFormik({
    initialValues: {
      houseName: "",
      streetOrArea: "",
      city: "",
      postOffice: "",
      district: "",
      state: "",
      country: "",
      pinCode: "",

      PSThouseName: "",
      PSTstreetOrArea: "",
      PSTcity: "",
      PSTpostOffice: "",
      PSTdistrict: "",
      PSTstate: "",
      PSTcountry: "",
      PSTpinCode: "",
    },
    validationSchema: CreateSchema2,
    onSubmit: (values) => {
      setActiveStep(step + 1);
    },
  });

  const CreateSchema3 = Yup.object().shape({
    courseType: Yup.string().required("course Type Required"),
    course: Yup.string().required("course Required"),
    courseStream: Yup.string().required("courseStream Required"),
    courseStatus: Yup.string().required("courseStatus Required"),
    yearOfPass: Yup.number().required("year Of Pass Required"),
    academicRemarks: Yup.string().required("academic Remarks Required"),
  });

  const formik3 = useFormik({
    initialValues: {
      courseType: "",
      course: "",
      courseStream: "",
      courseStatus: "",
      yearOfPass: "",
      academicRemarks: "",
    },
    validationSchema: CreateSchema3,
    onSubmit: () => {
      setActiveStep(step + 1);
    },
  });
  const panRegX = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
  const CreateSchema4 = Yup.object().shape({
    aadhaarCardNo: Yup.string()
      .required("aadhaar Card No Remarks Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(12, "Must be exactly 12 digits")
      .max(12, "Must be exactly 12 digits"),
    panCardNo: Yup.string()
      .required("panCardNo Required")
      // .matches(panRegX, "Pan number is not valid"),
      .min(10, "Must be exactly 10 ")
      .max(10, "Must be exactly 10 "),

    pfNo: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(22, "Must be exactly 22 digits")
      .max(22, "Must be exactly 22 digits"),
    doj: Yup.string().required("date of joining Required"),
    mobileNo: Yup.string()
      .required("mobileNo Required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    officialMobileNo: Yup.string()
      .required("official mobile No Required")

      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
    officialEmail: Yup.string().required("official Email Required"),
  });
  const formik4 = useFormik({
    initialValues: {
      aadhaarCardNo: "",
      panCardNo: "",
      pfNo: "",
      doj: "",
      mobileNo: "",
      officialMobileNo: "",
      officialEmail: "",
    },
    validationSchema: CreateSchema4,
    onSubmit: () => {
      dispatch(updateEmployee(data)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, {
            variant: "error",
          });
        } else if (res.payload.status === "success") {
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
          dispatch(profileUpdateSuccess());
          setActiveStep(step + 1);
        }
      });
    },
  });

  const CreateSchema5 = Yup.object().shape({
    bankName: Yup.string().required("Bank Name Required"),
    bankBranch: Yup.string().required("Branch Name Required"),
    accountHolder: Yup.string().required("Account Holder Required"),
    accountNumber: Yup.string()
      .required("Account Number Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be exactly 10 ")
      .max(12, "Must be exactly 12 "),
    ifsc: Yup.string().required("IFSC Code Required"),
    upiApp: Yup.string().required("UPI ID Required"),
    upiMobileNo: Yup.string()
      .required("UPI Mobile Number Required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits"),
  });
  const formik5 = useFormik({
    initialValues: {
      bankName: "",
      bankBranch: "",
      accountHolder: "",
      accountNumber: "",
      ifsc: "",
      upiApp: "",
      upiMobileNo: "",
    },
    validationSchema: CreateSchema5,
    onSubmit: (values) => {
      dispatch(userBankDetails(values)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, {
            variant: "error",
          });
        } else if (res.payload.status === "success") {
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
          dispatch(bankAccntCompleate());
        }
      });
    },
  });

  const data = {
    ...formik1.values,
    ...formik2.values,
    ...formik3.values,
    ...formik4.values,
  };
  console.log("total data", data);

  switch (step) {
    case 0:
      return (
        <>
          <Personalnformation
            formik={formik1}
            step={step}
            setActiveStep={setActiveStep}
          />
        </>
      );
    case 1:
      return (
        <>
          <AddressInformation
            formik={formik2}
            step={step}
            setActiveStep={setActiveStep}
          />
        </>
      );
    case 2:
      return (
        <>
          <Education
            formik={formik3}
            step={step}
            setActiveStep={setActiveStep}
          />
        </>
      );
    case 3:
      return (
        <>
          <OfficialInfo
            formik={formik4}
            step={step}
            setActiveStep={setActiveStep}
          />
        </>
      );
    case 4:
      return (
        <>
          <BankInfo
            formik={formik5}
            step={step}
            setActiveStep={setActiveStep}
          />
        </>
      );
    default:
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction={"column"}>
            <Icon
              icon="fluent:form-28-regular"
              width="80"
              height="60"
              color="#2f72b3"
            />
            <Typography variant="h5">Form End...</Typography>
          </Stack>
        </Box>
      );
  }
}
