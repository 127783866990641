import * as React from "react";
import { GlobalBarChart } from "../../components/Dashboard/GlobalComponents/GlobalBarChart";


const InduvidualReportChart = ({ data, chartId,storedStudentDataParams}) => {
    const [chartDatas, setChartDatas] = React.useState([]);
    const storedStudentData = storedStudentDataParams
    const [ymaxValue,setYmaxValue]=React.useState("")
   
    React.useEffect(()=>{
        if(storedStudentData.courseName==1){ //BAR CHART Y AXIS MAXIMUM VALUE IS 30 EXCEPT SPEAKPRO(50)
            setYmaxValue(50)
        }else{
            setYmaxValue(30)
        }
        },[storedStudentData])


    React.useEffect(() => {
        if (data) {
            if (storedStudentData?.courseName === 1) {
                let out = [];
                data.forEach((matrix) => {
                    out.push({
                        label: matrix.matrixName,
                        value: parseInt(matrix.gradeName),
                    });
                });
                setChartDatas(out);
            } else {
                let out = [];
                data.forEach((subjectData) => {
                    const objectivesArray = subjectData.levels?.[0]?.objectives;
                    if (objectivesArray && objectivesArray.length > 0) {
                        let maximumMark = objectivesArray.length * 30;
                        let achievedScore = objectivesArray.reduce(
                            (acc, objective) =>
                                ( parseInt(objective.starRating) || 0) + acc,
                            0
                        );
                        let totalPercentage =
                            (achievedScore / maximumMark) * 100;
                        let outOfThirty = (totalPercentage / 100) * 30;
                        out.push({
                            label: subjectData.subject,
                            value: Math.round(outOfThirty),
                        });
                    }
                });
                setChartDatas(out);
            }
        }
    }, [data]);

    if (chartId === 1) {
        return <GlobalBarChart horizontalView={false} chartData={chartDatas} maxY={ymaxValue} chartEdit={true}  />;
    } 
};

export default InduvidualReportChart;
