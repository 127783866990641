import React, { useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import {
  ApproveButton,
  CancelButton,
  UpdateButton,
} from "../../../../components/mui/Buttons/Buttons";
import { Box, Chip, Stack, TextField } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import {
  approveLeaveReportingHead,
  getLeaveAplictionsforReportingHead,
} from "../../../../redux/features/leaveSlice";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";

const ApproveLeaveAction = ({ params }) => {
  const { loading } = useSelector((state) => state.leave);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const schema = yup.object().shape({
    status: yup.number().required("please fill this field"),
    remarks: yup.string().required("This field is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      leaveId: params.leaveId || "",
      status: "",
      leaveTypeId: params.leavetype_id || "",
      remarks: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(approveLeaveReportingHead(values)).then((res) => {
        if (res.payload.status === "success") {
          setOpen(false);
          dispatch(
            getLeaveAplictionsforReportingHead(
              dayjs(params.fromDate).format("YYYY/MM/0/0")
            )
          );
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(res.payload.message, {
            variant: "error",
          });
        }
      });
    },
  });
  return (
    <GlobalDialog
      title="Approve Leave"
      open={open}
      setOpen={setOpen}
      closeButton={true}
      popupButton={
        <>
          {params.hod_status && params.hod_status == 1 ? (
            <Chip
              icon={<Icon icon="mdi:tick" />}
              color="success"
              size="small"
              variant="outlined"
              label="Approved"
            />
          ) : params.hod_status && params.hod_status == -1 ? (
            <Chip
              icon={<Icon icon="gridicons:cross" />}
              color="error"
              size="small"
              variant="outlined"
              label="Rejected"
            />
          ) : (
            <ApproveButton
              disabled={
                params.hod_status && params.hod_status == 2 ? true : false
              }
              size="small"
              action={() => setOpen(true)}
            >
              Approve
            </ApproveButton>
          )}
        </>
      }
      fullWidth
      maxWidth={"sm"}
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Box padding={2}>
                <Stack direction={"column"} spacing={2}>
                  <TextField
                    label={"Leave Type"}
                    value={params.leaveType}
                    fullWidth
                  />
                  <TextField
                    label={"Leave Type"}
                    value={params.leaveType}
                    fullWidth
                  />
                  <TextField
                    label={"From Date"}
                    value={params.fromDate}
                    fullWidth
                  />
                  <TextField
                    label={"To Date"}
                    value={params.toDate}
                    fullWidth
                  />
                  <TextField
                    label={"No Of Days"}
                    value={params.noOfdays}
                    fullWidth
                  />
                  <TextField label={"Reason"} value={params.reason} fullWidth />

                  <TextField
                    name="remarks"
                    label="Remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    multiline
                    rows={3}
                    error={Boolean(formik.errors.remarks)}
                    helperText={formik.errors.remarks}
                  />
                  <GlobalSelectField
                    options={{
                      name: "status",
                      label: "Action",
                      formik: formik,
                      data: [
                        { id: 1, value: "Approve" },
                        { id: -1, value: "Reject" },
                      ],
                      keys: {
                        id: "id",
                        value: "value",
                      },
                    }}
                  />
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction={"row"} spacing={1}>
                      <CancelButton size="small" action={() => setOpen(false)}>
                        Cancel
                      </CancelButton>
                      <UpdateButton size="small" type="submit">
                        {loading ? "Updating..." : "Update"}
                      </UpdateButton>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Form>
          </FormikProvider>
        </>
      }
    />
  );
};

export default ApproveLeaveAction;
