import { memo } from "react";
import { RecheduleBooking } from "./Mentoring/RecheduleBooking";
import { DeleteBooking } from "./Mentoring/DeleteBooking";

const RecheduleBookingView = memo((params) => {
    return <RecheduleBooking params={params} />;
});
const DeleteBookingView = memo((params) => {
    return <DeleteBooking params={params} />;
});
export { RecheduleBookingView, DeleteBookingView };
