import React from "react";
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormHelperText,
  Typography,
} from "@mui/material";

import {
  getPreprofile,
  updateEmployee,
  userBankDetails,
} from "../../../../../redux/features/profileSlice";
import useResponsive from "../../../../../Hooks/useResponsive";
import { useSelector } from "react-redux";
import { Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";

export default function Education({ formik, setActiveStep, step }) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  console.log("formik.education", formik);
  const preprofile = useSelector((state) => state.preprofile);

  return (
    <div>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {!smUp ? (
            <>
              <Typography padding={1} variant="h6">
                Education Details
              </Typography>
            </>
          ) : null}
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Current Status / Highest Qualification</InputLabel>
                <Select
                  variant="outlined"
                  label="Current Status / Highest Qualification"
                  value={formik.values.courseType}
                  onChange={(e) =>
                    formik.setFieldValue("courseType", e.target.value)
                  }
                  name="courseType"
                  error={Boolean(
                    formik.touched.courseType && formik.errors.courseType
                  )}
                >
                  {preprofile.courseType.map((item) => (
                    <MenuItem
                      value={item.course_type_id}
                      key={item.course_type_id}
                    >
                      {item.course_type_name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.courseType && formik.errors.courseType}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Course Name</InputLabel>
                <Select
                  name="course"
                  variant="outlined"
                  label="Course Name"
                  value={formik.values.course}
                  onChange={(e) =>
                    formik.setFieldValue("course", e.target.value)
                  }
                  error={Boolean(formik.touched.course && formik.errors.course)}
                >
                  {preprofile.course?.map((item) => {
                    if (item.course_type_id === formik.values.courseType) {
                      return (
                        <MenuItem value={item.course_id} key={item.course_id}>
                          {item.course_name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                <FormHelperText>
                  {formik.touched.course && formik.errors.course}
                </FormHelperText>
              </FormControl>
            </Stack>
          </Box>

          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <FormControl fullWidth>
                <InputLabel>Branch /Specialization</InputLabel>
                <Select
                  variant="outlined"
                  label="Branch /Specialization"
                  value={formik.values.courseStream}
                  onChange={(e) =>
                    formik.setFieldValue("courseStream", e.target.value)
                  }
                  name="courseStream"
                  error={Boolean(
                    formik.touched.courseStream && formik.errors.courseStream
                  )}
                >
                  {preprofile.courseStream?.map((item) => {
                    if (item.course_id === formik.values.course) {
                      return (
                        <MenuItem
                          value={item.course_stream_id}
                          key={item.course_stream_id}
                        >
                          {item.course_stream_name}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
                <FormHelperText>
                  {formik.touched.courseStream && formik.errors.courseStream}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Course Status </InputLabel>
                <Select
                  variant="outlined"
                  label="Course Status "
                  value={formik.values.courseStatus}
                  onChange={(e) =>
                    formik.setFieldValue("courseStatus", e.target.value)
                  }
                  name="courseStatus"
                  error={Boolean(
                    formik.touched.courseStatus && formik.errors.courseStatus
                  )}
                >
                  <MenuItem value={0}>Passed</MenuItem>
                  <MenuItem value={1}>Appearing</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.touched.courseStatus && formik.errors.courseStatus}
                </FormHelperText>
              </FormControl>
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="Year of Passing"
                variant="outlined"
                fullWidth
                value={formik.values.yearOfPass}
                onChange={formik.handleChange}
                name="yearOfPass"
                type="number"
                error={Boolean(
                  formik.touched.yearOfPass && formik.errors.yearOfPass
                )}
                helperText={
                  formik.touched.yearOfPass && formik.errors.yearOfPass
                }
              />
              <TextField
                label="Academic Remarks"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.academicRemarks}
                name="academicRemarks"
                error={Boolean(
                  formik.touched.academicRemarks &&
                    formik.errors.academicRemarks
                )}
                helperText={
                  formik.touched.academicRemarks &&
                  formik.errors.academicRemarks
                }
              />
            </Stack>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box margin={1}>
              <Button
                onClick={() => setActiveStep(step - 1)}
                variant="outlined"
              >
                Back
              </Button>
            </Box>
            <Box margin={1}>
              <Button variant="contained" type="submit">
                Next
                <Icon icon="tabler:player-track-next" width="25" height="25" />
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
