import {
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import useResponsive from "../../../Hooks/useResponsive";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { useState } from "react";
import Wrapper from "../../Wrapper/Wrapper";
import { makeStyles } from "@mui/styles";
import DatePickerCustom from "../../DatePicker/DatePickerCustom";

export default function CustomDataGrid({
  data,
  actions,
  details,
  rowId,
  columns,
  Head,
  setSelectedRows,
  selectedRows,
  name,
  admissionNo,
  chipValue,
  label,
  loading,
  pagination,
  setPaginationLimit,
  rowCount,
  pageNumberChange,
  handleFilterChange,
  customDataGridExtraClose,
  setCustomDataGridExtraClose
}) {
 
  const [gridSize, setGridSize] = useState(5);
  const [check, setCheck] = React.useState("Details");

  const [checked, setChecked] = React.useState(false);
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const defaultRowHeight = 50; // Set your desired default row height
  const maxHeightForAutoHeight = 50;

  const getRowHeight = (params) => {
    // Dynamically check content length for any column and adjust row height
    const dynamicContentLength = Object.keys(params.row).reduce(
      (acc, key) => (acc += String(params.row[key]).length),
      0
    );

    if (dynamicContentLength > maxHeightForAutoHeight) {
      return (
        params.api.getPreferredRowCount(params.row, true) * defaultRowHeight
      );
    }

    return defaultRowHeight;
  };
  const useStyles = makeStyles((theme) => ({
    customScrollbar: {
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: theme.palette.grey[200],
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  }));
  const classes = useStyles();


useEffect(()=>{
if(customDataGridExtraClose){
  setCheck(false)
  setSelectedRows([]);
  setGridSize(5);
}
if(setCustomDataGridExtraClose){
  setCustomDataGridExtraClose(false)
}

},[customDataGridExtraClose])


  return (
    <div>
      <Box marginTop={2}>
        <Box>
          <Box
            sx={{
              backgroundColor: "grey.200",
              borderRadius: 2,
            }}
          >
            <Stack direction={selectedRows.length === 0 ? "column" : "row"}>
              <Box
                width={
                  selectedRows.length === 0 ? "100%" : smUp ? "30%" : "0.1%"
                }
              >
                <Box
                  sx={{
                    borderRadius: selectedRows.length === 0 ? "8px 8px 0 0" : 3,
                  }}
                ></Box>
                <Box
                  className={classes.customScrollbar}
                  backgroundColor={"#fff"}
                  sx={{
                    height: selectedRows.length === 0 ? "" : 650,
                    overflow: selectedRows.length === 0 ? "" : "scroll",
                  }}
                >
               

                  <DataGrid
                   paginationMode={pagination?"server":"client"}
                   rowCount={rowCount}
                   onPageChange={(page)=>pageNumberChange(page)}
                    loading={loading}
                    rows={data}
                    columns={selectedRows?.length === 0 ? columns : Head}
                    pageSize={gridSize}
                    onPageSizeChange={(newGridSize) => {
                      setGridSize(newGridSize);
                      if (pagination) {
                        setPaginationLimit(newGridSize);
                      }
                    }}
                    sx={{
                      "& .MuiDataGrid-columnHeaderTitle": {
                        textOverflow: "clip",
                        whiteSpace: "break-spaces",
                        lineHeight: 1,
                      },
                    }}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    columnBuffer={30}
                    getRowId={(row) => row[rowId]}
                    autoHeight
                    // getRowHeight={() => "auto"}
                    checkboxSelection={false}
                    onSelectionModelChange={(ids) => {
                      setCheck("Details");
                      setChecked(true);
                      setGridSize(10);
                      const selectedIDs = new Set(ids);
                      const selectedRows = data?.filter((row) =>
                        selectedIDs.has(row[rowId])
                      );

                      setSelectedRows(selectedRows);
                    }}
                    components={{
                      Toolbar: CustomGridToolbar,
                    }}
                    componentsProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          debounceMs: 500,
                        },
                      },
                    }}
                    onFilterModelChange={handleFilterChange}
                  />
                </Box>
              </Box>
              {/* </Paper> */}
              {selectedRows.length === 0 ? (
                ""
              ) : (
                <Box
                  sx={{
                    marginTop: smUp ? "" : 1,
                  }}
                  width={
                    selectedRows.length === 0 ? "100%" : smUp ? "70%" : "100%"
                  }
                >
                  <Slide
                    direction="left"
                    in={checked}
                    mountOnEnter
                    unmountOnExit
                    timeout={{ enter: 800 }}
                  >
                    <Paper
                      className={classes.customScrollbar}
                      sx={{
                        backgroundColor: "grey.200",
                        height: 650,
                        overflow: "scroll",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          position: "sticky",
                          top: 0,
                          zIndex: 1000,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: 1,
                            backgroundColor: "grey.200",
                          }}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            sx={{
                              borderRadius: 5,
                            }}
                            onClick={() => {
                              setChecked(false);
                              setSelectedRows([]);
                              setGridSize(5);
                            }}
                          >
                            x
                          </Button>
                        </Box>
                      </Box>
                      <Box paddingLeft={3}>
                        <Typography variant="h6">{name}</Typography>
                        <Chip
                          size="small"
                          color="primary"
                          label={`${label} :-
                                ${chipValue}`}
                        />

                        <Divider
                          sx={{
                            borderStyle: "dashed",
                            marginTop: 2,
                            borderBottomWidth: 2,
                          }}
                        />

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 2,
                          }}
                        >
                          <Stack direction={"row"} spacing={2}>
                            <Chip
                              label="Details"
                              onClick={() => {
                                setCheck("Details");
                              }}
                              color="primary"
                              variant={
                                check === "Details" ? "filled" : "outlined"
                              }
                            />

                            <Chip
                              label="Actions"
                              color="primary"
                              onClick={() => {
                                setCheck("Actions");
                              }}
                              variant={
                                check === "Actions" ? "filled" : "outlined"
                              }
                            />
                          </Stack>
                        </Box>
                        {check === "Details" ? (
                          <Wrapper Content={<Box>{details}</Box>} />
                        ) : null}
                        {check === "Actions" ? (
                          <Wrapper Content={<Box>{actions}</Box>} />
                        ) : null}
                      </Box>
                    </Paper>
                  </Slide>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
