import { Button } from "@mui/material";
import AddButtonCroStudents from "./AddButtonCroStudents";

export const CRO_STUDENTS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "admnNo",
        headerName: "Admission NO",
        flex: 1,
        maxWidth: 200,
        headerAlign: "left",
        align: "left",
    },
    {
        field: "studentName",
        headerName: "Name",
        flex: 1,
        maxWidth: 200,
        headerAlign: "left",
        align: "left",
    },
    {
        field: "contactNumber",
        headerName: "Mobile number",
        flex: 1,
        maxWidth: 150,
        headerAlign: "left",
        align: "left",
    },
    {
        field: "courseName",
        headerName: "Course Name",
        flex: 1,
        headerAlign: "left",
        align: "left",
    },
    {
        field: "booksAndPublications",
        headerName: "Books and Publications",
        flex: 1,
        headerAlign: "left",
        align: "left",
    },
    {
        field: "numberOfEnrollments",
        headerName: "No Of enrollments",
        flex: 1,
        headerAlign: "left",
        align: "left",
    },
    {
        field: "action",
        headerName: "Action",
        flex: 1,
        maxWidth: 100,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
            return <AddButtonCroStudents row={params.row} />;
        },
    },
];
