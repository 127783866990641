import { Box } from "@mui/material";
import React from "react";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { TC_UPLOADED_STUDENTDETAILS_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/TcUploadedStudentDetails/TcUploadedStudentDetailsTableHead";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Details } from "./Details";
import { Actions } from "./Actions";
const data = [
    {
        id: 1,
        admissionNo: 6049,
        name: "AZIM JANSHID",
        mobile: "8848456953",
        class: 6,
        course: "Classroom - Short Term",
        croName: "DIYA FATHIMA.T U",
        totalSalesAmount: 16899,
        toatalFeeCollected: 17399,
        totalSessionsCompleted: 40,
        lastSessionDate: "2023-03-13",
        inactiveReason: "Course Completed",
        inactiveRemarks: "class over and selected next academic year	",
        inactiveMarkedTime: "2023-02-28 16:24:09",
        modeOfTcUpload: "Direct",
        activeStatusOnOtherCourse: "No",
        approval: "",
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function TcUploadedStudentDetails() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [value, setValue] = React.useState(dayjs("2022-04-07"));
    return (
        <div>
            <Wrapper
                title="TC Uploaded Student Details"
                Content={
                    <>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={["year", "month"]}
                                    label="Year and Month"
                                    value={value}
                                    InputProps={{ readOnly: true }}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.admissionNo}
                                label="AdNo"
                                name={selectedRows[0]?.name}
                                details={<Details data={selectedRows[0]} />}
                                actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
