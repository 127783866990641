import { Stack, Typography } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";

export default function ContactAction(params) {
  return (
    <div>
      <Stack direction={"row"}>
        <Typography>{params.row.phone}</Typography>
        <a
          href={`https://api.whatsapp.com/send?phone=91${params.row.phone}`}
          target="_blank"
        >
          <Icon icon="logos:whatsapp-icon" width="20" height="20" />
        </a>
      </Stack>
    </div>
  );
}
