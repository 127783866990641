import React, { useState } from "react";
import { TerminationClearance } from "./TerminationClearance";
import { useSelector, useDispatch } from "react-redux";
import {
    Box,
    Button,
    Paper,
    Stack,
    Chip,
    Rating,
    Typography,
    Card,
    CardContent,
    Grid,
    Divider,
    Switch,
    FormControlLabel,
    FormGroup,
} from "@mui/material";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import useResponsive from "../../../../../Hooks/useResponsive";
import { Icon } from "@iconify/react";
import { UpdateButton } from "../../../../mui/Buttons/Buttons";
import { useEffect } from "react";
import {
    updateCourseEnrollmentStatus,
    getAdmissionRegister,
} from "../../../../../redux/features/crmSlice";
import { useSnackbar } from "notistack";

export const Switches = ({
    content,
    label,
    firstLabel,
    secondLabel,
    setSwitch,
}) => {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    return (
        <Chip
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                padding: smUp ? 2 : 1,
            }}
            size={smUp ? "large" : "small"}
            variant="outlined"
            color="primary"
            icon={
                <Icon icon="ei:arrow-left" width="25" height="25" rotate={2} />
            }
            label={
                <>
                    <FormGroup>
                        <FormControlLabel
                            label={
                                <Typography fontSize={smUp ? null : 13}>
                                    {label}:-
                                </Typography>
                            }
                            labelPlacement="start"
                            control={
                                <>
                                    {smUp ? (
                                        <Typography
                                            fontSize={smUp ? null : 10}
                                            fontWeight="bolder"
                                        >
                                            {firstLabel}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}

                                    {<>{setSwitch}</>}
                                    {smUp ? (
                                        <Typography
                                            fontSize={smUp ? null : 10}
                                            fontWeight="bolder"
                                        >
                                            {secondLabel}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </>
                            }
                        />
                    </FormGroup>
                </>
            }
        />
    );
};

export const MyCard = ({
    title1,
    title2,
    action,
    value,
    name,
    onChange,
    ratingName,
    Ratingvalue,
    helperText,
    error,
    ratingHelperText,
    ratingError,
}) => {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    return (
        <Card sx={{ minWidth: "100%" }}>
            <CardContent>
                <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                >
                    {title1}
                </Typography>
                <Box>
                    <Rating
                        size={smUp ? "large" : "small"}
                        name={ratingName}
                        value={Ratingvalue}
                        max={10}
                        onChange={action}
                        required
                    />
                </Box>

                <Typography variant="body2">{title2}</Typography>
                <Box padding={1}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        maxRows={3}
                        value={value}
                        name={name}
                        onChange={onChange}
                        label="Remarks"
                        error={error}
                        helperText={helperText}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};

export const Active = ({ courseEnrollmentId, params, setOpen }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const [value, setValue] = React.useState(null);
    const data = useSelector(
        (state) => state.crm.courseTcDetails.courseEnrollment
    );

    const preData = useSelector((state) => state.crm.courseInactiveReasons);
    const admissionRegister = useSelector((state) => state.crm);

    const [status, setStatus] = useState("");

    const val = [
        { id: 0, status: "Inactive" },
        { id: 2, status: "Temporary Inactive" },
    ];

    const values = [
        { id: 1, status: "Active" },
        { id: 0, status: "Inactive" },
    ];

    const schema = () => {
        if (formik.values.courseEnrollmentStatusId === 0) {
            return Yup.object().shape({
                courseEnrollmentStatusId: Yup.string().required(
                    "courseEnrollmentStatusId is required"
                ),
                inactiveDate: Yup.string().required("inactiveDate is required"),
                inactiveReasonId: Yup.string().required(
                    "inactive Reason required "
                ),
                inactiveRemarks: Yup.string().required(
                    "student inactive Remarks"
                ),

                // studentRating: Yup.string().required("student Rating required "),
                studentRemarks: Yup.string().required(
                    "student Remarks required"
                ),
                // parentRating: Yup.string().required("parent Rating required"),
                parentRemarks: Yup.string().required("parent Remarks required"),
                // tutorRating: Yup.string().required("tutor Rating required"),
                tutorRemarks: Yup.string().required("tutor Remarks required"),
            });
        } else if (formik.values.courseEnrollmentStatusId === 1) {
            return Yup.object().shape({
                courseEnrollmentStatusId: Yup.string().required(
                    "courseEnrollmentStatusId is required"
                ),
                inactiveRemarks: Yup.string().required(
                    "student inactive Remarks"
                ),
            });
        } else if (formik.values.courseEnrollmentStatusId === 2) {
            return Yup.object().shape({
                courseEnrollmentStatusId: Yup.string().required(
                    "courseEnrollmentStatusId is required"
                ),
                inactiveDate: Yup.string().required("inactiveDate is required"),
                inactiveReasonId: Yup.string().required(
                    "inactive Reason required "
                ),
                inactiveRemarks: Yup.string().required(
                    "student inactive Remarks"
                ),
            });
        }
        return Yup.object().shape({
            courseEnrollmentStatusId: Yup.string().required(
                "courseEnrollmentStatusId is required"
            ),
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseEnrollmentId: courseEnrollmentId,
            courseEnrollmentStatusId: "",
            inactiveDate: "",
            courseRestartDate: "",
            inactiveReasonId: "",
            inactiveRemarks: "",
            studentRating: null,
            studentRemarks: "",
            parentRating: null,
            parentRemarks: "",
            tutorRating: null,
            tutorRemarks: "",
            admissionFeeStatus: 0,
            courseFeeStatus: 0,
            certificateStatus: 0,
            parentFreebieStatus: 0,
            studentFreebieStatus: 0,
            croRetainCallStatus: 0,
            ogaRetainCallStatus: 0,
            croExitCallStatus: 0,
        },

        validationSchema: schema,
        onSubmit: (values) => {
            dispatch(updateCourseEnrollmentStatus(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(
                        getAdmissionRegister(admissionRegister.admissionRegData)
                    );
                    setOpen(false);
                }
            });
        },
    });

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        "& .MuiSwitch-track": {
            borderRadius: 22 / 2,
            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 16,
            },
            "&:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main)
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            "&:after": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main)
                )}" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /></svg>')`,
                right: 12,
            },
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 16,
            height: 16,
            margin: 2,
        },
    }));

    return (
        <div>
            <Box>
                <TerminationClearance data={data} />
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                width: "100%",
                                paddingTop: 10,
                            }}
                        >
                            <Paper
                                sx={{
                                    margin: 4,
                                    backgroundColor: "grey.200",
                                }}
                            >
                                <Box padding={smUp ? 3 : 0}>
                                    <Stack
                                        direction={smUp ? "row" : "column"}
                                        spacing={2}
                                    >
                                        <GlobalSelectField
                                            fullWidth
                                            options={{
                                                formik: formik,
                                                name: "courseEnrollmentStatusId",
                                                label: "Course Enrollment Status",
                                                // sx: { width: "50%" },
                                                data:
                                                    params ===
                                                    "Temporarly Inactive"
                                                        ? values
                                                        : [] &&
                                                          params === "Active"
                                                        ? val
                                                        : [],

                                                keys: {
                                                    id: "id",
                                                    value: "status",
                                                },
                                            }}
                                        />
                                        {params?.param ===
                                            "Temporarly Inactive" ||
                                        formik.values
                                            .courseEnrollmentStatusId == 2 ||
                                        formik.values
                                            .courseEnrollmentStatusId == 0 ? (
                                            <>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        label="Date of Inactive"
                                                        name="inactiveDate"
                                                        value={
                                                            formik.values
                                                                .inactiveDate
                                                        }
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "inactiveDate",
                                                                e.format(
                                                                    "YYYY-MM-DD"
                                                                )
                                                            );
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                //   sx={{ width: "50%" }}
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                                <GlobalSelectField
                                                    options={{
                                                        name: "inactiveReasonId",
                                                        label: "Reason ",
                                                        formik: formik,
                                                        // sx: { width: "50%" },
                                                        data: preData?.inactiveReasons,

                                                        keys: {
                                                            id: "inactiveReasonId",
                                                            value: "inactiveReasonName",
                                                        },
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </Stack>
                                    {(formik.values.courseEnrollmentStatusId ===
                                        1 &&
                                        params?.params ===
                                            "Temporarly Inactive") ||
                                    formik.values.courseEnrollmentStatusId ===
                                        2 ? (
                                        <Box marginTop={2}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <DatePicker
                                                    name="courseRestartDate"
                                                    label="Course Restart Date"
                                                    value={
                                                        formik.values
                                                            .courseRestartDate
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            "courseRestartDate",
                                                            e.format(
                                                                "YYYY-MM-DD"
                                                            )
                                                        );
                                                    }}
                                                    inputProps={{
                                                        readOnly: true,
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            fullWidth
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    ) : (
                                        ""
                                    )}

                                    <Box marginTop={2}>
                                        <TextField
                                            label="Remarks "
                                            multiline
                                            fullWidth
                                            rows={4}
                                            variant="filled"
                                            name="inactiveRemarks"
                                            value={
                                                formik.values.inactiveRemarks
                                            }
                                            onChange={formik.handleChange}
                                            error={Boolean(
                                                formik.touched
                                                    .inactiveRemarks &&
                                                    formik.errors
                                                        .inactiveRemarks
                                            )}
                                            helperText={
                                                formik.errors.inactiveRemarks
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                            {/* --------------------------------------------------------------------------------------------- */}
                            {formik.values.courseEnrollmentStatusId === 0 ? (
                                <>
                                    <Box padding={3}>
                                        <Divider
                                            sx={{
                                                borderStyle: "dashed",
                                                borderBottomWidth: 3,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                padding={2}
                                            >
                                                Rating..
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <MyCard
                                                    name="studentRemarks"
                                                    value={
                                                        formik.values
                                                            .studentRemarks
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={Boolean(
                                                        formik.touched
                                                            .studentRemarks &&
                                                            formik.errors
                                                                .studentRemarks
                                                    )}
                                                    helperText={
                                                        formik.touched
                                                            .studentRemarks &&
                                                        formik.errors
                                                            .studentRemarks
                                                    }
                                                    ratingName="studentRating"
                                                    Ratingvalue={
                                                        formik.values
                                                            .studentRating
                                                    }
                                                    action={(e) => {
                                                        formik.setFieldValue(
                                                            "studentRating",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    title1={"Student Rating"}
                                                    title2={"Student Remarks"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <MyCard
                                                    name="parentRemarks"
                                                    value={
                                                        formik.values
                                                            .parentRemarks
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={Boolean(
                                                        formik.touched
                                                            .parentRemarks &&
                                                            formik.errors
                                                                .parentRemarks
                                                    )}
                                                    helperText={
                                                        formik.touched
                                                            .parentRemarks &&
                                                        formik.errors
                                                            .parentRemarks
                                                    }
                                                    ratingName="parentRating"
                                                    Ratingvalue={
                                                        formik.values
                                                            .parentRating
                                                    }
                                                    action={(e) => {
                                                        formik.setFieldValue(
                                                            "parentRating",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    title1={"Parent Rating"}
                                                    title2={"Parent Remarks"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <MyCard
                                                    name="tutorRemarks"
                                                    value={
                                                        formik.values
                                                            .tutorRemarks
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    error={Boolean(
                                                        formik.touched
                                                            .tutorRemarks &&
                                                            formik.errors
                                                                .tutorRemarks
                                                    )}
                                                    helperText={
                                                        formik.touched
                                                            .tutorRemarks &&
                                                        formik.errors
                                                            .tutorRemarks
                                                    }
                                                    ratingName="tutorRating"
                                                    Ratingvalue={
                                                        formik.values
                                                            .tutorRating
                                                    }
                                                    ratingError={
                                                        formik.values
                                                            .tutorRemarks === 0
                                                    }
                                                    action={(e) => {
                                                        formik.setFieldValue(
                                                            "tutorRating",
                                                            Number(
                                                                e.target.value
                                                            )
                                                        );
                                                    }}
                                                    title1={"Tutor Rating"}
                                                    title2={"Tutor Remarks "}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {/* ----------------------------------------------------------------------------------------- */}
                                    <Box padding={smUp ? 3 : 1}>
                                        <Box
                                            sx={{
                                                backgroundColor: "#fff",
                                                borderRadius: 3,
                                                padding: smUp ? 5 : 1,
                                            }}
                                        >
                                            <Grid container spacing={4}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <Switches
                                                        label="Admission Fee"
                                                        firstLabel="Paid"
                                                        setSwitch={
                                                            <>
                                                                <Android12Switch
                                                                    name="admissionFeeStatus"
                                                                    checked={Boolean(
                                                                        formik
                                                                            .values
                                                                            .admissionFeeStatus
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .checked ===
                                                                            true
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                "admissionFeeStatus",
                                                                                1
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                "admissionFeeStatus",
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        secondLabel=" Not Paid"
                                                    />
                                                </Grid>
                                                {/* ---------------------------------------------------------------- */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <Switches
                                                        label="Course Fee"
                                                        firstLabel="Paid"
                                                        setSwitch={
                                                            <>
                                                                <Android12Switch
                                                                    name="courseFeeStatus"
                                                                    checked={Boolean(
                                                                        formik
                                                                            .values
                                                                            .courseFeeStatus
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .checked ===
                                                                            true
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                "courseFeeStatus",
                                                                                1
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                "courseFeeStatus",
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        secondLabel=" Not Paid"
                                                    />
                                                </Grid>

                                                {/* ---------------------------------------------------------------- */}

                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <Switches
                                                        label="Certificate"
                                                        firstLabel="Issued"
                                                        setSwitch={
                                                            <>
                                                                <Android12Switch
                                                                    name="certificateStatus"
                                                                    checked={Boolean(
                                                                        formik
                                                                            .values
                                                                            .certificateStatus
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .checked ===
                                                                            true
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                "certificateStatus",
                                                                                1
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                "certificateStatus",
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        secondLabel=" Not Issued"
                                                    />
                                                </Grid>

                                                {/* ---------------------------------------------------------------- */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <Switches
                                                        label="Parent Freebie"
                                                        firstLabel="Given"
                                                        setSwitch={
                                                            <>
                                                                <Android12Switch
                                                                    name="parentFreebieStatus"
                                                                    checked={Boolean(
                                                                        formik
                                                                            .values
                                                                            .parentFreebieStatus
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .checked ===
                                                                            true
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                "parentFreebieStatus",
                                                                                1
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                "parentFreebieStatus",
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        secondLabel="Not Given"
                                                    />
                                                </Grid>

                                                {/* ---------------------------------------------------------------- */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <Switches
                                                        label="Student Freebie"
                                                        firstLabel="Given"
                                                        setSwitch={
                                                            <>
                                                                <Android12Switch
                                                                    name="studentFreebieStatus"
                                                                    checked={Boolean(
                                                                        formik
                                                                            .values
                                                                            .studentFreebieStatus
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .checked ===
                                                                            true
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                "studentFreebieStatus",
                                                                                1
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                "studentFreebieStatus",
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        secondLabel="Not Given"
                                                    />
                                                </Grid>

                                                {/* ---------------------------------------------------------------- */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <Switches
                                                        label="CRO Retain Call"
                                                        firstLabel="Called"
                                                        setSwitch={
                                                            <>
                                                                <Android12Switch
                                                                    name="croRetainCallStatus"
                                                                    checked={Boolean(
                                                                        formik
                                                                            .values
                                                                            .croRetainCallStatus
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .checked ===
                                                                            true
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                "croRetainCallStatus",
                                                                                1
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                "croRetainCallStatus",
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        secondLabel="Not Called"
                                                    />
                                                </Grid>

                                                {/* ---------------------------------------------------------------- */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <Switches
                                                        label="OGA Retain Call"
                                                        firstLabel="Called"
                                                        setSwitch={
                                                            <>
                                                                <Android12Switch
                                                                    name="ogaRetainCallStatus"
                                                                    checked={Boolean(
                                                                        formik
                                                                            .values
                                                                            .ogaRetainCallStatus
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .checked ===
                                                                            true
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                "ogaRetainCallStatus",
                                                                                1
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                "ogaRetainCallStatus",
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        secondLabel="Not Called"
                                                    />
                                                </Grid>

                                                {/* ---------------------------------------------------------------- */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <Switches
                                                        label={"CRO Exit Call"}
                                                        firstLabel="Called"
                                                        setSwitch={
                                                            <>
                                                                <Android12Switch
                                                                    //   size="small"
                                                                    name="croExitCallStatus"
                                                                    checked={
                                                                        false
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        if (
                                                                            e
                                                                                .target
                                                                                .checked ===
                                                                            true
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                "croExitCallStatus",
                                                                                1
                                                                            );
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                "croExitCallStatus",
                                                                                0
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        secondLabel="Not Called"
                                                    />
                                                </Grid>

                                                {/* ---------------------------------------------------------------- */}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                ""
                            )}
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: 3,
                                }}
                            >
                                <UpdateButton type="submit">
                                    {" "}
                                    Update
                                </UpdateButton>
                            </Box>
                        </Box>
                    </Form>
                </FormikProvider>
            </Box>
        </div>
    );
};
