import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    DatePicker,
    LocalizationProvider,
    TimePicker,
} from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
    approvalTaskPreData,
    clearTask,
    getTask,
    updateTask,
} from "../../../redux/features/todoSlice";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/lab";
import * as yup from "yup";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader/Loader";
import {
    CancelButton,
    SubmitButton,
} from "../../../components/mui/Buttons/Buttons";
import useResponsive from "../../../Hooks/useResponsive";

const UpdateMyTask = () => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const [data, setData] = useState({});
    const [time, setTime] = useState(dayjs(""));
    const [date, setDate] = useState(dayjs(""));

    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const params = useParams();

    const dispatch = useDispatch();
    const state = useSelector((state) => state.todo);

    const schema = yup.object().shape({
        statusId: yup.number().required("This field is required"),
        remarks: yup.string().required("This field is required"),
        expectedComplitionDate: yup.string().when("statusId", {
            is: (statusId) => {
                return statusId === 1 || statusId === 0;
            },
            then: yup.string().required("This feild is required required"),
        }),
        expectedComplitionTime: yup.string().when("statusId", {
            is: (statusId) => {
                return statusId === 1 || statusId === 0;
            },
            then: yup.string().required("This feild is required"),
        }),
    });

    const formik = useFormik({
        initialValues: {
            statusId: "",
            remarks: "",
            expectedComplitionDate: "",
            expectedComplitionTime: "",
        },
        validationSchema: schema,
        onSubmit: (values, { resetForm }) => {
            values = { ...values, taskId: data.taskId };

            dispatch(updateTask(values)).then((res) => {
                if (res.payload.status === "success") {
                    resetForm(values);
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    navigate("/todo/my-tasks");
                } else {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                }
            });
        },
    });

    const handleChange = (e) => {
        if (e.target.name === "remarks") {
            formik.setFieldValue(e.target.name, e.target.value);
        }
    };

    const handleChangeDate = (e) => {
        setDate(e);

        const date = e.toISOString().split("T")[0];
        formik.setFieldValue("expectedComplitionDate", date);
    };
    const handleChangeTime = (e) => {
        setTime(e);
        const Ftime = `${new Date(e).getHours()}:${new Date(
            e
        ).getMinutes()}:00`;

        formik.setFieldValue("expectedComplitionTime", Ftime);
    };

    useEffect(() => {
        dispatch(getTask(params.id));
        return () => {
            dispatch(clearTask());
        };
    }, []);
    useEffect(() => {
        if (state.task.taskLevel) {
            setData(state.task);
            dispatch(approvalTaskPreData(state.task.taskLevel));
        }
    }, [state.task]);
    useEffect(() => {}, [state.approvalPreData]);
    return (
        <div>
            <div>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Update Task
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                {state.loading ? (
                    <Loader />
                ) : (
                    <Paper elevation={3} sx={{ marginTop: 4 }}>
                        <Box
                            sx={{
                                height: 48,
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                            }}
                        ></Box>
                        {smUp && (
                            <Box padding={4}>
                                <FormikProvider value={formik}>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Stack
                                            gap={2}
                                            sx={{
                                                minWidth: "85%",
                                                margin: "35px auto 0",
                                            }}
                                        >
                                            <TextField
                                                label="Task"
                                                multiline
                                                rows={4}
                                                value={data.task || ""}
                                                disabled
                                            />
                                            <Stack direction="row" gap={2}>
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    label="Dead Line Date"
                                                    value={
                                                        data?.taskDeadLineDate
                                                    }
                                                    disabled
                                                />
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    label="Dead Line Time"
                                                    value={
                                                        data?.taskDeadLineTime
                                                    }
                                                    disabled
                                                />
                                                <FormControl
                                                    sx={{
                                                        minWidth: 160,
                                                        flex: 1,
                                                    }}
                                                >
                                                    <InputLabel>
                                                        Previous Status
                                                    </InputLabel>
                                                    <Select
                                                        name="statusId"
                                                        label="Previous Status"
                                                        value={
                                                            data.taskStatusId
                                                        }
                                                        disabled
                                                    >
                                                        {state.approvalPreData?.map(
                                                            (item) => (
                                                                <MenuItem
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {
                                                                        item.status
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                            <Stack gap={2} direction="row">
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Current Status",
                                                        name: "statusId",
                                                        formik: formik,
                                                        data: state.approvalPreData,
                                                        keys: {
                                                            id: "id",
                                                            value: "status",
                                                        },
                                                    }}
                                                />
                                                {formik.values.statusId !==
                                                    2 && (
                                                    <Box
                                                        sx={{
                                                            flex: 2,
                                                            display: "flex",
                                                            gap: 2,
                                                            m: 1,
                                                        }}
                                                    >
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                name="expectedComplitionDate"
                                                                label="Expected compleation date"
                                                                value={date}
                                                                onChange={
                                                                    handleChangeDate
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        helperText={
                                                                            formik
                                                                                .errors
                                                                                .expectedComplitionDate
                                                                        }
                                                                        {...params}
                                                                        sx={{
                                                                            flex: 1,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <TimePicker
                                                                label="Time"
                                                                value={time}
                                                                onChange={
                                                                    handleChangeTime
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        helperText={
                                                                            formik
                                                                                .errors
                                                                                .expectedComplitionTime
                                                                        }
                                                                        {...params}
                                                                        sx={{
                                                                            flex: 1,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                )}
                                            </Stack>
                                            <TextField
                                                name="remarks"
                                                label="Remarks"
                                                multiline
                                                rows={4}
                                                onChange={handleChange}
                                                error={Boolean(
                                                    formik.errors.remarks
                                                )}
                                                helperText={
                                                    formik.errors.remarks
                                                }
                                            />
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: 2,
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <CancelButton>
                                                    Cancel
                                                </CancelButton>

                                                <SubmitButton type="submit">
                                                    Submit
                                                </SubmitButton>
                                            </Box>
                                        </Stack>
                                    </Form>
                                </FormikProvider>
                            </Box>
                        )}
                        {!smUp && (
                            <Box padding={4}>
                                <FormikProvider value={formik}>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Stack
                                            gap={2}
                                            sx={{
                                                minWidth: "85%",
                                                margin: "35px auto 0",
                                            }}
                                        >
                                            <TextField
                                                label="Task"
                                                multiline
                                                rows={4}
                                                value={data.task || ""}
                                                disabled
                                            />
                                            <Stack direction="column" gap={2}>
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    label="Dead Line Date"
                                                    value={
                                                        data?.taskDeadLineDate
                                                    }
                                                    disabled
                                                />
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    label="Dead Line Time"
                                                    value={
                                                        data?.taskDeadLineTime
                                                    }
                                                    disabled
                                                />
                                                <FormControl
                                                    sx={{
                                                        minWidth: 160,
                                                        flex: 1,
                                                    }}
                                                >
                                                    <InputLabel>
                                                        Previous Status
                                                    </InputLabel>
                                                    <Select
                                                        name="statusId"
                                                        label="Previous Status"
                                                        value={
                                                            data.taskStatusId
                                                        }
                                                        disabled
                                                    >
                                                        {state.approvalPreData?.map(
                                                            (item) => (
                                                                <MenuItem
                                                                    key={
                                                                        item.id
                                                                    }
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {
                                                                        item.status
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                            <Stack gap={2} direction="column">
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Current Status",
                                                        name: "statusId",
                                                        formik: formik,
                                                        data: state.approvalPreData,
                                                        keys: {
                                                            id: "id",
                                                            value: "status",
                                                        },
                                                    }}
                                                />
                                                {formik.values.statusId !==
                                                    2 && (
                                                    <Box
                                                        sx={{
                                                            flex: 2,
                                                            display: "flex",
                                                            gap: 2,
                                                            m: 1,
                                                        }}
                                                    >
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                name="expectedComplitionDate"
                                                                label="Expected compleation date"
                                                                value={date}
                                                                onChange={
                                                                    handleChangeDate
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        helperText={
                                                                            formik
                                                                                .errors
                                                                                .expectedComplitionDate
                                                                        }
                                                                        {...params}
                                                                        sx={{
                                                                            flex: 1,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            <TimePicker
                                                                label="Time"
                                                                value={time}
                                                                onChange={
                                                                    handleChangeTime
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        helperText={
                                                                            formik
                                                                                .errors
                                                                                .expectedComplitionTime
                                                                        }
                                                                        {...params}
                                                                        sx={{
                                                                            flex: 1,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                )}
                                            </Stack>
                                            <TextField
                                                name="remarks"
                                                label="Remarks"
                                                multiline
                                                rows={4}
                                                onChange={handleChange}
                                                error={Boolean(
                                                    formik.errors.remarks
                                                )}
                                                helperText={
                                                    formik.errors.remarks
                                                }
                                            />
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: 2,
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <CancelButton>
                                                    Cancel
                                                </CancelButton>

                                                <SubmitButton type="submit">
                                                    Submit
                                                </SubmitButton>
                                            </Box>
                                        </Stack>
                                    </Form>
                                </FormikProvider>
                            </Box>
                        )}
                    </Paper>
                )}
            </div>
        </div>
    );
};

export default UpdateMyTask;
