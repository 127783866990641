import DeleteMatrix from "./DeleteMatrix";
import StatusSwitch from "./StatusSwitch";
import UpdateMatrix from "./UpdateMatrix";

export const MATRIX_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "matrix_name",
        headerName: "matrix Name",
        width: 200,
    },
    {
        field: "course_name",
        headerName: "Course Name",
        width: 200,
    },
    {
        field: "matrix_description",
        headerName: "Description",
        width: 200,
    },
    {
        field: "matrix_created_at",
        headerName: "Matrix Created",
        width: 200,
    },
    {
        field: "Status",
        headerName: "Status",
        width: 120,
        renderCell: (params) => <StatusSwitch params={params.row} />,
    },
    {
        field: "Update",
        headerName: "Update",
        width: 120,
        renderCell: (params) => <UpdateMatrix params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 120,
        renderCell: (params) => (
            <DeleteMatrix
                id={params.row.matrix_id}
                childFlag={params.row.has_child_element}
            />
        ),
    },
];
