import DeleteFood from "./DeleteFood";
import VerifyAction from "./VerifyAction";
import VerifyStatus from "./VerifyStatus";

export const FoodTableColumn = [
    {
      field: "serialNumber",
      headerName: "slNo",
      width:80
    },
    {
      field: "name",
      headerName: "Name",
      width: 230,
    },
    {
      field: "designation",
      headerName: "Designation / Role",
      width: 250,
    },
    {
      field: "department",
      headerName: "Department",
      width: 250,
    },
    {
      field: "office_name",
      headerName: "Office",
      width: 150,
    },
    {
      field: "tbl_food_slot",
      headerName: "Slot",
      width: 200,
    },
    {
      field: "food_list_verification_status",
      headerName: "Verfication Status",
      width: 180,
      renderCell:(params)=><VerifyStatus status={params.row.food_list_verification_status}/>
    },
    {
      field: "Verify",
      headerName: "Verfy Action",
      width: 150,
      renderCell:(params)=><VerifyAction id={params.row.food_id} status={params.row.food_list_verification_status}  date={params.row.food_date} shift={params.row.food_shift_id}/>
    },
    {
      field: "Delete",
      headerName: "Delete",
      width: 150,
      renderCell:(params)=><DeleteFood date={params.row.food_date} shift={params.row.food_shift_id} id={params.row.food_id}/>
    },
  ];
  