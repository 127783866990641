import React, { useEffect } from "react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getOgaLeadsStudents } from "../../../../redux/features/ogdSlice";
import { QUALIFIED_LEADS_TABLE_HEAD } from "../../../../components/datagrid/myworkspace/ogd/QualifiedLeadsTableHead";
import GlobalDatagrid from "../../../../components/mui/DataGrid/GlobalDatagrid";
import { Box, Paper } from "@mui/material";

const CartLeads = () => {
  const dispatch = useDispatch();
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { ogaLeadStudents, loading } = useSelector((state) => state.ogd);
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData;

  const data = {
    page: pageNum,
    limit: gridSize,
    search: search,
    openAnchor,
  };
  useEffect(() => {
    const datas = {
      ...data,
    };
    if (pageNum && gridSize) {
      dispatch(getOgaLeadsStudents(datas));
    }
  }, [pageNum, gridSize, search]);
  return (
    <GlobalWrapper title="Cart Leads">
      <Paper elevation={3}>
        <Box sx={{ width: "100%", padding: 0 }}>
          <GlobalDatagrid
            tableHead={QUALIFIED_LEADS_TABLE_HEAD}
            rows={ogaLeadStudents?.data}
            meta={ogaLeadStudents?.meta?.totalRecords || 0}
            rowUniqueId="sl_no"
            // rowClick={true}
            loading={loading}
            // actionComponent={
            //   <>
            //     <ActionDetails val={rowData?.date} />
            //     <GlobalAnchorList
            //       label={"Delete"}
            //       component={
            //         <DeleteCompReq
            //           data={data}
            //           id={rowData}
            //           paginationData={paginationData}
            //         />
            //       }
            //     />
            //     <GlobalAnchorList
            //       label={"Update Request"}
            //       component={
            //         <AddCompWorkReq
            //           update={data}
            //           params={rowData}
            //           paginationData={paginationData}
            //         />
            //       }
            //     />
            //   </>
            // }
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  );
};

export default CartLeads;
