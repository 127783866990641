import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const GlobalSelectField = ({ options, disabled, variant }) => {
  const [feildLabel, setFeildLabel] = useState("");
  const [itemData, setItemData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [feildName, setFeildName] = useState("");
  const [err, setErr] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [itemId, setId] = useState("");
  const [itemValue, setValue] = useState("");
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (options) {
      if (options.label) {
        setFeildLabel(options.label);
      }
      if (options.data) {
        setItemData(options.data);
      }
      if (options.keys) {
        const { id, value, ...other } = options.keys;
        setId(id);
        setValue(value);
      }
      if (options.name) {
        setFeildName(options.name);
      }
      if (options.sx) {
        setStyle(options.sx);
      }
      if (options.formik && options.name) {
        setInputValue(options.formik.values[options.name]);
        setHelperText(options.formik.errors[options.name]);
        // setHelperText(options.formik.touched[options.name] && options.formik.errors[options.name]);
        setErr(
          options.formik.touched[options.name] &&
            Boolean(options.formik.errors[options.name])
        );
        // setErr(options.formik.touched[options.name] && Boolean(options.formik.errors[options.name]));
      }
    }
  }, [options]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (options.formik && options.name) {
      options.formik.setFieldValue(feildName, e.target.value);
      options.formik.handleChange(e);
    }
  };

  return (
    <FormControl
      sx={{
        ...style,
        // minWidth: 160,
        // flex: 1,
      }}
      size="medium"
      error={err}
      disabled={disabled}
    >
      <InputLabel id="demo-simple-select-label">{feildLabel}</InputLabel>
      <Select
        name={feildName}
        labelId="demo-simple-select-label"
        label={feildLabel}
        value={inputValue}
        onChange={handleChange}
        variant={variant}
      >
        {itemData?.map((item) => (
          <MenuItem key={item[itemId]} value={item[itemId]}>
            {item[itemValue]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="course-helper">{helperText}</FormHelperText>
    </FormControl>
  );
};

export default GlobalSelectField;
