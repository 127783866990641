import React from "react";
import { Mytable, Rows } from "../../../mui/DataGrid/Details";
import { Box, Grid } from "@mui/material";

const StudentDetailsView = ({ data }) => {
    return (
        <Box>
            <Grid container spacing={1}>
                <Mytable
                    tableRow={
                        <>
                            <Rows label="OGA" value={data?.oga} />
                            <Rows label="mobile" value={data?.contactNumber} />
                            <Rows label="Date Of Birth" value={data?.dob} />
                            <Rows label="Class" value={data?.className} />
                            <Rows label="Course" value={data?.courseName} />
                            <Rows
                                label="Last Session Date"
                                value={data?.lastSessionDate}
                            />
                        </>
                    }
                />

                <Mytable
                    tableRow={
                        <>
                            <Rows
                                label="Total Sales Amount"
                                value={data?.totalSalesAmount}
                            />
                            <Rows
                                label="Total Fee Collected"
                                value={data?.totalFeeCollected}
                            />
                            <Rows
                                label="Course Interactive Date"
                                value={data?.courseInactiveDate}
                            />
                            <Rows
                                label="Admission Fee"
                                value={data?.admnFeeStatus}
                            />

                            <Rows
                                label="Mapping Status"
                                value={data?.mappingStatus}
                            />
                        </>
                    }
                />
            </Grid>
        </Box>
    );
};

export default StudentDetailsView;
