import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Icon } from "@iconify/react";
import { Box, Stack, TextField, Typography } from "@mui/material";

export default function CollapseList({ label, content }) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <div>
            <List
                sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton
                    sx={{ backgroundColor: "grey.200", mt: 0 }}
                    onClick={handleClick}
                >
                    <ListItemIcon>
                        <Icon icon="bi:text-paragraph" width="22" height="22" />
                    </ListItemIcon>
                    <ListItemText primary={label} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText
                                primary={
                                    <Box>
                                        <Stack direction={"column"} spacing={2}>
                                            {content}
                                        </Stack>
                                    </Box>
                                }
                            />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </div>
    );
}
