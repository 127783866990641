import React, { useState } from 'react'
import GlobalDialog from '../../../mui/Dialogue/GlobalDialog'
import { CancelButton, UpdateButton } from '../../../mui/Buttons/Buttons'
import GlobalSelectFieldWithoutFormik from '../../../mui/GlobalSelectFieldWithoutFormik'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import {getAllRequests, updateRaiseRequest } from '../../../../redux/features/helpAndSupportSlice'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import { useDispatch, useSelector } from 'react-redux'

const statuses=[
  {id:1,value:"started"},
  {id:2,value:"resolved"},
]
const StatusChange = ({row}) => {
  const dispatch=useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const handleCancel = () => {
    setOpen(false)
    setResetGlobalSelectField(true);
  }
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const {loading } = useSelector((store) => store.helpAndSupport)
  const [resetGlobalSelectField, setResetGlobalSelectField] = useState(false);
  const [selectedStatus,setSelectedStatus]=useState({
    id:row ? statuses.find((sts)=>sts.value==row.status)?.id : "",
    value:row ? statuses.find((sts)=>sts.value==row.status)?.value : ""
  })

  const statusUpdate=()=>{
    const updateData={
      ticketId:row.id,
      status:selectedStatus.value
  }
    dispatch(updateRaiseRequest(updateData)).then((res) => {
      if (res.payload.status === 'error') {
        enqueueSnackbar(res.payload.message, {
          variant: 'error',
        })
      } else if (res.payload.status === 'success') {
        dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
        dispatch(getAllRequests(paginationData))
        enqueueSnackbar(res.payload.message, {
          variant: 'success',
        })
      }
    })
  }
  return (
    <GlobalDialog
      maxWidth={'sm'}
      fullWidth
      title={'Update Status'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={
        <>
          <UpdateButton
          disabled={!row?.assignedTo}
            variant={'outlined'}
            size="small"
            action={() => {
              setOpen(true)
            }}>
            Update
          </UpdateButton>
        </>
      }
      content={
        <>
          <GlobalSelectFieldWithoutFormik
            sx={{ width: '100%' }}
            keys={{ itemId: 'id', itemValue: 'value' }}
            label={'Status'}
            data={statuses}
            selectedDetails={selectedStatus}
            setChange={setSelectedStatus}
            reset={resetGlobalSelectField}
          />
          <Box sx={{display:"flex",justifyContent:"flex-end",mt:3}}>
          <Box sx={{display:"flex",gap:1}}>
          <UpdateButton
          disabled={loading}
            variant={'outlined'}
            size="small"
            action={() => {
              statusUpdate()
            }}>
            Update
          </UpdateButton>

          <CancelButton
          
            variant={'outlined'}
            size="small"
            action={() => {
              handleCancel()
            }}>
            Cancel
          </CancelButton>
          </Box>
          </Box>
        </>
      }
    />
  )
}

export default StatusChange
