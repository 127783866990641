import { configureStore } from "@reduxjs/toolkit";
import SnackbarSlice from "./features/snackbarSlice";
import studentsReducer from "./features/studentsSlice";
import modalReducer from "./features/modalSlice";
import coursesReducer from "./features/coursesSlice";
import modulesReducer from "./features/modulesSlice";
import loginReducer from "./features/authSlice";
import scheduleReducer from "./features/scheduleSlice";
import sessionReducer from "./features/sessionSlice";
import notificationReducer from "./features/notificationSlice";
import todoReducer, { employeReducer } from "./features/todoSlice";
import profileReducer, { preprofileReducer } from "./features/profileSlice";
import privilegesReducer from "./features/privilegesSlice";
import userReducer from "./features/userSlice";
import rolesReducer from "./features/rolesSlice";
import departmentsReducer from "./features/departmentSlice";
import roleSlice from "./features/roleSlice";
import referrelSlice from "./features/referrelSlice";
import attendanceReducer from "./features/attendanceSlice";
import leaveReducer from "./features/leaveSlice";
import analyticsReducer from "./features/analyticsSlice";
import jobApplicationReducer from "./features/jobApplicationSlice";
import MarkAttendanceReducer from "./features/AttendanceCheckSlice";
import crmReducer from "./features/crmSlice";
import CroReducer from "./features/croSlice";
import settingsReducer from "./features/settingsSlice";
import mastersReducer from "./features/mastersSlice";
import researchReducer from "./features/researchSlice";
import cabinBookingReducer from "./features/cabinBookingSlice";
import mentoringReducer from "./features/mentoringSlice";
import dashboardAnalyticsReducer from "./features/dashboardAnalyticsSlice";
import assessmentToolReducer from "./features/assessmentToolSlice";
import holidaysSlice from "./features/holidaysSlice";
import foodSlice from "./features/foodSlice";
import invoiceSlice from "./features/invoiceSlice";
import incomeSlice from "./features/incomeSlice";
import ogdReducer from "./features/ogdSlice";
import globalDataGridSlice from "./features/globalDatagridSlice";
import courseSlice from "./features/courseSlice";
import helpAndSupportReducer from "./features/helpAndSupportSlice";
import cartSlice from "./features/cartSlice";
import studentTrackSlice from "./features/studentTrackSlice";
import postWorkingHoursSlice from "./features/postWorkingHoursSlice";

export default configureStore({
  reducer: {
    login: loginReducer,
    students: studentsReducer,
    courses: coursesReducer,
    modules: modulesReducer,
    modal: modalReducer,
    schedule: scheduleReducer,
    sessions: sessionReducer,
    notification: notificationReducer,
    snackbar: SnackbarSlice,
    profile: profileReducer,
    preprofile: preprofileReducer,
    todo: todoReducer,
    employees: employeReducer,
    privileges: privilegesReducer,
    referrel: referrelSlice,
    role: roleSlice,
    users: userReducer,
    roles: rolesReducer,
    departments: departmentsReducer,
    attendance: attendanceReducer,
    leave: leaveReducer,
    analytics: analyticsReducer,
    jobApplication: jobApplicationReducer,
    attendanceMarked: MarkAttendanceReducer,
    crm: crmReducer,
    cro: CroReducer,
    settings: settingsReducer,
    masters: mastersReducer,
    research: researchReducer,
    cabin: cabinBookingReducer,
    mentoring: mentoringReducer,
    dashboardAnalytics: dashboardAnalyticsReducer,
    assessmentTool: assessmentToolReducer,
    holidays: holidaysSlice,
    food: foodSlice,
    invoice: invoiceSlice,
    income: incomeSlice,
    ogd: ogdReducer,
    globalDataGrid: globalDataGridSlice,
    course: courseSlice,
    helpAndSupport: helpAndSupportReducer,
    course: courseSlice,
    studentTrack: studentTrackSlice,
    postWorking: postWorkingHoursSlice,
    cart: cartSlice,
  },
});
