import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

export const getAllUsers = createAsyncThunk("users/getAllUsers", async () => {
    const res = await apiRequest({
        method: "get",
        url: "users/all",
    });
    return formatDataForDataTable(res.data.users);
    // return res;
});
export const getSingleUser = createAsyncThunk(
    "users/getSingleUser",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `users/adminedit/${id}`,
        });

        return res;
    }
);

export const getPreData = createAsyncThunk("users/getPreData", async () => {
    const res = await apiRequest({
        method: "get",
        url: `pre-data/roletagging`,
    });

    return res;
});

export const editUser = createAsyncThunk("users/editUser", async (data) => {
    const res = await apiRequest({
        method: "patch",
        url: `users/adminedit/${data.id}`,
        data: data,
    });
    return res;
});

export const getRolesOfUser = createAsyncThunk(
    "users/getRolesOfUser",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `users/roletagging/${id}`,
        });

        return res;
    }
);

export const addRole = createAsyncThunk("users/add-roles", async (data) => {
    const res = await apiRequest({
        method: "post",
        url: `users/roletagging/${data.id}`,
        data: data.data,
    });

    //
    return res;
});

export const deleteRolesOfUser = createAsyncThunk(
    "users/deleteRolesOfUser",
    async (data) => {
        const res = await apiRequest({
            method: "delete",
            url: `users/roletagging/${data.id}`,
            data: data.body,
        });
        //
        return res;
    }
);

export const addNewRole = createAsyncThunk("users/addNewRole", async (data) => {
    const res = await apiRequest({
        method: "post",
        url: `roles`,
        data: data,
    });
    //
    return res;
});

const userSlice = createSlice({
    name: "users",
    initialState: {
        roles: {},
        loading: false,
        singleUser: {},
        data: [],
        preData: {
            funnelDatas: [],
        },
        userType: [],
        error: "",
    },
    reducers: {
        clearSingleUser: (state, action) => {
            return {
                ...state,
                singleUser: {},
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getAllUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------getSingleUser-----------------------------------------------
        builder.addCase(getSingleUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSingleUser.fulfilled, (state, action) => {
            state.loading = false;
            state.singleUser = action.payload.data.user;
            //
        });
        builder.addCase(getSingleUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------------getPreData------------------------------
        builder.addCase(getPreData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getPreData.fulfilled, (state, action) => {
            state.loading = false;

            // state.preDataNew=action.payload.data.departments.unshift({departmentId:0,departmentName:"All"});
            state.preData = action.payload.data;
        });
        builder.addCase(getPreData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------------------------------------------
        builder.addCase(editUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUser.fulfilled, (state, action) => {
            state.loading = false;
            state.Data = action.payload.data;
        });
        builder.addCase(editUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------getRolesOfUser-----------------------------

        builder.addCase(getRolesOfUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getRolesOfUser.fulfilled, (state, action) => {
            state.loading = false;
            state.roles = action.payload.data;
        });
        builder.addCase(getRolesOfUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------addRole-----------------------------

        builder.addCase(addRole.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addRole.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // -----------------------------deleteRolesOfUser--------------------
        builder.addCase(deleteRolesOfUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteRolesOfUser.fulfilled, (state, action) => {
            //
            state.loading = false;
        });
        builder.addCase(deleteRolesOfUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------addNewRole---------------------------------
        builder.addCase(addNewRole.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addNewRole.fulfilled, (state, action) => {
            //
            state.loading = false;
        });
        builder.addCase(addNewRole.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});

// Action creators are generated for each case reducer function
// export const { fetchStudents } = studentsSlice.actions;
export const { clearSingleUser } = userSlice.actions;
export default userSlice.reducer;
