import React from "react";
import "../css/Loader3D.css"; // Import the stylesheet

const Loader3D = React.memo(() => {
  return (
    <div className="loader-container-main">
      <div className="loader-3d-animation">
        {/* Use your icon as the background image */}
        <div
          className="face front"
          style={{
            backgroundImage: `url(https://intervaledu.com/static/web/images/logo/logo-dark.png)`,
          }}
        ></div>
        <div
          className="face back"
          style={{
            backgroundImage: `url(https://intervaledu.com/static/web/images/logo/logo-dark.png)`,
          }}
        ></div>
        <div
          className="face left"
          style={{
            backgroundImage: `url(https://intervaledu.com/static/web/images/logo/logo-dark.png)`,
          }}
        ></div>
        <div
          className="face right"
          style={{
            backgroundImage: `url(https://intervaledu.com/static/web/images/logo/logo-dark.png)`,
          }}
        ></div>
        <div
          className="face top"
          style={{
            backgroundImage: `url(https://intervaledu.com/static/web/images/logo/logo-dark.png)`,
          }}
        ></div>
        <div
          className="face bottom"
          style={{
            backgroundImage: `url(https://intervaledu.com/static/web/images/logo/logo-dark.png)`,
          }}
        ></div>
      </div>
    </div>
  );
});

export default Loader3D;
