import { DeletePackageClass } from "./DeletePackageClass";
import { EditPackageClass } from "./EditPackageClass";
import { PackageClassesListing } from "./PackageClassesListing";
import { PackageClassesStatus } from "./PackageClassesStatus";

export const PACKAGE_CLASSES_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "className",
        headerName: "Class Name",
        width: 200,
    },
    {
        field: "packageName",
        headerName: "Package Name",
        width: 250,
    },

    {
        field: "isActive",
        headerName: "isActive",
        width: 150,
        renderCell: (params) => <PackageClassesStatus params={params.row} />,
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 150,
        renderCell: (params) => <PackageClassesListing params={params.row} />,
    },
    {
        field: "Edit",
        headerName: "Edit",
        width: 150,
        renderCell: (params) => <EditPackageClass params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => (
            <DeletePackageClass params={params.row.packageClassId} />
        ),
    },
];
