import React, { useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import {
  CancelButton,
  SaveButton,
  UpdateButton,
} from "../../../../components/mui/Buttons/Buttons";
import { Box, Stack, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  approveActions,
  getReportingHeadCompWorkReq,
} from "../../../../redux/features/leaveSlice";
import { useSnackbar } from "notistack";
import { savePaginationData } from "../../../../redux/features/globalDatagridSlice";

const ApproveAction = ({ update, params, paginationData }) => {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const { loading } = useSelector((state) => state.leave);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      id: params.id,
      status: status,
      remarks: remarks,
    };
    dispatch(approveActions(data)).then((res) => {
      if (res.payload.status === "failed" || res.payload.status === "error") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        dispatch(getReportingHeadCompWorkReq(update));
        dispatch(savePaginationData({ ...paginationData, openAnchor: false }));
        setOpen(false);
        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
      }
    });
  };
  return (
    <GlobalDialog
      title="Update Status"
      open={open}
      setOpen={setOpen}
      fullWidth
      closeButton={true}
      maxWidth={"sm"}
      popupButton={
        <>
          {params.status === 0 ? (
            <UpdateButton size="small" action={() => setOpen(true)} />
          ) : null}
        </>
      }
      content={
        <>
          <Box>
            <form>
              <Box sx={{ minWidth: "100%" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    required
                    value={status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Approve</MenuItem>
                    <MenuItem value={2}>Reject</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box pt={2}>
                <TextField
                  label="Remarks"
                  fullWidth
                  required
                  multiline
                  rows={3}
                  value={remarks}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
                <Stack direction={"row"} spacing={1}>
                  <CancelButton size="small" action={() => setOpen(false)}>
                    Cancel
                  </CancelButton>
                  <SaveButton
                    disabled={remarks && status ? false : true}
                    type="submit"
                    action={handleSubmit}
                  >
                    {loading ? "Saving" : "Save"}
                  </SaveButton>
                </Stack>
              </Box>
            </form>
          </Box>
        </>
      }
    />
  );
};

export default ApproveAction;
