import React from 'react'
import GlobalDeleteDialog from '../../mui/Dialogue/GlobalDeleteDialog'
import { savePaginationData } from '../../../redux/features/globalDatagridSlice'
import { deleteCourse, getAllCourses } from '../../../redux/features/courseSlice'
import { useSelector } from 'react-redux'

const DeleteCourse = ({row}) => {
    const {loading}=useSelector((store)=>store.course)
    const { paginationData } = useSelector((state) => state.globalDataGrid)
  return (
    <GlobalDeleteDialog
    // disabled={true}
    title={"Delete"}
    deleteNote={"Are you sure? you want to delete this Course..?"}
    api={deleteCourse(row.id)}
    upDateCall={getAllCourses(paginationData)}
    updatePaginationCall={savePaginationData({ ...paginationData, openAnchor: false })}
    loading={loading}
    />
  )
}

export default DeleteCourse