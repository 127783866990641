import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import Box from "@mui/material/Box";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import {
  Button,
  Card,
  CardMedia,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CancelButton } from "../../../../components/mui/Buttons/Buttons";
import { Icon } from "@iconify/react";
import MotionContainer from "../../../../components/FramerMotion/MotionContainer";
import { compressImage } from "../../../../utils/constants/ImageCompress";
import Loader3D from "../../../../components/Loader/Loader3D";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";

const PaymentMethodsPopup = ({
  open,
  setOpen,
  isAvailabletotalPayingAmount,
}) => {
  const [value, setValue] = React.useState(dayjs());
  const [compressesImg, setCompressesImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const Schema = Yup.object().shape({
    paymentMethod: Yup.string().required("paymentMethod is required"),
    currency: Yup.string().required("currency is required"),
    transactionDate: Yup.string().required("transactionDate is required"),
  });
  const SchemaPaymentMethod = Yup.object().shape({
    paymentMethod: Yup.string().required("paymentMethod is required"),
  });
  const formik = useFormik({
    initialValues: {
      paymentMethod: "",
      currency: "",
      transactionDate: "",
      remarks: "",
    },
    validationSchema: (values) =>
      values?.paymentMethod == 0 ? SchemaPaymentMethod : Schema,
    onSubmit: (values) => {
      if (!compressesImg && values?.paymentMethod != 0) {
        enqueueSnackbar("Please upload payment screenshot..!", {
          variant: "error",
        });
      }
    },
  });

  useEffect(() => {
    if (compressesImg) {
      setLoading(false);
    }
  }, [compressesImg]);
  const paymentMethods = [
    { id: "0", name: "Generate Link" },
    { id: "1", name: "Cash In hand" },
    { id: "3", name: "Bank Transfer" },
    { id: "4", name: "Stripe" },
  ];

  const handleImageChange = (e) => {
    setImage(null);
    setCompressesImg(null);
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  useEffect(() => {
    if (image) {
      compressImage(image, 0.5).then((compressedFile) => {
        const compressedUrl = URL.createObjectURL(compressedFile);
        setCompressesImg(compressedUrl);
      });
    }
  }, [image]);

  useEffect(() => {
    if (value) {
      formik.setFieldValue("transactionDate", dayjs(value).format());
    }
  }, [value]);

  useEffect(() => {
    if (!open) {
      setImage(null);
      formik.setFieldValue("paymentMethod", "");
      setCompressesImg("");
    }
  }, [open]);
  return (
    <GlobalDialog
      title="Select Payment Method"
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              {open ? (
                <GlobalWrapper>
                  <Box>
                    <MotionContainer delay={0.8}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          pb: 2,
                        }}
                      >
                        <Chip
                          size="small"
                          color="primary"
                          label={
                            <Box sx={{ display: "flex" }}>
                              <Typography sx={{ fontWeight: "bolder" }}>
                                Paying amount :-
                              </Typography>
                              <Box pt={0.1}>
                                <Typography sx={{ fontWeight: "bolder" }}>
                                  {isAvailabletotalPayingAmount}
                                </Typography>
                              </Box>
                            </Box>
                          }
                          variant="outlined"
                        />
                      </Box>
                    </MotionContainer>

                    <MotionContainer delay={0.5}>
                      <Box>
                        <GlobalSelectField
                          options={{
                            name: "paymentMethod",
                            formik,
                            sx: { width: "100%" },
                            label: "Payment Methods",
                            data: paymentMethods,
                            keys: {
                              id: "id",
                              value: "name",
                            },
                          }}
                        />
                      </Box>
                    </MotionContainer>
                    {formik.values.paymentMethod == 0 ? null : (
                      <>
                        <Stack direction={"column"} spacing={2}>
                          <MotionContainer delay={0.5}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                pt: 3,
                              }}
                            >
                              <Card
                                sx={{
                                  textAlign: "center",
                                  padding: 2,
                                  width: "100%",
                                }}
                              >
                                <Typography variant="h6" gutterBottom>
                                  Upload Image
                                </Typography>
                                {loading ? (
                                  <Loader3D />
                                ) : (
                                  <>
                                    <input
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      id="upload-image"
                                      type="file"
                                      onChange={handleImageChange}
                                    />
                                    <label htmlFor="upload-image">
                                      <Button
                                        variant="contained"
                                        component="span"
                                      >
                                        Choose Image
                                      </Button>
                                    </label>
                                    {compressesImg && (
                                      <CardMedia
                                        component="img"
                                        image={compressesImg}
                                        alt="Uploaded image preview"
                                        sx={{
                                          marginTop: 2,
                                          width: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Card>
                            </Box>
                          </MotionContainer>
                          <MotionContainer delay={0.7}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                sx={{ width: "100%" }}
                                label="Transaction Date"
                                value={value}
                                onChange={(newValue) => setValue(newValue)}
                              />
                            </LocalizationProvider>
                          </MotionContainer>
                          <MotionContainer delay={0.7}>
                            <GlobalSelectField
                              options={{
                                name: "currency",
                                formik,
                                sx: { width: "100%" },
                                label: "Currency",
                                data: [
                                  { id: "INR", name: "INR" },
                                  { id: "AED", name: "AED" },
                                ],
                                keys: {
                                  id: "id",
                                  value: "name",
                                },
                              }}
                            />
                          </MotionContainer>
                          <MotionContainer delay={1}>
                            <TextField
                              name="remarks"
                              label="Remarks"
                              onChange={formik.handleChange}
                              value={formik.values.remarks}
                              fullWidth
                              multiline
                              rows={3}
                            />
                          </MotionContainer>
                        </Stack>
                      </>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: 2,
                      }}
                    >
                      <Stack direction={"row"} spacing={1}>
                        <CancelButton
                          size="small"
                          action={() => setOpen(false)}
                        >
                          Cancel
                        </CancelButton>
                        {formik.values.paymentMethod == 0 ? (
                          <Button
                            type="submit"
                            sx={{
                              color: "white",
                              background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                              borderRadius: 3,
                            }}
                            disabled={loading ? true : false}
                          >
                            <Icon
                              icon="mdi:cart-outline"
                              height={22}
                              width={22}
                            />
                            {loading ? "Please Wait..!" : "Generate Link"}
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            sx={{
                              color: "white",
                              background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                              borderRadius: 3,
                            }}
                            disabled={loading ? true : false}
                          >
                            <Icon
                              icon="mdi:cart-outline"
                              height={22}
                              width={22}
                            />
                            {loading ? "Please Wait..!" : "Proceed Payment"}
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </GlobalWrapper>
              ) : null}
            </Form>
          </FormikProvider>
        </>
      }
    />
  );
};

export default PaymentMethodsPopup;
