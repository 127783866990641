import { Box } from '@mui/material'
import React from 'react'
import { Icon } from "@iconify/react";

const VerifyStatus = ({status}) => {
  return (
    <Box display="flex" alignItems="center">
    {status == 0? (
      <>
       <Icon icon="fe:close"  style={{color:" #c03530"}} width="20" height="20" />
        <span style={{ marginLeft: "8px" }}>Not Verified</span>
      </>
    ) : (
      <>
        <Icon icon="mdi:approve" style={{color:" #0db05d"}} width="20" height="20" />
        <span style={{ marginLeft: "8px" }}>Verified</span>
      </>
    )}
  </Box>
  )
}

export default VerifyStatus