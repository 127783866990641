import React from "react";
import GlobalDialog from "../../../components/mui/Dialogue/GlobalDialog";
import { useState } from "react";
import {
    AddButton,
    CancelButton,
    EditButton,
    SaveButton,
} from "../../../components/mui/Buttons/Buttons";
import { Box, Stack, TextField } from "@mui/material";
import { FormikProvider, Form, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
    createCabin,
    updateCabin,
    getAllCabins,
    getAllCabinsForAdministration,
} from "../../../redux/features/cabinBookingSlice";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

export const AddCabin = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { loading } = useSelector((state) => state.cabin);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const Schema = Yup.object().shape({
        asset_name: Yup.string().required("asset_name is required"),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: params?.asset_id,
            asset_name: params?.asset_name || "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            if (params) {
                dispatch(updateCabin(values)).then((res) => {
                    if (res.payload.status === "error") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    } else if (res.payload.status === "success") {
                        setOpen(false);
                        dispatch(getAllCabinsForAdministration());
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                    }
                });
            } else {
                dispatch(createCabin(values)).then((res) => {
                    if (res.payload.status === "error") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    } else if (res.payload.status === "success") {
                        setOpen(false);
                        dispatch(getAllCabinsForAdministration());
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                    }
                });
            }
        },
    });

    return (
        <div>
            <GlobalDialog
                title="Create Cabin"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <>
                        {params ? (
                            <EditButton
                                size="small"
                                action={() => setOpen(true)}
                            >
                                Edit
                            </EditButton>
                        ) : (
                            <AddButton action={() => setOpen(true)}>
                                Add New Cabin
                            </AddButton>
                        )}
                    </>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <TextField
                                        name="asset_name"
                                        value={formik.values.asset_name}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        label="Cabin Name"
                                        error={
                                            formik.touched.asset_name &&
                                            Boolean(formik.errors.asset_name)
                                        }
                                        helperText={formik.errors.asset_name}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Stack
                                        direction={"row"}
                                        spacing={2}
                                        paddingTop={2}
                                    >
                                        <CancelButton
                                            action={() => setOpen(false)}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <SaveButton type="submit">
                                            {loading ? "Saving..." : "Save"}
                                        </SaveButton>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
