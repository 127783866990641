import { DeleteProjectPopup } from "./DeleteProjectPopup";
import { EditProjectPopup } from "./EditProjectPopup";
import { ProjectListingStatus } from "./ProjectListingStatus";
import ProjectStatus from "./ProjectStatus";

export const PROJECTS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "projectName",
        headerName: "Project Name",
        width: 200,
    },
    {
        field: "projectHead",
        headerName: "Project Head",
        width: 250,
    },
    {
        field: "isActive",
        headerName: "isActive",
        width: 150,
        renderCell: (params) => <ProjectStatus params={params.row} />,
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 150,
        renderCell: (params) => <ProjectListingStatus params={params.row} />,
    },
    {
        field: "Edit",
        headerName: "Edit",
        width: 150,
        renderCell: (params) => <EditProjectPopup params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => (
            <DeleteProjectPopup params={params.row.projectId} />
        ),
    },
];
