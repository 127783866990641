import React from "react";
import UpdateAttendanceDialogue from "./UpdateAttendanceDialogue";

export default function EditEmployeeAttendance(params) {
  return (
    <div>
      <UpdateAttendanceDialogue params={params} />
    </div>
  );
}
