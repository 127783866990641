import React from "react";
import { Box, Paper } from "@mui/material";
import AttendenceObjectiveComponent from "./AttendenceObjectiveComponent";

const AttendanceDetails = ({ data }) => {//THIS DATA WILL CONTAIN OBJECTIVES AND ALSO ATTENDENCE DATA ARRAY
  
  const objectIntoArray = Object.entries(data); //MAKING IT AS AN ITERABLE ARRAY FROM THIS OBJECT
  const removedAttendenceArray = objectIntoArray.filter(
    ([key, value]) => key !== "attendance"
  );
  const iterableArray = removedAttendenceArray.map(([key, value]) => ({ //NO NEED OF ATTENDANCE DATA AS KEY VALUE PAIRS SO REMOVED IT
    objectiveName: key,
    value: value,
  }));

  return (
    <Paper elevation={3}>
      <Box sx={{ padding: 2 }}>

         {/* ANOTHER METHOD TO AVOID REPEATED CALLING OF COMPONENT*/}
        {iterableArray.map((objective) => {   
          let color = "";
          let name=""
          if (
            objective.objectiveName === "totalA" ||
            objective.objectiveName === "totalLWP"
          ) {
            color = "#ffe4de";
            if( objective.objectiveName === "totalA"){
              name="Total Absent"
            }else if( objective.objectiveName === "totalLWP"){
              name="Total LOP"
            }
          } else if (objective.objectiveName === "totalP") {
            color = "#c8facd";
            name="Total Present"
          } else if (
            objective.objectiveName === "totalHD" ||
            objective.objectiveName === "totalWO" ||
            objective.objectiveName === "totalCO"
          ) {
            color = "#bbd1e4cf";
            if(objective.objectiveName === "totalHD"){
              name="Total Holiday"
            }else if( objective.objectiveName === "totalWO"){
              name="Total Week Off"
            }else if( objective.objectiveName === "totalCO"){
              name="Total complimentary"
            }
          } else if (
            objective.objectiveName === "totalCL" ||
            objective.objectiveName === "totalSL" ||
            objective.objectiveName === "totalML" ||
            objective.objectiveName === "totalPL"
          ) {
            color = "#dccfe0";
            if( objective.objectiveName === "totalCL"){
              name="Total Casual Leave"
            }else if(objective.objectiveName === "totalSL"){
               name="Total Sick Leave"
            }else if( objective.objectiveName === "totalML"){
               name="Total Medical Leave"
            }else if( objective.objectiveName === "totalPL"){
              name="Total Paid Leave"
            }
          } else if(objective.objectiveName === "payableDays"){
             color=""
             name="Payable Days"
          }else {
            color = "";
            name =objective.objectiveName
          }
          return (
            <AttendenceObjectiveComponent
              key={objective.objectiveName}
              backgroundColor={color}
              objectiveName={name}
              values={objective.value}
            />
          );
        })}

        {/* <AttendenceObjectiveComponent backgroundColor={"#c8facd"} objectiveName={"Total Present"} value={data.totalP}/>
        <AttendenceObjectiveComponent backgroundColor={"#ffe4de"} objectiveName={"Total Absent"} value={data.totalA}/>
        <AttendenceObjectiveComponent backgroundColor={"#ffe4de"} objectiveName={"Total Loss Of Pay"} value={data.totalLWP}/>
        <AttendenceObjectiveComponent backgroundColor={"#bbd1e4cf"} objectiveName={"Total Holiday"} value={data.totalHD}/>
        <AttendenceObjectiveComponent backgroundColor={"#bbd1e4cf"} objectiveName={"Total WeekOff"} value={data.totalWO}/>
        <AttendenceObjectiveComponent backgroundColor={"#bbd1e4cf"} objectiveName={"Total Compensatory"} value={data.totalCO}/>
        <AttendenceObjectiveComponent backgroundColor={"#dccfe0"} objectiveName={"Total Casual Leave"} value={data.totalCL}/>
        <AttendenceObjectiveComponent backgroundColor={"#dccfe0"} objectiveName={"Total Sick Leave"} value={data.totalSL}/>
        <AttendenceObjectiveComponent backgroundColor={"#dccfe0"} objectiveName={"Total Medical Leave"} value={data.totalML}/>
        <AttendenceObjectiveComponent backgroundColor={"#dccfe0"} objectiveName={"Total Paid Leave"} value={data.totalPL}/>
        <AttendenceObjectiveComponent backgroundColor={""} objectiveName={"Payable Days"} value={data.payableDays}/> */}
      </Box>
    </Paper>
  );
};

export default AttendanceDetails;
