import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearStudent,
    getClasses,
    getCountries,
    getMediums,
    getStudent,
    getStudentProfile,
    getSyllabus,
    updateStudent,
} from "../../../../redux/features/croSlice";
import BasicTextField from "../../../mui/TextField";
import { Form, FormikProvider, useFormik } from "formik";
import { Global } from "@emotion/react";
import GlobalSelectField from "../../../mui/GlobalSelectField";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const CroStudentUpdateForm = ({ setOpen, row }) => {
    const dispatch = useDispatch();
    const { loading, countries, student, classes, syllabuses, mediums } =
        useSelector((state) => state.cro);

    const { enqueueSnackbar } = useSnackbar();
    const studentFormSchema = Yup.object().shape({
        name: Yup.string().required("This field is required"),
        email: Yup.string().required("This field is required"),
        countryCode: Yup.string().required("This field is required"),
        mobileNumber: Yup.string()
            .matches(/^[0-9]{10}$/, "Invalid mobile number")
            .required("This feild is required"),
        classId: Yup.string().required("This field is required"),
        syllabusId: Yup.string().required("This field is required"),
        mediumId: Yup.string().required("This field is required"),
        country: Yup.string().required("This field is required"),
        cityTown: Yup.string().required("This field is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: student.name || "",
            email: student.email || "",
            countryCode: student.countryCode || "",
            mobileNumber: student.mobileNumber || "",
            classId: student.classId || "",
            syllabusId: student.syllabusId || "",
            mediumId: student.mediumId || "",
            country: student.country || "",
            cityTown: student.cityTown || "",
        },
        validationSchema: studentFormSchema,
        onSubmit: (values) => {
            console.log(values, "onsubmitt=-=-=-=-");
            dispatch(updateStudent({ data: values, admNo: row.admnNo })).then(
                (res) => {
                    if (res.payload.status === "success") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                        setOpen(false);
                    } else {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    }
                }
            );
        },
    });

    useEffect(() => {
        if (!countries.length > 0) {
            dispatch(getCountries());
        }
        if (!classes.length > 0) {
            dispatch(getClasses());
        }
        if (!syllabuses.length > 0) {
            dispatch(getSyllabus());
        }
        if (!mediums.length > 0) {
            dispatch(getMediums());
        }
        // dispatch(getStudentProfile(row.courseEnrollmentId));
        dispatch(getStudent(row.admnNo));

        return () => dispatch(clearStudent());
    }, []);

    return (
        <Box>
            <Typography
                variant="h4"
                sx={{
                    marginBottom: "15px",
                }}
            >
                Update Student Data
            </Typography>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Stack direction="column" width={800} gap={2}>
                        <BasicTextField
                            name="name"
                            label="Student Name"
                            value={formik.values.name}
                            action={formik.handleChange}
                            error={Boolean(
                                formik.errors.name && formik.touched.name
                            )}
                            helperText={formik.errors.name}
                        />
                        <BasicTextField
                            type="email"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            action={formik.handleChange}
                            error={Boolean(
                                formik.errors.email && formik.touched.email
                            )}
                            helperText={formik.errors.email}
                        />
                        <GlobalSelectField
                            options={{
                                name: "countryCode",
                                label: "Student Country Code",
                                formik: formik,
                                data: countries,
                                keys: {
                                    value: "displayName",
                                    id: "phonecode",
                                },
                            }}
                        />
                        <BasicTextField
                            type="number"
                            name="mobileNumber"
                            label="Student Mob No."
                            value={formik.values.mobileNumber}
                            action={formik.handleChange}
                            error={Boolean(
                                formik.errors.mobileNumber &&
                                    formik.touched.mobileNumber
                            )}
                            helperText={formik.errors.mobileNumber}
                        />
                        <GlobalSelectField
                            options={{
                                name: "classId",
                                label: "Class",
                                formik: formik,
                                data: classes,
                                keys: {
                                    value: "className",
                                    id: "classId",
                                },
                            }}
                        />
                        <GlobalSelectField
                            options={{
                                label: "Sylabus",
                                name: "syllabusId",
                                formik: formik,
                                data: syllabuses,
                                keys: {
                                    id: "id",
                                    value: "name",
                                },
                            }}
                        />
                        <GlobalSelectField
                            options={{
                                label: "Medium",
                                name: "mediumId",
                                formik: formik,
                                data: mediums,
                                keys: {
                                    id: "id",
                                    value: "name",
                                },
                            }}
                        />
                        <GlobalSelectField
                            options={{
                                name: "country",
                                formik: formik,
                                label: "Country",
                                data: countries,
                                keys: {
                                    id: "countryName",
                                    value: "countryName",
                                },
                            }}
                        />
                        <BasicTextField
                            type="text"
                            name="cityTown"
                            label="City / Town"
                            value={formik.values.cityTown}
                            action={formik.handleChange}
                            error={Boolean(
                                formik.errors.cityTown &&
                                    formik.touched.cityTown
                            )}
                            helperText={formik.errors.cityTown}
                        />

                        <Button
                            sx={{
                                width: "300px",
                                margin: "0 auto",
                            }}
                            type="submit"
                            variant="contained"
                            disabled={loading}
                        >
                            Update
                        </Button>
                    </Stack>
                </Form>
            </FormikProvider>
        </Box>
    );
};

export default CroStudentUpdateForm;
