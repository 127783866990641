import React from "react";
import BasicTextField from "../../../mui/TextField";
import { Box, Stack } from "@mui/material";
import { CancelButton, SubmitButton } from "../../../mui/Buttons/Buttons";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
    addExpectedSessions,
    getExpectedSessions,
} from "../../../../redux/features/crmSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import useResponsive from "../../../../Hooks/useResponsive";

export const UpdateForm = ({ setOpen, params }) => {
    const data = useSelector((state) => state.crm);

    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");

    const datas = {
        year: data.callData.date?.split("/")[0],
        month: data.callData.date?.split("/")[1],
        croId: data.callData?.croId,
    };

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const CreateSchema = Yup.object().shape({
        expectedSessions: Yup.string().required("Expected Sessions Required"),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseEnrollmentId: params.courseEnrollmentId,
            year: datas.year,
            month: datas.month,
            expectedSessions: params.expectedSessions
                ? params.expectedSessions
                : "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values) => {
            dispatch(addExpectedSessions(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(getExpectedSessions(data.callData));
                }
            });
        },
    });

    return (
        <div>
            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Box sx={{ width: smUp ? 400 : "100%", padding: 2 }}>
                        <BasicTextField
                            name="expectedSessions"
                            type="number"
                            value={formik.values.expectedSessions}
                            label="Expected Sessions"
                            action={formik.handleChange}
                            fullWidth
                            error={
                                formik.touched.expectedSessions &&
                                Boolean(formik.errors.expectedSessions)
                            }
                            helperText={formik.errors.expectedSessions}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 2,
                        }}
                    >
                        <Stack direction={"row"} spacing={2}>
                            <CancelButton action={() => setOpen(false)}>
                                Cancel
                            </CancelButton>
                            <SubmitButton size="small" type="submit">
                                {data.loading ? "Submitting..." : "Submit"}
                            </SubmitButton>
                        </Stack>
                    </Box>
                </Form>
            </FormikProvider>
        </div>
    );
};
