import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMiscMaster } from "../../../redux/features/mastersSlice";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { MISC_MASTER_TABLE_HEAD } from "../../../components/Masters/MiscMasterTableHead";
import { AddButton } from "../../../components/mui/Buttons/Buttons";
import { AddProjectPopup } from "../../../components/Masters/AddProjectPopup";
import { AddMiscMaster } from "../../../components/Masters/AddMiscMaster";

export default function MiscMaster() {
    const { miscMaster, loading } = useSelector((state) => state.masters);
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);

    useEffect(() => {
        dispatch(getMiscMaster());
    }, []);
    return (
        <div>
            {" "}
            <Wrapper
                title="Miscellaneous Master"
                actions={<AddMiscMaster />}
                Content={
                    <>
                        <Paper elevation={3}>
                            <Box sx={{ width: "100%", padding: 0 }}>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    loading={loading}
                                    autoHeight
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    getRowHeight={() => "auto"}
                                    rows={miscMaster}
                                    columns={MISC_MASTER_TABLE_HEAD}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
