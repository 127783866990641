import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { FormikProvider, Form, useFormik } from "formik";
import { Divider, Paper, TextField } from "@mui/material";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";
import useResponsive from "../../../../Hooks/useResponsive";
import {
  getCvStatusPreData,
  getSingleJobApplication,
} from "../../../../redux/features/jobApplicationSlice";
import { updateTestStatus } from "../../../../redux/features/jobApplicationSlice";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { timeLine } from "../../../../redux/features/jobApplicationSlice";

import { SaveButton } from "../../../../components/mui/Buttons/Buttons";
import {
  CancelButton,
  SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";
import * as Yup from "yup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TestFormUpdate = ({ testForm, setTestForm, params, singleData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const preData = useSelector((state) => state.jobApplication);
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const handleCloseForm = () => {
    setTestForm(false);
  };
  const InterviewSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    remarks: Yup.string().required("Remark is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: singleData.testStatusId ? singleData.testStatusId || "" : "",
      remarks: singleData.testRemarks ? singleData.testRemarks || "" : "",
    },
    validationSchema: InterviewSchema,
    onSubmit: (values) => {
      const data = {
        id: params.id,
        status: values.status,
        remarks: values.remarks,
      };
      dispatch(updateTestStatus(data)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });

          dispatch(getSingleJobApplication(params.id));
          setTestForm(false);
        }
      });
    },
  });

  return (
    <div>
      <Dialog
        open={testForm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseForm}
        aria-describedby="alert-dialog-slide-description"
      >
        <Paper sx={{ backgroundColor: "grey.200", display: "flex" }}>
          <DialogTitle>{"Upadate Status.."}</DialogTitle>
        </Paper>
        <Divider />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box sx={{ width: !smUp ? "100%" : 500, padding: 3 }}>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="filled"
                      name="status"
                      label="Status"
                      value={formik.values.status}
                      onChange={(e) => {
                        formik.setFieldValue("status", e.target.value);
                      }}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                    >
                      {preData.preData.data?.map((item) => {
                        return (
                          <MenuItem value={item.id} key={item.id}>
                            {item.status}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{formik.errors.status}</FormHelperText>
                  </FormControl>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <TextField
                    variant="filled"
                    fullWidth
                    label="Remark"
                    rows={3}
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    multiline
                    placeholder="Enter your remark..."
                    error={
                      formik.touched.remarks && Boolean(formik.errors.remarks)
                    }
                    helperText={formik.errors.remarks}
                  />
                </Box>
              </Box>

              <Divider sx={{ borderBottomWidth: 3 }} />
            </DialogContent>

            <DialogActions>
              <CancelButton action={handleCloseForm}>Cancel</CancelButton>
              <SaveButton type="submit">
                {preData.loading ? "Saving..." : "Save"}
              </SaveButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
};

export { TestFormUpdate };
