import React from "react";
import { projectClassStatus } from "../../redux/features/mastersSlice";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
export const ProjectClassesStatus = ({ params }) => {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.isActive}
                api={projectClassStatus(params.classId)}
            />
        </div>
    );
};
