import React from "react";
import GlobalDeleteDialog from "../../../mui/Dialogue/GlobalDeleteDialog";
import {
    deleteTrainerAttendance,
    getTrainerAttendance,
} from "../../../../redux/features/croSlice";
import { useSelector } from "react-redux";

export const DeleteTrainerAttendance = ({ params }) => {
    const { loading } = useSelector((state) => state.cro);
    console.log("params", params);
    return (
        <div>
            <GlobalDeleteDialog
                loading={loading}
                title="Delete Trainer Attendance"
                deleteNote="Are you sure .You want delete this Attendance..?"
                api={deleteTrainerAttendance(params.id)}
                upDateCall={getTrainerAttendance()}
            />
        </div>
    );
};
