import React from "react";
import CvStatus from "./CvStatus";

export default function Status(params) {
  return (
    <div>
      <CvStatus params={params} />
    </div>
  );
}
