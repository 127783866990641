export const COURSE_SOURCE_ANALYTICS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "courseName",
        headerName: "Course Name",
        width: 150,
    },
    {
        field: "totalLeads",
        headerName: "Total Leads",
        width: 150,
    },
    {
        field: "admissionConfirmed",
        headerName: "Admission Confirmed",
        width: 150,
    },
    {
        field: "conversionPercentage",
        headerName: "conversion %",
        width: 150,
    },
    {
        field: "admissionCancelled",
        headerName: "Admission Cancelled",
        width: 150,
    },
    {
        field: "pendingAdmissions",
        headerName: "Pending Admissions",
        width: 150,
    },
    {
        field: "assessmentPending",
        headerName: "Assessment Pending",
        width: 150,
    },
    {
        field: "notConnected",
        headerName: "Not Connected",
        width: 150,
    },
    {
        field: "notInterested",
        headerName: "Not Interested",
        width: 150,
    },
    {
        field: "alreadyAssessed",
        headerName: "Already Assessed",
        width: 150,
    },
    {
        field: "notAttended",
        headerName: "Not Attended",
        width: 150,
    },
    {
        field: "admissionConfirmedBasedOnConfirmeddate",
        headerName: "Admission Confirmed (Based On Confirmed date)",
        width: 150,
    },
];
