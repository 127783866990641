import React, { useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { Icon } from "@iconify/react";

const SlideToPay = ({ onConfirm }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const hasConfirmed = useRef(false); // Track if the confirmation has already occurred
  const x = useMotionValue(0);
  const background = useTransform(x, [0, 260], ["#E0E0E0", "#4CAF50"]);
  const endPosition = 260; // Maximum drag distance

  const handleDragEnd = (event, info) => {
    // Trigger confirmation only if dragged to the end and hasn't confirmed before
    if (info.offset.x >= endPosition && !hasConfirmed.current) {
      hasConfirmed.current = true;
      setIsConfirmed(true);
      onConfirm();

      // Reset slider after a delay
      setTimeout(() => {
        animate(x, 0, { duration: 0.5 });
        setIsConfirmed(false);
        hasConfirmed.current = false; // Reset to allow future use
      }, 1000);
    } else {
      // Reset position if not fully dragged
      animate(x, 0, { duration: 0.2 });
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: 300,
        height: 40,
        background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
        borderRadius: 30,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        userSelect: "none",
      }}
    >
      <Typography variant="body1" color="white" sx={{ zIndex: 1 }}>
        {isConfirmed ? "Select method" : "Slide to Payment"}
      </Typography>
      <motion.div
        drag="x"
        dragConstraints={{ left: 0, right: endPosition }}
        dragElastic={0.0}
        dragMomentum={false}
        style={{
          x,
          position: "absolute",
          left: 3,
          top: 3,
          bottom: 0,
          width: 35,
          height: 35,
          borderRadius: "50%",
          backgroundColor: "#ffffff",
          boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",

          zIndex: 2,
        }}
        onDragEnd={handleDragEnd}
        whileTap={{ scale: 0.95 }}
      >
        <Typography sx={{ paddingTop: 1 }} variant="body2" color="text.primary">
          <Icon
            icon="line-md:chevron-small-triple-right"
            height={23}
            width={23}
          />
        </Typography>
      </motion.div>
    </Box>
  );
};

export default SlideToPay;
