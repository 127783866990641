import * as React from "react";
import { Box } from "@mui/material";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Typography } from "@mui/material";

export default function ToolTip({ content, expand }) {
  return (
    <div>
      <Tooltip
        title={expand ? <Typography variant="body2">{expand}</Typography> : ""}
        followCursor
      >
        <Box>
          <Typography variant="body2">{content ? content : ""}</Typography>
        </Box>
      </Tooltip>
    </div>
  );
}
