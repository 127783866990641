import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import BasicTextField from "../../../mui/TextField";

const QaReportForm = () => {
    return (
        <div>
            <Box
                sx={{
                    minWidth: "600px",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: "20px",
                        pb: "20px",
                        mb: "10px",
                        borderBottom: "1px solid #000",
                    }}
                >
                    QA Report
                </Typography>
                <Stack
                    gap={2}
                    sx={{
                        borderBottom: "1px solid #000",
                        pb: "15px",
                        mb: "20px",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: "20px",
                            textAlign: "center",
                        }}
                    >
                        STUDENT REPORT
                    </Typography>

                    <BasicTextField
                        label="Student Remarks & Academic Checklist"
                        disabled
                        multiline
                        rows={3}
                    />

                    <BasicTextField
                        label="Problem Identified"
                        disabled
                        multiline
                        rows={3}
                    />

                    <BasicTextField
                        label="Solution Suggested"
                        disabled
                        multiline
                        rows={3}
                    />

                    <BasicTextField
                        label="Solution Applied"
                        disabled
                        multiline
                        rows={3}
                    />
                </Stack>

                <Stack
                    gap={2}
                    sx={{
                        borderBottom: "1px solid #000",
                        pb: "15px",
                        mb: "20px",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontSize: "20px",
                            textAlign: "center",
                        }}
                    >
                        TRAINER REPORT
                    </Typography>

                    <BasicTextField
                        label="Trainer Remarks"
                        multiline
                        rows={3}
                        disabled
                    />

                    <BasicTextField
                        label="Problem Identified"
                        multiline
                        rows={3}
                        disabled
                    />

                    <BasicTextField
                        label="Solution Suggested"
                        multiline
                        rows={3}
                        disabled
                    />

                    <BasicTextField
                        label="Solution Applied"
                        multiline
                        rows={3}
                        disabled
                    />
                </Stack>

                <Stack
                    gap={2}
                    sx={{
                        borderBottom: "1px solid #000",
                        pb: "15px",
                    }}
                >
                    <BasicTextField label="Trainer Categorization" disabled />
                    <BasicTextField
                        label="Suggestions (if any) "
                        disabled
                        multiline
                        rows={3}
                    />
                </Stack>
            </Box>
        </div>
    );
};

export default QaReportForm;
