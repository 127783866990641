import React from "react";
import CustomAccordion from "../../../../components/customAccordion/CustomAccordion";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ReportCharts from "./ReportCharts";
import { Icon } from "@iconify/react";

const ChartAccordion = ({ data, Chart, setchart, speakProChartData }) => {
    const charts = [
        { id: 1, chartName: "Bar Chart" },
        { id: 2, chartName: "Donut Chart" },
        { id: 3, chartName: "Pie Chart" },
        { id:4 , chartName: "Overall Perfomance" },

    ];

    const handleChange = (event) => {
        setchart(event.target.value);
    };
    return (
        <CustomAccordion
            title={"Report Chart"}
            variant="h4"
            bgclr="gery.200"
            icon={
                <Icon
                    icon="material-symbols:matter"
                    style={{
                        color: "#2b4ea1",
                        marginRight: 8,
                        fontSize: "24px",
                    }}
                />
            }
        >
            <Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Box width={"25%"} pb={2}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                                Chart Model
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={Chart}
                                label="Chart Model"
                                onChange={handleChange}
                            >
                                {charts.map((chart) => {
                                    return (
                                        <MenuItem
                                            key={chart.id}
                                            value={chart.id}
                                        >
                                            {chart.chartName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <ReportCharts
                data={data}
                chartId={Chart}
                speakProChartData={speakProChartData}
            />
        </CustomAccordion>
    );
};

export default ChartAccordion;
