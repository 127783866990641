import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Icon } from "@iconify/react";

import ImageContainer from "../speakpro/myworkspace/Report/ReportPreview/HeaderImage";
import speakProHeader from "../../../src/img/Artboard.jpg";
import speakProFooter from "../../../src/img/Artboard2.jpg";
import commonHeader from "../../../src/img/Assessment.jpg";
import commonFooter from "../../../src/img/Assessment Foot.jpg";
import SpeakProReportData from "../speakpro/myworkspace/Report/ReportPreview/SpeakProReportData";
import useResponsive from "../../Hooks/useResponsive";
import GlobalWrapper from "../../components/Wrapper/GlobalWrapper";
import InduvidualReportChart from "./InduvidualReportChart";
import Details from "../speakpro/myworkspace/Report/ReportPreview/Details";
import { useDispatch, useSelector } from "react-redux";
import { getInduvidualReportBySroId } from "../../redux/features/assessmentToolSlice";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InduvidualAssessmentReport = ({ leadId, sx, buttonName, btnIcon }) => {
  const Chart = 1;
  const [open, setOpen] = React.useState(false);
  const [subjectDescriptions, setSubjectDescriptions] = React.useState([]);
  const [pdfName, setPdfName] = React.useState("");
  const [header, setHeader] = React.useState("");
  const [footer, setFooter] = React.useState("");
  const mdDown = useResponsive("down", "md");
  let componentRef = useRef(null);
  const dispatch = useDispatch();
  const { induvidualReport, loading } = useSelector(
    (state) => state.assessmentTool
  );
  const [selectedReportIndex, setSelectedReportIndex] = React.useState(0);
  const [storedStudentData, setStoredStudentData] = React.useState({}); //STUDENT DATA AND COURSE DETAILS
  const [data, setData] = React.useState([]); //SELECTED DESCRIPTIONS AND CHART DATA

  React.useEffect(() => {
    if (open) {
      dispatch(getInduvidualReportBySroId(leadId));
    }
  }, [open, leadId]);

  const reportHandleChange = (e) => {
    setSelectedReportIndex(e.target.value);
  };

  React.useEffect(() => {
    setStoredStudentData({
      ...induvidualReport[selectedReportIndex]?.studentDetails,
      dateOfAssessment: dayjs(
        induvidualReport[selectedReportIndex]?.studentDetails?.dateOfAssessment
      ).format("DD-MM-YYYY"),
    });
    setData(induvidualReport[selectedReportIndex]?.description);
    if (induvidualReport[selectedReportIndex]?.studentDetails.courseName == 1) {
      setSubjectDescriptions(data);
    } else {
      const result = induvidualReport[selectedReportIndex]?.description?.map(
        ({ subject, levels }) => ({
          subject,
          checkedDescriptions: levels.flatMap(({ objectives }) =>
            objectives.flatMap(({ checkedDescriptions }) => checkedDescriptions)
          ),
        })
      );
      setSubjectDescriptions(result);
    }
  }, [induvidualReport, selectedReportIndex, leadId]);

  React.useEffect(() => {
    setPdfName(storedStudentData?.name);
  }, [storedStudentData]);

  React.useEffect(() => {
    //THIS USE EFFECT WILL DYNAMICALLY CHANGE THE HEADER AND FOOTER IMAGE
    if (storedStudentData.courseName === 1) {
      setHeader(speakProHeader);
      setFooter(speakProFooter);
    } else {
      setHeader(commonHeader);
      setFooter(commonFooter);
    }
  }, [storedStudentData?.courseName]);

  const downloadPdf = useReactToPrint({
    //BROWSER PREVIEW PAGE TO DOWNLOAD AND PRINT
    content: () => componentRef.current,
    documentTitle: `${pdfName}`,
    onPrintError: () => alert("error occured"),
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Button sx={sx} variant="outlined" onClick={() => setOpen(true)}>
          {btnIcon} {buttonName}
        </Button>
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "#051b30" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Button autoFocus color="inherit" onClick={handleClose}>
                  Close
                </Button>
              </Toolbar>
            </Box>

            <Box sx={{ color: "white", px: 2, py: "8px", minWidth: 200 }}>
              <FormControl fullWidth size="medium">
                <InputLabel
                  sx={{ color: "white" }}
                  id="demo-simple-select-label"
                >
                  Reports
                </InputLabel>
                <Select
                  sx={{ color: "white" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedReportIndex}
                  label="Reports"
                  onChange={reportHandleChange}
                >
                  {induvidualReport.map((data, index) => (
                    <MenuItem key={index} value={index}>{`Report ${
                      index + 1
                    }`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </AppBar>
        <Box>
          <Container sx={{ paddingTop: 2 }}>
            <GlobalWrapper
              title={"Report Preview"}
              actions={
                <>
                  <Button variant="contained" onClick={downloadPdf}>
                    <Icon icon="ion:print-outline" height="25" width="25" />
                    Download Report
                  </Button>
                </>
              }
            >
              <Box className="print-content">
                <Box ref={componentRef}>
                  <Container
                    sx={{
                      marginTop: 3,
                      marginBottom: 3,
                    }}
                  >
                    <ImageContainer src={header} alt="header" />
                  </Container>

                  <Box>
                    <Details storedStudentData={storedStudentData} />
                  </Box>
                  <Box pt={9}>
                    <Box sx={{ padding: 4 }}>
                      <Box>
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#3a6bae",
                            paddingLeft: 6,
                            fontFamily: "Lora, sans-serif",
                          }}
                        >
                          PERFORMANCE SUMMARY
                        </Typography>
                      </Box>
                      <Box
                        sx={{ width: Chart === 1 && mdDown ? "200%" : "80%" }}
                      >
                        <InduvidualReportChart
                          data={data}
                          chartId={Chart}
                          storedStudentDataParams={storedStudentData}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <Container
                      className="descriptionContainer"
                      sx={{
                        marginTop: 0,
                        marginBottom: 1,
                        padding: 4,
                      }}
                    >
                      {storedStudentData?.courseName === 1 ? (
                        <SpeakProReportData data={subjectDescriptions} />
                      ) : (
                        <div>
                          {subjectDescriptions?.map((subjectData, index) => (
                            <Paper
                              key={index}
                              style={{
                                margin: "16px 0",
                                padding: "16px",
                              }}
                            >
                              <Typography variant="h4" gutterBottom>
                                {subjectData.subject}
                              </Typography>
                              <List
                                sx={{
                                  padding: 4,
                                }}
                              >
                                {subjectData.checkedDescriptions.map(
                                  (description) => (
                                    <Box key={description.id}>
                                      <li>
                                        <ul className="description">
                                          <li>
                                            <Box
                                              component="span"
                                              sx={{
                                                display: "block",
                                                textAlign: "justify",
                                              }}
                                            >
                                              {description.description}
                                            </Box>
                                          </li>
                                        </ul>
                                      </li>
                                    </Box>
                                  )
                                )}
                              </List>
                            </Paper>
                          ))}
                        </div>
                      )}
                    </Container>
                  </Box>

                  <Box sx={{ marginBottom: 10 }}>
                    <Container sx={{ display: "grid", maxWidth: "100%" }}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#3a6bae",
                          paddingLeft: 0,
                          fontFamily: "Lora, sans-serif",
                        }}
                      >
                        SUMMARY
                      </Typography>
                      <Typography
                        sx={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingBottom: 2,
                          marginTop: 1,
                          display: "block",
                          textAlign: "justify",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {storedStudentData?.remarks}
                      </Typography>
                    </Container>
                  </Box>
                  <Container sx={{ paddingTop: 3 }}>
                    <ImageContainer src={footer} alt="footer" />
                  </Container>
                </Box>
              </Box>
            </GlobalWrapper>
          </Container>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default InduvidualAssessmentReport;
