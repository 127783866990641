export const FEE_STRUCTURE_TBL_HEAD = [
    { field: "termNo", headerName: "Term No", width: 100 },
    {
        field: "courseSpan",
        headerName: "Course Span (Days)",
        width: 150,
    },
    {
        field: "feeToCollect",
        headerName: "Fee To Collect",
        width: 150,
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
    },
];
