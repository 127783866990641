import React, { useEffect, useState } from "react";
//MUI IMPORTS
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Rating from "@mui/material/Rating";
import Checkbox from "@mui/material/Checkbox";
import { Icon } from "@iconify/react";
import Alert from "@mui/material/Alert";
//CUSTOM COMPONENTS
import Accordian from "../../../components/speakpro/Accordian";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { useSnackbar } from "notistack";
import PreviewReport from "./Report/ReportPreview/PreviewReport";
import ChartAccordion from "./Report/ChartAccordion";

const StudentReport = ({ data }) => {
    const [ratings, setRatings] = useState({}); //STATE FOR STORE STAR RATING STATE INDEPENDENTLY FROM SUBJECT,LEVEL
    const [selections, setSelections] = useState([]); //STATE FOR STORE THE ENTIRE DETAILS OF STUDENT PERFOMANCE
    const [showAlert, setShowAlert] = useState(false);
    const [alertContent, setAlertContent] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [subjectData, setSubjectData] = useState([]);
    const [open, setOpen] = useState(false);
    const [Chart, setchart] = React.useState(1);
    useEffect(() => {
        setSubjectData(data);
    }, [data]);

    const handleRatingChange = (subjectId, levelId, objectiveId, newValue) => {
        if (Object.keys(ratings).length === 0) {
            enqueueSnackbar(
                "To rate the candidate, you must select at least one description from the checkbox.",
                { variant: "warning" }
            );
        }

        if (newValue == null) {
            setSelections((prevSelections) => {
                const updatedSelections = prevSelections
                    .map((subject) => {
                        //IT WILL ITERATE AND FILTER ONLY THE SUBJECTS WHICH CONTAIN LEVEL ARRAY ELEMENT MORE THAN 0
                        if (subject.subjectId === subjectId) {
                            return {
                                ...subject,
                                levels: subject.levels
                                    .map((level) => {
                                        //IT WILL ITERATE ALL LEVELS AND ONLY FILTER THE LEVELS WHICH CONTAIN OBJECTIVE ARRAY LENGTH MORE THAN O
                                        if (level.levelId === levelId) {
                                            return {
                                                ...level,
                                                objectives:
                                                    level.objectives.filter(
                                                        (objective) =>
                                                            objective.id !==
                                                            objectiveId
                                                    ),
                                            };
                                        }
                                        return level;
                                    })
                                    .filter(
                                        (level) => level.objectives.length > 0
                                    ), // Remove levels with no objectives
                            };
                        }
                        return subject;
                    })
                    .filter((subject) => subject.levels.length > 0); // Remove subjects with no levels

                return updatedSelections;
            });
        }

        //THIS FUNCTION WILL HANDLE STAR MARKING PROCESS
        setRatings((prevRatings) => ({
            //{SUBJECTID:{LEVELID:{OBJECTID:GRADESCORE}}}
            ...prevRatings,
            [subjectId]: {
                ...prevRatings[subjectId],
                [levelId]: {
                    ...prevRatings[subjectId]?.[levelId],
                    [objectiveId]:
                        newValue === 1
                            ? 10
                            : newValue === 2
                            ? 20
                            : newValue === 3
                            ? 30
                            : null,
                },
            },
        }));
    };


    const handleCheckboxChange = (
        //TRIGGER THE FUNCTION WHILE USER PERFORMING CHECK BOX BUTTON
        subjectName,
        subjectId,
        levelName,
        levelId,
        objectiveId,
        objectiveName,
        gradeName,
        descriptionId,
        description
    ) => {
        //IT WILL CALL WHENEVER USER SELECT THE CHECK BOX
        setSelections((prevSelections) => {
            const updatedSelections = [...prevSelections];
            const subjectIndex = updatedSelections.findIndex(
                (item) => item.subject === subjectName
            ); //IT WILL GET THE INDEX OF CURRENT SUBJECT OBEJCT

            if (subjectIndex !== -1) {
                //IF THE SUBJECT OBJECT EXISTS IT WILL FIND THE  LEVEL OBJECT  INDEX OF CURRENT LEVEL
                const levelIndex = updatedSelections[
                    subjectIndex
                ].levels.findIndex((item) => item.level === levelName);

                if (levelIndex !== -1) {
                    //IF THE CURRENT LEVEL OBJECT IS EXISTS INSIDE IT WILL FIND THE INDEX OF CURRENT OBJECTIVE OBJECT INDEX
                    const objectiveIndex = updatedSelections[
                        subjectIndex
                    ].levels[levelIndex].objectives.findIndex(
                        (item) => item.id === objectiveId
                    );

                    if (objectiveIndex !== -1) {
                        //IF THE OBJECTIVES ARRAY CONTAIN THE CURRENT OBJECTIVE OBJECT
                        if (
                            updatedSelections[subjectIndex].levels[levelIndex]
                                .objectives[objectiveIndex].starRating !=
                            gradeName
                        ) {
                            //IF THE STAR RATING IS CHANGED THE GRADE NAME GETS CHANGED, IT WILL ENSURE THAT IF USER CHANGE THE STAR RATING ,IT WILL CLEAR THE OLD SELCTED CHECK BOX VALUES
                            updatedSelections[subjectIndex].levels[
                                levelIndex
                            ].objectives[objectiveIndex].checkedDescriptions =
                                [];
                        }

                        updatedSelections[subjectIndex].levels[
                            levelIndex
                        ].objectives[objectiveIndex].starRating = gradeName; //IT WILL ALWAYS SAVE THE STAR RATING GRADE AS LATEST

                        const descriptionIndex = updatedSelections[
                            subjectIndex
                        ].levels[levelIndex].objectives[
                            objectiveIndex
                        ].checkedDescriptions.findIndex(
                            (item) => item.id === descriptionId
                        ); //THIS WILL FIND THE INDEX OF CURRENT DESCRIPTION

                        if (descriptionIndex !== -1) {
                            // IF THE COMING DESCRIPTION ALREADY EXIST (WHILE UNCHECKING THE SELECT BOX) REMOVING THE CURRENT DESCRIPTION OBJECT
                            updatedSelections[subjectIndex].levels[
                                levelIndex
                            ].objectives[
                                objectiveIndex
                            ].checkedDescriptions.splice(descriptionIndex, 1);
                        } else {
                            updatedSelections[subjectIndex].levels[
                                levelIndex
                            ].objectives[
                                objectiveIndex
                            ].checkedDescriptions.push({
                                id: descriptionId,
                                description,
                            });
                        }
                    } else {
                        //IF THE CURRENT OBJECTIVE OBJECT NOT PRESENT IN OBJECTIVE ARRAY IT WILL PUSH
                        updatedSelections[subjectIndex].levels[
                            levelIndex
                        ].objectives.push({
                            id: objectiveId,
                            name: objectiveName,
                            starRating: gradeName,
                            checkedDescriptions: [
                                { id: descriptionId, description },
                            ],
                        });
                    }
                } else {
                    //IF SUBJECT PRESENT BUT INSIDE THERE IS NO LEVEL ARRAY,THIS WILL CREATE AN LEVEL ARRAY
                    updatedSelections[subjectIndex].levels.push({
                        level: levelName,
                        levelId: levelId,
                        objectives: [
                            {
                                id: objectiveId,
                                name: objectiveName,
                                starRating: gradeName,
                                checkedDescriptions: [
                                    { id: descriptionId, description },
                                ],
                            },
                        ],
                    });
                }
            } else {
                //IF THE SUBJECT INDEX IS NOT FIND IT WILL CREATE A NEW ARRAY FOR THE COMING SUBJECT

                updatedSelections.push({
                    subject: subjectName,
                    subjectId: subjectId,
                    levels: [
                        {
                            level: levelName,
                            levelId: levelId,
                            objectives: [
                                {
                                    id: objectiveId,
                                    name: objectiveName,
                                    starRating: gradeName,
                                    checkedDescriptions: [
                                        { id: descriptionId, description },
                                    ],
                                },
                            ],
                        },
                    ],
                });
            }

            return updatedSelections; //MODIFIED ARRAY WILL WRITE THE SELECTION STATE
        });
    };

    const handleSubmit = () => {
        setOpen(true);
        selections?.forEach((subject) => {
            subject?.levels?.forEach((level) => {
                level?.objectives?.forEach((objective) => {
                    if (objective.checkedDescriptions.length === 0) {
                        setShowAlert(true);
                        setAlertContent(
                            `No descriptions selected for the objective "${objective.name}"
              under the subject "${subject.subject}".\n\n` +
                                `To rate this
              objective, please select at least one description from the options
              provided.`
                        );
                    } else {
                        setShowAlert(false);
                    }
                });
            });
        });
    };

    return (
        <Box>
            {subjectData?.map((subject) => (
                <Accordian
                    key={subject.matrix_id}
                    title={subject.matrix_name}
                    variant="h4"
                    icon={
                        <Icon
                            icon="material-symbols:matter"
                            style={{
                                color: "#2b4ea1",
                                marginRight: 8,
                                fontSize: "24px",
                            }}
                        />
                    }
                >
                    {subject?.levels?.map((level) => (
                        <Accordian
                            variant={"h6"}
                            key={level.level_id}
                            title={level.level_name}
                            icon={
                                <Icon
                                    icon="icon-park-solid:level"
                                    style={{
                                        color: "#2b4ea1",
                                        marginRight: 8,
                                        fontSize: "24px",
                                    }}
                                />
                            }
                        >
                            <Box>
                                <Typography sx={{ fontWeight: "bold" }}>
                                    Objectives
                                </Typography>
                                {level?.objectives.map((objective, index) => (
                                    <Box
                                        key={objective.objective_id}
                                        sx={{ marginTop: "10px" }}
                                    >
                                        <Typography>
                                            {`${index + 1}. ${
                                                objective.objective_name
                                            }`}
                                        </Typography>
                                        <Box
                                            sx={{
                                                marginLeft: "15px",
                                                padding: "8px",
                                            }}
                                        >
                                            <Rating
                                                size="large"
                                                name="simple-controlled"
                                                value={
                                                    ratings[
                                                        subject.matrix_id
                                                    ]?.[level.level_id]?.[
                                                        objective.objective_id
                                                    ] / 10 || 0
                                                }
                                                max={3}
                                                onChange={(event, newValue) => {
                                                    handleRatingChange(
                                                        subject.matrix_id,
                                                        level.level_id,
                                                        objective.objective_id,
                                                        newValue
                                                    );
                                                }}
                                            />

                                            {ratings[subject.matrix_id]?.[
                                                level.level_id
                                            ]?.[objective.objective_id] && (
                                                <Box
                                                    sx={{
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    {objective?.grades
                                                        ?.filter(
                                                            (grade) =>
                                                                grade.grade_name ==
                                                                ratings[
                                                                    subject
                                                                        .matrix_id
                                                                ][
                                                                    level
                                                                        .level_id
                                                                ][
                                                                    objective
                                                                        .objective_id
                                                                ]
                                                        )[0]
                                                        ?.descriptions?.map(
                                                            (description) => (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                    key={
                                                                        description.description_id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        onChange={() =>
                                                                            handleCheckboxChange(
                                                                                subject.matrix_name,
                                                                                subject.matrix_id,
                                                                                level.level_name,
                                                                                level.level_id,
                                                                                objective.objective_id,
                                                                                objective.objective_name,
                                                                                ratings[
                                                                                    subject
                                                                                        .matrix_id
                                                                                ][
                                                                                    level
                                                                                        .level_id
                                                                                ][
                                                                                    objective
                                                                                        .objective_id
                                                                                ],
                                                                                description.description_id,
                                                                                description.description
                                                                            )
                                                                        }
                                                                    />
                                                                    {
                                                                        description.description
                                                                    }
                                                                </Box>
                                                            )
                                                        )}
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Accordian>
                    ))}
                </Accordian>
            ))}
            <Box p={1}>
                <ChartAccordion
                    data={selections}
                    Chart={Chart}
                    setchart={setchart}
                />
            </Box>

            {showAlert && (
                <Alert severity="warning" onClose={() => setShowAlert(false)}>
                    {alertContent}
                </Alert>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ marginTop: "20px" }}
                >
                    <Icon
                        icon="material-symbols:preview-outline"
                        height="25"
                        width="25"
                    />
                    Preview Report
                </Button>
            </Box>
            <PreviewReport
                data={selections}
                open={open}
                setOpen={setOpen}
                Chart={Chart}
            />
        </Box>
    );
};

export default StudentReport;
