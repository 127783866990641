import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MarkAttendance from "../MarkAttendance";


const TopSection = ({selectedDate,handleDateChange}) => { //RECIVEING THE SELECTED DATE TO SHOW ON THE PICKING DATE FIELD
  return (
    <>
      <Box >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            displayStaticWrapperAs="desktop"
            views={["month", "year"]}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ml:2}}>
       <MarkAttendance/>
      </Box>
    </>
  );
};

export default TopSection;
