import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
    CancelButton,
    EditButton,
    SubmitButton,
    UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
    Autocomplete,
    Box,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
    getMobileAppStudents,
    updateMobileAppStudent,
} from "../../../../../redux/features/croSlice";
import Iconify from "../../../../color-utils/Iconify";

export const Edit = ({ params }) => {
    const [open, setOpen] = React.useState(false);
    const loading = useSelector((state) => state.cro.loading);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = React.useState(false);

    const profile = JSON.parse(localStorage.getItem("cred")).profile;
    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const CreateSchema = Yup.object().shape({
        admnNo: Yup.string().required("Admission Number Required"),
        email: Yup.string().required("Email required"),
        password: Yup.string().min(
            6,
            "Password is too short - should be 6 chars minimum."
        ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            admnNo: params.admissionNumber || "",
            email: params.email || "",
            password: params.pwd || "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(updateMobileAppStudent(values)).then((res) => {
                console.log(res);
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getMobileAppStudents(profile.userId));
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                title="Edit Student"
                open={open}
                setOpen={setOpen}
                closeButton={true}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <EditButton size="small" action={() => setOpen(true)}>
                        Edit
                    </EditButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box padding={3}>
                                    <Stack direction={"column"} spacing={2}>
                                        <Box>
                                            <TextField
                                                label="student Name"
                                                variant="filled"
                                                value={params.studentName}
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                label="Admission No"
                                                name="admnNo"
                                                variant="filled"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={formik.values.admnNo}
                                                onChange={formik.handleChange}
                                                fullWidth
                                                error={
                                                    formik.touched.admnNo &&
                                                    Boolean(
                                                        formik.errors.admnNo
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.admnNo
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                label="Email"
                                                type="email"
                                                fullWidth
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.email &&
                                                    Boolean(formik.errors.email)
                                                }
                                                helperText={formik.errors.email}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                label="Password"
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="password"
                                                fullWidth
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={
                                                                    handleShowPassword
                                                                }
                                                                edge="end"
                                                            >
                                                                <Iconify
                                                                    icon={
                                                                        showPassword
                                                                            ? "eva:eye-fill"
                                                                            : "eva:eye-off-fill"
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={
                                                    formik.touched.password &&
                                                    Boolean(
                                                        formik.errors.password
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.password
                                                }
                                            />
                                        </Box>
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>

                                            <UpdateButton type="submit">
                                                {loading
                                                    ? "Updating..."
                                                    : " Update Student"}
                                            </UpdateButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
