import React from "react";
import NoDataIcon from "./nodata.png";
import { Box, Stack, Typography } from "@mui/material";

export default function NoDataFound() {
  return (
    <div>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 3,
        }}
      >
        <Stack direction={"column"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={NoDataIcon} height={50} width={50} />
          </Box>
          <Typography variant="h6">No Data Found..!</Typography>
        </Stack>
      </Box>
    </div>
  );
}
