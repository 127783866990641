import React from "react";
import { StatusToggleSwitch } from "../../../../StatusToggleSwitch";
import { inactiveLevel } from "../../../../../redux/features/assessmentToolSlice";

const LevelStatusSwitch = ({ params }) => {
    return (
        <StatusToggleSwitch
            disabled={params.has_child_element === 1 ? true : false}
            api={inactiveLevel(params.level_id)}
            initialValue={params.level_status === 0 ? true : false}
        />
    );
};

export default LevelStatusSwitch;
