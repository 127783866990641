import { Chip } from "@mui/material";
import { AssignCroToWiseAdmin } from "./AssignCroToWiseAdmin";

export const WISE_ADMIN_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "name",
        headerName: "cro Name",
        width: 350,
    },
    {
        field: "status",
        headerName: "status",
        width: 250,
        renderCell: (params) => {
            return (
                <Chip
                    size="small"
                    variant="outlined"
                    color={params.row.wiseUserId ? "success" : "error"}
                    label={params.row.wiseUserId ? "Assigned" : "Not Assigned"}
                />
            );
        },
    },
    {
        field: "Action",
        headerName: "Action",
        width: 150,
        renderCell: (params) => {
            if (params.row.wiseUserId) {
                return null;
            } else {
                return <AssignCroToWiseAdmin params={params.row} />;
            }
        },
    },
];
