import React from 'react'
import { useSelector } from 'react-redux'
import GlobalDeleteDialog from '../../../mui/Dialogue/GlobalDeleteDialog'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import { deleteWorkingHourRequest, getAllPostWorkingHours } from '../../../../redux/features/postWorkingHoursSlice'

const DeleteWorkingHour = ({isDisabled,dispatchParams,requestId}) => {
    const {loading}=useSelector((store)=>store.postWorking)
    const { paginationData } = useSelector((state) => state.globalDataGrid)
  return (
    <GlobalDeleteDialog
    disabled={isDisabled}
    title={"Delete"}
    deleteNote={"Are you sure? you want to delete this request..?"}
    api={deleteWorkingHourRequest(requestId)}
    upDateCall={getAllPostWorkingHours(dispatchParams)}
    updatePaginationCall={savePaginationData({ ...paginationData, openAnchor: false })}
    loading={loading}
    />
  )
}

export default DeleteWorkingHour