import React, { useEffect, useState } from 'react'
import { BulkApprove } from '../../components/mui/Buttons/Buttons'
import { useSelector } from 'react-redux'
import GlobalBulkDialog from '../../components/mui/Dialogue/GlobalBulkDialog'
import { eveningFoodApproveOrRejectOrBulk } from '../../redux/features/foodSlice'
import dayjs from 'dayjs'

const EveningFoodBulkApprove = ({upDateCall,checkedRowsIds}) => {
    const [open,setOpen]=useState(false)
    const { loading } = useSelector((store) => store.food)
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        const now = dayjs()
        const startTime = dayjs().set('hour', 14).set('minute', 0) // 2 PM
        const endTime = dayjs().set('hour', 16).set('minute', 0) // 4 PM
        if (now.isAfter(startTime) && now.isBefore(endTime)) {
          setIsDisabled(false)
        } else {
          setIsDisabled(true)
        }
      }, [open])
  return (
   <GlobalBulkDialog
   openModal={open}
   setOpen={setOpen}
   title={"Bulk Approve"}
   dialogNote={"Are you sure you want to bulk-approve the selected rows?"}
   api={eveningFoodApproveOrRejectOrBulk({approveIds:checkedRowsIds})}
   upDateCall={upDateCall}
   loading={loading}
   popUpButton={<BulkApprove disabled={isDisabled} size={"medium"} action={()=>setOpen(true)}>Bulk Approve</BulkApprove>}
   />
  )
}

export default EveningFoodBulkApprove