import LeaveActions from "./LeaveActions";

export const LEAVE_APPLICATION_TABLE_HEAD = [
    {
        field: "serialNumber",
        flex: 0.2,
        minWidth: 90,
        headerName: "sl.No",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "leaveId",
        flex: 1,
        minWidth: 100,
        headerName: "Leave App. Id",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "leaveType",
        flex: 1,
        minWidth: 200,
        headerName: "Type Of Leave",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "fromDate",
        flex: 1,
        minWidth: 200,
        headerName: "From Date",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "toDate",
        flex: 1,
        minWidth: 200,
        headerName: "To Date",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "noOfdays",
        flex: 1,
        minWidth: 200,
        headerName: "No of Days",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "reason",
        flex: 1,
        minWidth: 200,
        headerName: "Reason",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "leaveStatus",
        flex: 1,
        minWidth: 200,
        headerName: "Status",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "hodRemarks",
        flex: 1,
        minWidth: 200,
        headerName: "Hod Remarks",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "hodLastUpdate",
        flex: 1,
        minWidth: 200,
        headerName: "Last Update by Hod",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "hrRemarks",
        flex: 1,
        minWidth: 200,
        headerName: "HR remarks",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "hrLastUpdate",
        flex: 1,
        minWidth: 200,
        headerName: "Last update by HR",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "isEditable",
        flex: 1,
        minWidth: 200,
        headerName: "Actions",
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => LeaveActions(params),
    },
];
