import React from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { CancelButton } from "../../../../components/mui/Buttons/Buttons";
import { Form } from "./Form";

export const AddMou = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                fullWidth
                maxWidth={"sm"}
                title="Add MoU"
                open={open}
                setOpen={setOpen}
                closeButton={true}
                popupButton={
                    <CancelButton
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Add MoU Data
                    </CancelButton>
                }
                content={<Form />}
            />
        </div>
    );
};
