import NotificationSendUtilsMenu from "./NotificationSendUtilsMenu";
import NotificationSendToUtilsMenu from "./NotificationSendToUtilsMenu.js";
export const NOTIFICATIONS_TABLE_HEAD = [
  {
    field: "serialNumber",
    flex: 0.2,
    minWidth:90,
    headerName: "No.",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "notification_id",
    flex: 0.13,
    minWidth:90,
    headerName: "Id",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "title",
    flex: 0.4,
    minWidth:150,
    headerName: "Title",
    type: "string",
    headerAlign: "left",
    align: "left",
    // editable: true,
  },
  {
    field: "content",
    flex: 1,
    minWidth:150,
    headerName: "Content",
    type: "string",
    headerAlign: "left",
    align: "left",
    // editable: true,
  },
  {
    field: "Share to all",
    flex: 0.5,
    minWidth:100,
    headerName: "Share to all",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => NotificationSendUtilsMenu(params),
    // editable: true,
  },
  {
    field: "Share with",
    flex: 0.5,
    minWidth:100,
    headerName: "Share with",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => NotificationSendToUtilsMenu(params),

    // editable: true,
  },
  
]