import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAdmissionRegister,
    getSubjectSessionCount,
} from "../../../../redux/features/croSlice";
import { UPDT_SESSN_TBL_HEAD } from "./updateSessionCountTableHead";
import Loader from "../../../Loader/Loader";

const TotalSessionsUpdateTable = ({ setOpen, row }) => {
    const dispatch = useDispatch();
    const { subjectSessionCounts, loading, admissionRegFilter } = useSelector(
        (state) => state.cro
    );
    useEffect(() => {
        dispatch(getSubjectSessionCount(row.courseEnrollmentId));
        return () => {
            dispatch(getAdmissionRegister({ ...admissionRegFilter }));
        };
    }, []);

    return loading ? (
        <Loader />
    ) : (
        <div
            style={{
                minWidth: "500px",
            }}
        >
            <DataGrid
                loading={loading}
                rows={subjectSessionCounts}
                columns={UPDT_SESSN_TBL_HEAD}
                autoHeight
                getRowId={(row) => row.serialNumber}
            />
        </div>
    );
};

export default TotalSessionsUpdateTable;
