import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
  loading: false,
  paginationData: {},
};

const globalDataGridSlice = createSlice({
  name: "globalDatagrid",
  initialState: initialState,
  reducers: {
    savePaginationData: (state, action) => {
      return {
        ...state,
        paginationData: action.payload,
      };
    },
    clearPaginationData: (state, action) => {
      return {
        ...state,
        paginationData: {
          gridSize: 10,
          pageNum: 1,
          search: "",
          openAnchor: false,
          checkedRowsIds:[]
        },
      };
    },
  },
});

export const { savePaginationData, clearPaginationData } =
  globalDataGridSlice.actions;

export default globalDataGridSlice.reducer;
