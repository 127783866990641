import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Button, Paper } from "@mui/material";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { Actions } from "./Actions";
import { Invoice } from "../PaymentInvoices/Invoice";

const data = [
    {
        id: 1,
        name: "ABDULLA NIHAL(4385)",
        mobile: "919526711574",
        course: "Classroom - Short Term",
        openingBalance: 1180,
        feePaid: 3000,
        expectedSessions: 9,
        noOfSessions: 7,
        totalFee: 5000,
        balance: 3000,
        ledger: "",
        notes: "",
    },
];

const columns = [
    {
        field: "id",
        headerName: "id",
        width: 50,
    },
    {
        field: "name",
        headerName: "name",
        width: 200,
    },
    {
        field: "mobile",
        headerName: "mobile",
        width: 150,
    },
    {
        field: "course",
        headerName: "course",
        width: 200,
    },
    {
        field: "openingBalance",
        headerName: "Opening Balance",
        width: 90,
    },
    {
        field: "feePaid",
        headerName: "Fee Paid",
        width: 90,
    },

    {
        field: "expectedSessions",
        headerName: "Expected Sessions",
        width: 90,
    },

    {
        field: "noOfSessions",
        headerName: "No Of Sessions",
        width: 90,
    },
    {
        field: "totalFee",
        headerName: "Total Fee",
        width: 90,
    },
    {
        field: "balance",
        headerName: "balance",
        width: 150,
    },
    {
        field: "ledger",
        headerName: "ledger",
        width: 150,
    },
    {
        field: "notes",
        headerName: "notes",
        width: 150,
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function MonthlyAccounts() {
    const [value, setValue] = React.useState(dayjs("2022-04-07"));
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Ledger Summary"
                actions={
                    <Button variant="contained">Balance Fee :-536376</Button>
                }
                Content={
                    <>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={["year", "month"]}
                                    label="Year and Month"
                                    minDate={dayjs("2012-03-01")}
                                    maxDate={dayjs("2023-06-01")}
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        {/* <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 2,
                            }}
                        >
                           
                        </Box> */}

                        <Paper elevation={3}>
                            <Box>
                                <CustomDataGrid
                                    data={data}
                                    rowId={"id"}
                                    columns={columns}
                                    Head={Head}
                                    setSelectedRows={setSelectedRows}
                                    selectedRows={selectedRows}
                                    chipValue={selectedRows[0]?.course}
                                    label="course"
                                    name={selectedRows[0]?.name}
                                    // details={<Details data={selectedRows[0]} />}
                                    actions={<Actions />}
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
