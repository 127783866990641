import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { useFormik, FormikProvider, Form } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  createLevel,
  getAllLevels,
  updateLevel,
  getMatricesPreData,
} from "../../../../../redux/features/assessmentToolSlice";
import GlobalSelectField from "../../../../mui/GlobalSelectField";

const CreateUpdateLevel = ({ params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading, matricesPreData } = useSelector(
    (state) => state.assessmentTool
  );
  const [open, setOpen] = useState(false);
  const [isTextFieldTrue, setIsTextFieldTrue] = useState(false);
  const [isMatrixClassroom, setIsMatrixClassroom] = useState(false);

  const Schema = Yup.object().shape({
    levelName: Yup.string().required("levelName is required"),
    levelMetrixId: Yup.string().required("levelMetrixId is required"),
    levelCreatedBy: Yup.string().required("levelCreatedBy is required"),
  });
  const profile = JSON.parse(localStorage.getItem("cred")).profile;
  const levelData = [
    { id: "Level One", levelName: "Level One" },
    { id: "Level Two", levelName: "Level Two" },
    { id: "Level Three", levelName: "Level Three" },
  ];

  const categoryData = [
    { id: "Category One", levelName: "Category One" },
    { id: "Category Two", levelName: "Category Two" },
    { id: "Category Three", levelName: "Category Three" },
    { id: "Category Four", levelName: "Category Four" },
    { id: "Category Five", levelName: "Category Five" },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      levelName: params ? params.level_name : "",
      // levelName:
      //   params && isMatrixClassroom
      //     ? categoryData
      //     : levelData?.some((level) => level.levelName === params.level_name)
      //     ? params.level_name
      //     : "",
      // levelMetrixId: params ? params.level_metrix_id : "",
      levelMetrixId:
        params &&
        matricesPreData?.some(
          (matrix) => matrix.matrix_id === params.level_metrix_id
        )
          ? params.level_metrix_id
          : "",
      levelCreatedBy: profile.userId,
      levelUpdatedBy: profile.userId,
      id: params ? params.level_id : "",
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      if (params) {
        dispatch(updateLevel(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllLevels());
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(createLevel(values)).then((res) => {
          if (res.payload.status === "failed") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            resetForm();
            dispatch(getAllLevels());
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });

  useEffect(() => {
    if (formik.values.levelMetrixId) {
      const filter = matricesPreData.find(
        (item) => item.matrix_id == formik.values.levelMetrixId
      );

      if (filter) {
        if (
          filter.course_name === "foundation" ||
          filter.course_name === "Little Genie"
        ) {
          setIsTextFieldTrue(true);
        } else {
          setIsTextFieldTrue(false);
        }

        if (filter.course_name == "Classroom") {
          setIsMatrixClassroom(true);
        } else {
          setIsMatrixClassroom(false);
        }
      }
    }
  }, [formik.values.levelMetrixId]);

  return (
    <GlobalDialog
      title={params ? "Update Matrix Level" : "Create Matrix Level"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <>
          {params ? (
            <UpdateButton
              size="small"
              disabled={params.has_child_element === 1 ? true : false}
              action={() => {
                dispatch(
                  getMatricesPreData(profile?.departmentId == 22 ? 22 : 221)
                );
                setOpen(true);
              }}
            >
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size="large"
              action={() => {
                dispatch(
                  getMatricesPreData(profile?.departmentId == 22 ? 22 : 221)
                );
                setOpen(true);
              }}
            >
              Add Level
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <Box>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box>
                  <Stack direction={"column"} spacing={2}>
                    <GlobalSelectField
                      options={{
                        formik: formik,
                        label: "Matrix",
                        name: "levelMetrixId",
                        sx: {
                          width: "100%",
                        },
                        data: matricesPreData,

                        keys: {
                          id: "matrix_id",
                          value: "matrix_name",
                        },
                      }}
                    />
                    <Box>
                      {isTextFieldTrue ? (
                        <TextField
                          name="levelName"
                          label="Level Name"
                          onChange={formik.handleChange}
                          value={formik.values.levelName}
                          autoComplete="off"
                          fullWidth
                          error={
                            formik.touched.levelName &&
                            Boolean(formik.errors.levelName)
                          }
                          helperText={formik.errors.levelName}
                        />
                      ) : (
                        <GlobalSelectField
                          options={{
                            formik: formik,
                            label: "Level Name",
                            name: "levelName",
                            sx: {
                              width: "100%",
                            },
                            data: isMatrixClassroom ? categoryData : levelData,

                            keys: {
                              id: "id",
                              value: "levelName",
                            },
                          }}
                        />
                      )}
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      mt: 2,
                    }}
                  >
                    <Stack direction={"row"} spacing={1}>
                      <CancelButton action={() => setOpen(false)}>
                        Cancel
                      </CancelButton>
                      {params ? (
                        <UpdateButton type="submit">
                          {loading ? "Updating..." : "Update"}
                        </UpdateButton>
                      ) : (
                        <SubmitButton type="submit">
                          {loading ? "Saving..." : "Save"}
                        </SubmitButton>
                      )}
                    </Stack>
                  </Box>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </>
      }
    />
  );
};

export default CreateUpdateLevel;
