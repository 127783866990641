export const TC_UPLOADED_STUDENTDETAILS_TABLE_HEAD = [
    {
        field: "id",
        headerName: "id",
        width: 50,
    },
    {
        field: "admissionNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
    },
    {
        field: "mobile",
        headerName: "Mobile",
        width: 150,
    },
    {
        field: "class",
        headerName: "Class",
        width: 90,
    },
    {
        field: "course",
        headerName: "Course",
        width: 180,
    },

    {
        field: "croName",
        headerName: "CRO Name",
        width: 150,
    },

    {
        field: "totalSalesAmount",
        headerName: "TotalSales Amount",
        width: 200,
    },
    {
        field: "toatalFeeCollected",
        headerName: "Toatal Fee Collected",
        width: 150,
    },
    {
        field: "totalSessionsCompleted",
        headerName: "Total Sessions Completed",
        width: 150,
    },

    {
        field: "lastSessionDate",
        headerName: "Last Session Date",
        width: 150,
    },
    {
        field: "inactiveReason",
        headerName: "Inactive Reason",
        width: 150,
    },
    {
        field: "inactiveRemarks",
        headerName: "Inactive Remarks",
        width: 150,
    },
    {
        field: "inactiveMarkedTime",
        headerName: "Inactive Marked Time",
        width: 150,
    },
    {
        field: "modeOfTcUpload",
        headerName: "Mode OfTc Upload",
        width: 150,
    },
    {
        field: "activeStatusOnOtherCourse",
        headerName: "Active StatusOnOther Course",
        width: 150,
    },
    {
        field: "approval",
        headerName: "Approval",
        width: 150,
    },
];
