export const CRO_ACCOUNTS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "croName",
        headerName: "CRO Name",
        width: 150,
    },
    {
        field: "totalStudents",
        headerName: "Total Students",
        width: 150,
    },
    {
        field: "totalActiveStudents",
        headerName: "Total Active Students",
        width: 150,
    },
    {
        field: "totalTemporaryInactiveStudents",
        headerName: "Total Temporary Inactive Students",
        width: 150,
    },
    {
        field: "totalInactiveStudents",
        headerName: "Total Inactive Students",
        width: 150,
    },
    {
        field: "assignedStudents",
        headerName: "Assigned Students",
        width: 150,
    },
    {
        field: "classStopped",
        headerName: "Class Stopped",
        width: 150,
    },
    {
        field: "tcUploaded",
        headerName: "Tc Uploaded",
        width: 150,
    },
    {
        field: "expectedSessions",
        headerName: "Expected Sessions",
        width: 150,
    },
    {
        field: "completedSessions",
        headerName: "Completed Sessions",
        width: 150,
    },
    {
        field: "completionPercentage",
        headerName: "Completion Percentage",
        width: 150,
    },
    {
        field: "croCollectedFee",
        headerName: "CRO Collected Fee",
        width: 150,
    },
    {
        field: "trainersPayment",
        headerName: "Trainers Payment",
        width: 150,
    },
    {
        field: "feePerSession",
        headerName: "Fee Per Session",
        width: 150,
    },
    {
        field: "tpPerSession",
        headerName: "TP Per Session",
        width: 150,
    },
    {
        field: "totalSalesAmount",
        headerName: "Total Sales Amount",
        width: 150,
    },
    {
        field: "totalAdvancePaid",
        headerName: "Total Advance Paid",
        width: 150,
    },
    {
        field: "balanceFee",
        headerName: "Balance Fee",
        width: 150,
    },
    {
        field: "percentageFeePaid",
        headerName: "% Fee Paid",
        width: 150,
    },
];
