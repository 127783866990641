export const EVALUATION_ANALYTICS_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
        width: 90,
    },
    {
        field: "croName",
        headerName: "CRO Name",
        flex: 1,
    },
    {
        field: "totalEvaluations",
        headerName: "Total Evaluations",
        flex: 1,
    },
    {
        field: "compleated",
        headerName: "Compleated",
        flex: 1,
    },

    {
        field: "notCompleated",
        headerName: "Not Compleated",
        flex: 1,
    },
    {
        field: "croAcknowledged",
        headerName: "CRO Acknowledged",
        flex: 1,
    },
    {
        field: "croNotAcknowledged",
        headerName: "CRO Not Acknowledged",
        flex: 1,
    },
];
