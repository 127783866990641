import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";
import { CR_LEDGER_TBL_HEAD } from "../../../components/datagrid/myworkspace/cro/CrLedgerviewTableHead";
import { useDispatch, useSelector } from "react-redux";
import { getStudentMonthlyLedger } from "../../../redux/features/croSlice";
import PopUps from "../../../components/datagrid/myworkspace/cro/PopUps";
import CloseAccoundForm from "../../../components/datagrid/myworkspace/cro/CloseAccoundForm";
import { Icon } from "@iconify/react";

const CrLedgerView = () => {
    const {
        state: { ledgerYear, ledgerMonth, courseEnrollmentId },
    } = useLocation();

    const dispatch = useDispatch();
    const {
        studentMonthlyLedger,
        loading,
        ledgerAction,
        lgStudentDetails,
        nextLedger,
        previousLedger,
    } = useSelector((state) => state.cro);

    const [open, setOpen] = useState(false);
    const [ledgerDate, setLedgerDate] = useState({
        currentMonth: ledgerMonth,
        currentYear: ledgerYear,
    });

    const handlePrev = () => {
        setLedgerDate({
            currentMonth: previousLedger.month,
            currentYear: previousLedger.year,
        });
        dispatch(
            getStudentMonthlyLedger(
                `${courseEnrollmentId}/${previousLedger.year}/${previousLedger.month}`
            )
        );
    };

    const handleNext = () => {
        setLedgerDate({
            currentMonth: nextLedger.month,
            currentYear: nextLedger.year,
        });
        dispatch(
            getStudentMonthlyLedger(
                `${courseEnrollmentId}/${nextLedger.year}/${nextLedger.month}`
            )
        );
    };

    useEffect(() => {
        dispatch(
            getStudentMonthlyLedger(
                `${courseEnrollmentId}/${ledgerYear}/${ledgerMonth}`
            )
        );
    }, []);

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 2,
                }}
            >
                <div>
                    <Typography variant="h4">Ledger View</Typography>
                    <Breadcrumb />
                </div>
                <div>
                    {previousLedger.year && (
                        <Button
                            variant="contained"
                            sx={{
                                mr: 2,
                            }}
                            onClick={handlePrev}
                        >
                            <Icon icon="uim:previous" width="30" height="30" />
                            Prev
                        </Button>
                    )}
                    {nextLedger.year && (
                        <Button variant="contained" onClick={handleNext}>
                            Next
                            <Icon
                                icon="uim:previous"
                                width="30"
                                height="30"
                                rotate={2}
                            />{" "}
                        </Button>
                    )}
                </div>
            </Box>
            <Box
                sx={{
                    height: 48,
                    backgroundColor: "grey.200",
                    borderRadius: "8px 8px 0 0",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: 2,
                    }}
                >
                    <p>{`Name : ${lgStudentDetails.name} | AdmNo : ${lgStudentDetails.admnNo} | Course : ${lgStudentDetails.courseName} | Course Erollment Id : ${lgStudentDetails.courseEnrollmentId}`}</p>
                    <div>
                        {ledgerAction === "Close Accounts" ? (
                            <Button
                                variant="contained"
                                onClick={() => setOpen(true)}
                                color="success"
                            >
                                {ledgerAction}
                            </Button>
                        ) : (
                            <Button variant="contained">{ledgerAction}</Button>
                        )}
                        <PopUps
                            open={open}
                            setOpen={setOpen}
                            componentProps={{
                                setOpen: setOpen,
                                ledgerYear: ledgerDate.currentYear,
                                ledgerMonth: ledgerDate.currentMonth,
                                courseEnrollmentId,
                            }}
                            component={CloseAccoundForm}
                        />
                    </div>
                </Box>
            </Box>

            <DataGrid
                sx={{
                    mt: 1,
                }}
                loading={loading}
                rows={studentMonthlyLedger}
                columns={CR_LEDGER_TBL_HEAD}
                autoHeight
                getRowId={(row) => row.serialNumber}
            />
        </Box>
    );
};

export default CrLedgerView;
