import React from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
import { editSchedule } from "../../redux/features/scheduleSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    FormHelperText,
    Paper,
    Skeleton,
    Stack,
    TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CancelButton, SaveButton } from "../mui/Buttons/Buttons";
import useResponsive from "../../Hooks/useResponsive";
import { previousSession } from "../../redux/features/scheduleSlice";
import * as Yup from "yup";

export const SessionEditForm = ({ params, setOpen, handleClose }) => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const singleschedule = useSelector(
        (state) => state.schedule.singleschedule
    );
    const schedule = useSelector((state) => state.schedule);
    const EditSchema = Yup.object().shape({
        // meetLink: Yup.string().required("Please add meet link"),
        startDate: Yup.string().required("hhuhu"),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: params,
            startDate: singleschedule.sessionDate || "",
            startTime: singleschedule.fromTime || "",
            // sessionContent: singleschedule.sessionContent || "",
            meetLink: singleschedule.meetLink || "",
        },
        validationSchema: EditSchema,
        onSubmit: (values) => {
            dispatch(editSchedule(values)).then((res) => {
                if (
                    res.payload.status === "error" ||
                    res.payload.status === 422 ||
                    res.payload.status === 400
                ) {
                    console.log("test");
                    setOpen(false);
                    handleClose();
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    handleClose();
                    dispatch(previousSession(schedule.subjectEnrollmentId));
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: { md: 1, xs: 2 },
                }}
            >
                <Paper sx={{ width: "100%", backgroundColor: "grey.200" }}>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Stack sx={{ p: { md: 8, xs: 4 } }} spacing={3}>
                                <Stack
                                    direction={smUp ? "row" : "column"}
                                    spacing={3}
                                >
                                    {/* -----------------------DATE-PICKER----------------------------- */}
                                    {schedule.loading ? (
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{
                                                width: "100%",
                                                height: 56,
                                                borderRadius: 1,
                                            }}
                                        />
                                    ) : (
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker
                                                sx={{
                                                    width: "100%",
                                                    flex: 1,
                                                }}
                                                disablePast
                                                label="Session Date"
                                                views={["year", "month", "day"]}
                                                value={formik.values.startDate}
                                                name="startDate"
                                                format="YYYY-MM-DD"
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "startDate",
                                                        e
                                                            .toISOString()
                                                            .split("T")[0]
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        sx={{
                                                            width: smUp
                                                                ? "50%"
                                                                : "100%",
                                                            flex: 1,
                                                        }}
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    )}

                                    {/* -----------------------FROM-TIME----------------------------- */}
                                    {schedule.loading ? (
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{
                                                width: "100%",
                                                height: 56,
                                                borderRadius: 1,
                                            }}
                                        />
                                    ) : (
                                        <TextField
                                            label="Start Time"
                                            type="time"
                                            name="startTime"
                                            value={formik.values.startTime}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "startTime",
                                                    `${e.target.value}:00`
                                                );
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300,
                                            }}
                                            sx={{
                                                width: "100%",
                                                flex: 1,
                                            }}
                                        />
                                    )}
                                </Stack>
                                <Box>
                                    {schedule.loading ? (
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{
                                                width: "100%",
                                                height: 56,
                                                borderRadius: 1,
                                            }}
                                        />
                                    ) : (
                                        <TextField
                                            label="meetLink"
                                            name="meetLink"
                                            value={formik.values.meetLink}
                                            onChange={formik.handleChange}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300,
                                            }}
                                            sx={{ width: "100%" }}
                                            error={Boolean(
                                                formik.touched.meetLink &&
                                                    formik.errors.meetLink
                                            )}
                                            helperText={
                                                formik.touched.meetLink &&
                                                formik.errors.meetLink
                                            }
                                        />
                                    )}
                                </Box>
                                <Stack direction={"row"} spacing={3}>
                                    {/* -----------------------DESCRIPTION----------------------------- */}
                                    {/* {schedule.loading ? (
                                        <Skeleton
                                            variant="rectangular"
                                            sx={{
                                                width: "100%",
                                                height: 130,
                                                borderRadius: 1,
                                            }}
                                        />
                                    ) : (
                                        <TextField
                                            disabled={
                                                singleschedule.isAdditionalSession
                                                    ? false
                                                    : true
                                            }
                                            label="Session Content"
                                            name="sessionContent"
                                            value={formik.values.sessionContent}
                                            onChange={formik.handleChange}
                                            multiline
                                            rows={4}
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300,
                                            }}
                                            sx={{ width: "100%" }}
                                        />
                                    )} */}
                                </Stack>
                                {/* --------------------------------------------------------------- */}

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <CancelButton
                                        action={() => {
                                            setOpen(false);
                                            handleClose();
                                        }}
                                    >
                                        Cancel
                                    </CancelButton>
                                    <SaveButton type="submit">Save</SaveButton>
                                </Box>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </Paper>
            </Box>
        </div>
    );
};
