import React, { useEffect } from 'react'
import GlobalWrapper from '../../../components/Wrapper/GlobalWrapper'
import AddOrUpdateRequest from '../../../components/datagrid/HelpAndSupport/AddOrUpdateRequest'
import { getAllRequests } from '../../../redux/features/helpAndSupportSlice'
import { useDispatch, useSelector } from 'react-redux'
import GlobalDatagrid from '../../../components/mui/DataGrid/GlobalDatagrid'
import { RequsetRaisingTableHeader } from '../../../components/datagrid/HelpAndSupport/RequsetRaisingTableHeader'
import { Paper } from '@mui/material'
import ActionDetails from '../../../components/mui/GlobalAnchor/ActionDetails'
import GlobalAnchorList from '../../../components/mui/GlobalAnchor/GlobalAnchorList'
import AssigningTechUser from '../../../components/datagrid/HelpAndSupport/GeneratedRequests/AssigningTechUser'
import { AssigningMemo } from '../../../components/datagrid/HelpAndSupport/GeneratedRequests/Memoization'
import StatusChange from '../../../components/datagrid/HelpAndSupport/GeneratedRequests/StatusChange'

const GeneratedRequests = () => {
  const { resData, loading } = useSelector((store) => store.helpAndSupport)
  const { data, meta } = resData
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData
  const userProfile = JSON.parse(localStorage.getItem('cred')).profile

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllRequests(paginationData))
  }, [gridSize, pageNum, search])

  return (
    <GlobalWrapper title={'Generated Requests'} >
      <Paper elevation={4}>
        <GlobalDatagrid
          tableHead={RequsetRaisingTableHeader}
          rows={data || []}
          meta={meta?.totalRecords}
          rowUniqueId="id"
          loading={loading}
          rowClick={true}
          actionComponent={
            <>
              <ActionDetails val={rowData?.status?.charAt(0).toUpperCase() + rowData?.status?.slice(1)} />
              {meta?.showActions && <GlobalAnchorList label="Assign To" component={<AssigningMemo row={rowData} />} />}
              <GlobalAnchorList label="status" component={<StatusChange row={rowData} />}/>
            </>
          }
        />
      </Paper>
    </GlobalWrapper>
  )
}

export default GeneratedRequests
