// import viewLog from "../../views/cro/Notifications/ViewLog";

export const NOTIFICATIONS_LOG_HEAD = [
    {
      field: "logsId",
      flex: 0.1,
      minWidth:90,
      headerName: "id",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "sendTo",
      flex: 0.2,
      minWidth:150,
      headerName: "Students",
      type: "string",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "date",
      flex: 0.5,
      minWidth:150,
      headerName: "Date",
      type: "string",
      headerAlign: "left",
      align: "left",
      
    },
    {
        field: "title",
        flex: 0.5,
        minWidth:150,
        headerName: "title",
        type: "string",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "content",
        flex: 1,
        minWidth:200,
        headerName: "content",
        type: "string",
        headerAlign: "left",
        align: "left",
      },
]