import { IncomeScreenshotView } from "./IncomeScreenshotView";
import { Box, Chip, Stack } from "@mui/material";
import { Icon } from "@iconify/react";
import PaymentInvoicePopup from "../../../../views/cro/myworkspace/PaymentInvoices/PaymentInvoicePopup";
import { EditIncomePopup } from "./EditIncomePopup";
import { EditIncomePopupView } from "./RenderCellMain";
import { DeleteIncome } from "./DeleteIncome";

export const INCOME_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        width: 20,
    },
    {
        field: "incomeYear",
        headerName: "Year",
        headerAlign: "left",
        align: "left",
    },

    {
        field: "incomeMonth",
        headerName: "Month",
        width: 90,
    },
    {
        field: "feeType",
        headerName: "Fee Type",
        width: 120,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "courseName",
        headerName: "Course",
        width: 250,
    },
    {
        field: "transactionDate",
        headerName: "transaction Date",
        width: 150,
    },
    {
        field: "incomeAmount",
        headerName: "Amount",
        width: 90,
    },
    {
        field: "creditedAccount",
        headerName: "Credited A/C",
        width: 90,
    },
    {
        field: "paymentMode",
        headerName: "Payment Mode",
        width: 150,
    },
    {
        field: "screenshotLink",
        headerName: "Screenshot",
        width: 90,
        renderCell: (params) => <IncomeScreenshotView {...params.row} />,
    },
    {
        field: "incomeRemarks",
        headerName: "Remarks",
        width: 150,
    },
    {
        field: "incomeTimestamp",
        headerName: "incomeTimestamp",
        width: 150,
    },
    {
        field: "incomeAddedUser",
        headerName: "Added User",
        width: 150,
    },
    {
        field: "incomeVerification",
        headerName: "Verification",
        width: 200,
        renderCell: (params) => {
            return (
                <Chip
                    label={params.row.incomeVerification}
                    size="small"
                    color={
                        params.row.incomeVerification === "Verified"
                            ? "success"
                            : params.row.incomeVerification ===
                              "Marked As Duplicate"
                            ? "warning"
                            : "error"
                    }
                    icon={
                        params.row.incomeVerification === "Verified" ? (
                            <Icon icon="charm:tick" />
                        ) : params.row.incomeVerification ===
                          "Marked As Duplicate" ? (
                            <Icon icon="bi:exclamation" />
                        ) : (
                            <Icon icon="bitcoin-icons:cross-filled" />
                        )
                    }
                    variant="outlined"
                />
            );
        },
    },
    {
        field: "actionButton",
        headerName: "Actions",
        headerAlign: "left",
        width: 200,
        renderCell: (params) => {
            return (
                <>
                    {params.row.actionButton === "Receipt" ? (
                        <Box padding={1}>
                            <Stack direction={"row"} spacing={1}>
                                <EditIncomePopupView {...params.row} />
                                <DeleteIncome incomeId={params.row.incomeId} />
                            </Stack>
                            {/* <PaymentInvoicePopup /> */}
                        </Box>
                    ) : (
                        ""
                    )}
                </>
            );
        },
    },
];
