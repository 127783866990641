import PropTypes from "prop-types";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

// components
import Iconify from "../../../components/Iconify";
import { motion } from "framer-motion";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: "none",
    textAlign: "center",
    padding: theme.spacing(5, 0),
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

DashboardWidgetSummaryCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
    ]),
    icon: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number,
};

export default function DashboardWidgetSummaryCard({
    title,
    total,
    icon,
    color = "primary",
}) {
    return (
        <motion.div
            initial={{ y: -100 }} // Initial position of the box
            animate={{ y: 0 }} // Target position of the box after the transition
            transition={{ duration: 0.5 }} // Duration of the transition in seconds
        >
            <RootStyle
                sx={{
                    color: (theme) => theme.palette[color].darker,
                    bgcolor: (theme) => theme.palette[color].lighter,
                }}
            >
                <IconWrapperStyle
                    sx={{
                        color: (theme) => theme.palette[color].dark,
                        backgroundImage: (theme) =>
                            `linear-gradient(135deg, ${alpha(
                                theme.palette[color].dark,
                                0
                            )} 0%, ${alpha(
                                theme.palette[color].dark,
                                0.24
                            )} 100%)`,
                    }}
                >
                    <Iconify icon={icon} width={26} height={26} />
                </IconWrapperStyle>
                <Typography variant="h3">{total}</Typography>
                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {title}
                </Typography>
            </RootStyle>
        </motion.div>
    );
}
