import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";

const isMarked = JSON.parse(localStorage.getItem("att"));
const initialState = {
    loading: false,
    isAttenanceMarked: isMarked || false,
};

export const checkAttendanceMarked = createAsyncThunk(
    "attendance/mark",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "users/checkAttendanceMarked",
        });

        if (res.status === "success") {
            localStorage.setItem(
                "att",
                JSON.stringify(res.data.isAttenanceMarked)
            );
        }
        return res;
    }
);

const attendanceMarkSlice = createSlice({
    name: "attendanceMarkSlice",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkAttendanceMarked.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(checkAttendanceMarked.fulfilled, (state, action) => {
            return {
                ...state,
                isAttenanceMarked: action.payload.data.isAttenanceMarked,
                loading: false,
            };
        });
    },
});

export default attendanceMarkSlice.reducer;
