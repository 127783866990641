import { useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import AttendanceCheck from "../../routing/route/AttendanceCheck";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 55;

const RootStyle = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
    position: "relative",
});

const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE + 24,
    backgroundColor: theme.palette.common.white,
    paddingBottom: theme.spacing(10),
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up("lg")]: {},
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const [openSideBar, setOpenSideBar] = useState(true);
    let cred = JSON.parse(localStorage.getItem("cred"));

    return (
        <RootStyle>
            <DashboardNavbar
                // onOpenSidebar={() => setOpenSideBar(!openSideBar)}
                user={cred.profile}
                setOpenSideBar={setOpenSideBar}
                openSideBar={openSideBar}
            />
            <DashboardSidebar
                user={cred.profile}
                isOpenSidebar={openSideBar}
                onCloseSidebar={() => setOpenSideBar(false)}
            />
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
