import { Button } from "@mui/material";
import TrainerEvaluationPopUp from "./TrainerEvaluationPopUp";

export const TRAINERS_EVALUATION_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "SlNo",
        width: 90,
    },
    {
        field: "trainerId",
        headerName: "Trainer ID",
        flex: 1,
    },
    {
        field: "nameOfCandidate",
        headerName: "Name Of Candidate",
        flex: 1,
    },
    {
        field: "emailId",
        headerName: "Email ID",
        flex: 1,
    },
    {
        field: "mobileNo",
        headerName: "Mobile No",
        flex: 1,
    },
    {
        field: "qualification",
        headerName: "Qualification",
        flex: 1,
    },
    {
        field: "demovideoUploadedOn",
        headerName: "Demo video Uploaded On",
        flex: 1,
    },
    {
        field: "demoVideoEvaluation",
        headerName: "Demo Video Evaluation",
        flex: 1,
        renderCell: (params) => <TrainerEvaluationPopUp />,
    },
];
