import React, { useState } from 'react'
import GlobalBulkDialog from '../../../../components/mui/Dialogue/GlobalBulkDialog'
import { savePaginationData } from '../../../../redux/features/globalDatagridSlice'
import {postWorkingBulkApprove } from '../../../../redux/features/postWorkingHoursSlice'
import { useSelector } from 'react-redux'
import { BulkApprove } from '../../../../components/mui/Buttons/Buttons'

const PostWorkingBulkApprove = ({isDisabled,upDateCall,checkedRowsIds}) => {
    const { paginationData } = useSelector((state) => state.globalDataGrid)
    const [open,setOpen]=useState(false)
    const { loading } = useSelector((store) => store.postWorking)
    return (
   <GlobalBulkDialog
   openModal={open}
   setOpen={setOpen}
   disabled={isDisabled}
   title={"Bulk Approve"}
   dialogNote={"Are you sure you want to bulk-approve the selected rows?"}
   api={postWorkingBulkApprove({ids:checkedRowsIds,status:1})}
   upDateCall={upDateCall}
   updatePaginationCall={savePaginationData({ ...paginationData, openAnchor: false })}
   loading={loading}
   popUpButton={<BulkApprove size={"large"} action={()=>setOpen(true)}>Bulk Approve</BulkApprove>}
   />
  )
}

export default PostWorkingBulkApprove