import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../../components/mui/Dialogue/GlobalDialog'
import { getInvoiceDetails } from '../../../../redux/features/invoiceSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'

const CourseDetails = ({invoiceId}) => {
const [open,setOpen]=useState(false)
const [gridSize,setGridSize]=useState(5)
const dispatch=useDispatch("")
const {loading,invoicesDetails}=useSelector((store)=>store.invoice)

useEffect(() => {
    if (open && invoiceId) {
        dispatch(getInvoiceDetails(invoiceId))
    }
  }, [open, invoiceId]);


const hasForeignCurrency=invoicesDetails.some(row=>row.invoice_detail_fee_foreign_currency !==0)
const header=[
  {
    field: "serialNumber",
    headerName: "SlNo",
  },
  {
    field: "subject_name",
    headerName: "Subject",
    width: 200,
  },
  {
    field: "invoice_detail_fee_inr",
    headerName: "Invoice Fee (INR)",
    width: 200,
  },
]

if(hasForeignCurrency){
  header.push({
    field: "invoice_detail_fee_foreign_currency",
    headerName: "Invoice Fee (AED)",
    width: 200,
    renderCell: (params) => params.row.invoice_detail_fee_foreign_currency
  });
}
  return (
    <GlobalDialog
    maxWidth={"md"}
    fullWidth={true}
    title={"Details"}
    open={open}
    setOpen={setOpen}
    closeButton={true}
    popupButton={
        <>
         <Button size='small' variant='outlined'  sx={{ backgroundColor: "#eff4f9", color: "#3173b4" }} onClick={() => setOpen(true)}>View</Button>       
        </>
    }
    content={
       <Box >
        <DataGrid
          rows={invoicesDetails}
          columns={header}
          loading={loading}
          getRowId={(row) => row.serialNumber}
          pageSize={gridSize}
          autoHeight
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
          hideFooter
       />
       </Box>
    }
    />
  )
}

export default CourseDetails
