import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    modulesData: [],
    baseCourseId: [],
    sessionsData: [],
    modulesPreData: [],
    courseAvailablePreData: [],
    subjectsByClassPreData: [],
    mediumsPreData: [],
    projectCategoriesPreData: [],
    baseCourseFilteredData: [],
    modulesForBaseCourses: [],
    sessionsForBaseCourses: [],
    subjectEnrollmentDetailedId: [],
    studentAcademicSessions: [],
};

export const addModulesToBaseCourses = createAsyncThunk(
    "cro/addModulesToBaseCourses",
    async (data) => {
        const res = await apiRequest({
            url: `research/addModulesForBaseCourses`,
            method: "post",
            data: data,
        });
        return res;
    }
);

export const addSessionsToBaseCourses = createAsyncThunk(
    "cro/addSessionsToBaseCourses",
    async (data) => {
        const res = await apiRequest({
            url: `research/addSessionsForBaseCourses`,
            method: "post",
            data: data,
        });
        return res;
    }
);
export const getModulesPreData = createAsyncThunk(
    "cro/getModulesPreData",
    async (baseCourseId) => {
        const res = await apiRequest({
            url: `cr-pre-data/getModules/${baseCourseId}`,
            method: "get",
        });

        return res;
    }
);

export const getBaseCourseListingFiltered = createAsyncThunk(
    "cro/getBaseCourseListingFiltered",
    async (data) => {
        const res = await apiRequest({
            url: `cr-pre-data/getBaseCourseListingFiltered/${data.courseAvailableId}/${data.classId}/${data.subjectId}/${data.mediumId}/${data.syllabusId}/${data.levelId}`,
            method: "get",
        });

        return res;
    }
);

export const getCourseAvailableData = createAsyncThunk(
    "cro/getCourseAvailableData",
    async () => {
        const res = await apiRequest({
            url: `cr-pre-data/getCourseAvailable`,
            method: "get",
        });
        console.log(res);
        return res;
    }
);
export const getSubjectsByClass = createAsyncThunk(
    "cro/getSubjectsByClass",
    async (classId) => {
        const res = await apiRequest({
            url: `cr-pre-data/getSubjectsByClass/${classId}`,
            method: "get",
        });

        return res;
    }
);
export const getMediums = createAsyncThunk("cro/getMediums", async () => {
    const res = await apiRequest({
        url: `cr-pre-data/getMediums`,
        method: "get",
    });

    return res;
});

export const getSyllabus = createAsyncThunk("cro/getSyllabus", async () => {
    const res = await apiRequest({
        url: `cr-pre-data/getSyllabus`,
        method: "get",
    });

    return res;
});

export const getProjectCategoriesPreData = createAsyncThunk(
    "cro/getProjectCategoriesPreData",
    async () => {
        const res = await apiRequest({
            url: `cr-pre-data/getProjCategories`,
            method: "get",
        });

        return res;
    }
);

export const getModulesForBaseCourses = createAsyncThunk(
    "cro/getModulesForBaseCourses",
    async (baseCourseId) => {
        const res = await apiRequest({
            url: `research/getModulesForBaseCourses/${baseCourseId}`,
            method: "get",
        });

        return res;
    }
);

export const deleteModulesForBaseCourses = createAsyncThunk(
    "cro/deleteModulesForBaseCourses",
    async (data) => {
        const res = await apiRequest({
            url: `research/deleteModulesForBaseCourses`,
            method: "delete",
            data: data,
        });

        return res;
    }
);

export const editmodulestoBaseCourses = createAsyncThunk(
    "cro/editmodulestoBaseCourses",
    async (data) => {
        const res = await apiRequest({
            url: `research/editModulesForBaseCourses`,
            method: "patch",
            data: data,
        });

        return res;
    }
);
export const getSessionsForBaseCourses = createAsyncThunk(
    "cro/getSessionsForBaseCourses",
    async (baseCourseId) => {
        const res = await apiRequest({
            url: `research/getSessionsForBaseCourses/${baseCourseId}`,
            method: "get",
        });

        return res;
    }
);
export const addSessionsForBaseCourses = createAsyncThunk(
    "cro/addSessionsForBaseCourses",
    async (data) => {
        const res = await apiRequest({
            url: `research/addSessionsForBaseCourses`,
            method: "post",
            data: data,
        });

        return res;
    }
);

export const updateBaseCourseSession = createAsyncThunk(
    "cro/updateBaseCourseSession",
    async (data) => {
        const res = await apiRequest({
            url: `research/updateBaseCourseSession`,
            method: "patch",
            data: data,
        });

        return res;
    }
);

export const addStudentAcademicSchedules = createAsyncThunk(
    "cro/addStudentAcademicSchedules",
    async (data) => {
        const res = await apiRequest({
            url: `research/addStudentAcademicSchedules`,
            method: "post",
            data: data,
        });

        return res;
    }
);

export const getStudentAcademicSessions = createAsyncThunk(
    "cro/getStudentAcademicSessions",
    async (subjectEnrollmentDtldId) => {
        const res = await apiRequest({
            url: `research/getStudentAcademicSessions/${subjectEnrollmentDtldId}`,
            method: "get",
        });

        return res;
    }
);

export const updateStudentAcademicSchedules = createAsyncThunk(
    "cro/updateStudentAcademicSchedules",
    async (data) => {
        const res = await apiRequest({
            url: `research/updateStudentAcademicSchedules/${data.studentAcademicSchedulesId}`,
            method: "patch",
            data: data,
        });

        return res;
    }
);

export const deleteIndividualAcademicSession = createAsyncThunk(
    "cro/deleteIndividualAcademicSession",
    async (studentAcademicScheduleId) => {
        const res = await apiRequest({
            url: `research/deleteIndividualAcademicSession/${studentAcademicScheduleId}`,
            method: "delete",
        });

        return res;
    }
);
export const deleteStudentAcademicSessions = createAsyncThunk(
    "cro/deleteStudentAcademicSessions",
    async (subjectEnrollmentDetailedId) => {
        const res = await apiRequest({
            url: `research/deleteStudentAcademicSessions/${subjectEnrollmentDetailedId}`,
            method: "delete",
        });

        return res;
    }
);

const researchSlice = createSlice({
    name: "research",
    initialState: initialState,
    reducers: {
        modulesData(state, action) {
            return {
                ...state,
                modulesData: action.payload.modules,
                baseCourseId: action.payload.baseCourseId,
            };
        },
        sessionsData(state, action) {
            return {
                ...state,
                sessionsData: action.payload.sessions,
            };
        },

        clearModulesData: (state, action) => {
            return {
                ...state,
                modulesData: [],
            };
        },
        clearSessionsData: (state, action) => {
            return {
                ...state,
                sessionsData: [],
            };
        },
        subjectEnrollmentDetailedId: (state, action) => {
            return {
                ...state,
                subjectEnrollmentDetailedId: action.payload,
            };
        },
    },

    extraReducers: (builder) => {
        // -----------------------------getProjects----------------------------------
        builder.addCase(addModulesToBaseCourses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addModulesToBaseCourses.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------addSessionsToBaseCourses-----------------------------------
        builder.addCase(addSessionsToBaseCourses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addSessionsToBaseCourses.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------getModulesPreData---------------------------------------------
        builder.addCase(getModulesPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getModulesPreData.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                modulesPreData: data,

                loading: false,
            };
        });
        // ---------------------getBaseCourseListingFiltered-----------------------------------
        builder.addCase(
            getBaseCourseListingFiltered.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getBaseCourseListingFiltered.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(action.payload.data);
                return {
                    ...state,
                    baseCourseFilteredData: data,

                    loading: false,
                };
            }
        );
        // ---------------------getCourseAvailable------------------------------

        builder.addCase(getCourseAvailableData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCourseAvailableData.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                courseAvailablePreData: data,

                loading: false,
            };
        });
        // ----------------getSubjectsByClass-----------------------------
        builder.addCase(getSubjectsByClass.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSubjectsByClass.fulfilled, (state, action) => {
            if (action.payload.data.subjects) {
                const data = action.payload.data.subjects;
                return {
                    ...state,
                    subjectsByClassPreData: data,

                    loading: false,
                };
            } else {
                return {
                    ...state,
                    subjectsByClassPreData: [],

                    loading: false,
                };
            }
        });
        // ---------------------getMediums------------------------------------------
        builder.addCase(getMediums.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getMediums.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                mediumsPreData: data,

                loading: false,
            };
        });
        // ----------------getSyllabus---------------------------------
        builder.addCase(getSyllabus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSyllabus.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                syllabusPreData: data,

                loading: false,
            };
        });
        // ---------------------------get Project Categories------------------------
        builder.addCase(
            getProjectCategoriesPreData.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getProjectCategoriesPreData.fulfilled,
            (state, action) => {
                const data = action.payload.data;
                return {
                    ...state,
                    projectCategoriesPreData: data,

                    loading: false,
                };
            }
        );
        // ------------------------getModulesForBaseCourses-------------------------
        builder.addCase(getModulesForBaseCourses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getModulesForBaseCourses.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                modulesForBaseCourses: data,

                loading: false,
            };
        });
        // -----------------deleteModulesForBaseCourses--------------------------------
        builder.addCase(
            deleteModulesForBaseCourses.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            deleteModulesForBaseCourses.fulfilled,
            (state, action) => {
                return {
                    ...state,
                    loading: false,
                };
            }
        );
        // -------------------editmodulestoBaseCourses--------------------------------
        builder.addCase(editmodulestoBaseCourses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editmodulestoBaseCourses.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -----------------getSessionsForBaseCourses-----------------------------------------------
        builder.addCase(getSessionsForBaseCourses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getSessionsForBaseCourses.fulfilled,
            (state, action) => {
                const data = action.payload.data;
                return {
                    ...state,
                    sessionsForBaseCourses: data,
                    loading: false,
                };
            }
        );
        // --------------------------addSessionsForBaseCourses-------------------------------
        builder.addCase(addSessionsForBaseCourses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            addSessionsForBaseCourses.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // ----------------------updateBaseCourseSession-----------------------------
        builder.addCase(updateBaseCourseSession.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateBaseCourseSession.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -------------------addStudentAcademicSchedules------------------------
        builder.addCase(
            addStudentAcademicSchedules.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            addStudentAcademicSchedules.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // ----------------------getStudentAcademicSessions----------------------------

        builder.addCase(getStudentAcademicSessions.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getStudentAcademicSessions.fulfilled,
            (state, action) => {
                const data = action.payload.data;
                return {
                    ...state,
                    studentAcademicSessions: data,

                    loading: false,
                };
            }
        );
        // --------------------updateStudentAcademicSchedules------------------------------
        builder.addCase(
            updateStudentAcademicSchedules.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            updateStudentAcademicSchedules.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // ------------------deleteIndividualAcademicSession-----------------------------
        builder.addCase(
            deleteIndividualAcademicSession.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            deleteIndividualAcademicSession.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // ---------deleteStudentAcademicSessions--------------------------

        builder.addCase(
            deleteStudentAcademicSessions.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            deleteStudentAcademicSessions.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
    },
});
export const {
    modulesData,
    clearModulesData,
    sessionsData,
    clearSessionsData,
    subjectEnrollmentDetailedId,
} = researchSlice.actions;
export default researchSlice.reducer;
