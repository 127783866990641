import { Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import Page from "../../../components/Page";
import { INWARD_AND_OUTWARD_TABLE_HEAD as columns } from "../../../components/datagrid/Todo/InwardAndOutwardTablehead";

import {
    clearData,
    getDepartmentTasks,
} from "../../../redux/features/todoSlice";

const DepartmentTasks = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state.todo);

    useEffect(() => {
        dispatch(getDepartmentTasks());
        return () => dispatch(clearData());
    }, []);

    useEffect(() => {}, [state]);
    return (
        <Page title="Inward tasks">
            <Container component="main">
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Department tasks
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box sx={{ marginTop: 4 }}>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%" }}
                    >
                        <Box
                            sx={{
                                height: 48,
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                            }}
                        ></Box>

                        <Box sx={{ width: "100%" }}>
                            <DataGrid
                                loading={state.loading}
                                rows={state.data}
                                columns={columns}
                                pageSize={5}
                                autoHeight
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                getRowId={(row) => row.serialNumber}
                                components={{ Toolbar: CustomGridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: {
                                            debounceMs: 500,
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                </Box>
            </Container>
        </Page>
    );
};

export default DepartmentTasks;
