import React from "react";
import ActionsMenu from "./actionsMenu";

export default function Action(params) {
  return (
    <div>
      <ActionsMenu params={params} />
    </div>
  );
}
