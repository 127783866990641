import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { useState } from "react";
import useResponsive from "../../../../../Hooks/useResponsive";
import { TerminationClearance } from "./TerminationClearance";

export const InactiveData = () => {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const data = useSelector(
    (state) => state.crm.terminationClearance.terminationClearance
  );
  const [student, setStudent] = useState(data?.studentRating);
  const [parent, setParent] = useState(data?.parentRating);
  const [tutor, setTutor] = useState(data?.tutorRating);

  const MyCard = ({ rating, title1, title2, remark }) => {
    return (
      <Card sx={{ minWidth: "100%" }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {title1}
          </Typography>
          <Box>
            <Rating
              size={smUp ? "large" : "small"}
              name="customized-10"
              value={rating}
              max={10}
              readOnly
            />
          </Box>

          <Typography variant="body2">{title2}</Typography>
          <Box padding={1}>
            <Chip variant="outlined" color="primary" label={remark} />
          </Box>
        </CardContent>
      </Card>
    );
  };

  const Items = ({ name, value, chipStatus }) => {
    return (
      <Paper
        sx={{
          borderRadius: 3,
          backgroundColor: "grey.200",
          display: "flex",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Chip
          sx={{
            minWidth: 250,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          size={smUp ? "medium" : "small"}
          variant="outlined"
          color="primary"
          icon={<Icon icon="ei:arrow-left" width="25" height="25" rotate={2} />}
          label={
            <>
              <Typography variant="">
                {name}:-
                <Chip
                  sx={{ height: smUp ? null : 15 }}
                  color={chipStatus === 1 ? "success" : "error"}
                  icon={
                    chipStatus === 1 ? (
                      <Icon icon="charm:tick" />
                    ) : (
                      <Icon icon="radix-icons:cross-2" />
                    )
                  }
                  size="small"
                  variant="filled"
                  label={value}
                />
              </Typography>
            </>
          }
        />
      </Paper>
    );
  };

  return (
    <div>
      <Box>
        <TerminationClearance data={data} />
        {/* ----------------------------------------------------------------------------- */}
        <Box sx={{ width: "100%", backgroundColor: "grey.200" }}>
          <Box
            sx={{
              paddingTop: 9,
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography variant="h4">Rating...</Typography>
          </Box>
          <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 3 }} />
          <Box sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <MyCard
                  title1={"Student Rating"}
                  rating={student}
                  title2={"Student Remarks"}
                  remark={
                    data?.studentRemarks
                      ? data.studentRemarks
                      : "No data Found...!"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MyCard
                  title1={"Parent Rating"}
                  rating={parent}
                  title2={"Parent Remarks"}
                  remark={
                    data?.parentRemarks
                      ? data.parentRemarks
                      : "No data Found...!"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MyCard
                  title1={"Tutor Rating"}
                  rating={tutor}
                  title2={"Tutor Remarks "}
                  remark={
                    data?.tutorRemarks ? data.tutorRemarks : "No data Found...!"
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* ------------------------------------------------------------------------------------- */}

        <Stack direction={"column"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "grey.200",
              marginTop: 5,
              borderRadius: 3,
              padding: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Items
                  chipStatus={data?.admissionFeeStatus}
                  name={"Admission Fee"}
                  value={data?.admissionFeeStatus === 1 ? "Paid" : "Not Paid"}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Items
                  chipStatus={data?.courseFeeStatus}
                  name={"Course Fee"}
                  value={data?.courseFeeStatus === 1 ? "Paid" : "Not Paid"}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Items
                  chipStatus={data?.certificateStatus}
                  name={"Certificate"}
                  value={
                    data?.certificateStatus === 1 ? "Issued" : "Not Issued"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Items
                  chipStatus={data?.parentFreebieStatus}
                  name={"Parent Freebie"}
                  value={
                    data?.parentFreebieStatus === 1 ? "Given" : "Not Given"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Items
                  chipStatus={data?.studentFreebieStatus}
                  name={"Student Freebie"}
                  value={
                    data?.studentFreebieStatus === 1 ? "Given" : "Not Given"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Items
                  chipStatus={data?.croRetainCallStatus}
                  name={"CRO Retain Call"}
                  value={
                    data?.croRetainCallStatus === 1 ? "Called" : "Not Called"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Items
                  chipStatus={data?.ogaRetainCallStatus}
                  name={"OGA Retain Call "}
                  value={
                    data?.ogaRetainCallStatus === 1 ? "Called" : "Not Called"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Items
                  chipStatus={data?.croExitCallStatus}
                  name={"CRO Exit Call "}
                  value={
                    data?.croExitCallStatus === 1 ? "Called" : "Not Called"
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Box paddingTop={3}>
            <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 3 }} />
          </Box>

          {/* -------------------------------------------------------------------------------------- */}
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "grey.200",
              marginTop: 3,
              borderRadius: 3,
            }}
          >
            <Box padding={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Date of Inactive"
                    variant="filled"
                    inputProps={{ readOnly: true }}
                    fullWidth
                    value={
                      data?.inactiveDate
                        ? data.inactiveDate
                        : "No Data Found...!"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    value={
                      data?.inactiveReason
                        ? data.inactiveReason
                        : "No Data Found...!"
                    }
                    label="Reason"
                    variant="filled"
                    fullWidth
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    value={
                      data?.inactiveRemarks
                        ? data.inactiveRemarks
                        : "No Data Found...!"
                    }
                    variant="filled"
                    fullWidth
                    rows={4}
                    multiline
                    label="Remarks "
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Box sx={{ display: "flex", justifyContent: "center " }}>
            <Button
              disabled
              size="large"
              sx={{
                backgroundColor: "#f7b400",
                color: "black",
                marginTop: 2,
              }}
            >
              CRM Not Approved..!
              <Icon icon="clarity:sad-face-line" width="20" height="20" />
            </Button>
          </Box>
        </Stack>
        {/* --------------------------------------------------------------- */}
      </Box>
    </div>
  );
};
