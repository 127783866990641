import React, { useState } from "react";
import GlobalDialog from "../../../components/mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    EditButton,
    SaveButton,
} from "../../../components/mui/Buttons/Buttons";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Alert, Box, Paper, Stack } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useEffect } from "react";
import {
    createTimeSlot,
    getSlotesById,
    updateTimeSlot,
} from "../../../redux/features/cabinBookingSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
export const AddAndEditSlots = ({ cabinId, data }) => {
    const { loading } = useSelector((state) => state.cabin);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(null);
    const [timeStatus, setTimeStatus] = useState(false);
    const [open, setOpen] = useState(false);
    const Schema = Yup.object().shape({
        fromTime: Yup.string().required("fromTime is required"),
        toTime: Yup.string().required("toTime is required"),
    });

    // Parse the initial time string into a dayjs object
    const fromTimeObj = dayjs(data?.fromTime, "hh:mm A");
    const toTimeObj = dayjs(data?.toTime, "hh:mm A");

    const formik = useFormik({
        initialValues: {
            id: data?.slot_id || "",
            cabin_id: data?.cabin_id || cabinId,
            fromTime: fromTimeObj || "",
            toTime: toTimeObj || "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            const update = {
                id: values.id,
                cabin_id: cabinId,
                fromTime: values.fromTime.format("HH:mm:00"),
                toTime: values.toTime.format("HH:mm:00"),
            };
            const create = {
                cabin_id: cabinId,
                fromTime: values.fromTime.format("HH:mm:00"),
                toTime: values.toTime.format("HH:mm:00"),
            };

            if (data) {
                dispatch(updateTimeSlot(update)).then((res) => {
                    if (res.payload.status === "error") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    } else if (res.payload.status === "success") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                        dispatch(getSlotesById(data.cabin_id));
                        setOpen(false);
                    }
                });
            } else if (cabinId) {
                dispatch(createTimeSlot(create)).then((res) => {
                    if (res.payload.status === "error") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    } else if (res.payload.status === "success") {
                        setOpen(false);
                        dispatch(getSlotesById(cabinId));
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                    }
                });
            }
        },
    });

    function isValidTimeRange(fromTime, toTime) {
        if (formik.values.fromTime && formik.values.toTime) {
            const fromDate = fromTime;
            const toDate = toTime;

            if (toDate <= fromDate) {
                return false;
            }

            const durationInMinutes = (toDate - fromDate) / (1000 * 60);

            if (durationInMinutes < 30 - 1) {
                return false;
            }

            return true;
        }
    }
    useEffect(() => {
        if (isValidTimeRange(formik.values.fromTime, formik.values.toTime)) {
            setTimeStatus(false);
        } else {
            setTimeStatus(true);
        }
    }, [formik.values.fromTime, formik.values.toTime]);
    return (
        <div>
            <GlobalDialog
                title={data ? "Edit Slot" : "Add Slotes"}
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <>
                        {data ? (
                            <EditButton
                                size="small"
                                sx={{ marginLeft: 1 }}
                                action={() => setOpen(true)}
                            />
                        ) : (
                            <AddButton
                                sx={{ marginLeft: 1 }}
                                action={() => setOpen(true)}
                            >
                                Add Time Slot
                            </AddButton>
                        )}
                    </>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Stack direction={"row"} spacing={2}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <TimePicker
                                            name="fromTime"
                                            label="From Time"
                                            value={formik.values.fromTime}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                                formik.setFieldValue(
                                                    "fromTime",
                                                    newValue
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    error={
                                                        formik.touched
                                                            .fromTime &&
                                                        Boolean(
                                                            formik.errors
                                                                .fromTime
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.fromTime
                                                    }
                                                    fullWidth
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <TimePicker
                                            label="To Time"
                                            name="toTime"
                                            value={formik.values.toTime}
                                            onChange={(newValue) => {
                                                setValue(newValue);
                                                formik.setFieldValue(
                                                    "toTime",
                                                    newValue
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    error={
                                                        formik.touched.toTime &&
                                                        Boolean(
                                                            formik.errors.toTime
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.toTime
                                                    }
                                                    fullWidth
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Stack>

                                {timeStatus == true &&
                                formik.values.toTime &&
                                formik.values.fromTime ? (
                                    <Box marginTop={2}>
                                        <Alert severity="warning">
                                            Invalid Time Range..!
                                        </Alert>
                                    </Box>
                                ) : null}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Stack direction={"row"} spacing={2} pt={2}>
                                        <CancelButton
                                            size="small"
                                            action={() => setOpen(false)}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <SaveButton
                                            disabled={
                                                timeStatus == true
                                                    ? true
                                                    : false
                                            }
                                            size="small"
                                            type="submit"
                                        >
                                            {loading ? "Saving..." : "Save"}
                                        </SaveButton>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
