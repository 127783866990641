import React from "react";
import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";

export const JqaStatus = React.memo((params) => {
  if (params.jqa === null) {
    return null;
  } else if (params.jqa === "Not Assigned") {
    return (
      <Chip
        label={params.jqa}
        variant="outlined"
        icon={<Icon icon="radix-icons:cross-2" />}
        color="warning"
        size="small"
      />
    );
  } else {
    return (
      <Chip
        label={params.jqa}
        variant="outlined"
        icon={<Icon icon="ic:round-person" width="15" height="15" />}
        color="success"
        size="small"
      />
    );
  }
});
