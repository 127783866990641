import { Update } from "./Update";

export const UPDATE_EXPECTED_SESSION_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "admnNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "courseEnrollmentId",
        headerName: "Course Enrollment Id",
        width: 170,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },

    {
        field: "className",
        headerName: "Class",
        width: 100,
    },
    {
        field: "courseName",
        headerName: "Course",
        width: 200,
    },

    {
        field: "expectedSessions",
        headerName: "Expected Sessions",
        width: 170,
        renderCell: (params) => <Update params={params.row} />,
    },
];
