import React from "react";
import { useSelector } from "react-redux";
import useRoles from "../../Hooks/useRoles";
import { Route, Routes } from "react-router-dom";
import {
    Projects,
    Classes,
    ProjectPackeges,
    ProjectCategories,
    PackageSubjectSessions,
    MiscMaster,
    TextPublisher,
    CoursesAvailable,
    PackageClasses,
} from "../../views/cro/Masters";

const MasterRouter = () => {
    useRoles("masters");
    const { role } = useSelector((state) => state);
    return (
        <Routes>
            <Route path="/projects" element={<Projects />} />
            <Route path="/project-classes" element={<Classes />} />
            <Route path="/project-packages" element={<ProjectPackeges />} />
            <Route path="/project-categories" element={<ProjectCategories />} />
            <Route
                path="/package-subject-sessions"
                element={<PackageSubjectSessions />}
            />

            <Route path="/misc-masters" element={<MiscMaster />} />

            <Route path="/package-classes" element={<PackageClasses />} />
            <Route path="/text-publishers" element={<TextPublisher />} />
            <Route path="/courses-available" element={<CoursesAvailable />} />
        </Routes>
    );
};

export default MasterRouter;
