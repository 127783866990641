import RedirectToCart from "./RedirectToCart";

const cart = (params) => {
  return <RedirectToCart {...params.row} />;
};

export const QUALIFIED_LEADS_TABLE_HEAD = [
  {
    field: "sl_no",
    headerName: "slNo",
    width: 20,
  },
  {
    field: "leadId",
    headerName: "Lead Id",
    width: 150,
  },
  {
    field: "name",
    headerName: "Lead Name",
    width: 300,
  },
  {
    field: "leadTime",
    headerName: "Date",
    width: 300,
  },
  {
    field: "Cart",
    headerName: "Cart",
    width: 150,
    renderCell: cart,
  },
];
