import React, { useState } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { useDispatch, useSelector } from "react-redux";
import { getTpVerificationData } from "../../../../redux/features/croSlice";
import { useEffect } from "react";
import { TRAINER_PAYMENT_VERIFICATION } from "../../../../components/datagrid/myworkspace/cro/TrainerPaymentVarificationTableHead";

export const TrainerPaymentVerification = React.memo(() => {
    const dispatch = useDispatch();
    const { tpVerificationData, loading } = useSelector((state) => state.cro);
    const [value, setValue] = useState(dayjs(new Date()));
    const [gridSize, setGridSize] = useState(5);
    const profile = JSON.parse(localStorage.getItem("cred")).profile;
    const data = {
        croId: profile.userId,
        date: value.format("YYYY/MM"),
    };
    useEffect(() => {
        dispatch(getTpVerificationData(data));
    }, [value]);

    return (
        <div>
            <Wrapper
                title="Trainer Payment Verification"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                mb: 2,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    views={["year", "month"]}
                                    label="Year and Month"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Paper elevation={3}>
                            <Box sx={{ width: "100%", padding: 0 }}>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    loading={loading}
                                    autoHeight
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    getRowHeight={() => "auto"}
                                    rows={tpVerificationData}
                                    columns={TRAINER_PAYMENT_VERIFICATION}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
});
