import { deleteCabin } from "../../../../redux/features/cabinBookingSlice";
import { AddCabin } from "../../../../views/hr/myworkspace/AddCabin";
import { StatusToggleSwitch } from "../../../StatusToggleSwitch";
import { DeleteCabin } from "./DeleteCabin";
import { Slots } from "./Slots";

export const CABIN_SETTINGS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "SlNo",
        width: 90,
    },
    {
        field: "asset_name",
        headerName: "Cabin Name",
        width: 350,
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => (
            <StatusToggleSwitch
                initialValue={params.row.is_active === false ? true : false}
                api={deleteCabin(params.row.asset_id)}
            />
        ),
    },
    {
        field: "slots",
        headerName: "slots",
        width: 250,
        renderCell: (params) => <Slots params={params.row} />,
    },
    {
        field: "Edit",
        headerName: "Edit",
        width: 200,
        renderCell: (params) => <AddCabin params={params.row} />,
    },
    // {
    //     field: "Delete",
    //     headerName: "Delete",
    //     width: 120,
    //     renderCell: (params) => <DeleteCabin params={params.row} />,
    // },
];
