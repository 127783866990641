import ToolTip from "../../../../ToolTip/ToolTip"

export const ogaAdmissionRegisterTableHeader=[
    {
        field:"sl_no",
        headerName:"Sl No",
        minWidth: 50,
    },
    {
        field:"admissionNo",
        headerName:"Admission No",
        minWidth: 110,
    },
    {
        field:"studentName",
        headerName:"Name",
        minWidth: 200,
    },
    {
        field:"contactNo",
        headerName:"Mobile",
        minWidth: 150,
    },
    {
        field:"className",
        headerName:"Class",
        minWidth: 80,
    },
    {
        field:"syllabus",
        headerName:"Syllabus",
        minWidth: 100,
    },
    {
        field:"medium_name",
        headerName:"Medium",
        minWidth:100,
    },
    {
        field:"courseName",
        headerName:"Course Name",
        minWidth: 220,
        renderCell: (params) => {
            return (
              <ToolTip
                expand={params.row.courseName}
                content={params.row.courseName}
              />
            );
          },
    },
    {
        field:"courseEnrollmentDate",
        headerName:"Course Enrollment Date",
        minWidth: 180,
    },

    
    {
        field:"cro",
        headerName:"CRO",
        minWidth: 180,
    },
    {
        field:"firstTermFee",
        headerName:"First Term Fee",
        minWidth: 180,
        renderCell:(params)=>{
         return  params.row.firstTermFee==null || params.row.firstTermFee==undefined ?"Not Paid" :"Paid"
        }
    },

    {
        field:"jqaAssessmentReport",
        headerName:"JQA Report",
        minWidth: 200,
        renderCell: (params) => {
            return (
              <ToolTip
                expand={params.row.jqaAssessmentReport}
                content={params.row.jqaAssessmentReport}
              />
            );
          },
    },


   
]