import React from "react";
import { Paper, Box, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Icon } from "@iconify/react";
import { Divider } from "@mui/material";

export const MyList = ({ icon, name }) => {
  return (
    <>
      <List>
        <ListItem>
          <ListItemIcon>{icon}</ListItemIcon>
          <Typography variant="subtitle2">{name}</Typography>
        </ListItem>
        <Divider variant="inset" />
      </List>
    </>
  );
};

export const StudentProfile = React.memo(({ data }) => {
  return (
    <div>
      <Box marginTop={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h6">Personal Details</Typography>
                <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 2 }} />
                <MyList
                  icon={<Icon icon="ic:round-person" width="25" height="25" />}
                  name={
                    <>
                      Fist Name -
                      {data?.studentProfile.firstName
                        ? data.studentProfile.firstName
                        : "No Name"}
                    </>
                  }
                />

                <MyList
                  icon={<Icon icon="ic:round-person" width="25" height="25" />}
                  name={
                    <>
                      Last Name -
                      {data?.studentProfile.lastName
                        ? data.studentProfile.lastName
                        : "No Name"}
                    </>
                  }
                />
                <MyList
                  icon={<Icon icon="fa:intersex" width="25" height="25" />}
                  name={
                    <>
                      Genter -
                      {data?.studentProfile.gender
                        ? data.studentProfile.gender
                        : "No gender"}
                    </>
                  }
                />
                <MyList
                  icon={
                    <Icon
                      icon="mdi:blood-plus-outline"
                      width="25"
                      height="25"
                    />
                  }
                  name={
                    <>
                      blood Group -
                      {data?.studentProfile.bloodgroup
                        ? data.studentProfile.bloodgroup
                        : "No bloodgroup"}
                    </>
                  }
                />
                <MyList
                  icon={<Icon icon="mdi:father" width="25" height="25" />}
                  name={
                    <>
                      Father Name -
                      {data?.studentProfile.fatherName
                        ? data.studentProfile.fatherName
                        : "No fatherName"}
                    </>
                  }
                />

                <MyList
                  icon={<Icon icon="mdi:mother" width="25" height="25" />}
                  name={
                    <>
                      Mother Name -
                      {data?.studentProfile.motherName
                        ? data.studentProfile.motherName
                        : "No motherName"}
                    </>
                  }
                />
                <MyList
                  icon={
                    <Icon icon="ic:outline-date-range" width="25" height="25" />
                  }
                  name={
                    <>
                      Date Of Birth -
                      {data?.studentProfile.dob
                        ? data.studentProfile.dob
                        : "No dob"}
                    </>
                  }
                />
                <MyList
                  icon={
                    <Icon icon="clarity:email-line" width="25" height="25" />
                  }
                  name={
                    <>
                      Email -
                      {data?.studentProfile.email
                        ? data.studentProfile.email
                        : "No email"}
                    </>
                  }
                />
              </CardContent>
            </Card>
          </Grid>
          {/* ------------------------------------------------------------------------- */}
          <Grid item xs={12} sm={12} md={8}>
            <Card>
              <CardContent>
                <MyList
                  icon={
                    <Icon icon="bytesize:location" width="25" height="25" />
                  }
                  name={
                    <>
                      Permanent Address -
                      {data?.studentProfile.permanentAddress
                        ? data.studentProfile.permanentAddress
                        : "No permanentAddress"}
                    </>
                  }
                />

                <MyList
                  icon={<Icon icon="ph:phone-duotone" width="25" height="25" />}
                  name={
                    <>
                      Father Contact Number -
                      {data?.studentProfile.fatherContactNumber
                        ? data.studentProfile.fatherContactNumber
                        : "No fatherContactNumber"}
                    </>
                  }
                />
                <MyList
                  icon={<Icon icon="ph:phone-duotone" width="25" height="25" />}
                  name={
                    <>
                      Mother Contact Number -
                      {data?.studentProfile.motherContactNumber
                        ? data.studentProfile.motherContactNumber
                        : "No motherContactNumber"}
                    </>
                  }
                />
              </CardContent>
            </Card>
            <Grid sx={{ marginTop: 1 }} container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Academic Details</Typography>
                    <Divider
                      sx={{ borderStyle: "dashed", borderBottomWidth: 2 }}
                    />
                    <MyList
                      icon={<Icon icon="bxs:school" width="25" height="25" />}
                      name={
                        <>
                          School Name -
                          {data?.studentProfile.schoolName
                            ? data.studentProfile.schoolName
                            : "No schoolName"}
                        </>
                      }
                    />
                    <MyList
                      icon={
                        <Icon
                          icon="fluent:class-24-regular"
                          width="25"
                          height="25"
                        />
                      }
                      name={
                        <>
                          Class -
                          {data?.studentProfile.className
                            ? data.studentProfile.className
                            : "No className"}
                        </>
                      }
                    />
                    <MyList
                      icon={
                        <Icon icon="carbon:data-2" width="25" height="25" />
                      }
                      name={
                        <>
                          Syllabus -
                          {data?.studentProfile.syllabusName
                            ? data.studentProfile.syllabusName
                            : "No syllabusName"}
                        </>
                      }
                    />

                    <MyList
                      icon={
                        <Icon icon="carbon:data-2" width="25" height="25" />
                      }
                      name={
                        <>
                          Medium -
                          {data?.studentProfile.mediumName
                            ? data.studentProfile.mediumName
                            : "No mediumName"}
                        </>
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Course Details</Typography>
                    <Divider
                      sx={{ borderStyle: "dashed", borderBottomWidth: 2 }}
                    />
                    <MyList
                      icon={
                        <Icon
                          icon="fluent:form-20-regular"
                          width="25"
                          height="25"
                        />
                      }
                      name={
                        <>
                          Admission No -
                          {data?.studentProfile.admnNo
                            ? data.studentProfile.admnNo
                            : "No admnNo"}
                        </>
                      }
                    />
                    <MyList
                      icon={
                        <Icon
                          icon="ic:round-date-range"
                          width="25"
                          height="25"
                        />
                      }
                      name={
                        <>
                          Course Enrolled Date -
                          {data?.studentProfile.courseEnrolledDate
                            ? data.studentProfile.courseEnrolledDate
                            : "No courseEnrolledDate"}
                        </>
                      }
                    />
                    <MyList
                      icon={
                        <Icon
                          icon="mdi:rename-outline"
                          width="25"
                          height="25"
                        />
                      }
                      name={
                        <>
                          Course Name -
                          {data?.studentProfile.courseName
                            ? data.studentProfile.courseName
                            : "No courseName"}
                        </>
                      }
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
});
