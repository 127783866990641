import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Page404 from "../../../views/user/Auth/Page404";

const HrRoute = ({ children }) => {
    const profile = JSON.parse(localStorage.getItem("cred")).profile;
    if (profile.departmentName === "Human Resource (HR)") {
        return children ? children : <Outlet />;
    } else {
        return (
            <Page404
                message={
                    "Sorry!! You don'nt have the permission for such an action"
                }
            />
        );
    }
};

export default HrRoute;
