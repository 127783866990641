import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Chip, Button } from "@mui/material";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { InactiveData } from "./InactiveData";
import { useDispatch, useSelector } from "react-redux";

import {
    getTerminationClearance,
    getCourseTcDetails,
    getCourseInactiveReasons,
} from "../../../../../redux/features/crmSlice";
import SimpleLoader from "../../../../Loader/SimpleLoader";
import { Active } from "./Active";
import Wrapper from "../../../../Wrapper/Wrapper";

export const AdmissionStatus = React.memo(({ params, courseEnrollmentId }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const loading = useSelector((state) => state.crm.loading);

    return (
        <div>
            <GlobalDialog
                fullWidth
                maxWidth={"lg"}
                open={open}
                setOpen={setOpen}
                title="Termination Clearance"
                closeButton={true}
                popupButton={
                    <Chip
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setOpen(true);
                            {
                                params === "Inactive"
                                    ? dispatch(
                                          getTerminationClearance(
                                              courseEnrollmentId
                                          )
                                      )
                                    : dispatch(
                                          getCourseTcDetails(courseEnrollmentId)
                                      );
                            }
                            dispatch(getCourseInactiveReasons());
                        }}
                        icon={
                            params === "Active" ? (
                                <Icon icon="mdi:tick" />
                            ) : null || params === "Inactive" ? (
                                <Icon
                                    icon="basil:cross-outline"
                                    height="20"
                                    width="20"
                                />
                            ) : null || params === "Temporarly Inactive" ? (
                                <Icon
                                    icon="basil:cross-outline"
                                    height="20"
                                    width="20"
                                />
                            ) : null
                        }
                        color={
                            params === "Inactive"
                                ? "error"
                                : "info" && params === "Temporarly Inactive"
                                ? "warning"
                                : "info" && params === "Active"
                                ? "success"
                                : "info"
                        }
                        label={params}
                    />
                }
                content={
                    <Wrapper
                        Content={
                            <>
                                {loading ? (
                                    <SimpleLoader />
                                ) : (
                                    <>
                                        {params === "Inactive" ? (
                                            <InactiveData />
                                        ) : (
                                            ""
                                        )}
                                        {params === "Active" ? (
                                            <Active
                                                setOpen={setOpen}
                                                params={params}
                                                courseEnrollmentId={
                                                    courseEnrollmentId
                                                }
                                            />
                                        ) : (
                                            ""
                                        )}
                                        {params === "Temporarly Inactive" ? (
                                            <Active
                                                setOpen={setOpen}
                                                params={params}
                                                courseEnrollmentId={
                                                    courseEnrollmentId
                                                }
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </>
                                )}
                            </>
                        }
                    />
                }
            />
        </div>
    );
});
