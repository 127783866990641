import { React, useEffect } from "react";
import {
    Typography,
    Box,
    Skeleton,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Paper,
    FormHelperText,
    Stack,
    Autocomplete,
    TextField,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SessionTableColumns as columns } from "../../../components/datagrid/SessionsTableColumns";
import {
    previousSession,
    filterSchedules,
    clearSchedule,
    subjectEnrollment,
} from "../../../redux/features/scheduleSlice";

import { fetchStudents } from "../../../redux/features/studentsSlice";
import { fetchCoursesOfStudent } from "../../../redux/features/coursesSlice";
import * as Yup from "yup";
import Page from "../../../components/Page";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import useResponsive from "../../../../src/Hooks/useResponsive";
import Wrapper from "../../../components/Wrapper/Wrapper";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { formatSchedules } from "../../../redux/selectors";
import { useState } from "react";

function PreviousSessions() {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const { students, courses, schedule } = useSelector((state) => state);
    const previousSessions = useSelector((state) => formatSchedules(state));
    const [gridSize, setGridSize] = useState(5);
    const CreateSchema = Yup.object().shape({
        student: Yup.number("The selected student is invalid")
            .positive("Please select a student")
            .required("Please select a student")
            .integer(),
        course: Yup.string().required("Please select a course"),
        moduleId: Yup.string().required("Please select a module"),
    });
    const formik = useFormik({
        initialValues: {
            department: "",
            course: "",
            subjectEnrollmentId: "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values) => {
            console.log("vaaaaaaaaaaaaaaaaaaaaaaaaa", values);
            // dispatch(scheduleSession(values)).then((res) => {
            //   enqueueSnackbar(res.payload.message);
            // });
        },
    });

    useEffect(() => {
        dispatch(fetchStudents());
        return () => {
            dispatch(clearSchedule());
        };
    }, []);

    useEffect(() => {}, [students.loading]);

    return (
        <>
            <Wrapper
                title=" Sessions"
                Content={
                    <>
                        <Page title="Create Additional Session">
                            <>
                                {/*..................................HEADING.............................*/}

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        mt: { md: 4, xs: 2 },
                                    }}
                                >
                                    <Paper elevation={0} sx={{ width: "100%" }}>
                                        <FormikProvider value={formik}>
                                            <Form
                                                onSubmit={formik.handleSubmit}
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundColor:
                                                            "grey.200",
                                                        padding: 1,
                                                        borderRadius: 1,
                                                    }}
                                                >
                                                    <Stack
                                                        direction={
                                                            mdUp
                                                                ? "row"
                                                                : "column"
                                                        }
                                                        spacing={2}
                                                    >
                                                        {/* .........................STUDENT SELECTBOX............................ */}

                                                        {students.loading ? (
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Autocomplete
                                                                freeSolo
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                                options={
                                                                    students.data
                                                                }
                                                                getOptionLabel={(
                                                                    option
                                                                ) =>
                                                                    option.student
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "student",
                                                                        e.target
                                                                            .value
                                                                    );
                                                                    formik.setFieldValue(
                                                                        "subjectEnrollmentId",
                                                                        e.target.getAttribute(
                                                                            "data-subenid"
                                                                        )
                                                                    );

                                                                    dispatch(
                                                                        fetchCoursesOfStudent(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Student"
                                                                    />
                                                                )}
                                                                renderOption={(
                                                                    props,
                                                                    option
                                                                ) => {
                                                                    return (
                                                                        <li
                                                                            {...props}
                                                                            value={
                                                                                option.admissionNumber
                                                                            }
                                                                            key={
                                                                                option.admissionNumber
                                                                            }
                                                                            data-subenid={
                                                                                option.subjectEnrollmentId
                                                                            }
                                                                        >
                                                                            {
                                                                                option.student
                                                                            }{" "}
                                                                            : (
                                                                            {
                                                                                option.admissionNumber
                                                                            }
                                                                            )
                                                                        </li>
                                                                    );
                                                                }}
                                                            />
                                                        )}

                                                        {/*--------------------------COURSE SELECTBOX_______________________________*/}

                                                        {/* can'nt use globalised component due to perticular feild uses multiple keys as course name*/}
                                                        {courses.loading ? (
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        ) : (
                                                            <FormControl
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .course &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .course
                                                                    )
                                                                }
                                                            >
                                                                <InputLabel id="course-select-label">
                                                                    Course
                                                                </InputLabel>
                                                                <Select
                                                                    name="course"
                                                                    labelId="course-select-label"
                                                                    label="Course"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .course
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        const [
                                                                            course_base_id,
                                                                            subjectEnrollmentId,
                                                                        ] =
                                                                            e.target.value.split(
                                                                                "&"
                                                                            );
                                                                        formik.setFieldValue(
                                                                            "subjectEnrollmentId",
                                                                            subjectEnrollmentId
                                                                        );
                                                                        formik.handleChange(
                                                                            e
                                                                        );
                                                                        dispatch(
                                                                            previousSession(
                                                                                subjectEnrollmentId
                                                                            )
                                                                        );

                                                                        dispatch(
                                                                            subjectEnrollment(
                                                                                subjectEnrollmentId
                                                                            )
                                                                        );
                                                                        console.log(
                                                                            "subjectEnrollmentId",
                                                                            subjectEnrollmentId
                                                                        );
                                                                        // dispatch(fetchModulesOfCourse(course_base_id));
                                                                    }}
                                                                >
                                                                    {courses.data.map(
                                                                        (
                                                                            course
                                                                        ) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    value={`${course.courses_base_table_id}&${course.subjectEnrollmentId}`}
                                                                                    key={
                                                                                        course.courses_base_table_id
                                                                                    }
                                                                                >
                                                                                    {`${course.course_name} ${course.course_available_detailed_name} ${course.subject_name}`}
                                                                                </MenuItem>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Select>
                                                                <FormHelperText id="course-helper">
                                                                    {
                                                                        formik
                                                                            .errors
                                                                            .course
                                                                    }
                                                                </FormHelperText>
                                                            </FormControl>
                                                        )}

                                                        {/*--------------------------STATUS SELECTBOX----------------------*/}
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            size="medium"
                                                        >
                                                            <InputLabel id="demo-simple-select-label">
                                                                Status
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Status"
                                                                defaultValue=""
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    dispatch(
                                                                        filterSchedules(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="complete">
                                                                    Completed
                                                                </MenuItem>
                                                                <MenuItem value="incomplete">
                                                                    Incomplete
                                                                </MenuItem>
                                                                <MenuItem value="Trainer Cancelled">
                                                                    Trainer
                                                                    Cancelled
                                                                </MenuItem>
                                                                <MenuItem value="CRO Cancelled">
                                                                    CRO
                                                                    Cancelled
                                                                </MenuItem>
                                                                <MenuItem value="Expired">
                                                                    Expired
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Stack>
                                                </Box>
                                                {/* {!schedule.loading && sessions.length ? ( */}
                                                <Box marginTop={3}>
                                                    <Paper
                                                        elevation={3}
                                                        sx={{ width: "100%" }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <DataGrid
                                                                components={{
                                                                    Toolbar:
                                                                        CustomGridToolbar,
                                                                }}
                                                                loading={
                                                                    schedule.loading
                                                                }
                                                                rows={
                                                                    previousSessions
                                                                }
                                                                columns={
                                                                    columns
                                                                }
                                                                autoHeight
                                                                getRowId={(
                                                                    row
                                                                ) =>
                                                                    row.schedule_id
                                                                }
                                                                pageSize={
                                                                    gridSize
                                                                }
                                                                rowsPerPageOptions={[
                                                                    5, 10, 25,
                                                                    50,
                                                                ]}
                                                                onPageSizeChange={(
                                                                    newGridSize
                                                                ) =>
                                                                    setGridSize(
                                                                        newGridSize
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Box>
                                            </Form>
                                        </FormikProvider>
                                    </Paper>
                                </Box>
                            </>
                        </Page>
                    </>
                }
            />
        </>
    );
}

export default PreviousSessions;
