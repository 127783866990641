import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

//MUI IMPORTS
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//CUSTOM COMPONENTS
import Accordian from "../.././../../components/speakpro/Accordian";
import SpeakProRatingComponent from "./SpeakProRatingComponent";
import { Icon } from "@iconify/react";
import ChartAccordion from "../Report/ChartAccordion";
import PreviewReport from "../Report/ReportPreview/PreviewReport";
const StudentReportSpeakPro = ({ data }) => {
    const [rating, setRating] = useState({}); //TO STORE THE RATING VALUE OF EACH GRADE
    const [selections, setSelections] = useState([]);
    const [Chart, setchart] = React.useState(1);
    const [speakProChartData, setSpeakProChartData] = useState([]);
    const [ChartValues, setChartValues] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);

    const handleRatingChange = (newValue, matrixId) => {
        if (Object.keys(rating).length === 0) {
            //INITAL TIME OF RATING SELECT , USER GET AN ALERT TO MARK THE CHECK BOX
            enqueueSnackbar(
                "To rate the candidate, you must select at least one description from the checkbox.",
                { variant: "warning" }
            );
        }
        if (newValue === null && selections?.length > 0) {
            //IF THE RATING COMES NULL WHICH MEANS NO RATING ,CREATED OBJECT FOR SPECIFIED MATRIX WILL BE GETS DELETED
            setSelections((pvs) => {
                const matrixIndex = pvs?.findIndex(
                    (matrix) => matrix.matrixId == matrixId
                );
                if (matrixIndex !== -1) {
                    const removedMatrix = [...pvs];
                    removedMatrix.splice(matrixIndex, 1);
                    return removedMatrix;
                } else {
                    return pvs; //IF THE INDEX IS NOT FIND IN SELECTIONS ARRAY IT WILL RETURN THE OLD STATE
                }
            });
        }
        const value =
            newValue == 1
                ? "10"
                : newValue == 2
                ? "20"
                : newValue == 3
                ? "30"
                : newValue == 4
                ? "40"
                : newValue == 5
                ? "50"
                : null;

        setRating((prev) => ({
            //CREATED RATING OBJECTS OTHER WISE RATING STAR WILL BE CONFLICTED
            ...prev,
            [matrixId]: value,
        }));
    };

    const handleCheckboxChange = (
        //THIS WILL TRIGGER WHILE USER CHOOSE OPTION FROM CHECK BOX
        matrixName,
        matrixId,
        gradeName,
        checkedDescriptionId,
        checkedDescription
    ) => {
        setSelections((pvs) => {
            const matrixIndex = pvs?.findIndex(
                (matrix) => matrix.matrixId == matrixId
            );
            if (matrixIndex != -1) {
                //ALREADY EXISTS MATRIX OBJECT
                if (
                    pvs[matrixIndex].checkedDescriptionId ==
                    checkedDescriptionId
                ) {
                    //IF COMES WITH SAME DISCRIPTION THEN IT GETS DELETED
                    const removeMatrixObject = [...pvs];
                    removeMatrixObject.splice(matrixIndex, 1);
                    return removeMatrixObject;
                } else {
                    const oldSelections = [...pvs];
                    oldSelections[matrixIndex] = {
                        ...oldSelections[matrixIndex],
                        gradeName,
                        checkedDescriptionId,
                        checkedDescription,
                    };
                    return oldSelections;
                }
            } else {
                //IF THE MATRIX INDEX NOT FIND,THEN IT WILL CREATE A NEW OBJECT AND PUSH TO THE SELECTION STATE
                const newMatrix = {
                    matrixName,
                    matrixId,
                    gradeName,
                    checkedDescriptionId,
                    checkedDescription,
                };
                return [...pvs, newMatrix];
            }
        });
    };

    useEffect(() => {
        if (ChartValues) {
            //DEVELOPED A FUNCTION TO RETURN CHART X AND Y VALUES
            let out = [];
            ChartValues.forEach((matrix) => {
                out.push({
                    label: matrix.matrixName,
                    value: parseInt(matrix.gradeName),
                });
            });
            setSpeakProChartData(out);
        }
    }, [ChartValues]);

    useEffect(() => {
        setChartValues(selections);
    }, [selections]);

    const handleSubmit = () => {
        setOpen(true);
    };

    return (
        <Box>
            {data?.map((matrix) => (
                <Accordian
                    key={matrix.matrix_id}
                    title={matrix.matrix_name}
                    variant="h4"
                    icon={
                        <Icon
                            icon="material-symbols:matter"
                            style={{
                                color: "#2b4ea1",
                                marginRight: 8,
                                fontSize: "24px",
                            }}
                        />
                    }
                >
                    <SpeakProRatingComponent
                        rating={rating}
                        matrix={matrix}
                        handleRatingChange={handleRatingChange}
                        handleCheckboxChange={handleCheckboxChange}
                        selections={selections}
                    />
                </Accordian>
            ))}
            <Box p={1}>
                <ChartAccordion
                    speakProChartData={speakProChartData}
                    Chart={Chart}
                    setchart={setchart}
                />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ marginTop: "20px" }}
                >
                    <Icon
                        icon="material-symbols:preview-outline"
                        height="25"
                        width="25"
                    />
                    Preview Report
                </Button>
            </Box>
            <PreviewReport
                data={selections}
                speakProChartData={speakProChartData}
                open={open}
                setOpen={setOpen}
                Chart={Chart}
            />
        </Box>
    );
};

export default StudentReportSpeakPro;
