import * as React from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearStates,
    getAttendance,
} from "../../../redux/features/attendanceSlice";
import {
    Divider,
    Typography,
    Button,
    Container,
    Box,
    Skeleton,
    Grid,
} from "@mui/material";
import Page from "../../../components/Page";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";

import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MarkAttendance from "./MarkAttendance";
import SimpleLoader from "../../../components/Loader/SimpleLoader";

import { makeStyles } from "@material-ui/core/styles";
import useResponsive from "../../../../src/Hooks/useResponsive";
import NoDataFound from "../../../components/NodataIndication/NoDataFound";
import Wrapper from "../../../components/Wrapper/Wrapper";
import AttendanceCalender from "./AttendanceCalender";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(8, 4),
    },
    card: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: 20,
        border: "1px solid #fff",
        backgroundColor: "#ffb84d",
        borderRadius: "5px",
        textAlign: "center",
        padding: "5px 0",
    },
    title: {
        padding: theme.spacing(0),
    },
    featureList: {
        padding: theme.spacing(0),
        fontWeight: "bold",
    },
}));
export default function Attendance() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.attendance.loading);
    const attendance = useSelector((state) => state.attendance);
    const TotalData = useSelector((state) => state.attendance.TotalData);
    const [value, setValue] = React.useState();
    const [date, setDate] = React.useState(new Date());

    const classes = useStyles();
    useEffect(() => {
        let yer = `${date.toISOString().split("T")[0].split("-")[0]}/${
            date.toISOString().split("T")[0].split("-")[1]
        }`;
        setDate(yer);
        dispatch(getAttendance(yer));
        return () => dispatch(clearStates());
    }, []);

    const handleChange = (newValue) => {
        setValue(newValue);

        let yer = `${newValue.toISOString().split("T")[0].split("-")[0]}/${
            newValue.toISOString().split("T")[0].split("-")[1]
        }`;
        if (newValue !== null && JSON.stringify(newValue.$d) !== "null") {
            const formated = newValue.toISOString().split("T")[0];
        }
        dispatch(getAttendance(yer));
    };
    return (
        <>
            {/* <Wrapper
                title="Attendance"
                Content={
                    <>
                        <AttendanceCalender
                            mark={<MarkAttendance />}
                            Month={<Typography variant="h4">June</Typography>}
                        />
                    </>
                }
            /> */}

            <Page>
                <Container component="main">
                    <Box sx={{ marginBottom: 2 }}>
                        <Typography variant="h4">Attendance</Typography>
                        <Breadcrumb />
                    </Box>

                    <Paper elevation={3}>
                        <Stack
                            direction={"row"}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box sx={{ padding: 2 }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["year", "month"]}
                                        label="Year and Month"
                                        inputFormat="YYYY/MM"
                                        // minDate={dayjs()}
                                        // maxDate={dayjs('2023-06-01')}
                                        disableFuture
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                helperText={null}
                                                variant="filled"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box sx={{ padding: 3 }}>
                                <MarkAttendance />
                            </Box>
                        </Stack>
                        <Divider sx={{ marginBottom: 1 }} />
                        {loading ? (
                            <SimpleLoader />
                        ) : (
                            <>
                                {attendance.ats.length === 0 ? (
                                    <NoDataFound />
                                ) : (
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                        >
                                            <TableHead
                                                sx={{
                                                    backgroundColor: "grey.200",
                                                }}
                                            >
                                                <TableRow>
                                                    {attendance.ats.map(
                                                        (item) => {
                                                            return (
                                                                <TableCell
                                                                    key={
                                                                        item.serialNumber
                                                                    }
                                                                >
                                                                    Day{" "}
                                                                    {item.date}
                                                                </TableCell>
                                                            );
                                                        }
                                                    )}
                                                </TableRow>
                                            </TableHead>

                                            <TableHead>
                                                <TableRow>
                                                    {attendance.ats.map(
                                                        (item) => {
                                                            if (
                                                                item.statusFull ===
                                                                    "Present" ||
                                                                item.statusFull ===
                                                                    "On Duty" ||
                                                                item.statusFull ===
                                                                    "Work From Home"
                                                            ) {
                                                                return (
                                                                    <TableCell
                                                                        key={
                                                                            item.serialNumber
                                                                        }
                                                                    >
                                                                        <Button
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    "#c8facd",
                                                                                color: "#14b361",
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.statusFull
                                                                            }
                                                                        </Button>{" "}
                                                                    </TableCell>
                                                                );
                                                            }
                                                            if (
                                                                item.statusFull ===
                                                                "Absent"
                                                            ) {
                                                                return (
                                                                    <TableCell
                                                                        key={
                                                                            item.serialNumber
                                                                        }
                                                                    >
                                                                        <Button
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    "#ffe4de",
                                                                                color: "#c03530",
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.statusFull
                                                                            }
                                                                        </Button>
                                                                    </TableCell>
                                                                );
                                                            } else
                                                                return (
                                                                    <TableCell
                                                                        key={
                                                                            item.serialNumber
                                                                        }
                                                                    >
                                                                        <Button
                                                                            sx={{
                                                                                backgroundColor:
                                                                                    "#eff4f9",
                                                                                color: "#2f72b3",
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.statusFull
                                                                            }
                                                                        </Button>
                                                                    </TableCell>
                                                                );
                                                        }
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableContainer>
                                )}
                            </>
                        )}
                    </Paper>

                    <div>
                        {smUp && (
                            <Box sx={{ marginTop: 4 }}>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        backgroundColor: "#f2f3f5",
                                        width: "100%",
                                    }}
                                >
                                    <Box>
                                        <Container
                                            component="section"
                                            maxWidth="lg"
                                            className={classes.root}
                                        >
                                            <Typography variant="h6">
                                                Month Attendance Review
                                            </Typography>
                                            <Divider
                                                sx={{
                                                    borderBottomWidth: 3,
                                                    marginBottom: 2,
                                                }}
                                            />
                                            {loading ? (
                                                <SimpleLoader />
                                            ) : (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="stretch"
                                                >
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#00e600",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                payable Days
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.payableDays
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#ff4000",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total Absent
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalA
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total CL
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalCL
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total CO
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalCO
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#4d4dff",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total HD
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalHD
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#ff4000",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total LOP
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalLWP
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total ML
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalML
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#00e600",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total P
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalP
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total PL
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalPL
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total SL
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalSL
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={8} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#4d4dff",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total WO
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalWO
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Container>
                                    </Box>
                                </Paper>
                            </Box>
                        )}
                        {!smUp && (
                            <Box sx={{ marginTop: 4 }}>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        backgroundColor: "#f2f3f5",
                                        width: "100%",
                                    }}
                                >
                                    <Box>
                                        <Container
                                            component="section"
                                            maxWidth="lg"
                                            className={classes.root}
                                        >
                                            <Typography variant="h6">
                                                Month Attendance Review
                                            </Typography>
                                            <Divider
                                                sx={{
                                                    borderBottomWidth: 3,
                                                    marginBottom: 2,
                                                }}
                                            />
                                            {loading ? (
                                                <SimpleLoader />
                                            ) : (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="stretch"
                                                >
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#00e600",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                payable Days
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.payableDays
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#ff4000",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total Absent
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalA
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total CL
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalCL
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total CO
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalCO
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#4d4dff",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total HD
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalHD
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#ff4000",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total LOP
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalLWP
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total ML
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalML
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#00e600",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total P
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalP
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total PL
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalPL
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total SL
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalSL
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={5} sm={2}>
                                                        <div
                                                            className={
                                                                classes.card
                                                            }
                                                            style={{
                                                                background:
                                                                    "#4d4dff",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                                className={
                                                                    classes.title
                                                                }
                                                            >
                                                                total WO
                                                            </Typography>
                                                            <Typography
                                                                className={
                                                                    classes.featureList
                                                                }
                                                            >
                                                                {
                                                                    TotalData.totalWO
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Container>
                                    </Box>
                                </Paper>
                            </Box>
                        )}
                    </div>
                </Container>
            </Page>
        </>
    );
}
