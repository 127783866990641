import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";

import { LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
    clearAnalytics,
    getAnalytics,
} from "../../../../redux/features/analyticsSlice";
import DashBoardCard from "../../../../components/mui/DashBoardCard";
import EmployeesTable from "../../../../components/datagrid/analytics/hr-dashboard/EmployeesTable";
import FunnelChartComponent from "../../../../components/charts/funnelCharts";
import TImeLineComponet from "../../../../components/charts/TImeLineComponet";
import PieChartComponent from "../../../../components/charts/PieChart";
import SourceTable from "../../../../components/datagrid/analytics/hr-dashboard/SourceTable";
import useResponsive from "../../../../Hooks/useResponsive";

const HrDashboardLayout = () => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const {
        loading,
        preData,
        recruitmentFunnel,
        employeesHired,
        timeToHire,
        timeToFill,
        totalApplications,
        totalJoined,
        recruitmentFunnelPercentage,
        pieChartData,
        sources,
    } = useSelector((state) => state.analytics);
    const dispatch = useDispatch();

    const [data, setData] = useState({
        fromDate: new Date("2021-01-01"),
        toDate: new Date(),
        positionId: 0,
    });

    const handleChange = (e) => {
        setData({ ...data, positionId: e.target.value });
    };

    const handleFilter = () => {
        let formatedFromDate = data.fromDate.toISOString().split("T")[0];
        let formatedToDate = data.toDate.toISOString().split("T")[0];
        dispatch(
            getAnalytics(
                `${formatedFromDate}/${formatedToDate}/${data.positionId}`
            )
        );
    };

    useEffect(() => {
        if (!loading && data) {
            let formatedFromDate = data.fromDate.toISOString().split("T")[0];
            let formatedToDate = data.toDate.toISOString().split("T")[0];
            dispatch(
                getAnalytics(
                    `${formatedFromDate}/${formatedToDate}/${data.positionId}`
                )
            );
        }
        return () => {
            dispatch(clearAnalytics());
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    background: "#ddeedd",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    width: "100%",
                }}
            >
                <Stack
                    direction={!smUp ? "column" : "row"}
                    sx={{
                        marginBottom: 2,
                    }}
                >
                    <Box
                        width={!smUp ? "100%" : "90%"}
                        sx={{
                            // width: "85%",
                            display: "flex",
                            gap: 2,
                        }}
                    >
                        <Stack
                            direction={!smUp ? "column" : "row"}
                            gap={2}
                            sx={{ width: "100%" }}
                        >
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                sx={{
                                    flex: 1,
                                }}
                            >
                                <DatePicker
                                    sx={{
                                        width: "100%",
                                        flex: 1,
                                    }}
                                    label="From Date"
                                    views={["year", "month", "day"]}
                                    name="startDate"
                                    format="YYYY-MM-DD"
                                    value={data.fromDate}
                                    onChange={(e) => {
                                        setData({ ...data, fromDate: e });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ flex: 1 }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                sx={{
                                    flex: 1,
                                }}
                            >
                                <DatePicker
                                    sx={{
                                        width: "100%",
                                        flex: 1,
                                    }}
                                    label="To Date"
                                    views={["year", "month", "day"]}
                                    name="startDate"
                                    format="YYYY-MM-DD"
                                    value={data.toDate}
                                    onChange={(e) => {
                                        setData({ ...data, toDate: e });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ flex: 1 }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <FormControl
                                sx={{
                                    flex: 1,
                                }}
                            >
                                <InputLabel id="input-label">
                                    Position
                                </InputLabel>
                                <Select
                                    labelId="input-label"
                                    label="Position"
                                    value={data.positionId}
                                    onChange={handleChange}
                                >
                                    {preData.map((item) => (
                                        <MenuItem
                                            key={item.positionId}
                                            value={item.positionId}
                                        >
                                            {item.positionName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Box>

                    <Button variant="contained" onClick={handleFilter}>
                        apply filter
                    </Button>
                </Stack>
                <Stack direction={!smUp ? "column" : "row"} gap={2}>
                    <DashBoardCard text={"Time to Hire"} value={timeToHire} />
                    <DashBoardCard text={"Time To Fill"} value={timeToFill} />
                    <DashBoardCard
                        text={"Total Applications"}
                        value={totalApplications}
                    />
                    <DashBoardCard text={"Total Joined"} value={totalJoined} />
                </Stack>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                {smUp && (
                    <Stack direction={"row"} sx={{ marginTop: "30px" }}>
                        <Box sx={{ width: "30%" }}>
                            <EmployeesTable
                                data={employeesHired}
                                loading={loading}
                            />
                        </Box>
                        <Box sx={{ width: "69%" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    width: "100%",
                                }}
                            >
                                <FunnelChartComponent
                                    sx={{
                                        width: "60%",
                                    }}
                                    recruitmentFunnel={recruitmentFunnel}
                                />
                                <TImeLineComponet
                                    data={recruitmentFunnelPercentage}
                                    sx={{
                                        width: "40%",
                                        marginTop: "30px",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Stack>
                )}
                {!smUp && (
                    <Stack direction={"column"} sx={{ marginTop: "30px" }}>
                        <Box sx={{ width: "90%" }}>
                            <EmployeesTable
                                data={employeesHired}
                                loading={loading}
                            />
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    width: "100%",
                                }}
                            >
                                <FunnelChartComponent
                                    sx={{
                                        width: "60%",
                                    }}
                                    recruitmentFunnel={recruitmentFunnel}
                                />
                                <TImeLineComponet
                                    data={recruitmentFunnelPercentage}
                                    sx={{
                                        marginTop: "30px",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Stack>
                )}
            </Box>
            <Box
                sx={{
                    // display: "flex",
                    // justifyContent: "space-between",
                    marginBottom: "30px",
                }}
            >
                {mdUp && (
                    <Stack direction={"row"} sx={{ marginTop: "30px" }}>
                        <Box sx={{ width: "100%" }}>
                            <PieChartComponent data={pieChartData} />
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <SourceTable data={sources} />
                        </Box>
                    </Stack>
                )}
                {!mdUp && (
                    <Stack direction={"column"} sx={{ marginTop: "30px" }}>
                        {smUp && (
                            <Box sx={{ width: "100%", marginLeft: 20 }}>
                                <PieChartComponent data={pieChartData} />
                            </Box>
                        )}
                        {!smUp && (
                            <Box>
                                <PieChartComponent data={pieChartData} />
                            </Box>
                        )}
                        <Box sx={{ width: "100%" }}>
                            <SourceTable data={sources} />
                        </Box>
                    </Stack>
                )}
            </Box>
        </>
    );
};

export default React.memo(HrDashboardLayout);
