import React, { useCallback, useEffect, useState } from "react";
import GlobalWrapper from "../../../../Wrapper/GlobalWrapper";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { CancelButton, UpdateButton } from "../../../../mui/Buttons/Buttons";
import { useFormik, Form, FormikProvider } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  getCountry,
  getOgaLeadStatus,
  getOgaLeadEnquiryStatus,
  getLeadById,
  updateLead,
  getCourses,
  getOgmLeads,
} from "../../../../../redux/features/ogdSlice";
import { useSelector } from "react-redux";
import {
  getClasses,
  getMediums,
  getSyllabus,
} from "../../../../../redux/features/croSlice";

import Loader3D from "../../../../Loader/Loader3D";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import { savePaginationData } from "../../../../../redux/features/globalDatagridSlice";

const UpdateLead = ({ params, rowData }) => {
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    countryData,
    ogaLeadStatus,
    ogaLeadEnquiryStatus,
    leadDataById,
    courses,
  } = useSelector((state) => state.ogd);
  const ogdLoading = useSelector((state) => state.ogd.loading);
  const { classes, syllabuses, mediums, loading } = useSelector(
    (state) => state.cro
  );
  const [open, setOpen] = useState(false);
  const [nextEnquiryDate, setNextEnquiryDate] = React.useState("");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      leadId: params.leadId,
      sroAssignmentId: leadDataById.sroAssignmentId || "",
      countryCode: leadDataById.countryCode || "",
      mobileNo: leadDataById.mobileNo || "",
      place: leadDataById.place || "",
      localBody: leadDataById.localBody || "",
      district: leadDataById.district || "",
      leadDate: leadDataById.leadDate || "",
      schoolName: leadDataById.schoolName || "",
      schoolPlace: leadDataById.schoolPlace || "",
      fatherName: leadDataById.fatherName || "",
      motherName: leadDataById.motherName || "",
      fatherOccupation: leadDataById.fatherOccupation || "",
      motherOccupation: leadDataById.motherOccupation || "",
      familyStructure: leadDataById.familyStructure || "",
      studentInterest: leadDataById.studentInterest || "",
      studentChallenge: leadDataById.studentChallenge || "",
      learningDirection: leadDataById.learningDirection || "",
      studyTime: leadDataById.studyTime || "",
      routine: leadDataById.routine || "",
      tuitionHistory: leadDataById.tuitionHistory || "",
      needsAndReferences: leadDataById.needsAndReferences || "",
      leadClass: leadDataById.leadClass || "",
      studentSyllabus: leadDataById.syllabus || "",
      studentMedium: leadDataById.medium || "",
      courseSelected: leadDataById.courseSelected || "",
      leadRemarks: leadDataById.leadRemarks || "",
      sroRemarks: leadDataById.sroRemarks || "",
      srmRemarks: leadDataById.srmRemarks || "",
      leadStatus: leadDataById.LeadStatus || "",
      enquiryStatus: leadDataById.enquiryStatus || nextEnquiryDate,
      nextEnquiryDate: leadDataById.nextEnquiryDate || "",
      studentName: leadDataById.name || "",
      contactPerson: leadDataById.leadDataById || "",
      email: leadDataById.email || "",
      relation: leadDataById.relation || "",
      enquiryStatusId: leadDataById.enquiryStatusId || "",
      enquiryId: leadDataById.enquiryId || "",
    },
    onSubmit: (values, { resetForm }) => {
      dispatch(updateLead(values)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          dispatch(getOgmLeads(params));
          dispatch(
            savePaginationData({ ...paginationData, openAnchor: false })
          );
          setOpen(false);
          resetForm();
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    },
  });

  const debouncedFetchData = debounce(() => {
    dispatch(getLeadById(params.leadId));
    dispatch(getOgaLeadStatus());
    dispatch(getOgaLeadEnquiryStatus());
    dispatch(getSyllabus());
    dispatch(getCountry());
    dispatch(getClasses());
    dispatch(getMediums());
    dispatch(getCourses());
  }, 40);

  useEffect(() => {
    if (open) {
      debouncedFetchData();
    }
  }, [open]);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  return (
    <GlobalDialog
      title={"Update Lead"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"sm"}
      closeButton={true}
      popupButton={
        <UpdateButton
          disabled={rowData?.cro_name ? true : false}
          size="small"
          action={handleOpen}
        />
      }
      content={
        <>
          <GlobalWrapper>
            {loading || ogdLoading ? (
              <Loader3D />
            ) : (
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <Box>
                    <Stack direction={"column"} spacing={2}>
                      <TextField
                        disabled
                        name="studentName"
                        value={formik.values.studentName}
                        label="Name"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="contactPerson"
                        value={formik.values.contactPerson}
                        label="Contact Person"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="relation"
                        value={formik.values.relation}
                        label="Relationship"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="email"
                        value={formik.values.email}
                        label="Email ID "
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <GlobalSelectField
                        options={{
                          name: "countryCode",
                          formik,
                          label: "Country Code",
                          data: countryData,
                          keys: {
                            id: "phone_code",
                            value: "concatedName",
                          },
                        }}
                      />

                      <TextField
                        name="mobileNo"
                        value={formik.values.mobileNo}
                        label="Contact No."
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="place"
                        value={formik.values.place}
                        label="Place"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="localBody"
                        value={formik.values.localBody}
                        label="Panchayat / Muncipality / Corporation  "
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="district"
                        value={formik.values.district}
                        label="District"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="leadDate"
                        disabled
                        value={formik.values.leadDate}
                        label="Lead Came On"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="schoolName"
                        value={formik.values.schoolName}
                        label="Name of School"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="schoolPlace"
                        value={formik.values.schoolPlace}
                        label="Place of School"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="fatherName"
                        value={formik.values.fatherName}
                        label="Name of Father"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="fatherOccupation"
                        value={formik.values.fatherOccupation}
                        label="Occupation of Father"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="motherName"
                        value={formik.values.motherName}
                        label="Name of Mother"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="motherOccupation"
                        value={formik.values.motherOccupation}
                        label="Occupation of Mother"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <GlobalSelectField
                        options={{
                          name: "familyStructure",
                          formik,
                          label: "Family Structure",
                          data: [
                            { name: "Nuclear", val: "Nuclear" },
                            { name: "Joint", val: "Joint" },
                          ],
                          keys: {
                            id: "name",
                            value: "val",
                          },
                        }}
                      />
                      <TextField
                        name="studentInterest"
                        value={formik.values.studentInterest}
                        label="Interest of the Student"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="studentChallenge"
                        value={formik.values.studentChallenge}
                        label="Challenges of the Student"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="learningDirection"
                        value={formik.values.learningDirection}
                        label="Learning Directions at Home"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="studyTime"
                        value={formik.values.studyTime}
                        label="Study Time"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="routine"
                        value={formik.values.routine}
                        label="Daily Routine of the Student"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="tuitionHistory"
                        value={formik.values.tuitionHistory}
                        label="Previous Tuition History"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="needsAndReferences"
                        value={formik.values.needsAndReferences}
                        label="Specific Needs and Preferences"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <GlobalSelectField
                        options={{
                          name: "leadClass",
                          formik,
                          label: "Class ",
                          data: classes,
                          keys: {
                            id: "classId",
                            value: "className",
                          },
                        }}
                      />

                      <GlobalSelectField
                        options={{
                          name: "studentSyllabus",
                          formik,
                          label: "Syllabus ",
                          data: syllabuses,
                          keys: {
                            id: "id",
                            value: "name",
                          },
                        }}
                      />
                      <GlobalSelectField
                        options={{
                          name: "studentMedium",
                          formik,
                          label: "Medium",
                          data: mediums,
                          keys: {
                            id: "id",
                            value: "name",
                          },
                        }}
                      />
                      <TextField
                        name="courseSelected"
                        label="Course Preferred"
                        fullWidth
                        onChange={formik.handleChange}
                      />
                      <GlobalSelectField
                        options={{
                          name: "courseSelected",
                          formik,
                          label: "Course Selected",
                          data: courses,
                          keys: {
                            id: "id",
                            value: "courseName",
                          },
                        }}
                      />
                      <TextField
                        name="leadRemarks"
                        value={formik.values.leadRemarks}
                        label="Lead Remarks"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <TextField
                        name="srmRemarks"
                        value={formik.values.srmRemarks}
                        label="SRM Remarks"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                      <GlobalSelectField
                        options={{
                          name: "leadStatus",
                          formik,
                          label: "Status",
                          data: ogaLeadStatus,
                          keys: {
                            id: "sro_lead_status_id",
                            value: "sro_lead_status_name",
                          },
                        }}
                      />
                      <GlobalSelectField
                        options={{
                          name: "enquiryStatusId",
                          formik,
                          label: "Enquiry Status",
                          data: ogaLeadEnquiryStatus,
                          keys: {
                            id: "sro_lead_enquiry_status_id",
                            value: "sro_lead_enquiry_status_name",
                          },
                        }}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          name="nextEnquiryDate"
                          label="Next Enquiry Date"
                          value={nextEnquiryDate}
                          onChange={(newValue) => {
                            setNextEnquiryDate(newValue);
                          }}
                        />
                      </LocalizationProvider>
                      <TextField
                        name="sroRemarks"
                        value={formik.values.sroRemarks}
                        label="SRO Remarks"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={formik.handleChange}
                      />
                    </Stack>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Stack direction={"row"} spacing={1} pt={2}>
                        <CancelButton
                          size="small"
                          action={() => setOpen(false)}
                        >
                          Cancel
                        </CancelButton>
                        <UpdateButton type="submit" size="small">
                          Update
                        </UpdateButton>
                      </Stack>
                    </Box>
                  </Box>
                </Form>
              </FormikProvider>
            )}
          </GlobalWrapper>
        </>
      }
    />
  );
};

export default UpdateLead;
