import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import Page from "../../../../components/Page";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
    editLeave,
    getLeaveApplicationById,
    getLeaveTypes,
} from "../../../../redux/features/leaveSlice";
import * as yup from "yup";

const EditLeaveApplication = () => {
    const { leave } = useSelector((state) => state);
    const leaveTypes = useSelector((state) => state.leave);

    const [fromDate, setFrom] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [displayDuration, SetDisplayDuration] = useState(false);
    const [duration, setDuration] = useState("");
    const [numberOfDays, setNumberOfDays] = useState("");
    const [availableLeave, setAvailable] = useState("Not-Available");

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const validationSchema = yup.object().shape({
        leaveTypeId: yup.number().required("please select leave type"),
        fromDate: yup.string().required("please select From Date"),
        toDate: yup.string().required("please select To date"),
        halfday: yup.boolean().when({
            is: () => {
                return numberOfDays === 1 || numberOfDays === 0.5;
            },
            then: yup.boolean().required("this feild is required"),
        }),
        reason: yup.string().required("please Enter the reason for your leave"),
    });

    const params = useParams();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            leaveId: parseInt(params.id),
            leaveTypeId: leave.singleApplication.leaveTypeId || "",
            fromDate: leave.singleApplication.fromDate || "",
            toDate: leave.singleApplication.toDate || "",
            halfday: "",
            reason: leave.singleApplication.reason || "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.halfday === "") {
                values.halfday = false;
            }
            dispatch(editLeave(values)).then((res) => {
                if (res.payload.status === "error") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "error",
                    });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    navigate("/employeeservices/applications");
                }
            });
        },
    });

    const handleChangeFrom = (e) => {
        setFrom(e);
        if (e !== null && JSON.stringify(e.$d) !== "null") {
            formik.setFieldValue("fromDate", e.toISOString().split("T")[0]);
        }
    };
    const handleChangeTo = (e) => {
        setToDate(e);
        if (e !== null && JSON.stringify(e.$d) !== "null") {
            formik.setFieldValue("toDate", e.toISOString().split("T")[0]);
        }
    };

    const handleDuration = (e) => {
        if (e.target.value === "Half-Day") {
            setDuration(e.target.value);
            formik.setFieldValue("halfday", true);
            setNumberOfDays(0.5);
        } else {
            setDuration(e.target.value);
            formik.setFieldValue("halfday", false);
            setNumberOfDays(1);
        }
    };

    useEffect(() => {
        dispatch(getLeaveTypes());
        dispatch(getLeaveApplicationById(params.id));
    }, []);

    useEffect(() => {
        setDuration("");
        if (fromDate && toDate) {
            const diff =
                new Date(fromDate).getTime() - new Date(toDate).getTime();
            const numOfDays = Math.floor(
                Math.abs(diff / (1000 * 60 * 60 * 24))
            );
            setNumberOfDays(numOfDays + 1);
            if (numOfDays <= 0) {
                SetDisplayDuration(true);
            } else {
                SetDisplayDuration(false);
            }
        }
    }, [fromDate, toDate]);

    useEffect(() => {
        if (formik.values.leaveTypeId) {
            const selectedLeave = leave.data.filter(
                (item) => item.leaveTypeId === formik.values.leaveTypeId
            );
            if (selectedLeave[0].leavesRemaining !== "Not Applicable") {
                setAvailable(selectedLeave[0].leavesRemaining);
            } else {
                setAvailable("Not-available");
            }
        }
    }, [formik.values.leaveTypeId]);
    return leave.loading ? (
        <Typography>Set Loader----</Typography>
    ) : (
        <Page>
            <Container component="main">
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Edit Leave Details
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />

                <Paper elevation={3} sx={{ width: "100%", margin: 3 }}>
                    <Box
                        sx={{
                            height: 48,
                            backgroundColor: "grey.200",
                            borderRadius: "8px 8px 0 0",
                        }}
                    ></Box>
                    <Box sx={{ padding: 6 }}>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box sx={{ minWidth: 120, margin: 2 }}>
                                    <GlobalSelectField
                                        options={{
                                            name: "leaveTypeId",
                                            formik: formik,
                                            data: leave.data,
                                            label: "Leave Type",
                                            keys: {
                                                id: "leaveTypeId",
                                                value: "leaveNameFull",
                                            },
                                            sx: { width: "100%" },
                                        }}
                                    />
                                </Box>

                                <Box sx={{ margin: 2 }}>
                                    <TextField
                                        label="Available Leave"
                                        fullWidth
                                        value={availableLeave}
                                        disabled
                                        variant="filled"
                                    />
                                </Box>

                                <Box sx={{ margin: 2 }}>
                                    <Stack direction={"row"} spacing={2}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DatePicker
                                                label="From Date"
                                                value={
                                                    fromDate ||
                                                    new Date(
                                                        leave.singleApplication.fromDate
                                                    )
                                                }
                                                inputFormat="DD/MM/YYYY"
                                                onChange={handleChangeFrom}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.errors
                                                                .fromDate
                                                        )}
                                                        helperText={
                                                            formik.errors
                                                                .fromDate
                                                        }
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DatePicker
                                                label="To Date"
                                                value={
                                                    toDate ||
                                                    new Date(
                                                        leave.singleApplication.toDate
                                                    )
                                                }
                                                inputFormat="DD/MM/YYYY"
                                                name="date"
                                                onChange={handleChangeTo}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        {...params}
                                                        error={Boolean(
                                                            formik.errors.toDate
                                                        )}
                                                        helperText={
                                                            formik.errors.toDate
                                                        }
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                </Box>

                                {displayDuration && (
                                    <Box sx={{ margin: 2 }}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(
                                                formik.errors.halfday
                                            )}
                                        >
                                            <InputLabel id="Input-Label">
                                                Duration of Leave
                                            </InputLabel>
                                            <Select
                                                labelId="Input-Label"
                                                label="Duration of Leave"
                                                onChange={handleDuration}
                                                value={duration}
                                            >
                                                <MenuItem value="Half-Day">
                                                    Half-Day
                                                </MenuItem>
                                                <MenuItem value="Full-Day">
                                                    Full-Day
                                                </MenuItem>
                                            </Select>
                                            <FormHelperText>
                                                {formik.errors.halfday}
                                            </FormHelperText>
                                        </FormControl>
                                    </Box>
                                )}

                                <Box sx={{ margin: 2 }}>
                                    <TextField
                                        label="No.of Days"
                                        fullWidth
                                        disabled
                                        variant="filled"
                                        value={
                                            numberOfDays ||
                                            leave.singleApplication.noOfdays
                                        }
                                    />
                                </Box>
                                <Box sx={{ margin: 2 }}>
                                    <TextField
                                        name="reason"
                                        label="Reason"
                                        placeholder="Enter the reason for leave"
                                        value={formik.values.reason}
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        minRows={4}
                                        onChange={formik.handleChange}
                                        error={Boolean(formik.errors.reason)}
                                        helperText={formik.errors.reason}
                                    />
                                </Box>
                                <Divider />
                                <Box
                                    sx={{
                                        paddingTop: 5,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button variant="outlined">Reset</Button>

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        value="Save"
                                        sx={{ marginLeft: 2 }}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </Box>
                </Paper>
            </Container>
        </Page>
    );
};

export default EditLeaveApplication;
