import { Box, Grid } from "@mui/material";
import React from "react";
import { Mytable, Rows } from "../../../mui/DataGrid/Details";

const TermFeeCollectionDetails = ({ data }) => {
    return (
        <Box>
            <Grid container spacing={1}>
                <Mytable
                    tableRow={
                        <>
                            <Rows label="mobile" value={data?.studentMobNo} />
                            <Rows label="Class" value={data?.className} />
                            <Rows label="Course" value={data?.courseName} />
                        </>
                    }
                />
                <Mytable
                    tableRow={
                        <>
                            <Rows label="Term" value={data?.term} />
                            <Rows label="Amount" value={data?.amount} />
                        </>
                    }
                />
            </Grid>
        </Box>
    );
};

export default TermFeeCollectionDetails;
