import React, { useRef, useState } from "react";
import {
  Typography,
  Paper,
  Box,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Stack,
  Button,
  Divider,
  FormHelperText,
  Checkbox,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useEffect } from "react";
import { useSelector, useReducer, useDispatch } from "react-redux";
import useResponsive from "../../../../Hooks/useResponsive";
import {
  applyLeave,
  getLeaveTypes,
  getAvailableCompLeave,
  getAvailableLeaveCredits,
} from "../../../../redux/features/leaveSlice";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import dayjs from "dayjs";
// ---------------------------------------------------------
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function LeaveApplication() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const leaveTypes = useSelector((state) => state.leave);
  const { availableCompData, loading, availableLeaveCredits } = useSelector(
    (state) => state.leave
  );
  const dispatch = useDispatch();
  const [fromDate, setFrom] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [availableLeave, setAvailable] = useState("Not-Available");
  const [displayDuration, SetDisplayDuration] = useState(false);
  const [duration, setDuration] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [disableDates, setDisableDates] = useState(false);
  const [checkedItems, setCheckedItems] = React.useState([]);
  // ------------------available leave count----------------------------------------------------
  const [availableLeaveCount, setAvailableLeaveCount] =
    useState("Not-Available");

  useEffect(() => {
    dispatch(getLeaveTypes());
    dispatch(getAvailableCompLeave());
  }, []);

  const validationSchema = yup.object().shape({
    leaveTypeId: yup.number().required("please select leave type"),
    fromDate: yup.string().required("please select From Date"),
    toDate: yup.string().required("please select To date"),
    halfday: yup.boolean().when({
      is: () => {
        return numberOfDays === 1 || numberOfDays === 0.5;
      },
      then: yup.boolean().required("this feild is required"),
    }),
    reason: yup.string().required("please Enter the reason for your leave"),
  });

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      compIds: checkedItems,
      leaveTypeId: "",
      fromDate: "",
      toDate: "",
      halfday: "",
      reason: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.halfday === "") {
        values.halfday = false;
      }
      if (values.leaveTypeId === 3 && checkedItems.length == 0) {
        enqueueSnackbar("Available leave not Selected..!", {
          variant: "error",
        });
      } else {
        dispatch(applyLeave(values)).then((res) => {
          if (res.payload.status === "error") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
            navigate("/employeeservices/leave");
          }
        });
      }
    },
  });

  // -----------------------updated-available-leave-count-------------------------------

  useEffect(() => {
    if (formik.values.leaveTypeId != 3 && formik.values.fromDate) {
      const data = {
        date: formik.values.fromDate,
        id: formik.values.leaveTypeId,
      };
      dispatch(getAvailableLeaveCredits(data));
    }
  }, [formik.values.leaveTypeId, formik.values.fromDate]);

  useEffect(() => {
    if (availableLeaveCredits) {
      setAvailableLeaveCount(availableLeaveCredits[0]?.leavesRemaining);
    }
  }, [availableLeaveCredits]);
  // ---------------------------------------------------------------------------------------

  useEffect(() => {
    formik.setFieldValue("compIds", checkedItems);
  }, [checkedItems]);

  const handleChangeFrom = (e) => {
    setFrom(e);
    // if (e !== null && JSON.stringify(e.$d) !== "null") {
    //   formik.setFieldValue("fromDate", e.toISOString().split("T")[0]);
    // }
    formik.setFieldValue("fromDate", dayjs(e).format("YYYY-MM-DD"));
  };
  const handleChangeTo = (e) => {
    setToDate(e);
    // if (e !== null && JSON.stringify(e.$d) !== "null") {
    //   formik.setFieldValue("toDate", e.toISOString().split("T")[0]);
    // }
    formik.setFieldValue("toDate", dayjs(e).format("YYYY-MM-DD"));
  };

  const handleDuration = (e) => {
    if (e.target.value === "Half-Day") {
      setDuration(e.target.value);
      formik.setFieldValue("halfday", true);
      setNumberOfDays(0.5);
    } else {
      setDuration(e.target.value);
      formik.setFieldValue("halfday", false);
      setNumberOfDays(1);
    }
  };

  useEffect(() => {
    if (formik.values.leaveTypeId) {
      const selectedLeave = leaveTypes.data.filter(
        (item) => item.leaveTypeId === formik.values.leaveTypeId
      );
      if (selectedLeave[0].leavesRemaining !== "Not Applicable") {
        setAvailable(selectedLeave[0].leavesRemaining);
      } else {
        setAvailable("Not-available");
      }
    }
  }, [formik.values.leaveTypeId]);

  useEffect(() => {
    setDuration("");
    if (fromDate && toDate) {
      const diff = new Date(fromDate).getTime() - new Date(toDate).getTime();
      const numOfDays = Math.floor(Math.abs(diff / (1000 * 60 * 60 * 24)));
      setNumberOfDays(numOfDays + 1);
      if (numOfDays <= 0) {
        SetDisplayDuration(true);
      } else {
        SetDisplayDuration(false);
      }
    }
  }, [fromDate, toDate]);
  // --------------------------------

  useEffect(() => {
    setAvailableLeaveCount("");
    const totalCreditBalance = availableCompData.reduce(
      (sum, item) => sum + (item.credit_balance || 0),
      0
    );

    if (formik.values.leaveTypeId == 3) {
      setAvailableLeaveCount(totalCreditBalance);
    }
    if (formik.values.leaveTypeId == 5) {
      formik.setFieldValue("halfday", false);
    }
    if (formik.values.leaveTypeId == 8 || formik.values.leaveTypeId == 3) {
      setDisableDates("disableFutureAfter2Days");
    } else if (formik.values.leaveTypeId == 13) {
      setDisableDates("disablePastEnableTodayPlus1");
    } else {
      setDisableDates(null);
    }
  }, [formik.values.leaveTypeId]);
  const shouldDisableDate = (date) => {
    const today = dayjs();

    if (disableDates === "disableFutureAfter2Days") {
      const minDate = today.add(3, "day");
      return date.isBefore(minDate, "day"); // Disable past and up to today + 2
    }

    if (disableDates === "disablePastEnableTodayPlus1") {
      const maxDate = today.add(1, "day");
      return date.isBefore(today, "day") || date.isAfter(maxDate, "day"); // Disable past and dates beyond today + 1
    }

    return false; // No restriction
  };

  const AvailableCompLeaveList = () => {
    return (
      <>
        <TableContainer>
          <Table sx={{ maxWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align="right">Credit</TableCell>
                <TableCell align="right">Date</TableCell>

                <TableCell align="right">Check</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {availableCompData.map((row) => (
                <TableRow
                  key={row.sl_no}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.emp_comp_work_id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.credit_balance}
                  </TableCell>
                  <TableCell align="right">{row.comp_work_date}</TableCell>
                  <TableCell align="right">
                    <Checkbox
                      checked={checkedItems.includes(row.emp_comp_work_id)}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCheckedItems((prev) => [
                            ...prev,
                            row.emp_comp_work_id,
                          ]);
                        } else {
                          setCheckedItems((prev) =>
                            prev.filter((id) => id !== row.emp_comp_work_id)
                          );
                        }
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <div>
      <GlobalWrapper title="Apply For Leave">
        {smUp && (
          <Paper elevation={3}>
            <Box
              sx={{
                height: 48,
                backgroundColor: "grey.200",
                borderRadius: "8px 8px 0 0",
              }}
            ></Box>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box sx={{ padding: 6 }}>
                  <Box sx={{ minWidth: 120, margin: 2 }}>
                    <GlobalSelectField
                      options={{
                        name: "leaveTypeId",
                        formik: formik,
                        data: leaveTypes.data,
                        label: "Leave Type",
                        keys: {
                          id: "leaveTypeId",
                          value: "leaveNameFull",
                        },
                        sx: { width: "100%" },
                      }}
                    />
                  </Box>
                  {/* <Box sx={{ margin: 2 }}>
                    <TextField
                      label="Available Leave"
                      fullWidth
                      value={availableLeave}
                      disabled
                      variant="filled"
                    />
                  </Box> */}

                  <Box sx={{ margin: 2 }}>
                    <Stack direction={"row"} spacing={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From Date"
                          value={fromDate}
                          disablePast
                          inputFormat="DD/MM/YYYY"
                          onChange={handleChangeFrom}
                          shouldDisableDate={shouldDisableDate}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(formik.errors.fromDate)}
                              helperText={formik.errors.fromDate}
                            />
                          )}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To Date"
                          value={toDate}
                          disablePast
                          inputFormat="DD/MM/YYYY"
                          name="date"
                          onChange={handleChangeTo}
                          shouldDisableDate={
                            disableDates ? shouldDisableDate : undefined
                          }
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(formik.errors.toDate)}
                              helperText={formik.errors.toDate}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Box>
                  <Box sx={{ margin: 2 }}>
                    <TextField
                      label="Available Leave"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: availableLeaveCount ? true : false,
                      }}
                      value={availableLeaveCount}
                    />
                  </Box>

                  {displayDuration && (
                    <Box sx={{ margin: 2 }}>
                      <FormControl
                        fullWidth
                        error={Boolean(formik.errors.halfday)}
                      >
                        <InputLabel id="Input-Label">
                          Duration of Leave
                        </InputLabel>
                        <Select
                          disabled={
                            formik.values.leaveTypeId == 5 ? true : false
                          }
                          labelId="Input-Label"
                          label="Duration of Leave"
                          onChange={handleDuration}
                          value={
                            formik.values.leaveTypeId == 5
                              ? "Full-Day"
                              : duration
                          }
                        >
                          <MenuItem value="Half-Day">Half-Day</MenuItem>
                          <MenuItem value="Full-Day">Full-Day</MenuItem>
                        </Select>
                        <FormHelperText>{formik.errors.halfday}</FormHelperText>
                      </FormControl>
                    </Box>
                  )}

                  <Box sx={{ margin: 2 }}>
                    <TextField
                      label="No.of Days"
                      fullWidth
                      disabled
                      variant="filled"
                      value={numberOfDays}
                    />
                  </Box>
                  <Box>
                    {formik.values.leaveTypeId === 3 ? (
                      <Box>
                        <Typography>Available Leave Credits</Typography>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <AvailableCompLeaveList />
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                  <Box sx={{ margin: 2 }}>
                    <TextField
                      name="reason"
                      label="Reason"
                      placeholder="Enter the reason for leave"
                      fullWidth
                      variant="outlined"
                      multiline
                      minRows={4}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.reason)}
                      helperText={formik.errors.reason}
                    />
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      paddingTop: 5,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button variant="outlined">Reset</Button>

                    <Button
                      variant="contained"
                      type="submit"
                      value="Save"
                      sx={{ marginLeft: 2 }}
                    >
                      {loading ? "Please wait..." : "Save"}
                    </Button>
                  </Box>
                </Box>
              </Form>
            </FormikProvider>
          </Paper>
        )}
        {!smUp && (
          <Paper elevation={3} sx={{ width: "100%", marginTop: 3 }}>
            <Box
              sx={{
                height: 48,
                backgroundColor: "grey.200",
                borderRadius: "8px 8px 0 0",
              }}
            ></Box>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box sx={{ padding: 2 }}>
                  <Box sx={{ minWidth: 120, margin: 2 }}>
                    <GlobalSelectField
                      options={{
                        name: "leaveTypeId",
                        formik: formik,
                        data: leaveTypes.data,
                        label: "Leave Type",
                        keys: {
                          id: "leaveTypeId",
                          value: "leaveNameFull",
                        },
                        sx: { width: "100%" },
                      }}
                    />
                  </Box>
                  {/* <Box sx={{ margin: 2 }}>
                    <TextField
                      label="Available Leave"
                      fullWidth
                      value={availableLeave}
                      disabled
                      variant="filled"
                    />
                  </Box> */}
                  <Box sx={{ margin: 2 }}>
                    <Stack direction={"column"} spacing={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From Date"
                          disablePast
                          value={fromDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={handleChangeFrom}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(formik.errors.fromDate)}
                              helperText={formik.errors.fromDate}
                            />
                          )}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="To Date"
                          disablePast
                          value={toDate}
                          inputFormat="DD/MM/YYYY"
                          name="date"
                          onChange={handleChangeTo}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              error={Boolean(formik.errors.toDate)}
                              helperText={formik.errors.toDate}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Box>

                  <Box sx={{ margin: 2 }}>
                    <TextField
                      label="Available Leave"
                      fullWidth
                      disabled
                      InputLabelProps={{ shrink: true }}
                      value={availableLeaveCount}
                    />
                  </Box>

                  {displayDuration && (
                    <Box sx={{ margin: 2 }}>
                      <FormControl
                        fullWidth
                        error={Boolean(formik.errors.halfday)}
                      >
                        <InputLabel id="Input-Label">
                          Duration of Leave
                        </InputLabel>
                        <Select
                          labelId="Input-Label"
                          label="Duration of Leave"
                          onChange={handleDuration}
                          value={duration}
                        >
                          <MenuItem value="Half-Day">Half-Day</MenuItem>
                          <MenuItem value="Full-Day">Full-Day</MenuItem>
                        </Select>
                        <FormHelperText>{formik.errors.halfday}</FormHelperText>
                      </FormControl>
                    </Box>
                  )}

                  <Box sx={{ margin: 2 }}>
                    <TextField
                      label="No.of Days"
                      fullWidth
                      disabled
                      variant="filled"
                      value={numberOfDays}
                    />
                  </Box>
                  <Box>
                    {formik.values.leaveTypeId === 3 ? (
                      <Box>
                        <Typography>Available Leave Credits</Typography>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <AvailableCompLeaveList />
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                  <Box sx={{ margin: 2 }}>
                    <TextField
                      name="reason"
                      label="Reason"
                      placeholder="Enter the reason for leave"
                      fullWidth
                      variant="outlined"
                      multiline
                      minRows={4}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.reason)}
                      helperText={formik.errors.reason}
                    />
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      paddingTop: 5,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button variant="outlined">Reset</Button>

                    <Button
                      variant="contained"
                      type="submit"
                      value="Save"
                      sx={{ marginLeft: 2 }}
                    >
                      {loading ? "Please wait..." : "Save"}
                    </Button>
                  </Box>
                </Box>
              </Form>
            </FormikProvider>
          </Paper>
        )}
      </GlobalWrapper>
    </div>
  );
}
