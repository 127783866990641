import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { SUBJECT_SESSION_COUNT as columns } from "./SubjectSessionCountTableHead";
import { Paper } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import Wrapper from "../../../../Wrapper/Wrapper";

export const TotalSessionsData = React.memo(() => {
  const [gridSize, setGridSize] = React.useState(5);
  const data = useSelector((state) => state.crm);
  return (
    <div>
      <Wrapper
        Content={
          <Box>
            <Paper elevation={3}>
              <DataGrid
                loading={data.loading}
                rows={data.SubjectSessionCount}
                columns={columns}
                sx={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
                pageSize={gridSize}
                onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
                autoHeight
                rowsPerPageOptions={[5, 10, 25, 50]}
                getRowId={(row) => row.serialNumber}
                density={"comfortable"}
                onCellClick={(row, e) => e.stopPropagation()}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Paper>
          </Box>
        }
      />
    </div>
  );
});
