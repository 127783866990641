import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Divider,
  Paper,
} from "@mui/material";
import BasicButton from "../mui/Button";
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
// others
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchStudents } from "../../redux/features/studentsSlice";
import {
  sendSingleNotification,
  getNotificationByID,
} from "../../redux/features/notificationSlice";
import Page from "../../components/Page";
import { NOTIFICATION_TABLE_HEAD as columns } from "../../utils/constants/NotificationSendTable";
import { useSnackbar } from "notistack";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CancelButton, NextButton, SendButton } from "../mui/Buttons/Buttons";
import SimpleLoader from "../Loader/SimpleLoader";
import { ClearAll } from "@mui/icons-material";
import useResponsive from "../../Hooks/useResponsive";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(params) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const [opennew, setOpennew] = useState(false);
  const [newcontent, setContent] = useState("");
  const notification = useSelector((state) => state.notification.single);
  const loading = useSelector((state) => state.notification.loading);

  const handleClickOpennew = () => {
    setOpennew(true);
  };

  const handleClosenew = () => {
    setOpennew(false);
  };
  // ------------------------------------------
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    dispatch(getNotificationByID(params.id));
    setOpen(true);
  };

  const handleClose = () => {
    setData([]);
    setOpen(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const students = useSelector((state) => state.students);
  useEffect(() => {
    setContent((prev) => {
      return {
        ...prev,

        content: notification.content,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);
  const handleContent = (e) => {
    setContent((prev) => {
      return {
        ...prev,
        content: e.target.value,
      };
    });
  };

  useEffect(() => {
    dispatch(fetchStudents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [data, setData] = useState([]);

  const onRowsSelectionHandler = (ids) => {
    setData([...ids]);
  };

  const handleSend = () => {
    const datas = {
      students: data,
      id: params.id,
      title: notification.title,
      content: newcontent.content,
    };

    dispatch(sendSingleNotification(datas)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        handleClose();
        handleClosenew();
        setData([]);

        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
      }
    });
  };

  //-----------------------------------Start--CustomGridToolBar-------------------------------------------//
  function CustomGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <div>
      <SendButton
        disabled={params.row.crm_approved ? false : true}
        action={handleClickOpen}
      >
        Send
      </SendButton>
      <Dialog
        maxWidth={"xl"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {/* ------------------------------------------------------------------------------------ */}
          <Page title="My Students">
            <Stack direction="column" mb={5}>
              <Typography variant="h4" gutterBottom>
                Students
              </Typography>
              <Divider sx={{ borderBottomWidth: 3 }} />

              <DialogContentText sx={{ marginTop: 2 }}>
                Please Select the students .You want send notification
              </DialogContentText>
            </Stack>
            {loading ? (
              <SimpleLoader />
            ) : (
              <Box sx={{ height: 400, width: "100%" }}>
                <DataGrid
                  loading={students.loading}
                  rows={students.data}
                  columns={columns}
                  pageSize={5}
                  autoHeight
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  checkboxSelection
                  disableSelectionOnClick
                  onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                  selectionModel={data}
                  getRowId={(row) => row.admissionNumber}
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 500,
                      },
                    },
                  }}
                />
              </Box>
            )}
          </Page>
          {/* ------------------------------------------------------------------------------------- */}

          {/* ------------------------------------------------------------------------------------ */}
        </DialogContent>
        <DialogActions>
          <CancelButton action={handleClose}>Cancel</CancelButton>
          <NextButton
            action={handleClickOpennew}
            disabled={data == "" ? true : false}
          >
            Next
          </NextButton>
        </DialogActions>
      </Dialog>
      <div>
        <Dialog
          open={opennew}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClosenew}
          aria-describedby="alert-dialog-slide-description"
        >
          <Paper sx={{ backgroundColor: "grey.200" }}>
            <DialogTitle>{"Edit Content"}</DialogTitle>
          </Paper>
          <Divider />
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Please add the specific values...!
            </DialogContentText>

            <Box>
              {smUp && (
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 1, width: "50ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    onChange={handleContent}
                    label="Notification"
                    value={newcontent.content || ""}
                    id="outlined-basic"
                    variant="filled"
                  />
                </Box>
              )}
              {!smUp && (
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 0, width: "100%" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    onChange={handleContent}
                    label="Notification"
                    value={newcontent.content || ""}
                    id="outlined-basic"
                    variant="filled"
                  />
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <CancelButton action={handleClosenew}>Cancel</CancelButton>
            <SendButton action={handleSend}>
              {loading ? "Sending..." : "Send"}
            </SendButton>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
