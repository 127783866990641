import React from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { useState } from "react";
import {
    CancelButton,
    EditButton,
    SaveButton,
} from "../../../mui/Buttons/Buttons";
import { useFormik, Form, FormikProvider } from "formik";
import { Box, Stack, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import {
    editmodulestoBaseCourses,
    getModulesForBaseCourses,
} from "../../../../redux/features/researchSlice";
export const EditModulePopup = ({ data, baseCourseId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { loading } = useSelector((state) => state.research);

    const Schema = Yup.object().shape({
        moduleSrlNo: Yup.string().required("moduleSrlNo is required"),
        moduleContent: Yup.string().required("moduleContent is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            moduleSrlNo: data.moduleSrlNo || "",
            moduleContent: data.moduleContent || "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            const data = {
                baseCourseId: baseCourseId,
                modules: [values],
            };
            dispatch(editmodulestoBaseCourses(data)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    dispatch(getModulesForBaseCourses(baseCourseId));
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                title="Edit Module"
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <EditButton size="small" action={() => setOpen(true)}>
                        Edit
                    </EditButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box
                                    sx={{
                                        backgroundColor: "grey.200",
                                        padding: 2,
                                        borderRadius: 1,
                                    }}
                                >
                                    <Stack direction={"column"} spacing={2}>
                                        <TextField
                                            name="moduleSrlNo"
                                            label="moduleSrlNo"
                                            value={formik.values.moduleSrlNo}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.moduleSrlNo &&
                                                Boolean(
                                                    formik.errors.moduleSrlNo
                                                )
                                            }
                                            helperText={
                                                formik.errors.moduleSrlNo
                                            }
                                        />
                                        <TextField
                                            name="moduleContent"
                                            label="Module Content"
                                            value={formik.values.moduleContent}
                                            onChange={formik.handleChange}
                                            multiline
                                            rows={3}
                                            error={
                                                formik.touched.moduleContent &&
                                                Boolean(
                                                    formik.errors.moduleContent
                                                )
                                            }
                                            helperText={
                                                formik.errors.moduleContent
                                            }
                                        />
                                    </Stack>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack spacing={2} direction={"row"}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
