import BasicButton from "../../../components/mui/Button";

import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import Tasks from "./Tasks";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useResponsive from "../../../Hooks/useResponsive";
import {
    createTask,
    deptEmployees,
    getPreData,
} from "../../../redux/features/todoSlice";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { Form, FormikProvider, useFormik } from "formik";

import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";

export default function CreateTask() {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const [department, setDepartment] = useState([]);
    const [priorities, setPriorities] = useState([]);
    const [taskType, setTaskType] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [error, setError] = useState({
        index: [],
        status: true,
    });

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const state = useSelector((state) => state.todo);
    const empState = useSelector((state) => state.employees);

    const formSchema = yup.object().shape({
        departmentId: yup.number().required("This feild is required"),
        taskTypeId: yup.number().required("This feild is required"),

        // deadLineDate: yup.string().required("This feild is required"),
    });

    const errorManeger = (taskDetails) => {
        let indexArry = [];
        for (let elements of taskDetails) {
            let index = taskDetails.indexOf(elements);

            let taskObj = taskDetails[index];
            if (
                !taskObj.priorityId ||
                !taskObj.deadLineDate ||
                !taskObj.deadLineTime ||
                !taskObj.task ||
                !taskObj.assignedTo.length === 0
            ) {
                indexArry.push(index);
                setError({
                    index: indexArry,
                    status: true,
                });
            } else {
                setError({
                    index: [],
                    status: false,
                });
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            departmentId: "",
            taskTypeId: "",
            taskDetails: [
                {
                    priorityId: "",
                    task: "",
                    deadLineDate: "",
                    deadLineTime: "",
                    assignedTo: [],
                },
            ],
        },
        validationSchema: formSchema,
        onSubmit: (values, { resetForm }) => {
            errorManeger(values.taskDetails);

            if (!error.status) {
                dispatch(createTask(values)).then((res) => {
                    if (res.payload.status === "success") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                        formik.setFieldValue("taskDetails", [
                            {
                                priorityId: "",
                                task: "",
                                deadLineDate: "",
                                deadLineTime: "",
                                assignedTo: [],
                            },
                        ]);
                        navigate("/todo/created-tasks");
                    } else {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    }
                });
            }
        },
    });
    useEffect(() => {
        dispatch(getPreData());
    }, []);

    useEffect(() => {
        setDepartment(state.departments);
        setTaskType(state.taskType);
        setPriorities(state.priority);
    }, [state]);

    useEffect(() => {
        if (formik.values.departmentId) {
            dispatch(deptEmployees(formik.values.departmentId)).then((res) => {
                setEmployees(res.payload.data.employees);
            });
            setTaskType(
                state.taskType?.filter(
                    (item) => item.task_type_dept === formik.values.departmentId
                )
            );
        }
    }, [formik.values.departmentId]);

    return (
        <Box>
            {smUp && (
                <>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Create New Task
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                    {state.loading ? (
                        <Loader />
                    ) : (
                        <Paper elevation={3} sx={{ marginTop: 4 }}>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box
                                        sx={{
                                            height: 48,
                                            backgroundColor: "grey.200",
                                            borderRadius: "8px 8px 0 0",
                                        }}
                                    ></Box>

                                    <Box sx={{ padding: 5 }}>
                                        <Stack
                                            direction={"row"}
                                            spacing={3}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {department?.length === 0 ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    height={56}
                                                    sx={{
                                                        m: 1,
                                                        minWidth: 160,
                                                        flex: 1,
                                                        borderRadius: 1,
                                                    }}
                                                    size="medium"
                                                />
                                            ) : (
                                                <GlobalSelectField
                                                    options={{
                                                        name: "departmentId",
                                                        formik: formik,
                                                        label: "Select Department",
                                                        data: department,
                                                        keys: {
                                                            id: "dept_id",
                                                            value: "dept_name",
                                                        },
                                                    }}
                                                />
                                            )}

                                            <GlobalSelectField
                                                options={{
                                                    name: "taskTypeId",
                                                    label: "Task Type",
                                                    formik: formik,
                                                    data: taskType,
                                                    keys: {
                                                        id: "task_type_id",
                                                        value: "task_type_name",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Box>

                                    <Box>
                                        <Tasks
                                            priorities={priorities}
                                            formik={formik}
                                            employees={employees}
                                            loading={empState.loading}
                                            error={error}
                                            errorManeger={errorManeger}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: 5,
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <BasicButton
                                            variant="outlined"
                                            size="medium"
                                        >
                                            Reset
                                        </BasicButton>

                                        <BasicButton
                                            variant="contained"
                                            size="medium"
                                            type="submit"
                                            value="Save"
                                            sx={{ marginLeft: 2 }}
                                        >
                                            Submit
                                        </BasicButton>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    )}
                </>
            )}
            {!smUp && (
                <>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Create New Task
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                    {state.loading ? (
                        "Loading..."
                    ) : (
                        <Paper elevation={3}>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box
                                        sx={{
                                            height: 48,
                                            backgroundColor: "grey.200",
                                            borderRadius: "8px 8px 0 0",
                                        }}
                                    ></Box>

                                    <Box sx={{ padding: 2 }}>
                                        <Stack
                                            direction={"column"}
                                            spacing={3}
                                            // sx={{
                                            //     display: "flex",
                                            //     justifyContent: "center",
                                            //     alignItems: "center",
                                            // }}
                                        >
                                            {department?.length === 0 ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    height={56}
                                                    sx={{
                                                        m: 1,
                                                        minWidth: 160,
                                                        flex: 1,
                                                        borderRadius: 1,
                                                    }}
                                                    size="medium"
                                                />
                                            ) : (
                                                <GlobalSelectField
                                                    options={{
                                                        name: "departmentId",
                                                        formik: formik,
                                                        label: "Select Department",
                                                        data: department,
                                                        keys: {
                                                            id: "dept_id",
                                                            value: "dept_name",
                                                        },
                                                    }}
                                                />
                                            )}

                                            <GlobalSelectField
                                                options={{
                                                    name: "taskTypeId",
                                                    label: "Task Type",
                                                    formik: formik,
                                                    data: taskType,
                                                    keys: {
                                                        id: "task_type_id",
                                                        value: "task_type_name",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </Box>

                                    <Box>
                                        <Tasks
                                            priorities={priorities}
                                            formik={formik}
                                            employees={employees}
                                            loading={empState.loading}
                                            error={error}
                                            errorManeger={errorManeger}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            padding: 5,
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <BasicButton
                                            variant="outlined"
                                            size="medium"
                                        >
                                            Reset
                                        </BasicButton>

                                        <BasicButton
                                            variant="contained"
                                            size="medium"
                                            type="submit"
                                            value="Save"
                                            sx={{ marginLeft: 2 }}
                                        >
                                            Submit
                                        </BasicButton>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    )}
                </>
            )}
        </Box>
    );
}
