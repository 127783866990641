import React from "react";
import { Box, TextField, Stack, Button, Typography } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import useResponsive from "../../../../../Hooks/useResponsive";

export default function BankInfo({ formik, step, setActiveStep }) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const { data } = useSelector((state) => state.login);
  useEffect(() => {
    if (data.profile.profileCompletion) setActiveStep(4);
  }, [data]);
  return (
    <div>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {!smUp ? (
            <>
              <Typography padding={1} variant="h6">
                Bank Information
              </Typography>
            </>
          ) : null}
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="Bank Name"
                variant="outlined"
                fullWidth
                name="bankName"
                onChange={formik.handleChange}
                value={formik.values.bankName}
                error={Boolean(
                  formik.touched.bankName && formik.errors.bankName
                )}
                helperText={formik.touched.bankName && formik.errors.bankName}
              />
              <TextField
                label="bank Branch"
                variant="outlined"
                fullWidth
                name="bankBranch"
                onChange={formik.handleChange}
                value={formik.values.bankBranch}
                error={Boolean(
                  formik.touched.bankBranch && formik.errors.bankBranch
                )}
                helperText={
                  formik.touched.bankBranch && formik.errors.bankBranch
                }
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="Account Holder"
                variant="outlined"
                fullWidth
                name="accountHolder"
                onChange={formik.handleChange}
                value={formik.values.accountHolder}
                error={Boolean(
                  formik.touched.accountHolder && formik.errors.accountHolder
                )}
                helperText={
                  formik.touched.accountHolder && formik.errors.accountHolder
                }
              />
              <TextField
                label="Account Number"
                variant="outlined"
                fullWidth
                name="accountNumber"
                onChange={formik.handleChange}
                value={formik.values.accountNumber}
                type="number"
                error={Boolean(
                  formik.touched.accountNumber && formik.errors.accountNumber
                )}
                helperText={
                  formik.touched.accountNumber && formik.errors.accountNumber
                }
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="ifsc Code"
                variant="outlined"
                fullWidth
                name="ifsc"
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={formik.handleChange}
                value={formik.values.ifsc}
                error={Boolean(formik.touched.ifsc && formik.errors.ifsc)}
                helperText={formik.touched.ifsc && formik.errors.ifsc}
              />
              <TextField
                label="upi Id"
                variant="outlined"
                fullWidth
                name="upiApp"
                onChange={formik.handleChange}
                value={formik.values.upiApp}
                error={Boolean(formik.touched.upiApp && formik.errors.upiApp)}
                helperText={formik.touched.upiApp && formik.errors.upiApp}
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <TextField
              label="upi MobileNo"
              variant="outlined"
              fullWidth
              name="upiMobileNo"
              onChange={formik.handleChange}
              value={formik.values.upiMobileNo}
              type="number"
              error={Boolean(
                formik.touched.upiMobileNo && formik.errors.upiMobileNo
              )}
              helperText={
                formik.touched.upiMobileNo && formik.errors.upiMobileNo
              }
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box margin={1}>
              <Button
                onClick={() => setActiveStep(step - 1)}
                variant="outlined"
                disabled={data.profile.profileCompletion === 1 ? true : false}
              >
                Back
              </Button>
            </Box>
            <Box margin={1}>
              <Button
                size={`${!smUp ? "small" : ""}`}
                variant="contained"
                type="submit"
              >
                Submit Details
                <Icon icon="tabler:player-track-next" width="25" height="25" />
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
