import userPhotos from "./userPhotos";
import actionsMenu from "./actionsMenu";
import Action from "./action";
export const ALL_USERS_TABLE_HEAD = [
  {
    field: "serialNumber",
    width: 5,
    headerName: "Sl.No",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  // {
  //     field: "id",
  //     width:5,
  //     headerName: "id",
  //     type: "number",
  //     headerAlign: "left",
  //     align: "left",
  //   },
  {
    field: "name",
    flex: 0.3,
    minWidth: 200,
    headerName: "Name",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "photo",
    // flex: 0.1,
    // minWidth:90,
    headerName: "Photo",
    headerAlign: "left",
    align: "left",
    // height:100,
    renderCell: (params) => userPhotos(params),
  },
  {
    field: "designation",
    flex: 0.6,
    minWidth: 200,
    headerName: "designation",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "reportingHead",
    flex: 1,
    minWidth: 200,
    headerName: "Reporting Head",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "department",
    flex: 0.3,
    minWidth: 200,
    headerName: "department",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "email",
    flex: 0.6,
    minWidth: 250,
    headerName: "Email",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "officialEmail",
    flex: 0.6,
    minWidth: 200,
    headerName: "Official Email",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "actions",
    flex: 0.1,
    minWidth: 150,
    headerName: "Edit",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => Action(params),
  },
];
