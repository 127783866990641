import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { EditButton } from "../../../../mui/Buttons/Buttons";
import LeadUpdateForm from "./LeadUpdateForm";

const UpdateLead = ({ data }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <GlobalDialog
                title={"Update Lead"}
                popupButton={
                    <EditButton action={() => setOpen(true)} size="small" />
                }
                open={open}
                setOpen={setOpen}
                closeButton={true}
                fullWidth
                maxWidth={"lg"}
                content={<LeadUpdateForm data={data} setOpen={setOpen} />}
            />
        </div>
    );
};

export default UpdateLead;
